import moment from 'moment'

const timeMixins = {
    methods: {
        checkServerTime() {
            // if (!this.maintenance && !this.isApp) {
            //     if (this.utcServerCheck && this.utcServer == this.utcServerCheck) {
            //         this.setServerConnected(false)
            //     }
            //     else if (!this.utcServer) {
            //         this.setServerConnected(false)
            //     }
            //     else {
            //         this.setServerConnected(true)

            //         this.setStateData([
            //             { utcServerCheck: this.objCopy(this.utcServer) }
            //         ])
            //     }
            // }
        },
        currentUnixTime() {
            return moment().unix()
        },
        dateTimeToUnixTime(inDateTime) {
            return moment.utc(inDateTime, 'MM/DD/YYYY HH:mm', true).unix()
        },
        getCurrentTimeDiff(inTime) {
            let mySeconds = inTime - this.currentUnixTime()

            if (mySeconds >= 86400) {
                return Math.floor((inTime - this.currentUnixTime()) / 86400)
            }
            else if (mySeconds >= 3600) {
                return Math.floor((inTime - this.currentUnixTime()) / 3600)
            }
            else if (mySeconds >= 60) {
                return Math.floor((inTime - this.currentUnixTime()) / 60)
            }
            else if (mySeconds >= 1) {
                return '1'
            }
            else {
                return null
            }
        },
        getCurrentTimeDiffDesc(inTime) {
            let mySeconds = inTime - this.currentUnixTime()

            if (mySeconds >= 172800) {
                return 'Days to go'
            }
            else if (mySeconds >= 86400) {
                return 'Day to go'
            }
            else if (mySeconds >= 7200) {
                return 'Hours to go'
            }
            else if (mySeconds >= 3600) {
                return 'Hour to go'
            }
            else if (mySeconds >= 60) {
                return 'Minutes to go'
            }
            else {
                return 'Minute to go'
            }
        },
        getCurrentTimeDiffSeconds(inTime) {
            return inTime - this.currentUnixTime()
        },
        getLocalTime(inTime) {
            return new Date(inTime).toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute:'2-digit' })
            // return moment.utc(inTime).local().format('DD/MM/YYYY HH:mmA')
        }, 
        getLocalUnixDate(inTime) {
            return new Date(inTime * 1000).toLocaleDateString()
            // return moment.unix(inTime).local().format('DD/MM/YYYY')
        },
        getLocalUnixTime(inTime) {
            return new Date(inTime * 1000).toLocaleString(undefined, { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute:'2-digit' })
            // return moment.unix(inTime).local().format('DD/MM/YYYY HH:mmA')
        },
        humanizedAge(inTime) {
            return this.utcLocal ? moment.duration(moment.utc(this.utcLocal).diff(moment.utc(inTime))).humanize() + ' ago' : ''
        },
        minutesAgo(inTime) {
            let myDiff = this.currentUnixTime() - inTime

            if (myDiff < 60) {
                let myLabel = myDiff < 2 ? ' second ago' : ' seconds ago'
                return `${myDiff}${myLabel}`
            }

            if (Math.floor(myDiff/60) < 60) {
                let myLabel = Math.floor(myDiff/60) < 2 ? ' minute ago' : ' minutes ago'
                return `${Math.floor(myDiff/60)}${myLabel}`
            }

            let myLabel = Math.floor(myDiff/60/60) < 2 ? ' hour ago' : ' hours ago'
            return `${Math.floor(myDiff/60/60)}${myLabel}`
        },
        nextBillingDate(inTime) {
            return moment.unix(inTime).startOf('month').add(1, 'months').add(13, 'days').unix()
        },
        nextMonth() {
            return moment().startOf('month').add(1, 'months').unix()
        },
        roundTime(inDate, inDuration = moment.duration(30, "minutes"), inMethod = 'ceil') {
            return moment(Math[inMethod]((+inDate) / (+inDuration)) * (+inDuration))
        },
        updateServerTime(inData) {
            if (!this.isApp) {
                this.setServerConnected(true)

                this.setStateData([
                    { utcServer: inData.serverTime }
                ])
            }
        },
        updateUTCTime() {
            this.setStateData([
                { utcLocal: moment.utc().format() }
            ])
        }
    }
}

export default timeMixins