<template>
    <div class="w-full">
        <div v-for="x in count" :class="{ [classes]: true }" :style="styles ? styles : {}">
        </div>
    </div>
</template>

<script>
export default {
    props: ['classes', 'count', 'styles']
}
</script>