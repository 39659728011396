<template>
    <modal v-if="showBadgeModal" :open="showBadgeModal" class="text-white" @close="clickClose()">
        <modal-background-image
            :bgImage="defaultHero"
        />
        <div class="absolute top-0 left-0 w-full" :class="{ 'pb-16 px-1': windowWidth < desktopMaxWidth, 'desktop-modal-box': windowWidth >= desktopMaxWidth }">
            <div :class="{ 'desktop-modal': windowWidth >= desktopMaxWidth }">
                <div class="w-full mt-4">
                    <h3 class="section-heading mb-3 text-center">
                        New Badge Awarded
                    </h3>
                    <div class="mt-6 text-center">
                        <div v-if="badgeCategory">   
                            You've unlocked a <span class="font-bold" :class="{ [badgeClass]: true }">{{ badgeCategory }}</span> Badge!
                        </div>
                        <div v-else>   
                            You have earned the {{ badgeText }} badge!
                        </div>
                        <div class="w-full mt-8">
                            <cached-image imageClasses="w-40 h-auto mx-auto" :imageSrc="transformUrl(badgeUrl, ['optimize', 'sm', 'q80'])" />
                        </div>
                        <div class="mt-4" v-if="badgeCategory">   
                            {{ badgeText }}
                        </div>
                    </div>
                </div>
                <div class="mt-6">
                    <div v-if="!badgeAwarded.build && loggedUser.builds && loggedUser.builds.length > 1">
                        <div class="text-sm font-bold leading-5 text-center">
                            Equip on 1 or more of your builds <span class="text-xs">(Tap to select/unselect)</span>
                        </div>
                        <div class="round-tabs-container mt-1 mb-4">
                            <div v-for="build in loggedUser.builds" @click="switchBuildSelected(build.id)" class="round-tab" :class="{ 'round-tab-off': !isBuildSelected(build.id), 'round-tab-on': isBuildSelected(build.id) }">
                                {{ build.name }}
                            </div>
                        </div>
                        <div v-if="selectedBuilds.length" @click="equipBadge(badgeUrl, selectedBuilds)" class="standard-button-base mb-2">
                            Equip on selected Builds
                        </div>
                    </div>
                    <div v-if="!badgeAwarded.build && loggedUser.builds && loggedUser.builds.length == 1" @click="equipBadge(badgeUrl, [loggedUser.builds[0].id])" class="standard-button-base mb-2">
                        Equip on Build {{ loggedUser.builds[0].name }}
                    </div>
                    <div v-if="badgeAwarded.build" @click="equipBadge(badgeUrl, [badgeAwarded.build])" class="standard-button-base mb-2">
                        Equip on Build {{ badgeBuild.name }}
                    </div>
                    <div v-if="!loggedUser.builds || loggedUser.builds.length == 0" class="mb-6 text-center">
                        Create your first Build to equip this badge!
                    </div>
                    <div @click="clickClose()" class="alt-button-base mb-2">
                        Close
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/shared/Modal'
import ModalBackgroundImage from '@/shared/ModalBackgroundImage'

export default {
    components: {
        Modal,
        ModalBackgroundImage
    },
    data() {
        return {
            selectedBuilds: []
        }
    },
    computed: {
        badgeBuild() {
            return !this.badgeAwarded.build ? null : this.loggedUser.builds.filter((build) => build.id == this.badgeAwarded.build)[0]
        },
        badgeCategory() {
            return this.badgeAwarded?.badge_category ?? null
        },
        badgeClass() {
            if (!this.badgeCategory || this.badgeCategory == "Common") {
                return "text-white"
            }

            return this.badgeCategory == "Special" ? "text-blue" : (this.badgeCategory == "Rare" ? "text-yellow" : "text-orange-500")
        },
        badgeText() {
            return this.badgeAwarded.badge_text ?? this.badgeAwarded.text
        },
        badgeUrl() {
            return this.badgeAwarded.badge_url ?? this.badgeAwarded.badge
        }
    },
    mounted() {
        this.setStateData([
            { forceHubDataReload: true }
        ])

        if (this.badgePlaySound) {
            if ((this.isAndroidApp || this.isIOSApp) && this.badgeAwarded.sound) {
                this.playSound(this.badgeAwarded.sound)
            }

            let myUser = this.objCopy(this.loggedUser)
            
            let myBadge = {
                text: this.badgeText,
                badge: this.badgeUrl
            }

            let userBadges = myUser.badges ?? []
            userBadges.push(myBadge)
            myUser.badges = this.objCopy(userBadges)

            if (this.badgeAwarded.checklist) {
                if (myUser.checklist && this.objLength(myUser.checklist) > 0) {
                    myUser.checklist = {...myUser.checklist, ...{[`${this.badgeAwarded.checklist}`]: true}}
                }
                else {
                    myUser.checklist = {[`${this.badgeAwarded.checklist}`]: true}
                }

                if (this.badgeAwarded.checklist_label) {
                    this.appsFlyerEvent(
                        {
                            eventName: `Checklist ${this.badgeAwarded.checklist_label}`,
                            eventValue: {}
                        }
                    )
                }
            }

            this.replaceStateData([
                { loggedUser: myUser }
            ])
        }
    },
    methods: {
        clickClose() {
            this.closeBadgeModal()
        },
        equipBadge(inURL, inBuilds) {
            this.openSpinner()

            buildzAxios?.post(this.getRoute('buildEquipBadge'), { 
                badge_url: inURL,
                builds: inBuilds
            })
            .then((res) => {
                this.closeSpinner()
                
                this.setStateData([
                    { equippedBuilds: inBuilds }
                ])

                this.$nextTick(() => {
                    this.setStateData([
                        { equippedBuildBadge: inURL }
                    ])
                })

                this.closeBadgeModal()
            })
            .catch((error) => {
                console.log(error)
                this.pageMessage('error', 'An error occurred')
                this.closeSpinner()
            })
        },
        isBuildSelected(inBuildId) {
            return this.selectedBuilds.includes(inBuildId)
        },
        switchBuildSelected(inBuildId) {
            if (this.selectedBuilds.includes(inBuildId)) {
                let myBuilds = this.objCopy(this.selectedBuilds)
                myBuilds = myBuilds.filter((build) => build != inBuildId)
                this.selectedBuilds = this.objCopy(myBuilds)
            }
            else {
                this.selectedBuilds.push(inBuildId)
            }
        }
    }
}
</script>

<style>
    .text-blue {
        color: #00ccff;
    }

    .text-yellow {
        color: #ffff66;
    }
</style>