<template>
    <div v-if="!hideTrackerBar(groupBuy)" class="w-full h-4 flex items-center">
        <div :id="barId" class="relative w-full">
            <div class="absolute bg-gray-400 w-full my-1 h-2">
            </div>
            <div class="absolute my-1 h-2 max-w-full" :class="{ 'bg-green-500': !eoi && groupBuy.current_qty >= groupBuy.min_quantity, 'bg-red-500': !eoi && groupBuy.current_qty < groupBuy.min_quantity && gbStatus == 'current', 'bg-gray-500': !eoi && groupBuy.current_qty < groupBuy.min_quantity && gbStatus == 'ended', 'bg-blue-500': eoi }" :style="{ 'width': barWidth }">
            </div>
            <div v-for="(breakPoint, index) in breakPoints" class="absolute red-bubble-big" :style="{ 'left': breakPoint, 'top': '-2px' }">
                {{ index + 1 }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'barId', 
        'eoi', 
        'groupBuy',
        'reloadBar'
    ],
    data() {
        return {
            barWidth: 0,
            breakPoints: [],
            minLeft: 0
        }
    },
    computed: {
        gbStatus() {
            if (!this.groupBuy) {
                return null
            }

            if (this.groupBuy.start_time < this.currentUnixTime() && this.groupBuy.end_time > this.currentUnixTime()) {
                return "current"
            }

            if (this.groupBuy.start_time < this.currentUnixTime() && this.groupBuy.end_time < this.currentUnixTime()) {
                return "ended"
            }
        },
        maxQty() {
            if (this.eoi && !this.groupBuy.no_eoi_maximum) {
                return this.groupBuy.eoi_max_quantity
            }

            if (!this.eoi && !this.groupBuy.no_maximum) {
                return this.groupBuy.max_quantity
            }

            if (this.groupBuy.no_breakpoints) {
                return (this.eoi ? this.groupBuy.eoi_current_qty : this.groupBuy.current_qty) + 20
            }

            return Math.max((this.eoi ? this.groupBuy.eoi_current_qty : this.groupBuy.current_qty), this.sortedPricingLevels[this.sortedPricingLevels.length - 1].qty) + 20
        },
        sortedPricingLevels() {
            return this.groupBuy.quantity_levels
                .sort((a, b) => a.discount > b.discount ? 1 : -1)
        }
    },
    watch: {
        reloadBar: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.setWidths()

                    setTimeout(() => {
                        this.setWidths()
                    }, 200)
                }
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.setWidths()
        }, 200)
    },
    methods: {
        setWidths() {
            this.barWidth = Math.round((this.eoi ? this.groupBuy.eoi_current_qty : this.groupBuy.current_qty)/this.maxQty * this.getRectEl(this.elGet(this.barId)).width) + "px"
            this.minLeft = Math.round((this.eoi ? this.groupBuy.eoi_min_quantity : this.groupBuy.min_quantity)/this.maxQty * this.getRectEl(this.elGet(this.barId)).width) + "px"

            if (!this.groupBuy.no_breakpoints) {
                let myBreakPoints = []

                this.sortedPricingLevels.forEach((level) => {
                    myBreakPoints.push(Math.round(level.qty / this.maxQty * this.getRectEl(this.elGet(this.barId)).width) + "px")
                })

                this.breakPoints = myBreakPoints
            }
        }
    }
}
</script>