<template>
    <div @click.native.prevent="clickCopyLink()" class="flex items-center" :class="{ 'standard-button-base': orangeButton, 'alt-button-base': !orangeButton }" :style="whiteLabel && orangeButton ? { 'background-color': whiteLabelData.secondaryColor, 'color': whiteLabelData.secondaryTextColor, 'border-color': whiteLabelData.secondaryColor } : (whiteLabel && !orangeButton ? { 'border-color': whiteLabelData.secondaryColor, 'color': whiteLabelData.secondaryColor } : {})">
        <div v-if="isIOSApp">
            <fa-icon icon="arrow-up-from-bracket" type="fas" class="h-5 w-5" />
        </div>
        <div v-if="isAndroidApp">
            <fa-icon icon="share-nodes" type="fas" class="h-5 w-5" />
        </div>
        <div v-if="!isAndroidApp && !isIOSApp">
            <fa-icon icon="link" type="fas" class="h-5 w-5" />
        </div>
        <div class="ml-2">
            {{ shareGroupBuyButtonText(groupBuy) }}
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'customerVendor',
        'groupBuy', 
        'orangeButton'
    ],
    computed: {
        activityData() {
            return `Buildz.pro\n${this.activityUrl}\n\n#justhereforthebuildz\n#keepbuildingawesome\n`
        },
        activityUrl() {
            let appUrl = this.whiteLabel && this.customerVendor ? `https://${this.customerVendor.sub_domain}.buildz.store/` : this.appUrl
            let url = `${appUrl}groupBuy/${this.groupBuy.id}`

            if (this.loggedUser) {
                url = `${url}?session=${this.loggedUser?.short_id}`
            }

            return url
        }
    },
    methods: {
        clickCopyLink() {
            if (this.isAndroidApp || this.isIOSApp) {
                this.sharePost()
                this.gaShare(this.isAndroidApp ? 'Android' : 'IOS', 'Group Buy', this.groupBuy.id)
            }
            else {
                navigator.clipboard.writeText(this.activityUrl)
                    .then(() => {
                        this.pageMessage('success', 'Link copied to clipboard')
                        this.gaShare('Link', 'Group Buy', this.groupBuy.id)
                    })
                    .catch(() => {
                        this.pageMessage('error', 'Unable to access your clipboard')
                    })
            }
        },
        sharePost() {
            this.nativeShare(
                {
                    text: this.activityData,
                    url: this.activityUrl
                }
            )
        }
    }
}
</script>