<template>
    <div v-if="!hideTrackerBar(groupBuy)" class="w-full h-4 flex items-center">
        <div :id="barId" class="relative w-full">
            <div class="absolute bg-gray-400 w-full my-1 h-2">
            </div>
            <div class="absolute my-1 h-2 max-w-full bg-green-500" :style="{ 'width': barWidth }">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'barId', 
        'groupBuy',
        'reloadBar'
    ],
    data() {
        return {
            barWidth: 0
        }
    },
    computed: {
        maxDollars() {
            if (!this.groupBuy.no_maximum_dollars) {
                return parseFloat(this.groupBuy.max_dollars)
            }

            return parseFloat(this.groupBuy.current_dollars) + 200
        }
    },
    watch: {
        reloadBar: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.setWidths()

                    setTimeout(() => {
                        this.setWidths()
                    }, 200)
                }
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.setWidths()
        }, 200)
    },
    methods: {
        setWidths() {
            this.barWidth = Math.round(parseFloat(this.groupBuy.current_dollars) / this.maxDollars * this.getRectEl(this.elGet(this.barId)).width) + "px"
        }
    }
}
</script>