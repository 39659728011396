<template>
    <div class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="useStripe" fieldLabel="Do You Want To Use Stripe?" fieldLabelClasses="text-gray-800" :fieldRequired="true"
            :fieldValue="sellerDataForm.useStripe" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateSellerDataFormSelectValue"
        >
            <validation-message v-if="validating && sellerDataForm.useStripe == null" 
                message="Select yes or no"
            />
        </select-panel>
        <div v-if="sellerDataForm.useStripe" class="w-full">
            <div v-if="!hasStripeAccount">
                <select-panel
                    :fieldData="countryList" fieldDataLabel="name" fieldDataValue="code" fieldFormName="stripeCountryCode" fieldLabel="Country" 
                    fieldLabelClasses="text-gray-800" :fieldRequired="true" :fieldValue="stripeCountryCode" :forceMobile="true"
                    @fieldChange="updateCountry"
                />
                <div class="w-full  mt-px py-4 px-2 text-center" :class="{ 'flex flex-col items-center justify-center w-full': isDesktopWidth }">
                    <div @click="clickAddStripeAccount()" :class="{ 'secondary-button-base': stripeCountryCode, 'disabled-button-base': !stripeCountryCode }" class="w-full">
                        Begin Stripe Account Setup
                    </div>
                    <div class="flex justify-center mt-3 bg-white p-px rounded">
                        <img src="https://res.cloudinary.com/dnnzvmbis/image/upload/v1684722011/buildz_pro_assets/Powered_by_Stripe_-_blurple_k9uq45.svg" class="w-28" />
                    </div>
                </div>
            </div>
            <div v-if="incompleteStripeAccount" class="w-full mt-px px-2 py-3">
                <div @click="clickCompleteStripeAccount()" class="secondary-button-base">
                    Complete Stripe Account Setup
                </div>
            </div>
            <div v-if="hasStripeAccount && viewedStripeAccount" class="orange-transparent p-2 mx-2">
                <input-panel 
                    fieldLabel="Stripe Account Id" :fieldNonEdit="true" :fieldValue="viewedStripeAccount.data.stripe_account.id"
                />
                <input-panel 
                    fieldLabel="Details Submitted" :fieldNonEdit="true" :fieldValue="detailsSubmitted"
                />
                <input-panel 
                    fieldLabel="Charges Enabled" :fieldNonEdit="true" :fieldValue="chargesEnabled"
                />
                <input-panel 
                    fieldLabel="Payouts Enabled" :fieldNonEdit="true" :fieldValue="payoutsEnabled"
                />
            </div>
            <div v-if="completeStripeAccount" class="w-full mt-px white-transparent px-2 py-3">
                <div @click="clickViewStripeAccount()" class="alt-button-base">
                    Edit Stripe account
                </div>
            </div>
        </div>
        <validation-message v-if="validating && sellerDataForm.useStripe != null && !sellerDataForm.usePayPal && !sellerDataForm.useStripe" 
            message="You must select at least one Payment Gateway (PayPal or Stripe)"
        />
        <validation-message v-if="validating && sellerDataForm.useStripe && !completeStripeAccount" 
            message="You must complete the Stripe Account Setup"
        />
    </div>
</template>

<script>
export default {
    props: [
        'sellerDataForm',
        'validating'
    ],
    data() {
        return {
            stripeCountryCode: null,
            stripeSubscription: null
        }
    },
    computed: {
        chargesEnabled() {
            if (this?.viewedStripeAccount?.data?.stripe_account ?? false) {
                return this.viewedStripeAccount.data.stripe_account.charges_enabled ? 'Yes' : 'No'
            }

            return false
        },
        detailsSubmitted() {
            if (this?.viewedStripeAccount?.data?.stripe_account ?? false) {
                return this.viewedStripeAccount.data.stripe_account.details_submitted ? 'Yes' : 'No'
            }

            return false
        },
        payoutsEnabled() {
            if (this?.viewedStripeAccount?.data?.stripe_account ?? false) {
                return this.viewedStripeAccount.data.stripe_account.payouts_enabled ? 'Yes' : 'No'
            }

            return false
        },
        viewedStripeAccount() {
            return this.editingTempSeller ? this.stripeTempAccount : this.stripeAccount
        }
    },
    mounted() {
        if (!this.stripeCountryCode) {
            this.stripeCountryCode = this.sellerDataForm.countryCode
        }
    },
    methods: {
        async clickAddStripeAccount() {
            if (this.stripeCountryCode) {
                this.openSpinner()

                let accountResponse = await this.stripeCreateAccount(this.stripeCountryCode)

                this.closeSpinner()

                console.log(accountResponse.data.links.url)

                if (accountResponse?.data?.links?.url) {
                    this.openExternalUrl(accountResponse.data.links.url)
                }
            }
        },
        async clickCompleteStripeAccount() {
            this.openSpinner()
            let linkResponse = await this.stripeGetLinks()
            this.closeSpinner()

            if (linkResponse?.data?.links?.url) {
                this.openExternalUrl(linkResponse.data.links.url)
            }
        },
        async clickViewStripeAccount() {
            this.openSpinner()
            let linkResponse = await this.stripeGetLinks('account_update')
            this.closeSpinner()

            if (linkResponse?.data?.links?.url) {
                this.openExternalUrl(linkResponse.data.links.url)
            }
        },
        updateCountry(inField, inValue) {
            this.stripeCountryCode = inValue
        },
        updateSellerDataFormSelectValue(inField, inValue) {
            this.$emit('updateSellerDataFormSelectValue', inField, inValue)
        }
    }
}
</script>