<template>
    <div v-if="mainActivity" class="mt-2">
        <div v-if="showActivityText" :id="textContainerId" class="relative px-1 overflow-hidden" :class="{ 'pb-3': !showShowMore && renderText }" :style="!modal && !showMoreLinkClicked ? { maxHeight: '4.5rem' } : {}">
            <div v-if="renderText" v-html="renderText">
            </div>
        </div>
        <div v-if="serverConnected && !showMoreLinkClicked && needsShowMore" class="ml-1 mt-1 pb-3 text-sm text-orange-400 cursor-pointer" @click="clickShowMore()">
            Show more
        </div>
        <div v-if="renderYoutube && renderYoutube.length" v-html="renderYoutube" class="pb-3">
        </div>
        <build-card v-if="isBuild && !hasYoutubeLinks && !activityGallery" 
            :modal="modal" :buildData="mainActivity.attachments.og" :buildId="computedBuildId" :activityId="mainActivity.id"
        />
        <event-card v-if="isEvent && !hasYoutubeLinks && !activityGallery" 
            :eventData="mainActivity.attachments.og" 
        />
        <link-card v-if="isLink && !activityGallery" 
            :ogData="mainActivity.attachments.og" 
        />
        <div v-if="isYoutubeOg" v-html="youtubeLinks(mainActivity.attachments.og.url)" class="pb-3">
        </div>
        <gallery v-if="activityGallery && containerOkay && deviceInfo && deviceInfo.platform == 'web'" :isIntersecting="isIntersecting" :modal="modal" :containerId="modal ? 'activityModalContainer' : feedId" :imageStyle="imageStyle" :gallery="activityGallery" :videosMuted="videosMuted" @clickMute="clickMute" />
        <pinch-gallery v-if="activityGallery && containerOkay && deviceInfo && deviceInfo.platform != 'web'" 
            :galleryId="activity.id" :isIntersecting="isIntersecting" :modal="modal" :containerId="modal ? 'activityModalContainer' : feedId" :gallery="activityGallery" :videosMuted="videosMuted" 
            @clickMute="clickMute" 
        />
    </div>    
</template>

<script>
import BuildCard from './BuildCard'
import EventCard from './EventCard'
import Gallery from './Gallery'
import LinkCard from './LinkCard'
import PinchGallery from '@/shared/PinchGallery'

export default {
    props: ['activity', 'feedId', 'modal', 'showMoreTrigger', 'videosMuted', 'queryId', 'position', 'isIntersecting'],
    components: {
        BuildCard,
        EventCard,
        Gallery,
        LinkCard,
        PinchGallery
    },
    data() {
        return {
            containerOkay: false,
            hasYoutubeLinks: false,
            showMore: false,
            showMoreLinkClicked: false
        }
    },
    computed: {
        activityGallery() {
            if (!this.mainActivity.attachments || !this.mainActivity.attachments.images || !this.mainActivity.attachments.images.length) {
                return null
            }

            if (this.mainActivity.attachments.images[0].type) {
                return this.mainActivity.attachments.images
            }

            return this.mainActivity.attachments.images
                .map((image) => {
                    return {
                        type: 'image',
                        url: image
                    }
                })
        },
        activityText() {
            return typeof this.mainActivity.object === 'string' ? this.mainActivity.object : ''
        },
        computedBuildId() {
            return this?.mainActivity?.meta?.build?.id
        },
        imageStyle() {
            return this?.mainActivity?.meta?.image_style
        },
        isBuild() {
            return this.og && this.og.type == 'website' && this.og.url && this.og.url.includes('buildz') && this.og.url.includes('/build/') && !this.og.videos
        },
        isEvent() {
            return this.feedId && !this.feedId.includes('event:') && this.og && this.og.type == 'website' && this.og.url && this.og.url.includes('buildz') && this.og.url.includes('/event/') && !this.og.videos
        },
        isLink() {
            return this.og && this.og.type == 'website' && this.og.url && (!this.og.url.includes('buildz') || (!this.og.url.includes('/build/') && !this.og.url.includes('/event/') && !this.og.url.includes('/group/'))) && !this.og.videos
        },
        isRepost() {
            return Boolean(this.activity?.object?.object)
        },
        isYoutubeOg() {
            return this.og && !this.og.type == 'website' && this.og.url && !this.og.url.includes('buildz') && !this.og.url.includes('/build/') && !this.og.url.includes('/event/') && !this.og.url.includes('/group/') && this.og.videos
        },
        mainActivity() {
            return this.isRepost ? this.activity?.object : this.activity
        },
        needsShowMore() {
            return this.elGet(this.textContainerId) && this.elGet(this.textContainerId).scrollHeight > this.elGet(this.textContainerId).clientHeight
        },
        og() {
            return this.mainActivity && this.mainActivity.attachments && this.mainActivity.attachments.og ? this.mainActivity.attachments.og : null
        },
        renderText() {
            let myText = this.textRenderer(this.nlToBrSpace(this.activityText)).trim()
            return myText.length ? myText : null
        },
        renderYoutube() {
            return this.youtubeLinks(this.nlToBrSpace(this.activityText))
        },
        showActivityText() {
            return Boolean(this.activityText.trim().length > 0)
        },
        showShowMore() {
            return Boolean(!this.modal && this.elGet(this.textContainerId) && this.elGet(this.textContainerId).scrollHeight > this.elGet(this.textContainerId).clientHeight)
        },
        textContainerId() {
            return `${this.feedId}-${this.activity.id}`
        }
    },
    watch: {
        showMoreTrigger: {
            handler(val) {
                this.setShowMore()
            }
        }
    },
    mounted() {
        this.hasYoutubeLinks = this.renderYoutube && this.renderYoutube.length ? true : false

        this.$nextTick(() => {
            this.setShowMore()
        })

        setTimeout(() => {
            this.setShowMore()
        }, 500)

        this.checkContainerOkay()
    },
    methods: {
        checkContainerOkay() {
            // if (!this.modal && !this.feedId) {
            //     this.containerOkay = true
            // }
            // else {
            //     let containerWidth = null
            //     let containerId = this.modal ? 'activityModalContainer' : this.feedId

            //     if (containerId) {
            //         let myContainer = this.getRect(containerId)
            //         containerWidth = Math.round(myContainer.width)
            //     }

            //     this.containerOkay = Boolean(containerWidth && containerWidth > 0)
            // }
            this.containerOkay = true
        },
        clickMute(inMute) {
            this.$emit('clickMute', inMute)
        },
        clickShowMore() {
            this.showMoreLinkClicked = true
        },
        setShowMore() {
            this.showMore = !this.modal && this.elGet(this.textContainerId) && this.elGet(this.textContainerId).scrollHeight > this.elGet(this.textContainerId).clientHeight
        }
    }
}
</script>