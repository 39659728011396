<template>
    <div class="overflow-hidden w-full" :class="{ 'white-transparent': !whiteLabel || whiteLabelDark, 'black-transparent': whiteLabel && whiteLabelLight }" style="margin-bottom: 1px;">
        <div class="w-full flex items-stretch">
            <div class="w-1/3 flex items-center p-1" :class="{ 'white-transparent': !whiteLabel || whiteLabelDark, 'black-transparent': whiteLabel && whiteLabelLight }">
                <div class="overflow-hidden" :class="{ 'w-full': showImage, 'h-0 w-0': !showImage || !productImage }">
                    <cached-image @load="srcLoaded = true" imageClasses="w-full h-full object-scale-down" :imageSrc="transformUrl(productImage, ['optimize', 'md', 'q80'])" loading="lazy" />
                </div>
                <div class="overflow-hidden flex items-center justify-center opacity-60" :class="{ 'w-full h-full': !showImage, 'h-0 w-0': showImage }">
                    <fa-icon icon="bag-shopping" type="fas" class="h-28 w-28" />
                </div>
            </div>
            <div class="w-2/3 relative px-1 pt-2">
                <div class="py-2 px-1 text-sm w-full flex flex-col justify-between items-center h-full">
                    <div class="leading-tight text-center w-full mb-4">
                        <div class="font-bold">
                            {{ line.product_label }}
                        </div>
                        <div v-if="line.variant_description || line.add_on_description" class="text-xs">
                            {{ line.variant_description }} {{ line.add_on_description }}
                        </div>
                    </div>
                    <div class="flex items-center justify-between w-full flex-col">
                        <div class="flex items-center justify-between whitespace-nowrap">
                            <div class="mr-2">
                                Unit Price
                            </div>
                            <div class="text-sm leading-tight" :class="{ 'line-through': discountedPrice, 'font-semibold': !discountedPrice }">
                                {{ displayPrice(currency, linePrice) }} 
                            </div>
                            <div v-if="discountedPrice" class="ml-2 text-sm font-semibold leading-tight">
                                {{ displayPrice(currency, discountedPrice) }} 
                            </div>
                        </div>
                        <div class="flex items-center justify-between whitespace-nowrap mt-4">
                            <div class="mr-2">
                                Quantity
                            </div>
                            <div class="text-sm font-semibold leading-tight">
                                {{ line.product_qty }} 
                            </div>
                        </div>
                        <div class="flex items-center justify-between whitespace-nowrap mt-4">
                            <div class="mr-2">
                                Sub Total
                            </div>
                            <div class="text-sm leading-tight" :class="{ 'line-through': discountedPrice, 'font-semibold': !discountedPrice }">
                                {{ displayPrice(currency, linePrice * line.product_qty) }} 
                            </div>
                            <div v-if="discountedPrice" class="ml-2 text-sm font-semibold leading-tight">
                                {{ displayPrice(currency, discountedPrice * line.product_qty) }} 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="groupBuy && showGroupBuyDetails" class="mt-1">
            <group-buy-tiers
                :hideHeading="true" :product="gbProduct" :sortedPricingLevels="sortedPricingLevels"
            />
        </div>
    </div>
</template>

<script>
import GroupBuyTiers from '@/shared/Marketplace/GroupBuyTiers'

export default {
    props: [
        'currency', 
        'groupBuy', 
        'line', 
        'product',
        'sale', 
        'showGroupBuyDetails',
        'sortedPricingLevels'
    ],
    components: {
        GroupBuyTiers
    },
    data() {
        return {
            showImage: false,
            showMoreLinkClicked: false,
            srcLoaded: false
        }
    },
    computed: {
        discountedPrice() {
            if (!this.sale || !this.sale.data) {
                return 0
            }

            if ((!this.sale.data.discountCodes || !this.sale.data.discountCodes.length) && !this.globalDiscountOkay) {
                return 0
            }

            let myFullPrice = this?.line?.product_full_price ?? 0
            let myDisPrice = this?.line?.product_price ?? myFullPrice

            if (this.sale.data.discountCodes && this.sale.data.discountCodes.length) {
                this.sale.data.discountCodes.forEach((myCode) => {
                    myDisPrice = myDisPrice - ((myCode.percentage / 100) * (this?.line?.product_price ?? myFullPrice))
                })
            }

            if (this.globalDiscountOkay) {
                myDisPrice = myDisPrice - ((this.globalDiscount.percentage / 100) * (this?.line?.product_price ?? myFullPrice))
            }

            return myDisPrice
        },
        gbProduct() {
            let myFullPrice = this?.line?.product_full_price ?? 0

            return {
                price: {
                    currency: this.currency,
                    amount: myFullPrice
                },
                group_buy_id: this?.groupBuy?.id ?? null
            }
        },
        globalDiscount() {
            return this?.sale?.data?.globalDiscount ?? null
        },
        globalDiscountOkay() {
            return Boolean(this.globalDiscount && this.globalDiscount.enabled && this.globalDiscount.label && this.globalDiscount.percentage && this.globalDiscount.percentage > 0 && this.globalDiscount.percentage <= 100)
        },
        linePrice() {
            if (!this?.sale?.data) {
                return 0
            }

            let myFullPrice = this?.line?.product_full_price ?? 0

            return this?.line?.product_price ?? myFullPrice
        },
        productImage() {
            if (this.product && this.product.gallery && this.product.gallery.length) {
                let myGallery = this.product.gallery.filter((myMedia) => myMedia.url)
                return myGallery && myGallery.length ? myGallery[0].url : null
            }

            if (this.product && this.product.image && this.product.image.url) {
                return this.product.image.url
            }

            return null
        }
    },
    watch: {
        srcLoaded: {
            handler(val) {
                if (val && this.productImage) {
                    this.showImage = true
                }
            }
        }
    },
    methods: {
        clickAddToBuild() {
            this.setStateData([
                { shareIntentProductId: this.product.id },
                { shareIntentURL:  `https://buildz.pro/product/${this.product.id}` },
                { showShareIntentModal: true },
                { shareIntentProduct: this.product }
            ])
        }
    }
}
</script>