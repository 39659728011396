<template>
    <div v-if="formLoaded" class="w-full">
        <media-panel v-if="imagesArray"
            fieldLabel="Media" :imagesArray="imagesArray" :imagesOnly="false" :maxFiles="10" :multiple="true"
            @saveMedia="saveLocalGallery" @updateOfflineMedia="updateOfflineMedia"
        />
    </div>
</template>

<script>
export default {
    props: [
        'gallery'
    ],
    data() {
        return {
            formLoaded: false
        }
    },
    computed: {
        imagesArray() {
            let myGallery = []

            this.gallery.forEach((myImage) => {
                myGallery.push(myImage)
            })

            return myGallery
        }
    },
    mounted() {
        this.formLoaded = true
    },
    methods: {
        saveLocalGallery(inGallery) {
            this.$emit('saveLocalGallery', inGallery)
        },
        updateOfflineMedia(inGallery) {
            this.$emit('updateOfflineMedia', inGallery)
        }
    }
}
</script>