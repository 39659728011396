import { connect } from 'getstream'
import anchorme from 'anchorme'
import twitter from 'twitter-text'

const feedMixins = {
    methods: {
        async checkActivities(inFeedData, inFeed, inWrite = false) {
            let newResults = []
            let myActivities = this.objCopy(this?.activities || [])

            for (const activity of inFeedData?.results || []) {
                let thisActivity = null

                if (activity.target && activity.target != activity.id && (!activity?.meta?.post_type || activity.meta.post_type != "no_children")) {
                    let myFeed = await this.initFeed('user', activity.meta.activity_owner , null, false)

                    if (myFeed) {
                        thisActivity = await this.getSingleActivity(this.feeds[myFeed], activity.target)
                        thisActivity = thisActivity?.results?.length ? thisActivity.results[0] : null

                        if (thisActivity && (activity?.meta?.user_action ?? false)) {
                            if (!thisActivity.meta) {
                                thisActivity.meta = []
                            }

                            thisActivity.meta.user_action = activity?.meta?.user_action
                            thisActivity.meta.user_action_id = activity?.meta?.user_action_id
                            thisActivity.meta.reaction_id = activity?.meta.reaction_id
                            thisActivity.meta.comment_id = activity?.meta.comment_id
                        }
                    }
                }
                else {
                    thisActivity = await activity
                    
                    if (thisActivity?.meta?.post_type == "no_children") {
                        thisActivity.id = thisActivity.target
                    }
                }

                let hideMe = false

                if (this.loggedUser) {
                    // hide placeholder if I already follow the original user or the placeholder is mine
                    if (thisActivity?.meta?.user_action_id && thisActivity?.meta?.original_actor?.data?.id && (this.amIFollowing(thisActivity.meta.original_actor.data.id) || this.loggedUserId == parseInt(thisActivity.meta.original_actor.data.id))) {
                        hideMe = true
                    }

                    // hide placeholder if Ive blocked the original user or the user who actioned the placeholder
                    if (!hideMe && thisActivity?.meta?.user_action_id && thisActivity?.meta?.original_actor?.data?.id && (this.amIBlocking(thisActivity.meta.original_actor.data.id) || this.amIBlocking(thisActivity.meta?.user_action_id))) {
                        hideMe = true
                    }

                    // hide post if Ive blocked the user
                    if (!hideMe && this.amIBlocking(thisActivity?.actor?.data?.id)) {
                        hideMe = true
                    }

                    // temp hide all placeholders
                    if (thisActivity?.meta?.user_action_id) {
                        hideMe = true
                    }
                }

                if (!hideMe) {
                    if (thisActivity && !this.loggedUser || (thisActivity?.meta?.user_action_id != this.loggedUserId && (!thisActivity?.meta?.user_action_id || (thisActivity?.meta?.user_action_id != thisActivity?.meta?.activity_owner)) && !newResults.map(myActivity => myActivity.id).includes(thisActivity.id) && (!myActivities.map(myActivity => myActivity.id).includes(thisActivity.id)))) { // && !(this.viewedBuild?.deleted_posts?.includes(parseInt(thisActivity.foreign_id))) && !(this.viewedGarage?.deleted_posts?.includes(parseInt(thisActivity.foreign_id))))) {
                        newResults.push(thisActivity)
                    }
                    else if (thisActivity && !thisActivity?.meta?.original_id) {
                        let myNewResults = this.objCopy(newResults)

                        if (myNewResults.map(myActivity => myActivity.id).includes(thisActivity.id)) {
                            newResults = myNewResults.map((myActivity) => {
                                if (myActivity.id == thisActivity.id) {
                                    return thisActivity
                                }

                                return myActivity
                            })
                        }
                        else if (myActivities.map(myActivity => myActivity.id).includes(thisActivity.id)) {
                            myActivities = myActivities.map((myActivity) => {
                                if (myActivity?.id == thisActivity?.id) {
                                    return thisActivity
                                }

                                return myActivity
                            })
                        }
                    }
                }
            }

            this.updateActivities(myActivities, this.feed_id)
            let newFeedData = this.objCopy(inFeedData)
            
            if (newFeedData) {
                newFeedData.results = this.objCopy(newResults)
            } else {
                newFeedData = {}
            }

            newFeedData.postsCount = inFeedData?.results?.length || newFeedData?.results?.length
            return newFeedData
        },
        deleteFeed(inFeedId) {
            if (this.feeds) {
                let { [inFeedId]: _feed, ...myFeeds } = this.feeds

                this.setStateData([
                    { feeds: myFeeds }
                ])

                let { [inFeedId]: _feedActivities, ...myFeedActivities } = this.feedActivities || {}
                
                this.setStateData([
                    { feedActivities: myFeedActivities }
                ])
            }
        },
        async getActivities(inFeed, inOffset, inRpp, inMarkSeen = false, inWrite = false) {
            let feedData = null

            await inFeed?.get({
                limit: inRpp, 
                offset: inOffset, 
                enrich: true, 
                reactions: { 
                    own: true, 
                    counts: true, 
                    recent: true,
                    child: true
                },
                mark_seen: inMarkSeen  
            })
            .then((results) => {
                feedData = results
            })
            .catch((error) => {
                console.log(error)
            })

            feedData = await this.checkActivities(feedData, inFeed, inWrite)
            return feedData
        },
        async getChildReactions(inReaction, inLT) {
            let reactionData = null

            await this.streamClient.reactions?.filter({
                limit: 5,
                reaction_id: inReaction.id,
                kind: 'comment',
                id_lt: inLT
            })
                .then((results) => {
                    reactionData = results
                })
                .catch((error) => {
                    console.log(error)
                })

            return reactionData
        },
        async getLikes(inActivityId) {
            let likeData = null

            await this.streamClient.reactions?.filter({
                limit: 100,
                activity_id: inActivityId,
                kind: 'like',
            })
                .then((results) => {
                    likeData = results
                })
                .catch((error) => {
                    console.log(error)
                })

            return likeData
        },
        async getReactions(inLT) {
            let reactionData = null

            await this.streamClient?.reactions?.filter({
                limit: 5, 
                activity_id: this.activityToShow?.id, 
                kind: 'postReaction',
                id_lt: inLT,
                withOwnChildren: true
            })
            .then((results) => {
                reactionData = results
            })
            .catch((error) => {
                console.log(error)
            })

            return reactionData
        },
        async getSingleActivity(inFeed, inActivityId = this.activityToShow?.id) {
            let feedData = null

            await inFeed?.get({
                limit: 1, 
                id_gte: inActivityId,
                id_lte: inActivityId,
                enrich: true, 
                reactions: { 
                    own: true, 
                    counts: true, 
                    recent: true 
                }
            })
            .then((results) => {
                feedData = results
            })
            .catch((error) => {
                console.log(error)
            })
            

            return feedData
        },
        async getSinglePostReaction(inReaction, inActivityId) {
            let feedData = null

            await this.streamClient?.reactions?.filter({
                limit: 1, 
                activity_id: inActivityId ? inActivityId : this.activityToShow?.id,
                id_gte: inReaction.id ? inReaction.id : inReaction,
                kind: 'postReaction',
                withOwnChildren: true
            })
            .then((results) => {
                feedData = results
            })
            .catch((error) => {
                console.log(error)
            })

            return feedData
        },
        getTextLinks(text) {
            if (text === undefined) return

            return text
                .split(' ')
                .filter(word => word.toLowerCase().indexOf('youtube') == -1 && word.toLowerCase().indexOf('youtu.be') == -1)
                .map((word, i) => {
                    if (anchorme.validate.url(word)) {
                        return word
                    }

                    return ''
                })
                .filter(word => word.length)
        },
        async initClient() {
            if (!this.streamClient) {
                let myStreamClient = await connect(this.getStreamKey, this.streamToken, this.getStreamAppId)
                
                this.setStreamClient(myStreamClient)
            }
        },
        async initFeed(inFeedGroup, inFeedId, inFeedName = null, inSubscribe = true) {
            const myFeed = await this.streamClient?.feed(inFeedGroup, inFeedId)

            if (myFeed) {
                if (inSubscribe && !this.subscribedFeeds.includes(myFeed.id)) {
                    myFeed.subscribe(this.subscribeNewFeedData).then(this.subscribeSuccess(myFeed.id), this.subscribeFail)
                    let mySubscribedFeeds = this.objCopy(this.subscribedFeeds)
                    mySubscribedFeeds.push(myFeed.id)
                    
                    this.replaceStateDataArray([
                        { subscribedFeeds: mySubscribedFeeds }
                    ])
                }

                if (!this.feeds) {
                    this.setStateData([
                        { feeds: { [inFeedName ? inFeedName : myFeed.id]: myFeed } }
                    ])
                }
                else {
                    this.setStateData([
                        { feeds: { ...this.feeds, [inFeedName ? inFeedName : myFeed.id]: myFeed } }
                    ])
                }

                return myFeed.id
            }
            else {
                this.pageMessage('error', 'Error retrieving feed')
            }
        },
        subscribeFail(error) {
            console.log(error)
        },
        async subscribeNewFeedData(data) {
            if (this.feeds && data.feed && this.feedActivities && this.feedActivities[data.feed] && !data.feed.startsWith('notification')) {
                if (data?.new?.length) {
                    let myHasNewPosts = this.objCopy(this.eventHasNewPosts)
                    myHasNewPosts = { ...myHasNewPosts, ...data.feed }
                    
                    this.replaceStateData([
                        { eventHasNewPosts: myHasNewPosts }
                    ])
                }
            }
        },
        subscribeSuccess(feedId) {
            console.log(`now listening to changes in realtime for feed ${feedId}`)
        },
        textRenderer(text) {
            if (text === undefined) return

            return text
                .split(' ')
                .map((word, i) => {
                    if (anchorme.validate.url(word) || anchorme.validate.email(word)) {
                        const link = anchorme({input: word, options: {
                            attributes: {
                                target: word.toLowerCase().indexOf('buildz.pro') == -1 && word.toLowerCase().indexOf('buildz.test') == -1 ? "_blank" : "",
                                class: "text-orange-400 hover:underline",
                            }
                        }})

                        // if (link.toLowerCase().indexOf('youtube') != -1) {
                        //     return ''
                        // }

                        return link
                    }

                    if (word.includes('@')) {
                        const mention = twitter?.extractMentions(word)

                        if (!mention?.length) {
                            return word
                        }

                        return `<a href="/garage/${mention[0]}" class="text-orange-400 hover:underline">@${mention[0]}</a>`
                    }
                    else if (word.includes('#')) {
                        const hashtag = twitter.extractHashtags(word)

                        if (!hashtag.length) {
                            return word
                        }

                        return `<a href="/hashtag/${hashtag[0]}" class="text-orange-400 hover:underline">#${hashtag[0]}</a>`
                    }
                    else if (word.includes('+')) {
                        const build = [word]
                        let myBuild = build[0].split("+")

                        if (myBuild && myBuild.length > 1) {
                            return `<a href="/build/${myBuild[1]}" class="text-orange-400 hover:underline">${build[0]}</a>`
                        }
                    }
                    else if (word.includes('bz\/')) {
                        const group = [word]
                        let myGroup = group[0].split("bz\/")

                        if (myGroup && myGroup.length > 1) {
                            return `<a href="/community/${myGroup[1]}" class="text-orange-400 hover:underline">${group[0]}</a>`
                        }
                    }

                    return word
                })
                .join(' ')
        },
        youtubeLinks(text, inAutoplay, inLoop, inOrientation, inFullWidth) {
            if (text === undefined) return

            let myParams = ""
            let mySize = `width="1280" height="680"`
            let myPadding = '56.25%'

            if (inAutoplay) {
                myParams = `${myParams}&autoplay=1&mute=1`

                if (inLoop) {
                    myParams = `${myParams}&loop=1`
                }
            }
            else {
                if (inLoop) {
                    myParams = `${myParams}?loop=1`
                }
            }

            if (inOrientation == "portrait") {
                mySize = `width="680" height="1280"`
                myPadding = this.isDesktopWidth && !inFullWidth ? '56.25%' : '160%'
            }

            return text
                .split(' ')
                .filter(word => word.toLowerCase().indexOf('youtube') != -1 || word.toLowerCase().indexOf('youtu.be') != -1)
                .map((word, i) => {
                    if (anchorme.validate.url(word)) {
                        word = word.split('&')[0]
                        
                        const link = anchorme({
                            input: word, options: {
                                specialTransform: [
                                    {
                                        test: /youtube\.com\/watch\?v\=/,
                                        transform: str =>
                                            `<div class="iframeBlock"><div class="max-w-full h-auto relative" style="padding-bottom: ${myPadding}"><iframe class="absolute top-0 left-0 w-full h-full" src="https://www.youtube.com/embed/${str.replace(
                                                /.*watch\?v\=(.*)$/,
                                                "$1"
                                            )}?playlist=${str.replace(
                                                /.*watch\?v\=(.*)$/,
                                                "$1"
                                            )}${myParams}" ${mySize} /></div></div>`
                                    },
                                    {
                                        test: /youtube\.com\/shorts\//,
                                        transform: str =>
                                            `<div class="iframeBlock"><div class="h-full w-full relative" style="padding-bottom: ${myPadding}"><iframe class="absolute top-0 left-0 w-full h-full" src="https://www.youtube.com/embed/${str.replace(
                                                /.*youtube\.com\/shorts\/(.*)$/,
                                                "$1"
                                            )}?playlist=${str.replace(
                                                /.*youtube\.com\/shorts\/(.*)$/,
                                                "$1"
                                            )}${myParams}" ${mySize} /></div></div>`
                                    },
                                    {
                                        test: /youtu\.be\//,
                                        transform: str =>
                                            `<div class="iframeBlock"><div class="max-w-full h-auto relative" style="padding-bottom: ${myPadding}"><iframe class="absolute top-0 left-0 w-full h-full" src="https://www.youtube.com/embed/${str.replace(
                                                /.*youtu\.be\/(.*)$/,
                                                "$1"
                                            )}?playlist=${str.replace(
                                                /.*youtu\.be\/(.*)$/,
                                                "$1"
                                            )}${myParams}" ${mySize} /></div></div>`
                                    },
                                ]
                            }
                        })

                        return link
                    }

                    return ''
                })
                .join('')
        }
    }
}

export default feedMixins
