const tutorialMixins = {
    computed: {

    },
    methods: {
        closeTutorialGallery() {
            this.setStateData([
                { tutorialGallery: null },
                { tutorialName: null },
                { showTutorialGallery: false }
            ])
        },
        isValidTutorial(inIdentifier) {
            return this.userTutorials && this.userTutorials[inIdentifier] && this.userTutorials[inIdentifier].enabled
        },
        openTutorialGallery(inIdentifier) {
            if (this.userTutorials && this.userTutorials[inIdentifier] && this.userTutorials[inIdentifier].enabled) {
                let myGallery = []

                this.userTutorials[inIdentifier].gallery.forEach((media) => {
                    let myType = media.includes('.mp4') ? 'video' : 'image'

                    myGallery.push(
                        {
                            url: media,
                            type: myType
                        }
                    )
                })

                this.setStateData([
                    { tutorialName: inIdentifier },
                    { tutorialGallery: myGallery },
                    { showTutorialGallery: true }
                ])
            }
        }
    }
}

export default tutorialMixins