import { Share } from '@capacitor/share'

const shareMixins = {
    methods: {
        async nativeShare(inData) {
            await Share.share(inData);
        }
    }
}

export default shareMixins