const onboardingMixins = {
    computed: {
        hasLoggedIn() {
            return this.loggedUser && this.loggedUser.has_logged_in
        },
        hasLoggedInApp() {
            return this.loggedUser && this.loggedUser.has_logged_in_app
        },
        hasSelectedCategories() {
            return Boolean(this.loggedUser?.has_selected_categories)
        }
    },
    methods: {
        awardReviewBadge() {
            setTimeout(() => {
                buildzAxios?.post(this.getRoute('onboardingAwardReviewBadge'))
                    .then((response) => {

                    })
                    .catch((error) => {

                    })
            }, 60000)
        },
        setLoggedIn() {
            buildzAxios?.post(this.getRoute('onboardingLoggedIn'))
                .then((response) => {
                    let myUser = this.objCopy(this.loggedUser)
                    myUser.has_logged_in = true

                    if (this.isApp) {
                        myUser.has_logged_in_app = true
                    }

                    this.setStateData([
                        { loggedUser: myUser }
                    ])

                    this.setStorage(`user_data_${localStorage.getItem('token')}`, JSON.stringify(myUser))
                })
                .catch((error) => {

                })
        },
        setReachedLevelTwo() {
            buildzAxios?.post(this.getRoute('onboardingLevelTwo'))
                .then((response) => {
                    let myUser = this.objCopy(this.loggedUser)
                    myUser[`has_level_two`] = true
                    
                    this.setStateData([
                        { loggedUser: myUser }
                    ])
                })
                .catch((error) => {

                })
        },
        setSeenHelp(inHelp) {
            buildzAxios?.post(this.getRoute('onboardingSeenHelp'), {
                type: inHelp
            })
                .then((response) => {
                    let myUser = this.objCopy(this.loggedUser)
                    myUser[`has_seen_${inHelp}_help`] = true
                    
                    this.setStateData([
                        { loggedUser: myUser }
                    ])
                })
                .catch((error) => {

                })
        },
        setSwipedHelp() {
            buildzAxios?.post(this.getRoute('onboardingSwipedHelp'))
                .then((response) => {
                    let myUser = this.objCopy(this.loggedUser)
                    myUser[`has_swiped_help`] = true
                    
                    this.setStateData([
                        { loggedUser: myUser }
                    ])
                })
                .catch((error) => {

                })
        },
        setUserCategories(inBuildTypes, inSubCategories) {
            buildzAxios?.post(this.getRoute('onboardingUserCategories'), {
                buildTypes: inBuildTypes,
                subCategories: inSubCategories
            })
            .then((response) => {
                this.setStateData([
                    { loggedUser: response.data.user }
                ])
            })
            .catch((error) => {

            })
        },
        setUserCommunities(inGroupIds) {
            buildzAxios?.post(this.getRoute('onboardingUserCommunities'), {
                groupIds: inGroupIds
            })
            .then((response) => {
                this.setStateData([
                    { loggedUser: response.data.user }
                ])
            })
            .catch((error) => {

            })
        }
    }
}

export default onboardingMixins