<template>
    <div v-if="sortedPricingLevels.length">
            <div v-if="!hideHeading" class="font-bold text-center pb-2 mt-4" :class="{ [myFontSize]: true }">
                Group Buy Tiers
            </div>
            <template v-for="(level, index) in sortedPricingLevels" :key="index">
                <div class="p-2 flex items-center justify-between" :class="{ 'white-transparent': !whiteLabel || whiteLabelDark, 'black-transparent': whiteLabel && whiteLabelLight, [myFontSize]: true }">
                    <div class="flex items-center justify-start">
                        <div class="red-bubble-big mr-2">
                            {{ index + 1 }}
                        </div>
                        <div class="font-bold text-sm">
                            {{ level.qty }} units confirmed
                        </div>
                    </div>
                    <div class="flex items-center font-bold text-sm">
                        <div class="whitespace-nowrap">
                            {{ displayPrice(product.price.currency, getGroupBuyTierPrice(index, product.price.amount, sortedPricingLevels)) }}
                        </div>
                        <div v-if="sortedPricingLevels[index].discount > 0" class="bg-red-500 rounded px-1 whitespace-nowrap text-white ml-2">
                            -{{ sortedPricingLevels[index].discount }}%
                        </div>
                    </div>
                </div>
            </template>
        </div>
</template>

<script>
export default {
    props: [
        'groupBuy',
        'hideHeading', 
        'fontSize',
        'product', 
        'sortedPricingLevels'
    ],
    computed: {
        myFontSize() {
            return this.fontSize ? this.fontSize : 'text-lg'
        }
    }
}
</script>