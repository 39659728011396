<template>
    <div v-if="formLoaded" class="w-full">
        <input-panel 
            fieldFormName="label" fieldLabel="Invoice Label" fieldPlaceholder="Invoice Label" 
            :fieldRequired="true" fieldType="text" :fieldValue="dealForm.label"
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldFormName="timerLabel" fieldLabel="Timer Label" fieldPlaceholder="Timer Label" 
            :fieldRequired="true" fieldType="text" :fieldValue="dealForm.timerLabel"
            @fieldInput="updateFormValue"
        />
        <select-panel 
            :fieldData="yesNoData" fieldFormName="hideTimer" fieldLabel="Hide Initial Timer"
            :fieldValue="dealForm.hideTimer" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel 
            :fieldData="yesNoData" fieldFormName="infiniteInitial" fieldLabel="Infinite Initial Timer"
            :fieldValue="dealForm.infiniteInitial" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <input-panel 
            fieldFormName="initialTime" fieldLabel="Initial Time (minutes)" fieldPlaceholder="Initial Time" 
            :fieldRequired="true" fieldType="number" :fieldValue="dealForm.initialTime"
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldFormName="initialPauseTime" fieldLabel="Initial Pause Time (seconds)" fieldPlaceholder="Initial Pause Time" 
            :fieldRequired="true" fieldType="number" :fieldValue="dealForm.initialPauseTime"
            @fieldInput="updateFormValue"
        />
        <select-panel
            :fieldData="triggerPointData" fieldFormName="initialTriggerPoint" fieldLabel="Initial Trigger Point" 
            :fieldValue="dealForm.initialTriggerPoint" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <input-panel 
            fieldFormName="lockoutTime" fieldLabel="Lockout Time (minutes)" fieldPlaceholder="Lockout Time" 
            :fieldRequired="false" fieldType="number" :fieldValue="dealForm.lockoutTime"
            @fieldInput="updateFormValue"
        />
        <select-panel 
            :fieldData="yesNoData" fieldFormName="hideSecondTimer" fieldLabel="Hide Second Timer"
            :fieldValue="dealForm.hideSecondTimer" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel 
            :fieldData="yesNoData" fieldFormName="infiniteSecond" fieldLabel="Infinite Second Timer"
            :fieldValue="dealForm.infiniteSecond" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <input-panel 
            fieldFormName="secondTime" fieldLabel="Second Time (minutes)" fieldPlaceholder="Second Time" 
            :fieldRequired="false" fieldType="number" :fieldValue="dealForm.secondTime"
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldFormName="secondPauseTime" fieldLabel="Second Pause Time (seconds)" fieldPlaceholder="Second Pause Time" 
            :fieldRequired="false" fieldType="number" :fieldValue="dealForm.secondPauseTime"
            @fieldInput="updateFormValue"
        />
        <select-panel
            :fieldData="triggerPointData" fieldFormName="secondTriggerPoint" fieldLabel="Second Trigger Point" 
            :fieldValue="dealForm.secondTriggerPoint" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <input-panel 
            fieldFormName="retriggerPercentage" fieldLabel="Retrigger Percentage" fieldPlaceholder="Retrigger Percentage" 
            :fieldRequired="false" fieldType="number" :fieldValue="dealForm.retriggerPercentage"
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldFormName="secondaryRetriggerPercentage" fieldLabel="Secondary Retrigger Percentage" fieldPlaceholder="Secondary Retrigger Percentage" 
            :fieldRequired="false" fieldType="number" :fieldValue="dealForm.secondaryRetriggerPercentage"
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldFormName="secondaryRetriggerVisits" fieldLabel="Secondary Retrigger Visits" fieldPlaceholder="Secondary Retrigger Visits" 
            :fieldRequired="false" fieldType="number" :fieldValue="dealForm.secondaryRetriggerVisits"
            @fieldInput="updateFormValue"
        />
        <select-panel 
            :fieldData="yesNoData" fieldFormName="enabled" fieldLabel="Enabled"
            :fieldValue="dealForm.enabled" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
    </div>
</template>

<script>
export default {
    props: [
        'timedDeals'
    ],
    data() {
        return {
            dealForm: {
                enabled: false,
                hideSecondTimer: false,
                hideTimer: false,
                infiniteInitial: false,
                infiniteSecond: false,
                initialPauseTime: null,
                initialTime: null,
                initialTriggerPoint: null,
                label: null,
                lockoutTime: null,
                retriggerPercentage: null,
                secondaryRetriggerPercentage: null,
                secondaryRetriggerVisits: null,
                secondPauseTime: null,
                secondTime: null,
                secondTriggerPoint: null,
                timerLabel: null
            },
            formLoaded: false,
            triggerPointData: {
                "Giveaway Page": "giveaway",
                "Enter Now": "enternow",
                "Select Product": "select"
            },
            validating: false
        }
    },
    mounted() {
        this.dealForm.enabled = this?.timedDeals?.enabled ?? false
        this.dealForm.hideSecondTimer = this?.timedDeals?.hideSecondTimer ?? false
        this.dealForm.hideTimer = this?.timedDeals?.hideTimer ?? false
        this.dealForm.infiniteInitial = this?.timedDeals?.infiniteInitial ?? false
        this.dealForm.infiniteSecond = this?.timedDeals?.infiniteSecond ?? false
        this.dealForm.initialPauseTime = this?.timedDeals?.initialPauseTime ?? null
        this.dealForm.initialTime = this?.timedDeals?.initialTime ?? null
        this.dealForm.initialTriggerPoint = this?.timedDeals?.initialTriggerPoint ?? null
        this.dealForm.label = this?.timedDeals?.label ?? null
        this.dealForm.lockoutTime = this?.timedDeals?.lockoutTime ?? null
        this.dealForm.retriggerPercentage = this?.timedDeals?.retriggerPercentage ?? null
        this.dealForm.secondaryRetriggerPercentage = this?.timedDeals?.secondaryRetriggerPercentage ?? null
        this.dealForm.secondaryRetriggerVisits = this?.timedDeals?.secondaryRetriggerVisits ?? null
        this.dealForm.secondPauseTime = this?.timedDeals?.secondPauseTime ?? null
        this.dealForm.secondTime = this?.timedDeals?.secondTime ?? null
        this.dealForm.secondTriggerPoint = this?.timedDeals?.secondTriggerPoint ?? null
        this.dealForm.timerLabel = this?.timedDeals?.timerLabel ?? null
        this.formLoaded = true
    },
    methods: {
        updateFormSelectValue(inField, inValue) {
            this.dealForm[inField] = inValue
            this.updateTimedDeals(true)
        },
        updateFormValue(inField, inValue) {
            this.dealForm[inField] = inValue
            this.updateTimedDeals(false)
        },
        updateTimedDeals(inSaveImmediate) {
            if (this.dealForm.initialTime < 0) {
                this.dealForm.initialTime = 0
            }

            if (this.dealForm.lockoutTime < 0) {
                this.dealForm.lockoutTime = 0
            }

            if (this.dealForm.secondTime < 0) {
                this.dealForm.secondTime = 0
            }

            if (this.dealForm.retriggerPercentage < 0 || this.dealForm.retriggerPercentage > 100) {
                this.dealForm.retriggerPercentage = 0
            }

            if (this.dealForm.secondaryRetriggerPercentage < 0 || this.dealForm.secondaryRetriggerPercentage > 100) {
                this.dealForm.secondaryRetriggerPercentage = 0
            }

            if (this.dealForm.secondaryRetriggerVisits < 0) {
                this.dealForm.secondaryRetriggerVisits = 0
            }

            // if (!this.dealForm.label || !this.dealForm.initialTime || !this.dealForm.timerLabel) {
            //     this.dealForm.enabled = false
            // }

            // if ((this.dealForm.retriggerPercentage || this.dealForm.secondTime || this.dealForm.lockoutTime) && (!this.dealForm.retriggerPercentage || !this.dealForm.secondTime || !this.dealForm.lockoutTime)) {
            //     this.dealForm.enabled = false
            // }

            // if ((this.dealForm.secondaryRetriggerPercentage || this.dealForm.secondaryRetriggerVisits) && (!this.dealForm.secondaryRetriggerPercentage || !this.dealForm.secondaryRetriggerVisits)) {
            //     this.dealForm.enabled = false
            // }

            this.$emit('updateTimedDeals', this.dealForm, inSaveImmediate)
        }
    }
}
</script>