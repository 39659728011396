<template>
    <modal class="text-white font-normal"
        :open="openConditions"
        @close="closeModal" @updateModalScrollTop="updateModalScrollTop"
    >
        <modal-background-image
            :bgImage="heroImage" :altImage="altImage"
        />
        <div class="absolute top-0 left-0 w-full" :class="{ 'pb-16': windowWidth < desktopMaxWidth, 'desktop-modal-box': windowWidth >= desktopMaxWidth }">
            <div class="relative" :class="{ 'desktop-modal': windowWidth >= desktopMaxWidth }">
                <div class="absolute left-1 top-2 flex items-center" style="z-index: 1;">
                    <div v-if="(saving && !saved) || mediaInProgress" class="ml-2 text-sm">Saving...</div>
                    <div v-if="saved" class="ml-2 text-sm">Saved.</div>
                </div>
                <slot name="main"></slot>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/shared/Modal'
import ModalBackgroundImage from '@/shared/ModalBackgroundImage'

export default {
    props: [
        'altImage',
        'bgImage',
        'openConditions'
    ],
    components: {
        Modal,
        ModalBackgroundImage
    },
    computed: {
        heroImage() {
            return this?.bgImage ?? this.defaultHero
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal')
        },
        updateModalScrollTop(inScrollTop) {
            this.$emit('updateModalScrollTop', inScrollTop)
        }
    }
}
</script>