<template>
    <div class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="localPickup" fieldLabel="Do You Offer Local Pickup?" fieldLabelClasses="text-gray-800"
            :fieldValue="sellerDataForm.localPickup" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateSellerDataFormSelectValue"
        >
            <validation-message v-if="validating && sellerDataForm.localPickup == null" 
                message="Select yes or no"
            />
        </select-panel>
        <div v-if="sellerDataForm.localPickup" class="w-full">
            <location-panel v-if="!manualPickupAddress"
                fieldId="s_pickup" fieldLabel="Local Pickup Address" fieldLabelClasses="text-gray-800"
                fieldPlaceholder="Please Type Your Local Pickup Address" :fieldRequired="true" :fieldValue="sellerDataForm.localPickupAddress.formattedAddress" :forceMobile="true" :placeTypes="['address']"
                @fieldChange="getLocalPickupData"
            >
                <validation-message v-if="validating && !sellerDataForm.localPickupAddress.formattedAddress" 
                    message="Local Pickup Address must be selected"
                />
                <div v-if="sellerDataForm.localPickupAddress.formattedAddress && !manualPickupAddress" v-html="formatMyAddress(sellerDataForm.localPickupAddress.formattedAddress)" class="pt-2 text-gray-500">
                </div>
            </location-panel>
            <div v-if="!manualPickupAddress" class="w-full px-2 py-3">
                <div @click="clickManualAddress()" class="secondary-button-base">
                    Enter address manually
                </div>
            </div>
            <div v-if="manualPickupAddress">
                <div class="py-3 px-2 text-center font-semibold text-gray-800">
                    Local Pickup Address
                </div>
                <input-panel 
                    fieldFormName="line1" fieldLabel="Line 1" fieldLabelClasses="text-gray-800" fieldPlaceholder="Line 1" :fieldRequired="true" fieldType="text" :fieldValue="sellerDataForm.localPickupAddress.address.line1" 
                    :forceMobile="true"
                    @fieldInput="updateSellerDataFormLocalAddressValue"
                >
                    <validation-message v-if="validating && !sellerDataForm.localPickupAddress.address.line1" 
                        message="Line 1 must be entered"
                    />
                </input-panel>
                <input-panel 
                    fieldFormName="line2" fieldLabel="Line 2" fieldLabelClasses="text-gray-800" fieldPlaceholder="Line 2" :fieldRequired="false" fieldType="text" :fieldValue="sellerDataForm.localPickupAddress.address.line2" 
                    :forceMobile="true"
                    @fieldInput="updateSellerDataFormLocalAddressValue"
                />
                <input-panel 
                    fieldFormName="city" fieldLabel="Suburb" fieldLabelClasses="text-gray-800" fieldPlaceholder="Suburb" :fieldRequired="true" fieldType="text" :fieldValue="sellerDataForm.localPickupAddress.address.city" 
                    :forceMobile="true"
                    @fieldInput="updateSellerDataFormLocalAddressValue"
                >
                    <validation-message v-if="validating && !sellerDataForm.localPickupAddress.address.city" 
                        message="Suburb must be entered"
                    />
                </input-panel>
                <input-panel 
                    fieldFormName="postal_code" fieldLabel="Postal Code" fieldLabelClasses="text-gray-800" fieldPlaceholder="Postal Code" :fieldRequired="true" fieldType="text" :fieldValue="sellerDataForm.localPickupAddress.address.postal_code" 
                    :forceMobile="true"
                    @fieldInput="updateSellerDataFormLocalAddressValue"
                >
                    <validation-message v-if="validating && !sellerDataForm.localPickupAddress.address.postal_code" 
                        message="Postal Code must be entered"
                    />
                </input-panel>
                <input-panel 
                    fieldFormName="state" fieldLabel="State" fieldLabelClasses="text-gray-800" fieldPlaceholder="State" :fieldRequired="true" fieldType="text" :fieldValue="sellerDataForm.localPickupAddress.address.state" 
                    :forceMobile="true"
                    @fieldInput="updateSellerDataFormLocalAddressValue"
                >
                    <validation-message v-if="validating && !sellerDataForm.localPickupAddress.address.state" 
                        message="State must be entered"
                    />
                </input-panel>
                <select-panel
                    :fieldData="countryList" fieldDataLabel="name" fieldDataValue="code" fieldLabel="Country" fieldLabelClasses="text-gray-800" fieldFormName="country" :fieldRequired="true" 
                    :fieldValue="sellerDataForm.localPickupAddress.address.country" :forceMobile="true"
                    @fieldChange="updateSellerDataFormLocalAddressValue"
                >
                    <validation-message v-if="validating && !sellerDataForm.localPickupAddress.address.country" 
                        message="Country must be selected"
                    />
                </select-panel>
                <div v-if="manualPickupAddress" class="w-full px-2 py-3">
                    <div @click="clickSearchForAddress()" class="secondary-button-base">
                        Search for address
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'manualPickupAddress',
        'sellerDataForm',
        'validating'
    ],
    methods: {
        clickManualAddress() {
            let myAddress = this.objCopy(this.sellerDataForm.localPickupAddress)
            myAddress.formattedAddress = null
            this.$emit('updateSellerDataFormSelectValue', 'localPickupAddress', this.objCopy(myAddress))
            this.$emit('updateManualPickupAddress', true)
        },
        clickSearchForAddress() {
            this.$emit('updateManualPickupAddress', false)
        },
        getLocalPickupData(addressData) {
            let myAddress = this.objCopy(this.sellerDataForm.localPickupAddress)
            myAddress.formattedAddress = addressData.formatted_address
            this.$emit('updateSellerDataFormSelectValue', 'localPickupAddress', this.objCopy(myAddress))
        },
        updateSellerDataFormLocalAddressValue(inField, inValue) {
            this.$emit('updateSellerDataFormLocalAddressValue', inField, inValue)
        },
        updateSellerDataFormSelectValue(inField, inValue) {
            this.$emit('updateSellerDataFormSelectValue', inField, inValue)
        }
    }
}
</script>