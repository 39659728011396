<template>
    <div class="mt-1 w-full white-transparent p-1">
        <div class="font-bold text-lg text-center">
            {{ messageHeading }}
        </div>
        <div class="mt-2 text-center">
            {{ saleMessage }}
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'fromCustomer',
        'saleMessage'
    ],
    computed: {
        messageHeading() {
            return this.fromCustomer ? 'Message To Seller' : 'Customer Message'
        }
    }
}
</script>