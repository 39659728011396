<template>
    <div :class="{ 'orange-transparent': validRate(), 'red-transparent': !validRate(), 'mt-px': showName, 'pb-4': !multiRate }">
        <div v-if="showName" class="flex items-center justify-between w-full pt-4" :class="{ 'pb-4': multiRate }">
            <div @click="clickEditRate()" class="pl-2 w-full text-left">
                {{ rate.customRateName }}
            </div>
            <div class="pl-2 flex items-center">
                <div v-if="type == 'general'" @click="clickEditRate()" class="mr-2 whitespace-nowrap">
                    {{ displayPrice(defaultCurrency, rate.price) }}
                </div>
                <div v-if="!editingRateId && !editingZoneId" @click="clickEditRate()" class="cursor-pointer text-gray-600">
                    <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                </div>
                <div v-if="!editingRateId && !editingZoneId" @click="clickDeleteRate()" class="cursor-pointer text-gray-600 mx-2">
                    <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                </div>
            </div>
        </div>
        <div v-if="shippingIsPriceRate(rate)" @click="clickEditRate()" class="py-1 flex items-center justify-between border-t">
            <div class="w-1/3">
                <span class="text-xs font-bold">MIN</span> {{ displayPrice(defaultCurrency, rate.minPrice) }}
            </div>
            <div class="w-1/3">
                <span class="text-xs font-bold">MAX</span> {{ maxPrice }}
            </div>
            <div class="w-1/3">
                {{ displayPrice(defaultCurrency, rate.price) }}
            </div>
        </div>
        <div v-if="shippingIsWeightRate(rate)" @click="clickEditRate()" class="py-1 flex items-center justify-between border-t">
            <div class="w-1/2">
                {{ rate.minWeight }}kg - {{ maxWeight }}
            </div>
            <div class="w-1/2">
                {{ displayPrice(defaultCurrency, rate.price) }}
            </div>
        </div>
        <div v-if="shippingIsUnitRate(rate)" @click="clickEditRate()" class="pt-2 flex items-center justify-between">
            <div class="w-1/2">
                <span class="font-semibold">First:</span> {{ displayPrice(defaultCurrency, rate.firstUnitPrice) }}
            </div>
            <div class="w-1/2">
                <span class="font-semibold">Additional:</span> {{ displayPrice(defaultCurrency, rate.additionalUnitPrice) }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'defaultCurrency',
        'editingRateId',
        'editingRateZoneId',
        'editingZoneId',
        'rate',
        'showName',
        'type',
        'zone'
    ],
    computed: {
        maxPrice() {
            return this.rate.maxPrice == 999999999 ? 'No Limit' : this.displayPrice(this.defaultCurrency, this.rate.maxPrice)
        },
        maxWeight() {
            return this.rate.maxWeight == 999999999 ? 'No Limit' : `${this.rate.maxWeight}kg`
        },
        multiRate() {
            return this.type == 'weight' || this.type == 'price'
        }
    },
    methods: {
        clickDeleteRate() {
            if (confirm("You wish to delete this Shipping Rate?")) {
                this.$emit('clickDeleteRate', this.rate)
            }
        },
        clickEditRate() {
            if (!this.editingRateId && !this.editingZoneId) {
                this.$emit('clickEditRate', this.objCopy(this.rate))
            }
        },
        validRate() {
            return this.shippingCheckValidRate(this.zone, this.type, this.rate)
        }
    }
}
</script>