<template>
    <div class="mt-1 w-full p-1 text-center" :class="{ 'white-transparent': !whiteLabel || whiteLabelDark, 'black-transparent': whiteLabel && whiteLabelLight }">
        <div class="text-lg font-bold mb-2">
            Shipping Address
        </div>
        <div>
            <div class="font-bold">
                {{ shippingDetails.name }}
            </div>
            <div v-if="shippingDetails.formattedAddress" v-html="formatMyAddress(shippingDetails.formattedAddress)">
            </div>
            <div v-else>
                <div>
                    {{ shippingDetails.address.line1 }}
                </div>
                <div>
                    {{ shippingDetails.address.line2 }}
                </div>
                <div>
                    {{ shippingDetails.address.city }} {{ shippingDetails.address.postal_code }}
                </div>
                <div>
                    {{ shippingDetails.address.state }} {{ this.coGetCountryName(shippingDetails.address.country) }}
                </div>
            </div>
            <div class="font-semibold mt-1">
                {{ shippingDetails.phoneNumber }}
            </div>
            <div class="font-semibold mt-1">
                {{ customerEmail }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'customerEmail',
        'shippingDetails'
    ]
}
</script>