<template>
    <div @click="clickLike" class="mr-2 cursor-pointer" :class="{ 'text-orange-400': iToggleLike }">
        Like {{ likeCount }}
    </div>
</template>

<script>

export default {
    props: {
        reaction: null
    },
    computed: {
        iLike() {
            return this.ownChildren && this.ownChildren.like
        },
        isLikeToggling() {
            return this.userTogglingPostReactionLikes && this.userTogglingPostReactionLikes.includes(this.reaction.id)
        },
        iToggleLike() {
            return (this.iLike && !this.isLikeToggling) || (!this.iLike && this.isLikeToggling)
        },
        likeCount() {
            return this.reaction.children_counts && this.reaction.children_counts.like ? `(${this.reaction.children_counts.like})` : ''
        },
        ownChildren() {
            return this.reaction.own_children
        },
        userLike() {
            return this.ownChildren && this.ownChildren.like ? this.ownChildren.like[0] : null
        }
    },
    methods: {
        clickLike() {
            if (!this.userCanPost) {
                this.pageMessage('info', 'You need to verify your email to like a comment')
            }
            else {
                this.appsFlyerEvent(
                    {
                        eventName: 'Clicked Reaction Like',
                        eventValue: {
                            action: this.userLike ? "Unlike" : "Like",
                            actvityId: this?.activity?.foreign_id,
                            reactionId: this?.reaction?.id
                        }
                    }
                )

                this.toggleLikePostReaction(this.reaction, this.userLike)

                if (this?.activity?.foreign_id) {
                    this.algoliaConvert(this.activity.foreign_id, 'activities', this.queryId)
                }
            }
        }
    }
}
</script>