const followMixins = {
    methods: {
        amIFollowing(inUserId) {
            return this.userIdsImFollowing && this.userIdsImFollowing.includes(parseInt(inUserId))
        },
        startToggleFollowing(ownerId) {
            var myUser = this.objCopy(this.loggedUser)
            var myTogglingFollows = []

            if (this.userTogglingFollows) {
                myTogglingFollows = this.objCopy(this.userTogglingFollows)
            }

            if (!myTogglingFollows.includes(ownerId)) {
                myTogglingFollows.push(ownerId)
            }

            if (myUser){
                myUser.togglingFollows = myTogglingFollows
            }
            
            this.setStateData([
                { loggedUser: this.objCopy(myUser) }
            ])
        },
        stopToggleFollowing(ownerId) {
            var myUser = this.objCopy(this.loggedUser)

            if (myUser && this.userTogglingFollows) {
                var myTogglingFollows = this.objCopy(this.userTogglingFollows)
                myTogglingFollows = myTogglingFollows.filter(follow => follow != ownerId)
                myUser.togglingFollows = myTogglingFollows
                
                this.setStateData([
                    { loggedUser: this.objCopy(myUser) }
                ])
            }
        },
        toggleFollowOwner(owner) {
            if (!owner) return

            if (!this.loggedUser) {
                let url = new URL(window.location.href)

                this.setStateData([
                    { afterRegisterRoute: `${url.pathname}` }
                ])

                this.routeTo('/register')
            }
            else {
                this.startToggleFollowing(parseInt(owner.id))

                if (this.amIFollowing(parseInt(owner.id))) {
                    buildzAxios?.delete(this.getRoute('userUnfollow', owner.id))
                        .then((response) => {
                            if (response.status == 200) {
                                this.setStateData([
                                    { loggedUser: response.data.user }
                                ])
                            }
                            else {
                                this.pageMessage('error', 'Error unfollowing user')
                            }
                        })
                        .catch((error) => {
                            this.pageMessage('error', 'Error unfollowing user')
                        })
                        .then(() => {
                            this.stopToggleFollowing(parseInt(owner.id))
                        })
                }
                else {
                    buildzAxios?.post(this.getRoute('userFollow', owner.id))
                        .then((response) => {
                            if (response.status == 200) {
                                if (response.data.message == "success") {
                                    this.setStateData([
                                        { loggedUser: response.data.user }
                                    ])

                                    this.appsFlyerEvent(
                                        {
                                            eventName: 'Follow User',
                                            eventValue: {
                                                userId: owner.id
                                            }
                                        }
                                    )
                                }
                                else {
                                    this.pageMessage('error', response.data.message)
                                }
                            }
                            else {
                                this.pageMessage('error', 'Error following user')
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            this.pageMessage('error', 'Error following user')
                        })
                        .then(() => {
                            this.stopToggleFollowing(parseInt(owner.id))
                        })
                }
            }
        }
    }
}

export default followMixins