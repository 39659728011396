import { Preferences } from '@capacitor/preferences'

const storageMixins = {
    methods: {
        async getStorage(inName) {
            if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                return await Preferences.get({ key: inName })
            }
            else {
                return null
            }
        },
        async removeStorage(inName) {
            await Preferences.remove({ key: inName })
        },
        async setStorage(inName, inValue) {
            if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                return await Preferences.set({
                    key: inName,
                    value: inValue,
                })
            }
        }
    }
}

export default storageMixins