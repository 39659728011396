const collaborateMixins = {
    methods: {
        approveCollaboration(inCollaborationId, inBuildId, inApproved) {
            buildzAxios?.put(this.getRoute('collaborationUpdate', inCollaborationId), {
                buildId: inBuildId,
                approved: inApproved
            })
                .then((response) => {
                    if (response.status == 200 && response.data) {
                        if (inApproved) {
                            this.pageMessage('success', 'Collaboration request approved')
                        }
                        else {
                            this.pageMessage('success', 'Collaboration request denied')
                        }

                        let myUser = this.objCopy(this.loggedUser)
                        myUser.approved_collaboration_ids = this.objCopy(response.data.approved)
                        myUser.denied_collaboration_ids = this.objCopy(response.data.denied)

                        this.setStateData([
                            { loggedUser: this.objCopy(myUser) }
                        ])
                    }
                    else {
                        this.pageMessage('error', 'Error updating collaboration')
                    }
                })
                .catch((error) => {
                    this.pageMessage('error', 'Error updating collaboration')
                })
                .then(() => {

                })
        }
    }
}

export default collaborateMixins