import { Browser } from '@capacitor/browser'

const browserMixins = {
    methods: {
        async openExternalUrl(inUrl) {
            if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                await Browser.open({ url: inUrl })
            }
            else {
                window.location.assign(inUrl)
            }
        }
    }
}

export default browserMixins