<template>
    <div v-if="activity">
        <div v-if="isSale && saleData" class="py-2 flex items-center justify-between border-b px-1">
            <div v-if="!meta.sold" class="flex flex-col">
                <div class="text-base">
                    <span v-if="saleData.price">
                        {{ saleData.price }}
                    </span> 
                    <span v-if="saleData.price && saleData.locationAddress">
                        -
                    </span>
                    <span v-if="saleData.locationAddress">
                        {{ saleData.locationAddress }}
                    </span>
                </div>
                <div v-if="saleData.name" class="text-lg font-semibold">
                    {{ saleData.name }}
                </div>
            </div>
            <div v-else class="text-lg font-semibold">
                SOLD
            </div>
            <div v-if="serverConnected && loggedUser && loggedUserId != actor.id && !meta.sold" @click="clickChat()" class="text-base text-orange-500 font-semibold text-right cursor-pointer ml-2">
                Chat
            </div>
        </div>
        <div class="flex items-center pb-2 text-xs pt-2 mx-2">
            <div v-if="!minimal && latestReactions && latestReactions.like && latestReactions.like.length && !noChildren" class="flex items-center">
                <fa-icon icon="thumbs-up" type="fas" class="h-3 w-3 mr-2" />
                <template v-for="(like, index) in latestReactions.like" :key="index">
                    <div class="like-link-box" v-if="index < 1">
                        <div class="relative" @mouseenter="addClass" @mouseleave="removeClass">
                            <div v-html="getLinkUserText(like.user.data.name, index)"></div>
                            <div class="absolute w-10 h-10 like-link border-2 border-orange-500" style="right: 5px; bottom: 15px;">
                                <user-avatar :url="like.user.data.profileImage" :rounded="true" />
                            </div>
                        </div>
                    </div>
                </template>
                <div class="relative">
                    <div @click="clickOtherLikes" class="cursor-pointer" v-html="likesCountText"></div>
                    <div v-if="likes" v-click-outside="deleteLikes" class="absolute flex flex-col p-1 overflow-y-auto border rounded-sm" style="right: 5px; bottom: 20px; max-height: 200px;">
                        <div v-for="(like, index) in likes" v-if="index > 0" :key="index" class="whitespace-nowrap">
                            {{ like.user.data.name }}
                        </div>  
                    </div>
                </div>
            </div>
            <div v-if="modal" class="ml-auto text-gray-300">
                {{ activityAge }}
            </div>
        </div>
        <div v-if="serverConnected" class="flex pt-3 px-4 pb-2" :class="{ 'justify-center': centerFooter, 'justify-between': !centerFooter }">
            <like-button v-if="!minimal && !noChildren" :activity="activity" :feedId="feedId" :queryId="queryId" class="flex items-center px-5 cursor-pointer"/>
            <div class="flex items-center px-5" :class="{'cursor-pointer': !modal}" @click="clickComment">
                <div class="flex items-center mx-auto" :class="{ 'text-orange-400': iCommented || iClickedComment }">
                    <div v-if="!minimal && !noChildren" class="flex items-center">
                        <fa-icon icon="comments" type="fas" class="h-8 w-8" />
                        <span v-if="reactionCounts && reactionCounts.postReaction" class="block ml-2 font-semibold text-lg">{{ reactionCounts.postReaction }}</span> 
                    </div>
                    <div v-else-if="minimal">
                        <fa-icon icon="eye" type="fas" class="h-8 w-8" />
                    </div>
                    <div v-else-if="!modal && noChildren">
                        <fa-icon icon="comments" type="fas" class="h-8 w-8" />
                    </div>
                </div>
            </div>
            <div v-if="isIOSApp && !minimal && !noChildren" class="px-5">
                <div class="cursor-pointer" @click="sharePost">
                    <fa-icon icon="arrow-up-from-bracket" type="fas" class="h-8 w-8" />
                </div>
            </div>
            <div v-if="isAndroidApp && !minimal && !noChildren" class="px-5">
                <div class="cursor-pointer" @click="sharePost">
                    <fa-icon icon="share-nodes" type="fas" class="h-8 w-8" />
                </div>
            </div>
            <div v-if="!isAndroidApp && !isIOSApp && !minimal && !noChildren" @click="clickCopyLink()" class="px-5 cursor-pointer">
                <span>
                    <fa-icon icon="link" type="fas" class="h-8 w-8" />
                </span>
            </div>
            <div v-if="loggedUser && isModPost && !minimal && !noChildren" @click="clickAddMod()" class="px-5 cursor-pointer">
                <span>
                    <fa-icon icon="car" type="fas" class="h-7 w-7" />
                </span>
            </div>
            <div v-if="!minimal && !noChildren" class="flex items-center px-5 cursor-pointer">
                <drop-down v-if="loggedUser" class="ml-2">
                    <div @click="clickRepost()">
                        <span>
                            <fa-icon icon="retweet" type="fas" class="h-4 w-4" />
                        </span>
                        <span>
                            Repost
                        </span>
                    </div>
                    <div v-if="isMyPost" @click="openWriteActivity(activity)">
                        <span>
                            <fa-icon icon="pen-to-square" type="fas" class="h-4 w-4" />
                        </span>
                        <span>
                            Edit
                        </span>
                    </div>
                    <div v-if="isMyPost && isSale && saleData && !meta.sold" @click="clickSold()">
                        <span>
                            <fa-icon icon="check" type="fas" class="h-4 w-4" />
                        </span>
                        <span>
                            Sold
                        </span>
                    </div>
                    <div v-if="isMyPost || buildId || garageId" @click="deleteActivity(activity.id, buildId, garageId)">
                        <span>
                            <fa-icon icon="trash-can" type="fas" class="h-4 w-4" />
                        </span>
                        <span>
                            Delete
                        </span>
                    </div>
                    <div v-if="!isMyPost" @click="clickFlag()">
                        <span>
                            <fa-icon icon="flag" type="fas" class="h-4 w-4" />
                        </span>
                        <span>
                            Report
                        </span>
                    </div>
                    <div v-if="!isMyPost && actor" @click="clickBlock()">
                        <span>
                            <fa-icon icon="ban" type="fas" class="h-4 w-4" />
                        </span>
                        <span>
                            Block
                        </span>
                    </div>
                    <div v-if="(buildId || garageId) && !pinned" @click="pinActivity(activity.id, buildId, garageId)">
                        <span>
                            <fa-icon icon="map-pin" type="fas" class="h-4 w-4" />
                        </span>
                        <span>
                            Pin
                        </span>
                    </div>
                    <div v-if="(buildId || garageId) && pinned" @click="unPinActivity(activity.id, buildId, garageId)">
                        <span>
                            <fa-icon icon="map-pin" type="fas" class="h-4 w-4" />
                        </span>
                        <span>
                            Unpin
                        </span>
                    </div>
                </drop-down>
            </div>
        </div>
        <post-reactions v-if="!noChildren && loggedUser && modal && activity && activity.reaction_counts" :queryId="queryId" />
    </div>    
</template>

<script>
import DropDown from '@/shared/DropDown'
import LikeButton from './LikeButton'
import PostReactions from './PostReactions'

export default {
    props: ['activity', 'modal', 'feedId', 'minimal', 'queryId', 'position', 'centerFooter', 'buildId', 'garageId'],
    components: {
        DropDown,
        LikeButton,
        PostReactions
    },
    data() {
        return {
            iClickedComment: false,
            likes: null
        }
    },
    computed: {
        activityAge() {
            return this.humanizedAge(this?.mainActivity?.meta?.original_time ? this.mainActivity.meta.original_time : this.mainActivity.time)
        },
        activityData() {
            return `Buildz.pro\n${this.activityUrl}\n\n#justhereforthebuildz\n#keepbuildingawesome\n`
        },
        activityGallery() {
            if (!this.mainActivity.attachments || !this.mainActivity.attachments.images || !this.mainActivity.attachments.images.length) {
                return null
            }

            if (this.mainActivity.attachments.images[0].type) {
                return this.mainActivity.attachments.images.filter(image => image.type == "image")
            }

            return this.mainActivity.attachments.images
                .map((image) => {
                    return {
                        type: 'image',
                        url: image
                    }
                })
        },
        activityText() {
            return typeof this.mainActivity.object === 'string' ? this.mainActivity.object : ''
        },
        activityUrl() {
            return `${this.appUrl}activity/${this.mainActivity.foreign_id}?session=${this.loggedUser?.short_id}`
        },
        actor() {
            return this?.mainActivity?.meta?.original_actor?.data ? this.mainActivity.meta.original_actor.data : this.mainActivity.actor.data
        },
        iCommented() {
            return this.ownReactions && this.ownReactions.postReaction
        },
        iReposted() {
            return (this.ownReactions && this.ownReactions.repost) || (this.userRepostingActivities && this.userRepostingActivities.includes(this.activity.id))
        },
        isModPost() {
            return this?.mainActivity?.meta?.mod_post && this?.mainActivity?.meta?.mod_url
        },
        isMyPost() {
            return this.loggedUserId == parseInt(this?.actor?.id)
        },
        isRepost() {
            return Boolean(this.activity?.object?.object)
        },
        isSale() {
            return this?.mainActivity?.meta?.sale ?? false
        },
        latestReactions() {
            return this.activity.latest_reactions
        },
        likesCountText() {
            let myOthersCount = this.latestReactions?.like && this.latestReactions.like.length && this.reactionCounts.like ? this.reactionCounts.like - 1 : 0
            let myOthers = myOthersCount > 0 ? `&nbsp;and ${myOthersCount} other${myOthersCount > 1 ? 's' : ''}` : ''
            return myOthers
        },
        mainActivity() {
            return this.isRepost ? this.activity?.object : this.activity
        },
        meta() {
            return this.mainActivity?.meta || null 
        },
        noChildren() {
            return this?.activity?.meta?.post_type == "no_children"
        },
        ownReactions() {
            return this.activity.own_reactions
        },
        pinned() {
            return false //(this.viewedBuild && this.viewedBuild.pinned_activities && this.viewedBuild.pinned_activities.length && this.viewedBuild.pinned_activities.includes(this.activity.id)) || (this.viewedGarage && this.viewedGarage.pinned_activities && this.viewedGarage.pinned_activities.length && this.viewedGarage.pinned_activities.includes(this.activity.id))
        },
        reactionCounts() {
            return this.activity?.reaction_counts
        },
        repostCount() {
            return (this.reactionCounts?.repost || 0) + (this.meta?.reposts || 0)
        },
        saleData() {
            return this?.mainActivity?.meta?.sale_data ?? null
        }
    },
    methods: {
        addClass(e) {
            e.target?.classList.add("hovered")
        },
        clickAddMod() {
            this.setStateData([
                { shareIntentURL:  this.mainActivity.meta.mod_url },
                { showShareIntentModal: true }
            ])
        },
        clickBlock() {
            if (confirm(`You want to block user ${this.actor.name}`)) {
                this.toggleBlockOwner(this.actor)
            }
        },
        clickChat() {
            this.routeTo(`/chat/check/${this.actor.id}`)
        },
        clickComment() {
            this.iClickedComment = true

            setTimeout(() => {
                if (!this.loggedUser) {
                    let url = new URL(window.location.href)

                    this.setStateData([
                        { afterRegisterRoute: `${url.pathname}` }
                    ])

                    this.routeTo('/register')
                }
                else if (!this.modal) {
                    this.openActivityModal(this.activity, this.queryId, this.position)
                }
            }, 100)

            setTimeout(() => {
                this.iClickedComment = false
            }, 300)
        },
        clickCopyLink() {
            navigator.clipboard.writeText(`${this.appUrl}activity/${this.mainActivity.foreign_id}?session=${this.loggedUser?.short_id}`)
                .then(() => {
                    this.pageMessage('success', 'Link copied to clipboard')
                })
                .catch(() => {
                    this.pageMessage('error', 'Unable to access your clipboard')
                })
        },
        clickFlag(){
            this.flagItem('activity', this.activity.foreign_id)
        },
        async clickOtherLikes() {
            if (!this.likes) {
                let myLikes = await this.getLikes(this.activity.id)

                if (myLikes.results) {
                    this.likes = myLikes.results
                }
            }
            else {
                this.deleteLikes()
            }
        },
        clickRepost() {
            this.setStateData([
                { newActivityShareURL: `${this.appUrl}activity/${this.mainActivity.foreign_id}?session=${this.loggedUser?.short_id}` }
            ])

            this.openWriteActivity()
        },
        clickSold() {
            if (confirm("You wish to mark this item as sold?")) {
                this.setStateData([
                    { postingActivity: true }
                ])

                this.openSpinner()

                buildzAxios?.post(this.getRoute('activitySold', this.activity.id))
                    .then((response) => {
                        if (response.status == 200 && response.data) {
                            this.publishUpdatedActivity(this.activity.id)
                        }
                        else {
                            this.pageMessage('error', 'Error updating post')
                            this.closeSpinner()
                        }
                    })
                    .catch((error) => {
                        this.pageMessage('error', 'Error updating post')
                        this.closeSpinner()
                    })
            }
        },
        deleteLikes() {
            this.likes = null
        },
        getLinkUserText(inName, inIndex) {
            let myOthersCount = this.latestReactions?.like && this.latestReactions.like.length && this.reactionCounts.like ? this.reactionCounts.like - 1 : 0
            
            return inIndex == 0 ? inName : ((myOthersCount > 0 ? ', ' : '&nbsp;and ') + inName)
        },
        removeClass(e) {
            e.target?.classList.remove("hovered")
        },
        sharePost() {
            this.nativeShare(
                {
                    text: this.activityData,
                    url: this.activityUrl
                }
            )
        }
    }
}
</script>

<style>
.like-link-box .hovered .like-link {
    display: block;
}

.like-link-box .like-link {
    display: none;
}
</style>

