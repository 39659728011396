<template>
    <div class="relative overflow-hidden page-standard-height-width">
        <div class="fixed top-0 h-full" :class="{ 'left-56 desktop-width': isDesktopWidth && loggedUser, 'left-0 w-full': !isDesktopWidth || !loggedUser }">
            <div class="mx-auto max-main-width h-full">
                <cached-image imageClasses="object-cover h-full w-full" :imageSrc="transformUrl(defaultHero, ['optimize', 'md', 'q80'])" />
            </div>
        </div>
        <div class="fixed top-0 h-full black-blur-3" :class="{ 'left-56 desktop-width': isDesktopWidth && loggedUser, 'left-0 w-full': !isDesktopWidth || !loggedUser }">
        </div>
        <div class="fixed h-full" :class="{ 'left-56 desktop-width': isDesktopWidth && loggedUser, 'left-0 w-full': !isDesktopWidth || !loggedUser }">
            <div class="mx-auto max-main-width h-full py-16">
                <rectangle 
                    classes="w-full h-52 md:h-64 lg:h-96 flasher-mid-bg flasher-mid-animate" :count="1"
                />
                <div class="flex items center justify-between px-1 mt-2 mb-3">
                    <div class="flasher-button-base flasher-dark-animate">
                        <fa-icon icon="comments" type="fas" class="h-4 w-4" />
                        <div class="ml-1">
                            Chat
                        </div>
                    </div>
                </div>
                <div class="max-width-box">
                    <text-lines 
                        :count="4" classes="w-full flasher-light-bg flasher-light-animate h-4 rounded-full mb-1" 
                    />
                    <tabs 
                        :count="3" 
                    />
                    <section-heading 
                        classes="w-52 my-6" 
                    />
                    <write-post />
                    <posts 
                        :count="4" 
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Posts from '@/flashers/components/Posts'
import Rectangle from '@/flashers/components/Rectangle'
import SectionHeading from '@/flashers/components/SectionHeading'
import Tabs from '@/flashers/components/Tabs'
import TextLines from '@/flashers/components/TextLines'
import WritePost from '@/flashers/components/WritePost'

export default {
    components: {
        Posts,
        Rectangle,
        SectionHeading,
        Tabs,
        TextLines,
        WritePost
    }
}
</script>