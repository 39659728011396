<template>
    <div :ref="`comment${comment.id}`" v-if="comment" class="mx-3 my-4">
        <div class="flex" :class="{ '-mt-2 -ml-2 p-2': isFocusedComment }">
            <router-link :to="actorURL">
                <div class="w-6 h-6 rounded-full border-2 border-orange-500" style="min-width: 1.5rem;">
                    <user-avatar :url="actor ? actor.profileImage : ''" :rounded="true" />
                </div>
            </router-link>
            <div class="ml-2">
                <router-link class="font-bold" style="line-height: 1.5rem;" :to="actorURL">
                    {{ actor ? actor.name : 'Unknown' }}
                </router-link>
                <div class="mt-1" v-html="renderText">
                </div>
            </div>
        </div>
        <div class="flex mt-1 text-xs">
            <div class="mr-6 text-gray-300">
                {{ commentAge }}
            </div>
            <div class="flex">
                <drop-down v-if="loggedUser && isMyComment" linkText="More" linkClass="">
                    <span @click="deleteComment(comment)">
                        Delete
                    </span>
                    <span @click="showWriteComment = true">
                        Edit
                    </span>
                </drop-down>
            </div>
        </div>
        <write-comment v-if="showWriteComment" :popupOpen="showWriteComment" :commentToEdit="comment" @close="showWriteComment = false" />
    </div>
</template>

<script>
import scrollIntoView from 'scroll-into-view-if-needed'

import DropDown from '@/shared/DropDown'
import WriteComment from './WriteComment'

export default {
    props: {
        comment: null
    },
    components: {
        DropDown,
        WriteComment
    },
    data() {
        return {
            avatarError: false,
            showWriteComment: false
        }
    },
    computed: {
        actor() {
            return this.comment.user.data
        },
        actorURL() {
            return this.actor?.handle ? `/garage/${this.actor.handle}` : '#'
        },
        commentAge() {
            return this.humanizedAge(this.comment.created_at)
        },
        isFocusedComment() {
            return false
        },
        isMyComment() {
            return this.loggedUserId == this.actor.id
        },
        renderText() {
            let myText = this.textRenderer(this.nlToBrSpace(this.comment.data.text)).trim()
            return myText.length ? myText : null
        }
    },
    mounted() {
        if (this.scrollToCommentId && this.scrollToCommentId == this.comment.id && this.$refs[`comment${this.comment.id}`]) {
            scrollIntoView(this.$refs[`comment${this.comment.id}`], {
                behavior: 'smooth',
                scrollMode: 'if-needed',
                block: 'center',
                inline: 'center',
            })

            this.setStateData([
                { scrollToCommentId: null }
            ])
        }
    }
}

</script>