<template>
    <div v-if="formLoaded" class="w-full">
        <input-panel 
            fieldFormName="label" fieldLabel="Label" fieldPlaceholder="Label" 
            :fieldRequired="true" fieldType="text" :fieldValue="discountForm.label"
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldFormName="percentage" fieldLabel="Discount Percentage" fieldPlaceholder="Discount Percentage" 
            :fieldRequired="true" fieldType="number" :fieldValue="discountForm.percentage"
            @fieldInput="updateFormValue"
        />
        <select-panel 
            :fieldData="yesNoData" fieldFormName="enabled" fieldLabel="Enabled"
            :fieldValue="discountForm.enabled" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
    </div>
</template>

<script>
export default {
    props: [
        'globalDiscount'
    ],
    data() {
        return {
            discountForm: {
                enabled: false,
                label: null,
                percentage: null
            },
            formLoaded: false,
            validating: false
        }
    },
    mounted() {
        this.discountForm.enabled = this?.globalDiscount?.enabled ?? false
        this.discountForm.label = this?.globalDiscount?.label ?? null
        this.discountForm.percentage = this?.globalDiscount?.percentage ?? null
        this.formLoaded = true
    },
    methods: {
        updateFormSelectValue(inField, inValue) {
            this.discountForm[inField] = inValue
            this.updateGlobalDiscount(true)
        },
        updateFormValue(inField, inValue) {
            this.discountForm[inField] = inValue
            this.updateGlobalDiscount(false)
        },
        updateGlobalDiscount(inSaveImmediate) {
            if (!this.discountForm.label) {
                this.discountForm.label = this?.globalDiscount?.label ?? null
            }

            if (!this.discountForm.percentage || this.discountForm.percentage < 0 || this.discountForm.percentage > 100) {
                this.discountForm.percentage = this?.globalDiscount?.percentage ?? null
            }

            if (!this.discountForm.label || !this.discountForm.percentage || this.discountForm.percentage < 0 || this.discountForm.percentage > 100) {
                this.discountForm.enabled = false
            }

            this.$emit('updateGlobalDiscount', this.discountForm, inSaveImmediate)
        }
    }
}
</script>