<template>
    <div class="w-full px-0" :class="{'three-images' : multiple }" :style="boxStyle">
        <form v-show="!files.length || multiple" autocomplete="off" @submit.prevent="submitUpdate" ref="fileform" class="h-full bg-white rounded-md p-1" :style="boxStyle">
            <div class="absolute h-0 w-0 overflow-hidden file-select-box">
                <input ref="fileInput" type="file" class="custom-file-input" :multiple="multiple" :accept="allowedTypes" @change="storeImages($event)">
            </div>
            <div @click="clickPanel()" class="h-full bg-white edit-field-text-color text-xs text-center rounded-md p-2 flex items-center justify-center cursor-pointer" :class="{ [dropPanelClass]: dropPanelClass }" :style="boxStyle">
                Drag & Drop your files or Browse
            </div>
        </form>
        <draggable v-if="files.length && showMedia" class="rounded-md flex flex-wrap bg-white p-1" group="media" @change="saveOrder()" :style="boxStyle" item-key="id" tag="div" :list="files" handle=".handle-media" animation="250" easing="cubic-bezier(1, 0, 0, 1)">
            <template #item="{element, index}">
                <offline-media-card 
                    :element="element" :filesCount="files.length" :hideSpinner="hideSpinner" :index="index"
                    @deleteFile="deleteFile" @updateElement="updateElement"
                />
            </template>
        </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

import OfflineMediaCard from '@/shared/Cards/OfflineMediaCard'

export default {
    props:[
        'autoBrowse',
        'boxStyle', 
        'dropPanelClass',
        'hideSpinner',
        'imagesOnly', 
        'inFiles', 
        'maxFiles', 
        'multiple' 
    ],
    components: {
        draggable,
        OfflineMediaCard
    },
    data() {
        return {
            files: [],
            showMedia: true
        }
    },
    computed: {
        allowedTypes() {
            return this.imagesOnly ? this.allowedImageTypes.join(',') : this.allowedMediaTypes.join(',')
        }
    },
    watch: {
        inFiles: {
            immediate: true,
            handler(val) {
                this.showMedia = false
                this.files = []

                this.inFiles.forEach((inFile) => {
                    this.files.push(inFile)
                })

                this.$nextTick(() => {
                    this.showMedia = true
                })
            }
        }
    },
    mounted() {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
            this.$refs.fileform.addEventListener(evt, (e) => {
                e.preventDefault()
                e.stopPropagation()
            }, false)
        })

        this.$refs.fileform.addEventListener('drop', (e) => {
            e.dataTransfer.files.forEach((myFile) => {
                if (!myFile.identifier) {
                    myFile.identifier = this.getRandomString(20)
                }

                this.files.push(myFile)
            })

            this.updateMedia()
        })

        if (this.autoBrowse) {
            this.clickPanel()
        }
    },
    methods: {
        clickPanel() {
            this.$refs.fileInput.click()
        },
        deleteFile(inIndex) {
            this.files.splice(inIndex, 1)

            this.files.forEach((image) => {
                if (!image.identifier) {
                    image.identifier = this.getRandomString(20)
                }
            })

            this.$emit('saveMedia', this.files)
        },
        saveOrder() {
            this.files.forEach((image) => {
                if (!image.identifier) {
                    image.identifier = this.getRandomString(20)
                }
            })

            this.$emit('saveMedia', this.files)
        },
        storeImages(e) {
            if (this.objLength(e.target.files) > 0) {
                if (Array.isArray(e.target.files)) {
                    e.target.files.forEach((myFile) => {
                        this.files.push(myFile)
                    })
                }
                else {
                    Object.values(e.target.files).forEach((myFile) => {
                        this.files.push(myFile)
                    })
                }

                e.target.value = null
            }

            this.files.forEach((image) => {
                if (!image.identifier) {
                    image.identifier = this.getRandomString(20)
                }
            })

            this.updateMedia()
        },
        submitUpdate() {

        },
        updateElement(inImage, inIndex) {
            this.files[inIndex] = inImage
            // this.updateMedia()
        },
        updateMedia() {
            this.$emit('updateMedia', this.files)
        }
    }
}
</script>

<style>
    .three-images .image-box {
        width: calc(50% - 0.5em);
        @apply m-1
    }
</style>