<template>
    <div v-if="formLoaded" class="w-full">
        <input-panel 
            fieldType="text" :fieldValue="fixText(abForm.join_button_text)" fieldLabel="Join Button Text" fieldPlaceholder="Join Button Text"
            fieldFormName="join_button_text"
            @fieldInput="updateFormValue"
        />
        <input-panel v-if="!isGiveAway && groupBuy.has_eoi"
            fieldType="text" :fieldValue="abForm.register_button_text" fieldLabel="Register Button Text" fieldPlaceholder="Register Button Text"
            fieldFormName="register_button_text"
            @fieldInput="updateFormValue"
        />
        <input-panel v-if="!isGiveAway && groupBuy.has_eoi" 
            fieldType="text" :fieldValue="abForm.register_button_extra_text" fieldLabel="Register Button Extra Text" fieldPlaceholder="Register Button Extra Text"
            fieldFormName="register_button_extra_text"
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldType="text" :fieldValue="fixText(abForm.share_button_text)" fieldLabel="Share Button Text" fieldPlaceholder="Share Button Text"
            fieldFormName="share_button_text" hideFieldFormName="hide_share_button" :hideFieldValue="abForm.hide_share_button" :hideIcon="true"
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldType="text" :fieldValue="fixText(abForm.show_offer_details_text)" fieldLabel="Show Offer Details Text" fieldPlaceholder="Show Offer Details Text"
            fieldFormName="show_offer_details_text"
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldType="text" :fieldValue="fixText(abForm.hide_offer_details_text)" fieldLabel="Hide Offer Details Text" fieldPlaceholder="Hide Offer Details Text"
            fieldFormName="hide_offer_details_text"
            @fieldInput="updateFormValue"
        />
        <select-panel 
            :fieldValue="abForm.hide_tracker_bar" fieldLabel="Hide Tracker Bar" fieldFormName="hide_tracker_bar"
            :fieldData="yesNoData" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel v-if="isGiveAway && !groupBuy.no_maximum && !groupBuy.no_maximum_dollars && !abForm.hide_tracker_bar"
            :fieldData="yesNoData" fieldFormName="dollars_graphic" fieldLabel="Display Bar As Percentage Of Dollars"
            :fieldValue="abForm.dollars_graphic" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel 
            :fieldValue="abForm.hide_info_bar" fieldLabel="Hide Status Info" fieldFormName="hide_info_bar"
            :fieldData="yesNoData" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel v-if="isGiveAway && !groupBuy.no_maximum_dollars && !abForm.percentage_tracker && !abForm.hide_info_bar"
            :fieldData="yesNoData" fieldFormName="dollars_tracker" fieldLabel="Display Status Info As Percentage Of Dollars"
            :fieldValue="abForm.dollars_tracker" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel v-if="isGiveAway && !abForm.dollars_tracker && !abForm.hide_info_bar"
            :fieldData="yesNoData" fieldFormName="percentage_tracker" fieldLabel="Display Status Info As Percentage Of Tickets"
            :fieldValue="abForm.percentage_tracker" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <input-panel v-if="(!isGiveAway || !abForm.dollars_tracker) && !abForm.hide_info_bar"
            fieldType="text" :fieldValue="abForm.units_confirmed_text" :fieldLabel="unitsConfirmedText" :fieldPlaceholder="unitsConfirmedText"
            fieldFormName="units_confirmed_text" hideFieldFormName="hide_units_confirmed" :hideFieldValue="abForm.hide_units_confirmed" :hideIcon="true"
            @fieldInput="updateFormValue"
        />
        <input-panel v-if="isGiveAway && abForm.dollars_tracker && !abForm.hide_info_bar"
            fieldType="text" :fieldValue="abForm.dollars_percentage_text" fieldLabel="Dollars Percentage Text" fieldPlaceholder="Dollars Percentage Text"
            fieldFormName="dollars_percentage_text"
            @fieldInput="updateFormValue"
        />
        <input-panel v-if="!abForm.hide_info_bar"
            fieldType="text" :fieldValue="abForm.minimum_required_text" fieldLabel="Minimum Required Text" fieldPlaceholder="Minimum Required Text"
            fieldFormName="minimum_required_text"
            @fieldInput="updateFormValue"
        />
        <input-panel v-if="!abForm.hide_info_bar"
            fieldType="text" :fieldValue="abForm.maximum_allowed_text" fieldLabel="Maximum Allowed Text" fieldPlaceholder="Maximum Allowed Text"
            fieldFormName="maximum_allowed_text"
            @fieldInput="updateFormValue"
        />
        <select-panel v-if="!isGiveAway"
            :fieldValue="abForm.hide_products_description" fieldLabel="Hide Products Description" fieldFormName="hide_products_description"
            :fieldData="yesNoData" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <input-panel v-if="!isGiveAway"
            fieldType="text" :fieldValue="fixText(abForm.products_title_text)" fieldLabel="Products Title Text" fieldPlaceholder="Products Title Text"
            fieldFormName="products_title_text" hideFieldFormName="hide_products_title_text" :hideFieldValue="abForm.hide_products_title_text" :hideIcon="true"
            @fieldInput="updateFormValue"
        />
        <select-panel 
            :fieldValue="abForm.hide_offer_conditions" fieldLabel="Hide Offer Conditions" fieldFormName="hide_offer_conditions"
            :fieldData="yesNoData" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <input-panel v-if="!abForm.hide_offer_conditions"
            fieldType="text" :fieldValue="fixText(abForm.offer_conditions_text_one)" fieldLabel="Offer Conditions Text One" fieldPlaceholder="Offer Conditions Text One"
            fieldFormName="offer_conditions_text_one" hideFieldFormName="hide_offer_conditions_text_one" :hideFieldValue="abForm.hide_offer_conditions_text_one" :hideIcon="true"
            @fieldInput="updateFormValue"
        />
        <input-panel v-if="!abForm.hide_offer_conditions"
            fieldType="text" :fieldValue="fixText(abForm.offer_conditions_text_two)" fieldLabel="Offer Conditions Text Two" fieldPlaceholder="Offer Conditions Text Two"
            fieldFormName="offer_conditions_text_two" hideFieldFormName="hide_offer_conditions_text_two" :hideFieldValue="abForm.hide_offer_conditions_text_two" :hideIcon="true"
            @fieldInput="updateFormValue"
        />
    </div>
</template>

<script>
export default {
    props: [
        'groupBuy',
        'isGiveAway',
        'pAbForm'
    ],
    data() {
        return {
            formLoaded: false,
            abForm: {
                dollars_graphic: false,
                dollars_percentage_text: null,
                dollars_tracker: false,
                hide_info_bar: false,
                hide_offer_conditions: false,
                hide_offer_conditions_text_one: false,
                hide_offer_conditions_text_two: false,
                hide_offer_details_text: null,
                hide_products_description: false,
                hide_products_title_text: false,
                hide_share_button: false,
                hide_tracker_bar: false,
                hide_units_confirmed: false,
                join_button_text: null,
                maximum_allowed_text: null,
                minimum_required_text: null,
                offer_conditions_text_one: null,
                offer_conditions_text_two: null,
                percentage_tracker: false,
                products_title_text: null,
                register_button_extra_text: null,
                register_button_text: null,
                share_button_text: null,
                show_offer_details_text: null,
                type: null,
                units_confirmed_text: null
            }
        }
    },
    computed: {
        unitsConfirmedText() {
            return !this.isGiveAway ? "Units Confirmed Text" : (this.abForm.percentage_tracker ? "Tickets Percentage Text" : "Tickets Sold Text")
        }
    },
    mounted() {
        this.abForm.dollars_graphic = this.pAbForm.dollars_graphic
        this.abForm.dollars_percentage_text = this.pAbForm.dollars_percentage_text
        this.abForm.dollars_tracker = this.pAbForm.dollars_tracker
        this.abForm.hide_info_bar = this.pAbForm.hide_info_bar
        this.abForm.hide_offer_conditions = this.pAbForm.hide_offer_conditions
        this.abForm.hide_offer_conditions_text_one = this.pAbForm.hide_offer_conditions_text_one
        this.abForm.hide_offer_conditions_text_two = this.pAbForm.hide_offer_conditions_text_two
        this.abForm.hide_offer_details_text = this.pAbForm.hide_offer_details_text
        this.abForm.hide_products_description = this.pAbForm.hide_products_description
        this.abForm.hide_products_title_text = this.pAbForm.hide_products_title_text
        this.abForm.hide_share_button = this.pAbForm.hide_share_button
        this.abForm.hide_tracker_bar = this.pAbForm.hide_tracker_bar
        this.abForm.hide_units_confirmed = this.pAbForm.hide_units_confirmed
        this.abForm.join_button_text = this.pAbForm.join_button_text
        this.abForm.maximum_allowed_text = this.pAbForm.maximum_allowed_text
        this.abForm.minimum_required_text = this.pAbForm.minimum_required_text
        this.abForm.offer_conditions_text_one = this.pAbForm.offer_conditions_text_one
        this.abForm.offer_conditions_text_two = this.pAbForm.offer_conditions_text_two
        this.abForm.percentage_tracker = this.pAbForm.percentage_tracker
        this.abForm.products_title_text = this.pAbForm.products_title_text
        this.abForm.register_button_extra_text = this.pAbForm.register_button_extra_text
        this.abForm.register_button_text = this.pAbForm.register_button_text
        this.abForm.share_button_text = this.pAbForm.share_button_text
        this.abForm.show_offer_details_text = this.pAbForm.show_offer_details_text
        this.abForm.type = this.pAbForm.type
        this.abForm.units_confirmed_text = this.pAbForm.units_confirmed_text
        this.formLoaded = true
    },
    methods: {
        fixText(inText) {
            return inText.replace('{labelSingular}', this.groupBuyType(this.pAbForm.type).labelSingular)
        },
        saveWhiteLabelData(inImmediate) {
            this.$emit('updateWhiteLabel', this.abForm, inImmediate)
        },
        updateFormSelectValue(inField, inValue) {
            this.abForm[inField] = inValue
            this.saveWhiteLabelData(true)
        },
        updateFormValue(inField, inValue) {
            this.abForm[inField] = inValue
            this.saveWhiteLabelData(false)
        }
    }
}
</script>