<template>
    <div v-if="showBadgePopup" class="fixed left-0 bottom-2 w-full flex items-end justify-center z-80 text-gray-400">
        <div @click.stop="clickEquip()" class="relative w-full max-w-sm bg-white rounded-lg iphoneMarginBottom">
            <div class="relative flex items-center p-4">
                <div class="mr-4">
                    <cached-image imageClasses="w-20 h-auto mx-auto" :imageSrc="transformUrl(badgeUrl, ['optimize', 'sm', 'q80'])" />
                </div>
                <div class="text-center">
                    <div class="font-bold">
                        New Badge Unlocked!
                    </div>
                    <div>
                        {{ badgeText }}
                    </div>
                    <div>
                        Tap here to equip
                    </div>
                </div>
            </div>
            <div @click.stop="clickClose()" class="absolute top-2 right-2 cursor-pointer">
                <fa-icon icon="xmark" type="fas" class="h-5 w-5" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        badgeText() {
            return this.badgeAwarded.badge_text ?? this.badgeAwarded.text
        },
        badgeUrl() {
            return this.badgeAwarded.badge_url ?? this.badgeAwarded.badge
        }
    },
    mounted() {
        this.setStateData([
            { forceHubDataReload: true }
        ])
        
        if ((this.isAndroidApp || this.isIOSApp) && this.badgeAwarded.sound) {
            this.playSound(this.badgeAwarded.sound)
        }

        let myUser = this.objCopy(this.loggedUser)
        
        let myBadge = {
            text: this.badgeText,
            badge: this.badgeUrl
        }

        let userBadges = myUser.badges ?? []
        userBadges.push(myBadge)
        myUser.badges = this.objCopy(userBadges)

        if (this.badgeAwarded.checklist) {
            if (myUser.checklist && this.objLength(myUser.checklist) > 0) {
                myUser.checklist = {...myUser.checklist, ...{[`${this.badgeAwarded.checklist}`]: true}}
            }
            else {
                myUser.checklist = {[`${this.badgeAwarded.checklist}`]: true}
            }

            if (this.badgeAwarded.checklist_label) {
                this.appsFlyerEvent(
                    {
                        eventName: `Checklist ${this.badgeAwarded.checklist_label}`,
                        eventValue: {}
                    }
                )
            }
        }

        this.replaceStateData([
            { loggedUser: myUser }
        ])
    },
    methods: {
        clickClose() {
            this.closeBadgePopup()
        },
        clickEquip() {
            let myBadge = this.objCopy(this.badgeAwarded)
            this.openBadgeModal(myBadge, false)
            this.closeBadgePopup(false)
        }
    }
}
</script>