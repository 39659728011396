<template>
    <div class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="usePayPal" fieldLabel="Do You Want To Use PayPal?" fieldLabelClasses="text-gray-800" :fieldRequired="true"
            :fieldValue="sellerDataForm.usePayPal" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="updateSellerDataFormSelectValue"
        >
            <validation-message v-if="validating && sellerDataForm.usePayPal == null" 
                message="Select yes or no"
            />
        </select-panel>
        <div v-if="sellerDataForm.usePayPal" class="w-full mt-2">
            <input-panel 
                fieldFormName="paypal_merchant_id" fieldLabel="PayPal Merchant Id" fieldLabelClasses="text-gray-800" fieldPlaceholder="PayPal Merchant Id" :fieldRequired="true" fieldType="text" 
                :fieldValue="sellerForm.paypal_merchant_id" :forceMobile="true"
                @fieldInput="updateSellerFormValue"
            >
                <validation-message v-if="validating && !sellerForm.paypal_merchant_id" 
                    message="PayPal Merchant Id must be entered"
                />
            </input-panel>
            <input-panel 
                fieldFormName="paypal_client_id" fieldLabel="PayPal Client Id" fieldLabelClasses="text-gray-800" fieldPlaceholder="PayPal Client Id" :fieldRequired="true" fieldType="text" 
                :fieldValue="sellerForm.paypal_client_id" :forceMobile="true"
                @fieldInput="updateSellerFormValue"
            >
                <validation-message v-if="validating && !sellerForm.paypal_client_id" 
                    message="PayPal Client Id must be entered"
                />
            </input-panel>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'sellerDataForm',
        'sellerForm',
        'validating'
    ],
    methods: {
        updateSellerDataFormSelectValue(inField, inValue) {
            this.$emit('updateSellerDataFormSelectValue', inField, inValue)
        },
        updateSellerFormValue(inField, inValue) {
            this.$emit('updateSellerFormValue', inField, inValue)
        }
    }
}
</script>