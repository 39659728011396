import twitter from 'twitter-text'

const postReactionsMixins = {
    methods: {
        closeWritePostReaction() {
            this.setStateData([
                { showWritePostReaction: false },
                { postReactionToEdit: null }
            ])
        },
        deletePostReaction(inReaction) {
            if (!this.streamClient?.reactions) return

            this.streamClient.reactions.delete(inReaction.id)
                .then((response) => {
                    let myReactions = this.objCopy(this?.reactions)
                    myReactions = Object.values(myReactions).filter(reaction => reaction.id != inReaction.id)
                    
                    this.replaceStateData([
                        { reactions: myReactions }
                    ])

                    Object.entries(this.feedActivities).forEach(([feedId, activities]) => {
                        let myActivities = this.objCopy(activities)

                        myActivities = myActivities.map((activity) => {
                            if (activity.id == inReaction.activity_id) {
                                let { postReaction: postReactions, ...myOwnReactions } = activity.own_reactions
                                postReactions = postReactions.filter(reaction => reaction.id != inReaction.id)

                                if (postReactions.length) {
                                    myOwnReactions = { ...myOwnReactions, postReaction: postReactions}
                                }
                                
                                activity.own_reactions = myOwnReactions

                                if (activity?.reaction_counts?.postReaction && activity?.reaction_counts?.postReaction > 1) {
                                    --activity.reaction_counts.postReaction
                                }
                                else if (activity?.reaction_counts?.postReaction && activity?.reaction_counts?.postReaction == 1) {
                                    let { postReaction: _postReaction, ...myReactionCounts } = activity?.reaction_counts
                                    activity.reaction_counts = myReactionCounts
                                }
                            }

                            return activity
                        })

                        this.updateActivities(myActivities, feedId)
                    })
                })
                .catch((error) => {
                    this.pageMessage('error', 'Error deleting comment')
                })       
        },
        newPostReaction(inText, inImage, inFeeds, inActivityId) {
            let myActivityId = inActivityId ? inActivityId : this.activityToShow?.id
            
            if (!this.streamClient?.reactions) return

            this.streamClient.reactions.add("postReaction", myActivityId, { 
                object: inText, 
                image: inImage,
            })
                .then((response) => {
                    if (this.activityToShow && this.activityToShow?.id == myActivityId) {
                        let myReactions = this.objCopy(this?.reactions)
                        myReactions = { ...myReactions, response }
                        
                        this.setStateData([
                            { reactions: myReactions }
                        ])
                    }

                    Object.entries(this.feedActivities).forEach(([feedId, activities]) => {
                        let myActivities = this.objCopy(activities)

                        myActivities = myActivities.map((activity) => {
                            if (activity.id == myActivityId) {
                                if (activity.own_reactions) {
                                    if (activity.own_reactions.postReaction) {
                                        activity.own_reactions.postReaction.push(response)
                                    }
                                    else {
                                        activity.own_reactions = { ...activity.own_reactions, postReaction: [response] }
                                    }
                                }
                                else {
                                    activity.own_reactions = { postReaction: [response]}
                                }

                                if (activity?.reaction_counts.postReaction) {
                                    ++activity.reaction_counts.postReaction
                                }
                                else {
                                    activity.reaction_counts = { ...activity?.reaction_counts, postReaction: 1 }
                                }
                            }

                            return activity
                        })

                        this.updateActivities(myActivities, feedId)
                        this.closeWritePostReaction()
                        this.pageMessage('success', 'Comment created')
                    })

                    this.setStateData([
                        { postingReaction: false }
                    ])
                    
                    this.updateUTCTime()
                    
                    buildzAxios?.post(this.getRoute('postReactionCreate'), {
                        text: inText,
                        image: inImage,
                        activityId: myActivityId,
                        reactionId: response.id,
                        feeds: inFeeds,
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.pageMessage('error', 'Error creating comment')
                    
                    this.setStateData([
                        { postingReaction: false }
                    ])
                })
                .then(() => {
                
                })
        },
        openWritePostReaction(inReaction = null) {
            this.setStateData([
                { showWritePostReaction: true },
                { postReactionToEdit: inReaction }
            ])
        },
        postBackgroundPostReaction(inFiles) {
            if (this.backgroundPostReaction) {
                let myBackgroundPostReaction = this.objCopy(this.backgroundPostReaction)

                inFiles.forEach((item) => {
                    item.url = item.url.replace(/\/v[0-9].*?\//gm, '/')   // strip version
                    item.url = item.url.replace(/\/l_buildz_logo_black_plate_zbi4al,fl_region_relative,w_1.4,g_ocr_text\//gm, '/')   // strip existing ocr

                    if (item.type == 'image' && item.blur) {
                        item.url = item.url.replace(/\/buildz_pro_public\/.*/gm, "/l_buildz_logo_black_plate_zbi4al,fl_region_relative,w_1.4,g_ocr_text$&")
                    }
                })

                let feeds = {}
                let pattern = /\B\+[a-z0-9_-]+/gi

                feeds['builds'] = myBackgroundPostReaction.text.match(pattern)?.map(build => {
                    return build.substring(1)
                }) || []

                feeds['mentions'] = twitter?.extractMentions(myBackgroundPostReaction.text)
                feeds['hashtags'] = twitter?.extractHashtags(myBackgroundPostReaction.text)

                if (myBackgroundPostReaction.postReactionId) {
                    this.updatePostReaction(myBackgroundPostReaction.text, inFiles.length ? inFiles[0] : null, feeds, myBackgroundPostReaction.postReactionId, myBackgroundPostReaction.activityId)
                }
                else {
                    this.newPostReaction(myBackgroundPostReaction.text, inFiles.length ? inFiles[0] : null, feeds, myBackgroundPostReaction.activityId)
                }

                this.setStateData([
                    { backgroundPostReaction: null }
                ])
            }
        },
        async publishUpdatedPostReaction(inPostReactionId, inActivityId = null) {
            let myReaction = await this.getSinglePostReaction(inPostReactionId, inActivityId)

            if (myReaction && myReaction.results) {
                myReaction = myReaction.results[0]
                let myReactions = this.objCopy(this?.reactions)
                
                myReactions = Object.values(myReactions).map((reaction) => {
                    if (reaction.id == myReaction.id) {
                        return myReaction
                    }
                    else {
                        return reaction
                    }
                })

                this.replaceStateData([
                    { reactions: myReactions }
                ])
            }

            this.setStateData([
                { postingReaction: false }
            ])

            this.closeWritePostReaction()
        },
        async updatePostReaction(inText, inImage, inFeeds, inPostReactionId, inActivityId = null) {
            await buildzAxios?.put(this.getRoute('postReactionUpdate', inPostReactionId), {
                activityId: inActivityId ? inActivityId : this.activityToShow?.id,
                text: inText,
                image: inImage,
                feeds: inFeeds,
            })
                .then((response) => {
                    if (response.status == 200) {
                        if (this.activityToShow && this.activityToShow?.id == inActivityId) {
                            this.publishUpdatedPostReaction(inPostReactionId, inActivityId)
                        }
                        else {
                            this.setStateData([
                                { postingReaction: false }
                            ])

                            this.closeWritePostReaction()
                            this.pageMessage('success', 'Comment updated')
                        }
                    }
                    else {
                        this.pageMessage('error', 'Error updating comment')

                        this.setStateData([
                            { postingReaction: false }
                        ])
                    }
                })
                .catch((error) => {
                    this.pageMessage('error', 'Error updating comment')
                    
                    this.setStateData([
                        { postingReaction: false }
                    ])
                })
                .then(() => {
                    
                })
        },
    }
}

export default postReactionsMixins