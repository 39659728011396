const userMixins = {
    computed: {
        loggedUserId() {
            return this.loggedUser?.id || null
        },
        loggedUserHasBuild() {
            return this.loggedUser?.builds && this.loggedUser.builds.length
        },
        loggedUserHasCompletedBuild() {
            return this.loggedUserHasBuild && this.loggedUser.builds.filter((build) => build.search_enabled).length
        },
        userBuildzStaff() {
            return this.loggedUser?.buildz_staff
        },
        userCanPost() {
            return Boolean(this.loggedUser) //this.loggedUser?.can.postActivity
        },
        userHideIcons() {
            return this.loggedUser?.settings?.hide_icons || true
        },
        userIdsImFollowing() {
            return this.loggedUser?.user_ids_im_following || null
        },
        userIdsImBlocking() {
            return this.loggedUser?.user_ids_im_blocking ?? []
        },
        userRepostingActivities() {
            return this.loggedUser?.repostingActivities || null
        },
        userTogglingActivityLikes() {
            return this.loggedUser?.togglingActivityLikes || null
        },
        userTogglingFollows() {
            return this.loggedUser?.togglingFollows || null
        },
        userTogglingPostReactionLikes() {
            return this.loggedUser?.togglingPostReactionLikes || null
        }
    },
    methods: {
        loggedUserHasBuildWithType(inType) {
            return this.loggedUserHasBuild && this.loggedUser.builds.filter((build) => build.build_type.toLowerCase() == inType.toLowerCase()).length
        }
    }
}

export default userMixins