<template> 
    <div class="fixed inset-x-0 top-0 z-40 flex items-center justify-center h-screen overflow-y-hidden modal sm:inset-0 iphonePadding">
        <div class="relative z-10 flex flex-col self-start w-full max-h-full mx-auto max-main-width" :class="myClasses">
             <div v-if="!whiteLabel" class="fixed top-0 left-0 h-full w-full black-blur-3">
            </div>
            <div :id="`modal-${modalId}`" class="relative h-screen max-h-full overflow-y-auto max-width-box">
                <slot></slot> 
            </div>
            <div v-if="!hideClose" @click.stop="$emit('close')" class="top-2 right-2 cursor-pointer rounded-full p-1 text-white" :class="{ 'absolute': windowWidth < desktopMaxWidth, 'fixed': windowWidth >= desktopMaxWidth }" style="background: rgba(0, 0, 0, 0.40);">
                <fa-icon icon="xmark" type="fas" class="w-6 h-6" />
            </div>
        </div>
    </div> 
</template>

<script>

export default {
    props: ['open', 'classes', 'hideClose', 'noVerticalPadding', 'stopUnscroll'],
    data() {
        return {
            currentScrollTop: 0,
            modalId: Math.round(Math.random() * 100000000),
            modalScrollTop: 0
        }
    },
    computed: {
        myClasses() {
            return { [this?.classes ?? '']: true }
        }
    },
    watch: {
        keyEscape: {
            handler(val) {
                if (val && this.popupCheck(this.modalId)) {
                    this.setStateData([
                        { keyEscape: false }
                    ])

                    this.$emit('close')
                }
            }
        }
    },
    mounted() {
        this.popupIn(this.modalId)

        this.setStateData([
            { keyEscape: false }
        ])

        if (this.popups.length == 1) {
            this.currentScrollTop = this.scrollTop
                        
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant'
            })
            
            document.body.classList.remove("overflow-y-scroll")
            document.body.classList.add("overflow-y-hidden")
            document.body.style.marginTop = -(this.currentScrollTop) + "px"
        }

        this.elGet(`modal-${this.modalId}`).addEventListener('scroll', this.handleModalScroll)
    },
    beforeUnmount() {
        this.popupOut(this.modalId)

        if (this.popups.length == 0) {
            document.body.classList.remove("overflow-y-hidden")
            document.body.classList.add("overflow-y-scroll")
            document.body.style.marginTop = "0px"
            
            window.scrollTo({
                top: this.currentScrollTop,
                left: 0,
                behavior: 'instant'
            })
        }

        this.elGet(`modal-${this.modalId}`).removeEventListener('scroll', this.handleModalScroll)
    },
    methods: {
        handleModalScroll() {
            this.modalScrollTop = this.elGet(`modal-${this.modalId}`).scrollTop
            this.$emit('updateModalScrollTop', this.modalScrollTop)
        }
    }
}
</script>