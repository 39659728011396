const marketplaceMixins = {
    computed: {
        vendorDataOkay() {
            let vendorOkay = Boolean(this.completeStripeAccount && this.sellerVendor && this.sellerVendor.name && this.sellerVendor.avatar && this.sellerVendorData)

            if (!vendorOkay) {
                return false
            }

            vendorOkay = Boolean(this.sellerVendorData.shippingFrom && this.sellerVendorData.defaultCurrency && this.sellerVendorData.defaultShippingModel && this.sellerVendorData.defaultProductCategory && this.sellerVendorData.defaultGroupBuyCategory && this.sellerVendorData.shippingZones && this.sellerVendorData.defaultTaxRate && this.sellerVendorData.taxLabel && this.sellerVendorData.taxRates && this.sellerVendorData.shippingTaxRate)

            return vendorOkay
        }
    },
    methods: {
        addLatestSale(inData) {
            if (inData && inData.sale) {
                let myLatestSales = this.objCopy(Object.values(this.latestSales))
                myLatestSales = [this.objCopy(inData.sale), ...myLatestSales]

                this.setStateData([
                    { latestSales: this.objCopy(myLatestSales) }
                ])
            }

            if (inData && inData.group_buy) {
                let { [`${inData.group_buy.id}`]: _, ...theRest } = this.objCopy(this.groupBuyCurrentQty)
                let myCurrentQty = { ...theRest, [`${inData.group_buy.id}`]: inData.group_buy.current_qty }

                this.setStateData([
                    { groupBuyCurrentQty: null }
                ])

                this.setStateData([
                    { groupBuyCurrentQty: this.objCopy(myCurrentQty) }
                ])

                let { [`${inData.group_buy.id}`]: __, ...theRestX } = this.objCopy(this.groupBuyCurrentDollars)
                let myCurrentDollars = { ...theRestX, [`${inData.group_buy.id}`]: inData.group_buy.current_dollars }

                this.setStateData([
                    { groupBuyCurrentDollars: null }
                ])

                this.setStateData([
                    { groupBuyCurrentDollars: this.objCopy(myCurrentDollars) }
                ])
            }
        },
        displayCurrency(inCurrency) {
            let myCurrency = this.currencies[inCurrency]

            if (myCurrency) {
                return myCurrency.name_plural
            }
            
            return null
        },
        displayPrice(inCurrency, inPrice, inDecimals = null) {
            let myCurrency = this.currencies[inCurrency]

            if (myCurrency) {
                let myDecimals = inDecimals == null ? myCurrency.decimal_digits : inDecimals
                return `${myCurrency.symbol}${parseFloat(inPrice).toFixed(myDecimals)}`
            }
            
            return null
        },
        fixProductPrice(inCurrency, inPrice) {
            let myCurrency = this.currencies[inCurrency]

            if (myCurrency) {
                return parseFloat(inPrice).toFixed(myCurrency.decimal_digits)
            }

            return 0
        },
        getAddOnDescription(inProduct, inAddOnString) {
            let myDescription = ""
            let myAddOnIdentifiers = inAddOnString.split('|')

            inProduct.product_add_ons.vendorAddOns
                .filter((myAddOn) => myAddOn.selected)
                .forEach((myAddOn, index) => {
                    let myValue = inProduct.product_add_ons.addOnValues.find((theValue) => theValue.identifier == myAddOn.identifier)

                    if (myValue) {
                        myValue = myValue.values.find((theValue) => theValue.identifier == myAddOnIdentifiers[index])

                        if (myValue) {
                            if (myDescription != "") {
                                myDescription = `${myDescription} `
                            }

                            myDescription = `${myDescription}[${myValue.label}]`
                        }
                    }
                })

            return myDescription
        },
        getAddOnPriceAdjustment(inProduct, inAddOnString) {
            let myPriceVariance = 0
            let myAddOnIdentifiers = inAddOnString.split('|')

            inProduct.product_add_ons.vendorAddOns
                .filter((myAddOn) => myAddOn.selected)
                .forEach((myAddOn, index) => {
                    let myValue = inProduct.product_add_ons.addOnValues.find((theValue) => theValue.identifier == myAddOn.identifier)

                    if (myValue) {
                        myValue = myValue.values.find((theValue) => theValue.identifier == myAddOnIdentifiers[index])

                        if (myValue) {
                            myPriceVariance = myPriceVariance + (myValue.priceVariance ?? 0)
                        }
                    }
                })

            return myPriceVariance
        },
        getBaseCurrencyValue(inCurrency, inPrice) {
            let myCurrency = this.currencies[inCurrency]

            if (myCurrency) {
                let myMultiplier = parseInt(('1').padEnd(myCurrency.decimal_digits + 1, '0'))
                return Math.floor(inPrice * myMultiplier)
            }

            return 0
        },
        getCommission(inAmount, inCurrency, inCommissionOveride) {
            let myAmount = ((inCommissionOveride ?? this.sellerDefaultCommission) / 100) * inAmount

            if (!inCommissionOveride && (this?.sellerCommissions ?? false) && (this.sellerCommissions[inCurrency])) {
                let myCommission = this.sellerCommissions[inCurrency]
                    .find((myCommis) => myCommis.min <= inAmount && myCommis.max >= inAmount)

                if (myCommission?.rate ?? false) {
                    myAmount = (myCommission.rate / 100) * inAmount
                }
            }

            return myAmount
        },
        getCurrencySymbol(inCurrency) {
            let myCurrency = this.currencies[inCurrency]

            if (myCurrency) {
                return myCurrency.symbol
            }

            return null
        },
        async getCustomerEois() {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.get(this.getRoute('customerEois'))
                    .then((response) => {
                        resolve(response.data.eois)
                    })
                    .catch((error) => {
                        console.log('error getting eois')
                        reject(false)
                    })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async getCustomerGroupBuys() {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.get(this.getRoute('customerGroupBuys'))
                    .then((response) => {
                        resolve(response.data.group_buys)
                    })
                    .catch((error) => {
                        console.log(`error getting ${this.pageData.groupBuy.labelPlural}`)
                        reject(false)
                    })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async getCustomerPurchases() {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.get(this.getRoute('customerPurchases'))
                    .then((response) => {
                        resolve(response.data.purchases)
                    })
                    .catch((error) => {
                        console.log('error getting purchases')
                        reject(false)
                    })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async getCustomerPurchasesWhiteLabel() {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.get(this.getRoute('customerPurchases'), {
                    params: {
                        seller: this.whiteLabelData.sellerId        
                    }
                })
                .then((response) => {
                    resolve(response.data.purchases)
                })
                .catch((error) => {
                    console.log('error getting purchases')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async getCustomerSubscriptions(inGroupOfferArray) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.get(this.getRoute('customerSubscriptions'),
                    {
                        groupOffers: inGroupOfferArray     
                    }
                )
                .then((response) => {
                    resolve(response.data.subscriptions)
                })
                .catch((error) => {
                    console.log('error getting subscriptions')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        getSaleDiscountAmount(inAppliedCodes, inGroupBuy, inCustomerVendor, inProducts, inProductsData, inAmount) {
            let myDisPrice = 0

            if (inAppliedCodes.length) {
                inAppliedCodes.forEach((myCode) => {
                    let gbCode = null
                    let sellerCode = null
                    
                    if (inGroupBuy && inGroupBuy.discount_codes && inGroupBuy.discount_codes.length) {
                        gbCode = inGroupBuy.discount_codes.find((theCode) => theCode.code == myCode.code)
                    }

                    if (inCustomerVendor && inCustomerVendor.discount_codes && inCustomerVendor.discount_codes.length) {
                        sellerCode = inCustomerVendor.discount_codes.find((theCode) => theCode.code == myCode.code)
                    }

                    if (gbCode || sellerCode) {
                        myDisPrice = myDisPrice + ((myCode.percentage / 100) * inAmount)
                    }
                    else {
                        inProducts.forEach((myInProduct) => {
                            if (myInProduct.discount_codes && myInProduct.discount_codes.length) {
                                let productCode = myInProduct.discount_codes.find((theCode) => theCode.code == myCode.code)

                                if (productCode) {
                                    let myProduct = inProductsData.find((productData) => productData.id == myInProduct.id)

                                    if (myProduct) {
                                        myDisPrice = myDisPrice + ((myCode.percentage / 100) * (myProduct.qty * myProduct.amount))
                                    }
                                }
                            }
                        })
                    }
                })

                return myDisPrice
            }
        },
        async getSellerSales(inGroupBuyId, inPage, inView) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.post(this.getRoute('sellerSales'), {
                    groupBuyId: inGroupBuyId,
                    page: inPage,
                    viewCancelled: inView['cancelled'],
                    viewDelivered: inView['delivered'],
                    viewFailed: inView['failed'],
                    viewPaid: inView['paid'],
                    viewPending: inView['pending'],
                    viewShipped: inView['shipped']
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log('error getting sales')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        getSellerVendorData() {
            if (this.hasStripeAccount) {
                buildzAxios?.get(this.getRoute('sellerData'))
                    .then((response) => {
                        this.setStateData([
                            { sellerVendorData: response.data.marketplace_vendor_data },
                            { sellerVendor: response.data.marketplace_vendor },
                            { sellerVendorWhiteLabelData: response.data.marketplace_vendor_white_label_data }
                        ])
                    })
                    .catch((error) => {
                        console.log('error getting data')
                        console.log(error)
                    })
            }
        },
        getTaxRate(inId, inTaxRates) {
            if (inTaxRates ?? false) {
                return inTaxRates.find((taxRate) => taxRate.id == inId)
            }

            return null
        },
        getTransactionStatus(inOrderStatus) {
            if (inOrderStatus == 'delivered') {
                return "Delivered"
            }

            if (inOrderStatus == 'shipped') {
                return "Shipped"
            }

            // if (inOrderStatus == 'sent') {
            //     return "Sent"
            // }

            if (inOrderStatus == 'cancelled') {
                return "Cancelled"
            }

            if (inOrderStatus == 'paid') {
                return "Paid"
            }

            if (inOrderStatus == 'pending') {
                return "Pending"
            }

            if (inOrderStatus == 'failed' || inOrderStatus == 'unprocessed') {
                return "Payment Failed"
            }

            return null
        },
        getVariantDescription(inProduct, inVariant, inVariantIdentifier) {
            let myDescription = ""
            let myVariantIdentifiers = inVariantIdentifier.split('|')

            inVariant.variants.split('|')
                .forEach((myVariant, index) => {
                    let myValue = inProduct.product_variants.variantValues.find((theValue) => theValue.identifier == myVariant)

                    if (myValue) {
                        myValue = myValue.values.find((theValue) => theValue.identifier == myVariantIdentifiers[index])

                        if (myValue) {
                            if (myDescription != "") {
                                myDescription = `${myDescription}  `
                            }

                            myDescription = `${myDescription}[${myValue.label}]`
                        }
                    }
                })

            return myDescription
        },
        getVariantPriceAdjustment(inProduct, inVariant, inVariantIdentifier) {
            let myPriceVariance = 0
            
            if (parseFloat(inVariant.overridePrice) ?? false) {
                if (parseFloat(inProduct.compare_at_price) ?? false) {
                    myPriceVariance = (parseFloat(inVariant.overridePrice) - parseFloat(inProduct.price.amount)) - (parseFloat(inProduct.compare_at_price) - parseFloat(inProduct.price.amount))
                }   
                else {
                    myPriceVariance = parseFloat(inVariant.overridePrice) - parseFloat(inProduct.price.amount)
                }
            }
            else {
                let myVariantIdentifiers = inVariantIdentifier.split('|')

                inVariant.variants.split('|')
                    .forEach((myVariant, index) => {
                        let myValue = inProduct.product_variants.variantValues.find((theValue) => theValue.identifier == myVariant)

                        if (myValue) {
                            myValue = myValue.values.find((theValue) => theValue.identifier == myVariantIdentifiers[index])

                            if (myValue) {
                                myPriceVariance = myPriceVariance + (myValue.priceVariance ?? 0)
                            }
                        }
                    })
            }

            return myPriceVariance
        },
        isDiscountCodes(inGroupBuy, inCustomerVendor, inProducts) {
            let isCodes = false

            if (inGroupBuy && inGroupBuy.discount_codes && inGroupBuy.discount_codes.length) {
                isCodes = true
            }

            if (!isCodes && inCustomerVendor && inCustomerVendor.discount_codes && inCustomerVendor.discount_codes.length) {
                isCodes = true
            }

            if (!isCodes && inProducts && inProducts.length) {
                inProducts.forEach((myInProduct) => {
                    if (myInProduct.discount_codes && myInProduct.discount_codes.length) {
                        isCodes = true
                    }
                })
            }

            return isCodes
        },
        isDiscountCodeValidForProduct(inCode, inGroupBuy, inCustomerVendor, inProduct) {
            let myCode = null

            if (inGroupBuy && inGroupBuy.discount_codes && inGroupBuy.discount_codes.length) {
                myCode = inGroupBuy.discount_codes.find((theCode) => theCode.code == inCode.code)
            }

            if (!myCode && inCustomerVendor && inCustomerVendor.discount_codes && inCustomerVendor.discount_codes.length) {
                myCode = inCustomerVendor.discount_codes.find((theCode) => theCode.code == inCode.code)
            }

            if (!myCode && inProduct && inProduct.discount_codes && inProduct.discount_codes.length) {
                let productCode = inProduct.discount_codes.find((theCode) => theCode.code == inCode.code)

                if (productCode) {
                    myCode = productCode
                }
            }

            return Boolean(myCode)
        },
        async marketplaceCompletePurchase(inIntentId, inType = 'payment', inPayPalSaleId, inPayPalData) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.post(this.getRoute('customerCompletePurchase'), {   
                    paymentIntentId: inType == 'payment' ? inIntentId : null,
                    setupIntentId: inType == 'setup' ? inIntentId : null,
                    sessionId: inType == 'session' ? inIntentId : null,
                    paypal: inType == 'paypal',
                    paypalSaleId: inPayPalSaleId,
                    paypalData: inPayPalData,
                    guestEmail: this.guestEmailAddress,
                    fbp: this.getCookie('_fbp'),
                    fbc: this.getCookie('_fbc'),
                    clientId: this.deviceId.identifier,
                    metaPixelTrackPurchaseValue: this.metaPixelTrackPurchaseValue,
                    deviceId: this.isMobileDevice ? this.deviceId.identifier : null,
                    browserId: !this.isMobileDevice ? this.deviceId.identifier : null
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    console.log('error completing purchase')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async marketplaceCreatePurchase(inPaymentIntentId, inVendorId, inVendorCurrency, inVendorTaxLabel, inProducts, inTotalAmount, inCommission, inShippingAmount, inShippingDetails, inCustomerDetails, inShippingTaxRate, inCustomerMessage, inCheckoutType, inPaypal, inMarketing) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.post(this.getRoute('customerCreatePurchase'), {
                    paymentIntentId: inPaymentIntentId,
                    vendorId: inVendorId,
                    currency: inVendorCurrency,
                    commission: inCommission,
                    shippingAmount: inShippingAmount,
                    shippingDetails: inShippingDetails,
                    customerDetails: inCustomerDetails,
                    totalAmount: inTotalAmount,
                    taxRateLabel: inVendorTaxLabel,
                    currencyData: this.currencies[inVendorCurrency],
                    shippingTaxRate: inShippingTaxRate,
                    products: inProducts,
                    customerMessage: inCustomerMessage,
                    whiteLabel: this.whiteLabel,
                    checkoutType: inCheckoutType,
                    fbp: this.getCookie('_fbp'),
                    fbc: this.getCookie('_fbc'),
                    paypal: inPaypal,
                    newShop: true,
                    marketing: inMarketing
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    console.log('error creating purchase')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async marketplaceGetFailedSale(inIntentId) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.get(this.getRoute('customerGetFailedPurchase'), {
                    params: {
                        intentId: inIntentId
                    }
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    console.log('error finding failed purchase')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async marketplaceProcessPayment(inPaymentIntentId, inPurchaseId) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.post(this.getRoute('customerPrepPayment'), {   
                    paymentIntentId: inPaymentIntentId,
                    purchaseId: inPurchaseId
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    console.log('error prepping payment')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async validateCustomerEmail(inEmailAddress, inCustomerDetails, inShippingDetails, inCreateAccount, inVendorId, inFromEOI = false) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.post(this.getRoute('customerValidateEmail'), {
                    email: inEmailAddress,
                    createAccount: inCreateAccount,
                    from_eoi: inFromEOI,
                    customer_details: inCustomerDetails,
                    shipping_details: inShippingDetails,
                    vendor_id: inVendorId
                })
                .then((response) => {
                    if (response.data.tempEmail) {
                        this.setStateData([
                            { tempGuestEmail: response.data.tempEmail }
                        ])
                    }

                    resolve(response.data)
                })
                .catch((error) => {
                    console.log('error validating email')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async verifyEmailCode(inEmailAddress, inCode) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.get(this.getRoute('customerVerifyEmailCode'), {
                    params: {
                        email: inEmailAddress,
                        verificationCode: inCode
                    }
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log('error verifying code')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        }
    }
}

export default marketplaceMixins