const menuMixins = {
    methods: {
        closeBuildsMenu() {
            this.setStateData([
                { buildsMenuOpen: false }
            ])
        },
        closeMainMenu() {
            this.setStateData([
                { mainMenuOpen: false }
            ])
        },
        openBuildsMenu() {
            this.setStateData([
                { buildsMenuOpen: true }
            ])
        },
        openMainMenu() {
            this.setStateData([
                { mainMenuOpen: true }
            ])
        },
        setSelectedMenu(inMenuName) {
            this.setStateData([
                { menuSelected: inMenuName }
            ])
        }
    }
}

export default menuMixins