<template>
    <div v-if="activity" :id="activity.id" class="relative text-white" :class="{ 'mb-2 sm:rounded-md': !hideBG, 'mb-2': hideBG, 'white-transparent': hideBG && (!whiteLabel || whiteLabelDark), 'black-transparent': hideBG && whiteLabel && whiteLabelLight }">
        <div v-if="galleryImage && !hideBG" class="absolute top-0 left-0 h-full w-full">
            <img class="object-cover h-full w-full" :src="transformUrl(galleryImage, ['optimize', 'md', 'q80'])" />
        </div>
        <div v-else-if="ogImage && !hideBG" class="absolute top-0 left-0 h-full w-full">
            <img class="object-cover h-full w-full" :src="transformUrl(ogImage, ['optimize', 'md', 'q80'])" />
        </div>
        <div v-else-if="!hideBG" class="absolute top-0 left-0 h-full w-full">
            <img class="object-cover h-full w-full" :src="transformUrl(defaultHero, ['optimize', 'md', 'q80'])" />
        </div>
        <div v-if="!hideBG" class="absolute top-0 left-0 h-full w-full black-blur-1">
        </div>
        <div v-if="isQuestion || isSale" class="absolute top-0 left-0 h-full w-full orange-transparent">
        </div>
        <div class="relative" :class="{ 'pt-12': modal }">
            <activity-header 
                :activity="activity" :modal="modal" :queryId="queryId" :position="position" 
            />
            <activity-body 
                :isIntersecting="isIntersecting" :activity="activity" :feedId="feedId" :modal="modal" :queryId="queryId" :position="position" 
                :showMoreTrigger="showMoreTrigger" :videosMuted="videosMuted" @clickMute="clickMute" 
            />
            <activity-footer v-if="!hideFooter"
                :activity="activity" :feedId="feedId" :minimal="minimal" :modal="modal" :queryId="queryId" :position="position" 
                :centerFooter="centerFooter" :buildId="buildId" :garageId="garageId" 
            />
        </div>
    </div>
</template>

<script>
import ActivityBody from './ActivityBody'
import ActivityFooter from './ActivityFooter'
import ActivityHeader from './ActivityHeader'

export default {
    props: ['isIntersecting', 'observer', 'activity', 'feedId', 'modal', 'minimal', 'position', 'queryId', 'showMoreTrigger', 'videosMuted', 'centerFooter', 'buildId', 'garageId', 'hideBG','hideFooter'],
    components: {
        ActivityBody,
        ActivityFooter,
        ActivityHeader
    },
    computed: {
        galleryImage() {
            return this?.activity?.attachments?.images && this.activity.attachments.images.length ? this.activity.attachments.images[0].url : null
        },
        isQuestion() {
            return this?.activity?.meta?.question ?? false
        },
        isSale() {
            return this?.activity?.meta?.sale ?? false
        },
        myOgData() {
            return this?.activity?.attachments?.og
        },
        ogImage() {
            return !this?.myOgData?.images ? null : (this?.myOgData?.images[0]?.image ? this.myOgData.images[0].image : this?.myOgData?.images[0]?.secure_url)
        }
    },
    mounted() {
        this.observer.observe(this.$el)
    },
    methods: {
        clickMute(inMute) {
            this.$emit('clickMute', inMute)
        }
    }
}
</script>
