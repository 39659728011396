const visitorMixins = {
    methods: {
        recordVisit() {
            let url = new URL(window.location.href)
            let myBzc = null
            let myBzu = null

            if (url.searchParams.get("bzc") ?? false) {
                myBzc = url.searchParams.get("bzc")
            }

            if (url.searchParams.get("bzu") ?? false) {
                myBzu = url.searchParams.get("bzu")
            }

            let myData = {
                browserId: !this.isMobileDevice ? this.deviceId.identifier : null,
                bzc: myBzc,
                bzu: myBzu,
                deviceId: this.isMobileDevice ? this.deviceId.identifier : null,
                fbc: this.getCookie('_fbc'),
                fbp: this.getCookie('_fbp')
            }

            buildzAxios?.post(this.getRoute('visit'), myData)
                .then((response) => {
                    
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
}

export default visitorMixins