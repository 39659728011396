<template>
    <div v-if="gallery" class="relative w-full">
        <div v-if="isVideo(0) || (imageStyle && imageStyle == 'full')" class="relative w-full h-full cursor-pointer" @touchStart="onTouchStart" @touchMove="onTouchMove" @touchStop="onTouchStop">
            <div v-if="isImage(0)" class="w-full h-full">
                <img @click="clickMe(0)" @error="retryImage(0)" :src="getImageUrl(0)" ref="gallery-0" class="object-cover w-full h-full" :loading="imageLoading" style="max-height: 500px;" />
            </div>
            <div v-else-if="isVideo(0)" class="w-full h-full overflow-hidden border full-video-max-height">
                <div class="w-full h-full overflow-hidden full-video-max-height">
                    <video-intersect class="w-full h-full" style="width: 100% !important; height: auto !important; max-height: 600px;"
                        :isIntersecting="isIntersecting" :modal="modal" :shouldPauseOnExit="pauseOnExit" :src="getVideoUrl(0)" :poster="getPosterUrl(0)" :attrs="videoAttrs"
                        @clickMute="clickMute" @clickMe="clickMe(0)" 
                    />
                </div>
            </div>
            <div v-if="gallery.length > 1" @click="clickMe(0)" class="absolute text-gray-800 flex items-center justify-center w-10 h-10 text-xs font-semibold bg-white border rounded-full shadow-md top-10 right-1" style="opacity: 0.67">
                1/{{ gallery.length }}
            </div>
        </div>
        <div v-else class="flex h-full cursor-pointer" :class="{'w-full': gallery.length == 1}" @touchStart="onTouchStart" @touchMove="onTouchMove" @touchStop="onTouchStop">
            <div v-if="isImage(0)" :class="{ 'w-1/2 fixed-height border-r': gallery.length > 1, 'w-full h-full': gallery.length == 1}">
                <img @click="clickMe(0)" @error="retryImage(0)" :src="getImageUrl(0)" ref="gallery-0" class="object-cover w-full h-full" :loading="imageLoading" />
            </div>
            <div v-else-if="isVideo(0)" :class="{ 'w-1/2 border-r': gallery.length > 1, 'h-full': gallery.length != 1, 'w-full h-full video-max-height overflow-hidden': isVideo(0) && gallery.length == 1}" >
                <div class="w-full h-full overflow-hidden video-max-height" ref="vidContainer0">
                    <video-intersect class="w-full h-full"
                        :isIntersecting="isIntersecting" :modal="modal" :shouldPauseOnExit="pauseOnExit" :src="getVideoUrl(0, 'vidContainer0')" :poster="getPosterUrl(0, 'vidContainer0')" :attrs="videoAttrs"
                        @clickMute="clickMute" @clickMe="clickMe(0)" 
                    />
                </div>
            </div>
            <div v-if="gallery.length >= 2 && gallery.length <= 4" class="h-full fixed-height" :class="{ 'w-1/2': gallery.length == 2 , 'w-1/4 border-r': gallery.length > 2 }" ref="vidContainer1">
                <img v-if="isImage(1)" @click="clickMe(1)" @error="retryImage(1)" :src="getImageUrl(1)" ref="gallery-1" class="object-cover w-full h-full" :loading="imageLoading" />
                <video-intersect v-else-if="isVideo(1)" class="w-full h-full"
                    :isIntersecting="isIntersecting" :modal="modal" :shouldPauseOnExit="pauseOnExit" :src="getVideoUrl(1, 'vidContainer1')" :poster="getPosterUrl(1, 'vidContainer1')" :attrs="videoAttrs"
                    @clickMute="clickMute" @clickMe="clickMe(1)" 
                />
            </div>
            <div v-if="gallery.length == 3" class="w-1/4 h-full fixed-height" ref="vidContainer2">
                <img v-if="isImage(2)" @click="clickMe(2)" @error="retryImage(2)" :src="getImageUrl(2)" ref="gallery-2" class="object-cover w-full h-full" :loading="imageLoading" />
                <video-intersect v-else-if="isVideo(2)" class="w-full h-full"
                    :isIntersecting="isIntersecting" :modal="modal" :shouldPauseOnExit="pauseOnExit" :src="getVideoUrl(2, 'vidContainer2')" :poster="getPosterUrl(2, 'vidContainer2')" :attrs="videoAttrs"
                    @clickMute="clickMute" @clickMe="clickMe(2)" 
                />
            </div>
            <div v-if="gallery.length == 4" class="w-1/4 fixed-height">
                <div class="w-full border-b half-height" ref="vidContainer2">
                    <img v-if="isImage(2)" @click="clickMe(2)" @error="retryImage(2)" :src="getImageUrl(2)" ref="gallery-2" class="object-cover w-full h-full" :loading="imageLoading" />
                    <video-intersect v-else-if="isVideo(2)" class="w-full h-full"
                        :isIntersecting="isIntersecting" :modal="modal" :shouldPauseOnExit="pauseOnExit" :src="getVideoUrl(2, 'vidContainer2')" :poster="getPosterUrl(2, 'vidContainer2')" :attrs="videoAttrs"
                        @clickMute="clickMute" @clickMe="clickMe(2)" 
                    />
                </div>
                <div class="w-full half-height" ref="vidContainer3">
                    <img v-if="isImage(3)" @click="clickMe(3)" @error="retryImage(3)" :src="getImageUrl(3)" ref="gallery-3" class="object-cover w-full h-full" :loading="imageLoading" />
                    <video-intersect v-else-if="isVideo(3)" class="w-full h-full"
                        :isIntersecting="isIntersecting" :modal="modal" :shouldPauseOnExit="pauseOnExit" :src="getVideoUrl(3, 'vidContainer3')" :poster="getPosterUrl(3, 'vidContainer3')" :attrs="videoAttrs"
                        @clickMute="clickMute" @clickMe="clickMe(3)" 
                    />
                </div>
            </div>
            <div v-if="gallery.length >= 5" class="w-1/4 fixed-height">
                <div class="w-full border-b border-r half-height" ref="vidContainer1">
                    <img v-if="isImage(1)" @click="clickMe(1)" @error="retryImage(1)" :src="getImageUrl(1)" ref="gallery-1" class="object-cover w-full h-full" :loading="imageLoading" />
                    <video-intersect v-else-if="isVideo(1)" class="w-full h-full"
                        :isIntersecting="isIntersecting" :modal="modal" :shouldPauseOnExit="pauseOnExit" :src="getVideoUrl(1, 'vidContainer1')" :poster="getPosterUrl(1, 'vidContainer1')" :attrs="videoAttrs"
                        @clickMute="clickMute" @clickMe="clickMe(1)" 
                    />
                </div>
                <div class="w-full border-r half-height" ref="vidContainer2">
                    <img v-if="isImage(2)" @click="clickMe(2)" @error="retryImage(2)" :src="getImageUrl(2)" ref="gallery-2" class="object-cover w-full h-full" :loading="imageLoading" />
                    <video-intersect v-else-if="isVideo(2)" class="w-full h-full"
                        :isIntersecting="isIntersecting" :modal="modal" :shouldPauseOnExit="pauseOnExit" :src="getVideoUrl(2, 'vidContainer2')" :poster="getPosterUrl(2, 'vidContainer2')" :attrs="videoAttrs"
                        @clickMute="clickMute" @clickMe="clickMe(2)" 
                    />
                </div>
            </div>
            <div v-if="gallery.length >= 5" class="w-1/4 fixed-height">
                <div class="w-full border-b half-height" ref="vidContainer3">
                    <img v-if="isImage(3)" @click="clickMe(3)" @error="retryImage(3)" :src="getImageUrl(3)" ref="gallery-3" class="object-cover w-full h-full" :loading="imageLoading" />
                    <video-intersect v-else-if="isVideo(3)" class="w-full h-full"
                        :isIntersecting="isIntersecting" :modal="modal" :shouldPauseOnExit="pauseOnExit" :src="getVideoUrl(3, 'vidContainer3')" :poster="getPosterUrl(3, 'vidContainer3')" :attrs="videoAttrs"
                        @clickMute="clickMute" @clickMe="clickMe(3)" 
                    />
                </div>
                <div class="relative w-full half-height" ref="vidContainer4">
                    <img v-if="isImage(4)" @click="clickMe(4)" @error="retryImage(4)" :src="getImageUrl(4)" ref="gallery-4" class="object-cover w-full h-full" :loading="imageLoading" />
                    <video-intersect v-else-if="isVideo(4)" class="w-full h-full"
                        :isIntersecting="isIntersecting" :modal="modal" :shouldPauseOnExit="pauseOnExit" :src="getVideoUrl(4, 'vidContainer4')" :poster="getPosterUrl(4, 'vidContainer4')" :attrs="videoAttrs"
                        @clickMute="clickMute" @clickMe="clickMe(4)" 
                    />
                    <div v-if="gallery.length > 5" @click="clickMe(4)" class="absolute top-0 left-0 flex items-center justify-center w-full h-full text-xl font-bold text-white bg-black bg-opacity-90">
                        {{ gallery.length - 4 }} more
                    </div>
                </div>
            </div>
        </div>
        <new-gallery v-if="gallery && isFullscreen" v-show="isFullscreen" 
            :gallery="gallery" :initialSlide="initialSlide" 
            @close="isFullscreen = false;" 
        />
    </div>
</template>

<script>
import NewGallery from '@/shared/NewGallery'
import VideoIntersect from '@/shared/VideoIntersect'

export default {
    props: ['gallery', 'imageStyle', 'containerId', 'modal', 'videosMuted', 'isIntersecting'],
    components: {
        NewGallery,
        VideoIntersect
    },
    data() {
        return {
            imageLoading: "eager",
            imageRetries: [],
            initialSlide: 0,
            isFullscreen: false,
            tapping: false
        }
    },
    computed: {
        pauseOnExit() {
            return !this.modal && this.userAutoplay
        },
        userAutoplay() {
            return this?.loggedUser?.settings?.autoplay ?? true
        },
        // userMuted() {
        //     return this?.loggedUser?.settings?.mute ?? true
        // },
        videoAttrs() {
            return {
                autoplay: false, 
                loop: true, 
                playsinline: true, 
                controls: !this.userAutoplay, 
                muted: this.videosMuted //this.userMuted
            }
        },
    },
    methods: {
        clickMe(inIndex) {
            this.initialSlide = inIndex 
            this.isFullscreen = true
        },
        clickMute(inMute) {
            this.$emit('clickMute', inMute)
        },
        getImageUrl(inIndex) {
            return this.transformUrl(this.gallery[inIndex].url, ['optimize', 'lg', 'q80'])
        },
        getPosterUrl(inIndex, inContainerRef) {
            let containerWidth = 0
            let containerHeight = 0

            if (inContainerRef) {
                let myContainer = this.getRectEl(this.$refs[inContainerRef])
                containerWidth = Math.round(myContainer.width)
                containerHeight = Math.round(myContainer.height)
            }
            else {
                let myContainer = this.getRect(this.containerId)
                containerWidth = Math.round(myContainer.width)
                containerHeight = 0
            }

            return this.posterUrl(this.gallery[inIndex].url,['optimize', 'video-md'], containerWidth, containerHeight)
        },
        getVideoUrl(inIndex, inContainerRef) {
            let containerWidth = 0
            let containerHeight = 0

            if (inContainerRef) {
                let myContainer = this.getRectEl(this.$refs[inContainerRef])
                containerWidth = Math.round(myContainer.width)
                containerHeight = Math.round(myContainer.height)
            }
            else {
                let myContainer = this.getRect(this.containerId)
                containerWidth = Math.round(myContainer.width)
                containerHeight = 0
            }
            
            return this.transformUrl(this.gallery[inIndex].url,['video', 'optimize', 'video-md'], containerWidth, containerHeight)
        },
        isImage(inIndex) {
            return this.gallery[inIndex].type == 'image'
        },
        isVideo(inIndex) {
            return this.gallery[inIndex].type == 'video'
        },
        onTouchStart() {
            this.tapping = true
        },
        onTouchMove() {
            this.tapping = false
        },
        onTouchStop(e) {
            if (e.type == "click" || this.tapping) {
                isFullscreen = !isFullscreen
            }

            this.tapping = false
        },
        retryImage(inIndex) {
            if (!this.imageRetries.includes(inIndex) && this.$refs[`gallery-${inIndex}`]) {
                this.imageRetries.push(inIndex)
                this.$refs[`gallery-${inIndex}`].src = null

                this.$nextTick(() => {
                    this.$refs[`gallery-${inIndex}`].src = this.getImageUrl(inIndex)
                })
            }
            else if (this.$refs[`gallery-${inIndex}`]) {
                this.$refs[`gallery-${inIndex}`].classList.add('hidden')
            }
        }
    }
}
</script>

<style scoped>
.fixed-height {
    height: 298px;
}

.half-height {
    height: 149px;
}

.video-max-height {
    max-height: 298px;
}

.full-video-max-height {
    max-height: 500px;
}
</style>
