<template>
    <div class="w-full">
        <div class="white-transparent p-2 mt-2">
            <div @click="clickCopyURL()" class="alt-button-base">
                Copy URL to Clipboard
            </div>
        </div>
        <panel-section
            :adminSection="true" sectionLabel="Gallery"
        >
            <edit-ab-gallery
                :gallery="gallery"
                @saveLocalGallery="saveLocalGallery" @updateOfflineMedia="updateOfflineMedia"
            />
        </panel-section>
        <panel-section
            :adminSection="true" sectionLabel="Detail Blocks"
        >
            <ab-media-blocks
                :abTest="abTest" :groupBuy="groupBuy" :pMediaBlocks="media_blocks" 
                @updateMediaBlocks="updateMediaBlocks"
            />
        </panel-section>
        <panel-section
            :adminSection="true" sectionLabel="Purchase Page Blocks"
        >
            <ab-purchase-media-blocks
                :abTest="abTest" :groupBuy="groupBuy" :pMediaBlocks="purchase_media_blocks" 
                @updatePurchaseMediaBlocks="updatePurchaseMediaBlocks"
            />
        </panel-section>
        <panel-section 
            :adminSection="true" sectionLabel="Customisation"
        >
            <edit-ab-white-label
                :groupBuy="groupBuy" :isGiveAway="isGiveAway" :pAbForm="abWhiteLabelForm"
                @updateWhiteLabel="updateWhiteLabel" 
            />
        </panel-section>
    </div>
</template>

<script>
import AbMediaBlocks from './AbMediaBlocks'
import AbPurchaseMediaBlocks from './AbPurchaseMediaBlocks'
import EditAbGallery from './EditAbGallery'
import EditAbWhiteLabel from './EditAbWhiteLabel'

export default {
    props: [
        'abTest',
        'groupBuy',
        'isGiveAway'
    ],
    components: {
        AbMediaBlocks,
        AbPurchaseMediaBlocks,
        EditAbGallery,
        EditAbWhiteLabel
    },
    data() {
        return {
            abWhiteLabelForm: {
                dollars_graphic: false,
                dollars_percentage_text: null,
                dollars_tracker: false,
                hide_info_bar: false,
                hide_offer_conditions: false,
                hide_offer_conditions_text_one: false,
                hide_offer_conditions_text_two: false,
                hide_products_description: false,
                hide_products_title_text: false,
                hide_share_button: false,
                hide_tracker_bar: false,
                hide_units_confirmed: false,
                join_button_text: null,
                maximum_allowed_text: null,
                minimum_required_text: null,
                offer_conditions_text_one: null,
                offer_conditions_text_two: null,
                products_title_text: null,
                register_button_text: null,
                share_button_text: null,
                type: null,
                units_confirmed_text: null
            },
            gallery: [],
            media_blocks: null
        }
    },
    mounted() {
        this.gallery = this.abTest.gallery ?? []
        this.media_blocks = this.abTest.media_blocks ?? []

        this.abWhiteLabelForm.dollars_graphic = this.abTest?.white_label_data?.dollars_graphic ?? false
        this.abWhiteLabelForm.dollars_percentage_text = this.abTest?.white_label_data?.dollars_percentage_text ?? this.defaultText.groupBuy.dollarsPercentageText
        this.abWhiteLabelForm.dollars_tracker = this.abTest?.white_label_data?.dollars_tracker ?? false
        this.abWhiteLabelForm.hide_info_bar = this.abTest?.white_label_data?.hide_info_bar ?? false
        this.abWhiteLabelForm.hide_offer_conditions = this.abTest?.white_label_data?.hide_offer_conditions ?? false
        this.abWhiteLabelForm.hide_offer_conditions_text_one = this.abTest?.white_label_data?.hide_offer_conditions_text_one ?? false
        this.abWhiteLabelForm.hide_offer_conditions_text_two = this.abTest?.white_label_data?.hide_offer_conditions_text_two ?? false
        this.abWhiteLabelForm.hide_offer_description = this.abTest?.white_label_data?.hide_offer_description ?? false
        this.abWhiteLabelForm.hide_products_description = this.abTest?.white_label_data?.hide_products_description ?? false
        this.abWhiteLabelForm.hide_products_title_text = this.abTest?.white_label_data?.hide_products_title_text ?? false
        this.abWhiteLabelForm.hide_tracker_bar = this.abTest?.white_label_data?.hide_tracker_bar ?? false
        this.abWhiteLabelForm.hide_units_confirmed = this.abTest?.white_label_data?.hide_units_confirmed ?? false
        this.abWhiteLabelForm.join_button_text = this.abTest?.white_label_data?.join_button_text ?? this.defaultText.groupBuy.joinButtonText
        this.abWhiteLabelForm.maximum_allowed_text = this.abTest?.white_label_data?.maximum_allowed_text ?? this.defaultText.groupBuy.maximumAllowedText
        this.abWhiteLabelForm.minimum_required_text = this.abTest?.white_label_data?.minimum_required_text ?? this.defaultText.groupBuy.minimumRequiredText
        this.abWhiteLabelForm.offer_conditions_text_one = this.abTest?.white_label_data?.offer_conditions_text_one ?? this.defaultText.groupBuy.offerConditionsTextLineOne
        this.abWhiteLabelForm.offer_conditions_text_two = this.abTest?.white_label_data?.offer_conditions_text_two ?? this.defaultText.groupBuy.offerConditionsTextLineTwo
        this.abWhiteLabelForm.products_title_text = this.abTest?.white_label_data?.products_title_text ?? this.defaultText.groupBuy.productsTitleText
        this.abWhiteLabelForm.register_button_text = this.abTest?.white_label_data?.register_button_text ?? this.defaultText.groupBuy.registerButtonText
        this.abWhiteLabelForm.share_button_text = this.abTest?.white_label_data?.share_button_text ?? this.defaultText.groupBuy.shareButtonText
        this.abWhiteLabelForm.type = this.groupBuy.type ?? "groupBuy"
        this.abWhiteLabelForm.units_confirmed_text = this.abTest?.white_label_data?.units_confirmed_text ?? this.defaultText.groupBuy.unitsConfirmedText
    },
    methods: {
        clickCopyURL() {
            let myURL = "https://"

            if (this.sellerVendor.white_label) {
                if (this.sellerVendor.linked_domain) {
                    myURL = `${myURL}${this.sellerVendor.linked_domain}`
                }
                else {
                    myURL = `${myURL}${this.sellerVendor.sub_domain}.buildz.store`
                }
            }
            else {
                myURL = `${myURL}app.buildz.pro`
            }

            myURL = `${myURL}/groupBuy/${this.groupBuy.id}?ab=${this.abTest.identifier}`

            navigator.clipboard.writeText(myURL)
                .then(() => {
                    this.pageMessage('success', 'Link copied to clipboard')
                })
                .catch(() => {
                    this.pageMessage('error', 'Unable to access your clipboard')
                })
        },
        async saveLocalGallery(inGallery) {
            this.gallery = inGallery
            
            let myOtherData = {
                identifier: this.abTest.identifier
            }

            await this.postGallery('sellerGroupBuyABTestGallery', inGallery, this.groupBuy.id, myOtherData)

            this.$emit('updateABTestGallery', this.gallery, this.abTest.identifier)
        },
        updateMediaBlocks(inForm) {
            this.$emit('updateABTestMediaBlocks', inForm, this.abTest.identifier)
        },
        updatePurchaseMediaBlocks(inForm) {
            this.$emit('updateABTestPurchaseMediaBlocks', inForm, this.abTest.identifier)
        },
        async updateOfflineMedia(inGallery) {
            this.gallery = inGallery
            let myTempGallery = await this.buildOfflineTempGallery(inGallery, `groupBuy_ab_${this.abTest.identifier}_temp${this.groupBuy.id}`)
            
            let myOtherData = {
                identifier: this.abTest.identifier
            }

            setTimeout(() => {
                this.saveGalleryPrep(myTempGallery, 'groupBuyABTestGalleryUpdate', this.groupBuy.id, 'sellerGroupBuyABTestGallery', myOtherData)
            }, 100)

            this.$emit('updateABTestGallery', this.gallery, this.abTest.identifier)
        },
        updateWhiteLabel(inForm, inImmediate) {
            this.$emit('updateABTestWhiteLabel', inForm, inImmediate, this.abTest.identifier)
        }
    }
}
</script>