<template>
    <div v-if="formLoaded" class="w-full">
        <select-panel v-if="!isGiveAway"
            :fieldValue="groupBuyForm.local_pickup" fieldLabel="Local Pickup" fieldFormName="local_pickup"
            :fieldData="yesNoData" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <location-panel
            :fieldDisplayValue="displayAddress" fieldFormName="location" fieldId="gb_location" fieldLabel="Shipping From" :fieldNonEdit="!pGroupBuyForm.disabled" 
            fieldPlaceholder="Please Type Your Location" :fieldRequired="true" :fieldValue="locationName" 
            :hideIcon="true" :hideFieldValue="groupBuyForm.hide_shipping_location" hideFieldFormName="hide_shipping_location" :placeTypes="['(cities)']"
            @fieldChange="getAddressData" @fieldInput="updateFormValue"
        >
            <validation-message v-if="!gbLocation" 
                message="Shipping From must be selected"
            />
        </location-panel>
        <date-picker-panel
            :fieldDisplayValue="pGroupBuy.eta" fieldFormName="eta" fieldLabel="E.T.A." :fieldNonEdit="!pGroupBuyForm.disabled" :fieldRequired="true"
            :fieldValue="dates.eta" :hideIcon="true" :hideFieldValue="groupBuyForm.hide_shipping_eta" hideFieldFormName="hide_shipping_eta"
            @fieldChange="updateFormDateValue" @fieldInput="updateFormValue"
        >
            <validation-message v-if="!dates.eta" 
                message="E.T.A must be selected"
            />
            <validation-message v-if="dates.eta && dates.eta < pEndTime" 
                :message="`E.T.A. must be after Group Offer end time (${getGroupBuyTime(pGroupBuy.end_time)})`"
            />
        </date-picker-panel>
        <select-panel v-if="!isGiveAway"
            :fieldData="shippingModels" fieldFormName="shipping_model" fieldLabel="Shipping Model" :fieldRequired="true"
            :fieldValue="groupBuyForm.shipping_model" :isReverseLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        >
            <validation-message v-if="!groupBuyForm.shipping_model" 
                message="Shipping model must be selected"
            />
            <validation-message v-if="!shippingCheckValidModel(shippingZones, groupBuyForm.shipping_model)" 
                message="There are no valid shipping zones for your selected shipping model"
            />
        </select-panel>
        <div class="w-full mt-px" v-if="groupBuyForm.shipping_model && !isGiveAway">
            <div class="w-full">
                <zone v-if="defaultShippingZone"
                    :zone="defaultShippingZone" :openZones="openZones" :editingZoneId="editingZoneId" :readOnly="!pGroupBuyForm.disabled"
                    :shippingModel="groupBuyForm.shipping_model"
                    @clickEditRate="clickEditRate" @clickDeleteRate="clickDeleteRate" @clickNewRate="clickNewRate" 
                    @toggleDefaultDisabled="toggleDefaultDisabled" @clickOpenZone="clickOpenZone" 
                    @clickCloseZone="clickCloseZone" @clickDeleteZone="clickDeleteZone" @clickEditZone="clickEditZone"
                    @updateZoneCountry="updateZoneCountry"
                />
                <div v-if="!defaultShippingZoneDisabled" class="w-full white-transparent mt-px">
                    <div class="w-full">
                        <div class="font-bold text-center relative">
                            <div class="w-full p-2">
                                Exclude Countries From Default Zone
                            </div>
                            <div @click="excludedCountriesOpen = true" v-show="!excludedCountriesOpen" class="cursor-pointer absolute right-4 top-2">
                                <fa-icon icon="chevron-left" type="fas" class="h-6 w-6" />
                            </div>
                            <div @click="excludedCountriesOpen = false" v-show="excludedCountriesOpen" class="cursor-pointer absolute right-4 top-2">
                                <fa-icon icon="chevron-down" type="fas" class="h-6 w-6" />
                            </div>
                        </div>
                        <div v-if="excludedCountriesOpen" class="round-tabs-container px-1 mt-1">
                            <template v-for="(country) in countryList" :key="key">
                                <div v-if="pGroupBuyForm.disabled" @click="toggleCountry(country.code)" class="round-tab flex items-center" :class="{ 'round-tab-off': !excludedCountry(country.code), 'round-tab-on': excludedCountry(country.code) }">
                                    {{ country.name }}
                                </div>
                                <div v-else @click="toggleCountry(country.code)" class="round-tab flex items-center" :class="{ 'round-tab-off': !excludedCountry(country.code), 'round-tab-on': excludedCountry(country.code) }">
                                    {{ country.name }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <zone v-for="zone in sortedShippingZones"
                    :zone="zone" :openZones="openZones" :editingZoneId="editingZoneId" :readOnly="!pGroupBuyForm.disabled"
                    :shippingModel="groupBuyForm.shipping_model"
                    @clickEditRate="clickEditRate" @clickDeleteRate="clickDeleteRate" @clickNewRate="clickNewRate" 
                    @toggleDefaultDisabled="toggleDefaultDisabled" @clickOpenZone="clickOpenZone" 
                    @clickCloseZone="clickCloseZone" @clickDeleteZone="clickDeleteZone" @clickEditZone="clickEditZone"
                    @updateZoneCountry="updateZoneCountry"
                />
                <div v-if="creatingNewZone" class="orange-transparent">
                    <select-panel
                        :fieldData="countryList" fieldDataLabel="name" fieldDataValue="code" fieldLabel="Zone Country" :fieldRequired="true"
                        :fieldValue="newZoneCountry"
                        @fieldChange="updateCreateFormValue"
                    />
                    <div class="w-full white-transparent mt-px py-4 px-2" :class="{ 'desktop-button-box justify-center': isDesktopWidth }">
                        <div @click="clickAddZone()" :class=" { 'standard-button-base': newZoneCountry, 'disabled-button-base': !newZoneCountry } ">
                            Add
                        </div>
                        <div @click="clickCancelAddZone()" class="alt-button-base" :class="{ 'ml-2': isDesktopWidth, 'mt-2': !isDesktopWidth }">
                            Cancel
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="pGroupBuyForm.disabled && !creatingNewZone" class="w-full white-transparent mt-px py-4 px-2" :class="{ 'desktop-button-box justify-center': isDesktopWidth }">
                <div @click="clickNewShippingZone()" class="standard-button-base">
                    Add shipping zone
                </div>
            </div>
        </div>
        <add-shipping-rate-modal v-if="showNewShippingRateModal" 
            :popupOpen="showNewShippingRateModal" :customRates="customRates" :rateType="newRateType"
            @closePopup="closeNewShippingRateModal" @saveNewRate="saveNewRate"
        />
        <edit-shipping-rate-modal v-if="showEditShippingRateModal && editRate" 
            :popupOpen="showEditShippingRateModal" :customRates="customRates" :shippingRate="editRate"
            @closePopup="closeEditShippingRateModal" @saveEditRate="saveEditRate"
        />
    </div>
</template>

<script>
import AddShippingRateModal from '@/shared/ShippingAdmin/AddShippingRateModal'
import EditShippingRateModal from '@/shared/ShippingAdmin/EditShippingRateModal'
import Zone from '@/shared/ShippingAdmin/Zone'

export default {
    props:[
        'isGiveAway',
        'pEndTime', 
        'pEta',
        'pFormLoaded', 
        'pGotGroupBuy',
        'pGroupBuy', 
        'pGroupBuyForm'
    ],
    components: {
        AddShippingRateModal,
        EditShippingRateModal,
        Zone
    },
    data() {
        return {
            autocompleteOptions: {
                types: ['(cities)']
            },
            creatingNewZone: false,
            customRates: null,
            dates: {
                eta: null
            },
            editingZoneId: null,
            editRate: null,
            editRateZoneId: null,
            editZoneCountry: null,
            excludedCountries: [],
            excludedCountriesOpen: false,
            formLoaded: false,
            groupBuyForm: {
                eta: null,
                hide_shipping_eta: false,
                hide_shipping_location: false,
                local_pickup: false,
                location: null,
                shipping_model: null
            },
            newRateType: null,
            newRateZoneId: null,
            newZoneCountry: null,
            openZones: [],
            shipping: [],
            shippingZones: null,
            showEditShippingRateModal: false,
            showNewShippingRateModal: false,
            validShippingModels: []
        }
    },
    computed: {
        defaultShippingZone() {
            return !this.shippingZones ? null : this.shippingZones.find((zone) => zone.id == 1)
        },
        defaultShippingZoneDisabled() {
            return this?.defaultShippingZone?.disabled
        },
        displayAddress() {
            return this?.pGroupBuy?.location?.address ?? null
        },
        eta() {
            return this.dates.eta
        },
        gbLocation() {
            return this?.pGroupBuyForm?.location ?? null
        },
        locationName() {
            return this?.pGroupBuyForm?.location?.address ?? null
        },
        sortedShippingZones() {
            return !this.shippingZones ? [] : this.shippingZones
                .filter((zone) => zone.id != 1)
                .sort((a, b) => a.name < b.name ? -1 : 1)
        }
    },
    mounted() {
        this.dates.eta = this.pEta
        this.groupBuyForm.eta = this.pGroupBuyForm.eta
        this.groupBuyForm.hide_shipping_eta = this.pGroupBuyForm.hide_shipping_eta
        this.groupBuyForm.hide_shipping_location = this.pGroupBuyForm.hide_shipping_location
        this.groupBuyForm.local_pickup = this.pGroupBuyForm.local_pickup
        this.groupBuyForm.location = this.pGroupBuyForm.location
        this.groupBuyForm.shipping_model = this.pGroupBuyForm.shipping_model
        this.validShippingModels = this.shippingGetValidModels(this.sellerVendorData.shippingZones)
        this.shipping = this.objCopy(this.pGroupBuy.shipping) ?? []

        if (this?.pGroupBuy?.shipping?.shippingZones ?? false) {
            this.shippingZones = this.pGroupBuy.shipping.shippingZones
        }
        else {
            this.shippingZones = this?.sellerVendorData?.shippingZones ?? [
                {
                    id: 1,
                    name: 'Default zone',
                    rates: []
                }
            ]
        }

        if (this?.pGroupBuy?.shipping?.customRates ?? false) {
            this.customRates = this.pGroupBuy.shipping.customRates
        }
        else {
            this.customRates = this.objCopy(this?.sellerVendorData?.customRates ?? [
                'New rate'
            ])
        }

        if (this?.pGroupBuy?.shipping?.excludedCountries ?? false) {
            this.excludedCountries = this.pGroupBuy.shipping.excludedCountries
        }
        else {
            this.excludedCountries = this.objCopy(this?.sellerVendorData?.excludedCountries ?? [])
        }

        this.shipping = {
            shippingZones: this.shippingZones,
            customRates: this.customRates,
            excludedCountries: this.excludedCountries
        }

        this.formLoaded = true
    },
    methods: {
        clickAddZone() {
            if (this.newZoneCountry) {
                let myZone = this.shippingZones
                    .sort((a, b) => a.id < b.id ? 1 : -1)[0]

                let myNewZone = {
                    id: myZone.id + 1,
                    countryCode: this.newZoneCountry,
                    name: this.coGetCountryName(this.newZoneCountry),
                    rateId: 0
                }

                if (!this.shippingZones) {
                    this.shippingZones = []
                }

                this.shippingZones.push(myNewZone)

                let myShipping = this.objCopy(this.shipping)
                myShipping.shippingZones = this.objCopy(this.shippingZones)
                this.shipping = this.objCopy(myShipping)
                this.$emit('updateShippingData', this.shipping)
                this.clickCancelAddZone()
            }
        },
        clickCancelAddZone() {
            this.newZoneCountry = null
            this.creatingNewZone = false
        },
        clickCloseZone(inZoneId) {
            if (this.openZones.includes(inZoneId)) {
                this.openZones = this.openZones.filter((zoneId) => zoneId != inZoneId)
            }
        },
        clickDeleteRate(inZoneId, inRateId) {
            if (confirm('You wish to delete this shipping rate?')) {
                let myZone = this.shippingZones.find((zone) => zone.id == inZoneId)

                if (myZone) {
                    let myRates = []

                    myZone.rates.forEach((rate) => {
                        if (rate.id != inRateId) {
                            myRates.push(rate)
                        }
                    })

                    myZone.rates = []
                    myZone.rates = myRates
                }

                let myShipping = this.objCopy(this.shipping)
                myShipping.shippingZones = this.shippingZones
                this.shipping = this.objCopy(myShipping)
                this.$emit('updateShippingData', this.shipping)
            }
        },
        clickDeleteZone(inZoneId) {
            if (confirm('You wish to delete this shipping zone?')) {
                let myZones = []

                this.shippingZones.forEach((zone) => {
                    if (zone.id != inZoneId) {
                        myZones.push(zone)
                    }
                })

                this.shippingZones = []
                this.shippingZones = myZones

                let myShipping = this.objCopy(this.shipping)
                myShipping.shippingZones = this.objCopy(this.shippingZones)
                this.shipping = this.objCopy(myShipping)
                this.$emit('updateShippingData', this.shipping)
            }
        },
        clickEditRate(inZoneId, inRate) {
            this.editRateZoneId = inZoneId
            this.editRate = inRate
            this.showEditShippingRateModal = true
        },
        clickEditZone(inZone) {
            if (inZone.id == this.editingZoneId) {
                this.editingZoneId = null
                this.editZoneCountry = null
            }
            else {
                this.editingZoneId = inZone.id
                this.editZoneCountry = inZone.countryCode
            }
        },
        clickNewRate(inZoneId, inType) {
            this.newRateZoneId = inZoneId
            this.newRateType = inType
            this.showNewShippingRateModal = true
        },
        clickNewShippingZone() {
            this.creatingNewZone = true
        },
        clickOpenZone(inZoneId) {
            if (!this.openZones.includes(inZoneId)) {
                this.openZones.push(inZoneId)
            }
        },
        closeEditShippingRateModal() {
            this.editRateZoneId = null
            this.showEditShippingRateModal = false
        },
        closeNewShippingRateModal() {
            this.newRateZoneId = null
            this.newRateType = null
            this.showNewShippingRateModal = false
        },
        excludedCountry(inCountry) {
            return this.excludedCountries.includes(inCountry)
        },
        getAddressData(inAddressData) {
            let myAddress = inAddressData.formatted_address
            
            let geoloc = {
                lat: inAddressData.geometry.location.lat,
                lng: inAddressData.geometry.location.lng
            }

            this.$emit('updateAddress', myAddress, geoloc)
        },
        isZoneOpen(inZoneId) {
            return this.openZones.includes(inZoneId)
        },
        saveEditRate(inForm) {
            let myZone = this.shippingZones.find((zone) => zone.id == this.editRateZoneId)

            if (inForm.newCustomRateName && inForm.saveNewCustomRateName) {
                this.customRates.push(inForm.newCustomRateName)
                inForm.customRateName = inForm.newCustomRateName
                inForm.newCustomRateName = null
            }

            inForm.saveNewCustomRateName = false

            if (this.shippingIsWeightRate(inForm)) {
                if (!inForm.minWeight) {
                    inForm.minWeight = 0
                }

                if (!inForm.maxWeight) {
                    inForm.maxWeight = 999999999
                }
            }
            else if (this.shippingIsPriceRate(inForm)) {
                if (!inForm.minPrice) {
                    inForm.minPrice = 0
                }

                if (!inForm.maxPrice) {
                    inForm.maxPrice = 999999999
                }
            }

            let myRates = []

            myZone.rates.forEach((rate) => {
                if (rate.id != this.editRate.id) {
                    myRates.push(rate)
                }
            })

            myRates.push(inForm)
            myZone.rates = myRates
            this.$emit('updateShippingZones', this.shippingZones, true)
        },
        saveNewRate(inForm) {
            console.log(inForm)
            let myZone = this.shippingZones.find((zone) => zone.id == this.newRateZoneId)

            console.log(myZone)

            if (!myZone.rates) {
                myZone.rates = []
            }

            if (inForm.newCustomRateName && inForm.saveNewCustomRateName) {
                this.customRates.push(inForm.newCustomRateName)
                inForm.customRateName = inForm.newCustomRateName
                inForm.newCustomRateName = null
            }

            inForm.saveNewCustomRateName = false

            if (this.shippingIsWeightRate(inForm)) {
                if (!inForm.minWeight) {
                    inForm.minWeight = 0
                }

                if (!inForm.maxWeight) {
                    inForm.maxWeight = 999999999
                }
            }
            else if (this.shippingIsPriceRate(inForm)) {
                if (!inForm.minPrice) {
                    inForm.minPrice = 0
                }

                if (!inForm.maxPrice) {
                    inForm.maxPrice = 999999999
                }
            }

            inForm.id = myZone.rateId + 1 ?? 1
            myZone.rateId = inForm.id
            myZone.rates.push(inForm)
            this.$emit('updateShippingZones', this.shippingZones, true)
            this.closeNewShippingRateModal()
        },
        saveShipping() {
            this.$emit('updateShipping', this.groupBuyForm, true, this.dates.eta)
        },
        toggleCountry(inCountry) {
            if (this.excludedCountries.includes(inCountry)) {
                let myExcludedCountries = this.objCopy(this.excludedCountries)
                myExcludedCountries = myExcludedCountries.filter((myCountry) => myCountry != inCountry)
                this.excludedCountries = this.objCopy(myExcludedCountries)
            }
            else {
                this.excludedCountries.push(inCountry)
            }

            let myShipping = this.objCopy(this.shipping)
            
            if (!myShipping.shippingZones) {
                myShipping.shippingZones = []
            }

            myShipping.shippingZones = this.objCopy(this.shippingZones)

            if (!myShipping.customRates) {
                myShipping.customRates = []
            }

            myShipping.customRates = this.customRates

            if (!myShipping.excludedCountries) {
                myShipping.excludedCountries = []
            }

            myShipping.excludedCountries = this.excludedCountries

            this.shipping = this.objCopy(myShipping)
            this.$emit('updateShippingData', this.shipping)
        },
        toggleDefaultDisabled() {
            let myZone = this.shippingZones.find((zone) => zone.id == 1)

            if (this.defaultShippingZoneDisabled) {
                myZone.disabled = false
            }
            else {
                myZone.disabled = true
            }

            let myShipping = this.objCopy(this.shipping)
            
            if (!myShipping.shippingZones) {
                myShipping.shippingZones = []
            }

            myShipping.shippingZones = this.objCopy(this.shippingZones)

            if (!myShipping.customRates) {
                myShipping.customRates = []
            }

            myShipping.customRates = this.customRates

            this.shipping = this.objCopy(myShipping)
            this.$emit('updateShippingData', this.shipping)
        },
        updateCreateFormValue(inField, inValue) {
            this.newZoneCountry = inValue
        },
        updateFormDateValue(inField, inValue) {
            let originalValue = Number.isInteger(this.dateTimeToUnixTime(this.dates[inField])) ? this.dateTimeToUnixTime(this.dates[inField]) : this.dates[inField] / 1000
            let newValue = Number.isInteger(this.dateTimeToUnixTime(inValue)) ? this.dateTimeToUnixTime(inValue) : inValue / 1000

            if (originalValue != newValue) {
                this.dates[inField] = inValue
                this.groupBuyForm[inField] = inValue
                this.$emit('updateShipping', this.groupBuyForm, true, this.dates.eta)
            }
        },
        updateFormSelectValue(inField, inValue) {
            this.groupBuyForm[inField] = inValue
            this.$emit('updateShipping', this.groupBuyForm, true, this.dates.eta)
        },
        updateFormValue(inField, inValue) {
            this.groupBuyForm[inField] = inValue
            this.$emit('updateShipping', this.groupBuyForm, false, this.dates.eta)
        },
        updateZoneCountry(inCountryCode) {
            let myZone = this.shippingZones.find((zone) => zone.id == this.editingZoneId)
            myZone.countryCode = inCountryCode
            myZone.name = this.coGetCountryName(inCountryCode)
            this.$emit('updateShippingZones', this.shippingZones, true)
        }
    }
}
</script>