<template>
    <div class="w-full white-transparent mt-px" :class="{ 'px-2 py-3': !isDesktopWidth || forceMobile }">
        <div class="w-full" :class="{ 'flex items-stretch': isDesktopWidth && !forceMobile }">
            <div :class="{ 'w-1/2 py-3 pl-2 pr-1': isDesktopWidth && !forceMobile, 'w-full': !isDesktopWidth || forceMobile }">
                <panel-label 
                    :fieldLabel="fieldLabel" :fieldLabelClasses="fieldLabelClasses" :fieldRequired="fieldRequired && !fieldNonEdit" :forceMobile="forceMobile" :hide="hideFieldModel"
                />
            </div>
            <div v-if="!fieldNonEdit" class="relative" :class="{ 'w-1/2 pr-2 py-3': isDesktopWidth && !forceMobile, 'w-full': !isDesktopWidth || forceMobile }">
                <GMapAutocomplete
                    type="text"
                    :options="autocompleteOptions"
                    :ref="fieldId"
                    :id="fieldId"
                    :name="fieldId"
                    class="w-full form-input edit-field-text-color py-1 text-center"
                    :class="{ 'pr-11': hideIcon, 'bg-white': !hideFieldModel, 'bg-gray-400 border-gray-400': hideFieldModel }"
                    :placeholder="fieldPlaceholder"
                    @place_changed="getAddressData"
                    :value="fieldModel"
                    @input="checkInput"
                    @focus="onFocus"
                    :autocomplete="fieldAutoCompleteValue"
                />
                <div v-if="hideIcon" class="absolute bg-orange-500 text-white w-12 rounded-r-md flex items-center justify-center cursor-pointer" :class="{ 'top-3 right-2': isDesktopWidth && !forceMobile, 'top-0 right-0': !isDesktopWidth || forceMobile }" style="height: 34px;">
                    <div v-if="hideFieldModel" @click="toggleHide()" class="cursor-pointer flex items-center">
                        <fa-icon icon="eye-slash" type="fas" class="h-6 w-6" />
                    </div>
                    <div v-if="!hideFieldModel" @click="toggleHide()" class="cursor-pointer flex items-center">
                        <fa-icon icon="eye" type="fas" class="h-6 w-6" />
                    </div>
                </div>
            </div>
            <div v-else :class="{ 'w-1/2 pr-2 py-3': isDesktopWidth && !forceMobile, 'w-full': !isDesktopWidth || forceMobile }">
                <div class="form-input edit-field-text-color bg-gray-400 border-gray-400 border py-1">
                    {{ fieldDisplayValue }}
                </div>
            </div>
        </div>
        <div v-if="fieldSubLabel && !fieldNonEdit" class="text-sm text-center p-2">
            {{ fieldSubLabel }}
        </div>
        <slot /> 
    </div>
</template>

<script>
export default {
    props: [
        'fieldAutoComplete',
        'fieldDisplayValue',
        'fieldFormName',
        'fieldFormSubObjectName',
        'fieldId', 
        'fieldLabel',
        'fieldLabelClasses',
        'fieldPlaceholder',
        'fieldNonEdit',
        'fieldRequired', 
        'fieldSubLabel',
        'fieldValue',
        'forceMobile',
        'hideFieldFormName',
        'hideFieldValue',
        'hideIcon',
        'placeTypes'
    ],
    data() {
        return {
            autocompleteOptions: {
                types: this.placeTypes
            },
            fieldModel: null,
            hideFieldModel: null
        }
    },
    computed: {
        fieldAutoCompleteValue() {
            if (this.fieldAutoComplete) {
                return this.fieldAutoComplete
            }

            return this.fieldStopAutoComplete ? this.getRandomString(20) : ''
        }
    },
    watch: {
        hideFieldValue: {
            handler(val) {
                this.hideFieldModel = val
            }
        }
    },
    mounted() {
        this.fieldModel = this.fieldValue
        this.hideFieldModel = this.hideFieldValue
    },
    methods: {
        checkInput() {
            // if (!this.elGet(this.fieldId).value) {
                this.$emit('fieldInput', this.fieldFormName, this.elGet(this.fieldId).value, this.fieldFormSubObjectName)
            // }
        },
        getAddressData(inAddressData) {
            this.$emit('fieldChange', inAddressData)
        },
        onFocus() {
            let myValue = this.elGet(this.fieldId).value
            this.elGet(this.fieldId).value = ""
            this.elGet(this.fieldId).value = myValue
        },
        toggleHide() {
            this.hideFieldModel = !this.hideFieldModel
            this.$emit('fieldInput', this.hideFieldFormName, this.hideFieldModel)
        }
    }
}
</script>