<template>
    <div>
        <div v-for="x in count" class="flasher-light-bg w-full mb-2 pt-2 pb-12">
            <div class="flex items-center justify-between mb-3 px-1">
                <div class="flex items-center justify-start">
                    <div class="w-8 h-8 rounded-full flasher-mid-bg flasher-mid-animate mr-3" style="min-width: 2rem;">
                    </div>
                    <div class="flex flex-col">
                        <div class="flasher-mid-bg rounded-full w-56 h-4 mb-1 flasher-mid-animate">
                        </div>
                        <div class="flasher-mid-bg rounded-full w-28 h-4 flasher-mid-animate">
                        </div>
                    </div>
                </div>
                <div class="flasher-mid-bg w-10 h-10 rounded-lg ml-3 flasher-mid-animate">
                </div>
            </div>
            <div class="px-1">
                <div v-for="x in 3" class="flasher-mid-bg rounded-full w-full h-4 mt-1 flasher-mid-animate">
                </div>
            </div>
            <div class="w-full mt-3 flasher-mid-bg flasher-mid-animate" style="height: 180px;">
            </div>
            <div class="px-1">
                <div class="flasher-mid-bg rounded-full w-56 h-4 mt-1 flasher-mid-animate">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['count']
}
</script>