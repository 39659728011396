<template>
    <div v-if="formLoaded" class="w-full">
        <div @click="collapsed = !collapsed" class="white-transparent p-1 cursor-pointer mt-px">
            <div v-if="collapsed" class="standard-button-base">
                Expand Blocks
            </div>
            <div v-else class="standard-button-base">
                Collapse Blocks
            </div>
        </div>
        <draggable v-if="mediaBlocks && mediaBlocks.length" 
            animation="250" easing="cubic-bezier(1, 0, 0, 1)" group="media" handle=".handle-media" item-key="index" :list="mediaBlocks" tag="div" 
            @change="saveOrder()"
        >
            <template #item="{element, index}">
                <div class="flex items-start mt-px w-full white-transparent overflow-hidden" :class="{ 'h-20': collapsed && editingIndex != index }">
                    <div v-if="!newText && !newImage &&!newYouTube && !newFeatures && editingIndex == null" class="cursor-move handle-media p-2">
                        <fa-icon icon="up-down-left-right" type="fas" class="h-6 w-6" />
                    </div>
                    <div v-if="element.type == 'text' && editingIndex != index" v-html="element.text" class="w-full py-2">
                    </div>
                    <div v-if="element.type == 'text' && editingIndex == index" class="w-full">
                        <rich-text-panel 
                            fieldFormName="editingTextDescription" fieldLabel="Edit Text Block" :fieldValue="editingTextDescription"
                            @fieldInput="updateEditingText"
                        />
                        <div class="w-full mt-px pt-2 pb-4 px-2 white-transparent" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
                            <div @click="clickCancelEditText()" class="alt-button-base mt-2">
                                Cancel
                            </div>
                            <div @click="clickSaveEditText()" class="standard-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                                Save
                            </div>
                        </div>
                    </div>
                    <div v-if="element.type == 'image'" class="w-full py-2">
                        <offline-media-card 
                            :element="element.image" :filesCount="1" :hideDelete="true" :index="0"
                            @updateElement="updateElement"
                        />
                    </div>
                    <div v-if="element.type == 'youtube' && editingIndex != index" class="w-full py-2" v-html="youtubeLinks(element.url)">
                    </div>
                    <div v-if="element.type == 'youtube' && editingIndex == index" class="w-full">
                        <input-panel 
                            fieldFormName="editingYouTubeUrl" fieldLabel="Edit YouTube Block" :fieldValue="editingYouTubeUrl"
                            @fieldInput="updateEditingUrl"
                        />
                        <div class="w-full mt-px pt-2 pb-4 px-2 white-transparent" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
                            <div @click="clickCancelEditYouTube()" class="alt-button-base mt-2">
                                Cancel
                            </div>
                            <div @click="clickSaveEditYouTube()" class="standard-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                                Save
                            </div>
                        </div>
                    </div>
                    <div v-if="element.type == 'feature' && editingIndex != index" class="w-full py-2">
                        <div v-for="listRecord in element.list">
                            <media-block-feature
                                :record="listRecord"
                                @updateMediaRecordImage="updateMediaRecordImage"
                            />
                        </div>
                    </div>
                    <div v-if="element.type == 'feature' && editingIndex == index" class="w-full">
                        <draggable
                            animation="250" easing="cubic-bezier(1, 0, 0, 1)" group="newList" handle=".handle-record" item-key="id" :list="editingFeatureList" tag="div" 
                            @change="saveEditListOrder()"
                        >
                            <template #item="{element, index}">
                                <edit-feature-list-record class="w-full mb-px"
                                    :record="element" :editRecordId="editEditFeatureListRecordId"
                                    @deleteRecord="deleteEditFeatureListRecord" @updateRecord="updateEditFeatureListRecord" @updateEditRecordId="updateEditEditFeatureListRecordId"
                                />
                            </template>
                        </draggable>
                        <div v-if="!editEditFeatureListRecordId" class="flex items-center px-1 py-2 relative white-transparent">
                            <form class="w-full" @submit.prevent="submitPrevent()">
                                <input id="newFeatureListField" type="text" maxlength="50" v-model="newFeatureListTextOne" @focus="focusNewFeatureListNewRecord()" @blur="blurNewFeatureListNewRecord()" @keydown.enter="addEditFeatureListRecord()" class="text-center py-1 w-full" :class="{ 'form-input edit-field-text-color bg-white': focusedNewFeatureListNewRecord, 'button-input bg-transparent font-medium placeholder-white cursor-pointer': !focusedNewFeatureListNewRecord }" placeholder="Add New Line">
                            </form>
                            <div @click="addEditFeatureListRecord()" v-if="newFeatureListTextOne" class="mx-2 cursor-pointer" :class="{ 'text-orange-500': newFeatureListTextOne }">
                                <fa-icon icon="circle-plus" type="fas" class="h-6 w-6" />
                            </div>
                        </div>
                        <div v-if="!editEditFeatureListRecordId" class="w-full mt-px pt-2 pb-4 px-2 white-transparent" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
                            <div @click="clickCancelEditFeatures()" class="alt-button-base mt-2">
                                Cancel
                            </div>
                            <div v-if="editingFeatureList.length" @click="clickSaveEditFeatures()" class="standard-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                                Save
                            </div>
                        </div>
                    </div>
                    <div v-if="!newText && !newImage &&!newYouTube && !newFeatures && editingIndex == null" class="w-10">
                        <div v-if="!newText && !newImage &&!newYouTube && !newFeatures && editingIndex == null" @click="clickDeleteMediaBlock(index, element.type)" class="cursor-pointer p-2">
                            <fa-icon icon="trash-can" type="fas" class="h-6 w-6" />
                        </div>
                        <div v-if="element.type == 'text' && !newText && !newImage && !newYouTube && !newFeatures && editingIndex == null" @click="clickEditTextBlock(index)" class="cursor-pointer p-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-6 w-6" />
                        </div>
                        <div v-if="element.type == 'youtube' && !newText && !newImage && !newYouTube && !newFeatures && editingIndex == null" @click="clickEditYouTubeBlock(index)" class="cursor-pointer p-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-6 w-6" />
                        </div>
                        <div v-if="element.type == 'feature' && !newText && !newImage && !newYouTube && !newFeatures && editingIndex == null" @click="clickEditFeaturesBlock(index)" class="cursor-pointer p-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-6 w-6" />
                        </div>
                    </div>
                </div>
            </template>
        </draggable>
        <div v-if="newText" class="w-full mt-px">
            <rich-text-panel 
                fieldFormName="newTextDescription" fieldLabel="New Text Block" :fieldValue="newTextDescription"
                @fieldInput="updateNewText"
            />
            <div class="w-full mt-px pt-2 pb-4 px-2 white-transparent" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
                <div @click="clickCancelNewText()" class="alt-button-base mt-2">
                    Cancel
                </div>
                <div @click="clickSaveNewText()" class="standard-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                    Save
                </div>
            </div>
        </div>
        <div v-if="newImage" class="w-full mt-px">
            <media-panel 
                fieldLabel="New Media Block" :hideSpinner="true" :imagesArray="[]" :imagesOnly="false" :maxFiles="1" :multiple="false"
                @saveMedia="saveLocalMedia" @updateOfflineMedia="updateOfflineMedia"
            />
            <div class="w-full mt-px pt-2 pb-4 px-2 white-transparent" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
                <div @click="clickCancelNewImage()" class="alt-button-base mt-2">
                    Cancel
                </div>
                <div @click="clickSaveNewImage()" class="standard-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                    Save
                </div>
            </div>
        </div>
        <div v-if="newYouTube" class="w-full mt-px">
            <input-panel 
                fieldFormName="newYouTubeUrl" fieldLabel="New YouTube Block" :fieldValue="newYouTubeUrl"
                @fieldInput="updateNewYouTube"
            />
            <div class="w-full mt-px pt-2 pb-4 px-2 white-transparent" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
                <div @click="clickCancelNewYouTube()" class="alt-button-base mt-2">
                    Cancel
                </div>
                <div @click="clickSaveNewYouTube()" class="standard-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                    Save
                </div>
            </div>
        </div>
        <div v-if="newFeatures" class="w-full mt-px">
            <div class="text-gray-200 text-center font-semibold w-full pb-2 pt-3 white-transparent" style="font-size: 1rem; line-height: 1.5rem;">
                New Features Block
            </div>
            <div class="white-transparent" :class="{ 'pt-px': newFeatureList && newFeatureList.length }">
                <draggable
                    animation="250" easing="cubic-bezier(1, 0, 0, 1)" group="newList" handle=".handle-record" item-key="id" :list="newFeatureList" tag="div" 
                    @change="saveNewListOrder()"
                >
                    <template #item="{element, index}">
                        <new-feature-list-record class="w-full mb-px"
                            :record="element" :editRecordId="editNewFeatureListRecordId"
                            @deleteRecord="deleteNewFeatureListRecord" @updateRecord="updateNewFeatureListRecord" @updateEditRecordId="updateEditNewFeatureListRecordId"
                        />
                    </template>
                </draggable>
                <div v-if="!editNewFeatureListRecordId" class="flex items-center px-1 py-2 relative white-transparent">
                    <form class="w-full" @submit.prevent="submitPrevent()">
                        <input id="newFeatureListField" type="text" maxlength="50" v-model="newFeatureListTextOne" @focus="focusNewFeatureListNewRecord()" @blur="blurNewFeatureListNewRecord()" @keydown.enter="addNewFeatureListRecord()" class="text-center py-1 w-full" :class="{ 'form-input edit-field-text-color bg-white': focusedNewFeatureListNewRecord, 'button-input bg-transparent font-medium placeholder-white cursor-pointer': !focusedNewFeatureListNewRecord }" placeholder="Add New Line">
                    </form>
                    <div @click="addNewFeatureListRecord()" v-if="newFeatureListTextOne" class="mx-2 cursor-pointer" :class="{ 'text-orange-500': newFeatureListTextOne }">
                        <fa-icon icon="circle-plus" type="fas" class="h-6 w-6" />
                    </div>
                </div>
            </div>
            <div v-if="!editNewFeatureListRecordId" class="w-full mt-px pt-2 pb-4 px-2 white-transparent" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
                <div @click="clickCancelNewFeatures()" class="alt-button-base mt-2">
                    Cancel
                </div>
                <div v-if="newFeatureList.length" @click="clickSaveNewFeatures()" class="standard-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                    Save
                </div>
            </div>
        </div>
        <div v-if="!newText && !newImage &&!newYouTube && !newFeatures && editingIndex == null" class="w-full white-transparent mt-px pt-2 pb-4 px-2" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
            <div @click="clickNewImage()" class="alt-button-base mt-2">
                New Media Block
            </div>
            <div @click="clickNewText()" class="alt-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                New Text Block
            </div>
            <div @click="clickNewYouTube()" class="alt-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                New YouTube Block
            </div>
            <div @click="clickNewFeatures()" class="alt-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                New Features Block
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

import EditFeatureListRecord from '@/shared/MediaBlocks/EditFeatureListRecord'
import MediaBlockFeature from '@/shared/MediaBlocks/MediaBlockFeature'
import NewFeatureListRecord from '@/shared/MediaBlocks/NewFeatureListRecord'
import OfflineMediaCard from '@/shared/Cards/OfflineMediaCard'

export default {
    props: [
        'product',
        'pVariantForm'
    ],
    components: {
        draggable,
        EditFeatureListRecord,
        MediaBlockFeature,
        NewFeatureListRecord,
        OfflineMediaCard
    },
    data() {
        return {
            collapsed: true,
            editEditFeatureListRecordId: null,
            editingFeatureList: null,
            editingIndex: null,
            editNewFeatureListRecordId: null,
            editingTextDescription: null,
            editingYouTubeUrl: null,
            focusedNewFeatureListNewRecord: false,
            formLoaded: false,
            mediaBlocks: [],
            newFeatureList: null,
            newFeatureListId: null,
            newFeatureListTextOne: null,
            newFeatures: false,
            newGallery: null,
            newImage: false,
            newText: false,
            newTextDescription: null,
            newYouTube: false,
            newYouTubeUrl: null,
            variantForm: {
                media_blocks: []
            }
        }
    },
    mounted() {
        this.variantForm.media_blocks = this.pVariantForm.media_blocks ?? []
        this.mediaBlocks = this.pVariantForm.media_blocks ?? []
        this.formLoaded = true
    },
    methods: {
        addEditFeatureListRecord() {
            let myId = 0

            this.editingFeatureList.forEach((myRecord) => {
                if (myRecord.id > myId) {
                    myId = myRecord.id
                }
            })
            
            let myNewRecord = {
                gallery: [],
                image: null,
                id: myId + 1,
                textOne: this.newFeatureListTextOne,
                textTwo: null,
                url: null
            }

            this.editingFeatureList.push(myNewRecord)
            this.editEditFeatureListRecordId = myNewRecord.id
            this.newFeatureListTextOne = null
        },
        addNewFeatureListRecord() {
            let myNewRecord = {
                gallery: [],
                image: null,
                id: this.newFeatureListId,
                textOne: this.newFeatureListTextOne,
                textTwo: null,
                url: null
            }

            this.newFeatureList.push(myNewRecord)
            this.editNewFeatureListRecordId = myNewRecord.id
            this.newFeatureListId = this.newFeatureListId + 1
            this.newFeatureListTextOne = null
        },
        blurNewFeatureListNewRecord() {
            this.focusedNewFeatureListNewRecord = false
        },
        clickCancelEditFeatures() {
            this.editingIndex = null
            this.editingFeatureList = null
            this.newFeatureListTextOne = null
        },
        clickCancelEditText() {
            this.editingIndex = null
            this.editingTextDescription = null
        },
        clickCancelEditYouTube() {
            this.editingIndex = null
            this.editingYouTubeUrl = null
        },
        clickCancelNewFeatures() {
            this.newFeatureList = null
            this.newFeatureListId = null
            this.newFeatureListTextOne = null
            this.newFeatures = false
        },
        clickCancelNewImage() {
            this.newGallery = null
            this.newImage = false
        },
        clickCancelNewText() {
            this.newTextDescription = null
            this.newText = false
        },
        clickCancelNewYouTube() {
            this.newYouTubeUrl = null
            this.newYouTube = false
        },
        clickDeleteMediaBlock(inIndex, inType) {
            let theType = inType == 'image' ? 'Image' : (inType == 'youtube' ? 'YouTube' : (inType == 'feature' ? 'Features' : 'Text'))

            if (confirm(`You wish to delete this ${theType} block?`)) {
                this.variantForm.media_blocks = this.variantForm.media_blocks.filter((myBlock, index) => index != inIndex)
                this.$emit('updateMediaBlocks', this.variantForm, true)
                this.mediaBlocks = this.variantForm.media_blocks
            }
        },
        clickEditFeaturesBlock(inIndex) {
            let editBlock = this.variantForm.media_blocks.find((myBlock, index) => index == inIndex && myBlock.type == 'feature')

            if (editBlock) {
                this.editingIndex = inIndex
                this.editingFeatureList = editBlock.list

                this.editingFeatureList.forEach((myRecord) => {
                    if (myRecord.image) {
                        myRecord.gallery = null
                        myRecord.gallery = [myRecord.image]
                    }
                })
            }
        },
        clickEditTextBlock(inIndex) {
            let editBlock = this.variantForm.media_blocks.find((myBlock, index) => index == inIndex && myBlock.type == 'text')

            if (editBlock) {
                this.editingIndex = inIndex
                this.editingTextDescription = editBlock.text
            }
        },
        clickEditYouTubeBlock(inIndex) {
            let editBlock = this.variantForm.media_blocks.find((myBlock, index) => index == inIndex && myBlock.type == 'youtube')

            if (editBlock) {
                this.editingIndex = inIndex
                this.editingYouTubeUrl = editBlock.url
            }
        },
        clickNewFeatures() {
            this.newFeatureList = null
            this.newFeatureList = []
            this.newFeatureListId = 1
            this.newFeatures = true

            this.$nextTick(() => {
                this.elGet('newFeatureListField').focus()
            })
        },
        clickNewImage() {
            this.newImage = true
        },
        clickNewText() {
            this.newText = true
        },
        clickNewYouTube() {
            this.newYouTube = true
        },
        async clickSaveEditFeatures() {
            this.editingFeatureList.forEach((myRecord) => {
                if (myRecord.gallery && myRecord.gallery.length) {
                    myRecord.image = myRecord.gallery[0]
                }
                else {
                    myRecord.image = null
                }
            })

            let myBlocks = []

            this.variantForm.media_blocks.forEach((myBlock, index) => {
                if (myBlock.type == 'feature' && index == this.editingIndex) {
                    myBlock.list = this.editingFeatureList    
                }
                
                myBlocks.push(myBlock)
            })

            this.variantForm.media_blocks = myBlocks
            this.$emit('updateMediaBlocks', this.variantForm, true)

            this.editingFeatureList.forEach(async (myRecord) => {
                let myTempGallery = await this.buildOfflineTempGallery(myRecord.gallery, `listingMBFeature_temp${this.product.id}_${myRecord.id}`)
                
                setTimeout(() => {
                    this.saveGalleryPrep(myTempGallery, 'listingMBFeatureGalleryUpdate', this.product.id, 'sellerProductMBFeatureGallery')
                }, 100)
            })

            this.editingFeatureList = null
            this.editingIndex = null
            this.mediaBlocks = this.variantForm.media_blocks
        },
        clickSaveEditText() {
            let myBlocks = []

            this.variantForm.media_blocks.forEach((myBlock, index) => {
                if (myBlock.type == 'text' && index == this.editingIndex) {
                    myBlock.text = this.editingTextDescription    
                }
                
                myBlocks.push(myBlock)
            })

            this.variantForm.media_blocks = myBlocks
            this.$emit('updateMediaBlocks', this.variantForm, true)
            this.editingTextDescription = null
            this.editingIndex = null
            this.mediaBlocks = this.variantForm.media_blocks
        },
        clickSaveEditYouTube() {
            let myBlocks = []

            this.variantForm.media_blocks.forEach((myBlock, index) => {
                if (myBlock.type == 'youtube' && index == this.editingIndex) {
                    myBlock.url = this.editingYouTubeUrl    
                }
                
                myBlocks.push(myBlock)
            })

            this.variantForm.media_blocks = myBlocks
            this.$emit('updateMediaBlocks', this.variantForm, true)
            this.editingYouTubeUrl = null
            this.editingIndex = null
            this.mediaBlocks = this.variantForm.media_blocks
        },
        async clickSaveNewFeatures() {
            this.newFeatureList.forEach((myRecord) => {
                if (myRecord.gallery && myRecord.gallery.length) {
                    myRecord.image = myRecord.gallery[0]
                }
            })

            let myFeaturesBlock = {
                type: 'feature',
                list: this.newFeatureList
            }

            this.newFeatureList.forEach(async (myRecord) => {
                let myTempGallery = await this.buildOfflineTempGallery(myRecord.gallery, `listingMBFeature_temp${this.product.id}_${myRecord.id}`)
                
                console.log('temp', myTempGallery)

                setTimeout(() => {
                    this.saveGalleryPrep(myTempGallery, 'listingMBFeatureGalleryUpdate', this.product.id, 'sellerProductMBFeatureGallery')
                }, 100)
            })
            
            this.variantForm.media_blocks.push(myFeaturesBlock)
            this.$emit('updateMediaBlocks', this.variantForm, true)
            this.newFeatures = false
            this.mediaBlocks = this.variantForm.media_blocks
        },
        async clickSaveNewImage() {
            let myImageBlock = {
                type: 'image',
                image: this.newGallery[0]
            }

            this.gallery = this.newGallery
            let myTempGallery = await this.buildOfflineTempGallery(this.newGallery, `listingMB_temp${this.product.id}`)
            
            setTimeout(() => {
                this.saveGalleryPrep(myTempGallery, 'listingMBGalleryUpdate', this.product.id, 'sellerProductMBGallery')
            }, 100)
            
            this.variantForm.media_blocks.push(myImageBlock)
            this.$emit('updateMediaBlocks', this.variantForm, true)
            this.newGallery = null
            this.newImage = false
            this.mediaBlocks = this.variantForm.media_blocks
        },
        clickSaveNewText() {
            let myTextBlock = {
                type: 'text',
                text: this.newTextDescription
            }
            
            this.variantForm.media_blocks.push(myTextBlock)
            this.$emit('updateMediaBlocks', this.variantForm, true)
            this.newTextDescription = null
            this.newText = false
            this.mediaBlocks = this.variantForm.media_blocks
        },
        clickSaveNewYouTube() {
            let myYouTubeBlock = {
                type: 'youtube',
                url: this.newYouTubeUrl
            }
            
            this.variantForm.media_blocks.push(myYouTubeBlock)
            this.$emit('updateMediaBlocks', this.variantForm, true)
            this.newYouTubeUrl = null
            this.newYouTube = false
            this.mediaBlocks = this.variantForm.media_blocks
        },
        deleteEditFeatureListRecord(inRecordId) {
            this.editingFeatureList = this.editingFeatureList.filter((myRecord) => myRecord.id != inRecordId)
        },
        deleteImage() {

        },
        deleteNewFeatureListRecord(inRecordId) {
            this.newFeatureList = this.newFeatureList.filter((myRecord) => myRecord.id != inRecordId)
        },
        focusNewFeatureListNewRecord() {
            this.focusedNewFeatureListNewRecord = true
        },
        saveEditListOrder() {

        },
        saveLocalMedia(inGallery) {
            this.newGallery = inGallery
        },
        saveNewListOrder() {

        },
        saveOrder() {
            this.variantForm.media_blocks = this.mediaBlocks
            this.$emit('updateMediaBlocks', this.variantForm, true)
        },
        submitPrevent() {
            this.addNewFeatureListRecord()
        },
        updateEditEditFeatureListRecordId(inRecordId) {
            this.editEditFeatureListRecordId = inRecordId
        },
        updateEditFeatureListRecord(inRecord) {
            let myRecord = this.editingFeatureList.find((myRecord) => myRecord.id == inRecord.id)
            myRecord.gallery = inRecord.gallery
            myRecord.textOne =  inRecord.textOne
            myRecord.textTwo =  inRecord.textTwo
            myRecord.url = inRecord.url
        },
        updateEditingText(inField, inValue) {
            this.editingTextDescription = inValue
        },
        updateEditingUrl(inField, inValue) {
            this.editingYouTubeUrl = inValue
        },
        updateEditNewFeatureListRecordId(inRecordId) {
            this.editNewFeatureListRecordId = inRecordId
        },
        updateElement(inImage, inIndex) {
            let myBlocks = []

            this.variantForm.media_blocks.forEach((myBlock) => {
                if (myBlock.type == 'image' && myBlock.image.identifier == inImage.identifier) {
                    myBlock.image = inImage    
                }
                
                myBlocks.push(myBlock)
            })

            this.variantForm.media_blocks = myBlocks
            this.mediaBlocks = this.variantForm.media_blocks
        },
        updateMediaRecordImage(inImage) {
            let myBlocks = []

            this.variantForm.media_blocks.forEach((myBlock) => {
                let myList = []

                if (myBlock.type == 'feature' && myBlock.list && myBlock.list.length) {
                    myBlock.list.forEach((myLine) => {
                        console.log('my', myLine.image)
                        if (myLine.image && myLine.image.identifier && myLine.image.identifier == inImage.identifier) {
                            myLine.image = inImage
                        }

                        myList.push(myLine)
                    })

                    myBlock.list = null
                    myBlock.list = myList
                }
                
                myBlocks.push(myBlock)
            })

            this.variantForm.media_blocks = myBlocks
            this.mediaBlocks = this.variantForm.media_blocks
        },
        updateNewFeatureListRecord(inRecord) {
            let myRecord = this.newFeatureList.find((myRecord) => myRecord.id == inRecord.id)
            myRecord.gallery = inRecord.gallery
            myRecord.textOne =  inRecord.textOne
            myRecord.textTwo =  inRecord.textTwo
            myRecord.url = inRecord.url
        },
        updateNewText(inField, inValue) {
            this.newTextDescription = inValue
        },
        updateNewYouTube(inField, inValue) {
            this.newYouTubeUrl = inValue
        },
        updateOfflineMedia(inGallery) {
            this.newGallery = inGallery
        }
    }
}
</script>