const savingMixins = {
    methods: {
        saveDone() {
            let newTotal = this.savingTotal - 1

            this.setStateData([
                { savingTotal: newTotal }
            ])

            if (newTotal < 1) {
                this.setStateData([
                    { saving: false },
                    { savingTotal: 0 },
                    { saved: true },
                    { mediaProgressTotal: 0 },
                    { mediaProgress: 0 }
                ])

                setTimeout(() => {
                    this.setStateData([
                        { saved: false }
                    ])
                }, 3000)
            }

            // console.log('saveDone', newTotal)
        },
        saveError() {
            this.setStateData([
                { savingTotal: 0 },
                { saving: false },
                { savingError: true }
            ])

            setTimeout(() => {
                this.setStateData([
                    { savingError: false }
                ])
            }, 5000)
        },
        saveStart() {
            let newTotal = this.savingTotal + 1

            this.setStateData([
                { saving: true },
                { savingTotal: newTotal }
            ])

            // console.log('saveStart', newTotal)
        }
    }
}

export default savingMixins