const productMixins = {
    methods: {
        closeEditListingModal() {
            this.setStateData([
                { showEditListingModal: false },
                { productToEdit: null }
            ])
        },
        doIHaveOne(inProduct) {
            return this.loggedUser && this.loggedUser.products && this.loggedUser.products.length && this.loggedUser.products.includes(inProduct.id)
        },
        closeNewListingModal() {
            this.setStateData([
                { showNewListingModal: false }
            ])
        },
        openEditListingModal(inProduct) {
            this.setStateData([
                { showEditListingModal: true },
                { productToEdit: inProduct }
            ])
        },
        openNewListingModal() {
            this.setStateData([
                { showNewListingModal: true }
            ])
        },
        saveNewProduct(inProductId, inForm) {
            this.saveStart()

            buildzAxios?.post(this.getRoute('sellerProductUpdate', inProductId), inForm)
                .then((response) => {
                    let myRecord = Object.entries(this.vendorProducts).find((entry) => entry[1].id == inProductId)

                    if (myRecord) {
                        let myRecords = this.objCopy(this.vendorProducts)
                        myRecords[myRecord[0]] = this.objCopy(response.data.product)

                        this.setStateData([
                            { vendorProducts: myRecords },
                            { productEditing: response.data.product }
                        ])
                    }

                    this.saveDone()
                })
                .catch((error) => {
                    console.log(error)
                    this.saveDone()
                    this.pageMessage('error', 'Error updating listing')
                })
        }
    }
}

export default productMixins