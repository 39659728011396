<template>
    <modal v-if="showWritePostReaction" :open="showWritePostReaction" @close="closeWritePostReaction" :stopUnscroll="true">
        <div class="fixed top-0 left-0 h-full w-full gray-bg">
        </div>
        <div class="absolute top-0 left-0 w-full text-white" :class="{ 'pb-16': windowWidth < desktopMaxWidth, 'desktop-modal-box': windowWidth >= desktopMaxWidth }">
            <div :class="{ 'desktop-modal': windowWidth >= desktopMaxWidth }">
                <div class="section-heading mb-3 px-1 text-center mt-4">
                    {{ headingText }}
                </div>
                <div class="px-1">
                    <div class="flex align-start">
                        <div class="flex-none w-12 h-12 mr-2 rounded-full border-2 border-orange-500">
                            <user-avatar :url="loggedUser ? loggedUser.avatar : null" :rounded="true" />
                        </div>
                        <div class="flex-grow">
                            <mentions
                                :keys="['@', '+']" offset="6" inputId="newPostReactionText"
                                @apply="postBuildImage"
                            >
                                <textarea id="newPostReactionText" v-model="text" rows="6" class="w-full p-2 text-sm bg-white border border-gray-300 rounded focus:border-orange-500 focus:ring-orange-300 edit-field-text-color" placeholder="Type your comment...">
                                </textarea>
                            </mentions>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="flex-grow">
                            <media-panel v-if="mounted"
                                fieldLabel="Media" :hideSpinner="true" :imagesArray="gallery" :imagesOnly="false" :maxFiles="1" :multiple="false"
                                @saveMedia="saveLocalGallery" @updateOfflineMedia="updateOfflineMedia"
                            />
                        </div>
                    </div>
                    <div class="flex justify-end mt-3">
                        <div @click="clickPost" class="px-3 py-1 text-sm font-medium leading-5 text-white rounded-md" :class="{ 'bg-orange-500 cursor-pointer': !postingReaction, 'bg-gray-300 cursor-not-allowed': postingReaction }">
                            Post
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="postingReaction" class="absolute top-0 left-0 flex items-center justify-center w-full h-full text-orange-400">
                <fa-icon icon="spinner" type="fas" class="h-14 w-14 spin" />
            </div>
        </div>
    </modal>
</template>

<script>
import twitter from 'twitter-text'

import Modal from '@/shared/Modal'
import Mentions from './Mentions'

export default {
    components: {
        Modal,
        Mentions
    },
    data() {
        return {
            gallery: [],
            text: null,
            mounted: false
        }
    },
    computed: {
        headingText() {
            return this.postReactionToEdit ? "Edit Comment" : "New Comment"
        },
    },
    mounted() {
        if (this.postReactionToEdit) {
            if (this.postReactionToEdit.data.image) {
                this.gallery = [this.postReactionToEdit.data.image]
            }
            
            if (this.postReactionToEdit.data.object) {
                this.text = this.postReactionToEdit.data.object
            }
        }

        this.mounted = true
    },
    methods: {
        async clickPost() {
            if (!this.postingReaction) {
                this.setStateData([ 
                    { postingReaction: true }
                ])

                let feeds = {}
                let pattern = /\B\+[a-z0-9_-]+/gi
                
                feeds['builds'] = this.text.match(pattern)?.map(build => {
                    return build.substring(1)
                }) || []

                feeds['mentions'] = twitter?.extractMentions(this.text)
                feeds['hashtags'] = twitter?.extractHashtags(this.text)

                if (this.gallery.length) {
                    this.gallery.forEach((item) => {
                        if (item.url) {
                            item.url = item.url.replace(/\/v[0-9].*?\//gm,'/')   // strip version
                            item.url = item.url.replace(/\/l_buildz_logo_black_plate_zbi4al,fl_region_relative,w_1.4,g_ocr_text\//gm,'/')   // strip existing ocr
                            
                            if (item.type == 'image' && item.blur){
                                item.url = item.url.replace(/\/buildz_pro_public\/.*/gm, "/l_buildz_logo_black_plate_zbi4al,fl_region_relative,w_1.4,g_ocr_text$&")
                            }
                        }
                    })

                    let myTempGallery = await this.buildOfflineTempGallery(this.gallery, `reaction_${this.getRandomString(20)}`)

                    if (this.postReactionToEdit) {
                        let myOtherData = {
                            text: this.text, 
                            feeds: feeds, 
                            reactionId: this.postReactionToEdit.id,
                            activityId: this?.activityToShow?.id ?? null
                        }

                        setTimeout(() => {
                            this.saveGalleryPrep(myTempGallery, 'reactionUpdate', this.getRandomString(20), null, myOtherData)
                        }, 100)
                    }
                    else {
                        let myOtherData = {
                            text: this.text, 
                            feeds: feeds
                        }

                        setTimeout(() => {
                            this.saveGalleryPrep(myTempGallery, 'reactionNew', this.getRandomString(20), null, myOtherData)
                        }, 100)
                    }
                }
                else {
                    if (this.postReactionToEdit) {
                        this.updatePostReaction(this.text, null, feeds, this.postReactionToEdit.id, this.activityToShow?.id)
                    }
                    else {
                        this.newPostReaction(this.text, null, feeds)
                    }
                }
            }
        },
        postBuildImage(inItem, inKey) {
            if (inKey == "+" && inItem.avatar && inItem.avatar.includes('res.cloudinary.com') && this.gallery.length == 0) {
                let myImage = {
                    url: inItem.avatar,
                    type: "image"
                }

                this.gallery?.push(myImage)
            }
        },
        saveLocalGallery(inGallery) {
            this.gallery = inGallery
        },
        updateOfflineMedia(inGallery) {
            this.gallery = inGallery
        }
    }
}
</script>