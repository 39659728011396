<template>
    <div class="block">
        <sale-header-seller
            :sale="sale" :showClose="true"
            @closeSaleCard="closeSaleCard"
        />
        <div v-if="sellerVendorWhiteLabelData" class="white-transparent mb-1 py-2 flex items-center justify-center text-xl">
            <div v-if="sellerVendorWhiteLabelData && sale.white_label" class="rounded-full h-10 w-10 mr-2" style="min-width: 2.5rem;">
                <img :src="sellerVendorWhiteLabelData.logo" class="rounded-full h-full w-full object-cover" />
            </div>
            <div v-else-if="sellerVendorWhiteLabelData && !sale.white_label" class="rounded-full h-10 w-10 mr-2" style="min-width: 2.5rem;">
                <img :src="buildzLogo" class="rounded-full h-full w-full object-cover" />
            </div>
            <div v-if="sale.white_label">
                Sold on {{ whiteLabelUrl }}
            </div>
            <div v-else>
                Sold on buildz.pro
            </div>
        </div>
        <div class="w-full" :class="{ 'flex items-stretch': isDesktopWidth }">
            <div :class="{ 'w-60p': isDesktopWidth, 'w-full': !isDesktopWidth }">
                <sale-detail 
                    :fromCustomer="false" :minimal="false" :sale="sale" :singleLine="singleLine"
                    @clickSeeAllLines="clickSeeAllLines"
                />
            </div>
            <div :class="{ 'w-40p pl-1': isDesktopWidth, 'w-full mt-1': !isDesktopWidth }">
                <sale-total-box
                    :sale="sale" :showPurchaseLink="true"
                >
                    <template #buttons>
                        <div v-show="!showPaymentReminderPanel && sale.order_status == 'failed'" @click="showPaymentReminderPanel = true" class="alt-button-base mb-2">
                            Send Payment Reminder
                        </div>
                        <div v-if="showPaymentReminderPanel">
                            <date-picker-panel
                                fieldFormName="payByDate" fieldLabel="Pay By Time" :fieldValue="payByDate" :forceMobile="true"
                                @fieldChange="updatePayByDate"
                            >
                            </date-picker-panel>
                            <div v-if="lastReminder" class="w-full white-transparent mt-px p-2 text-xs text-center">
                                Last sent {{ lastReminder }}
                            </div>
                            <div class="w-full white-transparent mt-px p-2 mb-2">
                                <div @click="clickSendPaymentReminder()" :class="{ 'standard-button-base': payByDate, 'disabled-button-base': !payByDate }">
                                    Send
                                </div>
                                <div @click="showPaymentReminderPanel = false" class="alt-button-base mt-2">
                                    Cancel
                                </div>
                            </div>
                        </div>
                        <div v-show="sale.order_status == 'paid' && sale.group_buy && needsGroupBuyRefund" @click="clickGroupBuyRefund()" class="alt-button-base mb-2">
                            Offer Refund {{ refundAmount }}
                        </div>
                        <div v-show="!showRefundPanel && sale.paid && sale.paid_amount - sale.refund_amount > 0" @click="clickOpenRefundPanel()" class="alt-button-base mb-2">
                            Refund
                        </div>
                        <div v-if="showRefundPanel">
                            <input-panel
                                fieldFormName="saleRefundAmount" fieldLabel="Refund Amount" fieldType="number" :fieldValue="saleRefundAmount" :forceMobile="true"
                                @fieldInput="updateRefundAmount"
                            >
                            </input-panel>
                            <input-panel
                                fieldFormName="saleRefundDescription" fieldLabel="Refund Reason" fieldType="text" :fieldValue="saleRefundDescription" :forceMobile="true"
                                @fieldInput="updateRefundDescription"
                            >
                            </input-panel>
                            <div class="w-full white-transparent mt-px p-2 mb-2">
                                <div @click="clickRefund()" :class="{ 'standard-button-base': isRefundAmountOkay, 'disabled-button-base': !isRefundAmountOkay }">
                                    Confirm
                                </div>
                                <div @click="showRefundPanel = false" class="alt-button-base mt-2">
                                    Cancel
                                </div>
                            </div>
                        </div>
                        <div v-show="sale.order_status != 'shipped' && sale.order_status != 'delivered' && sale.order_status != 'cancelled'" @click="clickCancel()" class="alt-button-base mb-2">
                            Cancel sale
                        </div>
                        <div v-show="!showChangeProcessDatePanel && sale.order_status == 'pending' && sale.group_buy" @click="showChangeProcessDatePanel = true" class="alt-button-base mb-2">
                            Change Process Date
                        </div>
                        <div v-if="showChangeProcessDatePanel">
                            <date-picker-panel
                                fieldFormName="saleProcessDate" fieldLabel="Select Process Date" :fieldValue="saleProcessDate" :forceMobile="true"
                                @fieldChange="updateProcessDate"
                            >
                            </date-picker-panel>
                            <div class="w-full white-transparent mt-px p-2 mb-2">
                                <div @click="clickUpdateProcessDate()" :class="{ 'standard-button-base': saleProcessDate, 'disabled-button-base': !saleProcessDate }">
                                    Update
                                </div>
                                <div @click="showChangeProcessDatePanel = false" class="alt-button-base mt-2">
                                    Cancel
                                </div>
                            </div>
                        </div>
                        <div v-show="sale.order_status == 'paid'" @click="clickShipped()" class="alt-button-base mb-4">
                            Mark as shipped
                        </div>
                        <div v-show="sale.order_status == 'shipped'" @click="clickDelivered()" class="alt-button-base mb-4">
                            Mark as delivered
                        </div>
                    </template>
                </sale-total-box>
            </div>
        </div>
    </div>
</template>

<script>
import SaleDetail from '@/shared/Marketplace/SaleDetail'
import SaleHeaderSeller from '@/shared/Marketplace/SaleHeaderSeller'
import SaleTotalBox from '@/shared/Marketplace/SaleTotalBox'

export default {
    props: [
        'needsGroupBuyRefund',
        'refundAmount',
        'sale'
    ],
    components: {
        SaleDetail,
        SaleHeaderSeller,
        SaleTotalBox
    },
    data() {
        return {
            payByDate: null,
            saleProcessDate: null,
            saleRealRefundAmount: null,
            saleRefundAmount: null,
            saleRefundDescription: null,
            showChangeProcessDatePanel: false,
            showPaymentReminderPanel: false,
            showRefundPanel: false,
            singleLine: true
        }
    },
    computed: {
        isRefundAmountOkay() {
            return this.saleRealRefundAmount && this.saleRealRefundAmount > 0 && this.saleRealRefundAmount <= this.sale.total - this.sale.refund_amount
        },
        lastReminder() {
            let myReminders = this.sale.data.reminder_history ?? null

            if (myReminders) {
                return this.getLocalUnixTime(myReminders[myReminders.length - 1].sent_date)
            }

            return null
        },
        whiteLabelUrl() {
            if (!this.sellerVendorWhiteLabelData) {
                return null
            }

            return this.sellerVendor.linked_domain ?? `${this.sellerVendor.sub_domain}.buildz.store`
        }
    },
    mounted() {
        this.payByDate = this.sale.pay_by_date && this.sale.pay_by_date > 0 ? this.sale.pay_by_date *  1000 : (this.currentUnixTime() + 604800) * 1000 
    },
    methods: {
        clickCancel() {
            this.$emit('clickCancel', this.sale.id)
        },
        clickDelivered() {
            this.$emit('clickDelivered', this.sale.id)
        },
        clickGroupBuyRefund() {
            this.$emit('clickGroupBuyRefund', this.sale)
        },
        clickOpenRefundPanel() {
            this.saleRefundAmount = this.fixProductPrice(this.sale.currency, this.sale.total - this.sale.refund_amount)
            this.saleRealRefundAmount = this.sale.total - this.sale.refund_amount
            this.showRefundPanel = true
        },
        clickRefund() {
            if (this.isRefundAmountOkay) {
                this.$emit('clickRefund', this.sale, this.saleRealRefundAmount, this.saleRefundDescription)
                this.showRefundPanel = false
            }
        },
        clickSeeAllLines() {
            this.singleLine = false
        },
        clickSendPaymentReminder() {
            this.$emit('clickSendPaymentReminder', this.sale.id, this.payByDate)
            this.showPaymentReminderPanel = false
        },
        clickShipped() {
            this.$emit('clickShipped', this.sale.id)
        },
        clickUpdateProcessDate() {
            this.$emit('clickUpdateProcessDate', this.sale.id, this.saleProcessDate)
            this.showChangeProcessDatePanel = false
        },
        closeSaleCard() {
            this.$emit('closeSaleCard', this.sale.id)
        },
        updatePayByDate(inName, inValue) {
            this.payByDate = inValue
        },
        updateProcessDate(inName, inValue) {
            this.saleProcessDate = inValue
        },
        updateRefundAmount(inName, inValue) {
            this.saleRefundAmount = inValue
            this.saleRealRefundAmount = this.getBaseCurrencyValue(this.sale.currency, inValue)
        },
        updateRefundDescription(inName, inValue) {
            this.saleRefundDescription = inValue
        }
    }
}
</script>