<template>
    <div v-if="gallery" class="relative mt-1">
        <div class="flex h-full cursor-pointer md:flex" style="width: 100px; height: auto" :class="{'hidden': isFullscreen}" @touchStart="onTouchStart" @touchMove="onTouchMove" @touchStop="onTouchStop">
            <div class="h-full border">
                <img v-if="gallery[0].type == 'image'" @click="initialSlide = 0; isFullscreen=true" :src="transformUrl(gallery[0].url, ['optimize', 'md'])" id="gallery-0" class="object-contain" loading="lazy"/>
                <video v-else-if="gallery[0].type == 'video'" @click="initialSlide = 0; isFullscreen=true" :poster="gallery[0].thumbnail_url" preload="none" autoplay loop playsinline muted class="object-contain max-w-full max-h-full mx-auto gallery-video swiper-lazy">
                    <source :src="transformUrl(gallery[0].url,['video', 'optimize', 'md'])" type="video/mp4"/>
                    <source :src="transformUrl(gallery[0].url,['video', 'optimize', 'md'])" type="video/webm"/>
                    <source :src="transformUrl(gallery[0].url,['video', 'optimize', 'md'])" type="video/ogg"/>
                    Your browser does not support HTML5 video tags
                </video>
            </div>
        </div>
        <div v-if="isFullscreen">
            <new-gallery 
                :gallery="gallery" :initialSlide="initialSlide" 
                @close="isFullscreen = false"
            />
        </div>
    </div>
</template>

<script>
import NewGallery from '@/shared/NewGallery'

export default {
    props: {
        gallery: null
    },
    components: {
        NewGallery
    },
    data() {
        return {
            initialSlide: 0,
            isFullscreen: false,
            tapping: false
        }
    },
    methods: {
        getURL(inURL) {
            return inURL.includes('res.cloudinary') ? this.transformUrl(inURL, ['optimize', 'md']) : inURL
        },
        onTouchStart() {
            this.tapping = true
        },
        onTouchMove() {
            this.tapping = false
        },
        onTouchStop(e) {
            if (e.type == "click" || this.tapping) {
                isFullscreen = !isFullscreen
            }

            this.tapping = false
        },
        openFullscreen(index){
            this.mySwiper.el.getElementsByTagName('video')?.forEach(e => e.pause())
            this.initialSlide = index 
            this.isFullscreen = true
        }
    }
}
</script>