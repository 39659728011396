<template>
    <div class="px-1">
        <div class="flasher-mid-bg rounded-full h-6 mx-auto flasher-mid-animate" :class="{ [classes]: true }">
        </div>
    </div>
</template>

<script>
export default {
    props: ['classes']
}
</script>