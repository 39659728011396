import {loadStripe} from '@stripe/stripe-js'

const paypalMixins = {
    computed: {
        
    },
    watch: {
        loggedUser: {
            immediate: true,
            handler(val) {
                
            }
        }
    },
    methods: {
        paypalCheckOnboardingStatus() {
            this.setStateData([
                { paypalCheckingStatus: true }
            ])

            buildzAxios?.get(this.getRoute('paypalCheckOnboardingStatus'))
                .then((response) => {
                    console.log(response)
                    if ((response?.data?.message ?? false) && response.data.message == 'success') {
                        this.setStateData([
                            { paypalMerchantOkay: true },
                            { paypalCheckingStatus: false }
                        ])
                    }
                    else {
                        this.setStateData([
                            { paypalMerchantOkay: false },
                            { paypalCheckingStatus: false }
                        ])
                    }
                })
                .catch((error) => {
                    console.log('error checking status')

                    this.setStateData([
                        { paypalMerchantOkay: false },
                        { paypalCheckingStatus: false }
                    ])
                })
        },
        async paypalCompleteOnboarding(inMerchantIdInPayPal, inPermissionsGranted, inConsentStatus, inProductIntentId, inIsEmailConfirmed, inAccountStatus, inRiskStatus) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.post(this.getRoute('paypalCompleteOnboarding'), {   
                    merchant_id: inMerchantIdInPayPal,
                    permissions_granted: inPermissionsGranted,
                    consent_status: inConsentStatus,
                    product_intent_id: inProductIntentId,
                    email_confirmed: inIsEmailConfirmed,
                    account_status: inAccountStatus,
                    risk_status: inRiskStatus
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    console.log('error completing onboarding')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        paypalPartnerReferral() {
            buildzAxios?.get(this.getRoute('paypalPartnerReferral'))
                .then((response) => {
                    console.log(response)
                    if ((response?.data?.linkResponse?.links ?? false) && response.data.linkResponse.links.length) {
                        let myLink = response.data.linkResponse.links.find((link) => link.rel == 'action_url')

                        if (myLink) {
                            this.openNewTabLink(myLink.href)
                        }
                    }
                })
                .catch((error) => {
                    console.log('error getting paypal link')
                })
        }
    }
}

export default paypalMixins