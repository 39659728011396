import {loadStripe} from '@stripe/stripe-js'

const stripeMixins = {
    computed: {
        completeStripeAccount() {
            if (this.editingTempSeller) {
                return this.loggedUser && this.stripeTempAccountChecked && this.stripeTempAccountComplete
            }

            return this.loggedUser && this.stripeAccountChecked && this.stripeAccountComplete
        },
        hasStripeAccount() {
            if (this.editingTempSeller) {
                return this.loggedUser && this.loggedUser.stripe_temp_account_id
            }

            return this.loggedUser && this.loggedUser.stripe_account_id
        },
        incompleteOrNoStripeAccount() {
            return !this.hasStripeAccount || this.incompleteStripeAccount
        },
        incompleteStripeAccount() {
            if (this.editingTempSeller) {
                return this.loggedUser && this.hasStripeAccount && this.stripeTempAccountChecked && !this.stripeTempAccountComplete
            }

            return this.loggedUser && this.hasStripeAccount && this.stripeAccountChecked && !this.stripeAccountComplete
        }
    },
    watch: {
        loggedUser: {
            immediate: true,
            handler(val) {
                if (val && !this.stripeAccountChecked) {
                    this.checkExistingStripeAccount(false)

                    this.setStateData([
                        { stripeAccountChecked: true }
                    ])

                    this.getSellerVendorData()
                }

                if (val && !this.stripeTempAccountChecked) {
                    this.checkExistingStripeAccount(true)

                    this.setStateData([
                        { stripeTempAccountChecked: true }
                    ])
                }
            }
        }
    },
    methods: {
        async checkExistingStripeAccount(inTempAccount) {
            if ((!inTempAccount && this.loggedUser.stripe_account_id) || (inTempAccount && this.loggedUser.stripe_temp_account_id)) {
                let myStripeAccount = await this.stripeGetAccount(inTempAccount)

                if (myStripeAccount) {
                    if (inTempAccount) {
                        this.setStateData([
                            { stripeTempAccount: myStripeAccount },
                            { stripeTempAccountComplete: (myStripeAccount?.data?.stripe_account?.details_submitted ?? false) && (myStripeAccount?.data?.stripe_account?.charges_enabled ?? false) && (myStripeAccount?.data?.stripe_account?.payouts_enabled ?? false) }
                        ])
                    }
                    else {
                        this.setStateData([
                            { stripeAccount: myStripeAccount },
                            { stripeAccountComplete: (myStripeAccount?.data?.stripe_account?.details_submitted ?? false) && (myStripeAccount?.data?.stripe_account?.charges_enabled ?? false) && (myStripeAccount?.data?.stripe_account?.payouts_enabled ?? false) }
                        ])
                    }
                }
            }
        },
        async createPaymentMethod(inBillingDetails, inCard) {
            let promise = new Promise((resolve, reject) => {
                this.stripe.createPaymentMethod({
                    type: 'card',
                    card: inCard,
                    billing_details: inBillingDetails
                })
                .then((response) => {
                    console.log(response)
                    
                    if (response?.paymentMethod?.id ?? false) {
                        resolve(response.paymentMethod.id)
                    }
                    else if (response?.error?.message ?? false) {
                        this.pageMessage('error', response.error.message)
                        reject(false)
                    }
                    else {
                        this.pageMessage('error', 'There was an error creating payment method')
                        reject(false)
                    }
                })
                .catch((error) => {
                    // this.pageMessage('error', error.message)
                    console.log('error creating payment method')
                    console.log(error)
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async stripeCreateAccount(inCountry) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.put(this.getRoute('stripeAccount'), {
                    country: inCountry,
                    tempAccount: this.editingTempSeller
                })
                .then((response) => {
                    console.log(response)

                    if (response?.user ?? false) {
                        this.setStateData([
                            { loggedUser: response.user }
                        ])
                    }

                    resolve(response)
                })
                .catch((error) => {
                    console.log('error creating stripe account')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async stripeCreateCheckoutSession(inAmount, inQty, inCurrency, inCommission, inVendorId, inDescription) {
            let promise = new Promise((resolve, reject) => {
                let url = new URL(window.location.href)
                
                buildzAxios?.put(this.getRoute('stripeCheckoutSession'), {
                    amount: inAmount,
                    currency: inCurrency,
                    commission: inCommission,
                    vendorId: inVendorId,
                    description: inDescription,
                    qty: inQty,
                    presaleId: this.presaleId,
                    presaleCode: this.presaleCode,
                    origin: url.origin
                })
                .then((response) => {
                    console.log(response)
                    resolve(response)
                })
                .catch((error) => {
                    console.log('error creating checkout session')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async stripeCreateCustomer() {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.put(this.getRoute('stripeCustomer'))
                    .then((response) => {
                        console.log(response)

                        if (response?.user ?? false) {
                            this.setStateData([
                                { loggedUser: response.user }
                            ])
                        }

                        resolve(response)
                    })
                    .catch((error) => {
                        console.log('error creating stripe customer')
                        reject(false)
                    })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async stripeCreatePaymentIntent(inAmount, inCurrency, inCommission, inVendorId, inPaymentTypes = null, inDescription) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.put(this.getRoute('stripePaymentIntent'), {
                    amount: inAmount,
                    currency: inCurrency,
                    commission: inCommission,
                    vendorId: inVendorId,
                    payment_types: inPaymentTypes,
                    description: inDescription
                })
                .then((response) => {
                    console.log(response)
                    resolve(response)
                })
                .catch((error) => {
                    console.log('error creating setup itent')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async stripeCreateSetupIntent(inVendorId) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.put(this.getRoute('stripeSetupIntent'), {
                    vendorId: inVendorId
                })
                .then((response) => {
                    console.log(response)
                    resolve(response)
                })
                .catch((error) => {
                    console.log('error creating setup itent')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async stripeDeletePaymentMethod(inPaymentMethodId) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.delete(this.getRoute('stripePaymentMethod'), {
                    data: {
                        paymentMethodId: inPaymentMethodId
                    }
                })
                .then((response) => {
                    console.log(response)
                    resolve(response)
                })
                .catch((error) => {
                    console.log('error detaching payment method')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async stripeGetAccount(inTempAccount) {
            if (this?.loggedUser.stripe_account_id ?? false) {
                let promise = new Promise((resolve, reject) => {
                    buildzAxios?.get(this.getRoute('stripeAccount'), {
                        params: {
                            tempAccount: inTempAccount
                        }
                    })
                    .then((response) => {
                        console.log(response)
                        resolve(response)
                    })
                    .catch((error) => {
                        console.log('error getting stripe account')
                        reject(false)
                    })
                })

                var myReturn = false
    
                await promise
                    .then((result) => {
                        myReturn = result
                    })
                    .catch((e) => {
    
                    })
            
                return myReturn
            }
        },
        async stripeGetCustomer(inEmailAddress) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.get(this.getRoute('stripeCustomer'), {
                    params: {
                        email: inEmailAddress
                    }
                })
                .then((response) => {
                    console.log(response)
                    resolve(response)
                })
                .catch((error) => {
                    console.log('error getting stripe customer')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async stripeGetLinks(inLinkType = null) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.get(this.getRoute('stripeLinks'), {
                    params: {
                        linkType: inLinkType,
                        tempAccount: this.editingTempSeller
                    }
                })
                .then((response) => {
                    console.log(response)
                    resolve(response)
                })
                .catch((error) => {
                    console.log('error getting stripe links')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async stripeGetLoginLink() {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.get(this.getRoute('stripeAccountLoginLink'))
                    .then((response) => {
                        console.log(response)
                        resolve(response)
                    })
                    .catch((error) => {
                        console.log('error getting stripe login link')
                        reject(false)
                    })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        async stripeInit() {
            let myStripe = await loadStripe(this.marketplaceLive ? this.stripeLiveKey : this.stripeTestKey)

            if (myStripe) {
                this.setStateData([
                    { stripe: myStripe }
                ])
            }
        },
        async stripeSavePaymentMethod(inPaymentMethodId) {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.put(this.getRoute('stripePaymentMethod'), {
                    paymentMethodId: inPaymentMethodId
                })
                .then((response) => {
                    console.log(response)
                    resolve(response)
                })
                .catch((error) => {
                    console.log('error saving payment method')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        }
    }
}

export default stripeMixins