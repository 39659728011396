<template>
    <div v-if="open" class="new-notification-box iphonePaddingTop">
        <div ref="notificationBox" class="relative pr-8 overflow-y-auto mx-auto max-main-width" style="min-height: 100vh; max-height: 100vh;">
            <notification v-for="(notification, index) in sortedNotifications" :key="`${index}_${notification.id}`" :notification="notification" @closeNotifications="closeNotifications" />
            <div v-if="gettingRecords && open" class="flex justify-center mt-4 text-orange-400">
                <fa-icon icon="spinner" type="fas" class="spin w-8 h-8" />
            </div>
        </div>
        <div v-show="open" @click.stop="closeNotifications" class="fixed top-4 right-2 cursor-pointer pb-8 iphoneMarginTop">
            <fa-icon icon="xmark" type="fas" class="text-white h-9 w-9" />
        </div>
    </div>
</template>

<script>
import Notification from "./Notification"

export default {
    props: {
        open: false
    },
    components: {
        Notification
    },
    data() {
        return {
            gettingRecords: false,
            lastPage: false,
            offset: 0,
            recordsPP: 20
        }
    },
    computed: {
        activities() {
            return this.notificationActivities ?? []
        },
        feed() {
            return this.feeds && this.feeds.notifications ? this.feeds.notifications : null
        },
        sortedNotifications() {
            return this.activities
        }
    },
    watch: {
        keyEscape: {
            handler(val) {
                if (val && this.popupCheck('notifications')) {
                    this.setStateData([
                        { keyEscape: false }
                    ])

                    this.closeNotifications()
                }
            }
        },
        notifyScrollPos: {
            handler(val) {
                if (!this.gettingRecords && !this.lastPage && this.offset > 0 && this.getRect(this.feed_id).bottom != 0 && this.getRect(this.feed_id).bottom < window.innerHeight) {
                    this.getRecords()
                }
            }
        },
        open: {
            handler(val) {
                if (val) {
                    this.popupIn('notifications')

                    this.setStateData([
                        { keyEscape: false }
                    ])

                    if (this.popups.length == 1) {
                        this.currentScrollTop = this.scrollTop
                                    
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'instant'
                        })
                        
                        document.body.classList.remove("overflow-y-scroll")
                        document.body.classList.add("overflow-y-hidden")
                        document.body.style.marginTop = -(this.currentScrollTop) + "px"
                    }

                    this.setStateData([
                        { unseen: 0 },
                        { notificationActivities: null }
                    ])
                    
                    this.offset = 0
                    this.lastPage = false
                    this.getFeed()
                }
                else {
                    this.popupOut('notifications')

                    if (this.popups.length == 0) {
                        document.body.classList.remove("overflow-y-hidden")
                        document.body.classList.add("overflow-y-scroll")
                        document.body.style.marginTop = "0px"
                        
                        window.scrollTo({
                            top: this.currentScrollTop,
                            left: 0,
                            behavior: 'instant'
                        })
                    }
                }
            }
        }
    },
    mounted() {
        this.getFeed()
        this.$refs.notificationBox?.addEventListener('scroll', this.handleScroll)
    },
    beforeUnmount() {
        this.$refs.notificationBox?.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        async getFeed() {
            if (!this.streamClient || (this.loggedUser && this.streamClient?.userId != this.loggedUserId)) {
                this.setStateData([
                    { streamClient: null }
                ])

                await this.initClient()
            }
            
            if (this.streamClient) {
                const myFeedId = await this.initNotificationFeed()

                if (myFeedId) {
                    this.getRecords()
                }
            }
            else {
                // this.pageMessage('error', 'Error inititalising stream client')
            }
        },
        async getRecords() {
            if (!this.gettingRecords) {
                this.gettingRecords = true 
                const feedData = await this.getActivities(this.feed, this.offset, this.recordsPP, this.open)

                if (feedData && feedData.results) {
                    this.offset = this.offset + feedData.results.length
                    this.lastPage = feedData.results.length < this.recordsPP
                    let myActivities = this.objCopy(this.activities)

                    this.replaceStateDataArray([
                        { notificationActivities: [...myActivities, ...feedData.results] }
                    ])
                }
                else {
                    this.lastPage = true
                }

                this.gettingRecords = false
            }
        },
        handleScroll() {
            if (!this.gettingRecords && !this.lastPage && this.offset > 0 && this.$refs.notificationBox.clientHeight + this.$refs.notificationBox.scrollTop > this.$refs.notificationBox.scrollHeight - 40) {
                this.getRecords()
            }
        },
        async initNotificationFeed() {
            const myFeed = await this.streamClient?.feed('notification', this.loggedUserId)

            if (myFeed) {
                if (!this.subscribedFeeds.includes(myFeed.id)) {
                    myFeed.subscribe(this.subscribeNewNotificationFeedData).then(this.subscribeSuccess(myFeed.id), this.subscribeFail)
                    let mySubscribedFeeds = this.objCopy(this.subscribedFeeds)
                    mySubscribedFeeds.push(myFeed.id)

                    this.replaceStateDataArray([
                        { subscribedFeeds: mySubscribedFeeds }
                    ])
                }

                if (!this.feeds) {
                    this.setStateData([
                        { feeds: { notifications: myFeed } }
                    ])
                }
                else {
                    this.setStateData([
                        { feeds: { ...this.feeds, notifications: myFeed } }
                    ])
                }

                return myFeed.id
            }
            else {
                this.pageMessage('error', 'Error retrieving feed')
            }
        },
        async subscribeNewNotificationFeedData(data) {
            let myResults = await this.getActivities(this.feed, 0, 1)

            if (myResults?.unseen) {
                this.setStateData([
                    { unseen: myResults.unseen }
                ])
            }
        }
    }
}
</script>

<style>
    .new-notification-box {
        bottom: 0;
        left: 0;
        width: 100%;
        max-height: 100vh;
    }
</style>
