const notificationMixins = {
    methods: {
        closeNotifications() {
            this.setStateData([
                { shownNotifications: false }
            ])
        },
        openNotifications() {
            this.setStateData([
                { shownNotifications: true }
            ])
        }
    }
}

export default notificationMixins