<template>
    <div v-if="formLoaded" class="w-full p-2 white-transparent">
        <div class="round-tabs-container px-1 mt-1">
            <template v-for="(country) in countryList" :key="key">
                <div v-if="pGroupBuyForm.disabled" @click="toggleCountry(country.code)" class="round-tab flex items-center" :class="{ 'round-tab-off': !includedCountry(country.code), 'round-tab-on': includedCountry(country.code) }">
                    {{ country.name }}
                </div>
                <div v-else @click="toggleCountry(country.code)" class="round-tab flex items-center" :class="{ 'round-tab-off': !includedCountry(country.code), 'round-tab-on': includedCountry(country.code) }">
                    {{ country.name }}
                </div>
            </template>
        </div>
        <div class="w-full mt-4 flex items-center flex-wrap">
            <div :class="{ 'px-1 w-1/2': isDesktopWidth, 'w-full': !isDesktopWidth }">
                <div @click="clickSelectAll" class="alt-button-base">
                    Select All
                </div>
            </div>
            <div :class="{ 'px-1 w-1/2': isDesktopWidth, 'w-full': !isDesktopWidth }">
                <div @click="clickDeselectAll" class="alt-button-base">
                    Deselect All
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'pGroupBuyForm'
    ],
    data() {
        return {
            formLoaded: false,
            includedCountries: []
        }
    },
    mounted() {
        this.includedCountries = Object.values(this.objCopy(this.pGroupBuyForm.included_countries))
        this.formLoaded = true
    },
    methods: {
        clickDeselectAll() {
            this.includedCountries = []
            this.$emit('updateEligibleCountries', this.includedCountries)
        },
        clickSelectAll() {
            this.includedCountries = []

            this.countryData.forEach((myCountry) => {
                this.includedCountries.push(myCountry.code)
            })

            this.$emit('updateEligibleCountries', this.includedCountries)
        },
        includedCountry(inCountry) {
            return this.includedCountries.includes(inCountry)
        },
        toggleCountry(inCountry) {
            if (this.includedCountries.includes(inCountry)) {
                let myIncludedCountries = this.objCopy(this.includedCountries)
                myIncludedCountries = myIncludedCountries.filter((myCountry) => myCountry != inCountry)
                this.includedCountries = this.objCopy(myIncludedCountries)
            }
            else {
                this.includedCountries.push(inCountry)
            }

            this.$emit('updateEligibleCountries', this.includedCountries)
        }
    }
}
</script>