<template>
    <div v-if="formLoaded" class="w-full">
        <select-panel v-if="vendorValuesData"
            :fieldData="vendorValuesData" fieldFormName="selectVendorValue" :fieldLabel="`Select Base ${vendorVariant.label} Value`" 
            fieldSubLabel="Or enter new values below" :fieldValue="selectVendorValue"
            :isLabelAndValue="true"
            @fieldChange="vendorValueSelected"
        />
        <input-panel 
            fieldFormName="label" :fieldLabel="`${vendorVariant.label} Name`" :fieldPlaceholder="`${vendorVariant.label} Name`" 
            :fieldRequired="true" fieldType="text" :fieldValue="valueForm.label"
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(valueForm.label)" 
                :message="`${vendorVariant.label} Name must be entered`"
            />
        </input-panel>
        <input-panel 
            fieldFormName="identifier" :fieldLabel="`${vendorVariant.label} Identifier`" :fieldPlaceholder="`${vendorVariant.label} Identifier`" 
            :fieldRequired="true" fieldType="text" fieldSubLabel="This is a unique identifier, it is only used as an index. It is not shown to the customers."
            :fieldValue="valueForm.identifier"
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(valueForm.identifier)" 
                :message="`${vendorVariant.label} Identifier must be entered`"
            />
            <validation-message v-if="validating && duplicateValue && duplicateValue == valueForm.identifier" 
                :message="`Duplicate ${vendorVariant.label} identifier`"
            />
        </input-panel>
        <input-panel 
            fieldFormName="priceVariance" fieldLabel="Price Variance Amount" fieldPlaceholder="Price Variance Amount"
            fieldSubLabel="This amount will be added to the base price of this product, please enter a tax inclusive price" fieldType="number" 
            :fieldValue="valueForm.priceVariance" 
            @fieldInput="updateFormValue"
        />
        <div class="w-full white-transparent mt-px py-4 px-2" :class="{ 'desktop-button-box justify-center': isDesktopWidth }">
            <div @click="clickCreateNewValue()" class="w-full" :class=" { 'standard-button-base': allOkay, 'disabled-button-base': !allOkay } ">
                Create {{ vendorVariant.label }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'variantValues',
        'vendorVariant'
    ],
    data() {
        return {
            duplicateValue: false,
            formLoaded: false,
            selectVendorValue: null,
            validating: false,
            valueForm: {
                identifier: null,
                label: null,
                priceVariance: null
            }
        }
    },
    computed: {
        allOkay() {
            return Boolean(this.valueForm.identifier && this.valueForm.label)
        },
        vendorValuesData() {
            if (this.vendorVariant.values && this.vendorVariant.values.length) {
                let myValues = {}

                this.vendorVariant.values
                    .sort((a, b) => a.identifier < b.identifier ? -1 : 1)
                    .forEach((myValue) => {
                        myValues = {...myValues, ...{[myValue.label]: myValue.identifier}}
                    })

                return myValues
            }

            return null
        }
    },
    mounted() {
        this.formLoaded = true
    },
    methods: {
        clickCreateNewValue() {
            if (this.allOkay) {
                this.duplicateValue = null
                this.validating = false
                
                let checkValue = null
                
                if (this.variantValues) {
                    checkValue = this.variantValues.find((myValue) => myValue.identifier == this.valueForm.identifier)
                }

                if (checkValue) {
                    this.duplicateValue = this.valueForm.identifier
                    this.validating = true
                    alert(`That ${this.vendorVariant.label} identifier already exists`)
                }
                else {
                    this.$emit('clickCreateNewVariantValue', this.objCopy(this.valueForm), this.vendorVariant.identifier)
                    this.pageMessage('success', `${this.vendorVariant.label} '${this.valueForm.label}' created`)
                    this.valueForm.identifier = null
                    this.valueForm.label = null
                    this.valueForm.priceVariance = null
                    this.selectVendorValue = null
                }
            }
            else {
                this.validating = true
            }
        },
        updateFormValue(inField, inValue) {
            if (inField == 'identifier') {
                inValue = this.stripString(inValue.toLowerCase())
            }
            else if (inField == 'label') {
                this.valueForm.identifier = this.stripString(inValue.toLowerCase())
            }

            this.valueForm[inField] = inValue
        },
        vendorValueSelected(inField, inValue) {
            let myVendorValue = this.vendorVariant.values.find((myValue) => myValue.identifier == inValue)
            this.valueForm.identifier = myVendorValue.identifier
            this.valueForm.label = myVendorValue.label
        }
    }
}
</script>