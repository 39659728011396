const routesMixins = {
    computed: {
        isChatRoute() {
            return this.$route.name && this.$route.name.includes('Chat')
        }
    },
    methods: {
        getRoute(inName, inId) {
            if (this.routes[inName]) {
                return this.routes[inName].replace('{id}', inId).replace('api/', this.isLoggedIn ? 'api/' : 'api/guest/')
            }

            return null
        },
        routeTo(inSlug) {
            this.$router.push(inSlug)
        }
    }
}

export default routesMixins