<template>
     <wizard-container
        :openConditions="wizardData && sellerDataForm"
        @closeWizard="closeNewSellerWizard"
     >
        <template #main>
            <wizard-page v-if="formLoaded"
                :currentPage="currentPage" :hideLogo="currentPage == 1" :hidePrevious="currentPage < 3" :hideProgress="currentPage == 1" :introPages="introPages" :myPage="currentPage" :outroPages="outroPages" 
                :pages="pages" :startPage="startPage" :wizardData="wizardData"
                @jumpToPage="jumpToPage" @nextPage="nextPage" @previousPage="previousPage"
            >
                <template #main>
                    <page01 v-if="currentPage == 1"
                    />
                    <page02 v-if="currentPage == 2"
                        :sellerForm="sellerForm" :validating="validating"
                        @updateSellerFormValue="updateSellerFormValue"
                    />
                    <page03 v-if="currentPage == 3"
                        :sellerForm="sellerForm" :validating="validating"
                        @updateSellerFormValue="updateSellerFormValue"
                    />
                    <page04 v-if="currentPage == 4"
                        :avatarArray="avatarArray" :validating="validating"
                        @avatarSavedOkay="avatarSavedOkay" @saveLocalAvatar="saveLocalAvatar" @updateOfflineAvatar="updateOfflineAvatar"
                    />
                    <page05 v-if="currentPage == 5"
                        :imagesArray="imagesArray" :validating="validating"
                        @galleryImageSavedOkay="galleryImageSavedOkay" @saveLocalGallery="saveLocalGallery" @updateOfflineGallery="updateOfflineGallery"
                    />
                    <page06 v-if="currentPage == 6"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page07 v-if="currentPage == 7"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @changeExistingPlatform="changeExistingPlatform" @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page08 v-if="currentPage == 8"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @changeCountry="changeCountry"
                    />
                    <page09 v-if="currentPage == 9"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @changeChargeTax="changeChargeTax" @updateSellerDataFormValue="updateSellerDataFormValue" @updateTaxRates="updateTaxRates"
                    />
                    <page10 v-if="currentPage == 10"
                        :manualPickupAddress="manualPickupAddress" :sellerDataForm="sellerDataForm" :validating="validating"
                        @updateManualPickupAddress="updateManualPickupAddress" @updateSellerDataFormLocalAddressValue="updateSellerDataFormLocalAddressValue" @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page11 v-if="currentPage == 11"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @updateSellerDataFormValue="updateSellerDataFormValue"
                    />
                    <page12 v-if="currentPage == 12"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @changeMultipleCountries="changeMultipleCountries" @changeUseDefaultZone="changeUseDefaultZone" @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page13 v-if="currentPage == 13"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page14 v-if="currentPage == 14"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page15 v-if="currentPage == 15"
                        :editingRateId="editingRateId" :editingRateZoneId="editingRateZoneId" :sellerDataForm="sellerDataForm" :shippingZonesOkay="shippingZonesOkay" :validating="validating"
                        @clickDeleteRate="clickDeleteRate" @clickEditRate="clickEditRate" @clickNewZoneRate="clickNewZoneRate" @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page16 v-if="currentPage == 16"
                        :sellerDataForm="sellerDataForm" :sellerForm="sellerForm" :validating="validating"
                        @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue" @updateSellerFormValue="updateSellerFormValue"
                    />
                    <page17 v-if="currentPage == 17"
                        :sellerDataForm="sellerDataForm" :validating="validating"
                        @updateSellerDataFormSelectValue="updateSellerDataFormSelectValue"
                    />
                    <page18 v-if="currentPage == 18"
                        :sellerDataForm="sellerDataForm" :sellerForm="sellerForm" :validating="validating"
                        @updatePaymentType="updatePaymentType"
                    />
                    <page19 v-if="currentPage == 19"
                    />
                </template>
                <template #modals>
                    <add-zone-rate v-if="showAddNewZoneRate && currentPage == 15" 
                        :addingRateZone="addingRateZone" :editingRate="editingRate" :editingRateZone="editingRateZone" :rateType="sellerDataForm.defaultShippingModel" :sellerCurrency="sellerDataForm.defaultCurrency" :shippingRateLabels="sellerDataForm.shippingRateLabels"
                        @clickCancelNewZoneRate="clickCancelNewZoneRate" @clickSaveEditZoneRate="clickSaveEditZoneRate" @clickSaveNewZoneRate="clickSaveNewZoneRate"
                    />
                </template>
            </wizard-page>
        </template>
     </wizard-container>
</template>

<script>
import {debounce} from 'lodash'

import AddZoneRate from './new-seller-wizard/AddZoneRate'
import Page01 from './new-seller-wizard/Page01'
import Page02 from './new-seller-wizard/Page02'
import Page03 from './new-seller-wizard/Page03'
import Page04 from './new-seller-wizard/Page04'
import Page05 from './new-seller-wizard/Page05'
import Page06 from './new-seller-wizard/Page06'
import Page07 from './new-seller-wizard/Page07'
import Page08 from './new-seller-wizard/Page08'
import Page09 from './new-seller-wizard/Page09'
import Page10 from './new-seller-wizard/Page10'
import Page11 from './new-seller-wizard/Page11'
import Page12 from './new-seller-wizard/Page12'
import Page13 from './new-seller-wizard/Page13'
import Page14 from './new-seller-wizard/Page14'
import Page15 from './new-seller-wizard/Page15'
import Page16 from './new-seller-wizard/Page16'
import Page17 from './new-seller-wizard/Page17'
import Page18 from './new-seller-wizard/Page18'
import Page19 from './new-seller-wizard/Page19'
import WizardPage from '@/shared/WizardPage'

export default {
    components: {
        AddZoneRate,
        Page01,
        Page02,
        Page03,
        Page04,
        Page05,
        Page06,
        Page07,
        Page08,
        Page09,
        Page10,
        Page11,
        Page12,
        Page13,
        Page14,
        Page15,
        Page16,
        Page17,
        Page18,
        Page19,
        WizardPage
    },
    data() {
        return {
            activating: false,
            addingRateZone: null,
            avatarGallery: [],
            avatarImage: null,
            currentPage: 1,
            editingRate: null,
            editingRateId: null,
            editingRateZone: null,
            editingRateZoneId: null,
            formLoaded: false,
            gallery: [],
            introPages: 1,
            manualPickupAddress: false,
            outroPages: 0,
            sellerDataForm: {
                chargeTax: null,
                countryCode: null,
                customRates: null,
                defaultCurrency: null,
                defaultGroupBuyCategory: null,
                defaultProductCategory: null,
                defaultShippingModel: null,
                defaultTaxRate: null,
                discountCode: null,
                excludedCountries: null,
                existingPlatform: null,
                lastTaxRateId: null,
                localPickup: null,
                localPickupAddress: {
                    address: {
                        city: null,
                        country: null,
                        line1: null,
                        line2: null,
                        postal_code: null,
                        state: null
                    },
                    formattedAddress: null,
                    location: null
                },
                multipleCountries: null,
                newZoneRateZoneId: null,
                platform: null,
                shippingFrom: {
                    geoloc: null,
                    location: null
                },
                shippingRateLabels: null,
                shippingTaxRate: null,
                shippingZones: null,
                taxLabel: null,
                taxRates: null,
                useDefaultZone: null,
                usePayPal: null,
                useStripe: null,
                vendorPlan: null
            },
            sellerForm: {
                email: null,
                incomplete: null,
                name: null,
                payment_types: null,
                paypal_client_id: null,
                paypal_merchant_id: null
            },
            sellerId: null,
            showAddNewZoneRate: false,
            startPage: null,
            validating: false,
            wizardData: null
        }
    },
    computed: {
        avatarArray() {
            if (this.avatarGallery && this.avatarGallery.length) {
                let myGallery = []

                this.avatarGallery.forEach((myImage) => {
                    myGallery.push(myImage)
                })

                return myGallery
            }
            else {
                return this.avatarImage ? [ 
                    {
                        url: this.avatarImage,
                        type: 'image'
                    }
                ] : []
            }
        },
        defaultShippingZone() {
            return !this.sellerDataForm.shippingZones ? null : this.sellerDataForm.shippingZones.find((zone) => zone.id == 1)
        },
        finalPageButtonText() {
            return this.editingTempSeller ? "Make Your New Seller Live" : "Save And Close"
        },
        imagesArray() {
            let myGallery = []

            this.gallery.forEach((myImage) => {
                myGallery.push(myImage)
            })

            return myGallery
        },
        isPickupAddressOkay() {
            return this.sellerDataForm.localPickup != null && Boolean(!this.sellerDataForm.localPickup || (!this.manualPickupAddress && this.sellerDataForm.localPickupAddress.formattedAddress) || (this.manualPickupAddress && this.sellerDataForm.localPickupAddress.address.line1 && this.sellerDataForm.localPickupAddress.address.city && this.sellerDataForm.localPickupAddress.address.state && this.sellerDataForm.localPickupAddress.address.postal_code && this.sellerDataForm.localPickupAddress.address.country))
        },
        pages() {
            return {
                1: {
                    next: 2,
                    previous: false,
                    validation: true
                },
                2: {
                    next: 3,
                    previous: false,
                    validation: Boolean(this.sellerForm.name)
                },
                3: {
                    next: 4,
                    previous: 2,
                    validation: Boolean(this.sellerForm.email)
                },
                4: {
                    next: 5,
                    previous: 3,
                    validation: Boolean(this.avatarArray && this.avatarArray.length)
                },
                5: {
                    next: 6,
                    previous: 4,
                    validation: Boolean(this.avatarArray && this.avatarArray.length)
                },
                6: {
                    next: 7,
                    previous: 5,
                    validation: Boolean(this.sellerDataForm.defaultProductCategory && this.sellerDataForm.defaultGroupBuyCategory)
                },
                7: {
                    next: 8,
                    previous: 6,
                    validation: this.sellerDataForm.existingPlatform == false || (this.sellerDataForm.existingPlatform && Boolean(this.sellerDataForm.platform))
                },
                8: {
                    next: 9,
                    previous: 7,
                    validation: Boolean(this.sellerDataForm.defaultCurrency) && Boolean(this.sellerDataForm.countryCode)
                },
                9: {
                    next: 10,
                    previous: 8,
                    validation: Boolean(this.sellerDataForm.chargeTax != null) && (!this.sellerDataForm.chargeTax || (Boolean(this.sellerDataForm.taxLabel) && Boolean(this.sellerDataForm.defaultTaxRate) && (Boolean(this.sellerDataForm.taxRates) && this.sellerDataForm.taxRates.length > 1)))
                },
                10: {
                    next: 11,
                    previous: 9,
                    validation: Boolean(this.sellerDataForm.localPickup != null) && (Boolean(this.sellerDataForm.localPickup == false) || Boolean(this.isPickupAddressOkay))
                },
                11: {
                    next: 12,
                    previous: 10,
                    validation: Boolean(this.sellerDataForm.shippingFrom.location)
                },
                12: {
                    next: 13,
                    previous: 11,
                    validation: Boolean(this.sellerDataForm.multipleCountries != null) && Boolean(this.sellerDataForm.shippingZones && this.sellerDataForm.shippingZones.length > 1) && (Boolean(this.sellerDataForm.multipleCountries == false) || Boolean(this.sellerDataForm.useDefaultZone != null))
                },
                13: {
                    next: 14,
                    previous: 12,
                    validation: (Boolean(this.sellerDataForm.multipleCountries == false) || Boolean(this.sellerDataForm.useDefaultZone != null)) && Boolean(this.sellerDataForm.defaultShippingModel) && (!Boolean(this.sellerDataForm.chargeTax) || Boolean(this.sellerDataForm.shippingTaxRate))
                },
                14: {
                    next: 15,
                    previous: 13,
                    validation: (Boolean(this.sellerDataForm.multipleCountries == false) || Boolean(this.sellerDataForm.useDefaultZone != null)) && Boolean(this.sellerDataForm.shippingRateLabels && this.sellerDataForm.shippingRateLabels.length > 0)
                },
                15: {
                    next: 16,
                    previous: 14,
                    validation: this.shippingZonesOkay
                },
                16: {
                    next: 17,
                    previous: 15,
                    validation: Boolean(this.sellerDataForm.usePayPal == false) || (Boolean(this.sellerDataForm.usePayPal) && Boolean(this.sellerForm.paypal_client_id) && Boolean(this.sellerForm.paypal_merchant_id))
                },
                17: {
                    next: 18,
                    previous: 16,
                    validation: Boolean(this.sellerDataForm.useStripe != null) && (Boolean(this.sellerDataForm.useStripe) || Boolean(this.sellerDataForm.usePayPal)) && (!Boolean(this.sellerDataForm.useStripe) || this.completeStripeAccount)
                },
                18: {
                    next: 19,
                    previous: 17,
                    validation: Boolean(this.sellerForm.payment_types) && this.sellerForm.payment_types.length
                },
                19: {
                    next: 19,
                    nextText: this.finalPageButtonText,
                    previous: 17,
                    validation: true
                }
            }
        },
        shippingZonesOkay() {
            let allOkay = true

            this.sellerDataForm.shippingZones.forEach((myZone) => {
                if (myZone.id != 1 || this.sellerDataForm.useDefaultZone) {
                    if (!myZone.rates || !myZone.rates.length) {
                        allOkay = false
                    }
                }
            })

            return allOkay
        }
    },
    watch: {
        loggedUser: {
            immediate: true,
            handler(val) {
                if (val) {
                    if (this.loggedUser.temp_vendor_id) {
                        this.getTempSeller()
                    }
                    else {
                        this.formLoaded = true
                    }
                }
            }
        },
        sellerWizardData: {
            immediate: true,
            handler(val) {
                this.wizardData = this.sellerWizardData
            }
        },
        tempSellerVendor: {
            immediate: true,
            handler(val) {
                if (!this.sellerId) {
                    if (val) {
                        this.sellerId = this.tempSellerVendor.id
                        this.avatarGallery = this?.tempSellerVendor?.avatar_gallery ?? []
                        this.avatarImage = this.tempSellerVendor.avatar
                        this.gallery = this?.tempSellerVendor?.gallery ?? []
                        this.sellerForm.email = this.tempSellerVendor.email
                        this.sellerForm.incomplete = this.tempSellerVendor.incomplete
                        this.sellerForm.name = this.tempSellerVendor.name
                        this.sellerForm.payment_types = this.tempSellerVendor.payment_types.types ?? []
                        this.sellerForm.paypal_client_id = this.tempSellerVendor.paypal_client_id
                        this.sellerForm.paypal_merchant_id = this.tempSellerVendor.paypal_merchant_id
                    }
                    else {
                        this.sellerId = null
                        this.avatarGallery = []
                        this.avatarImage = null
                        this.gallery = []
                        this.sellerForm.email = null
                        this.sellerForm.incomplete = true
                        this.sellerForm.name = null
                        this.sellerForm.payment_types = []
                        this.sellerForm.paypal_client_id = null
                        this.sellerForm.paypal_merchant_id = null
                    }
                }
            }
        },
        tempSellerVendorData: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.sellerDataForm.chargeTax = (this?.tempSellerVendorData?.chargeTax ?? null) != null ? this.tempSellerVendorData.chargeTax : null
                    
                    if (this.tempSellerVendorData.shippingRateLabels) {
                        this.sellerDataForm.shippingRateLabels = this.tempSellerVendorData.shippingRateLabels
                    }
                    else {
                        let myLabels = []

                        this.defaultShippingRateLabels.forEach((myLabel) => {
                            myLabels.push(myLabel)
                        })

                        this.sellerDataForm.shippingRateLabels = myLabels
                    }

                    this.sellerDataForm.countryCode = this?.tempSellerVendorData?.countryCode ?? null
                    this.sellerDataForm.defaultCurrency = this?.tempSellerVendorData?.defaultCurrency ?? null
                    this.sellerDataForm.defaultGroupBuyCategory = this?.tempSellerVendorData?.defaultGroupBuyCategory ?? null
                    this.sellerDataForm.defaultProductCategory = this?.tempSellerVendorData?.defaultProductCategory ?? null
                    this.sellerDataForm.defaultShippingModel = this?.tempSellerVendorData?.defaultShippingModel ?? null
                    this.sellerDataForm.defaultTaxRate = this?.tempSellerVendorData?.defaultTaxRate ?? null
                    this.sellerDataForm.excludedCountries = this?.tempSellerVendorData?.excludedCountries ?? []
                    this.sellerDataForm.existingPlatform = (this?.tempSellerVendorData?.existingPlatform ?? null) != null ? this.tempSellerVendorData.existingPlatform : null
                    this.sellerDataForm.lastTaxRateId = this?.tempSellerVendorData?.lastTaxRateId ?? 1
                    this.sellerDataForm.localPickup = this?.tempSellerVendorData?.localPickup ?? null

                    this.sellerDataForm.localPickupAddress = this?.tempSellerVendorData?.localPickupAddress ?? {
                        address: {
                            city: null,
                            country: null,
                            line1: null,
                            line2: null,
                            postal_code: null,
                            state: null
                        },
                        formattedAddress: null,
                        location: null
                    }

                    this.sellerDataForm.multipleCountries = (this?.tempSellerVendorData?.multipleCountries ?? null) != null ? this.tempSellerVendorData.multipleCountries : null
                    this.sellerDataForm.platform = this?.tempSellerVendorData?.platform ?? null

                    this.sellerDataForm.shippingFrom = this?.tempSellerVendorData?.shippingFrom ?? {
                        geoloc: null,
                        location: null
                    }

                    this.sellerDataForm.shippingTaxRate = this?.tempSellerVendorData?.shippingTaxRate ?? null
                
                    if ((this?.tempSellerVendorData?.shippingZones ?? false) && this.tempSellerVendorData.shippingZones.length) {
                        this.sellerDataForm.shippingZones = this.tempSellerVendorData.shippingZones
                    }
                    else {
                        this.sellerDataForm.shippingZones = [
                            {
                                id: 1,
                                name: 'Default zone',
                                rates: [],
                                rateId: 0
                            }
                        ]
                    }

                    this.sellerDataForm.taxLabel = this?.tempSellerVendorData?.taxLabel ?? null

                    this.sellerDataForm.taxRates = this?.tempSellerVendorData?.taxRates ?? [
                        {
                            id: 1,
                            label: 'Tax free',
                            percentage: 0
                        }
                    ]

                    this.sellerDataForm.useDefaultZone = (this?.tempSellerVendorData?.useDefaultZone ?? null) != null ? this.tempSellerVendorData.useDefaultZone : null
                    this.sellerDataForm.usePayPal = (this?.tempSellerVendorData?.usePayPal ?? null) != null ? this.tempSellerVendorData.usePayPal : null
                    this.sellerDataForm.useStripe = (this?.tempSellerVendorData?.useStripe ?? null) != null ? this.tempSellerVendorData.useStripe : null

                    if (this.sellerDataForm.localPickupAddress.formattedAddress || !this.sellerDataForm.localPickupAddress.address.line1) {
                        this.manualPickupAddress = false
                    }
                    else {
                        this.manualPickupAddress = true
                    }
                }
                else {
                    this.sellerDataForm.chargeTax = null

                    let myLabels = []

                    this.defaultShippingRateLabels.forEach((myLabel) => {
                        myLabels.push(myLabel)
                    })

                    this.sellerDataForm.shippingRateLabels = myLabels

                    this.sellerDataForm.countryCode = null
                    this.sellerDataForm.defaultCurrency = null
                    this.sellerDataForm.defaultGroupBuyCategory = null
                    this.sellerDataForm.defaultProductCategory = null
                    this.sellerDataForm.defaultShippingModel = null
                    this.sellerDataForm.defaultTaxRate = null
                    this.sellerDataForm.existingPlatform = null
                    this.sellerDataForm.lastTaxRateId = 1
                    this.sellerDataForm.localPickup = null

                    this.sellerDataForm.localPickupAddress = {
                        address: {
                            city: null,
                            country: null,
                            line1: null,
                            line2: null,
                            postal_code: null,
                            state: null
                        },
                        formattedAddress: null,
                        location: null
                    }
                    
                    this.sellerDataForm.multipleCountries = null
                    this.sellerDataForm.platform = null

                    this.sellerDataForm.shippingFrom = {
                        geoloc: null,
                        location: null
                    }

                    this.sellerDataForm.shippingTaxRate = null

                    this.sellerDataForm.shippingZones = [
                        {
                            id: 1,
                            name: 'Default zone',
                            rates: [],
                            rateId: 0
                        }
                    ]

                    this.sellerDataForm.taxLabel = null

                    this.sellerDataForm.taxRates = [
                        {
                            id: 1,
                            label: 'Tax free',
                            percentage: 0
                        }
                    ]

                    this.sellerDataForm.useDefaultZone = null
                    this.sellerDataForm.usePayPal = null
                    this.sellerDataForm.useStripe = null

                    this.manualPickupAddress = false
                }
            }
        }
    },
    mounted() {
        this.setStateData([
            { editingTempSeller: true }
        ])

        if (this.newSellerWizardStartPage) {
            this.startPage = this.newSellerWizardStartPage
        }
    },
    beforeUnmount() {
        this.setStateData([
            { editingTempSeller: false },
            { newSellerWizardStartPage: null}
        ])
    },
    methods: {
        avatarSavedOkay(inImage, inIndex) {
            this.avatarGallery[inIndex] = inImage
            this.avatarImage = inImage.url
        },
        changeChargeTax(inField, inValue) {
            if (!inValue) {
                this.sellerDataForm.defaultTaxRate = 1
            }

            this.updateSellerDataFormSelectValue(inField, inValue)
        },
        changeCountry(inField, inValue) {
            if (this.coGetDefaultCurrency(inValue)) {
                this.sellerDataForm.defaultCurrency = this.coGetDefaultCurrency(inValue)
            }

            if (this.coGetDefaultTaxLabel(inValue)) {
                this.sellerDataForm.taxLabel = this.coGetDefaultTaxLabel(inValue)
            }

            this.updateSellerDataFormSelectValue(inField, inValue)
        },
        changeExistingPlatform(inField, inValue) {
            if (!inValue) {
                this.sellerDataForm.platform = null
            }

            this.updateSellerDataFormSelectValue(inField, inValue)
        },
        changeMultipleCountries(inField, inValue) {
            if (inValue == false && this.sellerDataForm.shippingZones.length > 1) {
                
            }
            else if (!inValue) {
                this.sellerDataForm.useDefaultZone = null
            }

            this.updateSellerDataFormSelectValue(inField, inValue)
        },
        changeUseDefaultZone(inField, inValue) {
            if (!inValue && this.defaultShippingZone) {
                let myZone = this.sellerDataForm.shippingZones.find((zone) => zone.id == 1)

                if (myZone) {
                    myZone.disabled = true
                }
                else {
                    myZone.disabled = false
                }
            }

            this.updateSellerDataFormSelectValue(inField, inValue)
        },
        clickCancelNewZoneRate() {
            this.newZoneRateZoneId = null
            this.editingRate = null
            this.editingRateId = null
            this.editingRateZone = null
            this.editingRateZoneId = null
            this.addingRateZone = null
            this.showAddNewZoneRate = false
        },
        clickCloseNewSellerWizard() {
            if (confirm("Confirm you wish to exit?")) {
                this.closeNewSellerWizard()
            }
        },
        clickDeleteRate(inZoneId, inRate) {
            let myZone = this.sellerDataForm.shippingZones.find((zone) => zone.id == inZoneId)
            let myNewRates = []

            myZone.rates.forEach((myRate) => {
                if (myRate.basedOn == inRate.basedOn && myRate.customRateName == inRate.customRateName) {
                    
                }
                else {
                    myNewRates.push(myRate)
                }
            })

            myZone.rates = myNewRates
            this.save()
        },
        clickEditRate(inZoneId, inRate) {
            this.editingRate = inRate
            this.editingRateId = inRate.id
            this.editingRateZoneId = inZoneId
            this.editingRateZone = this.sellerDataForm.shippingZones.find((zone) => zone.id == inZoneId)
            this.showAddNewZoneRate = true
        },
        clickNewZoneRate(inZoneId) {
            this.newZoneRateZoneId = inZoneId
            this.addingRateZone = this.sellerDataForm.shippingZones.find((zone) => zone.id == inZoneId)
            this.showAddNewZoneRate = true
        },
        clickSaveEditZoneRate(inForm, inRates) {
            let myZone = this.sellerDataForm.shippingZones.find((zone) => zone.id == this.editingRateZoneId)

            if (!myZone.rates) {
                myZone.rates = []
            }

            if (this.sellerDataForm.defaultShippingModel == 'weight') {
                inRates.forEach((weightRate) => {
                    if (myZone.rates.find((zRate) => zRate.id == weightRate.id)) {
                        myZone.rates.find((zRate) => zRate.id == weightRate.id).minWeight = weightRate?.minWeight ?? 0
                        myZone.rates.find((zRate) => zRate.id == weightRate.id).maxWeight = weightRate?.maxWeight ?? 0
                        myZone.rates.find((zRate) => zRate.id == weightRate.id).price = weightRate.price
                    }
                    else {
                        let myForm = this.objCopy(inForm)
                        myForm.minWeight = weightRate?.minWeight ?? 0
                        myForm.maxWeight = weightRate?.maxWeight ?? 999999999
                        myForm.price = weightRate.price
                        myForm.id = myZone.rateId + 1 ?? 1
                        myZone.rateId = myForm.id
                        myZone.rates.push(myForm)
                    }
                })

                this.showAddNewZoneRate = false
                this.newZoneRateZoneId = null
                this.editingRate = null
                this.editingRateId = null
                this.editingRateZone = null
                this.editingRateZoneId = null
                this.save()
            }
            else if (this.sellerDataForm.defaultShippingModel == 'price') {
                inRates.forEach((priceRate) => {
                    if (myZone.rates.find((zRate) => zRate.id == priceRate.id)) {
                        myZone.rates.find((zRate) => zRate.id == priceRate.id).minPrice = priceRate?.minPrice ?? 0
                        myZone.rates.find((zRate) => zRate.id == priceRate.id).maxPrice = priceRate?.maxPrice ?? 0
                        myZone.rates.find((zRate) => zRate.id == priceRate.id).price = priceRate.price
                    }
                    else {
                        let myForm = this.objCopy(inForm)
                        myForm.minPrice = priceRate?.minPrice ?? 0
                        myForm.maxPrice = priceRate?.maxPrice ?? 999999999
                        myForm.price = priceRate.price
                        myForm.id = myZone.rateId + 1 ?? 1
                        myZone.rateId = myForm.id
                        myZone.rates.push(myForm)
                    }
                })

                this.showAddNewZoneRate = false
                this.newZoneRateZoneId = null
                this.editingRate = null
                this.editingRateId = null
                this.editingRateZone = null
                this.editingRateZoneId = null
                this.save()
            }
            else {
                if (myZone.rates.find((zRate) => zRate.id == this.editingRateId)) {
                    myZone.rates.find((zRate) => zRate.id == this.editingRateId).price = inForm.price
                    myZone.rates.find((zRate) => zRate.id == this.editingRateId).firstUnitPrice = inForm.firstUnitPrice
                    myZone.rates.find((zRate) => zRate.id == this.editingRateId).additionalUnitPrice = inForm.additionalUnitPrice
                    this.showAddNewZoneRate = false
                    this.newZoneRateZoneId = null
                    this.editingRate = null
                    this.editingRateId = null
                    this.editingRateZone = null
                    this.editingRateZoneId = null
                    this.save()
                }
            }
        },
        clickSaveNewZoneRate(inForm, inRates) {
            let myZone = this.sellerDataForm.shippingZones.find((zone) => zone.id == this.newZoneRateZoneId)

            if (!myZone.rates) {
                myZone.rates = []
            }

            if (this.sellerDataForm.defaultShippingModel == 'weight') {
                inRates.forEach((weightRate) => {
                    let myForm = this.objCopy(inForm)
                    myForm.minWeight = weightRate?.minWeight ?? 0
                    myForm.maxWeight = weightRate?.maxWeight ?? 999999999
                    myForm.price = weightRate.price
                    myForm.id = myZone.rateId + 1 ?? 1
                    myZone.rateId = myForm.id
                    myZone.rates.push(myForm)
                })

                this.showAddNewZoneRate = false
                this.newZoneRateZoneId = null
                this.addingRateZone = null
                this.save()
            }
            else if (this.sellerDataForm.defaultShippingModel == 'price') {
                inRates.forEach((priceRate) => {
                    let myForm = this.objCopy(inForm)
                    myForm.minPrice = priceRate?.minPrice ?? 0
                    myForm.maxPrice = priceRate?.maxPrice ?? 999999999
                    myForm.price = priceRate.price
                    myForm.id = myZone.rateId + 1 ?? 1
                    myZone.rateId = myForm.id
                    myZone.rates.push(myForm)
                })

                this.showAddNewZoneRate = false
                this.newZoneRateZoneId = null
                this.addingRateZone = null
                this.save()
            }
            else {
                inForm.id = myZone.rateId + 1 ?? 1
                myZone.rateId = inForm.id
                myZone.rates.push(inForm)
                this.showAddNewZoneRate = false
                this.newZoneRateZoneId = null
                this.addingRateZone = null
                this.save()
            }
        },
        async createSeller() {
            if (!this.loggedUser.temp_vendor_id) {
                let promise = new Promise((resolve, reject) => {
                    buildzAxios?.put(this.getRoute('sellerData'), 
                    {
                        name: this.sellerForm.name
                    })
                    .then((response) => {
                        if (response?.data?.vendor_id ?? false) {
                            this.sellerId = response.data.vendor_id

                            myLoggedUser = this.objCopy(this.loggedUser)
                            myLoggedUser.temp_vendor_id = response.data.vendor_id

                            this.setStateData([
                                { loggedUser: myLoggedUser }
                            ])
                        }

                        resolve(response)
                    })
                    .catch((error) => {
                        console.log('error creating seller')
                        reject(false)
                    })
                })

                this.saveStart()
                var myReturn = false

                await promise
                    .then((result) => {
                        myReturn = result
                    })
                    .catch((e) => {

                    })
            
                this.saveDone()
                return myReturn
            }
        },
        galleryImageSavedOkay(inImage, inIndex) {
            this.gallery[inIndex] = inImage
        },
        async getTempSeller() {
            let promise = new Promise((resolve, reject) => {
                buildzAxios?.get(this.getRoute('sellerData'), 
                {
                    params: {
                        vendor_id: this.loggedUser.temp_vendor_id
                    }
                })
                .then((response) => {
                    this.setStateData([
                        { tempSellerVendorData: response.data.marketplace_vendor_data },
                        { tempSellerVendor: response.data.marketplace_vendor },
                        { tempSellerVendorWhiteLabelData: response.data.marketplace_vendor_white_label_data }
                    ])

                    this.formLoaded = true
                    resolve(response.data.sales)
                })
                .catch((error) => {
                    console.log('error getting temp seller')
                    reject(false)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        jumpToPage(inPageNumber) {
            this.currentPage = inPageNumber
        },
        nextPage() {
            if (this.pages[this.currentPage].validation) {
                this.validating = false

                if (!this.sellerId) {
                    this.createSeller()
                }

                if (this.currentPage == 19) {
                    this.openSpinner()
                    this.activating = true
                    this.sellerForm.incomplete = false
                    this.save()
                }
                else {
                    this.currentPage = this.pages[this.currentPage].next
                }
            }
            else {
                this.validating = true
            }
        },
        previousPage() {
            this.currentPage = this.pages[this.currentPage].previous
        },
        save() {
            this.saveStart()

            let myData = { ...this.sellerForm, ...this.sellerDataForm }
            myData.vendor_id = this.sellerId

            buildzAxios?.post(this.getRoute('sellerDataNew'), this.objCopy(myData))
                .then((response) => {
                    this.saveDone()

                    if (this.activating) {
                        buildzAxios?.put(this.getRoute('garageUpdate', this.loggedUser.id),
                            {
                                current_vendor_id: this.sellerId 
                            }
                        )
                        .then((response) => {
                            this.routeTo('/marketplace/seller')

                            setTimeout(() => {
                                window.location.reload()
                            }, 100)
                        })
                        .catch((error) => {
                            console.log(error)
                            this.closeSpinner()
                        })

                        this.closeNewSellerWizard()
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.saveDone()
                })
        },
        saveDebounce: debounce(function() {
            this.save()
        }, 2000),
        async saveLocalAvatar(inGallery) {
            this.avatarImage = null
            this.avatarGallery = inGallery
            await this.postGallery('sellerAvatar', inGallery, this.sellerId)
        },
        async saveLocalGallery(inGallery) {
            this.gallery = inGallery
            await this.postGallery('sellerGallery', inGallery, this.sellerId)
        },
        updateManualPickupAddress(inValue) {
            this.manualPickupAddress = inValue
        },
        async updateOfflineAvatar(inGallery) {
            this.avatarImage = null
            this.avatarGallery = inGallery
            let myTempGallery = await this.buildOfflineTempGallery(inGallery, `sellerAvatar_temp${this.sellerId}`)
            this.tempAvatarGallery = myTempGallery
            
            setTimeout(() => {
                this.saveGalleryPrep(this.tempAvatarGallery, 'sellerAvatarUpdate', this.sellerId, 'sellerAvatar')
            }, 100)
        },
        async updateOfflineGallery(inGallery) {
            this.gallery = inGallery
            let myTempGallery = await this.buildOfflineTempGallery(inGallery, `sellerGallery_temp${this.sellerId}`)
            this.tempGallery = myTempGallery

            setTimeout(() => {
                this.saveGalleryPrep(this.tempGallery, 'sellerGalleryUpdate', this.sellerId, 'sellerGallery')
            }, 100)
        },
        updatePaymentType(inField, inValue) {
            let myTypes = this.sellerForm.payment_types

            if (inValue) {
                if (!myTypes.includes(inField)) {
                    myTypes.push(inField)
                }
            }
            else {
                if (myTypes.includes(inField)) {
                    myTypes = myTypes.filter((myType) => myType != inField)
                }
            }

            this.sellerForm.payment_types = null
            this.sellerForm.payment_types = myTypes
            this.save()
        },
        updateSellerDataFormLocalAddressValue(inField, inValue) {
            this.sellerDataForm.localPickupAddress.address[inField] = inValue
            this.saveDebounce()
        },
        updateSellerDataFormSelectValue(inField, inValue) {
            this.sellerDataForm[inField] = inValue
            this.save()
        },
        updateSellerDataFormValue(inField, inValue) {
            this.sellerDataForm[inField] = inValue
            this.saveDebounce()
        },
        updateSellerFormValue(inField, inValue) {
            this.sellerForm[inField] = inValue

            if (this.sellerId) {
                this.saveDebounce()
            }
        },
        updateTaxRates(inTaxRates, inDefaultTaxRate) {
            this.sellerDataForm.taxRates = []
            this.sellerDataForm.taxRates = inTaxRates
            this.sellerDataForm.defaultTaxRate = inDefaultTaxRate
            this.save()
        }
    }
}
</script>