const buildMixins = {
    computed: {
        // nfc() {
        //     return this.$page?.props?.nfc || false
        // },
        // modsEmpty() {
        //     return this.$page?.props?.modsEmpty
        // },
        // availableListTypes() {
        //     return this.$page?.props?.build?.availableListTypes
        // },
        // availableListTemplates() {
        //     return this.$page?.props?.build?.availableListTemplates
        // },
        // availabilities() {
        //     return this.$page?.props?.build?.availabilities
        // },
        // currencies() {
        //     return this.$page?.props?.build?.currencies
        // },
        // buildLists() {
        //     return this.$page?.props?.build?.lists
        // }
    },
    methods: {
        approveBuildTransfer(inTransferId, inBuildId, inApprove) {
            buildzAxios?.post(this.getRoute('buildTransferApprove', inTransferId), {
                buildId: parseInt(inBuildId),
                approve: inApprove
            })
            .then((response) => {
                if (response.data) {
                    let myUser = this.objCopy(this.loggedUser)
                    myUser.builds = this.objCopy(response.data.builds)
                    myUser.approved_build_transfers = this.objCopy(response.data.approved_build_transfers)
                    myUser.denied_build_transfers = this.objCopy(response.data.denied_build_transfers)
                    
                    this.setStateData([
                        { loggedUser: this.objCopy(myUser) }
                    ])
                }
                else {
                    this.pageMessage('error', 'An error occured')
                }
            })
            .catch((error) => {
                this.pageMessage('error', 'An error occured')
            })
        },
        buildIsMine(inBuild) {
            return this.loggedUser && inBuild.owner.id == this.loggedUserId
        },
        closeNewBuildModal() {
            console.log('close')
            this.setStateData([
                { showNewBuildModal: false }
            ])
        },
        isMyBuild(inId, inHandle) {
            if (!this.loggedUser) {
                return 'build.show'
            }

            if (inId) {
                return this.loggedUser.builds.filter(build => build.id == inId).length > 0 ? 'build.myBuildShow' : 'build.show'
            }
            
            if (inHandle) {
                return this.loggedUser.builds.filter(build => build.handle == inHandle).length > 0 ? 'build.myBuildShow' : 'build.show'
            }

            return 'build.show'
        },
        openNewBuildModal() {
            if (this.serverConnected) {
                this.setStateData([
                    { showNewBuildModal: true }
                ])
            }
        }
    }
}

export default buildMixins