const groupMixins = {
    methods: {
        closeNewGroupModal() {
            this.setStateData([
                { showNewGroupModal: false },
                { groupEditForm: null },
                { editingGroup: null }
            ])
        },
        deleteGroup(inGroupId, inGroupName) {
            buildzAxios?.delete(this.getRoute('communityDelete', inGroupId))
                .then((response) => {
                    if (response.status == 200) {
                        this.pageMessage('success', `You deleted the group ${inGroupName}`)
                        this.routeTo('/communities/search')
                        this.closeNewGroupModal()
                    }
                    else {
                        this.pageMessage('error', 'Error deleting group')
                    }

                    this.postingNewGroup = false
                })
                .catch((error) => {
                    this.pageMessage('error', 'Error deleting group')
                    this.postingNewGroup = false
                })
        },
        openNewGroupModal(inEditingGroup = null) {
            if (this.serverConnected) {
                this.setStateData([
                    { editingGroup: inEditingGroup },
                    { showNewGroupModal: true }
                ])
            }
        },
        publishUpdatedGroup(inGroup) {
            if (this.communityLists) {
                Object.entries(this.communityLists).forEach(([listId, groups]) => {
                    let myGroups = this.objCopy(groups)

                    myGroups = myGroups.map((group) => {
                        if (group.id == inGroup.id) {
                            return inGroup
                        }
                        else {
                            return group
                        }
                    })

                    this.updateGroups(myGroups, listId)
                })
            }

            this.setStateData([
                { updatedGroup: inGroup }
            ])
        },
        updateGroups(inGroups, inListId) {
            if (this.communityLists) {
                let { [`${inListId}`]: _, ...myListGroups } = this.objCopy(this.communityLists)
                myListGroups = { ...myListGroups, [`${inListId}`]: inGroups }

                this.replaceStateData([
                    { communityLists: this.objCopy(myListGroups) }
                ])
            }
            else {
                this.replaceStateData([
                    { communityLists: { [`${inListId}`]: inGroups } }
                ])
            }
        }
    }
}

export default groupMixins