<template>
    <transition
        enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-active-class="transition duration-700 ease-out transform"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-class="opacity-100"
        leave-active-class="transition duration-500 ease-in"
        leave-to-class="opacity-0"
    >
        <div v-if="showing && systemMessage" class="fixed top-12 inset-0 flex items-end justify-center px-4 py-6 pointer-events-none z-80 sm:p-6 sm:items-start sm:justify-end">
            <div class="w-full max-w-sm bg-white rounded-lg pointer-events-auto border border-gray-300">
                <div class="overflow-hidden rounded-lg">
                    <div class="p-4">
                        <div class="flex items-start">
                            <div class="shrink-0">
                                <fa-icon v-if="systemMessage.level == 'success'" icon="check-circle" type="fas" class="h-6 w-6 text-green-400" />
                                <fa-icon v-if="systemMessage.level == 'error'" icon="circle-exclamation" type="fas" class="h-6 w-6 text-red-400" />
                                <fa-icon v-if="systemMessage.level == 'warning'" icon="circle-exclamation" type="fas" class="h-6 w-6 text-orange-400" />
                                <fa-icon v-if="systemMessage.level == 'info'" icon="circle-info" type="fas" class="h-6 w-6 text-twitterblue" />
                            </div>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="text-sm font-medium leading-5 text-gray-900">
                                {{ systemMessage ? systemMessage.result : '' }}
                                </p>
                                <p class="mt-1 text-sm leading-5 text-gray-500 line-clamp-2">
                                {{ systemMessage ? systemMessage.help : '' }}
                                </p>
                            </div>
                            <div class="flex shrink-0 ml-4">
                                <button @click="dismiss()" class="inline-flex text-gray-400 transition duration-150 ease-in-out focus:outline-none focus:text-gray-500">
                                    <fa-icon icon="xmark" type="fas" class="h-6 w-6" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    data() {
        return {
            dismissed: false,
            showing: false
        }
    },
    watch: {
        systemMessage() {
            this.checkMessage()
        }
    },
    mounted() {
        this.checkMessage()
    },
    methods: {
        checkMessage() {
            if (this.systemMessage && this.systemMessage.result && !this.showing)
            {
                setTimeout(() => { 
                    this.dismiss()
                }, 3000)

                this.showing = true
            }
        },
        dismiss() {
            this.showing = false
        }
    }
}
</script>