<template>
    <div :class="{ 'desktop-buttons-panel': !isLoggedIn, 'desktop-buttons-panel-logged-in': isLoggedIn }" :style="showGlobalIcons || !userHideIcons ? { 'margin-top': '0px' } : { 'margin-top': '-160px' }" style="background: rgba(0, 0, 0, 0.40);">
        <div class="w-full">
            <div class="h-16 flex items-center">
                <div class="max-width-centered-box">
                    <div v-if="isDesktopWidth" class="flex items-center justify-center">
                        <div @click="clickHome()" class="cursor-pointer mx-2 px-2 rounded-full" :class="{ 'bg-orange-500': $route.name == 'Home' }">
                            Home
                        </div>
                        <div v-if="!isLoggedIn && $route.name != 'Login' && $route.name != 'Register'" @click="clickRegister()" class="cursor-pointer mx-2 px-2">
                            Sign Up
                        </div>
                        <div v-if="isLoggedIn && loggedUser" @click="clickFeed()" class="cursor-pointer mx-2 px-2 rounded-full" :class="{ 'bg-orange-500': $route.name == 'Feed' }">
                            Feed
                        </div>
                        <div v-if="isLoggedIn && loggedUser" @click="clickNewActivity()" class="cursor-pointer mx-2 px-2">
                            New Post
                        </div>
                        <notification-text v-if="isLoggedIn && loggedUser" />
                        <div @click="clickCart()" class="cursor-pointer mx-2 px-2 rounded-full" :class="{ 'bg-orange-500': $route.name == 'Cart' }">
                            Cart
                        </div>
                        <div v-if="isLoggedIn && loggedUser" class="relative overflow-visible whitespace-nowrap">
                            <div @click="clickOpenBuildsMenu()" class="cursor-pointer mx-2 px-2 flex items-center justify-start">
                                <div>
                                    Account
                                </div>
                                <div class="ml-2 w-6 h-6 rounded-full relative border-2 border-orange-500">
                                    <user-avatar :url="loggedUser.avatar" :rounded="true" />
                                </div>
                            </div>
                            <div v-if="menuOpen && (showGlobalIcons || !userHideIcons)" class="absolute top-10 right-0 mt-1 px-2" style="background: rgba(0, 0, 0, 0.90);">
                                <div class="inline-flex flex-col pr-1 mb-3 items-end justify-end">
                                    <div class="standard-shadow mt-4">
                                        <div  @click="clickInvite()" class="inline-flex items-center justify-end cursor-pointer">
                                            <div class="pr-2">
                                                Invite Your Friends
                                            </div>
                                            <div class="w-6 h-6 rounded-full flex items-center justify-center bg-white text-orange-500">
                                                <fa-icon v-if="isAndroidApp" icon="share-nodes" type="fas" class="h-4 w-4" />
                                                <fa-icon v-else-if="isIOSApp" icon="arrow-up-from-bracket" type="fas" class="h-4 w-4" />
                                                <fa-icon v-else icon="link" type="fas" class="h-4 w-4" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <div  @click="clickNewBuild()" class="inline-flex items-center justify-end cursor-pointer">
                                            <div class="pr-2">
                                                Create a New Build
                                            </div>
                                            <div class="text-white">
                                                <fa-icon icon="circle-plus" type="fas" class="h-6 w-6" />
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="build in loggedUser.builds" class="mt-4">
                                        <div @click="clickBuild(build)" class="inline-flex items-center justify-end cursor-pointer">
                                            <div class="pr-2">
                                                {{ build.name }}
                                            </div>
                                            <div class="w-6 h-6 rounded-full relative">
                                                <build-avatar :url="build.primary_image" size="sm" :rounded="true" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="standard-shadow mt-4">
                                        <div v-if="vendorDataOkay && sellerVendor" @click="clickSeller" class="inline-flex items-center justify-end cursor-pointer">
                                            <div class="pr-2">
                                                Seller Profile
                                            </div>
                                            <div class="w-6 h-6 rounded-full relative">
                                                <user-avatar :url="sellerVendor.avatar" :rounded="true" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="standard-shadow mt-4">
                                        <div @click="clickGarage" class="inline-flex items-center justify-end cursor-pointer">
                                            <div class="pr-2">
                                                Home Garage
                                            </div>
                                            <div class="w-6 h-6 rounded-full relative">
                                                <user-avatar :url="loggedUser.avatar" :rounded="true" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NotificationText from '@/shared/Notifications/NotificationText'

export default {
    components: {
        NotificationText
    },
    data() {
        return {
            menuOpen: false
        }
    },
    methods: {
        clickBuild(inBuild) {
            this.routeTo(`/build/${inBuild.handle}`)
            this.clickOpenBuildsMenu()
        },
        clickCart() {
            this.routeTo(`/cart`)
        },
        clickFeed() {
            this.routeTo(`/feed`)
            this.menuOpen = false
        },
        clickGarage() {
            this.routeTo(`/garage/${this.loggedUser.handle}`)
            this.clickOpenBuildsMenu()
        },
        clickHome() {
            this.routeTo(`/`)
            this.menuOpen = false
        },
        clickInvite() {
            this.appsFlyerEvent(
                {
                    eventName: 'Click Invite Friends',
                    eventValue: {
                    }
                }
            )

            if (this.isAndroidApp || this.isIOSApp) {
                this.nativeShare(
                    {
                        text: '',
                        url: this.appsFlyerOneLink
                    }
                )
            }
            else {
                navigator.clipboard.writeText(this.appsFlyerOneLink)
                    .then(() => {
                        this.pageMessage('success', 'URL copied to clipboard')
                    })
                    .catch(() => {
                        this.pageMessage('error', 'Unable to access your clipboard')
                    })
            }
        },
        clickNewActivity() {
            this.openWriteActivity()
            this.menuOpen = false
        },
        clickNewBuild() {
            this.appsFlyerEvent(
                {
                    eventName: 'Click Create A Build',
                    eventValue: {
                    }
                }
            )

            this.openNewBuildModal()
            this.clickOpenBuildsMenu()
        },
        clickOpenBuildsMenu() {
            this.menuOpen = !this.menuOpen
        },
        clickRegister() {
            this.routeTo(`/register`)
            this.menuOpen = false
        },
        clickSeller() {
            this.routeTo(`/seller/${this.sellerVendor.handle}`)
            this.clickOpenBuildsMenu()
        }
    }
}
</script>

<style>
.desktop-buttons-panel {
    transition: margin 0.25s;
    @apply fixed text-center left-0 z-20 w-full
}

.desktop-buttons-panel-logged-in {
    width: calc(100% - 14rem);
    transition: margin 0.25s;
    @apply fixed text-center left-56 z-20
}
</style>