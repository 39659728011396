<template>
    <div class="w-full">
        <div class="w-full">
            <div v-if="!pProducts || this.objLength(pProducts) < 1" class="mt-px white-transparent p-2 text-red-500">
                Add one or more Products to your {{ pageData.groupBuy.adminLabelSingular }}!
            </div>
            <product-card v-for="product in pProducts" 
                :product="product" :pSortedPricingLevels="pSortedPricingLevels" :showBreakpoints="true" :showDelete="!pGroupBuyForm.eoi && pGroupBuyForm.disabled"
                :showEdit="true"
                @clickDeleteProduct="clickDeleteProduct"
            />
            <div v-if="!pGroupBuyForm.eoi && pGroupBuyForm.disabled" class="w-full white-transparent mt-px py-4 px-2" :class="{ 'desktop-button-box justify-center': isDesktopWidth }">
                <div @click="clickCreateNewProduct()" class="alt-button-base">
                    Create a new product
                </div>
                <div @click="clickAddProducts()" class="standard-button-base" :class="{ 'ml-2': isDesktopWidth, 'mt-2': !isDesktopWidth }">
                    Add products
                </div>
            </div>
        </div>
        <select-products-modal v-if="showSelectProductsModal && vendorProducts" 
            :productIds="productIds" :showSelectProductsModal="showSelectProductsModal"
            @addProducts="addProducts" @closeSelectProductsModal="closeSelectProductsModal"
        />
    </div>
</template>

<script>
import ProductCard from '@/shared/Cards/ProductCard'
import SelectProductsModal from '../modals/SelectProductsModal'

export default {
    props: [
        'pGroupBuyForm', 
        'pProducts', 
        'pSortedPricingLevels'
    ],
    components: {
        ProductCard,
        SelectProductsModal
    },
    data() {
        return {
            groupBuyForm: {
                products: null
            },
            showSelectProductsModal: false
        }
    },
    computed: {
        productIds() {
            return this.pProducts.map((product) => {
                return product.id
            }) ?? []
        }
    },
    watch: {
        groupBuyNewProduct: {
            handler(val) {
                if (val) {
                    let myProducts = this.objCopy(this.pProducts)
                    myProducts.push(this.objCopy(this.groupBuyNewProduct))
                    this.groupBuyForm.products = myProducts
                    this.$emit('updateProducts', this.groupBuyForm)
                }
            }
        },
        productEditing: {
            handler(val) {
                if (val) {
                    let myProducts = this.objCopy(this.pProducts)

                    if (myProducts) {
                        let myNewProducts = []

                        myProducts.forEach((product) => {
                            myNewProducts.push(product.id == this.productEditing.id ? this.objCopy(this.productEditing) : product)
                        })
                        
                        this.groupBuyForm.products = myNewProducts
                        this.$emit('updateProducts', this.groupBuyForm)
                    }
                }
            }
        }
    },
    mounted() {
        this.groupBuyForm.products = this.objCopy(this.pGroupBuyForm.products)
    },
    methods: {
        addProducts(inProducts, inListings) {
            this.closeSelectProductsModal()
            let myProducts = this.objCopy(this.pProducts)
            
            inProducts.forEach((product) => {
                let vp = Object.values(inListings).find((vProduct) => vProduct.id == product)

                if (vp) {
                    myProducts.push(this.objCopy(vp))
                }
            })

            this.groupBuyForm.products = myProducts
            this.$emit('updateProducts', this.groupBuyForm)
        },
        clickAddProducts() {
            // this.getListings(true)
            this.showSelectProductsModal = true
        },
        clickCreateNewProduct() {
            this.openNewListingModal()
        },
        clickDeleteProduct(inProductId) {
            if (confirm(`You wish to remove this product from your ${this.pageData.groupBuy.adminLabelSingular}?`)) {
                let myProducts = this.objCopy(this.pProducts)
                myProducts = myProducts.filter((product) => product.id != inProductId)

                this.groupBuyForm.products = myProducts
                this.$emit('updateProducts', this.groupBuyForm)
            }
        },
        closeSelectProductsModal() {
            this.showSelectProductsModal = false
        }
    }
}
</script>