<template>
    <div class="relative w-full text-center">
        <div @click="clickSection()" class="w-full text-center text-lg font-bold p-2 mt-2 rounded-sm cursor-pointer" :class="{ 'orange-transparent': isOpen && adminSection, 'white-transparent': (!isOpen && adminSection && !whiteLabel) || (!adminSection && whiteLabelDark), 'black-transparent': !adminSection && whiteLabel && whiteLabelLight }">
            {{ sectionLabel }}
        </div>
        <slot v-if="isOpen" 
        />
        <div class="absolute top-2 right-0">
            <div @click="clickSection()" v-show="!isOpen" class="cursor-pointer px-2">
                <fa-icon icon="chevron-left" type="fas" class="h-6 w-6" />
            </div>
            <div @click="clickSection()" v-show="isOpen" class="cursor-pointer px-2">
                <fa-icon icon="chevron-down" type="fas" class="h-6 w-6" />
            </div>
        </div>
        <div v-if="sectionCounter" class="absolute left-2" style="top: 0.6rem;">
            <div :class="{ 'red-bubble-big': !sectionCounterClass, [sectionCounterClass]: sectionCounterClass }">
                <div class="whitespace-nowrap">
                    {{ sectionCounter }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'adminSection',
        'sectionCounter',
        'sectionCounterClass',
        'sectionLabel',
        'sectionsOpen'
    ],
    data() {
        return {
            isOpen: false
        }
    },
    mounted() {
        this.isOpen = this.sectionsOpen ? this.sectionsOpen.includes(this.sectionLabel) : this.isOpen
    },
    methods: {
        clickSection() {
            this.isOpen = !this.isOpen
            this.$emit('cacheSectionsOpen', this.sectionLabel)       
        }
    }
}
</script>