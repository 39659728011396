<template>
    <div class="overflow-hidden py-16 relative page-standard-height-width">
        <div class="w-full h-full mx-auto max-main-width">
            <div class="w-full h-full max-width-box gray-bg">
                <cached-feed />
            </div>
        </div>
    </div>
</template>

<script>
import CachedFeed from '@/shared/ActivityFeed/CachedFeed'

export default {
    components: {
        CachedFeed
    },
    mounted() {
        document.title = 'Home'
    }
}
</script>