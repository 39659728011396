<template>
    <div v-if="latestSaleOn && latestSale" class="fixed bg-black border-white text-white" :class="{ 'bottom-4 left-4 border rounded-md': isDesktopWidth, 'bottom-0 left-0 border-t w-full': !isDesktopWidth }" style="z-index: 40">
        <div class="flex items-start justify-stretch">
            <div v-if="latestSale.image" class="h-20 w-20">
                <cached-image imageClasses="object-cover h-full w-full rounded-l-md" :imageSrc="transformUrl(latestSale.image, ['optimize', 'md', 'q80'])" />
            </div>
            <div class="p-2 text-xs text-left" :class="{ 'pr-12': isDesktopWidth, 'pr-6': !isDesktopWidth }">
                <div>
                    {{ saleText }}
                </div>
                <div class="font-bold mt-1">
                    {{ latestSale.label }}
                </div>
            </div>
        </div>
        <div @click="hideLatestSale(latestSaleId)" class="absolute right-1 top-1 cursor-pointer">
            <fa-icon icon="xmark" type="fas" class="h-5 w-5" />
        </div>
        <div class="absolute bottom-2 pl-2 text-orange-500 text-xs" :class="{ 'left-20':isDesktopWidth, 'right-2':!isDesktopWidth }" :style="whiteLabel ? { 'color': whiteLabelData.secondaryColor } : {}">
            {{ minutesAgo(latestSale.create_time) }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentGroupOfferId: null,
            latestSaleId: null,
            lastestSaleInterval: null,
            latestSaleOn: false,
            latestSaleShowNow: false
        }
    },
    computed: {
        gbLatestSales() {
            if (this.latestSaleGroupOfferId && this.latestSales) {
                let mySales = []
                
                Object.values(this.latestSales).forEach((mySale) => {
                    if (mySale.group_buy_id == this.latestSaleGroupOfferId) {
                        mySales.push(mySale)
                    }
                })

                return this.objCopy(mySales)
            }

            return null
        },
        latestSale() {
            return this.gbLatestSales && this.objLength(this.gbLatestSales) ? Object.values(this.gbLatestSales)[0] : null
        },
        saleText() {
            let mySuburb = this.latestSale.suburb ? `${this.latestSale.suburb}, ` : ''

            return `Someone from ${mySuburb}${this.coGetCountryName(this.latestSale.country)} purchased`
        }
    },
    watch: {
        gbLatestSales: {
            handler(val) {
                if (val) {
                    if (this.objLength(this.gbLatestSales) && ((!this.latestSaleId && this.objLength(this.gbLatestSales) == 1) || (Object.values(this.gbLatestSales)[0].id > this.latestSaleId && this.latestSaleId > 1))) {
                        this.latestSaleShowNow = true
                    }
                }
            }
        },
        latestSaleGroupOfferId: {
            immediate: true,
            handler(val) {
                if (val) {
                    setTimeout(() => {
                        this.showLatestSale()
                    }, 5000)

                    this.lastestSaleInterval = setInterval(() => { this.showLatestSale() }, 30000)
                    this.currentGroupOfferId = this.latestSaleGroupOfferId
                }
            }
        },
        latestSaleShowNow: {
            handler(val) {
                if (val && !this.latestSaleOn) {
                    this.showLatestSale()
                    this.latestSaleShowNow = false
                }
            }
        }
    },
    beforeUnmount() {
        clearInterval(this.lastestSaleInterval)

        this.setStateData([
            { latestSaleGroupOfferId: null }
        ])
    },
    methods: {
        hideLatestSale(inSaleId) {
            if (inSaleId == this.latestSaleId) {
                this.latestSaleOn = false
                let myLatestSales = []

                Object.values(this.objCopy(this.latestSales)).forEach((mySale) => {
                    if (mySale.id != inSaleId) {
                        myLatestSales.push(mySale)
                    }
                })

                this.setStateData([
                    { latestSales: null }
                ])

                this.setStateData([
                    { latestSales: this.objCopy(myLatestSales) }
                ])

                if (this.latestSaleShowNow) {
                    this.showLatestSale()
                    this.latestSaleShowNow = false
                }
            }
        },
        showLatestSale() {
            if (!this.latestSaleOn && this.objLength(this.gbLatestSales) && Object.values(this.gbLatestSales)[0].id != this.latestSaleId) {
                this.latestSaleOn = true
                this.latestSaleId = Object.values(this.gbLatestSales)[0].id

                setTimeout(() => {
                    this.hideLatestSale(this.latestSaleId)
                }, 10000)
            }
        }
    }
}
</script>