const flasherMixins = {
    methods: {
        setFlasher(inFlasher) {
            this.setStateData([
                { flasherPage: inFlasher }
            ])
        }
    }
}

export default flasherMixins
