<template>
    <div class="relative">
        <slot />
        <div v-if="displayedItems && displayedItems.length" class="absolute overflow-y-scroll text-sm bg-white border border-gray-400 z-10 text-gray-500" style="max-height: 8rem;" :style="caretPosition ? { top: `${caretPosition.top + caretPosition.height}px`, left: `${caretPosition.left}px`, } : {}">
            <div v-for="(item, index) of displayedItems" :key="index" class="flex items-center p-1 m-1 border-border-white" :class="{ 'border-gray-900 bg-gray-200': selectedIndex === index }"
                @mouseover="selectedIndex = index" @mousedown="applyMention(index)"
            >
                <div v-if="item.avatar" class="w-8 h-8 mr-2 rounded-full">
                    <user-avatar :url="item.avatar" :rounded="true" />
                </div>
                <span>{{ item. name}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import getCaretPosition from 'textarea-caret'

const userAgent = typeof window !== 'undefined' ? window.navigator.userAgent : ''
const isIe = userAgent.indexOf('MSIE ') !== -1 || userAgent.indexOf('Trident/') !== -1

export default {
    inheritAttrs: false,
    props: {
        keys: {
            type: Array,
            required: true,
        },
        limit: {
            type: Number,
            default: 8,
        },
        inputId: {
            type: String,
            required:true
        },
        restrictedUsers: {}
    },
    data () {
        return {
            items: null,
            key: null,
            oldKey: null,
            searchText: null,
            caretPosition: null,
            selectedIndex: 0,
            filteredItems: null,
            links: [],
            prevInputValue: null,
            currentDownKeyCode: null
        }
    },
    computed: {
        displayedItems() {
            if (!this.filteredItems) {
                return null
            }

            if (this.restrictedUsers && this.key == "@") {
                return this.filteredItems.filter((user) => this.restrictedUsers.includes(user.user_id)).sort((a, b) => a.name > b.name ? 1 : -1).slice(0, this.limit)
            }

            return this.filteredItems.sort((a, b) => a.name > b.name ? 1 : -1).slice(0, this.limit)
        },
        userIndex() {
            return this.algoliaSearchClient?.initIndex('users')
        },
        buildsIndex() {
            return this.algoliaSearchClient?.initIndex('builds')
        },
        tagsIndex() {
            return this.algoliaSearchClient?.initIndex('hashtags')
        }
    },
    watch: {
        displayedItems() {
            this.selectedIndex = 0
        },
        searchText(value, oldValue) {
            if (value) {
                if (this.key == "@") {
                this.searchUsers()
                }
            }

            if (this.key == "+") {
                this.searchBuilds()
            }

            if (this.key == "#") {
                this.searchTags()
            }
        },
    },
    mounted() {
        this.input = this.getInput()
        this.attach()
    },
    updated() {
        const input = this.getInput()
        
        if (input !== this.input) {
            this.detach()
            this.input = input
            this.attach()
        }
    },
    beforeUnmount() {
        this.detach()
    },
    methods: {
        applyMention(itemIndex) {
            const item = this.displayedItems[itemIndex]
            const value = item.native

            if (this.input.isContentEditable) {
                const range = window.getSelection().getRangeAt(0)
                range.setStart(range.startContainer, range.startOffset - this.key.length - (this.lastSearchText ? this.lastSearchText.length : 0))
                range.deleteContents()
                range.insertNode(document.createTextNode(value))
                range.setStart(range.endContainer, range.endOffset)
                this.emitInputEvent('input')
            } else {
                this.setValue(this.replaceText(this.getValue(), this.searchText, value, this.keyIndex))
                this.setCaretPosition(this.keyIndex + value.length)
            }
            
            this.$emit('apply', item, this.key)
            this.closeMenu()
        },
        attach() {
            if (this.input) {
                this.input.addEventListener('input', this.onInput)
                this.input.addEventListener('keydown', this.onKeyDown)
                this.input.addEventListener('keyup', this.onKeyUp)
                this.input.addEventListener('scroll', this.onScroll)
                this.input.addEventListener('blur', this.onBlur)
                this.input.addEventListener('paste', this.onPaste)
            }
        },
        cancelEvent(e) {
            e.preventDefault()
            e.stopPropagation()
            this.cancelKeyUp = e.key
            // IE
            this.cancelKeyCode = e.keyCode
        },
        checkKey() {
            const index = this.getSelectionStart()

            if (index >= 0) {
                const { key, keyIndex } = this.getLastKeyBeforeCaret(index)
                const searchText = this.lastSearchText = this.getLastSearchText(index, keyIndex)
                
                if (!(keyIndex < 1 || /\s/.test(this.getValue()[keyIndex - 1]))) {
                    return false
                }

                let mySS = this.input.selectionStart - 1
                let myPos = mySS || 0
                let myChar = this.input.value.substr(myPos, 1)
                let myKeyCode = myChar.charCodeAt(0)

                if (searchText != null && myKeyCode != 32 && myKeyCode != 13) {
                    this.openMenu(key, keyIndex)
                    this.searchText = searchText
                    return true
                }
            }
            
            this.closeMenu()
            return false
        },
        checkLinks() {
            let myLinks = this.objCopy(this.links)
            this.links = this.getTextLinks(this.nlToBrSpace(this.input.value))

            if (JSON.stringify(myLinks) != JSON.stringify(this.links)) {
                this.$emit('getLinks', this.links)
            }
        },
        closeMenu() {
            //if (this.key != null) {
                this.oldKey = this.key
                this.key = null
                this.searchText = null
                this.filteredItems = null
            //}
        },
        detach() {
            if (this.input) {
                this.input.removeEventListener('input', this.onInput)
                this.input.removeEventListener('keydown', this.onKeyDown)
                this.input.removeEventListener('keyup', this.onKeyUp)
                this.input.removeEventListener('scroll', this.onScroll)
                this.input.removeEventListener('blur', this.onBlur)
                this.input.removeEventListener('paste', this.onPaste)
            }
        },
        emitInputEvent(type) {
            let event

            if (isIe) {
                event = document.createEvent('Event')
                event.initEvent(type, true, true)
            } else {
                event = new Event(type)
            }
            
            this.input.dispatchEvent(event)
        },
        getInput() {
            let myInput = this.elGet(this.inputId)
            
            if (myInput) {
                if (myInput.tagName === 'INPUT' || myInput.tagName === 'TEXTAREA' || myInput.isContentEditable) {
                    return myInput
                } else {
                    return myInput.querySelector('input') || myInput.querySelector('textarea') || myInput.querySelector('[contenteditable="true"]')
                }
            }
            
            return null
        },
        getLastKeyBeforeCaret(caretIndex) {
            const [keyData] = this.keys.map(key => ({
                key,
                keyIndex: this.getValue().lastIndexOf(key, caretIndex - 1),
            })).sort((a, b) => b.keyIndex - a.keyIndex)
            
            return keyData
        },
        getLastSearchText(caretIndex, keyIndex) {
            if (keyIndex !== -1) {
                const searchText = this.getValue().substring(keyIndex + 1, caretIndex)
                // If there is a space we close the menu
                if (!/\s/.test(searchText)) {
                return searchText
                }
            }
            
            return null
        },
        getSelectionStart() {
            return this.input.isContentEditable ? window.getSelection().anchorOffset : this.input.selectionStart
        },
        getValue() {
            return this.input.isContentEditable ? window.getSelection().anchorNode.textContent : this.input.value
        },
        onBlur() {
            this.checkLinks()
            this.closeMenu()
        },
        onInput() {
            if (this.prevInputValue != this.input.value || this.currentDownKeyCode != 229) {
                this.prevInputValue = this.input.value
                this.checkKey()
            }
        },
        onKeyDown(e) {
            let myKeyCode = e.keyCode || e.which
            this.currentDownKeyCode = myKeyCode

            if (!this.key) {
                this.closeMenu()
            }

            if (this.key && this.displayedItems) {
                if (e.key === 'ArrowDown' || myKeyCode === 40) {
                    this.selectedIndex++

                    if (this.selectedIndex >= this.displayedItems.length) {
                        this.selectedIndex = 0
                    }
                    this.cancelEvent(e)
                }
                
                if (e.key === 'ArrowUp' || myKeyCode === 38) {
                    this.selectedIndex--

                    if (this.selectedIndex < 0) {
                        this.selectedIndex = this.displayedItems.length - 1
                    }
                    this.cancelEvent(e)
                }
                
                if ((e.key === 'Enter' || e.key === 'Tab' || myKeyCode === 13 || myKeyCode === 9) && this.displayedItems.length > 0) {
                    this.applyMention(this.selectedIndex)
                    this.cancelEvent(e)
                }
                
                if (e.key === 'Escape' || myKeyCode === 27) {
                    this.closeMenu()
                    this.cancelEvent(e)
                }
            }
        },
        onKeyUp(e) {
            if (!this.key) {
                this.closeMenu()
            }

            if (this.prevInputValue != this.input.value || this.currentDownKeyCode != 229) {
                let myKeyCode = e.keyCode || e.which
                
                if (myKeyCode === 229) {
                    let mySS = this.input.selectionStart - 1
                    let myPos = mySS || 0
                    let myChar = this.input.value.substr(myPos, 1)
                    myKeyCode = myChar.charCodeAt(0)
                }

                if (e.key === ' ' || myKeyCode === 32 || e.key === 'Enter' || myKeyCode === 13) {
                    this.checkLinks()
                }

                if (this.cancelKeyUp && (e.key === this.cancelKeyUp || myKeyCode === this.cancelKeyCode)) {
                    this.cancelEvent(e)
                }
                this.cancelKeyUp = null
                // IE
                this.cancelKeyCode = null
            }
        },
        onPaste() {
            this.checkLinks()
        },
        onScroll() {
            this.updateCaretPosition()
        },
        openMenu(key, keyIndex) {
            if (this.key !== key) {
                this.key = key
                this.keyIndex = keyIndex
                this.updateCaretPosition()
                this.selectedIndex = 0
                this.items = key == '@' ? this.userIndex : (this.items = key == '+' ? this.buildsIndex : this.tagsIndex  )
            }
        },
        replaceText(text, searchText, newText, index) {
            return text.slice(0, index) + newText + text.slice(index + searchText?.length + 1, text?.length)
        },
        async searchBuilds() {
            let results = null

            if (this.items) {
                await this.items.search(this.searchText, {
                    numericFilters: [`owner_id=${this.loggedUserId}`]
                }).then(({ hits }) => {
                    return hits.filter(hit => hit.handle != '').map(hit => {
                        return { id: hit.id, name: hit.name, native: '+' + hit.handle, avatar: hit.primary_image }
                    })
                }).then((returnData) => {
                    results = returnData  
                })

                // await this.items.search(this.searchText).then(({ hits }) => {
                //     return hits.filter(hit => hit.handle != '').map(hit => {
                //         return { id: hit.id, name: hit.name, native: '+' + hit.handle, avatar: hit.primary_image }
                //     })
                // }).then((returnData) => {
                //     results = returnData  
                // })
            }

            this.filteredItems = results
        },
        async searchTags() {
            let results = null

            if (this.items) {
                await this.items.search(this.searchText).then(({ hits }) => {
                    return hits.filter(hit => hit.name != '').map(hit => {
                        return { id: hit.id, name: hit.name, native: '#' + hit.name, avatar: null }
                    })
                }).then((returnData) => {
                    results = returnData  
                })
            }

            this.filteredItems = results
        },
        async searchUsers() {
            let results = null

            if (this.items) {
                await this.items.search(this.searchText).then(({ hits }) => {
                    return hits.filter(hit => hit.handle != '').map(hit => {
                        return { id: hit.handle, name: hit.name, native: '@' + hit.handle, avatar: hit.avatar, user_id: hit.id }
                    })
                }).then((returnData) => {
                    results = returnData  
                })
            }

            this.filteredItems = results
        },
        setCaretPosition(index) {
            this.$nextTick(() => {
                this.input.selectionEnd = index
            })
        },
        setValue(value) {
            this.input.value = value
            this.emitInputEvent('input')
        },
        updateCaretPosition() {
            if (this.key) {
                if (this.input.isContentEditable) {
                    const rect = window.getSelection().getRangeAt(0).getBoundingClientRect()
                    const inputRect = this.input.getBoundingClientRect()

                    this.caretPosition = {
                        left: rect.left - inputRect.left,
                        top: rect.top - inputRect.top,
                        height: rect.height,
                    }
                } else {
                    this.caretPosition = getCaretPosition(this.input, this.keyIndex)
                    const inputRect = this.input.getBoundingClientRect()

                    if (this.caretPosition.left > (inputRect.width - 300)) {
                        this.caretPosition.left = inputRect.width - 300 < 10 ? 10 : inputRect.width - 300
                    }
                }
                this.caretPosition.top -= this.input.scrollTop
                
                if (this.$refs.popper && this.$refs.popper.popperInstance) {
                    this.$refs.popper.popperInstance.scheduleUpdate()
                }
            }
        }
    }
}
</script>