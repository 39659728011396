<template>
    <div v-if="formLoaded" class="w-full">
        <div class="w-full">
            <div v-if="!pSortedPricingLevels || this.objLength(pSortedPricingLevels) < 1" class="mt-px white-transparent p-2 text-red-500">
                Add one or more Discount Breakpoints to your {{ pageData.groupBuy.adminLabelSingular }}!
            </div>
            <div v-else class="mt-px white-transparent p-2 text-sm">
                Your first (lowest quantity) Discount Breakpoint Quantity is the minimum commitment quantity required for this Group Offer to become funded. 
            </div>
            <div v-for="level in pSortedPricingLevels" class="text-xs white-transparent py-2 px-1 mt-px">
                <div v-if="!editingBreakpointId || editingBreakpointId != level.id" class="flex items-center justify-start grid grid-cols-3 gap-y-2 gap-x-2">
                    <div class="whitespace-nowrap">
                        <span class="font-bold">Quantity</span> {{ level.qty }}
                    </div>
                    <div class="whitespace-nowrap">
                        <span class="font-bold">Discount</span> {{ level.discount }}%
                    </div>
                    <div v-if="pGroupBuyForm.disabled" class="flex items-center justify-end">
                        <div @click="clickDeleteBreakpoint(level)" class="cursor-pointer">
                            <fa-icon icon="trash-can" type="fas" class="h-6 w-6" />
                        </div>
                        <div @click="clickEditBreakpoint(level)" class="cursor-pointer ml-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-6 w-6" />
                        </div>
                    </div>
                </div>
                <div v-else class="orange-transparent px-1 py-2">
                    <div class="w-full flex items-center">
                        <div class="w-1/2">
                            <div class="mr-1">
                                <label class="text-sm font-bold leading-5">
                                    Quantity
                                </label>
                                <div>
                                    <input type="number" v-model="editBreakpointForm.qty" class="edit-field-text-color bg-white py-1 w-full form-input" />
                                </div>
                            </div>
                        </div>
                        <div class="w-1/2">
                            <div class="ml-1">
                                <label class="text-sm font-bold leading-5">
                                    Percentage Discount
                                </label>
                                <div>
                                    <input type="number" v-model="editBreakpointForm.discount" class="edit-field-text-color bg-white py-1 w-full form-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="desktop-button-box justify-end">
                        <div @click="clickSaveBreakpoint()" class="mt-4" :class=" { 'standard-button-base': editBreakpointForm.qty > 0, 'disabled-button-base': !(editBreakpointForm.qty > 0) } ">
                            Save
                        </div>
                        <div @click="clickCancelSaveBreakpoint()" class="mt-4 ml-2 alt-button-base">
                            Cancel
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="creatingNewBreakpoint" class="orange-transparent">
                <input-panel
                    fieldFormName="qty" fieldLabel="Quantity" fieldPlaceholder="Quantity" :fieldRequired="true" 
                    fieldType="number" :fieldValue="newBreakpointForm.qty"
                    @fieldInput="updateCreateFormValue"
                />
                <input-panel
                    fieldFormName="discount" fieldLabel="Percentage Discount" fieldPlaceholder="Percentage Discount" :fieldRequired="true" 
                    fieldType="number" :fieldValue="newBreakpointForm.discount"
                    @fieldInput="updateCreateFormValue"
                />
                <div class="w-full white-transparent mt-px py-4 px-2" :class="{ 'desktop-button-box justify-center': isDesktopWidth }">
                    <div @click="clickAddBreakpoint()" :class=" { 'standard-button-base': newBreakpointForm.qty > 0, 'disabled-button-base': !(newBreakpointForm.qty > 0) } ">
                        Add
                    </div>
                    <div @click="clickCancelAddBreakpoint()" class="alt-button-base" :class="{ 'ml-2': isDesktopWidth, 'mt-2': !isDesktopWidth }">
                        Cancel
                    </div>
                </div>
            </div>
            <div v-if="pGroupBuyForm.disabled && !creatingNewBreakpoint" class="w-full white-transparent mt-px py-4 px-2" :class="{ 'desktop-button-box justify-center': isDesktopWidth }">
                <div @click="clickNewBreakpoint()" class="standard-button-base">
                    Add breakpoint
                </div>
            </div>
        </div>
        <div>
            <product-card v-for="product in pProducts" 
                :product="product" :showBreakpoints="true" :sortedPricingLevels="pSortedPricingLevels"
            />
        </div>
    </div>
</template>

<script>
import ProductCard from '@/shared/Cards/ProductCard'

export default {
    props: [
        'pGroupBuy',
        'pGroupBuyForm', 
        'pProducts',
        'pQuantityLevels', 
        'pSortedPricingLevels'
    ],
    components: {
        ProductCard
    },
    data() {
        return {
            creatingNewBreakpoint: false,
            editBreakpointForm: {
                qty: 0,
                discount: 0,
                id: 0
            },
            editingBreakpointId: null,
            formLoaded: false,
            newBreakpointForm: {
                qty: 0,
                discount: 0,
                id: 0
            }
        }
    },
    mounted() {
        this.formLoaded = true
    },
    methods: {
        clickAddBreakpoint() {
            if (this.newBreakpointForm.qty) {
                let myBreakpoints = this.objCopy(this.pQuantityLevels)
                let myId = 0

                this.pQuantityLevels
                    .forEach((breakpoint) => {
                        if (breakpoint.id > myId) {
                            myId = breakpoint.id
                        }
                    })

                this.newBreakpointForm.id = myId + 1
                myBreakpoints.push(this.objCopy(this.newBreakpointForm))
                this.$emit('updateBreakpoints', myBreakpoints)
                
                this.newBreakpointForm.qty = 0
                this.newBreakpointForm.discount = 0
            }
        },
        clickCancelAddBreakpoint() {
            this.newBreakpointForm.qty = 0
            this.newBreakpointForm.discount = 0
            this.creatingNewBreakpoint = false
        },
        clickCancelSaveBreakpoint() {
            this.editBreakpointForm.id = 0
            this.editBreakpointForm.qty = 0
            this.editBreakpointForm.discount = 0
            this.editingBreakpointId = null
        },
        clickDeleteBreakpoint(inBreakpoint) {
            if (confirm('You wish to delete this discount breakpoint?')) {
                let myBreakpoints = this.objCopy(this.pQuantityLevels)

                myBreakpoints = myBreakpoints
                    .filter((breakpoint) => breakpoint.id != inBreakpoint.id)

                this.$emit('updateBreakpoints', myBreakpoints)
            }
        },
        clickEditBreakpoint(inBreakpoint) {
            this.editBreakpointForm.id = inBreakpoint.id
            this.editBreakpointForm.qty = inBreakpoint.qty
            this.editBreakpointForm.discount = inBreakpoint.discount
            this.editingBreakpointId = inBreakpoint.id
        },
        clickNewBreakpoint() {
            this.creatingNewBreakpoint = true
        },
        clickSaveBreakpoint() {
            let myBreakpoints = this.objCopy(this.pQuantityLevels)

            myBreakpoints = myBreakpoints
                .filter((breakpoint) => breakpoint.id != this.editBreakpointForm.id)

            myBreakpoints.push(this.objCopy(this.editBreakpointForm))
            this.$emit('updateBreakpoints', myBreakpoints)

            this.editingBreakpointId = null
            this.editBreakpointForm.qty = 0
            this.editBreakpointForm.discouunt
            this.editBreakpointForm.id =0
        },
        updateCreateFormValue(inField, inValue) {
            this.newBreakpointForm[inField] = inValue
        }
    }
}
</script>