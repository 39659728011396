<template>
    <div v-if="record && record.id">
        <div class="flex flex-col items-center justify-center w-full white-transparent mb-px">
            <div class="text-sm font-semibold leading-5 break-words line-clamp-3">
                {{ record.textOne }}
            </div>
            <div v-if="record.type == 'image' && record.image" class="relative w-20 h-full" style="min-width: 5rem; min-height: 5rem;">
                <offline-media-card 
                    :element="record.image" :filesCount="1" :hideDelete="true" :index="0"
                    @updateElement="updateElement"
                />
            </div>
            <div v-if="record.type == 'youtube' && record.youtube" class="w-full pb-2" v-html="youtubeLinks(record.youtube)">
            </div>
        </div>
    </div>
</template>

<script>
import OfflineMediaCard from '@/shared/Cards/OfflineMediaCard'

export default {
    props: [
        'record'
    ],
    components: {
        OfflineMediaCard
    },
    methods: {
        updateElement(inImage, inIndex) {
            this.$emit('updateMediaRecordImage', inImage)
        }
    }
}
</script>