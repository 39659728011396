<template>
    <modal v-if="showGiveAwayTcModal" :open="showGiveAwayTcModal" @close="$emit('closeShowGiveAwayTcModal')" class="text-white font-normal">
        <div class="absolute top-0 left-0 w-full bg-black" :class="{ 'pb-16': windowWidth < desktopMaxWidth, 'desktop-modal-box': windowWidth >= desktopMaxWidth }">
            <div class="relative" :class="{ 'desktop-modal': windowWidth >= desktopMaxWidth }">
                <div class="text-lg font-bold mb-2">
                    Terms and Conditions for Gaming Activity
                </div>
                <div class="font-semibold mb-1">
                    Introduction
                </div>
                These Terms and Conditions govern the process and requirements for any licensee or permit holder ("Licensee") who wishes to conduct a gaming activity with the details of their authorized gaming activity under the license or permit issued. Changes may include but are not limited to modifications in gaming operation, location, type of games, gaming equipment, or any other aspect related to the gaming activity.
                <div class="font-semibold mb-1 mt-2">
                    Compliance with Laws and Regulations
                </div>
                The Licensee agrees to conduct all gaming activities in strict compliance with applicable laws, regulations, and any conditions of the license or permit. All modifications must adhere to the legal and regulatory framework governing gaming activities within the jurisdiction.
                <div class="font-semibold mb-1 mt-2">
                    Documentation and Record Keeping
                </div>
                The Licensee must maintain accurate and up-to-date records of all modifications made to the gaming activity. Such records should be made available to the Buildz.pro Trading Pty Ltd upon request.
                <div class="font-semibold mb-1 mt-2">
                    Compliance Review and Audits
                </div>
                The Licensee acknowledges that any modifications to the gaming activity may be subject to compliance reviews and audits by Buildz.pro Trading Pty Ltd or its designated agents to ensure adherence to all applicable laws, regulations, and terms of the license or permit.
                <div class="font-semibold mb-1 mt-2">
                    Responsibility and Liability    
                </div>
                The Licensee remains fully responsible and liable for all activities conducted under their license or permit, including any modifications made. The Licensee shall indemnify and hold harmless Buildz.pro Trading Pty Ltd against any claims, damages, or losses arising from the Licensee's failure to comply with these Terms and Conditions.
                <div class="font-semibold mb-1 mt-2">
                    Sanctions for Non-Compliance
                </div>
                Non-compliance with these Terms and Conditions, applicable laws, regulations, or the conditions of the license or permit may result in penalties, including but not limited to fines, suspension, or revocation of the license or permit.
                <div class="font-semibold mb-1 mt-2">
                    Amendment and Variation
                </div>
                Buildz.pro Trading Pty Ltd reserves the right to amend these Terms and Conditions at any time. Any changes will be communicated to the Licensee in a timely manner.
                <div class="font-semibold mb-1 mt-2">
                    Acceptance of Terms
                </div>
                By implementing modifications to the gaming activity details, the Licensee acknowledges and agrees to these Terms and Conditions.
                <div class="mt-6 px-2">
                    <div class="flex justify-center">
                        <input id="terms" required ref="terms" type="checkbox" v-model="terms" class="w-4 h-4 text-orange-500 border-gray-300 rounded edit-field-text-color" :class="{ 'background-orange-500': terms, 'border-red-400': termsNotChecked }" />
                        <label for="terms" class="block ml-2 text-xs leading-5" :class="{ 'text-white font-light': !termsNotChecked, 'text-red-500 font-bold': termsNotChecked }">
                            <span>I agree with the above Terms and Conditions.</span>
                        </label>
                    </div>
                </div>
                <div class="w-full px-2">
                    <div @click="editLockedDetails()" class="w-full mt-6" :class="{ 'standard-button-base': terms, 'disabled-button-base': !terms }">
                        Agree and Edit Locked Details
                    </div>
                    <div @click="$emit('closeShowGiveAwayTcModal')" class="w-full mt-4 alt-button-base">
                        Close
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/shared/Modal'

export default {
    props: [
        'showGiveAwayTcModal'
    ],
    components: {
        Modal
    },
    data() {
        return {
            terms: false,
            termsNotChecked: false
        }
    },
    methods: {
        editLockedDetails() {
            if (!this.terms) {
                this.termsNotChecked = true
            }
            else {
                this.termsNotChecked = true
                this.$emit('editTermsAgreed')
            }
        }
    }
}
</script>