<template>
    <div v-if="record" class="w-full orange-transparent relative">
        <input-panel 
            fieldFormName="textOne" fieldLabel="Label" :fieldValue="record.textOne" :forceMobile="true"
            @fieldInput="updateFormValue"
        />
        <select-panel
            :fieldData="typeData" fieldFormName="type" fieldLabel="Type" :fieldValue="record.type" :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <input-panel v-if="record.type == 'youtube'"
            fieldFormName="youtube" fieldLabel="YouTube URL" :fieldValue="record.youtube" :forceMobile="true"
            @fieldInput="updateFormValue"
        />
        <select-panel v-if="record.type == 'youtube'"
            :fieldData="yesNoData" fieldFormName="autoplay" fieldLabel="Auto Play" :fieldValue="record.autoplay" :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <select-panel v-if="record.type == 'youtube'"
            :fieldData="yesNoData" fieldFormName="loop" fieldLabel="Loop" :fieldValue="record.loop" :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <select-panel  v-if="record.type == 'youtube'"
            :fieldData="orientationData" fieldFormName="orientation" fieldLabel="Orientation" :fieldValue="record.orientation" :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <media-panel v-if="record.type == 'image'"
            fieldLabel="Image" :hideSpinner="true" :imagesArray="imagesArray" :imagesOnly="true" :maxFiles="1" :multiple="false"
            @saveMedia="saveLocalMedia" @updateOfflineMedia="updateOfflineMedia"
        />
        <input-panel
            fieldFormName="url" fieldLabel="Link URL" :fieldValue="record.url" :forceMobile="true"
            @fieldInput="updateFormValue"
        />
        <div class="absolute right-2 top-2 flex items-center">
            <div @click="deleteRecord()" class="cursor-pointer pl-2 mr-1">
                <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
            </div>
            <div @click="$emit('close')" class="cursor-pointer pl-1">
                <fa-icon icon="circle-stop" type="far" class="h-5 w-5" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'propRecord'
    ],
    data() {
        return {
            record: null
        }
    },
    computed: {
        imagesArray() {
            let myGallery = []

            this.propRecord.gallery.forEach((myImage) => {
                myGallery.push(myImage)
            })

            return myGallery
        },
        orientationData() {
            return {
                Landscape: "landscape",
                Portrait: "portrait"
            }
        },
        typeData() {
            return {
                Image: "image",
                YouTube: "youtube"
            }
        }
    },
    mounted() {
        this.record = this.objCopy(this.propRecord)

        if (!this.record.gallery) {
            this.record.gallery = []
        }
    },
    methods: {
        async deleteRecord() {
            if (confirm("Really delete '" + this.record.textOne + "'?")) {
                
            }
        },
        saveLocalMedia(inGallery) {
            this.record.gallery = inGallery
            this.updateRecord()
        },
        updateFormValue(inField, inValue) {
            this.record[inField] = inValue
            this.updateRecord()
        },
        updateOfflineMedia(inGallery) {
            this.record.gallery = inGallery
            this.updateRecord()
        },
        updateRecord() {
            this.$emit('updateRecord', this.record)
        }
    }
}
</script>