const termsMixins = {
    methods: {
        closeTermsModal() {
            this.setStateData([
                { termsModalData: null },
                { showTermsModal: false }
            ])
        },
        openTermsModal(inIdentifier) {
            if (this.termsData && this.termsData[inIdentifier]) {
                this.setStateData([
                    { termsModalData: this.termsData[inIdentifier] },
                    { showTermsModal: true }
                ])
            }
        }
    }
}

export default termsMixins