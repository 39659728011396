<template>
    <panel-sub-section
        :sectionLabel="fieldLabel"
        @cacheSectionOpen="clickShowLegend"
    >
        <div v-if="formLoaded" class="white-transparent flex text-center items-center justify-center w-full text-black py-3">
            <ColorPicker
                :alpha-channel="fieldShowAlpha ? 'show' : 'hide'"
                :color="fieldModel"
                :visible-formats="['rgb', 'hex']"
                @color-change="fieldChange"
            />
        </div>
    </panel-sub-section>
</template>

<script>
import { ColorPicker } from 'vue-accessible-color-picker'

export default {
    props: [
        'fieldFormName', 
        'fieldFormSubObjectName', 
        'fieldLabel', 
        'fieldRequired', 
        'fieldShowAlpha',
        'fieldValue'
    ],
    components: {
        ColorPicker
    },
    data() {
        return {
            fieldModel: null,
            formLoaded: false
        }
    },
    mounted() {
        this.fieldModel = this.fieldValue
        this.formLoaded = true
    },
    methods: {
        fieldChange(eventData) {
            if (eventData.cssColor != this.fieldValue) {
                this.$emit('fieldChange', this.fieldFormName, eventData.cssColor, this.fieldFormSubObjectName)
            }
        }
    }
}
</script>