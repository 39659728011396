<template>
    <div v-show="imageId" class="h-full">
        <img v-if="deviceInfo && deviceInfo.platform != 'web'" @load="$emit('load')" :id="imageId" :class="imageClasses" />
        <img v-else @load="$emit('load')" :src="imageSrc" :class="imageClasses" />
    </div>
</template>

<script>
export default {
    props: ['imageSrc', 'imageClasses'],
    data() {
        return {
            imageId: null
        }
    },
    computed: {
        imageReady() {
            return Boolean(this?.imageSrc ?? false) && Boolean(this?.imageId ?? false)
        }
    },
    watch: {
        imageReady: {
            immediate: true,
            handler(val) {
                if (val) {
                    if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                        this.$nextTick(() => {
                            this.cacheImageMobile()
                        })
                    }
                }
            }
        }
    },
    mounted() {
        this.imageId = this.getRandomString(20)
    },
    methods: {
        async cacheImageMobile() {
            let myStoredImage = await this.getStorage(`img_${this.imageSrc}`)

            if (myStoredImage?.value) {
                this.elGet(this.imageId).src = myStoredImage.value
            }
            else {
                this.elGet(this.imageId).src = this.imageSrc
            }

            if (this.serverConnected) {
                this.toDataURL((dataUrl) => {
                    if (dataUrl) {
                        this.setStorage(`img_${this.imageSrc}`, dataUrl)
                        this.elGet(this.imageId).src = dataUrl
                    }
                })
            }
        },
        toDataURL(callback) {
            var xhr = new XMLHttpRequest()
            
            xhr.onload = function () {
                var reader = new FileReader()

                reader.onloadend = function () {
                    callback(reader.result)
                }

                reader.readAsDataURL(xhr.response)
            }

            xhr.open('GET', this.imageSrc)
            xhr.responseType = 'blob'
            xhr.send()
        }
    }
}
</script>