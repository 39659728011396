<template>
    <div v-if="record">
        <div v-if="!recordEditPanelOpen" class="flex items-stretch w-full" style="min-height: 5rem;">
            <div @click="clickEdit()" class="flex items-stretch w-full">
                <div class="py-1 handle-record cursor-move white-transparent px-2 flex items-center mr-px">
                    <fa-icon icon="up-down-left-right" type="fas" class="h-6 w-6" />
                </div>
                <div class="white-transparent p-1 w-full flex items-center cursor-pointer">
                    <div v-if="record.gallery && record.gallery.length" class="relative w-20 h-full flex items-center mr-1" style="min-width: 5rem; min-height: 5rem;">
                        <offline-media-card 
                            :element="record.gallery[0]" :filesCount="1" :hideDelete="true" :hideSpinner="true" :index="0"
                        />
                    </div>
                    <div class="text-left">
                        <div class="text-sm font-semibold leading-5 break-words line-clamp-2" v-html="record.textOne">
                        </div>
                        <div class="text-xs leading-5 break-words line-clamp-2" v-html="record.textTwo">
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex items-stretch justify-end">
                <div class="w-full white-transparent">
                    <div @click="clickDelete()" class="cursor-pointer p-2 flex items-center">
                        <fa-icon icon="trash-can" type="fas" class="h-6 w-6" />
                    </div>
                    <div @click="clickEdit()" class="cursor-pointer p-2 flex items-center">
                        <fa-icon icon="pen-to-square" type="fas" class="h-6 w-6" />
                    </div>
                </div>
            </div>
        </div>
        <feature-list-record-edit v-if="recordEditPanelOpen" 
            :propRecord="record"
            @close="closeEditRecord" @updateRecord="updateRecord"
        />
    </div>
</template>

<script>
import FeatureListRecordEdit from './FeatureListRecordEdit'
import OfflineMediaCard from '@/shared/Cards/OfflineMediaCard'

export default {
    props: [
        'editRecordId',
        'record'
    ],
    components: {
        FeatureListRecordEdit,
        OfflineMediaCard
    },
    data() {
        return {
            recordEditPanelOpen: false
        }
    },
    watch: {
        editRecordId: {
            immediate: true,
            handler(val) {
                if (this.editRecordId == this.record.id) {
                    this.recordEditPanelOpen = true
                }
                else {
                    this.recordEditPanelOpen = false
                }
            }
        }
    },
    methods: {
        clickDelete() {
            if (confirm("Confirm you wish to delete this line?")) {
                this.$emit('deleteRecord', this.record.id)
            }
        },
        clickEdit() {
            this.$emit('updateEditRecordId', this.record.id)
        },
        closeEditRecord() {
            this.recordEditPanelOpen = false
            this.$emit('updateEditRecordId', null)
        },
        updateRecord(inRecord) {
            this.$emit('updateRecord', inRecord)
        }
    }
}
</script>