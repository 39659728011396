const whiteLabelMixins = {
    computed: {
        whiteLabelDark() {
            if (this?.whiteLabelData?.theme ?? false) {
                return this.whiteLabelData.theme == 'dark'
            }

            return true
        },
        whiteLabelLight() {
            if (this?.whiteLabelData?.theme ?? false) {
                return this.whiteLabelData.theme == 'light'
            }

            return false
        } 
    },
    methods: {
        dollarsPercentageText(inGroupBuy, inStopBreaks = false) {
            if (inGroupBuy.white_label_data?.dollars_percentage_text ?? false) {
                return inGroupBuy.white_label_data.dollars_percentage_text.replaceAll(' ', inStopBreaks ? ' ' : '<br/>')
            }

            return this.defaultText.groupBuy.dollarsPercentageText
        },
        hideBuildCategory(inGroupBuy) {
            if (inGroupBuy.white_label_data?.hide_build_category ?? false) {
                return true
            }

            return false
        },
        hideInfoBar(inGroupBuy) {
            if (inGroupBuy.white_label_data?.hide_info_bar ?? false) {
                return true
            }

            return false
        },
        hideMinimumQuantity(inGroupBuy) {
            if (inGroupBuy.white_label_data?.hide_minimum_required ?? false) {
                return true
            }

            return false
        },
        hideMaximumAllowed(inGroupBuy) {
            if (inGroupBuy.white_label_data?.hide_maximum_allowed ?? false) {
                return true
            }

            return false
        },
        hideOfferConditions(inGroupBuy) {
            if (inGroupBuy.white_label_data?.hide_offer_conditions ?? false) {
                return true
            }

            return false
        },
        hideOfferConditionsOne(inGroupBuy) {
            if (inGroupBuy.white_label_data?.hide_offer_conditions_text_one ?? false) {
                return true
            }

            return false
        },
        hideOfferConditionsTwo(inGroupBuy) {
            if (inGroupBuy.white_label_data?.hide_offer_conditions_text_two ?? false) {
                return true
            }

            return false
        },
        hideOfferDescription(inGroupBuy) {
            if (inGroupBuy.white_label_data?.hide_offer_description ?? false) {
                return true
            }

            return false
        },
        hideGroupOfferDetailsText(inGroupBuy) {
            if (inGroupBuy.white_label_data?.hide_offer_details_text ?? false) {
                return inGroupBuy.white_label_data.hide_offer_details_text.replace('{labelSingular}', this.groupBuyType(inGroupBuy.type).labelSingular)
            }

            return this.defaultText.groupBuy.hideOfferDetailsText.replace('{labelSingular}', this.groupBuyType(inGroupBuy.type).labelSingular)
        },
        hideProductDescription(inGroupBuy) {
            if (inGroupBuy.white_label_data?.hide_products_description ?? false) {
                return true
            }

            return false
        },
        hideProductsTitle(inGroupBuy) {
            if (inGroupBuy.white_label_data?.hide_products_title_text ?? false) {
                return true
            }

            return false
        },
        hideShippingETA(inGroupBuy) {
            if (inGroupBuy.white_label_data?.hide_shipping_eta ?? false) {
                return true
            }

            return false
        },
        hideShippingLocation(inGroupBuy) {
            if (inGroupBuy.white_label_data?.hide_shipping_location ?? false) {
                return true
            }

            return false
        },
        hideTrackerBar(inGroupBuy) {
            if (inGroupBuy.white_label_data?.hide_tracker_bar ?? false) {
                return true
            }

            return false
        },
        hideUnitsConfirmed(inGroupBuy) {
            if (inGroupBuy.white_label_data?.hide_units_confirmed ?? false) {
                return true
            }

            return false
        },
        async initWhiteLabel() {
            let url = new URL(window.location.href)
            let myHost = url.host
            let myHostAr = myHost.split('.')
            let mySub = myHostAr[0]
            let myWhite = !myHost.includes('app.buildz.pro')

            if (myWhite) {
                // if (url.hostname == "localhost") {
                //     mySub = "glenn"
                //     myHost = "whitetest"
                // }

                if (myHost.includes('whitetest')) {
                    mySub = "glenn"
                }

                if (myHost.includes('.buildz.store') || myHost.includes('whitetest')) {
                    myHost = null
                }
                else if (!myHost.includes('whitetest')) {
                    mySub = null
                }

                if (mySub || myHost) {
                    this.setStateData([
                        { whiteLabel: true }
                    ])

                    let myStoredData = await this.getStorage(`white_label_data_${mySub}`)

                    if (myStoredData?.value) {
                        try {
                            this.setStateData([
                                { whiteLabelData: JSON.parse(myStoredData.value) }
                            ])
                        } 
                        catch (error) {
                        }
                    }

                    buildzAxios?.get(this.getRoute('whiteLabelData'), {
                        params: {
                            sub: mySub,
                            host: myHost
                        }
                    })
                        .then((response) => {
                            if (response.status == 200 && response.data?.white_label_data) {
                                this.setStateData([
                                    { whiteLabelData: this.objCopy(response.data.white_label_data) }
                                ])

                                this.setStorage(`white_label_data_${mySub}`, JSON.stringify(response.data.white_label_data))

                                if (this.whiteLabelData.favIcon) {
                                    var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
                                    link.type = 'image/x-icon'
                                    link.rel = 'shortcut icon'
                                    link.href = this.whiteLabelData.favIcon
                                    document.getElementsByTagName('head')[0].appendChild(link)
                                }
                            }
                            else {
                                this.setStateData([
                                    { whiteLabel: false }
                                ])
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            
                            this.setStateData([
                                { whiteLabel: false }
                            ])
                        })
                }
            }
        },
        joinGroupBuyButtonText(inGroupBuy) {
            if (inGroupBuy.white_label_data?.join_button_text ?? false) {
                return inGroupBuy.white_label_data.join_button_text.replace('{labelSingular}', this.groupBuyType(inGroupBuy.type).labelSingular)
            }

            return this.defaultText.groupBuy.joinButtonText.replace('{labelSingular}', this.groupBuyType(inGroupBuy.type).labelSingular)
        },
        maximumAllowedText(inGroupBuy, inStopBreaks) {
            if (inGroupBuy.white_label_data?.maximum_allowed_text ?? false) {
                return inGroupBuy.white_label_data.maximum_allowed_text.replaceAll(' ', inStopBreaks ? ' ' : '<br/>')
            }

            return this.defaultText.groupBuy.maximumAllowedText.replaceAll(' ', inStopBreaks ? ' ' : '<br/>')
        },
        minimumRequiredText(inGroupBuy, inStopBreaks = false) {
            if (inGroupBuy.white_label_data?.minimum_required_text ?? false) {
                return inGroupBuy.white_label_data.minimum_required_text.replaceAll(' ', inStopBreaks ? ' ' : '<br/>')
            }

            return this.defaultText.groupBuy.minimumRequiredText.replaceAll(' ', inStopBreaks ? ' ' : '<br/>')
        },
        offerConditionsTextLineOne(inGroupBuy) {
            if (inGroupBuy.white_label_data?.offer_conditions_text_one ?? false) {
                return inGroupBuy.white_label_data.offer_conditions_text_one.replace('{dateTime}', this.getLocalUnixTime(inGroupBuy.end_time)).replace('{labelSingular}', this.groupBuyType(inGroupBuy.type).labelSingular)
            }

            return this.defaultText.groupBuy.offerConditionsTextLineOne.replace('{dateTime}', this.getLocalUnixTime(inGroupBuy.end_time)).replace('{labelSingular}', this.groupBuyType(inGroupBuy.type).labelSingular)
        },
        offerConditionsTextLineTwo(inGroupBuy) {
            if (inGroupBuy.white_label_data?.offer_conditions_text_two ?? false) {
                return inGroupBuy.white_label_data.offer_conditions_text_two.replace('{dateTime}', this.getLocalUnixTime(inGroupBuy.end_time)).replace('{labelSingular}', this.groupBuyType(inGroupBuy.type).labelSingular)
            }

            return this.defaultText.groupBuy.offerConditionsTextLineTwo.replace('{dateTime}', this.getLocalUnixTime(inGroupBuy.end_time)).replace('{labelSingular}', this.groupBuyType(inGroupBuy.type).labelSingular)
        },
        productsTitleText(inGroupBuy) {
            if (inGroupBuy.white_label_data?.products_title_text ?? false) {
                return inGroupBuy.white_label_data.products_title_text.replace('{labelSingular}', this.groupBuyType(inGroupBuy.type).labelSingular)
            }

            return this.defaultText.groupBuy.productsTitleText.replace('{labelSingular}', this.groupBuyType(inGroupBuy.type).labelSingular)
        },
        registerEoiButtonText(inGroupBuy) {
            if (inGroupBuy.white_label_data?.register_button_text ?? false) {
                return inGroupBuy.white_label_data.register_button_text
            }

            return this.defaultText.groupBuy.registerButtonText
        },
        shareGroupBuyButtonText(inGroupBuy) {
            if (inGroupBuy.white_label_data?.share_button_text ?? false) {
                return inGroupBuy.white_label_data.share_button_text.replace('{labelSingular}', this.groupBuyType(inGroupBuy.type).labelSingular)
            }

            return this.defaultText.groupBuy.shareButtonText.replace('{labelSingular}', this.groupBuyType(inGroupBuy.type).labelSingular)
        },
        showGroupOfferDetailsText(inGroupBuy) {
            if (inGroupBuy.white_label_data?.show_offer_details_text ?? false) {
                return inGroupBuy.white_label_data.show_offer_details_text.replace('{labelSingular}', this.groupBuyType(inGroupBuy.type).labelSingular)
            }

            return this.defaultText.groupBuy.showOfferDetailsText.replace('{labelSingular}', this.groupBuyType(inGroupBuy.type).labelSingular)
        },
        unitsConfirmedText(inGroupBuy, inStopBreaks = false) {
            if (inGroupBuy.white_label_data?.units_confirmed_text ?? false) {
                return inGroupBuy.white_label_data.units_confirmed_text.replaceAll(' ', inStopBreaks ? ' ' : '<br/>')
            }

            return this.defaultText.groupBuy.unitsConfirmedText
        }
    }
}

export default whiteLabelMixins