<template>
    <div class="orange-transparent mb-px">
        <div class="w-full relative leading-6 py-2">
            <div class="w-full text-center font-semibold">
                Shipping Rates
            </div>
            <div v-if="!editingRateId && !editingZoneId" @click="clickNewZoneRate()" class="absolute top-2 right-2 font-normal cursor-pointer text-gray-600">
                <fa-icon icon="plus" type="fas" class="h-5 w-5" />
            </div>
        </div>
        <zone-rate v-for="(rate, index) in rates" 
            :defaultCurrency="defaultCurrency" :editingRateId="editingRateId" :editingRateZoneId="editingRateZoneId" :editingZoneId="editingZoneId" :rate="rate" 
            :showName="showName(rate, index)" :type="type" :zone="zone"
            @clickDeleteRate="clickDeleteRate" @clickEditRate="clickEditRate" 
        />
    </div>
</template>

<script>
import ZoneRate from './ZoneRate'

export default {
    props: [
        'defaultCurrency',
        'editingRateId',
        'editingRateZoneId',
        'editingZoneId',
        'rates',    
        'type',
        'zone'
    ],
    components: {
        ZoneRate
    },
    methods: {
        clickDeleteRate(inRate) {
            this.$emit('clickDeleteRate', inRate)
        },
        clickEditRate(inRate) {
            this.$emit('clickEditRate', inRate)
        },
        clickNewZoneRate() {
            this.$emit('clickNewZoneRate')
        },
        showName(inRate, inIndex) {
            if ((this.type != 'weight' && this.type != 'price') || inIndex == 0) {
                return true
            }

            if (this.rates[inIndex - 1].customRateName != inRate.customRateName) {
                return true
            }

            return false
        }
    }
}
</script>