<template>
    <modal v-if="showWriteActivity" :open="showWriteActivity" @close="clickClose" class="text-white">
        <modal-background-image
            :bgImage="defaultHero"
        />
        <div class="absolute top-0 left-0 w-full" :class="{ 'pb-16': windowWidth < desktopMaxWidth, 'desktop-modal-box': windowWidth >= desktopMaxWidth }">
            <div :class="{ 'desktop-modal': windowWidth >= desktopMaxWidth }">
                <div class="w-full">
                    <h3 v-if="activityToEdit" class="section-heading mb-3 mt-4 px-1 text-center">
                        Edit Your Post
                    </h3>
                    <div v-if="!activityToEdit && !activityType" class="text-sm font-bold leading-5 text-center w-full mb-2 mt-8 sm:mt-4">
                        Post to one or more Community
                    </div>
                    <div v-if="!activityToEdit && !activityType">
                        <div class="px-1">
                            <div class="round-tabs-container mb-4">
                                <div v-for="group in sortedGroups" @click="switchGroupSelected(group.id)" class="round-tab" :class="{ 'round-tab-off': !isGroupSelected(group.id), 'round-tab-on': isGroupSelected(group.id) }">
                                    {{ group.name }}
                                </div>
                                <div @click="clickGroupSearch()" class="round-tab round-tab-blue">
                                    Find More Communities
                                </div>
                            </div>
                            <div v-if="loggedUser && sortedBuilds && selectedGroups.length" class="text-sm font-bold leading-5 text-center w-full mb-2">
                                Link a Build
                            </div>
                            <div class="round-tabs-container mb-4">
                                <div v-for="build in sortedBuilds" v-if="loggedUser && sortedBuilds && selectedGroups.length" @click="switchBuildSelected(build.id)" class="round-tab" :class="{ 'round-tab-off': !isBuildSelected(build.id), 'round-tab-on': isBuildSelected(build.id) }">
                                    {{ build.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="selectedGroups.length || activityType || activityToEdit" class="px-1 mt-3">
                    <div class="relative" style="z-index: 1;">
                        <div class="text-sm font-bold leading-5 text-center">
                            Post
                        </div>
                        <mentions
                            :keys="['@', '+', '#']" offset="6" inputId="newActivityText"
                            @getLinks="getLinks" @apply="postBuildImage"
                        >
                            <textarea id="newActivityText" v-model="text" rows="4" class="w-full p-2 text-sm bg-white border border-gray-300 rounded focus:border-orange-500 focus:ring-orange-300 edit-field-text-color" placeholder="Type your post...">
                            </textarea>
                        </mentions>
                    </div>
                    <media-panel v-if="imagesArray"
                        fieldLabel="Media" :hideSpinner="true" :imagesArray="imagesArray" :imagesOnly="false" :maxFiles="10" :multiple="true"
                        @saveMedia="saveLocalGallery" @updateOfflineMedia="updateOfflineMedia"
                    />
                    <div v-if="gettingLinks" key="spinner" class="flex justify-center mt-4 text-orange-400">
                        <fa-icon icon="spinner" type="fas" class="spin h-5 w-5 mr-2" />
                        <span class="text-xs">
                            Looking for a link preview
                        </span>
                    </div>
                    <div class="relative mt-2 px-1" v-if="linkOgData">
                        <div class="text-sm font-bold leading-5 mb-1 text-center">
                            Link Preview
                        </div>
                        <div>
                            <a :href="linkOgData.url" target="_blank" class="flex items-start">
                                <div v-if="linkOgData.images && linkOgData.images.length && linkOgData.images[0].secure_url" class="mr-2 pt-1" style="width: 100px; min-width: 100px; max-width: 100px;">
                                    <img :src="linkOgData.images[0].secure_url" class="w-full h-auto" />
                                </div>
                                <div class="text-sm font-semibold" v-html="linkOgData.title">
                                </div>
                            </a>
                        </div>
                        <div @click="linkOgData = null; linkDataExists = false;" class="absolute top-0 right-0">
                            <fa-icon icon="xmark" type="fas" class="w-6 h-6" />
                        </div>
                    </div>
                </div>
                <div class="mt-6 sm:flex sm:flex-row-reverse px-1">
                    <div @click="clickPost" v-if="isGoodPost" class="standard-button-base mb-2">
                        {{ buttonLabel }}
                    </div>
                    <div @click="clickClose" class="alt-button-base sm:mr-2 mb-2">
                        Cancel
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import twitter from 'twitter-text'
import Mentions from '@/shared/ActivityFeed/Mentions'
import Modal from '@/shared/Modal'
import ModalBackgroundImage from '@/shared/ModalBackgroundImage'

export default {
    components: {
        Mentions,
        Modal,
        ModalBackgroundImage
    },
    data() {
        return {
            gallery: [],
            gettingLinks: false,
            imageStyle: "full",
            linkOgData: null,
            linkDataExists: false,
            mounted: false,
            previewLink: null,
            selectedBuilds: [],
            selectedGroups: [],
            text: ''
        }
    },
    computed: {
        buttonLabel() {
            return this.activityToEdit ? 'Update' : 'Create'
        },
        imagesArray() {
            let myGallery = []

            if (!this.gallery || !this.gallery.length) {
                return []
            }

            this.gallery.forEach((myImage) => {
                myGallery.push(myImage)
            })

            return myGallery
        },
        isGoodPost() {
            let isGood = true

            if (this.text == '' && !this.gallery.length) {
                isGood = false
            }

            if (!this.selectedGroups.length && !this.activityType && !this.activityToEdit) {
                isGood = false
            }

            return isGood
        },
        sortedBuilds() {
            if (!this.loggedUser.builds || !this.loggedUser.builds.length) {
                return null
            }

            return this.loggedUser.builds
                .sort((a, b) => a.name < b.name ? -1 : 1)
        },
        sortedGroups() {
            if (!this.loggedUser.groups || !this.loggedUser.groups.length) {
                return null
            }

            return this.loggedUser.groups
                .sort((a, b) => a.name < b.name ? -1 : 1)
        }
    },
    mounted() {
        if (this.shareIntentURL) {
            this.text = this.shareIntentURL
            this.getLinks([this.shareIntentURL])
            
            this.setStateData([
                { shareIntentURL: null }
            ])
        }

        this.setPostTo()

        if (this.activityToEdit) {
            if (this.activityToEdit.attachments && this.activityToEdit.attachments.images) {
                this.gallery = null

                setTimeout(() => {
                    this.gallery = this.activityToEdit.attachments.images
                }, 200)
            }

            if (this.activityToEdit.object) {
                this.text = this.activityToEdit.object
            }

            if (this.activityToEdit.meta.image_style) {
                this.imageStyle = this.activityToEdit.meta.image_style
            }
        }
        else if (this.newActivityShareURL) {
            this.text = this.newActivityShareURL
            this.getLinks([this.newActivityShareURL])
        }

        this.mounted = true
    },
    methods: {
        clearForm() {
            this.text = ''
            this.imageStyle = 'full'
            this.gallery = null
            this.linkOgData = null
            this.linkDataExists = false
            this.previewLink = null

            setTimeout(() => {
                this.gallery = []
            }, 500)
        },
        clickBuilds() {
            this.buildsOpen = !this.buildsOpen
        },
        clickClose() {
            if (this.activityToEdit) {
                this.clearForm()
            }

            this.closeWriteActivity()
        },
        clickGroupSearch() {
            if (confirm('You wish to close this window and search for communities?')) {
                this.closeWriteActivity()
                this.routeTo('/communities/search')
            }
        },
        async clickPost() {
            if (!this.postingActivity && (this.elGet('newActivityText').value || this.gallery.length)) {
                let feeds = {}
                let pattern = /\B\+[a-z0-9_-]+/gi
                
                feeds['builds'] = this.elGet('newActivityText').value.match(pattern)?.map(build => {
                    return build.substring(1)
                }) || []

                feeds['mentions'] = twitter?.extractMentions(this.elGet('newActivityText').value)
                feeds['hashtags'] = twitter?.extractHashtags(this.elGet('newActivityText').value)

                if (this.activityToEdit) {
                        this.setStateData([
                        { postingActivity: true }
                    ])

                    this.openSpinner()

                    if (this.gallery.length) {
                        let myTempGallery = await this.buildOfflineTempGallery(this.gallery, `activity_${this.getRandomString(20)}`)

                        let attachments = {
                            images: null,
                            og: this.linkOgData
                        }

                        let postToArray = []

                        if (this.activityType) {
                            postToArray = [this.activityType]
                        }

                        let myOtherData = {
                            text: this.elGet('newActivityText').value, 
                            attachments: attachments, 
                            feeds: feeds, 
                            imageStyle: this.imageStyle, 
                            recordId: this.activityToEdit.id, 
                            saleData: []
                        }

                        setTimeout(() => {
                            this.saveGalleryPrep(myTempGallery, 'activityUpdate', this.getRandomString(20), null, myOtherData)
                        }, 100)
                    }
                    else {
                        let attachments = {
                            images: this.gallery,
                            og: this.linkOgData
                        }

                        this.updateActivity(this.elGet('newActivityText').value, attachments, feeds, this.imageStyle, this.activityToEdit.id, [])
                    }
                }
                else {
                    this.setStateData([
                        { postingActivity: true }
                    ])

                    this.openSpinner()

                    if (this.gallery.length) {
                        let myTempGallery = await this.buildOfflineTempGallery(this.gallery, `activity_${this.getRandomString(20)}`)

                        let attachments = {
                            images: null,
                            og: this.linkOgData
                        }

                        let postToArray = []

                        if (this.activityType) {
                            postToArray = [this.activityType]
                        }

                        let myOtherData = {
                            text: this.elGet('newActivityText').value, 
                            attachments: attachments, 
                            feeds: feeds, 
                            imageStyle: this.imageStyle, 
                            recordId: this?.activityEntity?.id ?? 0, 
                            postTo: postToArray, 
                            buildIds: this.selectedBuilds, 
                            groupIds: this.selectedGroups, 
                            postType: 'post', 
                            saleData: [],
                            meta: []
                        }

                        setTimeout(() => {
                            this.saveGalleryPrep(myTempGallery, 'activityNew', this.getRandomString(20), null, myOtherData)
                        }, 100)
                    }
                    else {
                        let attachments = {
                            images: this.gallery,
                            og: this.linkOgData
                        }

                        let postToArray = []

                        if (this.activityType) {
                            postToArray = [this.activityType]
                        }
                        
                        this.newActivity(this.elGet('newActivityText').value, attachments, feeds, this.imageStyle, this?.activityEntity?.id ?? 0, postToArray, this.selectedBuilds, this.selectedGroups, 'post', [], () => this.mediaInProgress)
                    }
                }
            }
        },
        getLinks(inLinks) {
            if (!this.linkDataExists) {
                this.previewLink = inLinks && inLinks.length ? inLinks[0] : null

                if (this.previewLink && !this.activityToEdit) {
                    this.gettingLinks = true

                    buildzAxios?.post(this.getRoute('linkOg'), {
                        'url': this.previewLink
                    })
                    .then((response)=> {
                        if (response.data) {
                            let res = response.data.metadata
                            let resOG = response.data.metadata.og
                            let resGoogle = response.data.metadata.google
                            let myImages = resOG.images ? resOG.images : resGoogle.images
                            let myImageArray = []

                            if (myImages && myImages.length) {
                                myImageArray = [
                                    {
                                        secure_url: myImages[0]
                                    }
                                ]
                            }

                            let myOgData = {
                                description: resOG.description ? resOG.description : (res.description ? res.description : resGoogle.description),
                                title: resOG.title ? resOG.title : (res.title ? res.title : resGoogle.title),
                                images: myImageArray,
                                site_name: resOG.site_name,
                                type: 'website',
                                url: resOG.url,
                            }

                            if (myOgData.title || myOgData.description || myImageArray.length) {
                                this.linkOgData = myOgData
                                this.linkDataExists = true
                            }
                            else {
                                this.linkOgData = null
                                this.pageMessage('info', 'Link preview was not found')
                            }
                        }
                        else {
                            this.linkOgData = null
                            this.pageMessage('info', 'Link preview was not found')
                        }

                        this.gettingLinks = false
                    })
                    .catch((error)=> {
                        console.log(error)
                        this.linkOgData = null
                        this.gettingLinks = false
                        this.pageMessage('error', 'There was a problem retrieving the link preview')
                    })
                    .then(() => {
                        
                    })
                }
                else {
                    this.linkOgData = null
                }
            }
        },
        isBuildSelected(inBuildId) {
            return this.selectedBuilds.includes(inBuildId)
        },
        isGroupSelected(inGroupId) {
            return this.selectedGroups.includes(inGroupId)
        },
        postBuildImage(inItem, inKey) {
            if (inKey == "+" && inItem.avatar && inItem.avatar.includes('res.cloudinary.com')) {
                let myImage = {
                    url: inItem.avatar,
                    type: "image"
                }

                this.gallery?.push(myImage)
            }
        },
        saveLocalGallery(inGallery) {
            this.gallery = inGallery
        },
        setPostTo() {
            if (this.newActivityType && this.newActivityRecordId) {
                this.switchGroupSelected(this.newActivityRecordId)
            }
        },
        switchBuildSelected(inBuildId) {
            if (this.selectedBuilds.includes(inBuildId)) {
                this.selectedBuilds = []
            }
            else {
                this.selectedBuilds = []
                this.selectedBuilds.push(inBuildId)
            }
        },
        switchGroupSelected(inGroupId) {
            if (this.selectedGroups.includes(inGroupId)) {
                let myGroups = this.objCopy(this.selectedGroups)
                myGroups = myGroups.filter((group) => group != inGroupId)
                this.selectedGroups = this.objCopy(myGroups)
            }
            else {
                this.selectedGroups.push(inGroupId)
            }
        },
        updateOfflineMedia(inGallery) {
            this.gallery = inGallery
        }
    }
}
</script>