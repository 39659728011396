<template>
    <div class="w-full">
        <div class="w-full white-transparent my-px">
            <div class="flex flex-wrap justify-center items-center mx-2">
                <div class="flex items-center mx-2 my-2">
                    <input type="checkbox" v-model="exportForm.failed">
                    <div class="ml-1">Payment failed</div>
                </div>
                <div class="flex items-center mx-2 my-2">
                    <input type="checkbox" v-model="exportForm.pending">
                    <div class="ml-1">Payment pending</div>
                </div>
                <div class="flex items-center mx-2 my-2">
                    <input type="checkbox" v-model="exportForm.paid">
                    <div class="ml-1">Paid</div>
                </div>
                <div class="flex items-center mx-2 my-2">
                    <input type="checkbox" v-model="exportForm.cancelled">
                    <div class="ml-1">Cancelled</div>
                </div>
                <div class="flex items-center mx-2 my-2">
                    <input type="checkbox" v-model="exportForm.shipped">
                    <div class="ml-1">Shipped</div>
                </div>
                <div class="flex items-center mx-2 my-2">
                    <input type="checkbox" v-model="exportForm.delivered">
                    <div class="ml-1">Delivered</div>
                </div>
            </div>
        </div>
        <date-picker-panel
            fieldFormName="startTime" fieldLabel="Start Time" fieldPlaceholder="Leave Empty For First Sale" :fieldValue="exportForm.startTime" 
            @fieldChange="updateFormValue"
        />
        <date-picker-panel
            fieldFormName="endTime" fieldLabel="End Time" fieldPlaceholder="Leave Empty For Last Sale" :fieldValue="exportForm.endTime" 
            @fieldChange="updateFormValue"
        />
        <select-panel v-if="isGiveAway && groupBuy.processed"
            :fieldData="yesNoData" fieldFormName="ticket_data" fieldLabel="Show Ticket Data" :fieldValue="exportForm.ticket_data" :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <text-area-panel
            fieldFormName="ignoreEmails" fieldLabel="Test Emails To Ignore" fieldPlaceholder="Test Emails To Ignore" :fieldValue="exportForm.ignoreEmails"
            rows="3"
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldFormName="email" fieldLabel="Email Address" fieldPlaceholder="Email Address" :fieldRequired="true" fieldType="text" :fieldValue="exportForm.email"
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !exportForm.email" 
                message="Email Address must be entered"
            />
            <validation-message v-if="validating && exportForm.email && !isValidEmailAddress(exportForm.email)" 
                message="Email Address is not a valid email address format"
            />
        </input-panel>
        <div class="w-full white-transparent mt-px py-4 px-2" :class="{ 'desktop-button-box justify-center': isDesktopWidth }">
            <div @click="clickExport()" :class=" { 'standard-button-base': allGood, 'disabled-button-base': !allGood } ">
                Export
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'groupBuy',
        'isGiveAway'
    ],
    data() {
        return {
            exportForm: {
                cancelled: false,
                delivered: false,
                email: null,
                end_time: null,
                endTime: null,
                failed: false,
                groupBuyId: null,
                ignoreEmails: null,
                paid: true,
                pending: false,
                shipped: false,
                start_time: null,
                startTime: null,
                ticket_data: false
            },
            validating: false
        }
    },
    computed: {
        allGood() {
            return Boolean(this.exportForm.email) && this.isValidEmailAddress(this.exportForm.email)
        }
    },
    mounted() {
        this.exportForm.email = this.loggedUser.email
        this.exportForm.groupBuyId = this.groupBuy.id
    },
    methods: {
        clickExport() {
            if (!this.allGood) {
                this.validating = true
            }
            else {
                this.openSpinner()
                this.validating = false
                this.exportForm.start_time = Number.isInteger(this.dateTimeToUnixTime(this.exportForm.startTime)) ? this.dateTimeToUnixTime(this.exportForm.startTime) : this.exportForm.startTime / 1000
                this.exportForm.end_time = Number.isInteger(this.dateTimeToUnixTime(this.exportForm.endTime)) ? this.dateTimeToUnixTime(this.exportForm.endTime) : this.exportForm.endTime / 1000
                
                buildzAxios?.post(this.getRoute('sellerSalesExport'), this.exportForm)
                    .then((response) => {
                        alert(`Export job created, your export will be emailed to ${this.exportForm.email} on completion.`)
                        this.closeSpinner()
                    })
                    .catch((error) => {
                        alert('Error creating export job.')
                        this.closeSpinner()
                    })
            }
        },
        updateFormValue(inField, inValue) {
            this.exportForm[inField] = inValue
        }
    }
}
</script>