<template>
    <div class="flex justify-center flex-wrap justify-center mt-2 mb-4">
        <div v-for="x in count" class="round-tab flasher-light-bg flasher-light-animate" :style="{ width: getWidth() }">
            &nbsp;
        </div>
    </div>
</template>

<script>
export default {
    props: ['count'],
    methods: {
        getWidth() {
            let min = 80
            let max = 120

            return `${Math.floor(Math.random() * (max - min) + min)}px`
        }
    }
}
</script>