<template>
    <div v-if="sale" class="w-full flex items-start justify-between text-sm p-1 mb-1" :class="{ 'white-transparent': !whiteLabel || whiteLabelDark, 'black-transparent': whiteLabel && whiteLabelLight }">
        <div class="text-center">
            <div class="font-bold">
                Sale Date
            </div>
            <div>
                {{ getLocalUnixDate(sale.create_time) }}
            </div>
        </div>
        <div class="text-center mx-2">
            <div class="font-bold">
                Sale Number
            </div>
            <div>
                {{ `${vendor.id}-${sale.id}` }}
            </div>
        </div>
        <div class="text-center">
            <div class="font-bold">
                Sold To
            </div>
            <div>
                {{ customer.name }}
            </div>
        </div>
        <div v-if="showClose" @click="clickClose()" class="self-center mr-1">
            <fa-icon icon="eye-slash" type="fas" class="h-6 w-6 cursor-pointer" />
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'sale',
        'showClose'
    ],
    computed: {
        customer() {
            return this?.sale?.customer ?? null
        },
        vendor() {
            return this?.sale?.vendor ?? null
        }
    },
    methods: {
        clickClose() {
            this.$emit('closeSaleCard')
        }
    }
}
</script>