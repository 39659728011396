<template>
    <div class="w-full my-4 flex justify-between items-center px-1">
        <div v-for="x in count" class="flasher-icon-box flasher-dark-animate">
        </div>
    </div>
</template>

<script>
export default {
    props: ['count']
}
</script>