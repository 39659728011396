export default {
	methods: {
		showPrice(amount, currency) {
            if(currency == 'AUD') return `$${amount} AUD`
            if(currency == 'USD') return `$${amount}`
            if(currency == 'EUR') return `€${amount}`
			return `$${amount}`
        },
        stock(item) {
            let stock = item.inventory_management == null || item.inventory_available == true ? 'In Stock' : 'Out of Stock';
            return stock;
        },
	}
}