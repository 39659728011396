<template>
     <modal-container v-if="showEditCampaignModal && campaign"
        :openConditions="showEditCampaignModal"
        @closeModal="closeEditCampaignModal" @updateModalScrollTop="updateModalScrollTop"
    >
        <template #main>
            <div v-if="formLoaded" class="w-full mt-4 px-1">
                <h3 class="section-heading mb-3 text-center">
                    Edit {{ campaignType[campaign.type] }} Campaign
                </h3>
                <div class="max-width-box">
                    <div class="w-full mb-1">
                        <div class="w-full">
                            <input @input="updateName()" placeholder="Title" type="text" v-model="campaign.name" class="form-input edit-field-text-color bg-white py-1 w-full text-center" />
                        </div>
                        <validation-message v-if="!campaign.name" 
                            message="Title must be entered"
                        />
                    </div>
                    <div v-if="campaign.status == 'cancelled'">
                    </div>
                    <div v-if="campaign.status == 'sent'" class="w-full flex flex-wrap">
                        <div class="p-1" :class="{ 'w-1/2': !isDesktopWidth, 'w-1/3': isDesktopWidth }">
                            <div class="white-transparent p-2 flex flex-col items-center justify-center">
                                <div class="font-semibold">
                                    Sent On
                                </div>
                                <div>
                                    {{ getLocalUnixTime(campaign.sent_time) }}
                                </div>
                            </div>
                        </div>
                        <div class="p-1" :class="{ 'w-1/2': !isDesktopWidth, 'w-1/3': isDesktopWidth }">
                            <div class="white-transparent p-2 flex flex-col items-center justify-center">
                                <div class="font-semibold">
                                    {{ campaignType[campaign.type] }}'s Sent
                                </div>
                                <div>
                                    {{ campaign.sent_qty }}
                                </div>
                            </div>
                        </div>
                        <div class="p-1" :class="{ 'w-1/2': !isDesktopWidth, 'w-1/3': isDesktopWidth }">
                            <div class="white-transparent p-2 flex flex-col items-center justify-center">
                                <div class="font-semibold">
                                    Clicks
                                </div>
                                <div>
                                    {{ campaign.visit_qty }}
                                </div>
                            </div>
                        </div>
                        <div class="p-1" :class="{ 'w-1/2': !isDesktopWidth, 'w-1/3': isDesktopWidth }">
                            <div class="white-transparent p-2 flex flex-col items-center justify-center">
                                <div class="font-semibold">
                                    Unique Clicks
                                </div>
                                <div>
                                    {{ campaign.unique_visit_qty }}
                                </div>
                            </div>
                        </div>
                        <div class="p-1" :class="{ 'w-1/2': !isDesktopWidth, 'w-1/3': isDesktopWidth }">
                            <div class="white-transparent p-2 flex flex-col items-center justify-center">
                                <div class="font-semibold">
                                    Sales
                                </div>
                                <div>
                                    {{ campaign.sale_qty }}
                                </div>
                            </div>
                        </div>
                        <div class="p-1" :class="{ 'w-1/2': !isDesktopWidth, 'w-1/3': isDesktopWidth }">
                            <div class="white-transparent p-2 flex flex-col items-center justify-center">
                                <div class="font-semibold">
                                    Sales Revenue
                                </div>
                                <div>
                                    {{ displayPrice(sellerVendorData.defaultCurrency, campaign.sale_dollars) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <input-panel v-if="campaign.type == 'email'"
                        fieldFormName="subject" fieldLabel="Subject" :fieldNonEdit="campaign.status != 'draft'" fieldPlaceholder="Subject" :fieldRequired="true" fieldType="text" 
                        :fieldValue="campaignForm.subject"
                        @fieldInput="updateFormValue"
                    >
                        <validation-message v-if="!campaignForm.subject" 
                            message="Subject must be entered"
                        />
                    </input-panel>
                    <rich-text-panel v-if="campaign.type == 'email'"
                        fieldFormName="message" fieldLabel="Message" :fieldNonEdit="campaign.status != 'draft'" :fieldSubLabel="messageSubLabel" :fieldValue="messageValue" :toolbar="customToolbar"
                        @fieldInput="updateFormValue"
                    >
                        <validation-message v-if="!campaignForm.message" 
                            message="Message must be entered"
                        />
                    </rich-text-panel> 
                    <text-area-panel v-if="campaign.type == 'sms'"
                        fieldFormName="sms_message" fieldLabel="Message" :fieldNonEdit="campaign.status != 'draft'" fieldPlaceholder="Message" :fieldSubLabel="smsMessageSubLabel" :fieldValue="campaignForm.sms_message"
                        rows="3"
                        @fieldInput="updateFormValue"
                    />
                    <select-panel
                        :fieldData="dataSources" fieldDataLabel="label" fieldDataValue="value" fieldFormName="data_source" fieldLabel="Data Source" :fieldNonEdit="campaign.status != 'draft'" 
                        :fieldRequired="true" :fieldNonEditValue="displayValueDataSource" :fieldValue="campaignForm.data_source"
                        @fieldChange="updateFormSelectValue"
                    >
                        <validation-message v-if="!campaignForm.data_source" 
                            message="Data Source must be selected"
                        />
                    </select-panel>
                    <select-panel
                        :fieldData="sortedSegments" fieldDataLabel="label" fieldDataValue="id" fieldFormName="customer_segment" fieldLabel="Customer Segment" :fieldNonEdit="campaign.status != 'draft'" 
                        :fieldNonEditValue="displayValueCustomerSegment" :fieldValue="campaignForm.customer_segment"
                        @fieldChange="updateFormSelectValue"
                    />
                    <input-panel
                        fieldFormName="link_url" fieldLabel="Link URL" :fieldNonEdit="campaign.status != 'draft'" fieldPlaceholder="Link URL" :fieldRequired="true" fieldType="text" 
                        :fieldValue="campaignForm.link_url"
                        @fieldInput="updateFormValue"
                    >
                        <validation-message v-if="!campaignForm.link_url" 
                            message="Link URL must be entered"
                        />
                    </input-panel>
                    <input-panel 
                        fieldFormName="validity_days" fieldLabel="Link Click Attribution Days" :fieldNonEdit="campaign.status != 'draft'" fieldPlaceholder="Link Click Attribution Days" :fieldRequired="true" 
                        fieldSubLabel="This is the number of days after a link click where a sale will be attributed to this campaign" fieldType="number" :fieldValue="campaignForm.validity_days"
                        @fieldInput="updateFormValue"
                    >
                        <validation-message v-if="!campaignForm.validity_days" 
                            message="Attribution Days must be entered"
                        />
                        <validation-message v-if="campaignForm.validity_days && campaignForm.validity_days < 1" 
                            message="Attribution Days must be greater than zero"
                        />
                    </input-panel>
                    <!-- <div v-if="campaign.status == 'draft'" class="w-full">
                        <div class="w-full white-transparent mt-px p-2">
                            <div @click="clickCount()" class="w-full" :class="{ 'alt-button-base': allOkay, 'disabled-button-base': !allOkay }">
                                Get Audience Count
                            </div>
                            <div v-if="audienceCount != null" class="w-full text-center mt-2">
                                Total expected audience = {{ audienceCount }}
                            </div>
                        </div>
                    </div> -->
                    <div v-if="campaign.status == 'draft' && campaign.type == 'email'" class="w-full">
                        <input-panel 
                            fieldFormName="testEmail" fieldLabel="Send Test Email To" fieldPlaceholder="Send Test Email To" fieldType="text" :fieldValue="testEmail"
                            @fieldInput="updateTestEmail"
                        />
                        <div class="w-full white-transparent mt-px p-2">
                            <div @click="clickSendTest()" class="w-full " :class="{ 'alt-button-base': testEmail && isValidEmailAddress(testEmail), 'disabled-button-base': !isValidEmailAddress(testEmail) }">
                                Send Test
                            </div>
                        </div>
                    </div>
                    <div v-if="campaign.status == 'draft' && campaign.type == 'sms'" class="w-full">
                        <input-panel 
                            fieldFormName="testPhone" fieldLabel="Send Test SMS To" fieldPlaceholder="Send Test SMS To" fieldType="text" :fieldValue="testPhone"
                            @fieldInput="updateTestPhone"
                        />
                        <div class="w-full white-transparent mt-px p-2">
                            <div @click="clickSendTest()" class="w-full" :class="{ 'alt-button-base': testPhone, 'disabled-button-base': !testPhone }">
                                Send Test
                            </div>
                        </div>
                    </div>
                    <div v-if="campaign.status == 'draft'" class="w-full">
                        <div class="w-full white-transparent mt-px p-2">
                            <div @click="clickSend()" class="w-full" :class="{ 'standard-button-base': allOkay, 'disabled-button-base': !allOkay }">
                                Send
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </modal-container>
</template>

<script>
import {debounce} from 'lodash'

export default {
    data() {
        return {
            audienceCount: null,
            campaign: null,
            campaignForm: {
                customer_segment: null,
                data_source: null,
                link_text: null,
                link_url: null,
                message: null,
                name: null,
                sms_message: null,
                subject: null,
                validity_days: 7
            },
            customToolbar: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],  
                [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
                ['blockquote'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check'}],
                [{ 'indent': '-1'}, { 'indent': '+1' }],  
                [{ 'color': [] }, { 'background': [] }],    
                ['clean']                    
            ],
            dataSources: [
                {
                    label: "Marketing Customers",
                    value: "marketing"
                },
                {
                    label: "Pending Customers",
                    value: "pending"
                }
            ],
            formLoaded: false,
            modalScrollTop: null,
            testEmail: null,
            testPhone: null
        }
    },
    computed: {
        allOkay() {
            return Boolean(this.campaign.type != 'email' || this.campaignForm.message) && Boolean(this.campaign.type != 'sms' || this.campaignForm.sms_message) && Boolean(this.campaignForm.name) && (this.campaign.type != 'email' || Boolean(this.campaignForm.subject)) && Boolean(this.campaignForm.link_url) && Boolean(this.campaignForm.data_source)
        },
        customerSegment() {
            return this.campaignForm.customer_segment
        },
        dataSource() {
            return this.campaignForm.data_source
        },
        displayValueCustomerSegment() {
            if (!this.vendorCustomerSegments || !this.campaignForm.customer_segment) {    
                return null
            }
            
            return Object.values(this.vendorCustomerSegments).find((mySegment) => mySegment.id == this.campaignForm.customer_segment).label
        },
        displayValueDataSource() {
            if (!this.dataSources || !this.campaignForm.data_source) {    
                return null
            }
            
            return this.dataSources.find((mySource) => mySource.value == this.campaignForm.data_source).label
        },
        messageSubLabel() {
            return "Use {{name}} to add customer name. To add an image use {{image}}Image Full URL{{/image}}. If the image URL becomes a link click it and then click 'Remove' so that it becomes plain text or it will not display."
        },
        messageValue() {
            if (this.campaign.status == 'draft') {
                return this.campaignForm.message
            }

            let myMessage = this.campaignForm.message.replace("{{name}}", this.loggedUser.name).replace("{{image}}", "<img src=\"").replace("{{/image}}", "\" />")

            return myMessage
        },
        smsMessageSubLabel() {
            return "Use {{name}} to add customer name. Use {{link}} to insert the link."
        },
        sortedSegments() {
            return Object.values(this.vendorCustomerSegments)
                .sort((a, b) => a.label > b.label ? 1 : -1)
        }
    },
    watch: {
        customerSegment: {
            handler(val) {
                this.audienceCount = null
            }
        },
        dataSource: {
            handler(val) {
                this.audienceCount = null
            }
        }
    },
    mounted() {
        this.formLoaded = true
        this.testEmail = this?.sellerVendor?.email ?? null

        if ((this?.loggedUser?.shipping_address?.countryPrefix ?? false) && (this?.loggedUser?.shipping_address?.phoneNumber ?? false)) {
            this.testPhone = `${this.loggedUser.shipping_address.countryPrefix}${this.loggedUser.shipping_address.phoneNumber}`
        }

        this.getCampaign()
    },
    methods: {
        clickCount() {
            this.openSpinner()

            buildzAxios?.post(this.getRoute('sellerCampaignCount', this.campaign.id))
                .then((response) => {
                    this.audienceCount = response.data.count
                    this.closeSpinner()
                })
                .catch((error) => {
                    console.log(error)
                    this.closeSpinner()
                    this.pageMessage('error', 'Error getting count')
                })
        },
        clickSend() {
            if (this.allOkay) {
                if (confirm('You wish to send?')) {
                    this.sendCampaign()
                }
            }
        },
        clickSendTest() {
            if (this.campaign.type == "email" && this.testEmail && this.isValidEmailAddress(this.testEmail)) {
                if (confirm('You wish to send test email?')) {
                    this.sendTestMessage()
                }
            }
            else if (this.campaign.type == "sms" && this.testPhone) {
                if (confirm('You wish to send test SMS?')) {
                    this.sendTestMessage()
                }
            }
        },
        getCampaign() {
            buildzAxios?.get(this.getRoute('sellerCampaign', this.campaignToEdit.id))
                .then((response) => {
                    if (response.status == 200 && (response?.data?.campaign ?? false)) {
                        this.campaign = this.objCopy(response.data.campaign)
                        this.campaignForm.customer_segment = this.campaign.customer_segment
                        this.campaignForm.data_source = this.campaign.data_source
                        this.campaignForm.link_text = this.campaign.link_text
                        this.campaignForm.link_url = this.campaign.link_url
                        this.campaignForm.message = this.campaign.message
                        this.campaignForm.name = this.campaign.name
                        this.campaignForm.sms_message = this.campaign.sms_message
                        this.campaignForm.subject = this.campaign.subject
                        this.campaignForm.validity_days = this.campaign.validity_days
                        this.formLoaded = true
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        save() {
            if (this.formLoaded) {
                this.saveCampaign(this.campaign.id, this.campaignForm)
            }
        },
        saveDebounce: debounce(function() {
            this.save()
        }, 2000),
        sendCampaign() {
            this.openSpinner()

            buildzAxios?.post(this.getRoute('sellerCampaignSend', this.campaign.id))
                .then((response) => {
                    let myRecord = Object.entries(this.vendorCampaigns).find((entry) => entry[1].id == this.campaign.id)
                    let myRecords = this.objCopy(this.vendorCampaigns)
                    myRecords[myRecord[0]] = this.objCopy(response.data.campaign)

                    this.setStateData([
                        { vendorCampaigns: myRecords }
                    ])

                    this.campaign = response.data.campaign
                    this.closeSpinner()
                })
                .catch((error) => {
                    console.log(error)
                    this.closeSpinner()
                    this.pageMessage('error', 'Error sending Campaign')
                })
        },
        sendTestMessage() {
            this.openSpinner()

            buildzAxios?.post(this.getRoute('sellerCampaignTest', this.campaign.id), {
                email: this.testEmail,
                phone: this.testPhone
            })
                .then((response) => {
                    this.closeSpinner()
                })
                .catch((error) => {
                    console.log(error)
                    this.closeSpinner()
                    this.pageMessage('error', 'Error sending Test')
                })
        },
        updateFormSelectValue(inField, inValue) {
            this.campaignForm[inField] = inValue
            this.save()
        },
        updateFormValue(inField, inValue) {
            this.campaignForm[inField] = inValue
            this.saveDebounce()
        },
        updateModalScrollTop(inScrollTop) {
            this.modalScrollTop = inScrollTop
        },
        updateName() {
            this.campaignForm.name = this.campaign.name
            this.saveDebounce()
        },
        updateTestEmail(inField, inValue) {
            this.testEmail = inValue
        },
        updateTestPhone(inField, inValue) {
            this.testPhone = inValue
        }
    }
}
</script>