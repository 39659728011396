import axios from "axios"

const mediaMixins = {
    computed: {
        allowedImageTypes() {
            return ['image/png', 'image/gif', 'image/jpeg', 'image/webp', 'image/tiff']
        },
        allowedImageTypesString() {
            return this.allowedImageTypes.join()
        },
        allowedMediaTypes() {
            return ['image/png', 'image/gif', 'image/jpeg', 'image/webp', 'image/tiff', 'video/mov', 'video/mp4', 'video/avi', 'video/mpeg', 'video/mkv', 'video/ogv', 'video/webm', 'video/wmv', 'video/3gpp', 'video/3gpp2', 'video/x-m4v', 'video/quicktime']
        },
        allowedMediaTypesString() {
            return this.allowedMediaTypes.join()
        }
    },
    methods: {
        async buildOfflinePostGallery(inGallery) {
            return new Promise(async (resolve, reject) => {
                let myOfflineMedia = null

                if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                    myOfflineMedia = await this.getStorage('offlineMediaFiles')
                    
                    if (myOfflineMedia?.value) {
                        myOfflineMedia = JSON.parse(myOfflineMedia.value)
                    }
                    else {
                        myOfflineMedia = []
                    }
                }
                else {
                    myOfflineMedia = this.offlineMediaFiles

                    if (myOfflineMedia) {
                        myOfflineMedia = JSON.parse(myOfflineMedia)
                    }
                    else {
                        myOfflineMedia = []
                    }
                }
                
                let myPostGallery = []
                let myQueueIds = []   
                

                for (const galleryItem of inGallery) {
                    let myOfflineFile = myOfflineMedia.filter((mediaFile) => mediaFile.url == galleryItem.url)

                    if (myOfflineFile.length) {
                        myOfflineFile = myOfflineFile[0]
                        let myQueueId = await this.addToQueue('updateSingleImage', myOfflineFile)
                        myQueueIds.push(myQueueId)

                        myPostGallery.push({
                            queueId: myQueueId
                        })
                    }
                    else {
                        myPostGallery.push(galleryItem)
                    }
                }

                resolve({
                    gallery: myPostGallery,
                    queueIds: myQueueIds
                })
            })
        },
        async buildOfflineTempGallery(inGallery, inTempNameString) {
            let loopPromise = new Promise(async (resolve, reject) => {
                let myTempGallery = []
                
                inGallery.forEach(async (galleryItem, index) => {
                    let myOfflineMedia = null

                    if ('File' in window && galleryItem instanceof File) {
                        let readerPromise = new Promise((resolve, reject) => {
                            const reader = new FileReader()
                            reader.readAsDataURL(galleryItem)
                            reader.onload = () => resolve(reader.result)
                            reader.onerror = error => reject(error)
                        })

                        let myFile = null

                        await readerPromise
                            .then((result) => {
                                myFile = result
                            })
                            .catch((e) => {

                            })

                        if (myFile) {
                            this.setStorage(`img_${inTempNameString}_${index}`, myFile)
                            let myUrl = `${inTempNameString}_${index}`

                            myTempGallery.push(
                                {
                                    url: myUrl,
                                    type: galleryItem.type.split('/')[0],
                                    identifier: galleryItem?.identifier ?? null
                                }
                            )

                            if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                                myOfflineMedia = await this.getStorage('offlineMediaFiles')
                                
                                if (myOfflineMedia?.value) {
                                    myOfflineMedia = JSON.parse(myOfflineMedia.value)
                                }
                                else {
                                    myOfflineMedia = []
                                }
                            }
                            else {
                                myOfflineMedia = this.offlineMediaFiles

                                console.log('offline', JSON.parse(myOfflineMedia))

                                if (myOfflineMedia) {
                                    myOfflineMedia = JSON.parse(myOfflineMedia)
                                }
                                else {
                                    myOfflineMedia = []
                                }
                            }

                            myOfflineMedia = myOfflineMedia.filter((mediaItem) => mediaItem.url != myUrl)

                            myOfflineMedia.push({
                                url: myUrl,
                                file: {
                                    imageName: galleryItem.name,
                                    imageType: galleryItem.type,
                                    image: myFile
                                },
                                identifier: galleryItem?.identifier ?? null
                            })

                            if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                                this.setStorage('offlineMediaFiles', JSON.stringify(myOfflineMedia))
                            }
                            else {
                                this.setStateData([
                                    { offlineMediaFiles: JSON.stringify(myOfflineMedia) }
                                ])
                            }
                        }
                    }
                    else {
                        myTempGallery.push(galleryItem)
                    }

                    if (index === inGallery.length - 1) {
                        resolve({
                            gallery: myTempGallery, 
                            offlineMedia: myOfflineMedia
                        })
                    }
                })
            })

            let myReturn = null

            await loopPromise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })

            // if (this.deviceInfo && this.deviceInfo.platform != 'web') {
            //     this.setStorage('offlineMediaFiles', JSON.stringify(myReturn.offlineMedia))
            // }
            // else {
            //     this.setStateData([
            //         { offlineMediaFiles: JSON.stringify(myReturn.offlineMedia) }
            //     ])
            // }

            return myReturn.gallery
        },
        mediaProgressStart() {
            if(!this.saved && !this.saving){
                this.setStateData([
                    { mediaProgressTotal: this.mediaProgressTotal + 1 }
                ])
            }
            window.mediaInProgress = true
            
            this.setStateData([
                { mediaInProgress: true }
            ])
        },
        mediaProgressDone() {
            this.setStateData([
                { mediaProgress: this.mediaProgress + 1 }
            ])

            setTimeout(() => {
                if(this.mediaProgress >= this.mediaProgressTotal){
                    this.setStateData([
                        { mediaProgressTotal: 0 },
                        { mediaProgress: 0 }
                    ])
                }
            }, 5000)

            if (this.mediaProgress >= this.mediaProgressTotal){
                window.mediaInProgress = false
                
                this.setStateData([
                    { mediaInProgress: false }
                ])
            }
        },
        uploadImage(inFile, inQueueId, inIdentifier) {
            let file = inFile

            if (file?.size > 45000000) {
                let uniqueUploadId = +new Date()
                let fileSize = file.size
                let chunk = null
                let chunkStart = 0
                let chunkEnd = 0
                let formEnd = 0
                let sliceSize = 6000000
                let chunksUploaded = 0

                const url = `https://api.cloudinary.com/v1_1/${this.cloudinaryName}/upload`

                do {
                    chunkEnd = chunkStart + sliceSize

                    if (chunkEnd > fileSize) {
                        chunkEnd = fileSize
                    }

                    formEnd = chunkEnd - 1

                    let slice = file.mozSlice
                        ? file.mozSlice
                        : file.webkitSlice
                            ? file.webkitSlice
                            : file.slice
                                ? file.slice
                                : noop

                    chunk = slice.bind(file)(chunkStart, chunkEnd)

                    let formData = new FormData()
                    formData.append('upload_preset', this.cloudinaryPreset)
                    formData.append('tags', 'browser_upload')
                    formData.append('file', chunk)

                    let xhr = new XMLHttpRequest()
                    xhr.open('POST', url, true)

                    xhr.setRequestHeader("X-Unique-Upload-Id", uniqueUploadId)

                    xhr.setRequestHeader(
                        "Content-Range",
                        `bytes ${chunkStart}-${formEnd}/${fileSize}`
                    )

                    xhr.onload = ((res) => {
                        if (JSON.parse(xhr.responseText)?.done) {
                            // progress(true, fileSize, fileSize);
                            const response = JSON.parse(xhr.responseText)
                            set(window, 'media.' + response.secure_url, response)
                            
                            let myImages = this.uploadImages

                            let strippedFile = {
                                'bytes': response.bytes,
                                'format': response.format,
                                'height': response.height,
                                'width': response.width,
                                'public_id': response.public_id,
                                'type': response.resource_type,
                                'url': response.secure_url,
                                'tags': response.tags,
                                'id': response.asset_id,
                                'source': response.secure_url,
                                'options': {
                                    type: 'local'
                                },
                                'identifier': inIdentifier
                            }
                            
                            myImages.push({
                                queueId: inQueueId,
                                image: strippedFile
                            })

                            this.replaceStateDataArray([
                                { uploadImages: this.objCopy(myImages) }
                            ])

                            this.stopQueue(inQueueId, true)

                            // load(response.secure_url)
                            return
                        }
                        else {
                            chunksUploaded++
                            // progress(true, chunksUploaded * sliceSize, fileSize)
                        }
                    })

                    xhr.onerror = ((error) => {
                        console.log(error)
                    })

                    xhr.send(formData)

                    if (chunkEnd < fileSize) {
                        chunkStart += sliceSize
                    }
                }
                while (chunkEnd < fileSize)
            }
            else {
                const url = `https://api.cloudinary.com/v1_1/${this.cloudinaryName}/upload`
                const xhr = new XMLHttpRequest()
                const formData = new FormData()

                xhr.open('POST', url, true)
                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

                // xhr.upload.addEventListener('progress', e => {
                //     progress(e.lengthComputable, e.loaded, e.total)
                // })

                xhr.onreadystatechange = e => {
                    if (xhr.readyState !== 4) {
                        return
                    }

                    if (xhr.status >= 200 && xhr.status < 300) {
                        const response = JSON.parse(xhr.responseText)
                        // set(window, 'media.' + response.secure_url, response)

                        let myImages = this.uploadImages

                        let strippedFile = {
                            'bytes': response.bytes,
                            'format': response.format,
                            'height': response.height,
                            'width': response.width,
                            'public_id': response.public_id,
                            'type': response.resource_type,
                            'url': response.secure_url,
                            'tags': response.tags,
                            'id': response.asset_id,
                            'source': response.secure_url,
                            'options': {
                                type: 'local'
                            },
                            'identifier': inIdentifier
                        }

                        myImages.push({
                            queueId: inQueueId,
                            image: strippedFile
                        })

                        this.replaceStateDataArray([
                            { uploadImages: this.objCopy(myImages) }
                        ])

                        this.stopQueue(inQueueId, true)
                        // load(response.secure_url)
                        return
                    }
                    
                    console.log('upload image error')

                    // error('oh no!')
                }

                formData.append('upload_preset', this.cloudinaryPreset)
                formData.append('tags', 'browser_upload')
                formData.append('file', file)
                xhr.send(formData)

                return {
                    abort: () => {
                        xhr.abort()
                    }
                }
            }
        }
    }
}

export default mediaMixins