<template>
    <div v-if="formLoaded" class="w-full">
        <div class="w-full">
            <div v-if="isGiveAway && !editingLockedDetails && !pGroupBuyForm.disabled && this.pGroupBuy.end_time > this.currentUnixTime()" class="white-transparent mb-px p-2">
                <div @click="clickEditLockedDetails()" class="standard-button-base">
                    Edit Locked Details
                </div>
            </div>
            <date-picker-panel
                :fieldDisplayValue="groupBuyForm.start_time" fieldFormName="start_time" fieldLabel="Group Offer Start Time" 
                :fieldNonEdit="!pGroupBuyForm.disabled && !editingLockedDetails" :fieldRequired="true" :fieldValue="dates.start_time" 
                @fieldChange="updateFormDateValue"
            >
                <validation-message v-if="!dates.start_time" 
                    message="Group Offer Start Time must be selected"
                />
                <validation-message v-if="pGroupBuyForm.has_eoi && dates.start_time && pEoiEndTime && dates.start_time < pEoiEndTime" 
                    :message="`Group Offer Start Time must be after E.O.I. End Time (${getGroupBuyTime(pGroupBuy.eoi_end_time)})`"
                />
            </date-picker-panel>
            <date-picker-panel v-if="!isMemberGiveAway"
                :fieldDisplayValue="groupBuyForm.end_time" fieldFormName="end_time" fieldLabel="Group Offer End Time" 
                :fieldNonEdit="!pGroupBuyForm.disabled && !editingLockedDetails" :fieldRequired="true" :fieldValue="dates.end_time" 
                @fieldChange="updateFormDateValue"
            >
                <validation-message v-if="!dates.end_time" 
                    message="Group Offer End Time must be selected"
                />
                <validation-message v-if="dates.start_time && dates.end_time && dates.end_time < dates.start_time" 
                    message="Group Offer End time must be after Group Offer Start Time"
                />
            </date-picker-panel>
            <select-panel v-if="!isMemberGiveAway"
                :fieldData="noYesData" fieldFormName="no_maximum" fieldLabel="Group Offer Has Maximum Quantity" :fieldNonEdit="!pGroupBuyForm.disabled && !editingLockedDetails" 
                :fieldNonEditValue="displayValueNoMaximum" :fieldValue="groupBuyForm.no_maximum" :isLabelAndValue="true"
                @fieldChange="updateFormSelectValue"
            />
            <input-panel v-if="!groupBuyForm.no_maximum && !isMemberGiveAway"
                fieldFormName="max_quantity" fieldLabel="Group Offer Maximum Quantity" fieldPlaceholder="Group Offer Maximum Quantity" :fieldNonEdit="!pGroupBuyForm.disabled && !editingLockedDetails" :fieldRequired="true" 
                fieldType="number" :fieldValue="groupBuyForm.max_quantity" hideFieldFormName="hide_maximum_allowed" :hideFieldValue="groupBuyForm.hide_maximum_allowed" :hideIcon="true"
                @fieldInput="updateFormValue"
            >
                <validation-message v-if="groupBuyForm.max_quantity < 1" 
                    message="Group Offer Maximum Quantity must be greater than zero"
                />
                <validation-message v-if="!pMaximumOkay && pSortedPricingLevels && pSortedPricingLevels.length" 
                    :message="`Group Offer Maximum Quantity must be greater than or equal to your largest breakpoint (${pSortedPricingLevels[pSortedPricingLevels.length - 1].qty})`"
                />
            </input-panel>
            <select-panel v-if="isGiveAway"
                :fieldData="noYesData" fieldFormName="no_maximum_dollars" fieldLabel="Group Offer Has Maximum Dollars" :fieldNonEdit="!pGroupBuyForm.disabled && !editingLockedDetails"
                :fieldNonEditValue="displayValueNoMaximumDollars" :fieldValue="groupBuyForm.no_maximum_dollars" :isLabelAndValue="true"
                @fieldChange="updateFormSelectValue"
            />
            <input-panel v-if="!groupBuyForm.no_maximum_dollars && isGiveAway"
                fieldFormName="max_dollars" fieldLabel="Group Offer Maximum Dollars" fieldPlaceholder="Group Offer Maximum Dollars" :fieldNonEdit="!pGroupBuyForm.disabled && !editingLockedDetails" :fieldRequired="true" 
                fieldType="number" :fieldValue="groupBuyForm.max_dollars" hideFieldFormName="hide_maximum_allowed" :hideFieldValue="groupBuyForm.hide_maximum_allowed" :hideIcon="true"
                @fieldInput="updateFormValue"
            >
                <validation-message v-if="groupBuyForm.max_quantity < 1" 
                    message="Group Offer Maximum Dollars must be greater than zero"
                />
            </input-panel>
            <input-panel v-if="!isMemberGiveAway"
                fieldFormName="initial_units_confirmed" fieldLabel="Initial Units Confirmed Quantity" fieldPlaceholder="Initial Units Confirmed Quantity" :fieldNonEdit="!pGroupBuyForm.disabled && !editingLockedDetails" :fieldRequired="false" 
                fieldType="number" :fieldValue="groupBuyForm.initial_units_confirmed" 
                @fieldInput="updateFormValue"
            >
                <validation-message v-if="!groupBuyForm.no_maximum && groupBuyForm.initial_units_confirmed >= groupBuyForm.max_quantity" 
                    message="Initial Units Confirmed must be less than your Maximum Quantity"
                />
            </input-panel>
            
        </div>
        <give-away-tc-modal v-if="showGiveAwayTcModal"
            :showGiveAwayTcModal="showGiveAwayTcModal"
            @closeShowGiveAwayTcModal="closeShowGiveAwayTcModal" @editTermsAgreed="editTermsAgreed"
        />
    </div>
</template>

<script>
import GiveAwayTcModal from '../modals/GiveAwayTcModal'

export default {
    props: [
        'isGiveAway',
        'isGroupBuy',
        'isMemberGiveAway',
        'pEndTime', 
        'pEoiEndTime', 
        'pFormLoaded',
        'pGotGroupBuy', 
        'pGroupBuy', 
        'pGroupBuyForm', 
        'pMaximumOkay',
        'pQuantityLevels', 
        'pSortedPricingLevels', 
        'pStartTime'
    ],
    components: {
        GiveAwayTcModal
    },
    data() {
        return {
            dates: {
                end_time: null,
                start_time: null
            },
            editingLockedDetails: false,
            formLoaded: false,
            groupBuyForm: {
                dollars_graphic: false,
                dollars_tracker: false,
                end_time: null,
                hide_maximum_allowed: false,
                hide_minimum_required: false,
                initial_units_confirmed: null,
                max_dollars: null,
                max_quantity: null,
                no_maximum_dollars: true,
                no_maximum: false,
                start_time: null
            },
            showGiveAwayTcModal: false
        }
    },
    computed: {
        displayValueNoMaximum() {
            return this.groupBuyForm.no_maximum ? "No" : "Yes"
        },
        displayValueNoMaximumDollars() {
            return this.groupBuyForm.no_maximum_dollars ? "No" : "Yes"
        },
        endTime() {
            return this.formLoaded ? this.dates.end_time : null
        },
        startTime() {
            return this.formLoaded ? this.dates.start_time : null
        }
    },
    mounted() {
        this.dates.end_time = this.pEndTime
        this.dates.start_time = this.pStartTime
        this.groupBuyForm.dollars_graphic = this.pGroupBuyForm.dollars_graphic
        this.groupBuyForm.dollars_tracker = this.pGroupBuyForm.dollars_tracker
        this.groupBuyForm.end_time = this.pGroupBuyForm.end_time
        this.groupBuyForm.hide_maximum_allowed = this.pGroupBuyForm.hide_maximum_allowed
        this.groupBuyForm.initial_units_confirmed = this.pGroupBuyForm.initial_units_confirmed
        this.groupBuyForm.max_dollars = this.pGroupBuyForm.max_dollars != null ? this.pGroupBuyForm.max_dollars : 0
        this.groupBuyForm.max_quantity = this.pGroupBuyForm.max_quantity != null ? this.pGroupBuyForm.max_quantity : 1
        this.groupBuyForm.no_maximum = this.pGroupBuyForm.no_maximum
        this.groupBuyForm.no_maximum_dollars = this.pGroupBuyForm.no_maximum_dollars
        this.groupBuyForm.start_time = this.pGroupBuyForm.start_time
        this.formLoaded = true
    },
    methods: {
        clickEditLockedDetails() {
            this.showGiveAwayTcModal = true
        },
        closeShowGiveAwayTcModal() {
            this.showGiveAwayTcModal = false
        },
        editTermsAgreed() {
            this.showGiveAwayTcModal = false
            this.editingLockedDetails = true
        },
        updateFormDateValue(inField, inValue) {
            let originalValue = Number.isInteger(this.dateTimeToUnixTime(this.dates[inField])) ? this.dateTimeToUnixTime(this.dates[inField]) : this.dates[inField] / 1000
            let newValue = Number.isInteger(this.dateTimeToUnixTime(inValue)) ? this.dateTimeToUnixTime(inValue) : inValue / 1000

            if (originalValue != newValue) {
                this.dates[inField] = inValue
                this.groupBuyForm[inField] = inValue
                this.$emit('updateGroupBuy', this.groupBuyForm, true, this.dates.end_time, this.dates.start_time)
            }
        },
        updateFormSelectValue(inField, inValue) {
            if (this.groupBuyForm[inField] != inValue) {
                this.groupBuyForm[inField] = inValue

                if (this.isGiveAway) {
                    if (!this.groupBuyForm.no_maximum && this.groupBuyForm.no_maximum_dollars) {
                        this.groupBuyForm.dollars_graphic = false
                    }
                    else if (this.groupBuyForm.no_maximum && !this.groupBuyForm.no_maximum_dollars) {
                        this.groupBuyForm.dollars_graphic = true
                    }

                    if (this.groupBuyForm.no_maximum_dollars) {
                        this.groupBuyForm.dollars_tracker = false
                    }
                }

                this.$emit('updateGroupBuy', this.groupBuyForm, true, this.dates.end_time, this.dates.start_time)
            }
        },
        updateFormValue(inField, inValue) {
            if (this.groupBuyForm[inField] != inValue) {
                this.groupBuyForm[inField] = inValue
                this.$emit('updateGroupBuy', this.groupBuyForm, false, this.dates.end_time, this.dates.start_time)
            }
        }
    }
}
</script>