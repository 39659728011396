<template>
    <div class="w-full h-full" :class="{'rounded-full': rounded, [containerClass]: containerClass, 'relative': !notRelative}">
        <div v-if="!hideSvg" class="w-full h-full text-gray-400 bg-gray-200" :class="{'rounded-full': rounded, [svgClass]: svgClass}">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 24 24" class="w-full h-full text-gray-400 bg-gray-200" :class="{'rounded-full': rounded, [svgClass]: svgClass}">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
        </div>
        <div v-if="url" class="absolute left-0 top-0 overflow-hidden" :class="{'rounded-full': rounded, 'w-full h-full bg-white': showImage, 'h-0 w-0': !showImage}">
            <cached-image @load="srcLoaded = true" :imageClasses="{'rounded-full': rounded, [imgClass]: imgClass, 'w-full h-full object-cover': showImage}" :imageSrc="transformUrl(url, ['optimize', imageSize, 'q80'])" alt="User Avatar" loading="lazy" />
        </div>
    </div>
</template>

<script>
export default {
    props: ['url', 'rounded', 'condition', 'containerClass', 'imgClass', 'svgClass', 'size', 'notRelative', 'hideSvg'],
    data() {
        return {
            srcLoaded: false,
            showImage: false
        }
    },
    computed: {
        imageSize() {
            return this.size || 'sm'
        }
    },
    watch: {
        srcLoaded: {
            handler(val) {
                if (val && this.url && (this.condition === null || this.condition === undefined || this.condition) && this.url) {
                    this.showImage = true
                }
            }
        }
    }
}
</script>