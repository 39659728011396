<template>
    <div v-if="formLoaded" class="w-full">
        <input-panel 
            fieldFormName="code" fieldLabel="Code" fieldPlaceholder="Code" 
            :fieldRequired="true" fieldType="text" :fieldValue="codeForm.code"
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(codeForm.code)" 
                message="Code must be entered"
            />
            <validation-message v-if="validating && duplicateCode && duplicateCode == codeForm.code" 
                message="Duplicate code"
            />
        </input-panel>
        <input-panel 
            fieldFormName="label" fieldLabel="Label" fieldPlaceholder="Label" 
            :fieldRequired="true" fieldType="text" :fieldValue="codeForm.label"
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(codeForm.label)" 
                message="Label must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="percentage" fieldLabel="Discount Percentage" fieldPlaceholder="Discount Percentage" 
            :fieldRequired="true" fieldType="number" :fieldValue="codeForm.percentage"
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(codeForm.percentage)" 
                message="Discount Percentage must be entered"
            />
            <validation-message v-else-if="validating && Boolean(codeForm.percentage) && codeForm.percentage < 0" 
                message="Discount Percentage must be greater than zero"
            />
            <validation-message v-else-if="validating && Boolean(codeForm.percentage) && codeForm.percentage > 100" 
                message="Discount Percentage cant be greater than 100"
            />
        </input-panel>
        <select-panel 
            :fieldData="yesNoData" fieldFormName="multiple" fieldLabel="Can Be Used With Other Offers?"
            :fieldValue="codeForm.multiple" :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <select-panel 
            :fieldData="yesNoData" fieldFormName="enabled" fieldLabel="Enabled"
            :fieldValue="codeForm.enabled" :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <div class="w-full white-transparent mt-px py-4 px-2" :class="{ 'desktop-button-box justify-center': isDesktopWidth }">
            <div @click="clickCreateNewCode()" class="w-full" :class=" { 'standard-button-base': allOkay, 'disabled-button-base': !allOkay } ">
                Create
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'discountCodes'
    ],
    data() {
        return {
            codeForm: {
                code: null,
                enabled: false,
                label: null,
                multiple: false,
                percentage: null
            },
            duplicateCode: false,
            formLoaded: false,
            validating: false
        }
    },
    computed: {
        allOkay() {
            return Boolean(this.codeForm.code && this.codeForm.label && this.codeForm.percentage && this.codeForm.percentage > 0 && this.codeForm.percentage <= 100)
        }
    },
    mounted() {
        this.formLoaded = true
    },
    methods: {
        clickCreateNewCode() {
            if (this.allOkay) {
                this.duplicateCode = null
                this.validating = false
                this.codeForm.code = this.codeForm.code.toUpperCase()
                
                let checkCode = null
                
                if (this.discountCodes) {
                    checkCode = this.discountCodes.find((myCode) => myCode.code == this.codeForm.code)
                }

                if (checkCode) {
                    this.duplicateCode = this.codeForm.code
                    this.validating = true
                    alert("That discount code already exists")
                }
                else {
                    this.$emit('clickCreateNewDiscountCode', this.codeForm)
                    this.codeForm.code = null
                    this.codeForm.enabled = false
                    this.codeForm.label = null
                    this.codeForm.percentage = null
                    alert("New Discount Code created")
                }
            }
            else {
                this.validating = true
            }
        },
        updateFormValue(inField, inValue) {
            this.codeForm[inField] = inValue
        }
    }
}
</script>