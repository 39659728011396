<template>
    <div class="w-full mt-2 text-sm">
        <zone-rate-type-card v-if="shippingModel == 'general'" 
            type="general" label="Flat Rate" :rates="generalRates" :zone="zone" :readOnly="readOnly"
            @clickEditRate="clickEditRate" @clickDeleteRate="clickDeleteRate" @clickNewRate="clickNewRate"
        />
        <zone-rate-type-card v-if="shippingModel == 'price'"  
            type="price" label="Price Based" :rates="priceRates" :zone="zone" :readOnly="readOnly"
            @clickEditRate="clickEditRate" @clickDeleteRate="clickDeleteRate" @clickNewRate="clickNewRate"
        />
        <zone-rate-type-card v-if="shippingModel == 'weight'"  
            type="weight" label="Weight Based" :rates="weightRates" :zone="zone" :readOnly="readOnly"
            @clickEditRate="clickEditRate" @clickDeleteRate="clickDeleteRate" @clickNewRate="clickNewRate"
        />
        <zone-rate-type-card v-if="shippingModel == 'unit'"
            type="unit" label="Per Unit Based" :rates="unitRates" :zone="zone" :readOnly="readOnly"
            @clickEditRate="clickEditRate" @clickDeleteRate="clickDeleteRate" @clickNewRate="clickNewRate"
        />
    </div>
</template>

<script>
import ZoneRateTypeCard from './ZoneRateTypeCard'

export default {
    props: ['zone', 'readOnly', 'shippingModel'],
    components: {
        ZoneRateTypeCard
    },
    computed: {
        generalRates() {
            return this.zone.rates && this.zone.rates
                .filter((rate) => this.shippingIsGeneralRate(rate))
                .sort((a, b) => a.transitTime < b.transitTime ? -1 : (a.transitTime > b.transitTime ? 1 : a.customRateName < b.customRateName ? -1 : 1))
        },
        priceRates() {
            return this.zone.rates && this.zone.rates
                .filter((rate) => this.shippingIsPriceRate(rate))
                .sort((a, b) => a.transitTime < b.transitTime ? -1 : (a.transitTime > b.transitTime ? 1 : a.customRateName < b.customRateName ? -1 : (a.customRateName > b.customRateName ? 1 : a.minPrice < b.minPrice ? -1 : 1)))
        },
        unitRates() {
            return this.zone.rates && this.zone.rates
                .filter((rate) => this.shippingIsUnitRate(rate))
                .sort((a, b) => a.transitTime < b.transitTime ? -1 : (a.transitTime > b.transitTime ? 1 : a.customRateName < b.customRateName ? -1 : 1))
        },
        weightRates() {
            return this.zone.rates && this.zone.rates
                .filter((rate) => this.shippingIsWeightRate(rate))
                .sort((a, b) => a.transitTime < b.transitTime ? -1 : (a.transitTime > b.transitTime ? 1 : a.customRateName < b.customRateName ? -1 : (a.customRateName > b.customRateName ? 1 : a.minWeight < b.minWeight ? -1 : 1)))
        }
    },
    methods: {
        clickDeleteRate(inRate) {
            this.$emit('clickDeleteRate', inRate)
        },
        clickEditRate(inRate) {
            this.$emit('clickEditRate', inRate)
        },
        clickNewRate(inType) {
            this.$emit('clickNewRate', inType)
        }
    }
}
</script>