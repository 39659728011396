<template>
    <div v-if="diff() > 0 && !hideTimer">
        <div v-if="headingText">
            {{ headingText }}
        </div>
        <div class="whitespace-nowrap" :class="{ [countdownClass] : countdownClass }">
            <span>{{ countdownString }}</span> <span v-if="postText">{{ postText }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'countdownClass',
        'countdownTime',
        'headingText', 
        'hideTimer',
        'postText'
    ],
    data() {
        return {
            countdownString: ''
        }
    },
    computed: {
        diffTest() {
            return this.diff()
        }
    },
    mounted() {
        this.updateCountdown()

        setInterval(() => {
            this.updateCountdown()
        }, 1000)
    },
    methods: {
        diff() {
            return this.countdownTime - this.currentUnixTime()
        },
        calcDays() {
            return this.displayDays() * 86400
        },
        calcHours() {
            return this.displayHours() * 3600
        },
        calcMinutes() {
            return this.displayMinutes() * 60
        },
        dayLabel() {
            return this.displayDays() == 1 ? 'day' : 'days'
        },
        displayDays() {
            return Math.floor(this.diff() / 86400)
        },
        displayHours() {
            return Math.floor((this.diff() - this.calcDays()) / 3600)
        },
        displayMinutes() {
            return Math.floor((this.diff() - this.calcDays() - this.calcHours()) / 60)
        },
        displaySeconds() {
            return Math.floor((this.diff() - this.calcDays() - this.calcHours() - this.calcMinutes()) / 1)
        },
        hourLabel() {
            return this.displayHours() == 1 ? 'hour' : 'hours'
        },
        minuteLabel() {
            return this.displayMinutes() == 1 ? 'minute' : 'minutes'
        },
        secondLabel() {
            return this.displaySeconds() == 1 ? 'second' : 'seconds'
        },
        updateCountdown() {
            let myDays = this.displayDays() > 0 ? `${this.displayDays()}` : null
            let myHours = this.displayHours() > 0 ? `${this.displayHours()}` : '0'
            let myMinutes = this.displayMinutes() > 0 ? `${this.displayMinutes()}` : '0'
            let mySeconds = this.displaySeconds() > 0 ? `${this.displaySeconds()}` : '0'

            this.countdownString = `${myHours.padStart(2, '0')}:${myMinutes.padStart(2, '0')}:${mySeconds.padStart(2, '0')}`

            if (this.diff() <= 0) {
                this.$emit('countdownOver')
            }
        }
    }
}

</script>