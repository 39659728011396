const countriesMixins = {
    computed: {
        countryList() {
            return this.countryData
                .sort((a, b) => a.name < b.name ? -1 : 1)
        },
        userCountryCode() {
            return this.coGetCountryCodeFromTZ()
        }
    },
    methods: {
        coBuildString(inBuildData, inGpData) {
            let myString = ''
            let myFound = false

            inBuildData.forEach((dataField) => {
                if (inGpData[dataField] ?? false) {
                    myFound = true
                    
                    if (inBuildData.length > 1) {
                        myString = `${myString}${inGpData[dataField]}`
                    }
                    else {
                        myString = inGpData[dataField]
                    }
                }
                else {
                    if (inBuildData.length > 1 && myFound) {
                        myString = `${myString}${dataField}`
                    }

                    myFound = false
                }
            })

            return myString
        },
        coCheckGP() {
            let myCountryCode = this.coGetCountryCodeFromTZ()

            if (myCountryCode) {
                let myCountry = this.countryData.find((country) => country.code == myCountryCode)

                if (myCountry && (myCountry.google_places ?? false)) {
                    return true
                }
            }

            return false
        },
        coGetDefaultCurrency(inCountryCode) {
            let myCountry = this.countryData.find((country) => country.code == inCountryCode)

            return myCountry?.default_currency ?? null
        },
        coGetDefaultTaxLabel(inCountryCode) {
            let myCountry = this.countryData.find((country) => country.code == inCountryCode)

            return myCountry?.default_tax_label ?? null
        },
        async coGetGPAddress(inData) {
            let promise = new Promise((resolve, reject) => {
                let myAddress = {
                    city: null,
                    country: null,
                    line1: null,
                    line2: null,
                    postal_code: null,
                    state: null
                }

                let myData = {
                    administrative_area_level_1: null,
                    administrative_area_level_2: null,
                    administrative_area_level_3: null,
                    administrative_area_level_4: null,
                    country_code: null,
                    locality: null,
                    postal_code: null,
                    route: null,
                    street_number: null,
                    sublocality_level_1: null,
                    subpremise: null
                }

                if (inData && (inData.address_components ?? false)) {
                    inData.address_components.forEach((adComponent) => {
                        if (adComponent && (adComponent.types ?? false)) {
                            adComponent.types.forEach((comType) => {
                                if (comType == 'country' && adComponent.short_name) {
                                    myData.country_code = adComponent.short_name
                                }

                                if (comType == 'subpremise' && adComponent.long_name) {
                                    myData.subpremise = adComponent.long_name
                                }

                                if (comType == 'street_number' && adComponent.long_name) {
                                    myData.street_number = adComponent.long_name
                                }

                                if (comType == 'route' && adComponent.long_name) {
                                    myData.route = adComponent.long_name
                                }

                                if (comType == 'locality' && adComponent.long_name) {
                                    myData.locality = adComponent.long_name
                                }

                                if (comType == 'administrative_area_level_4' && adComponent.long_name) {
                                    myData.administrative_area_level_4 = adComponent.long_name
                                }

                                if (comType == 'administrative_area_level_3' && adComponent.long_name) {
                                    myData.administrative_area_level_3 = adComponent.long_name
                                }

                                if (comType == 'administrative_area_level_2' && adComponent.short_name) {
                                    myData.administrative_area_level_2 = adComponent.short_name
                                }

                                if (comType == 'administrative_area_level_1' && adComponent.short_name) {
                                    myData.administrative_area_level_1 = adComponent.short_name
                                }

                                if (comType == 'postal_code' && adComponent.long_name) {
                                    myData.postal_code = adComponent.long_name
                                }

                                if (comType == 'sublocality_level_1' && adComponent.long_name) {
                                    myData.sublocality_level_1 = adComponent.long_name
                                }
                            })
                        }
                    })

                    if (myData.country_code) {
                        let myCountryData = this.coGetCountryData(myData.country_code)

                        if (myCountryData) {
                            if (myCountryData.google_places) {
                                myAddress.line1 = this.coBuildString(myCountryData.google_places.line1, myData)
                                myAddress.line2 = this.coBuildString(myCountryData.google_places.line2, myData)
                                myAddress.city = this.coBuildString(myCountryData.google_places.city, myData)
                                myAddress.state = this.coBuildString(myCountryData.google_places.state, myData)
                                myAddress.postal_code = this.coBuildString(myCountryData.google_places.postal_code, myData)
                                myAddress.country = myData.country_code

                                if (myAddress) {
                                    resolve(myAddress)
                                }
                            }
                        }
                    }
                }

                resolve(null)
            })

            let myReturn = null

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })
        
            return myReturn
        },
        coGetCountryCodeFromTZ() {
            let userCountry = null
            let userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone

            if (userTZ) {
                userCountry = this.countryTimeZones[userTZ]
            }

            return userCountry
        },
        coGetCountryData(inCode, inAltCode) {
            let myCountryData = null

            if (inCode) {
                let myCountry = this.countryData.find((country) => country.code == inCode)

                if (myCountry) {
                    myCountryData = myCountry
                }
            }
            else if (inAltCode) {
                let myCountry = this.countryData.find((country) => country.alt_code == inCode)

                if (myCountry) {
                    myCountryData = myCountry
                }
            }

            return myCountryData
        },
        coGetCountryName(inCode, inAltCode) {
            let myCountryName = null

            if (inCode) {
                let myCountry = this.countryData.find((country) => country.code == inCode)

                if (myCountry) {
                    myCountryName = myCountry.name
                }
            }
            else if (inAltCode) {
                let myCountry = this.countryData.find((country) => country.alt_code == inCode)

                if (myCountry) {
                    myCountryName = myCountry.name
                }
            }

            return myCountryName
        }
    }
}

export default countriesMixins