<template>
    <div class="mt-px text-gray-800" :class="{ 'bg-orange-200': (zone.rates && zone.rates.length) || isZoneOpen, 'bg-red-200': (!zone.rates || zone.rates.length == 0) && !isZoneOpen }">
        <div class="flex items-center justify-between px-1 py-2 w-full">
            <div @click="clickZone()" class="mr-2 pl-1 font-semibold w-full text-left">
                {{ zone.name }}
            </div>
            <div class="flex items-center justify-end pr-1 text-gray-600">
                <div @click="clickOpenZone()" v-if="!editingRateId" v-show="!isZoneOpen" class="cursor-pointer">
                    <fa-icon icon="chevron-left" type="fas" class="h-5 w-5" />
                </div>
                <div @click="clickCloseZone()" v-if="!editingRateId" v-show="isZoneOpen" class="cursor-pointer">
                    <fa-icon icon="chevron-down" type="fas" class="h-5 w-5" />
                </div>
            </div>
        </div>
        <zone-rates v-show="isZoneOpen" 
            :defaultCurrency="defaultCurrency" :editingRateId="editingRateId" :editingRateZoneId="editingRateZoneId"
            :shippingModel="shippingModel" :zone="zone"
            @clickDeleteRate="clickDeleteRate" @clickEditRate="clickEditRate" @clickNewZoneRate="clickNewZoneRate"
        />
    </div>
</template>

<script>
import ZoneRates from './ZoneRates'

export default {
    props: [
        'defaultCurrency',
        'editingRateId',
        'editingRateZoneId',
        'openZones',
        'shippingModel',
        'zone'
    ],
    components: {
        ZoneRates
    },
    data() {
        return {
            editZoneCountry: null
        }
    },
    computed: {
        isDefaultZone() {
            return this.zone.id == 1
        },
        isZoneOpen() {
            return this.openZones.includes(this.zone.id)
        }
    },
    methods: {
        clickCloseZone() {
            this.$emit('clickCloseZone', this.zone.id)
        },
        clickDeleteRate(inRate) {
            this.$emit('clickDeleteRate', this.zone.id, inRate)
        },
        clickEditRate(inRate) {
            this.$emit('clickEditRate', this.zone.id, inRate)
        },
        clickNewZoneRate() {
            this.$emit('clickNewZoneRate', this.zone.id)
        },
        clickOpenZone() {
            this.$emit('clickOpenZone', this.zone.id)
        },
        clickZone() {
            if (this.isZoneOpen) {
                this.clickCloseZone()
            }
            else {
                this.clickOpenZone()
            }
        }
    }
}
</script>