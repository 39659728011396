<template>
    <div v-if="record" class="w-full orange-transparent relative">
        <rich-text-panel 
            fieldFormName="textOne" fieldLabel="Label" :fieldValue="record.textOne" :forceMobile="true"
            @fieldInput="updateFormValue"
        />
        <div class="w-full relative">
            <input-panel 
                fieldFormName="url" fieldLabel="URL" :fieldValue="record.url" :forceMobile="true"
                @fieldInput="updateFormValue" @fieldPaste="getUrlPreview"
            />
            <div class="absolute right-2 top-2 flex items-center">
                <div @click="getUrlPreview(this.record.url)" class="cursor-pointer pl-1">
                    <fa-icon icon="globe" type="fas" class="h-5 w-5" />
                </div>
            </div>
        </div>
        <rich-text-panel 
            fieldFormName="textTwo" fieldLabel="Description" :fieldValue="record.textTwo" :forceMobile="true"
            @fieldInput="updateFormValue"
        />
        <media-panel 
            fieldLabel="Image" :hideSpinner="true" :imagesArray="imagesArray" :imagesOnly="true" :maxFiles="1" :multiple="false"
            @saveMedia="saveLocalMedia" @updateOfflineMedia="updateOfflineMedia"
        />
        <div class="absolute right-2 top-2 flex items-center">
            <div @click="deleteRecord()" class="cursor-pointer pl-2 mr-1">
                <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
            </div>
            <div @click="$emit('close')" class="cursor-pointer pl-1">
                <fa-icon icon="circle-stop" type="far" class="h-5 w-5" />
            </div>
        </div>
        <div v-if="checkingURL" class="absolute left-0 top-0 w-full h-full flex items-center justify-center" style="background: rgba(0, 0, 0, 0.80);">
            <div>
                <div>
                    <fa-icon icon="spinner" type="fas" class="spin h-20 w-20" />
                </div>
                <div class="text-lg font-bold mt-4">
                    Checking URL
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'propRecord'
    ],
    data() {
        return {
            checkingURL: false,
            preview: null,
            record: null
        }
    },
    computed: {
        imagesArray() {
            let myGallery = []

            this.propRecord.gallery.forEach((myImage) => {
                myGallery.push(myImage)
            })

            return myGallery
        }
    },
    mounted() {
        this.record = this.objCopy(this.propRecord)

        if (!this.record.gallery) {
            this.record.gallery = []
        }
    },
    methods: {
        async deleteRecord() {
            if (confirm("Really delete '" + this.record.textOne + "'?")) {
                
            }
        },
        getUrlPreview(inText) {
            if (this.isValidURL(inText)) {
                this.checkingURL = true
                this.preview = null
                
                buildzAxios?.get(this.getRoute('modSearch'), {
                    params: {
                        'type': 'meta',
                        'url': inText
                    }
                })
                .then((response) => {
                    if (!this.preview && response.data.title) {
                        this.preview = response.data
                        this.record.textTwo = this?.preview?.title ? this?.preview?.title.slice(0,50) : null
                        this.record.url = inText
                        let myGallery = this.preview.images

                        if (myGallery && myGallery.length && !this.record.gallery.length) {
                            let myImage = {
                                url: myGallery[0],
                                type: "image",
                                identifier: this.getRandomString(20)
                            }
                            
                            this.record.gallery = [myImage]
                        }

                        this.updateRecord()
                    }
                })
                .catch((error) => {
                    console.log(error)
                    alert("Unable to reach URL")
                })
                .then(() => {
                    this.checkingURL = false
                })
            }
        },
        saveLocalMedia(inGallery) {
            this.record.gallery = inGallery
            this.updateRecord()
        },
        updateFormValue(inField, inValue) {
            this.record[inField] = inValue
            this.updateRecord()
        },
        updateOfflineMedia(inGallery) {
            this.record.gallery = inGallery
            this.updateRecord()
        },
        updateRecord() {
            this.$emit('updateRecord', this.record)
        }
    }
}
</script>