<template>
    <div :ref="`postReaction${reaction.id}`" v-if="reaction" class="pb-6 my-6 border-b-2">
        <div class="flex" :class="{ '-mt-2 -ml-2 p-2': isFocusedReaction }">
            <router-link :to="actorURL">
                <div class="w-8 h-8 rounded-full border-2 border-orange-500" style="min-width: 2rem;">
                    <user-avatar :url="actor ? actor.profileImage : ''" :rounded="true" />
                </div>
            </router-link>
            <div class="ml-2">
                <router-link class="font-bold" style="line-height: 2rem;" :to="actorURL">
                    {{ actor ? actor.name : 'Unknown' }}
                </router-link>
                <post-reaction-gallery v-if="activityImage" :gallery="[activityImage]" />
                <div class="mt-1" v-html="renderText">
                </div>
            </div>
        </div>
        <div class="flex mt-1 text-xs">
            <div class="mr-6 text-gray-300">
                {{ reactionAge }}
            </div>
            <div class="flex">
                <reaction-like :reaction="reaction" :queryId="queryId" />
                <div @click="clickReply" class="mr-2 cursor-pointer">
                    Reply
                </div>
                <drop-down v-if="loggedUser && isMyPostReaction" linkText="More" linkClass="">
                    <span @click="deletePostReaction(reaction)">
                        Delete
                    </span>
                    <span @click="openWritePostReaction(reaction)">
                        Edit
                    </span>
                </drop-down>
            </div>
        </div>
        <!-- <inline-comment v-if="showInlineComment" :reaction="reaction" :queryId="queryId" /> -->
        <comments :reaction="reaction" />
    </div>
</template>

<script>
import scrollIntoView from 'scroll-into-view-if-needed'

import Comments from './Comments'
// import InlineComment from './InlineComment'
import DropDown from '@/shared/DropDown'
import PostReactionGallery from './PostReactionGallery'
import ReactionLike from './ReactionLike'

export default {
    props: {
        reaction: null,
        queryId: null
    },
    components: {
        Comments,
        // InlineComment,
        DropDown,
        PostReactionGallery,
        ReactionLike
    },
    data() {
        return {
            avatarError: false,
            showInlineComment: false
        }
    },
    computed: {
        activityImage() {
            if (!this.reaction.data.image) {
                return null
            }

            if (this.reaction.data.image.type) {
                return this.reaction.data.image
            }

            return {
                type: 'image',
                url: this.reaction.data.image
            }
        },
        actor() {
            return this.reaction.user.data
        },
        actorURL() {
            return this.actor?.handle ? `/garage/${this.actor.handle}` : '#'
        },
        isFocusedReaction() {
            return false
        },
        isMyPostReaction() {
            return this.loggedUserId == this.actor.id
        },
        reactionAge() {
            return this.humanizedAge(this.reaction.created_at)
        },
        renderText() {
            let myText = this.textRenderer(this.nlToBrSpace(this.reaction.data.object)).trim()
            return myText.length ? myText : null
        }
    },
    mounted() {
        if (this.scrollToReactionId && this.scrollToReactionId == this.reaction.id && this.$refs[`postReaction${this.reaction.id}`]) {
            scrollIntoView(this.$refs[`postReaction${this.reaction.id}`], {
                behavior: 'smooth',
                scrollMode: 'if-needed',
                block: 'center',
                inline: 'center',
            })

            this.setStateData([
                { scrollToReactionId: null }
            ])
        }
    },
    methods: {
        clickReply() {
            // if (!this.userCanPost) {
            //     this.pageMessage('info', 'You need to verify your email to reply to a comment')
            // }
            // else {
            //     this.showInlineComment = !this.showInlineComment
            // } 
            
            this.$emit('reply', this.reaction.user.data.handle)
        }
    }
}
</script>