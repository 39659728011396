<template>
    <div v-if="formLoaded" class="w-full p-2 white-transparent">
        <div v-if="bundles && !hideBundles" class="w-full">
            <draggable
                animation="250" easing="cubic-bezier(1, 0, 0, 1)" handle=".handle-bundle" item-key="index" :list="bundlesForm.bundles" tag="div" 
                @change="saveOrder()"
            >
                <template #item="{element, index}">
                    <div class="relative">
                        <panel-sub-section
                            :sectionIdentifier="element.id" :sectionLabel="element.label" :sectionOpen="showBundles.includes(element.id)" 
                            @cacheSectionOpen="clickShowBundle"
                        >
                            <edit-tier
                                :bundle="element" :groupBuy="groupBuy"
                                @deleteBundle="deleteBundle" @updateBundle="updateBundle"
                            />
                        </panel-sub-section>
                        <div class="absolute left-0 top-0 cursor-move handle-bundle p-2">
                            <fa-icon icon="up-down-left-right" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
        <div class="w-full orange-transparent" :class="{ 'mt-2': bundlesForm.bundles.length }">
            <panel-sub-section
                sectionLabel="Create A New Tier" :sectionOpen="showNewBundle"
                @cacheSectionOpen="clickShowNewBundle"
            >
                <new-tier
                    :groupBuy="groupBuy" :lastId="lastId"
                    @clickCreateNewBundle="clickCreateNewBundle"
                />
            </panel-sub-section>
        </div>   
    </div>
</template>

<script>
import draggable from 'vuedraggable'

import EditTier from './EditTier'
import NewTier from './NewTier'

export default {
    props: [
        'bundles',
        'groupBuy'
    ],
    components: {
        draggable,
        EditTier,
        NewTier
    },
    data() {
        return {
            bundlesForm: {
                bundles: null
            },
            formLoaded: false,
            hideBundles: false,
            lastId: 0,
            showBundles: [],
            showNewBundle: false
        }
    },
    mounted() {
        let myBundles = []

        if (this?.bundles ?? false) {    
            this.bundles.forEach((myBundle) => {
                let myNewBundle = {
                    charityPercentage: myBundle.charityPercentage,
                    description: myBundle.description,
                    enabled: myBundle.enabled,
                    id: myBundle.id,
                    image: myBundle.image,
                    label: myBundle.label,
                    milestones: myBundle.milestones,
                    price: myBundle.price,
                    prizePercentage: myBundle.prizePercentage
                }

                if (myBundle.id > this.lastId) {
                    this.lastId = myBundle.id
                }

                myBundles.push(myNewBundle)
            })
        }

        this.bundlesForm.bundles = myBundles
        this.formLoaded = true
    },
    methods: {
        clickCreateNewBundle(inForm) {
            let myForm = this.objCopy(inForm)
            myForm.id = this.lastId + 1
            this.lastId = this.lastId + 1
            this.bundlesForm.bundles.push(myForm)
            this.postBundle(myForm)
        },
        clickShowNewBundle(inIsOpen) {
            this.showNewBundle = inIsOpen
        },
        clickShowBundle(inIsOpen, inId) {
            if (inIsOpen) {
                if (!this.showBundles.includes(inId)) {
                    this.showBundles.push(inId)
                }
            }
            else {
                this.showBundles = this.showBundles.filter((myBundle) => myBundle != inId)
            }
        },
        deleteBundle(inId) {
            this.clickShowBundle(false, inId)
            let myBundles = this.objCopy(this.bundlesForm.bundles)
            myBundles = myBundles.filter((myBundle) => myBundle.id != inId)
            this.hideBundles = true
            this.bundlesForm.bundles = myBundles

            setTimeout(() => {
                this.hideBundles = false
            }, 100)

            this.$emit('updateBundles', myBundles)
            this.saveStart()

            buildzAxios?.delete(this.getRoute('sellerGroupBuyBundle', this.groupBuy.id), {
                data: {
                    bundleId: inId
                }
            })
                .then((response) => {
                    this.pageMessage('success', "Tier deleted")
                    let myRecord = Object.entries(this.vendorGroupBuys).find((entry) => entry[1].id == this.groupBuy.id)
                    let myRecords = this.objCopy(this.vendorGroupBuys)
                    myRecords[myRecord[0]] = this.objCopy(response.data.groupBuy)

                    this.setStateData([
                        { vendorGroupBuys: myRecords }
                    ])

                    this.saveDone()
                })
                .catch((error) => {
                    console.log(error)
                    this.saveDone()
                    this.pageMessage('error', 'Error updating Group Buy')
                })
        },
        postBundle(inBundle) {
            this.saveStart()
            this.$emit('updateBundles', this.bundlesForm.bundles)

            buildzAxios?.post(this.getRoute('sellerGroupBuyBundle', this.groupBuy.id), {
                bundle: inBundle
            })
                .then((response) => {
                    let myRecord = Object.entries(this.vendorGroupBuys).find((entry) => entry[1].id == this.groupBuy.id)
                    let myRecords = this.objCopy(this.vendorGroupBuys)
                    myRecords[myRecord[0]] = this.objCopy(response.data.groupBuy)

                    this.setStateData([
                        { vendorGroupBuys: myRecords }
                    ])

                    this.saveDone()
                })
                .catch((error) => {
                    console.log(error)
                    this.saveDone()
                    this.pageMessage('error', 'Error updating Group Buy')
                })
        },
        saveOrder() {
            this.$emit('updateBundles', this.bundlesForm.bundles)

            buildzAxios?.post(this.getRoute('sellerGroupBuyBundleOrder', this.groupBuy.id), {
                bundles: this.bundlesForm.bundles
            })
                .then((response) => {
                    let myRecord = Object.entries(this.vendorGroupBuys).find((entry) => entry[1].id == this.groupBuy.id)
                    let myRecords = this.objCopy(this.vendorGroupBuys)
                    myRecords[myRecord[0]] = this.objCopy(response.data.groupBuy)

                    this.setStateData([
                        { vendorGroupBuys: myRecords }
                    ])

                    this.saveDone()
                })
                .catch((error) => {
                    console.log(error)
                    this.saveDone()
                    this.pageMessage('error', 'Error updating Group Buy')
                })
        },
        updateBundle(inForm) {
            this.bundlesForm.bundles.find((myBundle) => myBundle.id == inForm.id).charityPercentage = inForm.charityPercentage
            this.bundlesForm.bundles.find((myBundle) => myBundle.id == inForm.id).description = inForm.description
            this.bundlesForm.bundles.find((myBundle) => myBundle.id == inForm.id).enabled = inForm.enabled
            this.bundlesForm.bundles.find((myBundle) => myBundle.id == inForm.id).label = inForm.label
            this.bundlesForm.bundles.find((myBundle) => myBundle.id == inForm.id).milestones = inForm.milestones
            this.bundlesForm.bundles.find((myBundle) => myBundle.id == inForm.id).price = inForm.price
            this.bundlesForm.bundles.find((myBundle) => myBundle.id == inForm.id).tempImage = inForm.tempImage
            this.bundlesForm.bundles.find((myBundle) => myBundle.id == inForm.id).prizePercentage = inForm.prizePercentage
            this.postBundle(inForm)
        }
    }
}
</script>