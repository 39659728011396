<template>
    <div class="w-full">
        <div v-if="mounted && product" class="w-full h-full flex items-start mt-px overflow-hidden" :class="{ 'white-transparent': !selected, 'orange-transparent': selected }">
            <div class="w-1/3 h-full relative" :class="{ 'white-transparent': !selected, 'orange-transparent': selected }">
                <div class="overflow-hidden" :class="{ 'w-full h-full': showImage, 'h-0 w-0': !showImage || !productImage }">
                    <cached-image @load="srcLoaded = true" imageClasses="w-full h-full object-scale-down" :imageSrc="transformUrl(productImage, ['optimize', 'md', 'q80'])" loading="lazy" />
                </div>
                <div class="overflow-hidden flex items-center justify-center opacity-60" :class="{ 'w-full h-full': !showImage, 'h-0 w-0': showImage }">
                    <fa-icon icon="bag-shopping" type="fas" class="h-28 w-28" />
                </div>
            </div>
            <div class="w-2/3 h-full relative">
                <div class="px-2 pt-1 text-white w-full">
                    <div class="text-lg leading-tight overflow-hidden mb-2 mr-8" style="max-height: 2.8rem;">
                        {{ product.title }}
                    </div>
                    <div>
                        {{ displayPrice(product.price.currency, product.price.amount) }}
                    </div>
                </div>
                <div v-if="showDelete || showEdit" class="absolute right-0 top-0 p-1 flex items-center justify-end">
                    <div v-if="showEdit" @click.stop="clickEditProduct()" class="cursor-pointer">
                        <fa-icon icon="pen-to-square" type="fas" class="h-6 w-6" />
                    </div>
                    <div v-if="showDelete" @click.stop="clickDeleteProduct()" class="cursor-pointer ml-2">
                        <fa-icon icon="trash-can" type="fas" class="h-6 w-6" />
                    </div>
                </div>
                <div v-if="selected" class="absolute right-0 top-0 p-1 flex items-center justify-end">
                    <div class="text-orange-500">
                        <fa-icon icon="check-circle" type="fas" class="h-6 w-6" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showBreakpoints && sortedPricingLevels" class="mt-px">
            <group-buy-tiers
                :hideHeading="true" :product="product" :sortedPricingLevels="sortedPricingLevels"
            />
        </div>
    </div>
</template>

<script>
import GroupBuyTiers from '@/shared/Marketplace/GroupBuyTiers'

export default {
    props: [
        'product',
        'selected',
        'showBreakpoints',
        'showDelete',
        'showEdit',
        'sortedPricingLevels'
    ],
    components: {
        GroupBuyTiers
    },
    data() {
        return {
            mounted: false,
            productImage: null,
            showImage: false,
            srcLoaded: false
        }
    },
    computed: {
        productShippingModel() {
            return this?.product?.shipping_model ? this.shippingModels[this.product.shipping_model] : 'No'
        }
    },
    watch: {
        product: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.productImage = this.product && this.product.gallery && this.product.gallery.length ? this.product.gallery[0].url : null
                }
            }
        },
        srcLoaded: {
            handler(val) {
                if (val && this.productImage) {
                    this.showImage = true
                }
            }
        }
    },
    mounted() {
        this.mounted = true
    },
    methods: {
        clickDeleteProduct() {
            this.$emit('clickDeleteProduct', this.product.id)
        },
        clickEditProduct() {
            this.openEditListingModal(this.product)
        }
    }
}
</script>