const garageMixins = {
    methods: {
        isMyGarage(inId, inHandle) {
            if (!this.loggedUser) {
                return 'garage.show'
            }

            if (inId) {
                return this.loggedUserId == inId ? 'garage.myGarageShow' : 'garage.show'
            }

            if (inHandle) {
                return this.loggedUser.handle == inHandle ? 'garage.myGarageShow' : 'garage.show'
            }

            return 'garage.show'
        }
    }
}

export default garageMixins