<template>
    <input-panel 
        fieldFormName="name" fieldLabel="Seller Name" fieldLabelClasses="text-gray-800" fieldPlaceholder="Seller Name" :fieldRequired="true" fieldType="text" :fieldValue="sellerForm.name" 
        :forceMobile="true"
        @fieldInput="updateSellerFormValue"
    >
        <validation-message v-if="validating && !sellerForm.name" 
            message="Seller Name must be entered"
        />
    </input-panel>
</template>

<script>
export default {
    props: [
        'sellerForm',
        'validating'
    ],
    methods: {
        updateSellerFormValue(inField, inValue) {
            this.$emit('updateSellerFormValue', inField, inValue)
        }
    }
}
</script>