// import { AFConstants, AppsFlyer } from "appsflyer-capacitor-plugin"

const appsFlyerMixins = {
    methods: {
        appsFlyerEvent(inData) {
            if (this.appsFlyerConnected && this.serverConnected) {
                AppsFlyer.logEvent(inData)
                    .then((r) => {
                        console.log(r.res)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } 
            else {
                console.log("appsflyer NOT connected!")
            }

            // inData is an object with eventName and eventValue
            // {
            //      eventName: 'test',
            //      eventValue: {
            //          af_revenue: 956,
            //          af_receipt_id: 'id536',
            //          af_currency: 'USD'
            //      }
            // }
        },
        initAppsFlyer() {
            if (AppsFlyer ?? false) {
                let appsFlyerConfig = {
                    appID: this.isIOSApp ? "1547386480" : "pro.buildz.android",
                    devKey: "ssi23q8yvSvppbxwkNVNfa", // replace with your dev key.
                    isDebug: false,
                    waitForATTUserAuthorization: 5, // for iOS 14 and higher
                    minTimeBetweenSessions: 60, // default 5 sec
                    registerOnDeepLink: true,
                    registerConversionListener: true,
                    registerOnAppOpenAttribution: true,
                    deepLinkTimeout: 4000, // default 3000 ms
                    useReceiptValidationSandbox: false, // iOS only
                    useUninstallSandbox: false, // iOS only 
                }

                AppsFlyer.initSDK(appsFlyerConfig)
                    .then((res) => {
                        console.log("appsflyer connected")

                        this.setStateData([
                            { appsFlyerConnected: true }
                        ])

                        let myToken = localStorage.getItem('token')
                        let myInstalled = localStorage.getItem('bzInstalled')

                        if (!myToken && !myInstalled) {
                            this.appsFlyerEvent(
                                {
                                    eventName: 'New Install',
                                    eventValue: {
                                    }
                                }
                            )
                        }

                        localStorage.setItem('bzInstalled', true)
                    })
                    .catch((error) => {
                        console.log("appsflyer failed to init")
                        console.log(error)
                    })
            }
            else {
                console.log('No appsflyer')
            }
        },
        setUninstallToken(inToken) {
            // AppsFlyer.updateServerUninstallToken({ token: inToken })
        }
    }
}

export default appsFlyerMixins
