<template>
    <div class="image-box w-full" v-if="element.type">
        <div class="bg-black w-full h-full flex items-center justify-center rounded-md relative">
            <cached-image v-if="element.type == 'image' && element.url" 
                imageClasses="rounded-md" :imageSrc="transformUrl(element.url, ['optimize', 'md', 'q80'])" 
            />
            <div v-if="!element.url && element.type != 'video'">
                <img :src="getPreview(element)" />
            </div>
            <div v-if="!element.url && element.type != 'video' && !hideSpinner" class="absolute overflow-hidden flex items-center justify-center opacity-60 py-8 w-full h-full">
                <fa-icon icon="spinner" type="fas" class="spin h-28 w-28" />
            </div>
            <div v-if="element.type == 'video'" class="rounded-md bg-white flex items-center justify-center w-full py-12 text-orange-400">
                <fa-icon icon="film" type="fas" class="h-20 w-20" />
            </div>
            <div v-if="!hideDelete" class="absolute left-0 top-0 w-full h-full reverse-scrim p-1 rounded-md flex items-start justify-between">
                <div @click="deleteFile(index)" class="cursor-pointer">
                    <fa-icon icon="xmark" type="fas" class="h-4 w-4" />
                </div>
                <div v-if="filesCount > 1" class="cursor-move handle-media">
                    <fa-icon icon="up-down-left-right" type="fas" class="h-4 w-4" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'element',
        'filesCount',
        'hideDelete',
        'hideSpinner',
        'index'
    ],
    watch: {
        queueImagesUpdated: {
            handler(val) {
                if (this.element.identifier && this.queueImagesUpdated[this.element.identifier]) {
                    this.$emit('updateElement', this.queueImagesUpdated[this.element.identifier].image, this.index)

                    let { [`${this.element.identifier}`]: _, ...theRest } = this.queueImagesUpdated

                    this.setStateData([
                        { queueImagesUpdated: null }
                    ])

                    this.setStateData([
                        { queueImagesUpdated: theRest }
                    ])
                }
            }
        }
    },
    methods: {
        deleteFile(inIndex) {
            this.$emit('deleteFile', inIndex)
        },
        getPreview(inElement) {
            try {
                return URL.createObjectURL(inElement)
            }
            catch (error) {
                return null
            }
        }
    }
}
</script>