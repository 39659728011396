<template>
    <div class="w-full text-sm">
        <zone-rate-type-card v-if="shippingModel == 'general'" 
            :defaultCurrency="defaultCurrency" :editingRateId="editingRateId" :editingRateZoneId="editingRateZoneId" :editingZoneId="editingZoneId" 
            :rates="generalRates" type="general" :zone="zone"
            @clickDeleteRate="clickDeleteRate" @clickEditRate="clickEditRate" @clickNewZoneRate="clickNewZoneRate"
        />
        <zone-rate-type-card v-if="shippingModel == 'price'"  
            :defaultCurrency="defaultCurrency" :editingRateId="editingRateId" :editingRateZoneId="editingRateZoneId" :editingZoneId="editingZoneId" 
            :rates="priceRates" type="price" :zone="zone"
            @clickDeleteRate="clickDeleteRate" @clickEditRate="clickEditRate" @clickNewZoneRate="clickNewZoneRate"
        />
        <zone-rate-type-card v-if="shippingModel == 'weight'"  
            :defaultCurrency="defaultCurrency" :editingRateId="editingRateId" :editingRateZoneId="editingRateZoneId" :editingZoneId="editingZoneId" 
            :rates="weightRates" type="weight" :zone="zone"
            @clickDeleteRate="clickDeleteRate" @clickEditRate="clickEditRate" @clickNewZoneRate="clickNewZoneRate"
        />
        <zone-rate-type-card v-if="shippingModel == 'unit'"
            :defaultCurrency="defaultCurrency" :editingRateId="editingRateId" :editingRateZoneId="editingRateZoneId" :editingZoneId="editingZoneId" 
            :rates="unitRates" type="unit" :zone="zone"
            @clickDeleteRate="clickDeleteRate" @clickEditRate="clickEditRate" @clickNewZoneRate="clickNewZoneRate"
        />
    </div>
</template>

<script>
import ZoneRateTypeCard from './ZoneRateTypeCard'

export default {
    props: [
        'defaultCurrency',
        'editingRateId',
        'editingRateZoneId',
        'editingZoneId',
        'shippingModel',
        'zone'
    ],
    components: {
        ZoneRateTypeCard
    },
    computed: {
        generalRates() {
            return this.zone.rates && this.zone.rates
                .filter((rate) => this.shippingIsGeneralRate(rate))
                .sort((a, b) => a.customRateName < b.customRateName ? -1 : 1)
        },
        priceRates() {
            return this.zone.rates && this.zone.rates
                .filter((rate) => this.shippingIsPriceRate(rate))
                .sort((a, b) => a.customRateName < b.customRateName ? -1 : (a.customRateName > b.customRateName ? 1 : a.minPrice < b.minPrice ? -1 : 1))
        },
        unitRates() {
            return this.zone.rates && this.zone.rates
                .filter((rate) => this.shippingIsUnitRate(rate))
                .sort((a, b) => a.customRateName < b.customRateName ? -1 : 1)
        },
        weightRates() {
            return this.zone.rates && this.zone.rates
                .filter((rate) => this.shippingIsWeightRate(rate))
                .sort((a, b) => a.customRateName < b.customRateName ? -1 : (a.customRateName > b.customRateName ? 1 : a.minWeight < b.minWeight ? -1 : 1))
        }
    },
    methods: {
        clickDeleteRate(inRate) {
            this.$emit('clickDeleteRate', inRate)
        },
        clickEditRate(inRate) {
            this.$emit('clickEditRate', inRate)
        },
        clickNewZoneRate() {
            this.$emit('clickNewZoneRate')
        }
    }
}
</script>