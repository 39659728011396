<template>
    <div>
        <div v-if="thisBuildImage" class="mb-3">
            <img @load="srcLoaded = true" :src="transformUrl(thisBuildImage, ['optimize', 'md'])" class="object-cover" :class="{ 'w-0 h-0': !showImage, 'w-full h-full': showImage }" loading="lazy"/>
        </div>
        <div v-if="isMounted" class="px-5 pb-3">
            <div>
                {{ buildData.title }}
            </div>
            <div class="text-xs text-orange-500">
                {{ originDomain }}
            </div>
            <div v-if="buildData.description" :id="textContainerId" class="mt-1 text-xs overflow-hidden" :style="!modal && !showMoreLinkClicked ? { maxHeight: '4.3rem' } : {}">
                <div v-if="renderText" v-html="renderText">
                </div>
            </div>
            <div v-if="!showMoreLinkClicked && needsShowMore()" class="mt-1 pb-3 text-sm text-orange-400 cursor-pointer" @click="clickShowMore()">
                Show more
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['buildData', 'buildId', 'modal', 'activityId'],
    data() {
        return {
            isMounted: false,
            showImage: false,
            showMore: false,
            showMoreLinkClicked: false,
            srcLoaded: false
        }
    },
    computed: {
        handle() {
            return this?.buildData.url.split('/')[4] ? this.buildData.url.split('/')[4] : null
        },
        originDomain() {
            return this?.buildData.url.split('/')[2]
        },
        renderText() {
            let myText = this.textRenderer(this.nlToBrSpace(this.buildData.description)).trim()
            return myText.length ? myText : null
        },
        showShowMore() {
            return Boolean(!this.modal && this.elGet(this.textContainerId) && this.elGet(this.textContainerId).scrollHeight > this.elGet(this.textContainerId).clientHeight)
        },
        textContainerId() {
            return `build-text-${this.activityId}`
        },
        thisBuildImage() {
            return !this.buildData.images ? null : (this?.buildData?.images[0]?.image ? this.buildData.images[0].image : this?.buildData?.images[0]?.secure_url)
        }
    },
    watch: {
        srcLoaded: {
            handler(val) {
                if (val) {
                    this.showImage = true
                }
            }
        }
    },
    mounted() {
        this.isMounted = true

        this.$nextTick(() => {
            this.setShowMore()
        })

        setTimeout(()=>{
            this.setShowMore()
        }, 500)
    },
    methods: {
        clickShowMore() {
            this.showMoreLinkClicked = true
        },
        needsShowMore() {
            return Boolean(this.isMounted && this.elGet(this.textContainerId) && this.elGet(this.textContainerId).scrollHeight > this.elGet(this.textContainerId).clientHeight)
        },
        setShowMore() {
            this.showMore = !this.modal && this.elGet(this.textContainerId) && this.elGet(this.textContainerId).scrollHeight > this.elGet(this.textContainerId).clientHeight
        }
    }
}
</script>