<template>
    <div class="mt-px text-gray-800 bg-orange-200">
        <div class="flex items-center justify-between px-1 py-2">
            <div v-if="editingZoneId != zone.id" class="mr-2 pl-1 font-semibold">
                {{ zone.name }}
            </div>
            <div v-if="editingZoneId == zone.id" class="mr-2">
                <select v-model="editZoneCountry" class="form-input edit-field-text-color bg-white py-1 w-full pr-8">
                    <option v-for="country in countryList" :key="country.code" :value="country.code">{{ country.name }}</option>
                </select>
            </div>
            <div class="flex items-center justify-end pr-1 text-gray-600">
                <div @click="clickDeleteZone()" v-if="!isDefaultZone && !editingZoneId && !editingRateId" class="cursor-pointer">
                    <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                </div>
                <div @click="clickEditZone()" v-if="!isDefaultZone && !editingZoneId && !editingRateId" class="cursor-pointer mx-2">
                    <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                </div>
                <div @click="clickSaveEditZone()" v-if="editingZoneId && !editingRateId" class="cursor-pointer">
                    <fa-icon icon="floppy-disk" type="fas" class="h-5 w-5" />
                </div>
                <div @click="clickEditZone()" v-if="editingZoneId && !editingRateId" class="cursor-pointer mx-2">
                    <fa-icon icon="ban" type="fas" class="h-5 w-5" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'editingZoneId',
        'zone'
    ],
    data() {
        return {
            editZoneCountry: null
        }
    },
    computed: {
        isDefaultZone() {
            return this.zone.id == 1
        }
    },
    methods: {
        clickDeleteZone() {
            this.$emit('clickDeleteZone', this.zone.id)
        },
        clickEditZone() {
            if (this.zone.id == this.editingZoneId) {
                this.editZoneCountry = null
            }
            else {
                this.editZoneCountry = this.zone.countryCode
            }
            
            this.$emit('clickEditZone', this.zone)
        },
        clickSaveEditZone() {
            this.$emit('updateZoneCountry', this.editZoneCountry)
            this.clickEditZone()
        }
    }
}
</script>