const likeMixins = {
    methods: {
        startToggleActivityLike(inActivityId) {
            var myUser = this.objCopy(this.loggedUser)
            var myTogglingActivityLikes = []

            if (this.userTogglingActivityLikes) {
                myTogglingActivityLikes = this.objCopy(this.userTogglingActivityLikes)
            }

            if (!myTogglingActivityLikes.includes(inActivityId)) {
                myTogglingActivityLikes.push(inActivityId)
            }

            myUser.togglingActivityLikes = myTogglingActivityLikes
            
            this.setStateData([
                { loggedUser: myUser }
            ])
        },
        startTogglePostReactionLike(inReactionId) {
            var myUser = this.objCopy(this.loggedUser)
            var myTogglingPostReactionLikes = []

            if (this.userTogglingPostReactionLikes) {
                myTogglingPostReactionLikes = this.objCopy(this.userTogglingPostReactionLikes)
            }

            if (!myTogglingPostReactionLikes.includes(inReactionId)) {
                myTogglingPostReactionLikes.push(inReactionId)
            }

            myUser.togglingPostReactionLikes = myTogglingPostReactionLikes
            
            this.setStateData([
                { loggedUser: myUser }
            ])
        },
        stopToggleActivityLike(inActivityId) {
            var myUser = this.objCopy(this.loggedUser)

            if (this.userTogglingActivityLikes) {
                var myTogglingActivityLikes = this.objCopy(this.userTogglingActivityLikes)
                myTogglingActivityLikes = myTogglingActivityLikes.filter(like => like != inActivityId)
                myUser.togglingActivityLikes = myTogglingActivityLikes
                
                this.setStateData([
                    { loggedUser: myUser }
                ])
            }
        },
        stopTogglePostReactionLike(inReactionId) {
            var myUser = this.objCopy(this.loggedUser)

            if (this.userTogglingPostReactionLikes) {
                var myTogglingPostReactionLikes = this.objCopy(this.userTogglingPostReactionLikes)
                myTogglingPostReactionLikes = myTogglingPostReactionLikes.filter(like => like != inReactionId)
                myUser.togglingPostReactionLikes = myTogglingPostReactionLikes
                
                this.setStateData([
                    { loggedUser: myUser }
                ])
            }
        },
        toggleLikeActivity(inActivity, inLike, inFeedId) {
            if (!this.streamClient?.reactions) return
            
            if (!this.userTogglingActivityLikes || !this.userTogglingActivityLikes.includes(inActivity.id)) {
                this.startToggleActivityLike(inActivity.id)
                if (!inLike) {
                this.streamClient.reactions.add("like", inActivity.id)
                    .then(async (response) => {
                        let myActivity = await this.getSingleActivity(this.feeds[inFeedId], inActivity.id)
                        myActivity = myActivity?.results[0] || null
                        let myRecentLikes = myActivity?.latest_reactions?.like || null
                        
                        Object.entries(this.feedActivities).forEach(([feedId, activities]) => {
                            let myActivities = this.objCopy(activities)

                            myActivities = myActivities.map((activity) => {
                                if (activity.id == inActivity.id) {
                                    activity.own_reactions = { ...activity.own_reactions, like: [response] }
                                    activity.latest_reactions.like = myRecentLikes ? this.objCopy(myRecentLikes) : null

                                    if (activity?.reaction_counts.like) {
                                        ++activity.reaction_counts.like
                                    }
                                    else {
                                        activity.reaction_counts = { ...activity?.reaction_counts, like: 1 }
                                    }
                                }

                                return activity
                            })

                            this.updateActivities(myActivities, feedId)
                        })

                        // if (this.loggedUserId != parseInt(inActivity.actor.id)) {
                        buildzAxios?.post(this.getRoute('activityLike', inActivity.id))
                        // }
                    })
                    .catch((error) => {
                        this.pageMessage('error', 'Error liking post')
                    })
                    .then(() => {
                        this.stopToggleActivityLike(inActivity.id)
                    })
                }
                else {
                    this.streamClient.reactions.delete(inLike.id)
                        .then(async (response) => {
                            let myActivity = await this.getSingleActivity(this.feeds[inFeedId], inActivity.id)
                            myActivity = myActivity?.results[0] || null
                            let myRecentLikes = myActivity?.latest_reactions?.like || null
                            
                            Object.entries(this.feedActivities).forEach(([feedId, activities]) => {
                                let myActivities = this.objCopy(activities)

                                myActivities = myActivities.map((activity) => {
                                    if (activity.id == inActivity.id) {
                                        let { like: _, ...myReactions } = activity.own_reactions
                                        activity.own_reactions = myReactions
                                        activity.latest_reactions.like = myRecentLikes ? this.objCopy(myRecentLikes) : null

                                        if (activity?.reaction_counts.like && activity?.reaction_counts.like > 1) {
                                            --activity.reaction_counts.like
                                        }
                                        else if (activity?.reaction_counts.like && activity?.reaction_counts.like == 1) {
                                            let { like: _like, ...myReactionCounts } = activity?.reaction_counts
                                            activity.reaction_counts = myReactionCounts
                                        }
                                    }

                                    return activity
                                })

                                this.updateActivities(myActivities, feedId)
                            })
                        })
                        .catch((error) => {
                            this.pageMessage('error', 'Error unliking post')
                        })
                        .then(() => {
                            this.stopToggleActivityLike(inActivity.id)
                        })
                }
            }
        },
        toggleLikePostReaction(inReaction, inLike) {
            if(!this.streamClient?.reactions) return;
            
            if (!this.userTogglingPostReactionLikes || !this.userTogglingPostReactionLikes.includes(inReaction.id)) {
                this.startTogglePostReactionLike(inReaction.id)

                if (!inLike) {
                    this.streamClient.reactions.addChild("like", inReaction)
                        .then((response) => {
                        //console.log(response)

                            let myReactions = this.objCopy(this?.reactions)

                            myReactions = Object.values(myReactions).map((reaction) => {
                                if (reaction.id == inReaction.id) {
                                    if (!reaction.own_children) {
                                        reaction.own_children = {}
                                    }

                                    reaction.own_children = { ...reaction.own_children, like: [response] }

                                    if (reaction.children_counts.like) {
                                        ++reaction.children_counts.like
                                    }
                                    else {
                                        reaction.children_counts = { ...reaction.children_counts, like: 1 }
                                    }
                                }

                                return reaction
                            })

                            this.replaceStateData([
                                { reactions: myReactions }
                            ])

                            if (this.loggedUserId != parseInt(inReaction.user_id)) {
                                buildzAxios?.post(this.getRoute('postReactionLike', inReaction.id), {
                                    activityId: inReaction.activity_id,
                                    postReactionOwnerId: inReaction.user_id
                                })
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            this.pageMessage('error', 'Error liking comment')
                        })
                        .then(() => {
                            this.stopTogglePostReactionLike(inReaction.id)
                        })
                }
                else {
                    this.streamClient.reactions.delete(inLike.id)
                        .then((response) => {
                            let myReactions = this.objCopy(this?.reactions)

                            myReactions = Object.values(myReactions).map((reaction) => {
                                if (reaction.id == inReaction.id) {
                                    let { like: _, ...myChildren } = reaction.own_children
                                    reaction.own_children = myChildren

                                    if (reaction.children_counts.like && reaction.children_counts.like > 1) {
                                        --reaction.children_counts.like
                                    }
                                    else if (reaction.children_counts.like && reaction.children_counts.like == 1) {
                                        let { like: _, ...myChildrenCounts } = reaction.children_counts
                                        reaction.children_counts = myChildrenCounts
                                    }
                                }

                                return reaction
                            })

                            this.replaceStateData([
                                { reactions: myReactions }
                            ])
                        })
                        .catch((error) => {
                            this.pageMessage('error', 'Error unliking comment')
                        })
                        .then(() => {
                            this.stopTogglePostReactionLike(inReaction.id)
                        })
                }
            }
        }
    }
}

export default likeMixins