<template>
    <div class="px-1">
        <div v-if="lt && !lastPage" @click="getRecords(false)" class="flex justify-center w-full py-2 mt-3 font-bold text-orange-400 bg-white border border-orange-400 rounded cursor-pointer">
            Load more comments
        </div>
        <inline-post-reaction 
            :queryId="queryId" :replying="replying" :replyingHandle="replyingHandle"
            @unsetReplying="unsetReplying"
        />
        <template v-for="(reaction, index) in sortedReactions" :key="index">
            <post-reaction v-if="!amIBlocking(reaction.user_id)" 
                :reaction="reaction" :queryId="queryId" 
                @reply="reply"
            />
        </template>
        <write-post-reaction v-if="showWritePostReaction" />
    </div>
</template>

<script>
import PostReaction from './PostReaction'
import InlinePostReaction from './InlinePostReaction'
import WritePostReaction from './WritePostReaction'

export default {
    props: ['queryId'],
    components: {
        PostReaction,
        InlinePostReaction,
        WritePostReaction,
    },
    data() {
        return {
            gettingRecords: false,
            lastPage: false,
            lt: null,
            replying: false,
            replyingHandle: null
        }
    },
    computed: {
        sortedReactions() {
            return !this.reactions || this.objLength(this.reactions) == 0 ? [] : Object.values(this.reactions)
                .sort((a, b) => a.created_at > b.created_at ? 1 : -1)
        }
    },
    mounted() {
        this.getRecords(true)
    },
    methods: {
        async getRecords(inInitial = false) {
            if (!this.gettingRecords) {
                this.gettingRecords = true 
                const reactionData = await this.getReactions(this.lt)

                if (reactionData && reactionData.results && reactionData.results.length) {
                    this.lt = this.getLastArrayKey(reactionData.results).id
                    let myReactions = inInitial ? [] : this.objCopy(this.reactions)
                    myReactions = [...myReactions, ...reactionData.results]
                    this.lastPage = myReactions.length == this.activityToShow?.reaction_counts?.postReaction

                    this.setStateData([
                        { reactions: this.objCopy(myReactions) }
                    ])
                }
                else {
                    this.lastPage = true
                }

                this.gettingRecords = false
            }
        },
        reply(inReplyHandle) {
            this.replying = true
            this.replyingHandle = inReplyHandle
        },
        unsetReplying() {
            this.replying = false
            this.replyingHandle = null
        }
    }
}
</script>
