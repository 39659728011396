<template>
    <div class="relative overflow-hidden page-standard-height-width">
        <div class="fixed top-0 h-full" :class="{ 'left-56 desktop-width': isDesktopWidth, 'left-0 w-full': !isDesktopWidth }">
            <div class="mx-auto max-main-width h-full">
                <cached-image imageClasses="object-cover h-full w-full" :imageSrc="transformUrl(defaultHero, ['optimize', 'md', 'q80'])" />
            </div>
        </div>
        <div class="fixed top-0 h-full black-blur-3" :class="{ 'left-56 desktop-width': isDesktopWidth, 'left-0 w-full': !isDesktopWidth }">
        </div>
        <div class="fixed h-full" :class="{ 'left-56 desktop-width': isDesktopWidth, 'left-0 w-full': !isDesktopWidth }">
            <div class="mx-auto max-main-width h-full py-16">
                <div class="max-width-box">
                    <tabs 
                        :count="3" 
                    />
                    <section-heading 
                        classes="w-52 mt-6 mb-3" 
                    />
                    <pills 
                        :count="16"
                    />
                    <rectangle 
                        classes="w-full h-16 flasher-mid-bg flasher-mid-animate" :styles="{ 'margin-bottom': '1px' }" :count="12"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pills from '@/flashers/components/Pills'
import Rectangle from '@/flashers/components/Rectangle'
import SectionHeading from '@/flashers/components/SectionHeading'
import Tabs from '@/flashers/components/Tabs'

export default {
    components: {
        Pills,
        Rectangle,
        SectionHeading,
        Tabs
    }
}
</script>