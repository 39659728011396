<template>
    <div class="relative white-transparent w-full text-center">
        <div @click="clickSection()" class="w-full text-center font-bold white-transparent p-2 mt-px rounded-sm cursor-pointer">
            {{ sectionLabel }}
        </div>
        <div v-show="isOpen" class="w-full">
            <slot
            />
        </div>
        <div class="absolute top-2 right-4">
            <div @click="clickSection()" v-show="!isOpen" class="cursor-pointer px-2">
                <fa-icon icon="chevron-left" type="fas" class="h-6 w-6" />
            </div>
            <div @click="clickSection()" v-show="isOpen" class="cursor-pointer px-2">
                <fa-icon icon="chevron-down" type="fas" class="h-6 w-6" />
            </div>
        </div>
        <div v-if="sectionCounter" class="absolute left-2" style="top: 0.6rem;">
            <div :class="{ 'red-bubble-big': !sectionCounterClass, [sectionCounterClass]: sectionCounterClass }">
                <div class="whitespace-nowrap">
                    {{ sectionCounter }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'sectionCounter',
        'sectionCounterClass',
        'sectionIdentifier',
        'sectionLabel',
        'sectionOpen'
    ],
    data() {
        return {
            isOpen: false
        }
    },
    mounted() {
        this.isOpen = this.sectionOpen ?? this.isOpen
    },
    methods: {
        clickSection() {
            this.isOpen = !this.isOpen
            this.$emit('cacheSectionOpen', this.isOpen, this.sectionIdentifier)       
        }
    }
}
</script>