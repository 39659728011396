<template>
    <div class="desktop-guest-logo">
        <div class="relative">
            <router-link to="/" class="mx-2 mt-4 flex flex-row shrink-0">
                <cached-image imageClasses="block w-auto h-8" :imageSrc="bzLogo" />
                <span class="hidden h-full ml-2 text-xl font-semibold tracking-tight text-white sm:inline">Buildz</span>
                <span class="hidden h-full text-xl italic font-light tracking-tight text-white sm:inline">.pro</span>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style>
.desktop-guest-logo {
    @apply fixed text-center left-0 z-20
}
</style>