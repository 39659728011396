<template>
    <div v-if="selectedCountry" class="w-full mt-px" :class="{ 'px-2 py-3': !isDesktopWidth || forceMobile, 'white-transparent': !whiteLabel || whiteLabelDark, 'black-transparent': whiteLabel && whiteLabelLight }">
        <div class="w-full" :class="{ 'flex items-stretch': isDesktopWidth && !forceMobile }">
            <div :class="{ 'w-1/2 py-3 pl-2 pr-1': isDesktopWidth && !forceMobile, 'w-full': !isDesktopWidth || forceMobile }">
                <panel-label 
                    :fieldLabel="fieldLabel" :fieldRequired="fieldRequired && !fieldNonEdit" :forceMobile="forceMobile"
                />
            </div>
            <div :class="{ 'w-1/2 pr-2 py-3': isDesktopWidth && !forceMobile, 'w-full': !isDesktopWidth || forceMobile }">
                <div v-if="!fieldNonEdit" class="w-full relative flex items-center justify-start">
                    <div @click="clickToggleCountryCode()" class="flex items-center mr-1 px-1 border rounded-md cursor-pointer bg-white border-white" :class="{ 'py-1': isDesktopWidth, 'py-2': !isDesktopWidth }">
                        <div v-if="selectedCountry && selectedCountry.code" class="mr-1">
                            <img :src="`/images/flags/${selectedCountry.code.toLowerCase()}.svg`" class="border border-gray-300" style="min-width: 28px;" />
                        </div>
                        <div class="whitespace-nowrap edit-field-text-color text-base mr-1">
                            {{ selectedCountry.dial_code }}
                        </div>
                        <div class="text-gray-500">
                            <fa-icon icon="chevron-down" type="fas" class="h-3 w-3" />
                        </div>
                    </div>
                    <input type="number" :id="fieldId" v-model="fieldModel" @input="fieldInput()" @blur="fieldBlur()" :placeholder="fieldPlaceholder" :autocomplete="fieldAutoCompleteValue" class="form-input edit-field-text-color py-1 w-full text-center" :class="{ 'bg-white': !fieldEdited, 'bg-blue-200 border-blue-200': fieldEdited }" />
                    <div v-if="showCountrySelect" class="absolute w-full top-12 z-10 bg-white border border-gray-300">
                        <div class="p-1 border-b border-gray-300">
                            <input type="text" v-model="countryFilterText" placeholder="Search" class="form-input edit-field-text-color py-1 w-full text-center bg-white" />
                        </div>
                        <div class="overflow-y-scroll h-52 relative" :id="`${fieldCountryPrefix}_container`">
                            <div v-for="countryCode in filteredCountryData" @click="clickCountryCode(countryCode)" :id="`${fieldCountryPrefix}_${countryCode.code}`" class="flex p-1 cursor-pointer relative">
                                <div class="mr-1">
                                    <img :src="`/images/flags/${countryCode.code.toLowerCase()}.svg`" class="w-8 border border-gray-300" />
                                </div>
                                <div class="edit-field-text-color text-sm mr-1 text-left">
                                    {{ countryCode.name }}
                                </div>
                                <div class="whitespace-nowrap edit-field-text-color text-sm font-semibold">
                                    {{ countryCode.dial_code }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="w-full relative flex items-center justify-start">
                    <div class="flex items-center mr-1 px-1 py-1 border rounded-md">
                        <div class="mr-1">
                            <img :src="`/images/flags/${selectedCode}.svg`" class="border border-gray-300" style="min-width: 28px;" />
                        </div>
                        <div class="whitespace-nowrap edit-field-text-color text-base">
                            {{ selectedCountry.dial_code }}
                        </div>
                    </div>
                    <div class="relative rounded-md edit-field-text-color w-full">
                        <div class="flex items-center justify-center rounded-md" style="min-height: 34px;">
                            {{ fieldModel }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="fieldSubLabel" class="text-sm text-center px-2 pb-2">
            {{ fieldSubLabel }}
        </div>
        <slot /> 
    </div>
</template>

<script>
export default {
    props: [
        'fieldAutoComplete', 
        'fieldCountryEdited',
        'fieldCountryFormName', 
        'fieldCountryPrefix',
        'fieldCountryValue',
        'fieldEdited',
        'fieldFormName', 
        'fieldFormSubObjectName', 
        'fieldId',
        'fieldLabel', 
        'fieldNonEdit',
        'fieldPlaceholder', 
        'fieldRequired', 
        'fieldStopAutoComplete',
        'fieldSubLabel',
        'fieldType', 
        'fieldValue',
        'forceMobile'
    ],
    data() {
        return {
            countryFilterText: null,
            countryModel: null,
            fieldModel: null,
            showCountrySelect: false
        }
    },
    computed: {
        fieldAutoCompleteValue() {
            if (this.fieldAutoComplete) {
                return this.fieldAutoComplete
            }

            return this.fieldStopAutoComplete ? this.getRandomString(20) : ''
        },
        filteredCountryData() {
            if (this.countryFilterText) {
                return this.countryData.filter((myCountry) => myCountry.name.toLowerCase().includes(this.countryFilterText.toLowerCase()))
            }

            return this.countryData
        },
        selectedCode() {
            return this.selectedCountry ? this.selectedCountry.code.toLowerCase() : 'au'
        },
        selectedCountry() {
            let myCode = this.countryModel ?? this.coGetCountryCodeFromTZ()
            return this.countryData.find((myCountry) => myCountry.code == myCode) ?? "AU"
        }
    },
    watch: {
        fieldCountryValue: {
            handler(val) {
                this.countryModel = val
            }
        },
        fieldValue: {
            handler(val) {
                this.fieldModel = val
            }
        }
    },
    mounted() {
        this.fieldModel = this.fieldValue
        this.countryModel = this.selectedCountry.code
        this.fieldInput()
    },
    methods: {
        clickCountryCode(inCountryCode) {
            this.countryModel = inCountryCode.code
            this.showCountrySelect = false
            this.countryFilterText = null
            this.fieldInput()
        },
        clickToggleCountryCode() {
            this.countryFilterText = null
            this.showCountrySelect = !this.showCountrySelect

            this.$nextTick(() => {
                if (this.showCountrySelect) {
                    let myCountryElRect = this.getRect(`${this.fieldCountryPrefix}_${this.selectedCountry.code}`)
                    
                    if (myCountryElRect) {
                        let myCountryTop = myCountryElRect.top
                        let myContainerEl = this.elGet(`${this.fieldCountryPrefix}_container`)
                        let myContainerRect = this.getRect(`${this.fieldCountryPrefix}_container`)
                        myContainerEl.scrollTop = myCountryTop - myContainerRect.top
                    }
                }
            })
        },
        fieldBlur() {
            this.$emit('fieldBlur', this.fieldFormName, this.fieldModel, this.fieldCountryFormName, this.countryModel, this.fieldFormSubObjectName, this.selectedCountry)
        },
        fieldInput() {
            this.$emit('fieldInput', this.fieldFormName, this.fieldModel, this.fieldCountryFormName, this.countryModel, this.fieldFormSubObjectName, this.selectedCountry)
        }
    }
}
</script>