<template>
    <modal v-if="showNewBuildModal" :open="showNewBuildModal" class="text-white" @close="clickClose()">
        <modal-background-image
            :bgImage="defaultHero"
        />
        <div class="absolute top-0 left-0 w-full" v-if="build_types && form" :class="{ 'pb-16 px-1': windowWidth < desktopMaxWidth, 'desktop-modal-box': windowWidth >= desktopMaxWidth }">
            <div :class="{ 'desktop-modal': windowWidth >= desktopMaxWidth }">
                <div class="w-full mt-4">
                    <h3 class="section-heading mb-3 text-center">
                        {{ headingText }}
                    </h3>
                    <div>
                        <div class="text-sm font-bold leading-5 text-center mb-1">
                            Name
                        </div>
                        <div>
                            <input type="text" v-model="form.name" class="form-input text-center edit-field-text-color bg-white py-1 w-full" placeholder="My build name" />
                        </div>
                    </div>
                    <!-- <div class="mt-4">
                        <div  class="text-sm font-bold leading-5 text-center">
                            Build Visibility
                        </div>
                        <div class="round-tabs-container mt-2 px-1">
                            <div @click="form.build_visibility = 'public'" class="round-tab" :class="{ 'round-tab-off': form.build_visibility != 'public', 'round-tab-on': form.build_visibility == 'public' }">
                                Public
                            </div>
                            <div @click="form.build_visibility = 'private'" class="round-tab" :class="{ 'round-tab-off': form.build_visibility != 'private', 'round-tab-on': form.build_visibility == 'private' }">
                                Private
                            </div>
                        </div>
                    </div> -->
                    <div v-if="form.build_visibility" class="mt-4">
                        <div class="text-sm font-bold leading-5 text-center">
                            Build Category
                        </div>
                        <div class="mt-2 px-1">
                            <div class="round-tabs-container">
                                <template v-for="(category, index) in sortedCategories" :key="index">
                                    <div v-if="category.parent == 0 || isParentOpen(category)" @click="clickCategory(category)" class="round-tab" :class="{ 'round-tab-off': isNonSelectedParent(category), 'round-tab-on': isSelectedParent(category), 'round-tab-child-off': isNonSelectedChild(category), 'round-tab-child-on': isSelectedChild(category) }">
                                        {{ category.label }}
                                    </div>
                                </template>
                                <div @click="clickCustom()" class="round-tab" :class="{ 'round-tab-off': form.build_type_id != 0, 'round-tab-on': form.build_type_id == 0 }">
                                    Custom Build Type
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="form.build_type_id" class="mt-4">
                        <div  class="text-sm font-bold leading-5 text-center">
                            How do you want to start your Build Lists?
                        </div>
                        <div class="round-tabs-container mb-4 mt-2">
                            <div @click="newList.listType = 'blank'" class="round-tab" :class="{ 'round-tab-off': newList.listType != 'blank', 'round-tab-on': newList.listType == 'blank' }">
                                Create a Build List from scratch
                            </div>
                            <div v-if="buildAvailableListTemplates && buildAvailableListTemplates.length" @click="newList.listType = 'template'" class="round-tab" :class="{ 'round-tab-off': newList.listType != 'template', 'round-tab-on': newList.listType == 'template' }">
                                Use a BZ Build Template
                            </div>
                            <div @click="newList.listType = 'import'" class="round-tab" :class="{ 'round-tab-off': newList.listType != 'import', 'round-tab-on': newList.listType == 'import' }">
                                Import a Build List from your notes
                            </div>
                        </div>
                        <div v-if="newList.listType == 'blank'" class="white-transparent px-1 py-2">
                            <div>
                                <div class="text-sm font-bold leading-5 text-center mb-1">
                                    Build List Name
                                </div>
                                <div>
                                    <input ref="listName" type="text" maxlength="50" v-model="newList.name" @keyup.enter="$event.target.blur()" required class="form-input text-center edit-field-text-color bg-white py-1 w-full" placeholder="Name of your list" />
                                </div>
                            </div>
                            <div class="mt-4">
                                <div class="text-sm font-bold leading-5 text-center w-full">
                                    Build List Type
                                </div>
                                <div class="round-tabs-container my-2 px-1">
                                    <div v-for="(type, index) in buildAvailableListTypes" @click="newList.type = type" class="round-tab" :class="{ 'round-tab-off': newList.type != type, 'round-tab-on': newList.type == type }">
                                        {{ type }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="newList.listType == 'template'" class="white-transparent px-1 py-2">
                            <div>
                                <div class="text-sm font-bold leading-5 text-center w-full">
                                    Select Build Template
                                </div>
                                <div class="round-tabs-container my-2 px-1">
                                    <div v-for="(template, index) in buildAvailableListTemplates" @click="newList.template_id = template.id" class="round-tab" :class="{ 'round-tab-off': newList.template_id != template.id, 'round-tab-on': newList.template_id == template.id }">
                                        {{ template.name }}
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="pt-2 mt-4 text-xs text-left">
                                <template v-for="(template, index) in buildAvailableListTemplates" :key="index">
                                    <div v-if="newList.template_id == template.id">
                                        <div class="pb-2 font-medium">Template ({{ template.name }}) Preview:</div>
                                        <div v-for="(list, listIndex) in template.lists" :key="listIndex">
                                            <span>{{ list.name }} ({{ list.type }})</span>
                                            <div v-for="(category, catIndex) in list.categories" :key="catIndex">
                                                <span class="pl-4">- {{ category.name }}</span>
                                                <div v-for="(mod, modIndex) in category.mods" :key="modIndex">
                                                    <span class="pl-8">- {{ mod.type }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div> -->
                        </div>
                        <div v-if="newList.listType == 'import'" class="white-transparent px-1 py-2">
                            <div class="text-sm font-bold leading-5 text-center">
                                You will be prompted to import your list after your build is created.
                            </div>
                        </div>
                    </div>
                    <div v-show="showCustomBuildType" class="mt-4">
                        <div class="text-sm font-bold leading-5 text-center mb-1">
                            Custom Build Type Name
                        </div>
                        <div class="flex items-center">
                            <input type="text" v-model="form.build_type_name" class="form-input text-center edit-field-text-color bg-white py-1 w-full" placeholder="Custom build type..." />
                            <div @click="form.build_type_id = 1; showCustomBuildType = false;">
                                <fa-icon icon="trash-can" type="fas" class="cursor-pointer h-5 w-5 ml-1" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-6 sm:flex sm:flex-row-reverse">
                    <div v-if="showActivate" @click="goToMemberPortal()" class="standard-button-base mb-2">
                        Become a Buildz.pro member
                    </div>
                    <div v-if="isFormOkay" @click="createBuild()" class="standard-button-base mb-2">
                        Create
                    </div>
                    <div @click="clickClose()" class="alt-button-base sm:mr-2 mb-2">
                        {{ cancelText }}
                    </div>
                </div>
            </div>
        </div>
        <import-mod-file-modal v-if="showImportModFileModal && newBuild"
            :propLists="newBuild.lists" :buildImage="defaultHero" :build="newBuild" :showPopup="showImportModFileModal"
            @closePopup="closeImport" @updateBuild="updateBuild"
        />
    </modal>
</template>

<script>
import ImportModFileModal from '@/pages/build/tabs/mods/edit/modals/ImportModFileModal'
import Modal from '@/shared/Modal'
import ModalBackgroundImage from '@/shared/ModalBackgroundImage'

export default {
    components: {
        ImportModFileModal,
        Modal,
        ModalBackgroundImage
    },
    props: ['build_types'],
    data() {
        return {
            buildAvailableListTypes: ['Active', 'Wish List', 'Archive'],
            categoriesData: [],
            form: {
                build_type_id: null,
                build_type_name: null,
                build_visibility: 'public',
                name: null,
                sub_categories: []
            },
            newBuild: null,
            newList: {
                listType: 'template',
                name: 'Mod List',
                template_id: 1,
                type: 'Active'
            },
            postingNewBuild: false,
            showActivate: false,
            showCustomBuildType: false,
            showImportModFileModal: false,
            tag: ''
        }
    },
    computed: {
        buildAvailableListTemplates() {
            return Object.values(this.buildTemplates).filter((buildTemplate) => buildTemplate.build_type_id == this.form.build_type_id)
        },
        buildType() {
            return this.form.build_type_id
        },
        buildVisibility() {
            return this.form.build_visibility
        },
        cancelText() {
            return this.hasLoggedIn ? 'Cancel' : 'No Thanks, I\'d like to look around first'
        },
        headingText() {
            return this.hasLoggedIn ? 'Create a Build' : 'Lets create your first Build'
        },
        isFormOkay() {
            return this.form && this.form.name && this.form.build_type_id && this.form.build_visibility
        },
        sortedCategories() {
            return this.categoriesData.sort((a, b) => a.parent > b.parent ? 1 : (a.parent < b.parent ? 0 : (a.label < b.label ? 1 : 0)))
        }
    },
    watch: {
        buildType: {
            immediate: true,
            handler(val) {
                this.newList.listType = this.form.build_type_id == this.build_types[0].id ? 'template' : 'blank'
            }
        },
        buildTypes: {
            immediate: true,
            handler(val) {
                if (this.categoriesData.length == 0) {
                    this.buildCategoriesData()
                }
            }
        },
        showNewBuildModal: {
            handler(val) {
                this.form.build_type_id = this?.build_types[0]?.id
            }
        }
    },
    methods: {
        buildCategoriesData() {
            if (this.categoriesData.length == 0) {
                Object.values(this.buildTypes).forEach((buildType) => {
                    let myRecord = {
                        parent: 0,
                        label: buildType.label,
                        id: buildType.id
                    }

                    this.categoriesData.push(myRecord)

                    buildType.sub_categories.forEach((subCat) => {
                        let myRecord = {
                            parent: buildType.id,
                            label: subCat,
                            id: null
                        }

                        this.categoriesData.push(myRecord)
                    })
                })
            }
        },
        clickCategory(inCategory) {
            if (inCategory.parent == 0) {
                this.form.sub_categories = null
                this.form.sub_categories = []

                if (this.form.build_type_id == inCategory.id) {
                    this.form.build_type_id = null
                }
                else {
                    this.form.build_type_id = inCategory.id
                }
            }
            else {
                if (this.form.sub_categories.includes(inCategory.label)) {
                    let mySubCats = this.objCopy(this.form.sub_categories)
                    mySubCats = mySubCats.filter((subCat) => subCat != inCategory.label)
                    this.form.sub_categories = this.objCopy(mySubCats)
                }
                else {
                    this.form.sub_categories.push(inCategory.label)
                }
            }
        },
        clickClose() {
            console.log('click close')
            this.closeNewBuildModal()
        },
        clickCustom() {
            this.showCustomBuildType = true
            this.form.sub_categories = null
            this.form.sub_categories = []
            this.form.build_type_id = 0
        },
        closeImport() {
            this.routeTo(`/build/${this.newBuild.handle}`)
            this.showImportModFileModal = false
            this.closeNewBuildModal()
            this.postingNewBuild = false
            this.closeSpinner()
        },
        createBuild() {
            if (!this.serverConnected) {
                alert('You cant create a build when you are offline')
            }
            else if (!this.postingNewBuild) {
                this.postingNewBuild = true
                this.openSpinner()

                if (this.showCustomBuildType) {
                    this.form.sub_categories = []
                }

                this.appsFlyerEvent(
                    {
                        eventName: 'Create Build',
                        eventValue: {
                            name: this.form.name
                        }
                    }
                )

                buildzAxios?.post(this.getRoute('buildCreate'), this.form)
                    .then((response) => {
                        if (response.status == 200 && response.data.build) {
                            if (response.data.newUserBuild) {
                                let myUser = this.objCopy(this.loggedUser)
                                myUser.builds.push(this.objCopy(response.data.newUserBuild))

                                this.replaceStateData([
                                    { loggedUser: myUser }
                                ])
                            }

                            if (this.newList.listType == 'blank') {
                                this.setStateData([
                                    { flasherPage: 'build' }
                                ])

                                this.createList(response.data.build)
                            }
                            else if (this.newList.listType == 'template') {
                                this.setStateData([
                                    { flasherPage: 'build' }
                                ])
                                
                                this.createListFromTemplate(response.data.build)
                            }
                            else if (this.newList.listType == 'import') {
                                this.closeSpinner()
                                this.newBuild = this.objCopy(response.data.build)
                                this.showImportModFileModal = true
                            }
                        }
                        else {
                            this.pageMessage('error', 'Error creating build')
                            this.postingNewBuild = false
                            this.closeSpinner()
                        }
                    })
                    .catch((error) => {
                        this.postingNewBuild = false
                        this.closeSpinner()
                        console.log(error)
                        this.pageMessage('error', 'Error creating build')
                    })
            }
        },
        async createList(inBuild) {
            let myLists = []

            myLists[0] = {
                'name' : this.newList.name && this.newList.name.length ? this.newList.name : 'Mod List',
                'type' : this.newList.type,
                'categories' : []
            }

            this.saveStart()

            buildzAxios?.put(this.getRoute('buildListUpdate', inBuild.id), 
                { 
                    lists : myLists 
                })
                .then((response) => {
                    this.saveDone()

                    if (response.status == 200 && response.data.build) {
                        this.updateBuild(response.data.build)
                    }
                    else {
                        this.pageMessage('error', 'Error posting data')
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.pageMessage('error', 'Error posting data')
                    this.saveDone()
                })
        },
        createListFromTemplate(inBuild) {
            this.saveStart()
            
            buildzAxios?.post(this.getRoute('buildListAddTemplate', inBuild.id), 
                {
                    'template_id' : this.newList.template_id 
                })
                .then((response) => {
                    this.saveDone()

                    if (response.status == 200 && response.data.build) {
                        this.updateBuild(response.data.build)
                    }
                    else {
                        this.pageMessage('error', 'Error posting data')
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.pageMessage('error', 'Error posting data')
                    this.saveDone()
                })
        },
        goToMemberPortal() {
            this.clickClose()
            this.routeTo('/hub/status')
        },
        isSubCatSelected(inSubCat) {
            return this.form.sub_categories.includes(inSubCat)
        },
        isNonSelectedChild(inCategory) {
            return inCategory.parent != 0 && !this.form.sub_categories.includes(inCategory.label)
        },
        isNonSelectedParent(inCategory) {
            return inCategory.parent == 0 && this.form.build_type_id != inCategory.id
        },
        isParentOpen(inSubCat) {
            return this.form.build_type_id == inSubCat.parent
        },
        isSelectedChild(inCategory) {
            return inCategory.parent != 0 && this.form.sub_categories.includes(inCategory.label)
        },
        isSelectedParent(inCategory) {
            return inCategory.parent == 0 && this.form.build_type_id == inCategory.id
        },
        updateBuild(inBuild) {
            let myStoredBuildz = this.objCopy(this.storedBuildz)
            myStoredBuildz[inBuild.handle] = this.objCopy(inBuild)

            this.setStateData([
                { storedBuildz: myStoredBuildz }
            ])

            this.setStorage(`build_${inBuild.handle}`, JSON.stringify(inBuild))
            this.routeTo(`/build/${inBuild.handle}`)
            this.closeNewBuildModal()
            this.postingNewBuild = false
            this.closeSpinner()
        }
    }
}
</script>