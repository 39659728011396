<template>
    <div class="absolute top-0 left-0 w-full h-full flex items-center justify-center black-less-transparent">
        <div class="border border-gray-800 rounded-md bg-white text-gray-800 relative pb-20" style="width: 95%; max-width: 500px;" :style="rateType == 'price' || rateType == 'weight' ? { 'height': '280px' } : { 'height': '200px' }">
            <div class="w-full text-center">
                <div class="bg-orange-400 py-4 px-12 font-bold rounded-t-md border-b border-gray-800 relative">
                    <div class="w-full">
                        {{ headingText }}
                    </div>
                    <div @click="clickCancelNewZoneRate()" class="absolute top-1 right-1 font-normal cursor-pointer text-gray-800">
                        <fa-icon icon="xmark" type="fas" class="h-5 w-5" />
                    </div>
                </div>
                <div class="w-full p-1 bg-gray-100 overflow-scroll" :style="rateType == 'price' || rateType == 'weight' ? { 'height': '173px' } : { 'height': '93px' }">
                    <div v-if="currentSlide == 1" class="w-full">
                        <select-panel
                            :fieldData="sortedShippingRateLabels" fieldFormName="customRateName" fieldLabel="Rate Label" fieldLabelClasses="text-gray-800" 
                            :fieldValue="form.customRateName" :forceMobile="true" :hideLabel="true" :isSingleValue="true"
                            @fieldChange="updateFormValue"
                        >
                            <validation-message v-if="validating && !form.customRateName" 
                                message="Shipping rate label must be selected"
                            />
                        </select-panel>
                    </div>
                    <div v-if="currentSlide == 2" class="w-full">
                        <input-panel 
                            fieldFormName="firstUnitPrice" :fieldPlaceholder="getCurrencySymbol(sellerCurrency)" :fieldRequired="true" fieldType="number" :fieldValue="form.firstUnitPrice" 
                            :forceMobile="true" :hideLabel="true"
                            @fieldInput="updateFormValue"
                        >
                            <validation-message v-if="validating && !form.firstUnitPrice" 
                                message="Price must be entered"
                            />
                        </input-panel>
                    </div>
                    <div v-if="currentSlide == 3" class="w-full">
                        <input-panel 
                            fieldFormName="additionalUnitPrice" :fieldPlaceholder="getCurrencySymbol(sellerCurrency)" :fieldRequired="true" fieldType="number" :fieldValue="form.additionalUnitPrice" 
                            :hideLabel="true" :forceMobile="true"
                            @fieldInput="updateFormValue"
                        >
                            <validation-message v-if="validating && !form.additionalUnitPrice" 
                                message="Price must be entered"
                            />
                        </input-panel>
                    </div>
                    <div v-if="currentSlide == 4" class="w-full">
                        <div class="font-semibold py-2">
                            {{ form.customRateName }}
                        </div>
                        <div class="flex items-center justify-between py-2">
                            <div class="w-1/2">
                                <span class="font-semibold">First Unit:</span> {{ displayPrice(sellerCurrency, form.firstUnitPrice) }}
                            </div>
                            <div class="w-1/2">
                                <span class="font-semibold">Additional Units:</span> {{ displayPrice(sellerCurrency, form.additionalUnitPrice) }}
                            </div>
                        </div>
                    </div>
                    <div v-if="currentSlide == 5" class="w-full">
                        <input-panel 
                            fieldFormName="price" :fieldPlaceholder="getCurrencySymbol(sellerCurrency)" :fieldRequired="true" fieldType="number" :fieldValue="form.price" 
                            :forceMobile="true" :hideLabel="true" 
                            @fieldInput="updateFormValue"
                        >
                            <validation-message v-if="validating && !form.price" 
                                message="Price must be entered"
                            />
                        </input-panel>
                    </div>
                    <div v-if="currentSlide == 6" class="w-full">
                        <div class="w-full flex items-center justify-between p-2">
                            <div class="font-semibold">
                                {{ form.customRateName }}
                            </div>
                            <div class="font-semibold">
                                {{ displayPrice(sellerCurrency, form.price) }}
                            </div>
                        </div>
                    </div>
                    <div v-if="currentSlide == 7" id="priceRatesPanel" class="w-full">
                        <div v-for="(rate, index) in sortedPriceRates" class="mt-px bg-orange-200">
                            <div v-if="editingPriceRate == null || editingPriceRate != rate.id" class="flex items-center justify-between pr-1 pl-2 py-2">
                                <div class="flex items-center justify-between w-3/4">
                                    <div class="w-1/2">
                                        <span class="text-xs font-bold">MIN</span> {{ getDisplayMinPrice(rate.minPrice) }}
                                    </div>
                                    <div class="w-1/2">
                                        <span class="text-xs font-bold">MAX</span> {{ getDisplayMaxPrice(rate.maxPrice) }}
                                    </div>
                                </div>
                                <div v-if="editingPriceRate == null" class="flex items-center justify-end text-gray-600 w-1/4">
                                    <div @click="clickDeletePriceRate(rate, index)" v-if="rate.id != 1" class="cursor-pointer mr-2">
                                        <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickEditPriceRate(rate, index)" class="cursor-pointer mr-1">
                                        <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="editingPriceRate != null && editingPriceRate == rate.id" class="flex items-center justify-between py-1">
                                <div class="flex items-center justify-between w-3/4">
                                    <div class="w-1/2">
                                        <span class="text-xs font-bold">MIN</span> {{ getDisplayMinPrice(rate.minPrice) }}
                                    </div>
                                    <div class="w-1/2 flex items-center">
                                        <div class="whitespace-nowrap">
                                            <span class="text-xs font-bold">MAX</span>
                                        </div>
                                        <input-panel 
                                            fieldFormName="maxPrice" :fieldPlaceholder="`${getCurrencySymbol(sellerCurrency)}`" :fieldRequired="true" fieldType="number" :fieldValue="editPriceRateForm.maxPrice" 
                                            :forceMobile="true" :hideLabel="true" :noVerticalPadding="true"
                                            @fieldInput="updateEditPriceRateFormValue" 
                                        >
                                        </input-panel>
                                    </div>
                                </div>
                                <div class="flex items-center justify-end w-1/4">
                                    <div class="flex items-center justify-center text-gray-600">
                                        <div @click="clickSaveEditPriceRate()" class="cursor-pointer">
                                            <fa-icon icon="floppy-disk" type="fas" class="h-5 w-5" />
                                        </div>
                                        <div @click="clickCancelEditPriceRate()" class="cursor-pointer mx-2">
                                            <fa-icon icon="ban" type="fas" class="h-5 w-5" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentSlide == 8" class="w-full">
                        <div v-for="(rate, index) in sortedPriceRates" class="mt-px bg-orange-200">
                            <div class="flex items-center justify-between px-2 py-1">
                                <div class="w-1/3">
                                    <span class="text-xs font-bold">MIN</span> {{ getDisplayMinPrice(rate.minPrice) }}
                                </div>
                                <div class="w-1/3">
                                    <span class="text-xs font-bold">MAX</span> {{ getDisplayMaxPrice(rate.maxPrice) }}
                                </div>
                                <div class="w-1/3">
                                    <input-panel 
                                        fieldFormName="price" :fieldFormSubObjectName="rate.id" :fieldPlaceholder="`Price ${getCurrencySymbol(sellerCurrency)}`" :fieldRequired="true" fieldType="number" :fieldValue="rate.price" 
                                        :forceMobile="true" :hideLabel="true" :noVerticalPadding="true"
                                        @fieldInput="updatePriceRateFormValue" 
                                    >
                                    </input-panel>
                                </div>
                            </div>
                            <validation-message v-if="validating && !rate.price" 
                                message="Price must be entered"
                            />
                        </div>
                    </div>
                    <div v-if="currentSlide == 9" class="w-full">
                        <div class="w-full flex items-center justify-between p-2">
                            <div class="font-semibold">
                                {{ form.customRateName }}
                            </div>
                        </div>
                        <div v-for="(rate, index) in sortedPriceRates" class="mt-px bg-orange-200">
                            <div class="flex items-center justify-between px-2 py-1">
                                <div class="w-1/3">
                                    <span class="text-xs font-bold">MIN</span> {{ getDisplayMinPrice(rate.minPrice) }}
                                </div>
                                <div class="w-1/3">
                                    <span class="text-xs font-bold">MAX</span> {{ getDisplayMaxPrice(rate.maxPrice) }}
                                </div>
                                <div class="w-1/3">
                                    {{ displayPrice(sellerCurrency, rate.price) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentSlide == 11" id="weightRatesPanel" class="w-full">
                        <div v-for="(rate, index) in sortedWeightRates" class="mt-px bg-orange-200">
                            <div v-if="editingWeightRate == null || editingWeightRate != rate.id" class="flex items-center justify-between pr-1 pl-2 py-2">
                                <div class="flex items-center justify-between w-3/4">
                                    <div class="w-1/2">
                                        <span class="text-xs font-bold">MIN</span> {{ getDisplayMinWeight(rate.minWeight) }}
                                    </div>
                                    <div class="w-1/2">
                                        <span class="text-xs font-bold">MAX</span> {{ getDisplayMaxWeight(rate.maxWeight) }}
                                    </div>
                                </div>
                                <div v-if="editingWeightRate == null" class="flex items-center justify-end text-gray-600 w-1/4">
                                    <div @click="clickDeleteWeightRate(rate, index)" v-if="rate.id != 1" class="cursor-pointer mr-2">
                                        <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                    </div>
                                    <div @click="clickEditWeightRate(rate, index)" class="cursor-pointer mr-1">
                                        <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="editingWeightRate != null && editingWeightRate == rate.id" class="flex items-center justify-between py-1">
                                <div class="flex items-center justify-between w-3/4">
                                    <div class="w-1/2">
                                        <span class="text-xs font-bold">MIN</span> {{ getDisplayMinWeight(rate.minWeight) }}
                                    </div>
                                    <div class="w-1/2 flex items-center">
                                        <div class="whitespace-nowrap">
                                            <span class="text-xs font-bold">MAX</span>
                                        </div>
                                        <input-panel 
                                            fieldFormName="maxWeight" fieldPlaceholder="kg" :fieldRequired="true" fieldType="number" :fieldValue="editWeightRateForm.maxWeight" 
                                            :forceMobile="true" :hideLabel="true" :noVerticalPadding="true"
                                            @fieldInput="updateEditWeightRateFormValue" 
                                        >
                                        </input-panel>
                                    </div>
                                </div>
                                <div class="flex items-center justify-end w-1/4">
                                    <div class="flex items-center justify-center text-gray-600">
                                        <div @click="clickSaveEditWeightRate()" class="cursor-pointer">
                                            <fa-icon icon="floppy-disk" type="fas" class="h-5 w-5" />
                                        </div>
                                        <div @click="clickCancelEditWeightRate()" class="cursor-pointer mx-2">
                                            <fa-icon icon="ban" type="fas" class="h-5 w-5" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentSlide == 12" class="w-full">
                        <div v-for="(rate, index) in sortedWeightRates" class="mt-px bg-orange-200">
                            <div class="flex items-center justify-between px-2 py-1">
                                <div class="w-1/3">
                                    <span class="text-xs font-bold">MIN</span> {{ getDisplayMinWeight(rate.minWeight) }}
                                </div>
                                <div class="w-1/3">
                                    <span class="text-xs font-bold">MAX</span> {{ getDisplayMaxWeight(rate.maxWeight) }}
                                </div>
                                <div class="w-1/3">
                                    <input-panel 
                                        fieldFormName="price" :fieldFormSubObjectName="rate.id" :fieldNude="true" :fieldPlaceholder="`Price ${getCurrencySymbol(sellerCurrency)}`" :fieldRequired="true" fieldType="number" :fieldValue="rate.price" 
                                        :forceMobile="true" :hideLabel="true" :noVerticalPadding="true"
                                        @fieldInput="updateWeightRateFormValue" 
                                    >
                                    </input-panel>
                                </div>
                            </div>
                            <validation-message v-if="validating && !rate.price" 
                                message="Price must be entered"
                            />
                        </div>
                    </div>
                    <div v-if="currentSlide == 13" class="w-full">
                        <div class="w-full flex items-center justify-between p-2">
                            <div class="font-semibold">
                                {{ form.customRateName }}
                            </div>
                        </div>
                        <div v-for="(rate, index) in sortedWeightRates" class="mt-px bg-orange-200">
                            <div class="flex items-center justify-between px-2 py-1">
                                <div class="w-1/3">
                                    <span class="text-xs font-bold">MIN</span> {{ getDisplayMinWeight(rate.minWeight) }}
                                </div>
                                <div class="w-1/3">
                                    <span class="text-xs font-bold">MAX</span> {{ getDisplayMaxWeight(rate.maxWeight) }}
                                </div>
                                <div class="w-1/3">
                                    {{ displayPrice(sellerCurrency, rate.price) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="absolute left-0 bottom-0 w-full flex items-center bg-gray-100 border-t border-gray-800 py-2 h-12 rounded-b-md">
                <div class="w-1/2">
                    <div v-if="!hidePrevious && !editingPriceRate" @click="previousSlide()" class="secondary-button-base mx-2">
                        Back
                    </div>
                </div>
                <div class="w-1/2">
                    <div v-if="!hideNext && !editingPriceRate" @click="nextSlide()" class="standard-button-base mx-2">
                        Continue
                    </div>
                    <div v-if="!hideSave && !editingPriceRate" @click="clickSaveNewZoneRate()" class="standard-button-base mx-2">
                        Yes, Save shipping rate
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'addingRateZone',
        'editingRate',
        'editingRateZone',
        'rateType',
        'sellerCurrency',
        'shippingRateLabels'
    ],
    data() {
        return {
            currentSlide: 1,
            editingPriceRate: null,
            editPriceIndex: null,
            editPriceRateForm: {
                maxPrice: null
            },
            editingWeightRate: null,
            editWeightIndex: null,
            editWeightRateForm: {
                maxWeight: null
            },
            form: {
                additionalUnitPrice: null,
                basedOn: null,
                customRateName: null,
                firstUnitPrice: null,
                freeShipping: false,
                hasConditions: false,
                id: null,
                maxPrice: null,
                maxWeight: null,
                minPrice: null,
                minWeight: null,
                price: null
            },
            lastPriceRateId: 1,
            lastWeightRateId: 1,
            newLabel: null,
            priceRates: [],
            validating: false,
            weightRates: [],
        }
    },
    computed: {
        alreadyLabels() {
            let myLabels = []

            if (this?.addingRateZone?.rates ?? false) {
                this.addingRateZone.rates.forEach((myRate) => {
                    if (myRate.basedOn == this.rateType || (!myRate.basedOn && this.rateType == 'general')) {
                        myLabels.push(myRate.customRateName)
                    }
                })
            }

            return myLabels
        },
        headingText() {
            return this.slides[this.currentSlide].heading
        },
        hideNext() {
            return !this.slides[this.currentSlide].next
        },
        hidePrevious() {
            return !this.slides[this.currentSlide].previous || this.editingRate && this.currentSlide == this.slide1Next
        },
        hideSave() {
            return !this.slides[this.currentSlide].save
        },
        slides() {
            return {
                1: {
                    heading: "Select a label for your shipping rate",
                    next: this.slide1Next,
                    previous: false,
                    save: false,
                    validation: Boolean(this.form.customRateName)
                },
                2: {
                    heading: "Enter the shipping price for the first unit",
                    next: 3,
                    previous: 1,
                    save: false,
                    validation: Boolean(this.form.firstUnitPrice)
                },
                3: {
                    heading: "Enter the shipping price for additional units",
                    next: 4,
                    previous: 2,
                    save: false,
                    validation: Boolean(this.form.additionalUnitPrice)
                },
                4: {
                    heading: "Is this correct?",
                    next: false,
                    previous: 3,
                    save: true,
                    validation: true
                },
                5: {
                    heading: "Enter the shipping price",
                    next: 6,
                    previous: 1,
                    save: false,
                    validation: Boolean(this.form.price)
                },
                6: {
                    heading: "Is this correct?",
                    next: false,
                    previous: 5,
                    save: true,
                    validation: true
                },
                7: {
                    heading: "Set your price break ranges",
                    next: 8,
                    previous: 1,
                    save: false,
                    validation: true
                },
                8: {
                    heading: "Set the shipping prices for your ranges",
                    next: 9,
                    previous: 7,
                    save: false,
                    validation: this.checkPriceRatePrices()
                },
                9: {
                    heading: "Is this correct?",
                    next: false,
                    previous: 8,
                    save: true,
                    validation: true
                },
                11: {
                    heading: "Set your weight break ranges",
                    next: 12,
                    previous: 1,
                    save: false,
                    validation: true
                },
                12: {
                    heading: "Set the shipping prices for your ranges",
                    next: 13,
                    previous: 11,
                    save: false,
                    validation: this.checkWeightRateWeights()
                },
                13: {
                    heading: "Is this correct?",
                    next: false,
                    previous: 13,
                    save: true,
                    validation: true
                }
            }
        },
        slide1Next() {
            if (this.rateType == 'unit') {
                return 2
            }
            else if (this.rateType == 'general') {
                return 5
            }
            else if (this.rateType == 'price') {
                return 7
            }
            else if (this.rateType == 'weight') {
                return 11
            }
        },
        sortedPriceRates() {
            return this.priceRates
                .sort((a, b) => a.minPrice < b.minPrice ? -1 : 1)
        },
        sortedShippingRateLabels() {
            return this.shippingRateLabels
                .filter((myLabel) => !this.alreadyLabels.includes(myLabel))
                .sort((a, b) => a < b ? -1 : 1)
        },
        sortedWeightRates() {
            return this.weightRates
                .sort((a, b) => a.minWeight < b.minWeight ? -1 : 1)
        }
    },
    mounted() {
        if (this.editingRate) {
            this.form.customRateName = this.editingRate.customRateName
            this.form.price = this.editingRate.price
            this.form.firstUnitPrice = this.editingRate.firstUnitPrice
            this.form.additionalUnitPrice = this.editingRate.additionalUnitPrice
            this.currentSlide = this.slide1Next

            if (this.rateType == 'price') {
                this.lastPriceRateId = this.editingRateZone.rateId

                this.priceRates = this.editingRateZone.rates
                    .filter((myRate) => myRate.basedOn == this.editingRate.basedOn && myRate.customRateName == this.editingRate.customRateName)
            }
            else if (this.rateType == 'weight') {
                this.lastWeightRateId = this.editingRateZone.rateId

                this.weightRates = this.editingRateZone.rates
                    .filter((myRate) => myRate.basedOn == this.editingRate.basedOn && myRate.customRateName == this.editingRate.customRateName)
            }
        }
        else {
            if (this.rateType == 'price') {
                this.priceRates = [
                    {
                        id: 1,
                        minPrice: null,
                        maxPrice: null,
                        price: null
                    }
                ]
            }
            else if (this.rateType == 'weight') {
                this.weightRates = [
                    {
                        id: 1,
                        minWeight: null,
                        maxWeight: null,
                        price: null
                    }
                ]
            }
        }

        if (this.rateType != 'general') {
            this.form.basedOn = this.rateType
            this.form.hasConditions = true
        }
    },
    methods: {
        checkPriceRatePrices() {
            let myOkay = true

            if (this.priceRates) {
                this.priceRates.forEach((priceRate) => {
                    if (!priceRate.price) {
                        myOkay = false
                    }
                })
            }

            return myOkay
        },
        checkWeightRateWeights() {
            let myOkay = true

            if (this.weightRates) {
                this.weightRates.forEach((weightRate) => {
                    if (!weightRate.price) {
                        myOkay = false
                    }
                })
            }

            return myOkay
        },
        clickCancelEditPriceRate() {
            this.editingPriceRate = null
            this.editPriceRateForm.maxPrice = null
        },
        clickCancelEditWeightRate() {
            this.editingWeightRate = null
            this.editWeightRateForm.maxWeight = null
        },
        clickCancelNewZoneRate() {
            this.$emit('clickCancelNewZoneRate')
        },
        clickDeletePriceRate(inRate, inIndex) {
            if (confirm('You wish to delete this price range?')) {
                let myPriceRate = this.priceRates.find((priceRate) => priceRate.id == inRate.id)
                let arrayLength = this.sortedPriceRates.length
                let nextLine = null

                if (inIndex + 1 < arrayLength) {
                    nextLine = this.sortedPriceRates[inIndex + 1]
                    nextLine = this.priceRates.find((priceRate) => priceRate.id == nextLine.id)
                }

                if (nextLine) {
                    nextLine.minPrice = myPriceRate.minPrice
                }
                else {
                    let prevLine = this.sortedPriceRates[inIndex - 1]
                    prevLine = this.priceRates.find((priceRate) => priceRate.id == prevLine.id)
                    prevLine.maxPrice = null
                }

                let myPriceRates = []
                
                if (this.priceRates) {
                    this.priceRates.forEach((priceRate) => {
                        if (priceRate.id != inRate.id) {
                            myPriceRates.push(priceRate)
                        }
                    })
                }

                this.priceRates = this.objCopy(myPriceRates)
            }
        },
        clickDeleteWeightRate(inRate, inIndex) {
            if (confirm('You wish to delete this weight range?')) {
                let myWeightRate = this.weightRates.find((weightRate) => weightRate.id == inRate.id)
                let arrayLength = this.sortedWeightRates.length
                let nextLine = null

                if (inIndex + 1 < arrayLength) {
                    nextLine = this.sortedWeightRates[inIndex + 1]
                    nextLine = this.weightRates.find((weightRate) => weightRate.id == nextLine.id)
                }

                if (nextLine) {
                    nextLine.minWeight = myWeightRate.minWeight
                }
                else {
                    let prevLine = this.sortedWeightRates[inIndex - 1]
                    prevLine = this.weightRates.find((weightRate) => weightRate.id == prevLine.id)
                    prevLine.maxWeight = null
                }

                let myWeightRates = []
                
                if (this.weightRates) {
                    this.weightRates.forEach((weightRate) => {
                        if (weightRate.id != inRate.id) {
                            myWeightRates.push(weightRate)
                        }
                    })
                }

                this.weightRates = this.objCopy(myWeightRates)
            }
        },
        clickEditPriceRate(inRate, inIndex) {
            this.editingPriceRate = inRate.id
            this.editPriceRateForm.maxPrice = inRate.maxPrice
            this.editPriceIndex = inIndex
        },
        clickEditWeightRate(inRate, inIndex) {
            this.editingWeightRate = inRate.id
            this.editWeightRateForm.maxWeight = inRate.maxWeight
            this.editWeightIndex = inIndex
        },
        clickSaveEditPriceRate() {
            let myPriceRate = this.priceRates.find((priceRate) => priceRate.id == this.editingPriceRate)
            let myFloat = parseFloat(this.editPriceRateForm.maxPrice)
            let arrayLength = this.sortedPriceRates.length
            let nextLine = null

            if (this.editPriceIndex + 1 < arrayLength) {
                nextLine = this.sortedPriceRates[this.editPriceIndex + 1]
                nextLine = this.priceRates.find((priceRate) => priceRate.id == nextLine.id)
            }

            let allOkay = true

            if (nextLine && (!this.editPriceRateForm.maxPrice || (nextLine.maxPrice && this.editPriceRateForm.maxPrice >= nextLine.maxPrice))) {
                allOkay = false
                this.editPriceRateForm.maxPrice = nextLine.minPrice
                alert("Invalid maximum price")
            }
            else if (this.editPriceRateForm.maxPrice != null && this.editPriceRateForm.maxPrice != "" && myFloat == 0) {
                allOkay = false
                alert("The maximum price can't be 0")
            }
            else if (myFloat > 0 && myFloat <= myPriceRate.minPrice) {
                allOkay = false
                alert("The maximum price must be greater than the minimum price")
            }

            if (allOkay) {
                myPriceRate.maxPrice = this.editPriceRateForm.maxPrice
                
                if (nextLine) {
                    nextLine.minPrice = this.editPriceRateForm.maxPrice
                }
                else if (!nextLine && this.editPriceRateForm.maxPrice) {
                    this.lastPriceRateId = this.lastPriceRateId + 1

                    let myNewLine = {
                        id: this.lastPriceRateId,
                        minPrice: this.editPriceRateForm.maxPrice,
                        maxPrice: null,
                        price: null
                    }

                    this.priceRates.push(this.objCopy(myNewLine))

                    this.$nextTick(() => {
                        this.elGet('priceRatesPanel').scrollTop = this.elGet('priceRatesPanel').scrollHeight
                    })
                }

                this.editPriceRateForm.maxPrice = null
                this.editingPriceRate = null
                this.editPriceIndex = null
            }
        },
        clickSaveEditWeightRate() {
            let myWeightRate = this.weightRates.find((weightRate) => weightRate.id == this.editingWeightRate)
            let myFloat = parseFloat(this.editWeightRateForm.maxWeight)
            let arrayLength = this.sortedWeightRates.length
            let nextLine = null

            if (this.editWeightIndex + 1 < arrayLength) {
                nextLine = this.sortedWeightRates[this.editWeightIndex + 1]
                nextLine = this.weightRates.find((weightRate) => weightRate.id == nextLine.id)
            }

            let allOkay = true

            if (nextLine && (!this.editWeightRateForm.maxWeight || (nextLine.maxPrice && this.editWeightRateForm.maxWeight >= nextLine.maxWeight))) {
                allOkay = false
                this.editWeightRateForm.maxWeight = nextLine.minWeight
                alert("Invalid maximum weight")
            }
            else if (this.editWeightRateForm.maxWeight != null && this.editWeightRateForm.maxWeight != "" && myFloat == 0) {
                allOkay = false
                alert("The maximum weight can't be 0")
            }
            else if (myFloat > 0 && myFloat <= myWeightRate.minWeight) {
                allOkay = false
                alert("The maximum weight must be greater than the minimum weight")
            }

            if (allOkay) {
                myWeightRate.maxWeight = this.editWeightRateForm.maxWeight
                
                if (nextLine) {
                    nextLine.minWeight = this.editWeightRateForm.maxWeight
                }
                else if (!nextLine && this.editWeightRateForm.maxWeight) {
                    this.lastWeightRateId = this.lastWeightRateId + 1

                    let myNewLine = {
                        id: this.lastWeightRateId,
                        minWeight: this.editWeightRateForm.maxWeight,
                        maxWeight: null,
                        price: null
                    }

                    this.weightRates.push(this.objCopy(myNewLine))

                    this.$nextTick(() => {
                        this.elGet('weightRatesPanel').scrollTop = this.elGet('weightRatesPanel').scrollHeight
                    })
                }

                this.editWeightRateForm.maxWeight = null
                this.editingWeightRate = null
                this.editWeightIndex = null
            }
        },
        clickSaveNewZoneRate() {
            if (this.slides[this.currentSlide].validation) {
                this.validating = false

                if (!this.editingRate) {
                    this.$emit('clickSaveNewZoneRate', this.objCopy(this.form), this.rateType == 'price' ? this.priceRates : (this.rateType == 'weight' ? this.weightRates : null))
                }
                else {
                    this.$emit('clickSaveEditZoneRate', this.objCopy(this.form), this.rateType == 'price' ? this.priceRates : (this.rateType == 'weight' ? this.weightRates : null))
                }
            }
            else {
                this.validating = true
            }
        },
        getDisplayMaxPrice(inPrice) {
            if (inPrice == null || inPrice == 999999999) {
                return "No Limit"
            }

            return this.displayPrice(this.sellerCurrency, inPrice)
        },
        getDisplayMaxWeight(inWeight) {
            if (inWeight == null || inWeight == 999999999) {
                return "No Limit"
            }

            return `${inWeight}kg`
        },
        getDisplayMinPrice(inPrice) {
            return this.displayPrice(this.sellerCurrency, inPrice ?? 0)
        },
        getDisplayMinWeight(inWeight) {
            return `${inWeight ?? 0}kg`
        },
        nextSlide() {
            if (this.slides[this.currentSlide].validation) {
                this.validating = false
                this.currentSlide = this.slides[this.currentSlide].next
            }
            else {
                this.validating = true
            }
        },
        previousSlide() {
            this.currentSlide = this.slides[this.currentSlide].previous
        },
        updateEditPriceRateFormValue(inField, inValue) {
            this.editPriceRateForm.maxPrice = inValue != 0 ? inValue : null
        },
        updateEditWeightRateFormValue(inField, inValue) {
            this.editWeightRateForm.maxWeight = inValue != 0 ? inValue : null
        },
        updateFormValue(inField, inValue) {
            if (inValue == "") {
                inValue = null
            }

            this.form[inField] = inValue
        },
        updatePriceRateFormValue(inField, inValue, inId) {
            let myPriceRate = this.priceRates.find((priceRate) => priceRate.id == inId)
            myPriceRate.price = inValue
        },
        updateWeightRateFormValue(inField, inValue, inId) {
            let myWeightRate = this.weightRates.find((weightRate) => weightRate.id == inId)
            myWeightRate.price = inValue
        },
    }
}
</script>