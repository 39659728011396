<template>
    <div class="w-full px-2">
        <div v-for="abTest in groupBuy.ab_tests">
            <panel-section 
                :adminSection="true" :sectionLabel="`${abTest.name} (${abTest.identifier})`"
            >
                <ab-test 
                    :abTest="abTest" :groupBuy="groupBuy" :isGiveAway="isGiveAway"
                    @updateABTestGallery="updateABTestGallery" @updateABTestMediaBlocks="updateABTestMediaBlocks" @updateABTestPurchaseMediaBlocks="updateABTestPurchaseMediaBlocks" 
                    @updateABTestWhiteLabel="updateABTestWhiteLabel"
                />
            </panel-section>
        </div>
    </div>
</template>

<script>
import AbTest from './AbTest'

export default {
    props: [
        'groupBuy',
        'isGiveAway'
    ],
    components: {
        AbTest
    },
    methods: {
        updateABTestGallery(inGallery, inIdentifier) {
            this.$emit('updateABTestGallery', inGallery, inIdentifier)
        },
        updateABTestMediaBlocks(inForm, inIdentifier) {
            this.$emit('updateABTestMediaBlocks', inForm, inIdentifier)
        },
        updateABTestPurchaseMediaBlocks(inForm, inIdentifier) {
            this.$emit('updateABTestPurchaseMediaBlocks', inForm, inIdentifier)
        },
        updateABTestWhiteLabel(inForm, inImmediate, inIdentifier) {
            this.$emit('updateABTestWhiteLabel', inForm, inImmediate, inIdentifier)
        }
    }
}
</script>