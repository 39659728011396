<template>
    <div v-if="formLoaded" class="w-full">
        <input-panel 
            fieldType="text" :fieldValue="fixText(groupBuyForm.join_button_text)" fieldLabel="Join Button Text" fieldPlaceholder="Join Button Text"
            fieldFormName="join_button_text"
            @fieldInput="updateFormValue"
        />
        <input-panel v-if="!isGiveAway && pGroupBuyForm.has_eoi"
            fieldType="text" :fieldValue="groupBuyForm.register_button_text" fieldLabel="Register Button Text" fieldPlaceholder="Register Button Text"
            fieldFormName="register_button_text"
            @fieldInput="updateFormValue"
        />
        <input-panel v-if="!isGiveAway && pGroupBuyForm.has_eoi"
            fieldType="text" :fieldValue="groupBuyForm.register_button_extra_text" fieldLabel="Register Button Extra Text" fieldPlaceholder="Register Button Extra Text"
            fieldFormName="register_button_extra_text"
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldType="text" :fieldValue="fixText(groupBuyForm.share_button_text)" fieldLabel="Share Button Text" fieldPlaceholder="Share Button Text"
            fieldFormName="share_button_text" hideFieldFormName="hide_share_button" :hideFieldValue="groupBuyForm.hide_share_button" :hideIcon="true"
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldType="text" :fieldValue="fixText(groupBuyForm.show_offer_details_text)" fieldLabel="Show Offer Details Text" fieldPlaceholder="Show Offer Details Text"
            fieldFormName="show_offer_details_text"
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldType="text" :fieldValue="fixText(groupBuyForm.hide_offer_details_text)" fieldLabel="Hide Offer Details Text" fieldPlaceholder="Hide Offer Details Text"
            fieldFormName="hide_offer_details_text"
            @fieldInput="updateFormValue"
        />
        <select-panel 
            :fieldValue="groupBuyForm.hide_tracker_bar" fieldLabel="Hide Tracker Bar" fieldFormName="hide_tracker_bar"
            :fieldData="yesNoData" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel v-if="isGiveAway && !pGroupBuyForm.no_maximum && !pGroupBuyForm.no_maximum_dollars && !groupBuyForm.hide_tracker_bar"
            :fieldData="yesNoData" fieldFormName="dollars_graphic" fieldLabel="Display Bar As Percentage Of Dollars"
            :fieldValue="groupBuyForm.dollars_graphic" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel 
            :fieldValue="groupBuyForm.hide_info_bar" fieldLabel="Hide Status Info" fieldFormName="hide_info_bar"
            :fieldData="yesNoData" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel v-if="isGiveAway && !pGroupBuyForm.no_maximum_dollars && !groupBuyForm.percentage_tracker && !groupBuyForm.hide_info_bar"
            :fieldData="yesNoData" fieldFormName="dollars_tracker" fieldLabel="Display Status Info As Percentage Of Dollars"
            :fieldValue="groupBuyForm.dollars_tracker" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel v-if="isGiveAway && !groupBuyForm.dollars_tracker && !groupBuyForm.hide_info_bar"
            :fieldData="yesNoData" fieldFormName="percentage_tracker" fieldLabel="Display Status Info As Percentage Of Tickets"
            :fieldValue="groupBuyForm.percentage_tracker" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <input-panel v-if="(!isGiveAway || !groupBuyForm.dollars_tracker) && !groupBuyForm.hide_info_bar"
            fieldType="text" :fieldValue="groupBuyForm.units_confirmed_text" :fieldLabel="unitsConfirmedText" :fieldPlaceholder="unitsConfirmedText"
            fieldFormName="units_confirmed_text" hideFieldFormName="hide_units_confirmed" :hideFieldValue="groupBuyForm.hide_units_confirmed" :hideIcon="true"
            @fieldInput="updateFormValue"
        />
        <input-panel v-if="isGiveAway && groupBuyForm.dollars_tracker && !groupBuyForm.hide_info_bar"
            fieldType="text" :fieldValue="groupBuyForm.dollars_percentage_text" fieldLabel="Dollars Percentage Text" fieldPlaceholder="Dollars Percentage Text"
            fieldFormName="dollars_percentage_text"
            @fieldInput="updateFormValue"
        />
        <input-panel v-if="!groupBuyForm.hide_info_bar"
            fieldType="text" :fieldValue="groupBuyForm.minimum_required_text" fieldLabel="Minimum Required Text" fieldPlaceholder="Minimum Required Text"
            fieldFormName="minimum_required_text"
            @fieldInput="updateFormValue"
        />
        <input-panel v-if="!groupBuyForm.hide_info_bar"
            fieldType="text" :fieldValue="groupBuyForm.maximum_allowed_text" fieldLabel="Maximum Allowed Text" fieldPlaceholder="Maximum Allowed Text"
            fieldFormName="maximum_allowed_text"
            @fieldInput="updateFormValue"
        />
        <select-panel v-if="!isGiveAway"
            :fieldValue="groupBuyForm.hide_products_description" fieldLabel="Hide Products Description" fieldFormName="hide_products_description"
            :fieldData="yesNoData" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <input-panel v-if="!isGiveAway"
            fieldType="text" :fieldValue="fixText(groupBuyForm.products_title_text)" fieldLabel="Products Title Text" fieldPlaceholder="Products Title Text"
            fieldFormName="products_title_text" hideFieldFormName="hide_products_title_text" :hideFieldValue="groupBuyForm.hide_products_title_text" :hideIcon="true"
            @fieldInput="updateFormValue"
        />
        <select-panel 
            :fieldValue="groupBuyForm.hide_offer_conditions" fieldLabel="Hide Offer Conditions" fieldFormName="hide_offer_conditions"
            :fieldData="yesNoData" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <input-panel v-if="!groupBuyForm.hide_offer_conditions"
            fieldType="text" :fieldValue="fixText(groupBuyForm.offer_conditions_text_one)" fieldLabel="Offer Conditions Text One" fieldPlaceholder="Offer Conditions Text One"
            fieldFormName="offer_conditions_text_one" hideFieldFormName="hide_offer_conditions_text_one" :hideFieldValue="groupBuyForm.hide_offer_conditions_text_one" :hideIcon="true"
            @fieldInput="updateFormValue"
        />
        <input-panel v-if="!groupBuyForm.hide_offer_conditions"
            fieldType="text" :fieldValue="fixText(groupBuyForm.offer_conditions_text_two)" fieldLabel="Offer Conditions Text Two" fieldPlaceholder="Offer Conditions Text Two"
            fieldFormName="offer_conditions_text_two" hideFieldFormName="hide_offer_conditions_text_two" :hideFieldValue="groupBuyForm.hide_offer_conditions_text_two" :hideIcon="true"
            @fieldInput="updateFormValue"
        />
    </div>
</template>

<script>
export default {
    props: [
        'groupBuy',
        'isGiveAway',
        'pGroupBuyForm'
    ],
    data() {
        return {
            formLoaded: false,
            groupBuyForm: {
                dollars_graphic: false,
                dollars_percentage_text: null,
                dollars_tracker: false,
                hide_info_bar: false,
                hide_offer_conditions: false,
                hide_offer_conditions_text_one: false,
                hide_offer_conditions_text_two: false,
                hide_offer_details_text: null,
                hide_products_description: false,
                hide_products_title_text: false,
                hide_share_button: false,
                hide_tracker_bar: false,
                hide_units_confirmed: false,
                join_button_text: null,
                maximum_allowed_text: null,
                minimum_required_text: null,
                offer_conditions_text_one: null,
                offer_conditions_text_two: null,
                percentage_tracker: false,
                products_title_text: null,
                register_button_extra_text: null,
                register_button_text: null,
                share_button_text: null,
                show_offer_details_text: null,
                units_confirmed_text: null
            }
        }
    },
    computed: {
        unitsConfirmedText() {
            return !this.isGiveAway ? "Units Confirmed Text" : (this.groupBuyForm.percentage_tracker ? "Tickets Percentage Text" : "Tickets Sold Text")
        }
    },
    mounted() {
        this.groupBuyForm.dollars_graphic = this.pGroupBuyForm.dollars_graphic
        this.groupBuyForm.dollars_percentage_text = this.pGroupBuyForm.dollars_percentage_text
        this.groupBuyForm.dollars_tracker = this.pGroupBuyForm.dollars_tracker
        this.groupBuyForm.hide_info_bar = this.pGroupBuyForm.hide_info_bar
        this.groupBuyForm.hide_offer_conditions = this.pGroupBuyForm.hide_offer_conditions
        this.groupBuyForm.hide_offer_conditions_text_one = this.pGroupBuyForm.hide_offer_conditions_text_one
        this.groupBuyForm.hide_offer_conditions_text_two = this.pGroupBuyForm.hide_offer_conditions_text_two
        this.groupBuyForm.hide_offer_details_text = this.pGroupBuyForm.hide_offer_details_text
        this.groupBuyForm.hide_products_description = this.pGroupBuyForm.hide_products_description
        this.groupBuyForm.hide_products_title_text = this.pGroupBuyForm.hide_products_title_text
        this.groupBuyForm.hide_share_button = this.pGroupBuyForm.hide_share_button
        this.groupBuyForm.hide_tracker_bar = this.pGroupBuyForm.hide_tracker_bar
        this.groupBuyForm.hide_units_confirmed = this.pGroupBuyForm.hide_units_confirmed
        this.groupBuyForm.join_button_text = this.pGroupBuyForm.join_button_text
        this.groupBuyForm.maximum_allowed_text = this.pGroupBuyForm.maximum_allowed_text
        this.groupBuyForm.minimum_required_text = this.pGroupBuyForm.minimum_required_text
        this.groupBuyForm.offer_conditions_text_one = this.pGroupBuyForm.offer_conditions_text_one
        this.groupBuyForm.offer_conditions_text_two = this.pGroupBuyForm.offer_conditions_text_two
        this.groupBuyForm.percentage_tracker = this.pGroupBuyForm.percentage_tracker
        this.groupBuyForm.products_title_text = this.pGroupBuyForm.products_title_text
        this.groupBuyForm.register_button_extra_text = this.pGroupBuyForm.register_button_extra_text
        this.groupBuyForm.register_button_text = this.pGroupBuyForm.register_button_text
        this.groupBuyForm.share_button_text = this.pGroupBuyForm.share_button_text
        this.groupBuyForm.show_offer_details_text = this.pGroupBuyForm.show_offer_details_text
        this.groupBuyForm.units_confirmed_text = this.pGroupBuyForm.units_confirmed_text
        this.formLoaded = true
    },
    methods: {
        fixText(inText) {
            return inText.replace('{labelSingular}', this.groupBuyType(this.groupBuy.type).labelSingular)
        },
        saveWhiteLabelData(inImmediate) {
            this.$emit('updateWhiteLabel', this.groupBuyForm, inImmediate)
        },
        updateFormSelectValue(inField, inValue) {
            this.groupBuyForm[inField] = inValue
            this.saveWhiteLabelData(true)
        },
        updateFormValue(inField, inValue) {
            this.groupBuyForm[inField] = inValue
            this.saveWhiteLabelData(false)
        }
    }
}
</script>