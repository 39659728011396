<template>
    <div class="w-full">
        <div class="w-full px-2 mt-2 text-gray-800">
            <div class="w-full relative leading-6">
                <div class="w-full text-center font-semibold mb-1">
                    Shipping Rate Labels
                </div>
                <div v-if="!addingLabel && editingLabel == null" @click="clickNewLabel()" class="absolute top-1 right-2 font-normal cursor-pointer text-gray-600">
                    <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                </div>
            </div>
            <div v-if="addingLabel" class="mt-px bg-orange-200 flex items-center justify-between">
                <input-panel 
                    fieldFormName="label" fieldPlaceholder="Label" :fieldRequired="true" fieldType="text" :fieldValue="newLabelForm.label" :forceMobile="true" :hideLabel="true"
                    @fieldInput="updateNewLabelFormValue" 
                >
                </input-panel>
                <div class="flex items-center justify-end">
                    <div class="flex items-center justify-center text-gray-600">
                        <div @click="clickSaveNewLabel()" class="cursor-pointer">
                            <fa-icon icon="floppy-disk" type="fas" class="h-5 w-5" />
                        </div>
                        <div @click="clickCancelNewLabel()" class="cursor-pointer mx-2">
                            <fa-icon icon="ban" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-for="(shippingLabel, index) in sortedShippingRateLabels" class="mt-px bg-orange-200">
                <div v-if="editingLabel == null || editingLabel != index" class="flex items-center justify-between py-1 pr-1 pl-2">
                    <div class="flex items-center justify-between w-full">
                        <div @click="clickEditLabel(shippingLabel, index)" class="mr-2 w-full text-left">
                            {{ shippingLabel }}
                        </div>
                    </div>
                    <div v-if="editingLabel == null && !addingLabel" class="flex items-center justify-end text-gray-600">
                        <div @click="clickEditLabel(shippingLabel, index)" class="cursor-pointer mr-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                        </div>
                        <div @click="clickDeleteLabel(index)" class="cursor-pointer mr-1">
                            <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </div>
                <div v-if="editingLabel != null && editingLabel == index" class="flex items-center justify-between">
                    <input-panel 
                        fieldFormName="label" fieldPlaceholder="Label" :fieldRequired="true" fieldType="text" :fieldValue="editLabelForm.label" :forceMobile="true" :hideLabel="true"
                        @fieldInput="updateEditLabelFormValue" 
                    >
                    </input-panel>
                    <div class="flex items-center justify-end">
                        <div class="flex items-center justify-center text-gray-600">
                            <div @click="clickSaveEditLabel()" class="cursor-pointer">
                                <fa-icon icon="floppy-disk" type="fas" class="h-5 w-5" />
                            </div>
                            <div @click="clickCancelEditLabel()" class="cursor-pointer mx-2">
                                <fa-icon icon="ban" type="fas" class="h-5 w-5" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <validation-message v-if="validating && (!sellerDataForm.shippingRateLabels || sellerDataForm.shippingRateLabels.length == 1)" 
            message="You must add at least 1 shipping rate label"
        />
    </div>
</template>

<script>
export default {
    props: [
        'sellerDataForm',
        'validating'
    ],
    data() {
        return {
            addingLabel: false,
            editingLabel: null,
            editLabelForm: {
                label: null
            },
            newLabelForm: {
                label: null
            }
        }
    },
    computed: {
        sortedShippingRateLabels() {
            return this.sellerDataForm.shippingRateLabels
                .sort((a, b) => a < b ? -1 : 1)
        }
    },
    methods: {
        clickCancelEditLabel() {
            this.editLabelForm.label = null
            this.editingLabel = null
        },
        clickCancelNewLabel() {
            this.newLabelForm.label = null
            this.addingLabel = false
        },
        clickDeleteLabel(inIndex) {
            if (confirm('You wish to delete this label?')) {
                let myLabels = []
                
                this.sellerDataForm.shippingRateLabels.forEach((label, index) => {
                    if (index != inIndex) {
                        myLabels.push(label)
                    }
                })

                this.$emit('updateSellerDataFormSelectValue', 'shippingRateLabels', myLabels)
            }
        },
        clickEditLabel(inLabel, inIndex) {
            this.editLabelForm.label = inLabel
            this.editingLabel = inIndex
        },
        clickNewLabel() {
            this.newLabelForm.label = null
            this.addingLabel = true
        },
        clickSaveEditLabel() {
            let myLabels = this.objCopy(this.sellerDataForm.shippingRateLabels).filter((label, index) => index != this.editingLabel)
            myLabels.push(this.editLabelForm.label)
            this.$emit('updateSellerDataFormSelectValue', 'shippingRateLabels', myLabels)
            this.editLabelForm.label = null
            this.editingLabel = null
        },
        clickSaveNewLabel() {
            let myLabels = this.objCopy(this.sellerDataForm.shippingRateLabels)
            myLabels.push(this.objCopy(this.newLabelForm.label))
            this.$emit('updateSellerDataFormSelectValue', 'shippingRateLabels', myLabels)
            this.newLabelForm.label = null
            this.addingLabel = false
        },
        updateEditLabelFormValue(inField, inValue) {
            this.editLabelForm[inField] = inValue
        },
        updateNewLabelFormValue(inField, inValue) {
            this.newLabelForm[inField] = inValue
        }
    }
}
</script>