<template>
    <div class="text-xs text-red-500 text-center">{{ message }}</div>
</template>

<script>
export default {
    props: [
        'message'
    ]
}
</script>