const shippingMixins = {
    methods: {
        formatMyAddress(inAddress) {
            return inAddress.split(",").join("</br>")
        },
        shippingCheckPriceTransitTimeValid(inZone, inTransitTime) {
            let transitTimesMin = false
            let transitTimesMax = false
            let transitTimesValid = true
            let lastMax = null

            if (inZone.rates && inZone.rates.length && !inZone.disabled) {
                inZone.rates
                    .filter((rate) => this.shippingIsPriceRate(rate) && ((rate.transitTime == 'custom' && rate.customRateName == inTransitTime) || rate.transitTime == inTransitTime))
                    .sort((a, b) => a.minPrice < b.minPrice ? -1 : 1)
                    .forEach((rate) => {
                        if (rate.price >= 0) {
                            if (transitTimesValid) {
                                if (!lastMax) {
                                    if (rate.minPrice == 0) {
                                        transitTimesMin = true
                                        lastMax = rate.maxPrice
                                    }
                                    else {
                                        transitTimesValid = false
                                    }

                                    if (rate.maxPrice == 999999999) {
                                        transitTimesMax = true
                                    }
                                }
                                else {
                                    if (transitTimesMax) {
                                        transitTimesValid = false
                                    }
                                    else if (lastMax != rate.minPrice) {
                                        transitTimesValid = false
                                    }
                                    else {
                                        lastMax = rate.maxPrice

                                        if (rate.maxPrice == 999999999) {
                                            transitTimesMax = true
                                        }
                                    }
                                }
                            }
                        }
                    })

                return transitTimesValid && transitTimesMin && transitTimesMax
            }
            else {
                return false
            }
        },
        shippingCheckValidModel(inZones, inModel) {
            let modelOkay = false
            
            if (inZones && inZones.length) {
                inZones.forEach((zone) => {
                    if (zone.rates && zone.rates.length && !zone.disabled && !modelOkay) {
                        zone.rates.forEach((rate) => {
                            let rateLabel = rate.transitTime == 'custom' ? rate.customRateName : rate.transitTime
                            
                            if (inModel == 'general' && this.shippingIsGeneralRate(rate) && this.shippingCheckValidRate(zone, 'general', rate)) {
                                modelOkay = true
                            }
                            else if (inModel == 'weight' && this.shippingIsWeightRate(rate) && this.shippingCheckValidRate(zone, 'weight', rate)) {
                                modelOkay = true
                            }
                            else if (inModel == 'price' && this.shippingIsPriceRate(rate) && this.shippingCheckValidRate(zone, 'price', rate)) {
                                 modelOkay = true
                            }
                            else if (inModel == 'unit' && this.shippingIsUnitRate(rate) && this.shippingCheckValidRate(zone, 'unit', rate)) {
                                modelOkay = true
                            }
                        })
                    }
                })
            }

            return modelOkay
        },
        shippingCheckValidRate(inZone, inModel, inRate) {
            let rateLabel = inRate.transitTime == 'custom' ? inRate.customRateName : inRate.transitTime

            if (inModel == 'general' && this.shippingIsGeneralRate(inRate) && inRate.price >= 0) {
                return true
            }
            else if (inModel == 'weight' && this.shippingIsWeightRate(inRate)) {
                if (this.shippingCheckWeightTransitTimeValid(inZone, rateLabel)) {
                    return true
                }
            }
            else if (inModel == 'price' && this.shippingIsPriceRate(inRate)) {
                if (this.shippingCheckPriceTransitTimeValid(inZone, rateLabel)) {
                    return true
                }
            }
            else if (inModel == 'unit' && this.shippingIsUnitRate(inRate) && inRate.firstUnitPrice && inRate.additionalUnitPrice) {
                return true
            }

            return false
        },
        shippingCheckWeightTransitTimeValid(inZone, inTransitTime) {
            let transitTimesMin = false
            let transitTimesMax = false
            let transitTimesValid = true
            let lastMax = null

            if (inZone.rates && inZone.rates.length && !inZone.disabled) {
                inZone.rates
                    .filter((rate) => this.shippingIsWeightRate(rate) && ((rate.transitTime == 'custom' && rate.customRateName == inTransitTime) || rate.transitTime == inTransitTime))
                    .sort((a, b) => a.minWeight < b.minWeight ? -1 : 1)
                    .forEach((rate) => {
                        if (rate.price) {
                            if (transitTimesValid) {
                                if (!lastMax) {
                                    if (rate.minWeight == 0) {
                                        transitTimesMin = true
                                        lastMax = rate.maxWeight
                                    }
                                    else {
                                        transitTimesValid = false
                                    }
                                }
                                else {
                                    if (transitTimesMax) {
                                        transitTimesValid = false
                                    }
                                    else if (lastMax != rate.minWeight) {
                                        transitTimesValid = false
                                    }
                                    else {
                                        lastMax = rate.maxWeight

                                        if (rate.maxWeight == 999999999) {
                                            transitTimesMax = true
                                        }
                                    }
                                }
                            }
                        }
                    })

                return transitTimesValid && transitTimesMin && transitTimesMax
            }
            else {
                return false
            }
        },
        shippingGetInvalidProducts() {
            let myProducts = []

            if (this.vendorProducts && Object.values(this.vendorProducts).length) {
                Object.values(this.vendorProducts)
                    .filter((product) => !product.disabled && (!this.shopifyStore || product.shopify_status == 'active'))
                    .forEach((product) => {
                        if (!this.shippingCheckValidModel(this.sellerVendorData.shippingZones, product.shipping_model)) {
                            myProducts.push(this.objCopy(product))
                        }
                    })
            }

            return myProducts.length ? myProducts : null
        },
        shippingGetRates(inZone, inModel, inTransitTime, inTotalPrice, inTotalWeight) {
            let myRates = []

            if (inZone.rates && inZone.rates.length && !inZone.disabled) {
                inZone.rates
                    .forEach((rate) => {
                        let rateLabel = rate.transitTime == 'custom' ? rate.customRateName : rate.transitTime

                        if (inTransitTime == rate.transitTime) {
                            if (inModel == 'general' && this.shippingIsGeneralRate(rate) && this.shippingCheckValidRate(inZone, 'general', rate)) {
                                myRates.push(rate)
                            }
                            else if (inModel == 'weight' && this.shippingIsWeightRate(rate) && this.shippingCheckValidRate(inZone, 'weight', rate) && inTotalWeight >= rate.minWeight && inTotalWeight < rate.maxWeight) {
                                myRates.push(rate)
                            }
                            else if (inModel == 'price' && this.shippingIsPriceRate(rate) && this.shippingCheckValidRate(inZone, 'price', rate) && inTotalPrice >= rate.minPrice && inTotalPrice < rate.maxPrice) {
                                myRates.push(rate)
                            }
                            else if (inModel == 'unit' && this.shippingIsUnitRate(rate) && this.shippingCheckValidRate(inZone, 'unit', rate)) {
                                myRates.push(rate)
                            }
                        }
                    })
            }

            return myRates
        },
        shippingGetValidCountries(inZones, inModel, inType = 'name') {
            let myZones = []
            
            if (inZones && inZones.length) {
                inZones.forEach((zone) => {
                    let zoneType = zone.name == 'Default zone' ? 'name' : inType

                    if (zone.rates && zone.rates.length && !zone.disabled) {
                        zone.rates.forEach((rate) => {
                            if (!myZones.find((myZone) => myZone == zone[zoneType]) && this.shippingCheckValidRate(zone, inModel, rate)) {
                                let rateLabel = rate.transitTime == 'custom' ? rate.customRateName : rate.transitTime

                                if (inModel == 'general' && this.shippingIsGeneralRate(rate)) {
                                    myZones.push(zone[zoneType])
                                }
                                else if (inModel == 'weight' && this.shippingIsWeightRate(rate)) {
                                    myZones.push(zone[zoneType])
                                }
                                else if (inModel == 'price' && this.shippingIsPriceRate(rate)) {
                                    myZones.push(zone[zoneType])
                                }
                                else if (inModel == 'unit' && this.shippingIsUnitRate(rate)) {
                                    myZones.push(zone[zoneType])
                                }
                            }
                        })
                    }
                })
            }

            return myZones
                .sort((a, b) => a > b ? 1 : -1)
        },
        shippingGetValidModels(inZones) {
            let myModels = []
            
            if (inZones && inZones.length) {
                inZones.forEach((zone) => {
                    if (zone.rates && zone.rates.length && !zone.disabled) {
                        zone.rates.forEach((rate) => {
                            let rateLabel = rate.transitTime == 'custom' ? rate.customRateName : rate.transitTime

                            if (this.shippingIsGeneralRate(rate) && !myModels.includes('general') && this.shippingCheckValidRate(zone, 'general', rate)) {
                                myModels.push('general')
                            }
                            else if (this.shippingIsWeightRate(rate) && !myModels.includes('weight') && this.shippingCheckValidRate(zone, 'weight', rate)) {
                                myModels.push('weight')
                            }
                            else if (this.shippingIsPriceRate(rate) && !myModels.includes('price') && this.shippingCheckValidRate(zone, 'price', rate)) {
                                myModels.push('price')
                            }
                            else if (this.shippingIsUnitRate(rate) && !myModels.includes('unit') && this.shippingCheckValidRate(zone, 'unit', rate)) {
                                myModels.push('unit')
                            }
                        })
                    }
                })
            }

            return myModels
        },
        shippingGetValidTransitTimes(inZone, inModel) {
            let myTransitTimes = []

            if (inZone.rates && inZone.rates.length && !inZone.disabled) {
                inZone.rates
                    .forEach((rate) => {
                        let rateLabel = rate.transitTime == 'custom' ? rate.customRateName : rate.transitTime

                        if (inModel == 'general' && this.shippingIsGeneralRate(rate) && this.shippingCheckValidRate(inZone, 'general', rate) && !myTransitTimes.find((myTransitTime) => myTransitTime == rateLabel)) {
                            myTransitTimes.push(rateLabel)
                        }
                        else if (inModel == 'weight' && this.shippingIsWeightRate(rate) && this.shippingCheckValidRate(inZone, 'weight', rate) && !myTransitTimes.find((myTransitTime) => myTransitTime == rateLabel)) {
                            myTransitTimes.push(rateLabel)
                        }
                        else if (inModel == 'price' && this.shippingIsPriceRate(rate) && this.shippingCheckValidRate(inZone, 'price', rate) && !myTransitTimes.find((myTransitTime) => myTransitTime == rateLabel)) {
                            myTransitTimes.push(rateLabel)
                        }
                        else if (inModel == 'unit' && this.shippingIsUnitRate(rate) && this.shippingCheckValidRate(inZone, 'unit', rate) && !myTransitTimes.find((myTransitTime) => myTransitTime == rateLabel)) {
                            myTransitTimes.push(rateLabel)
                        }
                    })
            }

            return myTransitTimes
        },
        shippingIsGeneralRate(inRate) {
            return !inRate.hasConditions
        },
        shippingIsPriceRate(inRate) {
            return inRate.hasConditions && inRate.basedOn == 'price'
        },
        shippingIsUnitRate(inRate) {
            return inRate.hasConditions && inRate.basedOn == 'unit'
        },
        shippingIsWeightRate(inRate) {
            return inRate.hasConditions && inRate.basedOn == 'weight'
        }
    }
}

export default shippingMixins