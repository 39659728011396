<template>
    <div v-if="formLoaded" class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="track_quantity" fieldLabel="Track Quantity"
            :fieldValue="variantForm.track_quantity" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <input-panel v-if="variantForm.track_quantity" 
            fieldFormName="qty" fieldLabel="Quantity in Stock" fieldPlaceholder="Quantity in Stock" :fieldRequired="true" fieldType="number" 
            :fieldValue="variantForm.qty" 
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldFormName="min_order_qty" fieldLabel="Minimum Order Quantity" fieldPlaceholder="Minimum Order Quantity" :fieldRequired="true" fieldType="number"
            :fieldValue="variantForm.min_order_qty" 
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldFormName="units" fieldLabel="Units" :fieldNonEdit="uploadedProduct && !allowImportedEdit" 
            fieldPlaceholder="Units" fieldType="text" :fieldValue="variantForm.units"  
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldFormName="price" fieldLabel="Unit Price" :fieldNonEdit="uploadedProduct && !allowImportedEdit" fieldPlaceholder="Unit Price"
            :fieldRequired="true" fieldSubLabel="Please enter a tax inclusive price" fieldType="number" :fieldValue="variantForm.price" 
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldFormName="compare_at_price" fieldLabel="Compare at Price" :fieldNonEdit="uploadedProduct && !allowImportedEdit" 
            fieldPlaceholder="Compare at price" fieldSubLabel="Please enter a tax inclusive price" fieldType="number" :fieldValue="variantForm.compare_at_price"  
            @fieldInput="updateFormValue"
        />
        <select-panel 
            :fieldData="sellerVendorData.taxRates" fieldDataLabel="label" fieldDataValue="id" fieldFormName="tax_rate_id" fieldLabel="Tax Rate" 
            :fieldRequired="true" :fieldValue="variantForm.tax_rate_id"
            @fieldChange="updateFormSelectValue"
        />
    </div>
</template>

<script>
export default {
    props: [
        'pVariantForm'
    ],
    data() {
        return {
            formLoaded: false,
            variantForm: {
                compare_at_price: null,
                min_order_qty: null,
                price: null,
                qty: null,
                tax_rate_id: null,
                track_quantity: false,
                units: null
            }
        }
    },
    mounted() {
        this.variantForm.compare_at_price = this.pVariantForm.compare_at_price
        this.variantForm.min_order_qty = this.pVariantForm.min_order_qty
        this.variantForm.price = this.pVariantForm.price
        this.variantForm.qty = this.pVariantForm.qty
        this.variantForm.tax_rate_id = this.pVariantForm.tax_rate_id
        this.variantForm.track_quantity = this.pVariantForm.track_quantity
        this.variantForm.units = this.pVariantForm.units
        this.formLoaded = true
    },
    methods: {
        updateFormSelectValue(inField, inValue) {
            this.variantForm[inField] = inValue
            this.$emit('updatePricing', this.variantForm, true)
        },
        updateFormValue(inField, inValue) {
            this.variantForm[inField] = inValue
            this.$emit('updatePricing', this.variantForm, false)
        }
    }
}
</script>