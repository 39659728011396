<template>
    <div v-if="buildsMenuOpen" class="overlay-menu-base bottom-0">
        <div class="absolute top-4 left-2 w-full iphoneMarginTop" :class="{ 'ml-6': isIOSApp }">
            <div class="mx-auto max-main-width">
                <cached-image imageClasses="block w-auto h-8" :imageSrc="bzLogo" />
            </div>
        </div>
        <div class="absolute right-0 items-end justify-end w-full iphoneMarginBottom overflow-y-auto" style="max-height: 100%;" :class="{ 'bottom-0': !isIOSApp, 'bottom-2': isIOSApp }">
            <div class="mx-auto max-main-width flex justify-end">
                <div class="inline-flex flex-col pr-1 mb-3 items-end justify-end">
                    <div class="standard-shadow mt-4">
                        <div  @click="clickInvite()" class="inline-flex items-center justify-end cursor-pointer">
                            <div class="pr-2">
                                Invite Your Friends
                            </div>
                            <div class="w-10 h-10 rounded-full flex items-center justify-center" :class="{ 'bg-white text-orange-500': clickedMenu != 'invite', 'bg-orange-500 text-white': clickedMenu == 'invite' }">
                                <fa-icon v-if="isAndroidApp" icon="share-nodes" type="fas" class="h-6 w-6" />
                                <fa-icon v-else-if="isIOSApp" icon="arrow-up-from-bracket" type="fas" class="h-6 w-6" />
                                <fa-icon v-else icon="link" type="fas" class="h-6 w-6" />
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div  @click="clickNewBuild()" class="inline-flex items-center justify-end cursor-pointer">
                            <div class="pr-2">
                                Create a New Build
                            </div>
                            <div :class="{ 'text-white': clickedMenu != 'new', 'text-orange-500': clickedMenu == 'new' }">
                                <fa-icon icon="circle-plus" type="fas" class="h-10 w-10" />
                            </div>
                        </div>
                    </div>
                    <div v-for="build in loggedUser.builds" class="mt-4">
                        <div @click="clickBuild(build)" class="inline-flex items-center justify-end cursor-pointer">
                            <div class="pr-2">
                                {{ build.name }}
                            </div>
                            <div class="w-10 h-10 rounded-full relative" :class="{ 'border-2 border-orange-500': clickedMenu == build.id }">
                                <build-avatar :url="build.primary_image" size="sm" :rounded="true" />
                                <div v-show="clickedMenu == build.id" class="absolute w-full h-full top-0 left-0 rounded-full bg-orange-500 opacity-50">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="standard-shadow mt-4">
                        <div v-if="vendorDataOkay && sellerVendor" @click="clickSeller" class="inline-flex items-center justify-end cursor-pointer">
                            <div class="pr-2">
                                Seller Profile
                            </div>
                            <div class="w-10 h-10 rounded-full relative" :class="{ 'border-2 border-orange-500': clickedMenu == 'seller' }">
                                <user-avatar :url="sellerVendor.avatar" :rounded="true" />
                                <div v-show="clickedMenu == 'seller'" class="absolute w-full h-full top-0 left-0 rounded-full bg-orange-500 opacity-50">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="standard-shadow mt-4">
                        <div @click="clickGarage" class="inline-flex items-center justify-end cursor-pointer">
                            <div class="pr-2">
                                Home Garage
                            </div>
                            <div class="w-10 h-10 rounded-full relative" :class="{ 'border-2 border-orange-500': clickedMenu == 'garage' }">
                                <user-avatar :url="loggedUser.avatar" :rounded="true" />
                                <div v-show="clickedMenu == 'garage'" class="absolute w-full h-full top-0 left-0 rounded-full bg-orange-500 opacity-50">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div @click="clickClose" class="inline-flex items-center justify-end cursor-pointer">
                            <div class="mr-2">
                                Close Menu
                            </div>
                            <div :class="{ 'orphan-icon-white-box': clickedMenu != 'close', 'orphan-icon-box': clickedMenu == 'close'}">
                                <fa-icon icon="xmark" type="fas" class="overlay-menu-icon h-7 w-7" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            clickedMenu: null,
            currentScrollTop: 0,
            vcoConfig: {
                handler: this.closeBuildsMenu,
                middleware: this.clickOutsideCheck,
                events: ['dblclick', 'click'],
                isActive: true,
                detectIFrame: true,
                capture: false
            }
        }
    }, 
    watch: {
        buildsMenuOpen: {
            handler(val) {
                if (val) {
                    this.clickedMenu = null
                }
            }
        },
        keyEscape: {
            handler(val) {
                if (val && this.popupCheck('buildzMenu')) {
                    this.setStateData([
                        { keyEscape: false }
                    ])

                    this.clickClose()
                }
            }
        }
    },
    mounted() {
        this.popupIn('buildzMenu')

        this.setStateData([
            { keyEscape: false }
        ])

        if (this.popups.length == 1) {
            this.currentScrollTop = this.scrollTop
                        
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant'
            })
            
            document.body.classList.remove("overflow-y-scroll")
            document.body.classList.add("overflow-y-hidden")
            document.body.style.marginTop = -(this.currentScrollTop) + "px"
        }
    },
    beforeUnmount() {
        this.popupOut('buildzMenu')

        if (this.popups.length == 0) {
            document.body.classList.remove("overflow-y-hidden")
            document.body.classList.add("overflow-y-scroll")
            document.body.style.marginTop = "0px"
            
            window.scrollTo({
                top: this.currentScrollTop,
                left: 0,
                behavior: 'instant'
            })
        }
    },
    methods: {
        clickBuild(inBuild) {
            this.clickedMenu = inBuild.id

            if (this.currentBuildHandle != inBuild.handle) {
                this.setFlasher('build')
            }

            setTimeout(() => {
                this.routeTo(`/build/${inBuild.handle}`)
                this.closeBuildsMenu()
            }, 100)
        },
        clickClose() {
            this.clickedMenu = 'close'

            setTimeout(() => {
                this.closeBuildsMenu()
            }, 100)
        },
        clickGarage() {
            this.clickedMenu = 'garage'

            setTimeout(() => {
                this.routeTo(`/garage/${this.loggedUser.handle}`)
                this.closeBuildsMenu()
            }, 100)
        },
        clickInvite() {
            this.clickedMenu = 'invite'

            setTimeout(() => {
                this.appsFlyerEvent(
                    {
                        eventName: 'Click Invite Friends',
                        eventValue: {
                        }
                    }
                )

                if (this.isAndroidApp || this.isIOSApp) {
                    this.nativeShare(
                        {
                            text: '',
                            url: this.appsFlyerOneLink
                        }
                    )
                }
                else {
                    navigator.clipboard.writeText(this.appsFlyerOneLink)
                        .then(() => {
                            this.pageMessage('success', 'URL copied to clipboard')
                        })
                        .catch(() => {
                            this.pageMessage('error', 'Unable to access your clipboard')
                        })
                }
            }, 100)
        },
        clickNewBuild() {
            this.clickedMenu = 'new'

            setTimeout(() => {
                this.appsFlyerEvent(
                    {
                        eventName: 'Click Create A Build',
                        eventValue: {
                        }
                    }
                )

                this.openNewBuildModal()
                this.closeBuildsMenu()
            }, 100)
        },
        clickOutsideCheck(event) {
            if (this.buildsMenuOpen) {
                event.preventDefault()
                return true
            }
        },
        clickSeller() {
            this.clickedMenu = 'seller'

            setTimeout(() => {
                this.routeTo(`/seller/${this.sellerVendor.handle}`)
                this.closeBuildsMenu()
            }, 100)
        }
    }
}
</script>