<template>
    <div v-if="!hideInfoBar(groupBuy)" class="mt-4">
        <div v-if="eoi" class="flex items-start justify-between text-xs">
            <div class="text-center">
                <div class="text-xl font-bold leading-tight">
                    {{ groupBuy.eoi_current_qty }}
                </div>
                Registered
            </div>
            <div v-if="!groupBuy.no_breakpoints" class="text-center">
                <div class="text-xl font-bold leading-tight">
                    {{ groupBuy.eoi_min_quantity }}
                </div>
                <div v-html="minimumRequiredText(groupBuy)"></div>
            </div>
            <div v-if="getCurrentTimeDiff(groupBuy.eoi_end_time)" class="text-center">
                <div class="text-xl font-bold leading-tight">
                    {{ getCurrentTimeDiff(groupBuy.eoi_end_time) }}
                </div>
                {{ getCurrentTimeDiffDesc(groupBuy.eoi_end_time) }}
            </div>
        </div>
        <div v-if="!eoi" class="flex items-start text-xs mt-2" :class="{ 'justify-between': columnCount > 2, 'w-full': columnCount != 2 }">
            <div v-if="!hideUnitsConfirmed(groupBuy) && groupBuy.white_label_data.dollars_tracker" class="text-center" :class="{ 'w-1/2 pr-2': columnCount == 2, 'w-full': columnCount == 1 }">
                <div class="text-xl font-bold leading-tight">
                    {{ dollarsPercentage }}
                </div>
                <div v-html="dollarsPercentageText(groupBuy)"></div>
            </div>
            <div v-if="!hideUnitsConfirmed(groupBuy) && groupBuy.white_label_data.percentage_tracker" class="text-center" :class="{ 'w-1/2 pr-2': columnCount == 2, 'w-full': columnCount == 1 }">
                <div class="text-xl font-bold leading-tight">
                    {{ ticketsPercentage }}
                </div>
                <div v-html="unitsConfirmedText(groupBuy)"></div>
            </div>
            <div v-if="!hideUnitsConfirmed(groupBuy) && !groupBuy.white_label_data.dollars_tracker && !groupBuy.white_label_data.percentage_tracker" class="text-center" :class="{ 'w-1/2 pr-2': columnCount == 2, 'w-full': columnCount == 1 }">
                <div class="text-xl font-bold leading-tight">
                    {{ groupBuy.current_qty }}
                </div>
                <div v-html="unitsConfirmedText(groupBuy)"></div>
            </div>
            <div v-if="!groupBuy.no_breakpoints && !hideMinimumQuantity(groupBuy)" class="text-center" :class="{ 'w-1/2 pr-2': columnCount == 2, 'w-full': columnCount == 1 }">
                <div class="text-xl font-bold leading-tight">
                    {{ groupBuy.min_quantity }}
                </div>
                <div v-html="minimumRequiredText(groupBuy)"></div>
            </div>
            <div v-if="!groupBuy.no_maximum && !hideMaximumAllowed(groupBuy) && (!groupBuy.white_label_data.dollars_tracker)" class="text-center" :class="{ 'w-1/2 pr-2': columnCount == 2, 'w-full': columnCount == 1 }">
                <div class="text-xl font-bold leading-tight">
                    {{ groupBuy.max_quantity }}
                </div>
                <div v-html="maximumAllowedText(groupBuy)"></div>
            </div>
            <div v-if="showOvertimeText" class="text-center" :class="{ 'w-1/2 pl-2': columnCount == 2, 'w-full': columnCount == 1 }">
                <div class="text-base font-bold leading-tight">
                    {{ groupBuy.overtime_override_text }}
                </div>
            </div>
            <div v-else-if="showEndTime" class="text-center" :class="{ 'w-1/2 pl-2': columnCount == 2, 'w-full': columnCount == 1 }">
                <div class="text-xl font-bold leading-tight">
                    {{ getCurrentTimeDiff(groupBuy.end_time) }}
                </div>
                {{ getCurrentTimeDiffDesc(groupBuy.end_time) }}
            </div>
            <div v-else-if="showOriginalEndTime" class="text-center" :class="{ 'w-1/2 pl-2': columnCount == 2, 'w-full': columnCount == 1 }">
                <div class="text-xl font-bold leading-tight">
                    {{ getCurrentTimeDiff(groupBuy.original_end_time) }}
                </div>
                {{ getCurrentTimeDiffDesc(groupBuy.original_end_time) }}
            </div>
            <div v-else-if="isLastDay && !offerHasEnded" class="text-center" :class="{ 'w-1/2 pl-2': columnCount == 2, 'w-full': columnCount == 1 }">
                <countdown 
                    countdownClass="text-xl font-bold leading-tight" :countdownTime="groupBuy.end_time"
                    @countdownOver="setOfferEnded"
                />
                <div>
                    Remaining
                </div>
            </div>
            <div v-else-if="offerHasEnded" class="text-center" :class="{ 'w-1/2 pl-2': columnCount == 2, 'w-full': columnCount == 1 }">
                <div class="font-bold leading-tight">
                    Offer<br/>Ended
                </div>
            </div>
        </div>
        <div v-if="!hideIcons" class="flex flex-wrap items-start justify-center text-xs">
            <div v-if="buildType && !hideBuildCategory(groupBuy)" class="flex items-start mx-2 mt-4">
                <fa-icon icon="shapes" type="fas" class="h-5 w-5 mr-1" />
                <span class="font-semibold whitespace-nowrap" style="padding-top: 3px;">
                    {{ buildType.label }}
                </span>
            </div>
            <div v-if="!hideShippingLocation(groupBuy)" class="flex items-start mx-2 mt-4">
                <fa-icon icon="location-dot" type="fas" class="h-5 w-5 mr-1" />
                <span class="font-semibold" style="padding-top: 3px;">
                    {{ groupBuy.location.address }}
                </span>
            </div>
            <div v-if="!hideShippingETA(groupBuy)" class="flex items-start mx-2 mt-4">
                <fa-icon icon="van-shuttle" type="fas" class="h-5 w-5 mr-1" />
                <span class="font-semibold whitespace-nowrap" style="padding-top: 3px;">
                    ETA {{ getLocalUnixDate(groupBuy.eta) }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Countdown from '@/shared/Countdown'

export default {
    props: [
        'eoi',
        'groupBuy', 
        'hideIcons'
    ],
    components: {
        Countdown
    },
    data() {
        return {
            isLastDay: false,
            isLastDayCounting: false,
            offerHasEnded: false
        }
    },
    computed: {
        buildType() {
            if (!this.buildTypes) {
                return null
            }

            let myBuildType = Object.values(this.buildTypes).filter((buildType) => buildType.id == this.groupBuy.buildz_group_id)
            return myBuildType.length ? myBuildType[0]  : null
        },
        columnCount() {
            let myCount = 0
            
            if (!this.hideMaximumAllowed(this.groupBuy)) {
                myCount = myCount + 1
            } 
            
            if (!this.hideMinimumQuantity(this.groupBuy)) {
                myCount = myCount + 1
            }

            if (!this.hideUnitsConfirmed(this.groupBuy)) {
                myCount = myCount + 1
            }

            return myCount
        },
        dollarsPercentage() {
            if ((this?.groupBuy?.current_dollars ?? false) && (this?.groupBuy?.max_dollars)) {
                return `${Math.round((this.groupBuy.current_dollars / this.groupBuy.max_dollars) * 1000) / 10}%`
            }

            return "0%"
        },
        gbStatus() {
            if (!this.groupBuy) {
                return null
            }

            if (this.groupBuy.start_time < this.currentUnixTime() && this.groupBuy.end_time > this.currentUnixTime()) {
                return "current"
            }

            if (this.groupBuy.start_time < this.currentUnixTime() && this.groupBuy.end_time < this.currentUnixTime()) {
                return "ended"
            }
        },
        isOvertimeActive() {
            return this.gbStatus == 'current' && this.groupBuy.overtime && this.groupBuy.original_end_time && this.currentUnixTime() > this.groupBuy.original_end_time 
        },
        showEndTime() {
            return Boolean(this.groupBuy.end_time && !this.isLastDay && (this.isOvertimeActive || !this.groupBuy.overtime) && this.getCurrentTimeDiff(this.groupBuy.end_time))
        },
        showOriginalEndTime() {
            return Boolean(!this.isLastDay && !this.isOvertimeActive && this.groupBuy.overtime && this.groupBuy.original_end_time && this.getCurrentTimeDiff(this.groupBuy.original_end_time))
        },
        showOvertimeText() {
            return Boolean(this.groupBuy.end_time && this.isOvertimeActive && this.groupBuy.overtime_override_countdown && this.groupBuy.overtime_override_text && this.getCurrentTimeDiff(this.groupBuy.end_time))
        },
        ticketsPercentage() {
            if ((this?.groupBuy?.current_qty ?? false) && (this?.groupBuy?.max_quantity)) {
                return `${Math.round((this.groupBuy.current_qty / this.groupBuy.max_quantity) * 1000) / 10}%`
            }

            return "0%"
        },
        timeRemaining() {
            return this.groupBuy.end_time - this.currentUnixTime()
        }
    },
    mounted() {
        this.offerHasEnded = this.timeRemaining <= 0
        this.checkLastDay()
    },
    methods: {
        checkLastDay() {
            this.isLastDay = this.groupBuy.end_time - this.currentUnixTime() <= 86400

            if (!this.isLastDayCounting) {
                this.isLastDayCounting = true

                setInterval(() => {
                    this.checkLastDay()
                }, 300000)
            }
        },
        setOfferEnded() {
            this.$emit('setOfferEnded')
            this.offerHasEnded = true
        }
    }
}
</script>