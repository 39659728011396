<template>
    <div class="w-full">
        <div class="w-full flex justify-center">
            <img class="w-48 opacity-25" src="https://res.cloudinary.com/dnnzvmbis/image/upload/v1594705532/BuildZ%20Assets/buildz_logo_black_full_avxohl.png" />
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>