<template>
    <div v-if="formLoaded" class="w-full">
        <select-panel v-if="isGroupBuy"
            :fieldData="yesNoData" fieldFormName="has_eoi" fieldLabel="Offer Has E.O.I. Stage" :fieldNonEdit="pGroupBuyForm.eoi || !pGroupBuyForm.disabled"
            :fieldNonEditValue="displayValueHasEoi" :fieldValue="groupBuyForm.has_eoi" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel v-if="isGroupBuy"
            :fieldData="noYesData" fieldFormName="no_breakpoints" fieldLabel="Offer Has Breakpoints" :fieldNonEdit="pGroupBuyForm.eoi || !pGroupBuyForm.disabled"
            :fieldNonEditValue="displayValueNoBreakpoints" :fieldValue="groupBuyForm.no_breakpoints" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel
            :fieldData="yesNoData" fieldFormName="private" fieldLabel="Private Offer" :fieldNonEdit="pGroupBuyForm.eoi || !pGroupBuyForm.disabled"
            :fieldNonEditValue="displayValuePrivate" :fieldValue="groupBuyForm.private" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel v-if="isGroupBuy && sellerVendor.allow_immediate_group_buys"
            :fieldData="yesNoData" fieldFormName="immediate_payment" fieldLabel="Immediate Payment" 
            :fieldValue="groupBuyForm.immediate_payment" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel v-if="sellerVendor.white_label"
            :fieldData="yesNoData" fieldFormName="white_label_only" fieldLabel="White Label Only" 
            :fieldValue="groupBuyForm.white_label_only" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel v-if="isGiveAway"
            :fieldData="yesNoData" fieldFormName="secondary_offer" fieldLabel="Offer Has Secondary Draw"
            :fieldValue="groupBuyForm.secondary_offer" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
    </div>
</template>

<script>
export default {
    props: [
        'isGiveAway',
        'isGroupBuy',
        'isMemberGiveAway',
        'pGroupBuyForm'
    ],
    data() {
        return {
            formLoaded: false,
            groupBuyForm: {
                has_eoi: false,
                immediate_payment: false,
                no_breakpoints: false,
                private: false,
                secondary_offer: false,
                white_label_only: false
            }
        }
    },
    computed: {
        displayValueHasEoi() {
            return this.groupBuyForm.has_eoi ? "Yes" : "No"
        },
        displayValueNoBreakpoints() {
            return this.groupBuyForm.no_breakpoints ? "No" : "Yes"
        },
        displayValuePrivate() {
            return this.groupBuyForm.private ? "Yes" : "No"
        }
    },
    mounted() {
        this.groupBuyForm.has_eoi = this.pGroupBuyForm.has_eoi
        this.groupBuyForm.immediate_payment = this.pGroupBuyForm.immediate_payment
        this.groupBuyForm.no_breakpoints = this.pGroupBuyForm.no_breakpoints
        this.groupBuyForm.private = this.pGroupBuyForm.private
        this.groupBuyForm.secondary_offer = this.pGroupBuyForm.secondary_offer
        this.groupBuyForm.white_label_only = this.pGroupBuyForm.white_label_only
        this.formLoaded = true
    },
    methods: {
        updateFormSelectValue(inField, inValue) {
            this.groupBuyForm[inField] = inValue
            this.$emit('updateOptions', this.groupBuyForm, true)
        }
    }
}
</script>