<template>
    <div class="relative pt-16 pb-16" :class="{ 'page-standard-height-width': whiteLabel || !isDesktopWidth || !loggedUser, 'page-standard-height-width-desktop': !whiteLabel && isDesktopWidth && loggedUser, [outerClasses]: outerClasses }">
        <div :id="headerId" class="page-box" :class="{ [innerClasses]: innerClasses }">
            <slot name="main"></slot>
        </div>
        <slot name="footer"></slot>
    </div>
</template>

<script>
export default {
    props:[
        'headerId',
        'innerClasses',
        'outerClasses'
    ]
}
</script>