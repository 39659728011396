<template>
    <div class="w-full">
        <select-panel 
            :fieldData="buildTypes" fieldDataLabel="label" fieldDataValue="id" fieldFormName="defaultProductCategory" fieldLabel="Default Listing Category" 
            fieldLabelClasses="text-gray-800" :fieldRequired="true" :fieldValue="sellerDataForm.defaultProductCategory" :forceMobile="true"
            @fieldChange="updateSellerDataFormSelectValue"
        >
            <validation-message v-if="validating && !sellerDataForm.defaultProductCategory" 
                message="Default Listing Category must be selected"
            />
        </select-panel>
        <select-panel 
            :fieldData="buildTypes" fieldDataLabel="label" fieldDataValue="id" fieldFormName="defaultGroupBuyCategory" :fieldLabel="`Default ${pageData.groupBuy.adminLabelSingular} Category`" 
            fieldLabelClasses="text-gray-800" :fieldRequired="true" :fieldValue="sellerDataForm.defaultGroupBuyCategory" :forceMobile="true"
            @fieldChange="updateSellerDataFormSelectValue"
        >
            <validation-message v-if="validating && !sellerDataForm.defaultGroupBuyCategory" 
                :message="`Default ${pageData.groupBuy.adminLabelSingular} Category must be selected`"
            />
        </select-panel>
    </div>
</template>

<script>
export default {
    props: [
        'sellerDataForm',
        'validating'
    ],
    methods: {
        updateSellerDataFormSelectValue(inField, inValue) {
            this.$emit('updateSellerDataFormSelectValue', inField, inValue)
        }
    }
}
</script>