<template>
    <div class="drop-down-container" :class="{ 'relative': !absolutePosition, 'absolute': absolutePosition }">
        <div @click="dropDownOpen = !dropDownOpen">
            <span v-if="linkText" class="cursor-pointer" :class="linkClass">
                {{ linkText }}
            </span>
            <fa-icon v-else icon="chevron-down" type="fas" class="w-7 h-7 cursor-pointer" />
        </div>
        <div v-if="dropDownOpen" @click="dropDownOpen = false" class="drop-down-slot absolute sm:text-sm p-2 right-0 top-6 flex flex-col border rounded-sm z-10 popup" style="min-width: 100px;" v-click-outside="closeDropdown"> 
            <slot>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        absolutePosition: false,
        linkClass: null,
        linkText: null
    },
    data() {
        return {
            dropDownOpen: false
        }
    },
    methods: {
        closeDropdown() {
            this.dropDownOpen = false
        }
    }
}
</script>

<style>
    .drop-down-container .drop-down-slot span {
        @apply text-orange-400 whitespace-nowrap font-semibold p-1 py-2 cursor-pointer
    }

    .drop-down-container .drop-down-slot a {
        @apply text-orange-400 whitespace-nowrap font-semibold p-1 py-2 cursor-pointer
    }

    .drop-down-container .drop-down-slot div {
        @apply flex items-center
    }

    .drop-down-container .drop-down-slot div span {
        @apply text-orange-400 whitespace-nowrap font-semibold p-1 py-2 sm:py-1 cursor-pointer
    }

    .drop-down-container .drop-down-slot div a {
        @apply text-orange-400 whitespace-nowrap font-semibold p-1 py-2 sm:py-1 cursor-pointer
    }
</style>