export default {
    methods: {
        getIframeSrc(url){
            if (!url) {
                return null
            }

            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            const match = url.match(regExp);
        
            const id = (match && match[2].length === 11)
                ? match[2]
                : null;
            
            return `https://www.youtube.com/embed/${id}`
        },
        getChatIframeSrc(url) {
            if (!url) {
                return null
            }

            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            const match = url.match(regExp);

            const id = (match && match[2].length === 11)
                ? match[2]
                : null;

            return `https://www.youtube.com/live_chat?v=${id}`
        }
    }
}