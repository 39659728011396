<template>
    <div v-if="formLoaded" class="w-full p-2 white-transparent">
        <div class="w-full">
            <panel-sub-section
                sectionLabel="Select Vendor Product Variants" :sectionOpen="showVendorVariants"
                @cacheSectionOpen="clickShowVendorVariants"
            >
                <div v-for="vendorVariant in sellerVendor.product_variants">
                    <select-panel
                        :fieldData="yesNoData" :fieldFormName="vendorVariant.identifier" :fieldLabel="vendorVariant.label"
                        :fieldValue="isVendorVariantSelected(vendorVariant.identifier)" :isLabelAndValue="true"
                        @fieldChange="updateVariantSelected"
                    />
                </div>
            </panel-sub-section>
        </div>
        <template v-for="(vendorVariant, index) in sellerVendor.product_variants" :key="index">
            <div v-if="isVendorVariantSelected(vendorVariant.identifier)" class="mt-2">
                <panel-sub-section
                    :sectionIdentifier="vendorVariant.identifier" :sectionLabel="`${vendorVariant.label} Values`" :sectionOpen="showVariantValues.includes(vendorVariant.identifier)"
                    @cacheSectionOpen="clickShowVariantValues"
                >
                    <div class="w-full p-2">
                        <draggable
                            animation="250" easing="cubic-bezier(1, 0, 0, 1)" handle=".handle-value" item-key="index" :list="getRealVariantValues(vendorVariant.identifier)" tag="div" 
                            @change="saveOrder()"
                        >
                            <template #item="{element, index}">
                                <div class="relative">
                                    <panel-sub-section
                                        :sectionIdentifier="`${vendorVariant.identifier}|${element.identifier}`" :sectionLabel="element.label" :sectionOpen="isValueDetailOpen(vendorVariant.identifier, element.identifier)"
                                        @cacheSectionOpen="clickShowValueDetail"
                                    >
                                        <edit-variant-value
                                            :variantValue="element" :vendorVariant="vendorVariant"
                                            @updateVariantValue="updateVariantValue"
                                        />
                                    </panel-sub-section>
                                    <div class="absolute left-0 top-0 cursor-move handle-value p-2">
                                        <fa-icon icon="up-down-left-right" type="fas" class="h-5 w-5" />
                                    </div>
                                </div>
                            </template>
                        </draggable>
                        <div class="w-full orange-transparent" :class="{ 'mt-2': getRealVariantValues(vendorVariant.identifier).length }">
                            <panel-sub-section
                                :sectionIdentifier="vendorVariant.identifier" :sectionLabel="`Create A New ${vendorVariant.label}`" :sectionOpen="showNewVariantValue.includes(vendorVariant.identifier)"
                                @cacheSectionOpen="clickShowNewVariantValue"
                            >
                                <new-variant-value
                                    :variantValues="getRealVariantValues(vendorVariant.identifier)" :vendorVariant="vendorVariant"
                                    @clickCreateNewVariantValue="clickCreateNewVariantValue"
                                />
                            </panel-sub-section>
                        </div>    
                    </div>
                </panel-sub-section>
            </div>
        </template>
        <div v-if="variantForm.theVariants && variantForm.theVariants.length" class="w-full mt-2">
            <panel-sub-section
                sectionLabel="Variants" :sectionOpen="showTheVariants"
                @cacheSectionOpen="clickShowTheVariants"
            >
                <div v-for="aVariant in sortedTheVariants" :class="{ 'orange-transparent': !aVariant.enabled }">
                    <panel-sub-section
                        :sectionIdentifier="aVariant.identifier" :sectionLabel="getTheVariantDisplayLabel(aVariant)" :sectionOpen="showTheVariant.includes(aVariant.identifier)"
                        @cacheSectionOpen="clickShowTheVariant"
                    >
                        <edit-the-variant
                            :product="product" :theVariant="aVariant" :theVariantLabel="getTheVariantLabel(aVariant)" :theVariantPrice="getTheVariantPrice(aVariant)"
                            @updateTheVariant="updateTheVariant"
                        />
                    </panel-sub-section>
                </div>
            </panel-sub-section>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

import EditTheVariant from './EditTheVariant'
import EditVariantValue from './EditVariantValue'
import NewVariantValue from './NewVariantValue'

export default {
    props: [
        'product',
        'productVariants'
    ],
    components: {
        draggable,
        EditTheVariant,
        EditVariantValue,
        NewVariantValue
    },
    data() {
        return {
            formLoaded: false,
            showNewVariantValue: [],
            showTheVariant: [],
            showTheVariants: false,
            showValueDetail: [],
            showVariantValues: [],
            showVendorVariants: false,
            variantForm: {
                theVariants: null,
                vendorVariants: null,
                variantValues: null
            }
        }
    },
    computed: {
        areVendorVariantsOkay() {
            if (this.variantForm.vendorVariants && this.variantForm.vendorVariants.length) {
                let myCount = this.variantForm.vendorVariants.filter((myVariant) => myVariant.selected).length

                if (myCount && this.variantForm.variantValues && this.variantForm.variantValues.length && myCount == this.variantForm.variantValues.length) {
                    return true
                }
            }

            return false
        },
        sortedTheVariants() {
            if (!this.variantForm.theVariants && !this.variantForm.theVariants.length) {
                return null
            }

            return this.variantForm.theVariants
                .sort((a, b) => a.identifier < b.identifier ? -1 : 1)
        }
    },
    mounted() {
        let myVendorVariants = []

        this.sellerVendor.product_variants.forEach((mySellerVariant) => {
            let mySelected = false

            if (this.productVariants && this.productVariants.vendorVariants) {
                let myVariant = this.productVariants.vendorVariants.find((myVariant) => myVariant.identifier == mySellerVariant.identifier)
                mySelected = myVariant?.selected ?? false
            }

            let myNewVariant = {
                identifier: mySellerVariant.identifier,
                label: mySellerVariant.label,
                selected: mySelected
            }

            myVendorVariants.push(myNewVariant)
        })

        this.variantForm.vendorVariants = myVendorVariants

        if (this.productVariants && this.productVariants.variantValues) {
            this.variantForm.variantValues = Object.values(this.objCopy(this.productVariants.variantValues))
        }

        if (this.productVariants && this.productVariants.theVariants) {
            this.variantForm.theVariants = Object.values(this.objCopy(this.productVariants.theVariants))
        }

        this.formLoaded = true
    },
    methods: {
        buildVariants(inVariantIdentifier, inValueIdentifier) {
            let myVariants = []
            let myCount = 0
            let myVariantString = ""

            this.variantForm.vendorVariants
                .forEach((myVendorVariant) => {
                    let myVariant = this.variantForm.variantValues.find((vfVariant) => vfVariant.identifier == myVendorVariant.identifier)
                    
                    if (myVariant) {
                        if (myVariant.identifier != inVariantIdentifier) {
                            myVariants[myCount] = []

                            myVariant.values
                                .sort((a, b) => a.identifier < b.identifier ? -1 : 1)
                                .forEach((myValue) => {
                                    myVariants[myCount].push(myValue.identifier)
                                })
                        }
                        else {
                            myVariants[myCount] = [inValueIdentifier]
                        }

                        if (myCount > 0) {
                            myVariantString = `${myVariantString}|` 
                        }

                        myVariantString = `${myVariantString}${myVariant.identifier}` 
                        myCount++
                    }
                })
            
            let myStrings = []

            if (myVariants.length) {
                myVariants[0].forEach((myValue) => {
                    myStrings.push(myValue)
                    
                    myVariants.forEach((myVariant, index) => {
                        if (index != 0) {
                            myVariant.forEach((myNextValue) => {
                                myStrings.forEach((myVString) => {
                                    let pipeCount = myVString.split("|").length

                                    if (pipeCount == index && myVString.split("|")[0] == myValue) {
                                        myStrings.push(`${myVString}|${myNextValue}`)
                                    }
                                })
                            })
                        }
                    })
                })

                myStrings.forEach((myString) => {
                    let mySectionsCount = myString.split("|").length

                    if (mySectionsCount == myVariants.length) {
                        let myVariant = null

                        if (this.variantForm.theVariants && this.variantForm.theVariants.length) {
                            myVariant = this.variantForm.theVariants.find((tVariant) => tVariant.identifier == myString)
                        }
                        else {
                            this.variantForm.theVariants = []
                        }

                        if (!myVariant) {
                            let myNewVariant = {
                                enabled: true,
                                identifier: myString,
                                overridePrice: null,
                                qty: null,
                                sku: null,
                                variants: myVariantString
                            }

                            this.variantForm.theVariants.push(myNewVariant)
                        }
                    }
                })
            }

            this.$emit('updateVariants', this.variantForm, true)
        },
        clickCreateNewVariantValue(inForm, inVariantIdentifier) {
            let myVariantValues = this.variantForm.variantValues ?? []
            let myVariantValue = myVariantValues.find((myValue) => myValue.identifier == inVariantIdentifier)
            let myValues = myVariantValue?.values ?? []

            myValues.push(inForm)

            if (myVariantValue) {
                myVariantValue.values = myValues
            }
            else {
                myVariantValue = {
                    identifier: inVariantIdentifier,
                    values: myValues
                }
            }

            myVariantValues = myVariantValues.filter((myValue) => myValue.identifier != inVariantIdentifier)
            myVariantValues.push(myVariantValue)
            this.variantForm.variantValues = myVariantValues

            if (this.areVendorVariantsOkay) {
                this.buildVariants(inVariantIdentifier, inForm.identifier)
            }
            else {
                this.$emit('updateVariants', this.variantForm, true)
            }
        },
        clickShowNewVariantValue(inIsOpen, inIdentifier) {
            if (inIsOpen) {
                if (!this.showNewVariantValue.includes(inIdentifier)) {
                    this.showNewVariantValue.push(inIdentifier)
                }
            }
            else {
                this.showNewVariantValue = this.showNewVariantValue.filter((myValue) => myValue != inIdentifier)
            }
        },
        clickShowTheVariant(inIsOpen, inIdentifier) {
            if (inIsOpen) {
                if (!this.showTheVariant.includes(inIdentifier)) {
                    this.showTheVariant.push(inIdentifier)
                }
            }
            else {
                this.showTheVariant = this.showTheVariant.filter((myValue) => myValue != inIdentifier)
            }
        },
        clickShowTheVariants(inIsOpen) {
            this.showTheVariants = inIsOpen
        },
        clickShowValueDetail(inIsOpen, inIdentifier) {
            if (inIsOpen) {
                if (!this.showValueDetail.includes(inIdentifier)) {
                    this.showValueDetail.push(inIdentifier)
                }
            }
            else {
                this.showValueDetail = this.showValueDetail.filter((myValue) => myValue != inIdentifier)
            }
        },
        clickShowVariantValues(inIsOpen, inIdentifier) {
            if (inIsOpen) {
                if (!this.showVariantValues.includes(inIdentifier)) {
                    this.showVariantValues.push(inIdentifier)
                }
            }
            else {
                this.showVariantValues = this.showVariantValues.filter((myValue) => myValue != inIdentifier)
            }
        },
        clickShowVendorVariants(inIsOpen) {
            this.showVendorVariants = inIsOpen
        },
        getRealVariantValues(inIdentifier) {
            if (this.variantForm.variantValues) {
                let myVariantValues = this.variantForm.variantValues.find((myValues) => myValues.identifier == inIdentifier)

                if (myVariantValues) {
                    return this.variantForm.variantValues.find((myValues) => myValues.identifier == inIdentifier).values
                }
            }

            return []
        },
        getTheVariantDisplayLabel(inTheVariant) {
            let myPriceLabel = ""
            let myAmount = 0

            if (inTheVariant.overridePrice) {
                myAmount = inTheVariant.overridePrice
            }
            else {
                myAmount = this.getTheVariantPrice(inTheVariant)
            }

            myPriceLabel = ` (${this.displayPrice(this.sellerVendorData.defaultCurrency, myAmount)})`
            
            let myQtyLabel = ""

            if (inTheVariant.qty && this.product.track_quantity) {
                myQtyLabel = ` (${inTheVariant.qty} in stock)`
            }

            let mySkuLabel = ""

            if (inTheVariant.sku) {
                mySkuLabel = ` ${inTheVariant.sku}`
            }

            return `${this.getTheVariantLabel(inTheVariant)}${mySkuLabel}${myPriceLabel}${myQtyLabel}`
        },
        getTheVariantPrice(inTheVariant) {
            let myValues = inTheVariant.identifier.split("|")
            let myVariants = inTheVariant.variants.split("|")
            let myPrice = parseFloat(this?.product.price ?? 0)

            myVariants.forEach((myVariant, index) => {
                let myVariantValue = this.variantForm.variantValues.find((vValue) => vValue.identifier == myVariant)
                let myValue = myVariantValue.values.find((vvValue) => vvValue.identifier == myValues[index])

                myPrice = myPrice + (parseFloat(myValue?.priceVariance ?? 0))
            })

            return myPrice
        },
        getTheVariantLabel(inTheVariant) {
            let myValues = inTheVariant.identifier.split("|")
            let myVariants = inTheVariant.variants.split("|")
            let myLabel = ""

            myVariants.forEach((myVariant, index) => {
                let myVariantValue = this.variantForm.variantValues.find((vValue) => vValue.identifier == myVariant)
                let myValue = myVariantValue.values.find((vvValue) => vvValue.identifier == myValues[index])

                if (index > 0) {
                    myLabel = `${myLabel}-`
                }

                myLabel = `${myLabel}${myValue.label}`
            })

            return myLabel
        },
        isValueDetailOpen(inVariantIdentifier, inValueIdentifier) {
            let myIdentifier = `${inVariantIdentifier}|${inValueIdentifier}`
            return this.showValueDetail.includes(myIdentifier)
        },
        isVendorVariantSelected(inIdentifier) {
            let myVendorVariant = this.variantForm.vendorVariants.find((myVariant) => myVariant.identifier == inIdentifier)
            return myVendorVariant.selected ?? false
        },
        saveOrder() {
            this.$emit('updateVariants', this.variantForm, true)
        },
        updateTheVariant(inForm, inSaveImmediate) {
            this.variantForm.theVariants.find((myVariant) => myVariant.identifier == inForm.identifier).qty = inForm.qty
            this.variantForm.theVariants.find((myVariant) => myVariant.identifier == inForm.identifier).sku = inForm.sku
            this.variantForm.theVariants.find((myVariant) => myVariant.identifier == inForm.identifier).enabled = inForm.enabled
            this.variantForm.theVariants.find((myVariant) => myVariant.identifier == inForm.identifier).overridePrice = inForm.overridePrice
            this.$emit('updateVariants', this.variantForm, inSaveImmediate)
        },
        updateVariantSelected(inField, inValue) {
            let myVendorVariant = this.variantForm.vendorVariants.find((myVariant) => myVariant.identifier == inField)
            myVendorVariant.selected = inValue
            this.$emit('updateVariants', this.variantForm, true)
        },
        updateVariantValue(inForm, inVariantIdentifier, inValueIdentifier, inSaveImmediate) {
            let myVariantValues = this.variantForm.variantValues ?? []
            let myVariantValue = myVariantValues.find((myValue) => myValue.identifier == inVariantIdentifier)
            let myValues = myVariantValue?.values ?? []

            myValues.find((myValue) => myValue.identifier == inValueIdentifier).label = inForm.label
            myValues.find((myValue) => myValue.identifier == inValueIdentifier).enabled = inForm.enabled
            myValues.find((myValue) => myValue.identifier == inValueIdentifier).priceVariance = inForm.priceVariance
            this.variantForm.variantValues.find((myValue) => myValue.identifier == inVariantIdentifier).values = myValues

            if (this.areVendorVariantsOkay) {
                this.buildVariants(inVariantIdentifier, inForm.identifier)
            }
            else {
                this.$emit('updateVariants', this.variantForm, inSaveImmediate)
            }
        }
    }
}
</script>