<template>
    <div class="text-white">
        <media-panel v-if="avatarArray"
            fieldLabel="Avatar" fieldLabelClasses="text-gray-800" :fieldRequired="true" :imagesArray="avatarArray" :imagesOnly="true" :maxFiles="1" :multiple="false"
            @imageSavedOkay="avatarSavedOkay" @saveMedia="saveLocalAvatar" @updateOfflineMedia="updateOfflineAvatar"
        >
            <validation-message v-if="validating && (!avatarArray || !avatarArray.length)" 
                message="Avatar must be selected"
            />
        </media-panel>
    </div>
</template>

<script>
export default {
    props: [
        'avatarArray',
        'validating'
    ],
    methods: {
        avatarSavedOkay(inImage, inIndex) {
            this.$emit('avatarSavedOkay', inImage, inIndex)
        },
        saveLocalAvatar(inGallery) {
            this.$emit('saveLocalAvatar', inGallery)
        },
        updateOfflineAvatar(inGallery) {
            this.$emit('updateOfflineAvatar', inGallery)
        }
    }
}
</script>