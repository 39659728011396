<template>
    <div v-if="!isToggleFollowing || showUnfollow" @click.stop="clickButton" class="cursor-pointer" :class="{ [buttonClasses]: true, 'standard-button-base': !textOnly, 'unfollow-button': isToggleFollowing && !textOnly, 'follow-button': !isToggleFollowing && !textOnly, 'leading-5': !small, 'px-1': showFollowers }">
        <span v-if="showFollowers && followersCount > 0 && !textOnly" class="mr-1 text-xs">{{ followersCount }}</span>
        <fa-icon v-if="!textOnly" icon="users" type="fas" :class="{ 'w-3 h-3 -ml-1': small, 'w-4 h-4': !small }" />
        <span v-if="showText" class="ml-1 whitespace-nowrap" :class="{ [textClasses]: true }">{{ isToggleFollowing ? 'Following' : 'Follow' }}</span>
    </div>
</template>

<script>
    export default {
        props: [
            'buildId',
            'buttonClasses',
            'followersCount',
            'fromBuild',
            'isFollowing',
            'owner',
            'queryId',
            'showFollowers',
            'showText',
            'showUnfollow',
            'small',
            'textClasses',
            'textOnly'
        ],
        computed: {
            isToggleFollowing() {
                return (this.isFollowing && !this.isToggling) || (!this.isFollowing && this.isToggling)
            },
            isToggling() {
                return this.userTogglingFollows && this.userTogglingFollows.includes(parseInt(this.owner.id))
            }
        },
        methods: {
            clickButton() {
                if (!this.isToggling && this.serverConnected) {
                    this.toggleFollowOwner(this.owner)
                    this.algoliaConvert(this.fromBuild && this.buildId ? this.buildId : this.owner.id, this.fromBuild && this.buildId ? 'builds' : 'users', this.queryId)
                }
            },
        }    
    }
</script>