<template>
    <div v-if="formLoaded" class="w-full">
        <select-panel
            :fieldData="buildTypes" fieldDataLabel="label" fieldDataValue="id" fieldFormName="buildz_group_id" fieldLabel="Build Category" :fieldNonEdit="!pGroupBuyForm.disabled"
            :fieldNonEditValue="displayValueBuildType" :fieldValue="groupBuyForm.buildz_group_id" hideFieldFormName="hide_build_category" 
            :hideFieldValue="groupBuyForm.hide_build_category" :hideIcon="true"
            @fieldChange="updateFormSelectValue"
        />
        <input-panel v-if="isGroupBuy"
            fieldFormName="min_order_qty" fieldLabel="Combined Product Minimum Order Quantity (CPMOQ)" :fieldNonEdit="!pGroupBuyForm.disabled" fieldPlaceholder="Minimum Order Quantity" 
            :fieldRequired="true" fieldType="number" :fieldSubLabel="cpmoqSubLabel" :fieldValue="groupBuyForm.min_order_qty"
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="groupBuyForm.min_order_qty < 1" 
                message="CPMOQ must be greater than zero"
            />
        </input-panel>
        <text-area-panel 
            fieldFormName="keywords" fieldLabel="Keywords" fieldPlaceholder="Keywords" fieldSubLabel="Maximum of 250 characters" :fieldValue="groupBuyForm.keywords"
            rows="5"
            @fieldInput="updateKeywordsValue"
        />
        <media-panel v-if="imagesArray"
            fieldLabel="Media" :imagesArray="imagesArray" :imagesOnly="false" :maxFiles="10" :multiple="true"
            @saveMedia="saveLocalGallery" @updateOfflineMedia="updateOfflineMedia"
        />
        <input-panel v-if="!isGroupBuy"
            fieldFormName="live_url" fieldLabel="Live Stream URL" fieldPlaceholder="Live Stream URL" 
            fieldType="text" :fieldValue="groupBuyForm.live_url"
            @fieldInput="updateFormValue"
        />
        <select-panel v-if="!isGroupBuy"
            :fieldData="yesNoData" fieldFormName="live_now" fieldLabel="Live Now" 
            :fieldValue="groupBuyForm.live_now" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <rich-text-panel
            fieldFormName="purchase_confirmation_email_text" fieldLabel="Receipt Email Text" :fieldValue="groupBuyForm.purchase_confirmation_email_text"
            @fieldInput="updateFormValue"
        />
        <rich-text-panel v-if="isGiveAway"
            fieldFormName="ticket_email_text" fieldLabel="Ticket Email Text" :fieldValue="groupBuyForm.ticket_email_text"
            @fieldInput="updateFormValue"
        />
    </div>
</template>

<script>
export default {
    props: [
        'isGiveAway',
        'isGroupBuy',
        'isMemberGiveAway',
        'pGallery', 
        'pGroupBuy', 
        'pGroupBuyForm'
    ],
    data() {
        return {
            cpmoqSubLabel: null,
            formLoaded: false,
            groupBuyForm: {
                buildz_group_id: null,
                hide_build_category: false,
                hide_offer_description: false,
                keywords: null,
                live_now: false,
                live_url: null,
                min_order_qty: 1,
                no_breakpoints: false,
                purchase_confirmation_email_text: null,
                secondary_offer: false,
                ticket_email_text: null
            }
        }
    },
    computed: {
        displayValueBuildType() {
            return !this.groupBuyForm.buildz_group_id ? null :
                Object.values(this.buildTypes).find((buildType) => buildType.id == this.groupBuyForm.buildz_group_id).label 
        },
        imagesArray() {
            let myGallery = []

            this.pGallery.forEach((myImage) => {
                myGallery.push(myImage)
            })

            return myGallery
        }
    },
    mounted() {
        this.groupBuyForm.buildz_group_id = this.pGroupBuyForm.buildz_group_id
        this.groupBuyForm.hide_build_category = this.pGroupBuyForm.hide_build_category
        this.groupBuyForm.hide_offer_description = this.pGroupBuyForm.hide_offer_description
        this.groupBuyForm.keywords = this.pGroupBuyForm.keywords
        this.groupBuyForm.live_now = this.pGroupBuyForm.live_now
        this.groupBuyForm.live_url = this.pGroupBuyForm.live_url
        this.groupBuyForm.min_order_qty = this.pGroupBuyForm.min_order_qty != null ? this.pGroupBuyForm.min_order_qty : 1
        this.groupBuyForm.no_breakpoints = this.pGroupBuyForm.no_breakpoints
        this.groupBuyForm.purchase_confirmation_email_text = this.pGroupBuyForm.purchase_confirmation_email_text
        this.groupBuyForm.secondary_offer = this.pGroupBuyForm.secondary_offer
        this.groupBuyForm.ticket_email_text = this.pGroupBuyForm.ticket_email_text
        this.cpmoqSubLabel = "For example, if the CPMOQ is 5, then a customer must choose a minimum of 5 units across all Products available to join this Group Offer."
        this.formLoaded = true
    },
    methods: {
        saveLocalGallery(inGallery) {
            this.$emit('saveLocalGallery', inGallery)
        },
        updateFormSelectValue(inField, inValue) {
            this.groupBuyForm[inField] = inValue
            this.$emit('updateDetails', this.groupBuyForm, true)
        },
        updateFormValue(inField, inValue) {
            this.groupBuyForm[inField] = inValue
            this.$emit('updateDetails', this.groupBuyForm, false)
        },
        updateKeywordsValue(inField, inValue) {
            if (inValue.length > 250) {
                alert('Keywords will be trimmed to 250 characters.')
            }

            this.groupBuyForm[inField] = inValue.substring(0, 250)
            this.$emit('updateDetails', this.groupBuyForm, false)
        },
        updateOfflineMedia(inGallery) {
            this.$emit('updateOfflineMedia', inGallery)
        }
    }
}
</script>