<template>
    <div class="ml-9">
        <div v-if="lt && !lastPage" @click="getRecords" class="flex justify-center w-full py-2 mt-3 font-bold text-orange-400 bg-white border border-orange-400 rounded cursor-pointer">
            Load more replies
        </div>
        <template v-for="(comment, index) in sortedComments" :key="index">
            <comment v-if="!amIBlocking(comment.user_id)" 
                :comment="comment" 
            />
        </template>
    </div>
</template>

<script>
import Comment from './Comment'

export default {
    props: {
        reaction: null
    },
    components: {
        Comment
    },
    data() {
        return {
            gettingRecords: false,
            lastPage: false,
            lt: null,
        }
    },
    computed: {
        comments() {
            return this.reaction.comments ? this.reaction.comments : {}
        },
        isFocusedComment() {
            return false
        },
        sortedComments() {
            return !this.comments ? {} : Object.values(this.comments)
                .sort((a, b) => a.created_at > b.created_at ? 1 : -1)
        }
    },
    watch: {
        comments: {
            handler(val) {
                if ((!val || val.length == 0) && this.reaction.latest_children && this.reaction.latest_children.comment) {
                    this.getLatestChildren(this.reaction.latest_children.comment.slice(0, 5))
                }        
            }
        }
    },
    mounted() {
        if (this.reaction.latest_children && this.reaction.latest_children.comment) {
            this.getLatestChildren(this.reaction.latest_children.comment.slice(0, 5))
        }
    },
    methods: {
        getLatestChildren(inLatestChildren) {
            this.lt = this.getLastArrayKey(inLatestChildren).id
            this.lastPage = inLatestChildren.length == this.reaction.children_counts.comment
            let myReactions = this.objCopy(this.reactions)
            
            myReactions = Object.values(myReactions).map((reaction) => {
                if (reaction.id == this.reaction.id) {
                    reaction.comments = inLatestChildren
                }

                return reaction
            })

            this.replaceStateData([
                { reactions: this.objCopy(myReactions) }
            ])
        },
        async getRecords() {
            if (!this.gettingRecords) {
                this.gettingRecords = true 
                const reactionData = await this.getChildReactions(this.reaction, this.lt)
                
                if (reactionData && reactionData.results && reactionData.results.length) {
                    this.lt = this.getLastArrayKey(reactionData.results).id
                    let myReactions = this.objCopy(this.reactions)
                    
                    myReactions = Object.values(myReactions).map((reaction) => {
                        if (reaction.id == this.reaction.id) {
                            let myComments = this.objCopy(reaction.comments)
                            myComments = [...myComments, ...reactionData.results]
                            reaction.comments = myComments
                            this.lastPage = myComments.length == this.reaction.children_counts.comment
                        }

                        return reaction
                    })
                    
                    this.replaceStateData([
                        { reactions: this.objCopy(myReactions) }
                    ])
                }
                else {
                    this.lastPage = true
                }

                this.gettingRecords = false
            }
        }
    }
}
</script>
