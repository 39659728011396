<template>
    <modal v-if="showPopup" :open="showPopup" @close="closePopup" class="text-white">
        <modal-background-image
            :bgImage="buildImage"
        />
        <div class="absolute top-0 left-0 w-full" :class="{ 'pb-16 px-1': windowWidth < desktopMaxWidth, 'desktop-modal-box': windowWidth >= desktopMaxWidth }">
            <div :class="{ 'desktop-modal': windowWidth >= desktopMaxWidth }">
                <div class="w-full mt-4">
                    <h3 class="section-heading mb-3 text-center">
                        Import a Build List
                    </h3>
                    <div v-if="page == 1">
                        <div class="mt-2">
                            <div>
                                <textarea
                                    placeholder="Paste your list here"
                                    ref="myTextarea"
                                    v-model="rawData"
                                    class="w-full p-2 text-sm bg-white border border-gray-300 rounded focus:border-orange-500 focus:ring-orange-300 edit-field-text-color"
                                    rows="20"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-if="page == 2 && groups && groups.length">
                        <div>
                            <label class="text-sm font-bold leading-5">
                                Name
                            </label>
                            <div>
                                <input ref="name" type="text" maxlength="50" v-model="newList.name" @keyup.enter="$event.target.blur()" required class="form-input edit-field-text-color bg-white py-1 w-full" placeholder="Name of your new list" />
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="text-sm font-bold leading-5 text-center w-full">
                                List Type
                            </div>
                            <div class="round-tabs-container my-2 px-1">
                                <div v-for="(type, index) in buildAvailableListTypes" @click="newList.type = type" class="round-tab" :class="{ 'round-tab-off': newList.type != type, 'round-tab-on': newList.type == type }">
                                    {{ type }}
                                </div>
                            </div>
                        </div>
                        <draggable class="dragArea" item-key="id" tag="div" v-model="groups" handle=".handle-group" dragClass="drag-class" animation="250" easing="cubic-bezier(1, 0, 0, 1)">
                            <template #item="{element, index}">
                                <div class="pt-2">    
                                    <div class="flex justify-end items-center mb-2">
                                        <div class="cursor-move handle-group mr-4">
                                            <fa-icon icon="arrows-up-down-left-right" type="fas" class="h-5 w-5" />
                                        </div>
                                        <div v-if="index > 0" @click="clickGroupToMod(element, index)" class="mr-4 cursor-pointer mr-4">
                                            <fa-icon icon="folder-minus" type="fas" class="h-5 w-5" />
                                        </div>
                                        <div @click="clickEditGroup(element)" class="cursor-pointer mr-4">
                                            <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                        </div>
                                        <div @click="clickDeleteGroup(element)" class="cursor-pointer">
                                            <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                        </div>
                                    </div>
                                    <div class="flex items-center mb-2">
                                        <div class="text-orange-500">
                                            <fa-icon icon="folder" type="fas" class="h-4 w-4 mr-2" />
                                        </div>
                                        <div v-if="!editGroups.includes(element.id)" class="font-bold">
                                            {{ element.name }}
                                        </div>
                                        <div v-else class="w-full">
                                            <input type="text" v-model="element.name" required class="form-input edit-field-text-color bg-white py-1 w-full" />
                                        </div>
                                    </div>
                                    <draggable class="dragArea" group="mods" item-key="id" tag="div" v-model="element.mods" handle=".handle-mod" dragClass="drag-class" animation="250" easing="cubic-bezier(1, 0, 0, 1)">
                                        <template #item="{element, index}"> 
                                            <div class="w-full p-2 white-transparent" style="margin-bottom: 1px;">
                                                <div class="flex justify-end items-center mb-2">
                                                    <div class="cursor-move handle-mod mr-4">
                                                        <fa-icon icon="arrows-up-down-left-right" type="fas" class="h-5 w-5" />
                                                    </div>
                                                    <div v-if="element.details.length == 0" @click="clickModToGroup(element, index)" class="mr-4 cursor-pointer">
                                                        <fa-icon icon="folder-plus" type="fas" class="h-5 w-5" />
                                                    </div>
                                                    <div v-if="index > 0 && element.details.length == 0" @click="clickModToDetail(element, index)" class="mr-4 cursor-pointer">
                                                        <fa-icon icon="comment-medical" type="fas" class="h-5 w-5" />
                                                    </div>
                                                    <div @click="clickEditMod(element)" class="cursor-pointer mr-4">
                                                        <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                                    </div>
                                                    <div @click="clickDeleteMod(element)" class="cursor-pointer">
                                                        <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div v-if="!editMods.includes(element.id)">
                                                        {{ element.name }}
                                                    </div>
                                                    <div v-else class="w-full">
                                                        <input type="text" v-model="element.name" required class="form-input edit-field-text-color bg-white py-1 w-full" />
                                                    </div>
                                                    <div class="text-sm mt-2">
                                                        <div v-for="detail in element.details" class="border border-white p-2" style="margin-bottom: 1px;">
                                                            <div class="flex items-center justify-end mb-2">
                                                                <div @click="clickDetailToMod(detail)" class="mr-4 cursor-pointer">
                                                                    <fa-icon icon="comment-slash" type="fas" class="h-5 w-5" />
                                                                </div>
                                                                <div @click="clickEditDetail(detail)" class="cursor-pointer mr-4">
                                                                    <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                                                                </div>
                                                                <div @click="clickDeleteDetail(detail)" class="cursor-pointer">
                                                                    <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                                                                </div>
                                                            </div>
                                                            <div v-if="!editDetails.includes(detail.id)">
                                                                {{ detail.text }}
                                                            </div>
                                                            <div v-else class="w-full">
                                                                <textarea v-model="detail.text" rows="3" required class="w-full p-2 text-sm bg-white border border-gray-300 rounded focus:border-orange-500 focus:ring-orange-300 edit-field-text-color" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </draggable>
                                </div>
                            </template>
                        </draggable>
                    </div>
                </div>
                <div class="mt-6 pb-8 sm:pb-0 sm:flex sm:justify-end">
                    <div v-show="page == 1 && rawData" @click="clickContinue()" class="standard-button-base sm:mr-2 mb-2">
                        Continue
                    </div>
                    <div v-show="page == 2" @click="clickCreate()" class="standard-button-base mb-2 sm:mr-2">
                        Create List
                    </div>
                    <div @click="closePopup()" class="alt-button-base mb-2">
                        Cancel
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import draggable from 'vuedraggable'
import Modal from '@/shared/Modal'
import ModalBackgroundImage from '@/shared/ModalBackgroundImage'

export default {
    props: ['propLists', 'showPopup', 'buildImage', 'build'],
    components: {
        draggable,
        Modal,
        ModalBackgroundImage
    },
    data() {
        return {
            detailId: 1,
            editDetails: [],
            editGroups: [],
            editMods: [],
            groupId: 1,
            groups: [
                {
                    id: 1,
                    name: 'Default group',
                    mods: [],
                }
            ],
            modId: 1,
            newList: {
                name: 'Imported list',
                type: 'Active',
                categories: null
            },
            page: 1,
            rawData: ''
        }
    },
    computed: {
        buildAvailableListTypes() {
            return this.build?.availableListTypes ?? []
        }
    },
    methods: {
        clickContinue() {
            let myMods = this.rawData.split(/\r\n|\r|\n/)
                .filter((mod) => mod.length > 0)
                .map((mod, index) => {
                    return { 
                        id: index + 1,
                        name: mod,
                        details: [],
                        groupId: 1
                    }
                })

            this.modId = myMods.length
            this.groups[0].mods = myMods
            this.page = 2
        },
        clickCreate() {
            this.openSpinner()
            this.saveStart()
            this.closePopup()

            this.newList.categories = this.objCopy(this.groups)
            
            buildzAxios?.post(this.getRoute('buildListImport', this.build.id), 
                {
                    'new_list' : this.newList 
                })
                .then((response) => {
                    this.saveDone()
                    this.closeSpinner()

                    if (response.status == 200 && response.data.build) {
                        this.$emit('updateBuild', this.objCopy(response.data.build))
                    }
                    else {
                        this.pageMessage('error', 'Error posting data')
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.pageMessage('error', 'Error posting data')
                    this.saveDone()
                    this.closeSpinner()
                })
        },
        clickDeleteDetail(inDetail) {
            if (confirm(`You wish to delete description line '${inDetail.text}'`)) {
                let myGroup = this.objCopy(this.groups.filter((group) => group.id == inDetail.groupId)[0])
                let myMods = this.objCopy(myGroup.mods)
                let myMod = this.objCopy(myMods.filter((mod) => mod.id == inDetail.modId)[0])
                let myDetails = this.objCopy(myMod.details)

                myDetails = myDetails.filter((detail) => detail.id != inDetail.id)
                myMod.details = myDetails

                myMods.forEach((mod, index) => {
                    if (mod.id == myMod.id) {
                        myMods[index] = myMod
                    }
                })

                myGroup.mods = myMods

                this.groups.forEach((group, index) => {
                    if (group.id == myGroup.id) {
                        this.groups[index] = myGroup
                    }
                })
            }
        },
        clickDeleteGroup(inGroup) {
            if (confirm(`You wish to delete group '${inGroup.name}'`)) {
                this.groups = this.groups.filter((group) => group.id != inGroup.id)
            }
        },
        clickDeleteMod(inMod) {
            if (confirm(`You wish to delete mod '${inMod.name}'`)) {
                let myGroup = this.objCopy(this.groups.filter((group) => group.id == inMod.groupId)[0])
                let myMods = this.objCopy(myGroup.mods)

                myMods = myMods.filter((mod) => mod.id != inMod.id)
                myGroup.mods = myMods

                this.groups.forEach((group, index) => {
                    if (group.id == myGroup.id) {
                        this.groups[index] = myGroup
                    }
                })
            }
        },
        clickDetailToMod(inDetail) {
            if (confirm(`You wish to make description line '${inDetail.text}' a mod`)) {
                let myGroup = this.objCopy(this.groups.filter((group) => group.id == inDetail.groupId)[0])
                let myMods = this.objCopy(myGroup.mods)
                let myMod = this.objCopy(myMods.filter((mod) => mod.id == inDetail.modId)[0])
                let myDetails = this.objCopy(myMod.details)

                myDetails = myDetails.filter((detail) => detail.id != inDetail.id)
                myMod.details = myDetails

                let newMod = { 
                    id: this.modId + 1,
                    name: inDetail.text,
                    details: [],
                    groupId: myGroup.id
                }

                this.modId = this.modId + 1

                let myModIndex = null

                myMods.forEach((mod, index) => {
                    if (mod.id == inDetail.modId) {
                        myModIndex = index
                    }
                })

                let preMods = []
                let postMods = []

                myMods.forEach((mod, index) => {
                    if (index < myModIndex) {
                        preMods.push(mod)
                    }

                    if (index > myModIndex) {
                        postMods.push(mod)
                    }
                })

                myMods = [...preMods, ...[myMod], ...[newMod], ...postMods]
                myGroup.mods = myMods

                this.groups.forEach((group, index) => {
                    if (group.id == myGroup.id) {
                        this.groups[index] = myGroup
                    }
                })
            }
        },
        clickEditDetail(inDetail) {
            if (this.editDetails.includes(inDetail.id)) {
                this.editDetails = this.editDetails.filter((detail) => detail != inDetail.id)
            }
            else {
                this.editDetails.push(inDetail.id)
            }
        },
        clickEditGroup(inGroup) {
            if (this.editGroups.includes(inGroup.id)) {
                this.editGroups = this.editGroups.filter((group) => group != inGroup.id)
            }
            else {
                this.editGroups.push(inGroup.id)
            }
        },
        clickEditMod(inMod) {
            if (this.editMods.includes(inMod.id)) {
                this.editMods = this.editMods.filter((mod) => mod != inMod.id)
            }
            else {
                this.editMods.push(inMod.id)
            }
        },
        clickGroupToMod(inGroup, inIndex) {
            if (confirm(`You wish to make '${inGroup.name}' a mod`)) {
                let parentGroup = this.objCopy(this.groups[inIndex - 1])
                let myGroup = this.objCopy(this.groups[inIndex])
                let parentMods = this.objCopy(parentGroup.mods)
                let myMods = this.objCopy(myGroup.mods)

                let newMod = { 
                    id: this.modId + 1,
                    name: myGroup.name,
                    details: [],
                    groupId: parentGroup.id
                }

                this.modId = this.modId + 1

                myMods.map((mod) => {
                    mod.groupId = parentGroup.id
                })

                parentMods = [...parentMods, ...[newMod], ...myMods]
                parentGroup.mods = parentMods

                this.groups.forEach((group, index) => {
                    if (group.id == parentGroup.id) {
                        this.groups[index] = parentGroup
                    }
                })

                this.groups = this.groups.filter((group) => group.id != myGroup.id)
            }
        },  
        clickModToDetail(inMod, inIndex) {
            let myGroup = this.objCopy(this.groups.filter((group) => group.id == inMod.groupId)[0])
            let myMods = this.objCopy(myGroup.mods)

            if (confirm(`You wish to move '${inMod.name}' to description of '${myMods[inIndex - 1].name}'`)) {
                myMods[inIndex - 1].details.push({
                    text: myMods[inIndex].name,
                    id: this.detailId,
                    modId: myMods[inIndex - 1].id,
                    groupId: inMod.groupId
                })

                this.detailId = this.detailId + 1

                myMods = myMods
                    .filter((mod, index) => index != inIndex)

                myGroup.mods = myMods

                this.groups.forEach((group, index) => {
                    if (group.id == myGroup.id) {
                        this.groups[index] = myGroup
                    }
                })
            }
        },
        clickModToGroup(inMod, inIndex) {
            if (confirm(`You wish to make '${inMod.name}' a group`)) {
                let myGroup = this.objCopy(this.groups.filter((group) => group.id == inMod.groupId)[0])
                let myMods = this.objCopy(myGroup.mods)
                
                this.groupId = this.groupId + 1

                let newGroup = {
                    id: this.groupId,
                    name: inMod.name,
                    mods: []
                }

                myMods.forEach((mod, index) => {
                    if (index > inIndex) {
                        mod.groupId = this.groupId
                        newGroup.mods.push(mod)
                    }
                })

                myMods = myMods.filter((mod, index) => index < inIndex)
                myGroup.mods = myMods

                this.groups.forEach((group, index) => {
                    if (group.id == myGroup.id) {
                        this.groups[index] = myGroup
                    }
                })

                let myGroupIndex = 0

                this.groups.forEach((group, index) => {
                    if (group.id == inMod.groupId) {
                        myGroupIndex = index + 1
                    }
                })

                this.groups.splice(myGroupIndex, 0, newGroup)
            }
        },
        closePopup() {
            this.$emit('closePopup')
        }
    }
}
</script>