import { isMobile, isAndroid, isIOS, isMacOs, isSafari } from 'mobile-device-detect'

const mobileMixins = {
    computed: {
        isAndroidApp() {
            return this.isAndroidDevice && navigator.userAgent.indexOf("buildz.pro") != -1
        },
        isAndroidDevice() {
            return isAndroid
        },
        isApp() {
            return navigator.userAgent.indexOf("buildz.pro") != -1
        },
        isFacebook() {
            return navigator.userAgent.indexOf("FBAN") != -1 || navigator.userAgent.indexOf("FBAV") != -1
        },
        isIOSApp() {
            return (
                this.isIOSDevice &&
                navigator.userAgent.indexOf("buildz.pro") != -1
            )
        },
        isIOSDevice() {
            return isIOS
        },
        isMac() {
            return isMacOs
        },
        isMacSafari() {
            return isMacOs && isSafari
        },
        isMobileDevice() {
            return isMobile
        },
        showAndroid() {
            return this.isAndroidDevice && !this.isApp
        },
        showIOS() {
            return this.isIOSDevice && !this.isApp
        }
    }
}

export default mobileMixins