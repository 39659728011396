<template>
    <div v-if="formLoaded" class="w-full p-2 white-transparent">
        <div v-if="sortedDiscounts" class="w-full">
            <div v-for="discount in discountsForm.discounts">
                <panel-sub-section
                    :sectionIdentifier="discount.id" :sectionLabel="discount.qty" :sectionOpen="showDiscounts.includes(discount.id)"
                    @cacheSectionOpen="clickShowDiscount"
                >
                    <edit-bulk-discount
                        :bulkDiscount="discount" :productBulkDiscounts="productBulkDiscounts"
                        @deleteBulkDiscount="deleteBulkDiscount" @updateBulkDiscount="updateBulkDiscount"
                    />
                </panel-sub-section>
            </div>
        </div>
        <div class="w-full orange-transparent" :class="{ 'mt-2': discountsForm.discounts.length }">
            <panel-sub-section
                sectionLabel="Create A New Bulk Discount" :sectionOpen="showNewBulkDiscount"
                @cacheSectionOpen="clickShowNewBulkDiscount"
            >
                <new-bulk-discount
                    :productBulkDiscounts="productBulkDiscounts"
                    @clickCreateNewBulkDiscount="clickCreateNewBulkDiscount"
                />
            </panel-sub-section>
        </div>   
    </div>
</template>

<script>
import EditBulkDiscount from './EditBulkDiscount'
import NewBulkDiscount from './NewBulkDiscount'

export default {
    props: [
        'product',
        'productBulkDiscounts'
    ],
    components: {
        EditBulkDiscount,
        NewBulkDiscount
    },
    data() {
        return {
            discountsForm: {
                discounts: null
            },
            formLoaded: false,
            lastId: 0,
            showDiscounts: [],
            showNewBulkDiscount: false
        }
    },
    computed: {
        sortedDiscounts() {
            if (this?.productBulkDiscounts?.discounts ?? false) {
                return this.productBulkDiscounts.discounts
                    .sort((a, b) => a.qty < b.qty ? -1 : 1)
            }

            return null
        }
    },
    mounted() {
        let myDiscounts = []

        if (this?.productBulkDiscounts?.discounts ?? false) {    
            this.productBulkDiscounts.discounts.forEach((myBulkDiscount) => {
                let myNewDiscount = {
                    qty: myBulkDiscount.qty,
                    percentage: myBulkDiscount.percentage,
                    enabled: myBulkDiscount.enabled,
                    id: myBulkDiscount.id
                }

                if (myBulkDiscount.id > this.lastId) {
                    this.lastId = myBulkDiscount.id
                }

                myDiscounts.push(myNewDiscount)
            })
        }

        this.discountsForm.discounts = myDiscounts
        this.formLoaded = true
    },
    methods: {
        clickCreateNewBulkDiscount(inForm) {
            let myForm = this.objCopy(inForm)

            myForm.id = this.lastId + 1
            this.lastId = this.lastId + 1
            this.discountsForm.discounts.push(myForm)
            this.$emit('updateBulkDiscounts', this.discountsForm, true)
        },
        clickShowNewBulkDiscount(inIsOpen) {
            this.showNewBulkDiscount = inIsOpen
        },
        clickShowDiscount(inIsOpen, inId) {
            if (inIsOpen) {
                if (!this.showDiscounts.includes(inId)) {
                    this.showDiscounts.push(inId)
                }
            }
            else {
                this.showDiscounts = this.showDiscounts.filter((myDiscount) => myDiscount != inId)
            }
        },
        deleteBulkDiscount(inId) {
            this.discountsForm.discounts = this.discountsForm.discounts.filter((myDiscount) => myDiscount.id !=inId)
            this.$emit('updateBulkDiscounts', this.discountsForm, true)
            this.pageMessage('success', "Bulk Discount deleted")
        },
        updateBulkDiscount(inForm) {
            this.discountsForm.discounts.find((myDiscount) => myDiscount.id == inForm.id).qty = inForm.qty
            this.discountsForm.discounts.find((myDiscount) => myDiscount.id == inForm.id).percentage = inForm.percentage
            this.discountsForm.discounts.find((myDiscount) => myDiscount.id == inForm.id).enabled = inForm.enabled
            this.$emit('updateBulkDiscounts', this.discountsForm, true)
        }
    }
}
</script>