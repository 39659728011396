import anchorme from 'anchorme'
import twitter from 'twitter-text'

const chatMixins = {
    computed: {
        sortedChats() {
            let chats = this.objCopy(this.chats)
            return chats?.sort((a, b) => a.latest_message_time > b.latest_message_time ? -1 : 1)
        }
    },
    methods: {
        chatTextRender(text, linkClass) {
            if (text === undefined) return

            return text
                .split(' ')
                .map((word, i) => {
                    if (anchorme.validate.url(word) || anchorme.validate.email(word)) {
                        const link = anchorme({
                            input: word, options: {
                                attributes: {
                                    target: word.toLowerCase().indexOf('buildz.pro') == -1 && word.toLowerCase().indexOf('buildz.test') == -1 ? "_blank" : "",
                                    class: `${linkClass} underline hover:underline`,
                                }
                            }
                        })

                        return link
                    }

                    if (word.includes('@')) {
                        const mention = twitter?.extractMentions(word)

                        if (!mention?.length) {
                            return word
                        }

                        return `<a href="/garage/${mention[0]}" class="${linkClass} hover:underline">@${mention[0]}</a>`
                    }
                    else if (word.includes('#')) {
                        const hashtag = twitter.extractHashtags(word)

                        if (!hashtag.length) {
                            return word
                        }

                        return `<a href="/hashtag/${hashtag[0]}" class="${linkClass} hover:underline">#${hashtag[0]}</a>`
                    }
                    else if (word.includes('+')) {
                        const build = [word]

                        return `<a href="/build/${build[0].slice(1)}" class="${linkClass} hover:underline">${build[0]}</a>`
                    }

                    return word
                })
                .join(' ')
        },
        closeNewChatModal() {
            this.setStateData([
                { showNewChatModal: false },
                { chatEditForm: null }
            ])
        },
        isChatMine(inChat) {
            return inChat?.owner_id == this?.loggedUserId
        },
        isMyMessage(inActivity) {
            return parseInt(inActivity?.actor?.id) == this?.loggedUserId
        },
        openNewChatModal() {
            this.setStateData([
                { showNewChatModal: true }
            ])
        },
        sendMessage(inChat, inMessage, inImage = null) {
            buildzAxios?.post(this.getRoute('chatMessage', inChat.id), {
                text: inMessage,
                image: inImage
            })
            .then((response) => {
                if (response.status == 200) {
                    
                }
                else {
                    this.pageMessage('error', 'Error sending message')
                }
            })
            .catch((error) => {
                this.pageMessage('error', 'Error sending message')
            })
        },
        setChatLatestRead(inChat) {
            if (this.inChat?.latest_message_time ?? false) {
                if (this.inChat.latest_message_time && this.inChat.latest_message_time.length > 1 && (!this.inChat.latest_read_message_time || this.inChat.latest_read_message_time != this.inChat.latest_message_time)) {
                    let myChats = this.objCopy(this.chats)

                    myChats = myChats
                        .map((myChat) => {
                            if (myChat.id == this.inChat.id) {
                                myChat.latest_read_message_time = myChat.latest_message_time
                            }

                            return myChat
                        })

                    this.setStateData([
                        { chats: myChats }
                    ])

                    buildzAxios?.post(this.getRoute('chatUpdateRead', this.inChat.id), {
                        time: this.inChat.latest_message_time
                    })
                    .then((response) => {
                        if (response.status == 200) {

                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                }
            }
        },
        submitChatData(inForm) {
            buildzAxios?.put(this.getRoute('chatUpdate', inForm.id), inForm)
                .then((response) => {
                    if (response.status == 200 && response.data?.chat) {
                        // this.pageMessage('success', 'Chat updated')
                        this.chat = this.objCopy(response.data.chat)
                        let myChats = this.objCopy(this.chats)

                        myChats = myChats
                            .map((myChat) => {
                                if (myChat.id == inForm.id) {
                                    myChat = this.objCopy(this.chat)
                                }

                                return myChat
                            })

                        this.replaceStateDataArray([
                            { chats: this.objCopy(myChats) }
                        ])

                        this.setStorage(`my_chats_${this.loggedUserId}`, JSON.stringify(myChats))
                        this.closeSpinner()
                        this.closeNewChatModal()
                    }
                    else {
                        this.closeSpinner()
                        this.pageMessage('error', 'Error updating Chat')
                    }
                })
                .catch((error) => {
                    this.closeSpinner()
                    this.pageMessage('error', 'Error updating Chat')
                })
        },
        submitNewChat(inForm) {
            buildzAxios?.post(this.getRoute('chatStore'), inForm)
                .then((response) => {
                    if (response.status == 200) {
                        this.replaceStateDataArray([
                            { chats: this.objCopy(response.data.user_chats) }
                        ])

                        this.setStorage(`my_chats_${this.loggedUserId}`, JSON.stringify(response.data.user_chats))
                        this.userChatFeedInit(response.data.chat.id)
                        // this.selectChat(response.data.chat)
                        this.closeNewChatModal()
                    }
                    else {
                        this.pageMessage('error', 'Error creating chat')
                    }

                    this.closeSpinner()
                })
                .catch((error) => {
                    this.pageMessage('error', 'Error creating chat')
                    this.closeSpinner()
                })
        },
        async userChatFeedInit(inId) {
            let myFeed = await this.streamClient?.feed('chat', inId)

            if (myFeed) {
                if (!this.subscribedChats.includes(myFeed.id)) {
                    myFeed.subscribe(this.subscribeNewUserChatFeedData).then(this.subscribeSuccess(myFeed.id), this.subscribeFail)
                    let mySubscribedChats = this.objCopy(this.subscribedChats)
                    mySubscribedChats.push(myFeed.id)

                    this.replaceStateDataArray([
                        { subscribedChats: mySubscribedChats }
                    ])
                }
                
                return myFeed.id
            }
            else {
                this.pageMessage('error', 'Error retrieving feed')
            }
        }
    }
}

export default chatMixins