const formMixins = {
    computed: {
        noYesData() {
            return {
                Yes: false,
                No: true
            }
        },
        yesNoData() {
            return {
                Yes: true,
                No: false
            }
        }
    },
    methods: {
    }        
}

export default formMixins