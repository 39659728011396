<template>
    <div class="py-2 mt-px" :class="{ 'white-transparent': zone.rates || isZoneOpen, 'red-transparent': !zone.rates && !isZoneOpen }">
        <div class="flex items-center justify-between px-1">
            <div v-if="editingZoneId != zone.id" class="mr-2 pl-4 font-semibold">
                {{ zone.name }}
            </div>
            <div v-if="editingZoneId == zone.id" class="mr-2">
                <select v-model="editZoneCountry" @change="updateZoneCountry()" class="form-input edit-field-text-color bg-white py-1 w-full pr-8">
                    <option v-for="country in countryList" :key="country.code" :value="country.code">{{ country.name }}</option>
                </select>
            </div>
            <div class="flex items-center justify-end pr-3">
                <div @click="clickDeleteZone()" v-if="!isDefaultZone && !readOnly" class="cursor-pointer">
                    <fa-icon icon="trash-can" type="fas" class="h-6 w-6" />
                </div>
                <div @click="clickEditZone()" v-if="!isDefaultZone && !readOnly" class="cursor-pointer mx-2">
                    <fa-icon icon="pen-to-square" type="fas" class="h-6 w-6" />
                </div>
                <div @click="toggleDefaultDisabled()" v-if="isDefaultZone && !readOnly" v-show="!zone.disabled" class="cursor-pointer mr-2">
                    <fa-icon icon="eye" type="fas" class="h-6 w-6" />
                </div>
                <div @click="toggleDefaultDisabled()" v-if="isDefaultZone && !readOnly" v-show="zone.disabled" class="cursor-pointer mr-2">
                    <fa-icon icon="eye-slash" type="fas" class="h-6 w-6" />
                </div>
                <div @click="clickOpenZone()" v-show="!isZoneOpen" class="cursor-pointer">
                    <fa-icon icon="chevron-left" type="fas" class="h-6 w-6" />
                </div>
                <div @click="clickCloseZone()" v-show="isZoneOpen" class="cursor-pointer">
                    <fa-icon icon="chevron-down" type="fas" class="h-6 w-6" />
                </div>
            </div>
        </div>
        <zone-rates v-show="isZoneOpen" 
            :zone="zone" :readOnly="readOnly" :shippingModel="shippingModel"
            @clickEditRate="clickEditRate" @clickDeleteRate="clickDeleteRate" @clickNewRate="clickNewRate"
        />
    </div>
</template>

<script>
import ZoneRates from './ZoneRates'

export default {
    props: ['zone', 'openZones', 'editingZoneId', 'readOnly', 'shippingModel'],
    components: {
        ZoneRates
    },
    data() {
        return {
            editZoneCountry: null
        }
    },
    computed: {
        isDefaultZone() {
            return this.zone.id == 1
        },
        isZoneOpen() {
            return this.openZones.includes(this.zone.id)
        }
    },
    methods: {
        clickCloseZone() {
            this.$emit('clickCloseZone', this.zone.id)
        },
        clickDeleteRate(inRate) {
            this.$emit('clickDeleteRate', this.zone.id, inRate)
        },
        clickDeleteZone() {
            this.$emit('clickDeleteZone', this.zone.id)
        },
        clickEditRate(inRate) {
            this.$emit('clickEditRate', this.zone.id, inRate)
        },
        clickEditZone() {
            if (this.zone.id == this.editingZoneId) {
                this.editZoneCountry = null
            }
            else {
                this.editZoneCountry = this.zone.countryCode
            }
            
            this.$emit('clickEditZone', this.zone)
        },
        clickNewRate(inType) {
            this.$emit('clickNewRate', this.zone.id, inType)
        },
        clickOpenZone() {
            this.$emit('clickOpenZone', this.zone.id)
        },
        toggleDefaultDisabled() {
            this.$emit('toggleDefaultDisabled')
        },
        updateZoneCountry() {
            this.$emit('updateZoneCountry', this.editZoneCountry)
        }
    }
}
</script>