const algoliaMixins = {
    computed: {
        algoliaUserToken() {
            return this.loggedUser ? `user${this.loggedUserId}` : null
        }
    },
    methods: {
        algoliaClick(inId, inIndex, inQueryId, inPosition) {
            this.gaEventPush({
                'algoliaObjectID': `${parseInt(inId)}`,
                'algoliaDisplayedQueryID': inQueryId,
                'algoliaDisplayedPosition': `${parseInt(inPosition)}`,
                'algoliaIndexName': inIndex,
                'algoliaEventName': `${inIndex} click`,
                'event': inQueryId ? 'algoliaClickAfterSearch' : 'algoliaClick'
            })
        },
        algoliaConvert(inId, inIndex, inQueryId) {
            this.gaEventPush({
                'algoliaObjectID': `${parseInt(inId)}`,
                'algoliaDisplayedQueryID': inQueryId,
                'algoliaIndexName': inIndex,
                'algoliaEventName': `${inIndex} convert`,
                'event': inQueryId ? 'algoliaConvertAfterSearch' : 'algoliaConvert'
            })
        },
        algoliaObjectView(inId, inIndex) {
            this.gaEventPush({
                'algoliaObjectID': `${parseInt(inId)}`,
                'algoliaIndexName': inIndex,
                'algoliaEventName': `${inIndex} view`,
                'event': 'algoliaObjectView'
            })
        }
    }
}

export default algoliaMixins