import twitter from 'twitter-text'

const activitiesMixins = {
    methods: {
        closeActivityModal() {
            this.setStateData([
                { showActivityModal: false },
                { activityToShow: null },
                { newActivityShareURL: null }
            ])

            this.replaceStateData([
                { reactions: {} }
            ])

            this.updateActivities([], 'modal')
        },
        closeWriteActivity() {
            this.setStateData([
                { showWriteActivity: false },
                { activityToEdit: null }
            ])
        },
        deleteActivity(inActivityId, inBuildId, inGarageId) {
            if (confirm('You want to delete this post?')) {
                buildzAxios?.delete(this.getRoute('activityDestroy', inActivityId), {
                    data: {
                        buildId: inBuildId,
                        garageId: inGarageId
                    }
                })
                .then((response) => {
                    if (response.status == 200) {
                        Object.entries(this.feedActivities).forEach(([feedId, activities]) => {
                            let myActivities = this.objCopy(activities)
                            myActivities = myActivities.filter(activity => activity.id != inActivityId)
                            this.updateActivities(myActivities, feedId)
                        })
                    }
                    else {
                        this.pageMessage('error', 'Error deleting post')
                    }
                })
                .catch((error) => {
                    this.pageMessage('error', 'Error deleting post')
                })
                .then(() => {

                })
            }
        },
        getActivityFromData(inId) {
            this.openSpinner()

            buildzAxios?.get(this.getRoute('activityData', inId))
                .then((response) => {
                    if (response.status == 200 && response.data.activity) {
                        this.openActivityModal(response.data.activity, null, null, false)

                        if (this?.activity?.foreign_id) {
                            this.algoliaObjectView(response.data.activity.foreign_id, 'activities')
                        }
                    }

                    this.closeSpinner()
                })
                .catch((error) => {
                    this.closeSpinner()
                })
        },
        async newActivity(inText, inAttachments, inFeeds, inImageStyle, inRecordId, inPostTo, inBuildIds, inGroupIds, inPostType, inSaleData, inMeta) {
            this.setStateData([
                { postingActivity: true }
            ])

            this.openSpinner()

            await waitUntil(() => {
                return !window.mediaInProgress
            }, 180000)

            buildzAxios?.post(this.getRoute('activityCreate'), {
                text: inText,
                attachments: inAttachments,
                feeds: inFeeds,
                recordId: inRecordId,
                postTo: inPostTo,
                builds: inBuildIds,
                groups: inGroupIds,
                imageStyle: inImageStyle,
                meta: inMeta,
                postType: inPostType,
                saleData: inSaleData,
                activityType: this.activityType,
                // buildId: inBuildId,
                // garageId: inGarageId,
                // eventId: inEventId,
                // groupId: inGroupId,
            })
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.checklist) {
                        let myUser = this.objCopy(this.loggedUser)
                        myUser.checklist = response.data.checklist
                        myUser.badges = response.data.badges
                        myUser.xp_points = response.data.xp_points
                        myUser.xp_prestige = response.data.xp_prestige
                        myUser.xp_level = response.data.xp_level
                        myUser.xp_current = response.data.xp_current
                        myUser.xp_next = response.data.xp_next

                        this.setStateData([
                            { loggedUser: myUser }
                        ])
                    }

                    if (this.newActivityType == 'build' && inBuildIds && inBuildIds.length && inBuildIds.includes(this.newActivityRecordId)) {
                        this.setStateData([
                            { reloadBuildId: this.newActivityRecordId }
                        ])
                    }

                    if (this.newActivityType == 'garage') {
                        this.setStateData([
                            { reloadGarageId: this.newActivityRecordId }
                        ])
                    }

                    if (this.activityType == 'groupBuy') [
                        this.setStateData([
                            { newGroupBuyFeedPost: true }
                        ])
                    ]

                    // this.clearForm()
                    this.closeWriteActivity()
                    this.pageMessage('success', 'Post created')
                }
                else {
                    this.pageMessage('error', 'Error creating post')
                }
            })
            .catch((error) => {
                this.pageMessage('error', 'Error creating post')
            })
            .then(() => {
                this.updateUTCTime()

                this.setStateData([
                    { postingActivity: false }
                ])

                this.closeSpinner()
            })
        },
        openActivityModal(inActivity, inQueryId = null, inPosition = null, inClicked = true) {
            if (this.serverConnected) {
                this.setStateData([
                    { activityToShow: inActivity },
                    { showMoreClicked: inClicked },
                    { algoliaQueryId: inQueryId },
                    { algoliaPosition: inPosition },
                    { showActivityModal: true }
                ])
            }
        },
        openWriteActivity(inActivity = null, inType = null, inEntity = null) {
            this.setStateData([
                { showWriteActivity: true },
                { activityToEdit: inActivity },
                { activityType: inType },
                { activityEntity: inEntity }
            ])
        },
        async pinActivity(inActivityId, inBuildId, inGarageId) {
            buildzAxios?.post(this.getRoute('activityPin', inActivityId), {
                buildId: inBuildId,
                garageId: inGarageId
            })
            .then((response) => {
                if (response.status == 200 && response.data) {
                    if (response.data.alreadyPinned) {
                        this.pageMessage('error', 'Post already pinned')
                    }
                    else {
                        this.pageMessage('success', 'Post pinned')
                    }
                }
                else {
                    this.pageMessage('error', 'Error pinning post')
                }
            })
            .catch((error) => {
                this.pageMessage('error', 'Error pinning post')
            })
            .then(() => {

            })
        },
        postBackgroundActivity(inFiles) {
            if (this.backgroundActivity) {
                let myBackgroundActivity = this.objCopy(this.backgroundActivity)
                
                inFiles.forEach((file) => {
                    myBackgroundActivity.gallery?.push(file)
                })

                myBackgroundActivity.gallery.forEach((item) => {
                    item.url = item.url.replace(/\/v[0-9].*?\//gm, '/')   // strip version
                    item.url = item.url.replace(/\/l_buildz_logo_black_plate_zbi4al,fl_region_relative,w_1.4,g_ocr_text\//gm, '/')   // strip existing ocr

                    if (item.type == 'image' && item.blur) {
                        item.url = item.url.replace(/\/buildz_pro_public\/.*/gm, "/l_buildz_logo_black_plate_zbi4al,fl_region_relative,w_1.4,g_ocr_text$&")
                    }
                })

                let attachments = {
                    images: myBackgroundActivity.gallery,
                    og: myBackgroundActivity.linkOgData
                }

                let feeds = {}
                let pattern = /\B\+[a-z0-9_-]+/gi

                feeds['builds'] = myBackgroundActivity.text.match(pattern)?.map(build => {
                    return build.substring(1)
                }) || []

                feeds['mentions'] = twitter?.extractMentions(myBackgroundActivity.text)
                feeds['hashtags'] = twitter?.extractHashtags(myBackgroundActivity.text)

                if (myBackgroundActivity.activityId) {
                    this.updateActivity(myBackgroundActivity.text, attachments, feeds, myBackgroundActivity.imageStyle, myBackgroundActivity.activityId)
                }
                else {
                    this.newActivity(myBackgroundActivity.text, attachments, feeds, myBackgroundActivity.imageStyle, myBackgroundActivity.garage, myBackgroundActivity.build, myBackgroundActivity.event, myBackgroundActivity.group, myBackgroundActivity.instagram)
                }

                this.setStateData([
                    { backgroundActivity: null }
                ])
            }
        },
        async publishUpdatedActivity(inActivityId) {
            const myUserFeedId = `user:${this.loggedUserId}`
            const myFeedId = this.feeds[myUserFeedId] ? myUserFeedId : await this.initFeed("user", this.loggedUserId)

            if (myFeedId) {
                let myActivity = await this.getSingleActivity(this.feeds[myFeedId], inActivityId)

                if (myActivity && myActivity.results) {
                    myActivity = myActivity.results[0]

                    Object.entries(this.feedActivities).forEach(([feedId, activities]) => {
                        let myActivities = this.objCopy(activities)

                        myActivities = myActivities.map((activity) => {
                            if (activity?.id == myActivity?.id) {
                                return myActivity
                            }
                            else {
                                return activity
                            }
                        })

                        this.updateActivities(myActivities, feedId)
                    })
                }

                this.setStateData([
                    { postingActivity: false }
                ])

                this.closeSpinner()
                this.closeWriteActivity()
                this.pageMessage('success', 'Post updated')
            }
            else {
                this.pageMessage('error', 'Error updating post')

                this.setStateData([
                    { postingActivity: false }
                ])

                this.closeSpinner()
            }
        },
        async unPinActivity(inActivityId, inBuildId, inGarageId) {
            buildzAxios?.post(this.getRoute('activityUnpin', inActivityId), {
                buildId: inBuildId,
                garageId: inGarageId
            })
            .then((response) => {
                if (response.status == 200 && response.data) {
                    if (response.data.wasntPinned) {
                        this.pageMessage('error', 'Post wasn\'t pinned')
                    }
                    else {
                        this.pageMessage('success', 'Post unpinned')
                    }
                }
                else {
                    this.pageMessage('error', 'Error pinning post')
                }
            })
            .catch((error) => {
                this.pageMessage('error', 'Error pinning post')
            })
            .then(() => {

            })
        },
        updateActivities(inActivities, inFeedId) {
            if (this.feedActivities) {
                let { [`${inFeedId}`]: _, ...myFeedActivities } = this.objCopy(this.feedActivities)
                myFeedActivities = { ...myFeedActivities, [`${inFeedId}`]: inActivities }

                this.replaceStateData([
                    { feedActivities: this.objCopy(myFeedActivities) }
                ])
            }
            else {
                this.replaceStateData([
                    { feedActivities: { [`${inFeedId}`]: inActivities } }
                ])
            }
        },
        async updateActivity(inText, inAttachments, inFeeds, inImageStyle, inActivityId, inSaleData) {
            this.setStateData([
                { postingActivity: true }
            ])

            this.openSpinner()

            buildzAxios?.put(this.getRoute('activityUpdate', inActivityId), {
                text: inText,
                attachments: inAttachments,
                feeds: inFeeds,
                imageStyle: inImageStyle,
                saleData: inSaleData
            })
            .then((response) => {
                if (response.status == 200 && response.data) {
                    this.publishUpdatedActivity(inActivityId)
                }
                else {
                    this.pageMessage('error', 'Error updating post')

                    this.setStateData([
                        { postingActivity: false }
                    ])

                    this.closeSpinner()
                }
            })
            .catch((error) => {
                this.pageMessage('error', 'Error updating post')

                this.setStateData([
                    { postingActivity: false }
                ])
                
                this.closeSpinner()
            })
            .then(() => {

            })
        }
    }
}

export default activitiesMixins

async function waitUntil(f, timeoutMs) {
    return new Promise((resolve, reject) => {
        let timeWas = new Date();
        let wait = setInterval(function() {
            
            if (f()) {
                // console.log("resolved after", new Date() - timeWas, "ms");
                clearInterval(wait);
                resolve();
            } else if (new Date() - timeWas > timeoutMs) { // Timeout
                // console.log("rejected after", new Date() - timeWas, "ms");
                clearInterval(wait);
                reject();
            }
        }, 200);
    });
}