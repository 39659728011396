import { library } from '@fortawesome/fontawesome-svg-core'
import FontAwesomeIcon from '@/shared/FontAwesomeIcon'

import { 
    faAngleLeft,
    faAngleRight,
    faArrowDown,
    faArrowsUpDown,
    faArrowsUpDownLeftRight,
    faArrowUp,
    faArrowUpFromBracket,
    faArrowUpRightFromSquare,
    faAsterisk,
    faAward,
    faBagShopping,
    faBan,
    faBars,
    faBell,
    faBoxArchive,
    faCalendar,
    faCalendarDays,
    faCalendarPlus,
    faCalendarWeek,
    faCar,
    faCartShopping,
    faChartLine,
    faCheck,
    faCheckCircle,
    faCheckToSlot,
    faChevronDown,
    faChevronLeft,
    faChevronUp,
    faCircleArrowDown,
    faCircleArrowRight,
    faCircleArrowUp,
    faCircleExclamation,
    faCircleInfo,
    faCirclePlus, 
    faClock, 
    faClockRotateLeft, 
    faCoins,
    faCommentMedical, 
    faComments,
    faCommentSlash,
    faCopy,
    faCreditCard,
    faEnvelope,
    faEraser,
    faEye,
    faEyeSlash,
    faFileImport,
    faFileLines,
    faFilm,
    faFilter,
    faFire,
    faFlag,
    faFloppyDisk,
    faFolder,
    faFolderMinus,
    faFolderPlus,
    faGear,
    faGears,
    faGift,
    faGlobe,
    faHand,
    faHeartPulse,
    faHouse,
    faImage,
    faInfo,
    faLifeRing,
    faLink,
    faList,
    faListOl,
    faLocationDot,
    faMagnifyingGlass,
    faMapPin,
    faMessage,
    faMoneyBillTransfer,
    faPenToSquare,
    faPeopleGroup,
    faPhotoFilm,
    faPlus,
    faQuestion, 
    faRetweet,
    faRightFromBracket,
    faRotate, 
    faSackDollar, 
    faScrewdriverWrench,
    faShapes,
    faShare,
    faShareNodes,
    faShop,
    faSliders,
    faSort,
    faSpinner,
    faStar,
    faStream,
    faTableCellsLarge,
    faTag,
    faThumbsUp,
    faTicket,
    faTrashCan,
    faTrashCanArrowUp,
    faTriangleExclamation,
    faTrophy,
    faTruck,
    faTruckRampBox,
    faUpDownLeftRight,
    faUpload,
    faUpRightFromSquare,
    faUser,
    faUserGear,
    faUserGroup,
    faUserPen,
    faUsers,
    faUserSecret,
    faUserShield,
    faUserTag,
    faVanShuttle,
    faWallet,
    faXmark 
} from '@fortawesome/free-solid-svg-icons'

import {
    faApple,
    faApplePay,
    faChrome,
    faFacebook,
    faFacebookSquare,
    faGoogle,
    faGooglePay,
    faInstagramSquare,
    faNfcSymbol,
    faShopify,
    faTwitterSquare,
    faYoutubeSquare
} from '@fortawesome/free-brands-svg-icons'

import {
    faCircleStop, 
    faEye as farEye,
    faHeart
} from '@fortawesome/free-regular-svg-icons'

library.add(faAngleLeft)
library.add(faAngleRight)
library.add(faArrowDown)
library.add(faArrowsUpDown)
library.add(faArrowsUpDownLeftRight)
library.add(faArrowUp)
library.add(faArrowUpFromBracket)
library.add(faArrowUpRightFromSquare)
library.add(faAsterisk)
library.add(faAward)
library.add(faBagShopping)
library.add(faBan)
library.add(faBars)
library.add(faBell)
library.add(faBoxArchive)
library.add(faCalendar)
library.add(faCalendarDays)
library.add(faCalendarPlus)
library.add(faCalendarWeek)
library.add(faCar)
library.add(faCartShopping)
library.add(faChartLine)
library.add(faCheck)
library.add(faCheckCircle)
library.add(faCheckToSlot)
library.add(faChevronDown)
library.add(faChevronLeft)
library.add(faChevronUp)
library.add(faCircleArrowRight)
library.add(faCircleArrowDown)
library.add(faCircleArrowUp)
library.add(faCircleExclamation)
library.add(faCircleInfo)
library.add(faCirclePlus)
library.add(faClock)
library.add(faClockRotateLeft)
library.add(faCoins)
library.add(faCommentMedical)
library.add(faComments)
library.add(faCommentSlash)
library.add(faCopy)
library.add(faCreditCard)
library.add(faEnvelope)
library.add(faEraser)
library.add(faEye)
library.add(faEyeSlash)
library.add(faFileLines)
library.add(faFilm)
library.add(faFilter)
library.add(faFire)
library.add(faFlag)
library.add(faFloppyDisk)
library.add(faFolder)
library.add(faFolderMinus)
library.add(faFolderPlus)
library.add(faGear)
library.add(faGears)
library.add(faGift)
library.add(faGlobe)
library.add(faHand)
library.add(faHeartPulse)
library.add(faHouse)
library.add(faImage)
library.add(faInfo)
library.add(faFileImport)
library.add(faLifeRing)
library.add(faLink)
library.add(faList)
library.add(faListOl)
library.add(faLocationDot)
library.add(faMagnifyingGlass)
library.add(faMapPin)
library.add(faMessage)
library.add(faMoneyBillTransfer)
library.add(faPenToSquare)
library.add(faPeopleGroup)
library.add(faPhotoFilm)
library.add(faPlus)
library.add(faQuestion)
library.add(faRetweet)
library.add(faRightFromBracket)
library.add(faRotate)
library.add(faSackDollar)
library.add(faScrewdriverWrench)
library.add(faShapes)
library.add(faShare)
library.add(faShareNodes)
library.add(faShop)
library.add(faSliders)
library.add(faSort)
library.add(faSpinner)
library.add(faStar)
library.add(faStream)
library.add(faTableCellsLarge)
library.add(faTag)
library.add(faThumbsUp)
library.add(faTicket)
library.add(faTrashCan)
library.add(faTrashCanArrowUp)
library.add(faTriangleExclamation)
library.add(faTrophy)
library.add(faTruck)
library.add(faTruckRampBox)
library.add(faUpDownLeftRight)
library.add(faUpload)
library.add(faUpRightFromSquare)
library.add(faUser)
library.add(faUserGear)
library.add(faUserGroup)
library.add(faUserPen)
library.add(faUserSecret)
library.add(faUsers)
library.add(faUserShield)
library.add(faUserTag)
library.add(faVanShuttle)
library.add(faXmark)
library.add(faWallet)

library.add(faApple)
library.add(faApplePay)
library.add(faChrome)
library.add(faFacebook)
library.add(faFacebookSquare)
library.add(faGoogle)
library.add(faGooglePay)
library.add(faInstagramSquare)
library.add(faNfcSymbol)
library.add(faShopify)
library.add(faTwitterSquare)
library.add(faYoutubeSquare)

library.add(faCircleStop)
library.add(farEye)
library.add(faHeart)

export {
    FontAwesomeIcon
}