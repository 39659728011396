<template>
    <div v-if="cachedHomeFeed" class="relative">
        <div id="top_cached_feed" style="width: 0; height: 1px;"></div>
        <div v-if="cachedHomeFeed.length > 0">
            <div v-for="(activity, index) in cachedHomeFeed" :key="index">
                <activity-card 
                    :activity="activity" :modal="false" :videosMuted="videosMuted"
                    :observer="observer" :isIntersecting="cardsObserving.includes(activity.id)"
                    @clickMute="clickMute" 
                />
            </div>
        </div>
    </div>
</template>

<script>
import ActivityCard from './ActivityCard'

export default {
    components: {
        ActivityCard
    },
    data() {
        return {
            cardsObserving: [],
            videosMuted: true
        }
    },
    created() {
        this.observer = new IntersectionObserver(
            this.onElementObserved, 
            {
                root: null,
                threshold: [0.65],
            }
        )
    },
    mounted() {
        this.feedScrollElement = this.scrollElement ? this.scrollElement : 'theMainPart'

        if (this.elGet(this.feedScrollElement)) {
            this.elGet(this.feedScrollElement)?.addEventListener('scroll', this.handleFeedScroll)
        }
    },
    beforeUnmount() {
        if (this.loggedUser) {
            this.elGet(this.feedScrollElement)?.removeEventListener('scroll', this.handleFeedScroll)
        }

        this.observer.disconnect()
    },
    methods: {
        actor(inActivity) {
            return inActivity?.meta?.original_actor?.data ? inActivity.meta.original_actor.data : inActivity.actor.data
        },
        clickMute(inMute) {
            this.videosMuted = inMute
        },
        onElementObserved(entries) {
            entries.forEach(({ target, isIntersecting}) => {
                if (!isIntersecting && this.cardsObserving.includes(target.id)) {
                    let myObs = this.objCopy(this.cardsObserving)
                    myObs = myObs.filter((ob) => ob != target.id)
                    this.cardsObserving = myObs
                }
                else if (isIntersecting && !this.cardsObserving.includes(target.id)) {
                    this.cardsObserving.push(target.id)
                }
            })
        }
    }
}
</script>