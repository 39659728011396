<template>
    <modal v-if="popupOpen" :open="popupOpen" @close="$emit('close')" :stopUnscroll="true">
        <div class="fixed top-0 left-0 h-full w-full gray-bg">
        </div>
        <div class="absolute top-0 left-0 w-full text-white" :class="{ 'pb-16': windowWidth < desktopMaxWidth, 'desktop-modal-box': windowWidth >= desktopMaxWidth }">
            <div :class="{ 'desktop-modal': windowWidth >= desktopMaxWidth }">
                <div class="section-heading mb-3 px-1 text-center mt-4">
                    {{ headingText }}
                </div>
                <div class="px-1">
                    <div class="flex align-start">
                        <div class="flex-none w-12 h-12 mr-2 rounded-full border-2 border-orange-500">
                            <user-avatar :url="loggedUser ? loggedUser.avatar : null" :rounded="true" />
                        </div>
                        <div class="flex-grow">
                            <textarea id="newCommentText" v-model="text" rows="6" class="w-full p-2 text-sm bg-white border border-gray-300 rounded focus:border-orange-500 focus:ring-orange-300 edit-field-text-color" placeholder="Type your reply...">
                            </textarea>
                        </div>
                    </div>
                    <div class="flex justify-end mt-3">
                        <div @click="clickPost" class="px-3 py-1 text-sm font-medium leading-5 text-white rounded-md" :class="{ 'bg-orange-500 cursor-pointer': !postingComment, 'bg-gray-300 cursor-not-allowed': postingComment }">
                            Post
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="postingComment" class="absolute top-0 left-0 flex items-center justify-center w-full h-full text-orange-400">
                <fa-icon icon="spinner" type="fas" class="h-14 w-14 spin" />
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/shared/Modal'

export default {
    props: {
        commentToEdit: null,
        reaction: null,
        popupOpen: false
    },
    components: {
        Modal
    },
    data() {
        return {
            text: null
        }
    },
    computed: {
        headingText() {
            return this.commentToEdit ? "Edit Reply" : "New Reply"
        }
    },
    mounted() {
        if (this.commentToEdit && this.commentToEdit.data.text) {
            this.text = this.commentToEdit.data.text
        } 
    },
    methods: {
        async clickPost() {
            if (!this.postingComment) {
                if (!this.commentToEdit) {
                    let myResult = await this.newComment(this.text, this.reaction)

                    if (myResult) {
                        buildzAxios?.post(this.getRoute('commentCreate'), {
                            comment: myResult,
                            activityId: this.activityToShow?.id,
                            postReaction: this.reaction
                        })

                        this.$emit('close')
                    } 
                }
                else {
                    let myResult = await this.updateComment(this.text, this.commentToEdit)

                    if (myResult) {
                        this.$emit('close')
                    } 
                }
            }
        }
    }
}
</script>