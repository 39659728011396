<template>
    <div class="fixed top-0 left-0 flex items-center justify-center w-full h-full standard-overlay text-orange-400" style="z-index: 50;">
        <fa-icon icon="spinner" type="fas" class="w-16 h-16 spin" />
    </div>
</template>

<script>
export default {
    
}
</script>