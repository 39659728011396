<template>
    <div class="relative overflow-hidden page-standard-height-width">
        <div class="fixed top-0 h-full" :class="{ 'left-56 desktop-width': isDesktopWidth, 'left-0 w-full': !isDesktopWidth }">
            <div class="mx-auto max-main-width h-full">
                <cached-image imageClasses="object-cover h-full w-full" :imageSrc="transformUrl(defaultHero, ['optimize', 'md', 'q80'])" />
            </div>
        </div>
        <div class="fixed top-0 h-full black-blur-3" :class="{ 'left-56 desktop-width': isDesktopWidth, 'left-0 w-full': !isDesktopWidth }">
        </div>
        <div class="fixed h-full" :class="{ 'left-56 desktop-width': isDesktopWidth, 'left-0 w-full': !isDesktopWidth }">
            <div class="mx-auto max-main-width h-full py-16">
                <div class="max-width-box">
                    <tabs 
                        :count="3" 
                    />
                    <section-heading 
                        classes="w-52 mt-6 mb-3" 
                    />
                    <text-lines 
                        :count="1" classes="w-3/5 flasher-light-bg flasher-light-animate h-4 mx-auto rounded-full" 
                    />
                    <text-lines 
                        :count="1" classes="w-28 flasher-light-bg flasher-light-animate h-4 mt-4 rounded-full mb-1" 
                    />
                    <text-lines 
                        :count="1" classes="w-full flasher-mid-bg flasher-mid-animate h-8 rounded-md" style="padding-bottom: 3px;"
                    />
                    <text-lines 
                        :count="1" classes="w-40 flasher-light-bg flasher-light-animate h-4 rounded-full mt-2"
                    />
                    <text-lines 
                        :count="1" classes="w-28 flasher-light-bg flasher-light-animate h-4 mt-4 rounded-full mb-1" 
                    />
                    <text-lines 
                        :count="1" classes="w-full flasher-mid-bg flasher-mid-animate h-8 rounded-md"
                    />
                    <text-lines 
                        :count="1" classes="w-24 flasher-light-bg flasher-light-animate h-4 mt-3 rounded-full" 
                    />
                    <rectangle 
                        classes="w-20 h-8 flasher-mid-bg flasher-mid-animate mt-2 ml-1" :count="1"
                    />
                    <text-lines 
                        :count="1" classes="w-28 flasher-light-bg flasher-light-animate h-4 mt-3 rounded-full mb-1" 
                    />
                    <text-lines 
                        :count="1" classes="w-full flasher-mid-bg flasher-mid-animate h-8 rounded-md"
                    />
                    <text-lines 
                        :count="1" classes="w-28 flasher-light-bg flasher-light-animate h-4 mt-4 rounded-full mb-1" 
                    />
                    <text-lines 
                        :count="1" classes="w-full flasher-mid-bg flasher-mid-animate h-64 rounded-md"
                    />
                    <text-lines 
                        :count="1" classes="w-full flasher-dark-bg flasher-dark-animate h-10 mt-5 rounded-md" 
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Rectangle from '@/flashers/components/Rectangle'
import SectionHeading from '@/flashers/components/SectionHeading'
import Tabs from '@/flashers/components/Tabs'
import TextLines from '@/flashers/components/TextLines'

export default {
    components: {
        Rectangle,
        SectionHeading,
        Tabs,
        TextLines
    }
}
</script>