const campaignMixins = {
    computed: {
        campaignStatuses() {
            return {
                cancelled: "Cancelled",
                draft: "Draft",
                sent: "Sent"
            }
        },
        campaignType() {
            return {
                email: "Email",
                sms: "SMS"
            }
        },
        campaignTypes() {
            return {
                Email: "email",
                SMS: "sms"
            }
        }
    },
    methods: {
        closeEditCampaignModal() {
            this.setStateData([
                { showEditCampaignModal: false },
                { campaignToEdit: null }
            ])
        },
        closeEditSegmentModal() {
            this.setStateData([
                { showEditCustomerSegmentModal: false },
                { customerSegmentToEdit: null }
            ])
        },
        editCampaign(inCampaign) {
            this.openEditCampaignModal(inCampaign)
        },
        editSegment(inSegment) {
            this.openEditSegmentModal(inSegment)
        },
        getCampaignTime(inDate) {
            return inDate ? this.getLocalUnixTime(inDate) : "--/--/----"
        },
        openEditCampaignModal(inCampaign) {
            this.setStateData([
                { showEditCampaignModal: true },
                { campaignToEdit: inCampaign }
            ])
        },
        openEditSegmentModal(inSegment) {
            this.setStateData([
                { showEditCustomerSegmentModal: true },
                { customerSegmentToEdit: inSegment }
            ])
        },
        pushSegmentCount(inData) {
            let { [`${inData.segment_id}`]: mySegment, ...theOtherSegments } = this.objCopy(this.customerSegmentCounts)

            if (mySegment) {
                let { [`${inData.identifier}`]: myCount, ...theOtherCounts } = this.objCopy(mySegment)

                if (!myCount?.count || myCount.count <= inData.count) {
                    myCount = null
                    myCount = inData

                    let myNewSegment = { ...theOtherCounts, [`${inData.identifier}`]: myCount }
                    let myNewSegments = { ...theOtherSegments, [`${inData.segment_id}`]: myNewSegment }

                    this.setStateData([
                        { customerSegmentCounts: myNewSegments }
                    ])
                }
            }
        },
        saveCampaign(inCampaignId, inForm) {
            this.saveStart()

            buildzAxios?.post(this.getRoute('sellerCampaign', inCampaignId), inForm)
                .then((response) => {
                    let myRecord = Object.entries(this.vendorCampaigns).find((entry) => entry[1].id == inCampaignId)
                    let myRecords = this.objCopy(this.vendorCampaigns)
                    myRecords[myRecord[0]] = this.objCopy(response.data.campaign)

                    this.setStateData([
                        { vendorCampaigns: myRecords }
                    ])

                    this.saveDone()
                })
                .catch((error) => {
                    console.log(error)
                    this.saveDone()
                    this.pageMessage('error', 'Error updating Campaign')
                })
        },
        saveSegment(inSegmentId, inForm) {
            this.saveStart()

            buildzAxios?.post(this.getRoute('sellerCustomerSegment', inSegmentId), inForm)
                .then((response) => {
                    let myRecord = Object.entries(this.vendorCustomerSegments).find((entry) => entry[1].id == inSegmentId)
                    let myRecords = this.objCopy(this.vendorCustomerSegments)
                    myRecords[myRecord[0]] = this.objCopy(response.data.segment)

                    this.setStateData([
                        { vendorCustomerSegments: myRecords }
                    ])

                    this.saveDone()
                })
                .catch((error) => {
                    console.log(error)
                    this.saveDone()
                    this.pageMessage('error', 'Error updating Customer Segment')
                })
        }
    }
}

export default campaignMixins