<template>
    <div class="relative overflow-hidden page-standard-height-width">
        <div class="fixed top-0 left-0 h-full w-full">
            <div class="mx-auto max-main-width h-full">
                <cached-image imageClasses="object-cover h-full w-full" :imageSrc="transformUrl(defaultHero, ['optimize', 'md', 'q80'])" />
            </div>
        </div>
        <div class="fixed top-0 left-0 h-full w-full black-blur-3">
        </div>
        <div class="fixed w-full h-full">
            <div class="mx-auto max-main-width h-full py-16">
                <tabs 
                    :count="4" 
                />
                <section-heading 
                    classes="w-40 mt-6 mb-3" 
                />
                <gallery-item
                    classes="w-full h-full flasher-mid-bg flasher-mid-animate" parentClasses="w-1/2 h-44" :count="12"
                />
            </div>
        </div>
    </div>
</template>

<script>
import GalleryItem from '@/flashers/components/GalleryItem'
import SectionHeading from '@/flashers/components/SectionHeading'
import Tabs from '@/flashers/components/Tabs'

export default {
    components: {
        GalleryItem,
        SectionHeading,
        Tabs
    }
}
</script>