<template>
    <div class="mb-px" :class="{ 'mt-1': !noTopMargin, 'white-transparent': !whiteLabel || whiteLabelDark, 'black-transparent': whiteLabel && whiteLabelLight }">
        <div class="text-base font-bold leading-5 p-2" :class="{ 'white-transparent': !whiteLabel || whiteLabelDark, 'black-transparent': whiteLabel && whiteLabelLight }">
            <slot /> 
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'noTopMargin'
    ]
}
</script>