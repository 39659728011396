const gaMixins = {
    methods: {
        customPageView(inLabel, inValue = '') {
            var url = new URL(window.location.href)
            var session = url.searchParams.get("session")

            this.gaEventPush({
                'event': this.loggedUser ? 'UserPageView' : 'GuestPageView',
                'label': inLabel,
                'value': inValue
            })

            if (inLabel == 'Search' && inValue != '') {
                this.gaEventPush({
                    'event': `${inValue}Search`,
                    'label': inValue,
                    'value': inValue
                })
            }

            var session = url.searchParams.get("session");

            if (session) {
                this.gaEventPush({
                    'event': 'ReferralHit',
                    'label': session,
                    'value': session
                })
            }
        },
        
        gaAddCustomerInfo(inCurrency, inValue, inItems) {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "add_customer_info",
                ecommerce: {
                    currency: inCurrency,
                    value: inValue,
                    items: inItems
                }
            })
        },
        gaAddPaymentInfo(inCurrency, inValue, inPaymentType, inItems) {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "add_payment_info",
                ecommerce: {
                    currency: inCurrency,
                    value: inValue,
                    payment_type: inPaymentType,
                    items: inItems
                }
            })
        },
        gaAddShippingInfo(inCurrency, inValue, inShippingTier, inItems) {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "add_shipping_info",
                ecommerce: {
                    currency: inCurrency,
                    value: inValue,
                    shipping_tier: inShippingTier,
                    items: inItems
                }
            })
        },
        gaAddToCart(inCurrency, inValue, inItems) {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "add_to_cart",
                ecommerce: {
                    currency: inCurrency,
                    value: inValue,
                    items: inItems
                }
            })
        },
        gaAddToWishList(inCurrency, inValue, inItems) {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "add_to_wishlist",
                ecommerce: {
                    currency: inCurrency,
                    value: inValue,
                    items: inItems
                }
            })
        },
        gaBeginCheckout(inCurrency, inValue, inItems) {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "begin_checkout",
                ecommerce: {
                    currency: inCurrency,
                    value: inValue,
                    items: inItems
                }
            })
        },
        gaBuildItemArray(inItems) {
            let myItems = []

            inItems.forEach((myItem) => {
                if (myItem.qty > 0) {
                    let myBuildType = Object.values(this.buildTypes).find((buildType) => buildType.id == myItem.data.buildz_group_id)

                    myItems.push({
                        item_id: myItem.data.id,
                        item_name: myItem.data.title,
                        item_category: myBuildType?.name ?? null,
                        price: this.fixProductPrice(myItem.data.price.currency, myItem.data.price.amount),
                        quantity: myItem.qty
                    })
                }
            })

            return myItems
        },
        gaCheckoutBackButton() {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "checkout_back_button"
            })
        },
        gaEventPush(inData) {
            let url = window.location.href

            if (url.includes('apptest.buildz') || url.includes('whitetest.buildz') || url.includes('localhost')) {
                console.log('nope, its a test, but this is the data', inData)
            }
            else {
                if (typeof dataLayer != 'undefined') {
                    dataLayer.push(inData)
                }
            }
        },
        gaInternationalAUD() {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "international_aud"
            })
        },
        gaInternationalUSD() {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "international_usd"
            })
        },
        gaPaymentBackButton() {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "payment_back_button"
            })
        },
        gaPayPalCancelled() {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "paypal_cancelled"
            })
        },
        gaPayPalError() {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "paypal_error"
            })
        },
        gaPayWithAfterPay(inData) {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "pay_with_afterpay",
                ecommerce: {
                    currency: inData[0] ?? null,
                    value: inData[1] ?? null,
                    items: inData[2] ?? null
                }
            })
        },
        gaPayWithApplePay(inData) {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "pay_with_applepay",
                ecommerce: {
                    currency: inData[0] ?? null,
                    value: inData[1] ?? null,
                    items: inData[2] ?? null
                }
            })
        },
        gaPayWithCreditCard(inData) {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "pay_with_creditcard",
                ecommerce: {
                    currency: inData[0] ?? null,
                    value: inData[1] ?? null,
                    items: inData[2] ?? null
                }
            })
        },
        gaPayWithGPay(inData) {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "pay_with_gpay",
                ecommerce: {
                    currency: inData[0] ?? null,
                    value: inData[1] ?? null,
                    items: inData[2] ?? null
                }
            })
        },
        gaPayWithPayPal(inData) {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "pay_with_paypal",
                ecommerce: {
                    currency: inData[0] ?? null,
                    value: inData[1] ?? null,
                    items: inData[2] ?? null
                }
            })
        },
        gaPayWithZip(inData) {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "pay_with_zip",
                ecommerce: {
                    currency: inData[0] ?? null,
                    value: inData[1] ?? null,
                    items: inData[2] ?? null
                }
            })
        },
        gaPurchase(inCurrency, inValue, inTax, inShipping, inTransactionId, inItems) {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "purchase",
                ecommerce: {
                    transaction_id: inTransactionId,
                    currency: inCurrency,
                    value: inValue,
                    tax: inTax,
                    shipping: inShipping,
                    items: inItems
                }
            })
        },
        gaSearch(inSearchTerm) {
            this.gaEventPush({
                event: "search",
                search_term: inSearchTerm
            })
        },
        gaShare(inMethod, inContentType, inItemId) {
            this.gaEventPush({
                event: "share",
                method: inMethod,
                content_type: inContentType,
                item_id: inItemId
            })
        },
        gaTimedDealStart1() {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "timed_deal_start_1"
            })
        },
        gaTimedDealStart2() {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "timed_deal_start_2"
            })
        },
        gaTimedDealStop() {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "timed_deal_stop"
            })
        },
        gaViewCart(inCurrency, inValue, inItems) {
            this.gaEventPush({ ecommerce: null })

            this.gaEventPush({
                event: "view_cart",
                ecommerce: {
                    currency: inCurrency,
                    value: inValue,
                    items: inItems
                }
            })
        }
    }
}

export default gaMixins