<template>
    <modal v-if="showActivityModal" :open="showActivityModal" @close="closeActivityModal" class="text-white">
        <div class="absolute top-0 left-0 w-full" :class="{ 'pb-16': windowWidth < desktopMaxWidth, 'desktop-modal-box': windowWidth >= desktopMaxWidth }">
            <div :class="{ 'desktop-modal p-0': windowWidth >= desktopMaxWidth }">
                <div v-if="activityToShow && !showFlasher">
                    <div id="activityModalContainer" v-if="activities.length > 0 && !activityNotFound">
                        <activity-card 
                            :observer="observer" :isIntersecting="cardsObserving.includes(activities[0].id)" 
                            :activity="activities[0]" :feedId="feed_id" :modal="true" 
                        />
                    </div>
                    <div v-if="activityNotFound || !activityToShow" class="text-white">
                        There was a problem opening the post or the post has been deleted.
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import ActivityCard from '@/shared/ActivityFeed/ActivityCard'
import Modal from '@/shared/Modal'

export default {
    components: {
        ActivityCard,
        Modal
    },
    data() {
        return {
            activityNotFound: false,
            cardsObserving: [],
            feed_id: null,
            open: true,
            useBuild: false,
            useGarage: false,
            useMyFeed: false,
            useTimeline: false,
            useZero: false
        }
    },
    computed: {
        activities() {
            return this.feed_id && this.feedActivities && this.feedActivities[this.feed_id] && this.feedActivities[this.feed_id].length ? this.feedActivities[this.feed_id] : (this.activityToShow?.meta ? [this.activityToShow] : [])
        },
        feed() {
            return this.feed_id && this.feeds && this.feeds[this.feed_id] ? this.feeds[this.feed_id] : null
        },
        foreignId() {
            return this?.activityToShow?.foreign_id
        },
        isBuildPost() {
            return this?.activityToShow?.meta?.build && this?.activityToShow?.meta?.build?.data?.id
        },
        isEventPost() {
            return this?.activityToShow?.meta?.event && (this?.activityToShow?.meta?.event?.data?.id || this?.activityToShow?.meta?.event.includes(':'))
        },
        isGaragePost() {
            return this?.activityToShow?.meta?.garage && (this?.activityToShow?.meta?.garage?.data?.id || this?.activityToShow?.meta?.garage.includes(':'))
        },
        isGroupPost() {
            return this?.activityToShow?.meta?.myGroup && (this?.activityToShow?.meta?.myGroup?.data?.id || this?.activityToShow?.meta?.myGroup.includes(':'))
        },
        isGroupBuyPost() {
            return (this?.activityToShow?.meta?.groupBuy ?? false) && (this?.activityToShow?.meta?.groupBuy?.data?.id || this?.activityToShow?.meta?.groupBuy.includes(':'))
        },
        showFlasher() {
            return !this.activityNotFound && (this?.activities.length == 0 || !this?.activities[0]?.actor?.data?.name)
        }
    },
    watch: {
        foreignId: {
            immediate: true,
            handler(val) {
                if (val) {
                    if (this.loggedUser && this.showMoreClicked) {
                        this.algoliaClick(val, 'activities', this.algoliaQueryId, this.algoliaPosition)

                        if (this.isBuildPost) {
                            this.algoliaClick(this.activityToShow.meta.build.data.id, 'builds')
                        }

                        if (this.isBuildPost && this?.activityToShow?.meta?.garage?.data?.id) {
                            this.algoliaClick(this.activityToShow.meta.garage.data.id, 'users')
                        }

                        if (this?.activityToShow?.meta?.myGroup?.data?.id) {
                            this.algoliaClick(this.activityToShow.meta.myGroup.data.id, 'groups')
                        }

                        if (this?.activityToShow?.meta?.event?.data?.id) {
                            this.algoliaClick(this.activityToShow.meta.event.data.id, 'events')
                        }

                        if (this?.activityToShow?.meta?.groupBuy?.data?.id) {
                            this.algoliaClick(this.activityToShow.meta.groupBuy.data.id, 'groupBuys')
                        }
                    }

                    this.setStateData([
                        { algoliaQueryId: null },
                        { algoliaPosition: null },
                        { showMoreClicked: false }
                    ])
                }
            }
        }
    },
    created() {
        this.observer = new IntersectionObserver(
            this.onElementObserved, 
            {
                root: null,
                threshold: [0.65],
            }
        )
    },
    mounted() {
        if (this.isEventPost) {
            this.getEventPost()
        }
        if (this.isGaragePost) {
            this.getGaragePost()
        }
        else if (this.isGroupPost) {
            this.getGroupPost()
        }
        else if (this.isGroupBuyPost) {
            this.getGroupBuyPost()
        }
        else {
            this.getFeed()
        }
    },
    methods: {
        async getActivity() {
            const feedData = await this.getSingleActivity(this.feed)

            if (feedData?.results && feedData.results.length) {
                this.updateActivities(feedData.results, this.feed_id)

                this.setStateData([
                    { activityToShow: this.objCopy(feedData.results[0]) }
                ])
            }
            else if (feedData?.results && !feedData.results.length && !this.useMyFeed) {
                this.useMyFeed = true
                this.getFeed(this?.activityToShow?.meta?.activity_owner ? this.activityToShow.meta.activity_owner : this.loggedUserId)
            }
            else if (feedData?.results && !feedData.results.length && this.useMyFeed && !this.useTimeline) {
                this.useTimeline = true
                this.getFeed(this.loggedUserId, 'timeline')
            }
            else if (feedData?.results && !feedData.results.length && this.isBuildPost && this.useMyFeed && this.useTimeline && !this.useBuild) {
                this.useBuild = true
                this.getFeed(this?.activityToShow?.meta?.build?.data?.id, 'build')
            }
            // else if (feedData?.results && !feedData.results.length && this.isGaragePost && this.useMyFeed && this.useTimeline && !this.useGarage) {
            //     this.useGarage = true
            //     this.getFeed(this?.activityToShow?.meta?.garage?.data?.id, 'garage')
            // }
            else if (feedData?.results && !feedData.results.length && this.useMyFeed && this.useTimeline && !this.useZero) {
                this.useZero = true
                this.getFeed(0)
            }
            else {
                this.activityNotFound = true
            }
        },
        getEventPost() {
            let eventId = 0

            if (this?.activityToShow?.meta?.event?.data?.id) {
                eventId = this.activityToShow.meta.event.data.id
            }
            else {
                let evAr = this?.activityToShow?.meta?.event?.split(':')
                eventId = evAr[2] ?? 0
            }

            this.getFeed(eventId ?? 0, 'event')
        },
        async getFeed(inOwnerId, inFeed = 'user') {
            if (!this.streamClient) {
                await this.initClient()
            }

            if (this.streamClient) {
                const myFeedId = await this.initFeed(inFeed, inOwnerId ? inOwnerId : this.activityToShow.actor.id, 'modal', false)

                if (myFeedId) {
                    this.feed_id = 'modal'
                    this.getActivity()
                }
            }
            else {
                // this.pageMessage('error', 'Error inititalising stream client')
            }
        },
        getGaragePost() {
            let garageId = 0

            if (this?.activityToShow?.meta?.garage?.data?.id) {
                garageId = this.activityToShow.meta.garage.data.id
            }
            else {
                let evAr = this?.activityToShow?.meta?.garage?.split(':')
                garageId = evAr[2] ?? 0
            }

            this.getFeed(garageId ?? 0, 'garage')
        },
        getGroupPost() {
            let groupId = 0

            if (this?.activityToShow?.meta?.mygroup?.data?.id) {
                groupId = this.activityToShow.meta.mygroup.data.id
            }
            else {
                let grAr = this?.activityToShow?.meta?.mygroup?.split(':')
                groupId = grAr[2] ?? 0
            }

            this.getFeed(groupId ?? 0, 'group')
        },
        getGroupBuyPost() {
            let groupBuyId = 0

            if (this?.activityToShow?.meta?.groupBuy?.data?.id) {
                groupBuyId = this.activityToShow.meta.groupBuy.data.id
            }
            else {
                let grAr = this?.activityToShow?.meta?.groupBuy?.split(':')
                groupBuyId = grAr[2] ?? 0
            }

            this.getFeed(groupBuyId ?? 0, 'group_buy')
        },
        onElementObserved(entries) {
            entries.forEach(({ target, isIntersecting}) => {
                if (!isIntersecting && this.cardsObserving.includes(target.id)) {
                    let myObs = this.objCopy(this.cardsObserving)
                    myObs = myObs.filter((ob) => ob != target.id)
                    this.cardsObserving = myObs
                }
                else if (isIntersecting && !this.cardsObserving.includes(target.id)) {
                    this.cardsObserving.push(target.id)
                }
            })
        }
    }
}
</script>