<template>
    <div v-if="formLoaded" class="w-full">
        <input-panel 
            fieldFormName="identifier" :fieldLabel="`${vendorVariant.label} Identifier`" :fieldNonEdit="true" :fieldPlaceholder="`${vendorVariant.label} Identifier`" 
            fieldType="text" :fieldValue="valueForm.identifier"
        />
        <input-panel 
            fieldFormName="label" :fieldLabel="`${vendorVariant.label} Name`" :fieldPlaceholder="`${vendorVariant.label} Name`" 
            :fieldRequired="true" fieldType="text" :fieldValue="valueForm.label"
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(valueForm.label)" 
                :message="`${vendorVariant.label} Label must be entered`"
            />
        </input-panel>
        <input-panel 
            fieldFormName="priceVariance" fieldLabel="Price Variance Amount" fieldPlaceholder="Price Variance Amount"
            fieldSubLabel="This amount will be added to the base price of this product, please enter a tax inclusive price" fieldType="number" 
            :fieldValue="valueForm.priceVariance" 
            @fieldInput="updateFormValue"
        />
        <div class="w-full white-transparent mt-px p-2">
            <div @click="clickSaveValue()" class="w-full" :class=" { 'standard-button-base': allOkay, 'disabled-button-base': !allOkay } ">
                Save
            </div>
            <div @click="clickDelete()" class="mt-2 w-full secondary-button-base">
                Delete
            </div>
            <div v-if="isChanges" @click="clickCancelChanges()" class="mt-2 w-full alt-button-base">
                Cancel Changes
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'vendorVariant',
        'variantValue'
    ],
    data() {
        return {
            valueForm: {
                identifier: null,
                label: null,
                priceVariance: null
            },
            formLoaded: false,
            validating: false
        }
    },
    computed: {
        allOkay() {
            return Boolean(this.valueForm.label)
        },
        isChanges() {
            return Boolean(this.valueForm.label != this.variantValue.label || this.valueForm.priceVariance != this.variantValue.priceVariance)
        }
    },
    mounted() {
        this.valueForm.identifier = this.variantValue.identifier
        this.valueForm.label = this.variantValue.label
        this.valueForm.priceVariance = this.variantValue.priceVariance
        this.formLoaded = true
    },
    methods: {
        clickCancelChanges() {
            this.valueForm.label = this.variantValue.label
            this.valueForm.priceVariance = this.variantValue.priceVariance
        },
        clickDelete() {
            if (confirm(`You wish to delete ${this.vendorVariant.label} '${this.variantValue.label}'?`)) {
                this.$emit('deleteVariantValue', this.variantValue.identifier)
            }
        },
        clickSaveValue() {
            if (this.allOkay) {
                this.validating = false
                this.$emit('updateVariantValue', this.valueForm, this.vendorVariant.identifier, this.variantValue.identifier, true)
                this.pageMessage('success', `${this.vendorVariant.label} '${this.valueForm.label}' saved`)
            }
            else {
                this.validating = true
            }
        },
        updateFormValue(inField, inValue) {
            this.valueForm[inField] = inValue
        }
    }
}
</script>