<template>
    <nav id="bzNavbar" class="fixed z-20 text-white top-nav iphonePaddingTop" :style="showGlobalIcons || !userHideIcons ? { 'top': '0px' } : { 'top': '-160px' }">
        <div class="px-2 w-full">
            <div class="relative flex items-center h-16 w-full justify-between">
                <div class="flex items-center pr-2">
                    <div v-if="isLoggedIn && !isDesktopWidth" class="py-2 mr-3 text-orange-400 cursor-pointer" @click="openMainMenu()">
                        <fa-icon icon="bars" type="fas" class="orphan-icon h-8 w-8" />
                    </div>
                    <router-link v-if="!isDesktopWidth" to="/" class="flex flex-row shrink-0">
                        <cached-image imageClasses="block w-auto h-8" :imageSrc="bzLogo" />
                        <span class="hidden h-full ml-2 text-xl font-semibold tracking-tight text-white sm:inline">Buildz</span>
                        <span class="hidden h-full text-xl italic font-light tracking-tight text-white sm:inline">.pro</span>
                    </router-link>
                    <div v-if="serverConnected" class="relative ml-2 text-white flex items-center">
                        <div v-show="saving || mediaProgressTotal > 0">
                            <fa-icon icon="spinner" type="fas" class="spin h-4 w-4" />
                        </div>
                        <div v-show="mediaProgressTotal > 0" class="w-36 text-center ml-2 p-0.5 text-xs text-white font-medium transition duration-500 ease-in-out iphonePaddingTop" :class="{'animate-pulse' : mediaProgress < mediaProgressTotal }">{{ `${mediaProgress} of ${mediaProgressTotal} files uploaded` }}</div>
                        <transition enter-class="opacity-0" enter-active-class="duration-500 ease-out" enter-to-class="opacity-100" leave-class="opacity-100" leave-active-class="duration-1000 ease-in" leave-to-class="opacity-0">
                            <div v-show="saved">
                                <fa-icon icon="floppy-disk" type="fas" class="h-4 w-4" />
                            </div>
                        </transition>
                    </div>
                    <div v-else class="ml-2 text-red-500 flex items-center">
                        <fa-icon icon="triangle-exclamation" type="fas" class="h-4 w-4" />
                    </div>
                </div>
                <!-- <div v-if="showAndroid && !saving && mediaProgressTotal == 0 && !saved" class="flex items-start h-9 xs:h-10">
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=pro.buildz.android" class="block transition-margin h-9 xs:h-10">
                        <cached-image imageClasses="h-9 xs:h-10" imageSrc="https://res.cloudinary.com/dnnzvmbis/image/upload/v1652836861/google_play_badge_uoverv.png" />
                    </a>
                </div>
                <div v-else-if="showIOS && !saving && mediaProgressTotal == 0 && !saved" class="flex items-start h-9 xs:h-10">
                    <a target="_blank" href="https://apps.apple.com/us/app/buildz-pro/id1547386480" class="block transition-margin h-9 xs:h-10">
                        <cached-image imageClasses="h-9 xs:h-10" imageSrc="https://res.cloudinary.com/dnnzvmbis/image/upload/v1652836861/app_store_badge_qy2yqu.png" />
                    </a>
                </div> -->
                <div v-if="!isDesktopWidth" class="flex items-cente justify-end">
                    <router-link to="/register" v-if="!isLoggedIn && $route.name != 'Login' && $route.name != 'Register'" class="standard-button-base bg-orange-400">
                        Sign up
                    </router-link>
                    <router-link v-if="!isLoggedIn" to="/" class="orphan-icon-overlay-box ml-2">
                        <fa-icon icon="house" type="fas" class="orphan-icon h-5 w-5" />
                    </router-link>  
                    <router-link to="/cart" class="orphan-icon-overlay-box ml-2">
                        <fa-icon icon="cart-shopping" type="fas" class="orphan-icon h-5 w-5" />
                    </router-link>
                    <div v-if="isLoggedIn && isApp" @click="back()" class="cursor-pointer rounded-full p-1 ml-4" style="background: rgba(0, 0, 0, 0.40);">
                        <fa-icon icon="angle-left" type="fas" class="w-6 h-6" />
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    
}
</script>