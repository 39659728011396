<template>
    <div v-if="record && record.id">
        <div class="flex items-stretch w-full white-transparent mb-px">
            <div class="flex items-stretch w-full">
                <div v-if="record.image" class="relative w-20 h-full flex items-center" style="min-width: 5rem; min-height: 5rem;">
                    <offline-media-card 
                        :element="record.image" :filesCount="1" :hideDelete="true" :index="0"
                        @updateElement="updateElement"
                    />
                </div>
                <div class="w-full p-1 flex items-center text-left">
                    <div>
                        <div class="text-sm font-semibold leading-5 break-words line-clamp-3" v-html="record.textOne">
                        </div>
                        <div class="text-xs leading-5 break-words line-clamp-3" v-html="record.textTwo">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OfflineMediaCard from '@/shared/Cards/OfflineMediaCard'

export default {
    props: [
        'record'
    ],
    components: {
        OfflineMediaCard
    },
    methods: {
        updateElement(inImage, inIndex) {
            this.$emit('updateMediaRecordImage', inImage)
        }
    }
}
</script>