<template>
    <div class="fixed">
        <div class="modal-background-image-container">
            <div v-if="!altImage || !showImage" class="h-full w-full">
                <img :src="transformUrl(bgImage, ['optimize', 'md', 'q80'])" class="object-cover h-full w-full" />
            </div>
            <div v-if="altImage" :class="{ 'h-full w-full': showImage, 'h-0 w-0': !showImage }">
                <img @load="srcLoaded = true" :src="transformUrl(altImage, ['optimize', 'md', 'q80'])" class="object-cover h-full w-full" />
            </div>
        </div>
        <div class="modal-background-image-container">
            <div class="h-full w-full black-blur-3">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['bgImage', 'altImage'],
    data() {
        return {
            showImage: false,
            srcLoaded: false
        }
    },
    watch: {
        srcLoaded: {
            handler(val) {
                if (val) {
                    this.showImage = true
                }
            }
        }
    }
}
</script>