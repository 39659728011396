<template>
    <div v-if="formLoaded" class="w-full">
        <input-panel 
            fieldFormName="qty" fieldLabel="Quantity" fieldPlaceholder="Quantity" :fieldRequired="true" fieldType="number" :fieldValue="discountForm.qty" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(discountForm.qty)" 
                message="Quantity must be entered"
            />
            <validation-message v-if="validating && duplicateQty && duplicateQty == discountForm.qty" 
                message="Duplicate Quantity"
            />
        </input-panel>
        <input-panel 
            fieldFormName="percentage" fieldLabel="Percentage Discount" fieldPlaceholder="Percentage Discount" :fieldRequired="true" fieldType="number" 
            :fieldValue="discountForm.percentage" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(discountForm.percentage)" 
                message="Discount Percentage must be entered"
            />
        </input-panel>
        <select-panel 
            :fieldData="yesNoData" fieldLabel="Enabled" fieldFormName="enabled" :fieldRequired="true" :fieldValue="discountForm.enabled" 
            :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <div class="w-full white-transparent mt-px p-2">
            <div @click="clickSaveBulkDiscount()" class="w-full" :class=" { 'standard-button-base': allOkay, 'disabled-button-base': !allOkay } ">
                Save
            </div>
            <div @click="clickDelete()" class="mt-2 w-full secondary-button-base">
                Delete
            </div>
            <div v-if="isChanges" @click="clickCancelChanges()" class="mt-2 w-full alt-button-base">
                Cancel Changes
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'bulkDiscount'
    ],
    data() {
        return {
            discountForm: {
                enabled: false,
                id: null,
                percentage: null,
                qty: null
            },
            duplicateQty: false,
            formLoaded: false,
            validating: false
        }
    },
    computed: {
        allOkay() {
            return Boolean(this.discountForm.qty && this.discountForm.percentage)
        },
        isChanges() {
            return Boolean(this.discountForm.qty != this.bulkDiscount.qty || this.discountForm.percentage != this.bulkDiscount.percentage || this.discountForm.enabled != this.bulkDiscount.enabled)
        }
    },
    mounted() {
        this.discountForm.id = this.bulkDiscount.id
        this.discountForm.qty = this.bulkDiscount.qty
        this.discountForm.percentage = this.bulkDiscount.percentage
        this.discountForm.enabled = this.bulkDiscount.enabled
        this.formLoaded = true
    },
    methods: {
        clickCancelChanges() {
            this.discountForm.qty = this.bulkDiscount.qty
            this.discountForm.percentage = this.bulkDiscount.percentage
            this.discountForm.enabled = this.bulkDiscount.enabled
        },
        clickDelete() {
            if (confirm(`You wish to delete this Bulk Discount (Quantity ${this.bulkDiscount.qty})?`)) {
                this.$emit('deleteBulkDiscount', this.bulkDiscount.id)
            }
        },
        clickSaveBulkDiscount() {
            if (this.allOkay) {
                this.duplicateQty = null
                this.validating = false
                
                let checkQty = null
                
                if (this?.productBulkDiscounts?.discounts ?? false) {
                    checkQty = this.productBulkDiscounts.discounts.find((myDiscount) => myDiscount.qty == this.discountForm.qty && myDiscount.id != this.discountForm.id)
                }

                if (checkQty) {
                    this.duplicateQty = this.discountForm.qty
                    this.validating = true
                    alert("That Quantity already exists")
                }
                else {
                    this.$emit('updateBulkDiscount', this.discountForm)
                    this.pageMessage('success', "Bulk Discount saved")
                }
            }
            else {
                this.validating = true
            }
        },
        updateFormValue(inField, inValue) {
            this.discountForm[inField] = inValue
        }
    }
}
</script>