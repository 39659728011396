const popupMixins = {
    methods: {
        closeLastPopup() {
            if (this.popups.length) {
                this.popupOut(this.getLastArrayKey(this.popups))
            }
        },
        popupCheck(inName) {
            return this.getLastArrayKey(this.popups) == inName
        },
        popupIn(inName) {
            var myPopups = this.popups
            myPopups.push(inName)

            this.replaceStateDataArray([
                { popups: myPopups }
            ])
        },
        popupOut(inName) {
            this.replaceStateDataArray([
                { popups: this.popups.filter(item => item !== inName) }
            ])
        }
    }
}

export default popupMixins