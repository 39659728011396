<template>
    <a :href="ogData.url" :target="linkTarget">
        <div v-if="ogData.images[0]" class="mb-3">
            <img @load="srcLoaded = true" :src="transformUrl(ogData.images[0].secure_url ? ogData.images[0].secure_url : ogData.images[0].image, ['optimize', 'md'])" class="object-cover" :class="{ 'w-0 h-0': !showImage, 'w-full h-full': showImage }" loading="lazy"/>
        </div>
        <div class="px-5 pb-3">
            <div v-html="ogData.title">
            </div>
            <div class="text-xs text-orange-400">
                {{ ogData.url }}
            </div>
            <div v-if="ogData.description" v-html="nlToBr(ogData.description)" class="mt-1 text-xs line-clamp-6">
            </div>
        </div>
    </a>
</template>

<script>
export default {
    props: {
        ogData: null,
    },
    data() {
        return {
            showImage: false,
            srcLoaded: false
        }
    },
    computed: {
        linkTarget() {
            return this.ogData.url.toLowerCase().indexOf('buildz.pro') == -1 && this.ogData.url.toLowerCase().indexOf('buildz.test') == -1 ? "_blank" : ""
        }
    },
    watch: {
        srcLoaded: {
            handler(val) {
                if (val) {
                    this.showImage = true
                }
            }
        }
    }
}
</script>