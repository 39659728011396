import moment from 'moment'

const eventMixins = {
    methods: {
        closeNewEventModal() {
            this.setStateData([
                { showNewEventModal: false },
                { eventEditForm: null },
                { editingEvent: null }
            ])
        },
        deleteEvent(inEventId, inEventName) {
            buildzAxios?.delete(this.getRoute('eventDelete', inEventId))
                .then((response) => {
                    if (response.status == 200) {
                        this.pageMessage('success', `You deleted the event ${inEventName}`)
                        this.routeTo('/events/upcoming')
                        this.closeNewEventModal()
                    }
                    else {
                        this.pageMessage('error', 'Error deleting event')
                    }

                    this.postingNewEvent = false
                })
                .catch((error) => {
                    this.pageMessage('error', 'Error deleting event')
                    this.postingNewEvent = false
                })
        },
        eventMine(inEvent) {
            return inEvent.owner_id && this.loggedUserId && inEvent.owner_id == this.loggedUserId
        },
        isOnNowOrWhat(inEvent) {
            let nowUTC = moment.utc(new Date()).valueOf()
            let eventStart = moment.utc(inEvent.utc_start_time).valueOf()
            let eventEnd = moment.utc(inEvent.utc_end_time).valueOf()

            return nowUTC >= eventStart && nowUTC <= eventEnd ? 'now' : (nowUTC > eventEnd ? 'past' : 'future')
        },
        openNewEventModal(inEditingEvent = null) {
            if (this.serverConnected) {
                this.setStateData([
                    { editingEvent: inEditingEvent },
                    { showNewEventModal: true }
                ])
            }
        },
        postAttendeeStatus(inEventId, inStatus) {
            buildzAxios?.put(this.getRoute('attendeeUpdate', inEventId), {
                status: inStatus
            })
            .then((response) => {
                if (response.status == 200 && response?.data?.event) {
                    this.publishUpdatedEvent(response.data.event)
                }
                else {
                    this.pageMessage('error', 'Error updating event')
                }

                this.updatingStatus = false
            })
            .catch((error) => {
                this.pageMessage('error', 'Error updating event')
                this.updatingStatus = false
            })
        },
        publishUpdatedEvent(inEvent) {
            if (this.eventLists) {
                Object.entries(this.eventLists).forEach(([listId, events]) => {
                    let myEvents = this.objCopy(events)

                    myEvents = myEvents.map((event) => {
                        if (event.id == inEvent.id) {
                            return inEvent
                        }
                        else {
                            return event
                        }
                    })

                    this.updateEvents(myEvents, listId)
                })
            }

            this.setStateData([
                { updatedEvent: inEvent }
            ])
        },
        updateEvents(inEvents, inListId) {
            if (this.eventLists) {
                let { [`${inListId}`]: _, ...myListEvents } = this.objCopy(this.eventLists)
                myListEvents = { ...myListEvents, [`${inListId}`]: inEvents }

                this.replaceStateData([
                    { eventLists: this.objCopy(myListEvents) }
                ])
            }
            else {
                this.replaceStateData([
                    { eventLists: { [`${inListId}`]: inEvents } }
                ])
            }
        },
        voteForPedro(inLeaderboard, inBuildId, inFromLeaderboard, inAllowedGuest = false) {
            buildzAxios?.post(this.getRoute('leaderboardVote', inLeaderboard.id),
                {
                    buildId: inBuildId,
                    fromEvent: inFromLeaderboard,
                    allowedGuest: inAllowedGuest
                })
                .then((response) => {
                    if (response.status == 200) {
                        if (inFromLeaderboard && response?.data?.event) {
                            this.publishUpdatedEvent(response.data.event)
                        }

                        if (response?.data?.message != "success") {
                            this.pageMessage('error', response.data.message)
                        }
                        else {
                            this.pageMessage('success', 'Thank you for voting!')
                        }

                        this.togglingVote = false
                    }
                    else {
                        this.pageMessage('error', 'An error occured')
                        this.togglingVote = false
                    }
                })
                .catch((error) => {
                    this.togglingVote = false
                })
        }
    }
}

export default eventMixins