<template>
    <div class="px-1 py-4" :class="{ 'white-transparent': validRate(), 'red-transparent': !validRate() }" style="margin-bottom: 1px;">
        <div class="flex items-center justify-between w-full">
            <div>
                {{ rateDescription }}
            </div>
            <div class="pl-2 flex items-center">
                <div v-if="!shippingIsUnitRate(rate)" class="mr-2">
                    {{ displayPrice(sellerVendorData.defaultCurrency, rate.price) }}
                </div>
                <div v-if="!readOnly" @click="clickDeleteRate()" class="cursor-pointer">
                    <fa-icon icon="trash-can" type="fas" class="h-6 w-6" />
                </div>
                <div v-if="!readOnly" @click="clickEditRate()" class="cursor-pointer mx-2">
                    <fa-icon icon="pen-to-square" type="fas" class="h-6 w-6" />
                </div>
            </div>
        </div>
        <div v-if="shippingIsPriceRate(rate)" class="mt-2">
            <div>
                {{ displayPrice(sellerVendorData.defaultCurrency, rate.minPrice) }} - {{ maxPrice }}
            </div>
        </div>
        <div v-if="shippingIsWeightRate(rate)" class="mt-2">
            <div>
                {{ rate.minWeight }}kg - {{ maxWeight }}
            </div>
        </div>
        <div v-if="shippingIsUnitRate(rate)" class="mt-2">
            <div class="flex items-center justify-between">
                <div class="w-1/2">
                    <span class="font-semibold">First:</span> {{ displayPrice(sellerVendorData.defaultCurrency, rate.firstUnitPrice) }}
                </div>
                <div class="w-1/2">
                    <span class="font-semibold">Additional:</span> {{ displayPrice(sellerVendorData.defaultCurrency, rate.additionalUnitPrice) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['zone', 'rate', 'readOnly', 'type'],
    computed: {
        maxPrice() {
            return this.rate.maxPrice == 999999999 ? 'No Limit' : this.displayPrice(this.sellerVendorData.defaultCurrency, this.rate.maxPrice)
        },
        maxWeight() {
            return this.rate.maxWeight == 999999999 ? 'No Limit' : `${this.rate.maxWeight}kg`
        },
        rateDescription() {
            return (this.rate?.newCustomRateName ?? false) ? this.rate.newCustomRateName : (this.rate?.customRateName ?? this.transitTimes[this.rate.transitTime])
        }
    },
    methods: {
        clickDeleteRate() {
            this.$emit('clickDeleteRate', this.rate)
        },
        clickEditRate() {
            this.$emit('clickEditRate', this.rate)
        },
        validRate() {
            return this.shippingCheckValidRate(this.zone, this.type, this.rate)
        }
    }
}
</script>