<template>
    <div @click="clickLikeButton">
        <div class="flex items-center mx-auto" :class="{ 'text-orange-400': (imClicked && !iLike) || (iLike && !imClicked) }">
            <fa-icon icon="thumbs-up" type="fas" class="h-7 w-7" />
            <span v-if="reactionCounts && reactionCounts.like" class="block ml-2 font-semibold text-lg whitespace-nowrap">{{ reactionCounts.like }}</span> 
        </div>
    </div>
</template>

<script>

export default {
    props: {
        activity: null,
        feedId: null,
        queryId: null
    },
    data() {
        return {
            imClicked: false
        }
    },
    computed: {
        iLike() {
            return this?.ownReactions && this?.ownReactions?.like
        },
        isLikeToggling() {
            return this.userTogglingActivityLikes && this.userTogglingActivityLikes.includes(this.activity.id)
        },
        iToggleLike() {
            return (this.iLike && !this.isLikeToggling) || (!this.iLike && this.isLikeToggling)
        },
        ownReactions() {
            return this.activity?.own_reactions
        },
        reactionCounts() {
            return this.activity?.reaction_counts
        },
        userLike() {
            return this?.ownReactions?.like ? this.ownReactions.like[0] : null
        }
    },
    watch: {
        iLike: {
            handler(val) {
                this.imClicked = false
            }
        }
    },
    methods: {
        clickLikeButton() {
            if (!this.loggedUser) {
                let url = new URL(window.location.href)

                this.setStateData([
                    { afterRegisterRoute: `${url.pathname}` }
                ])
                
                this.routeTo('/register')
            }
            else if (!this.userCanPost) {
                this.pageMessage('info', 'You need to verify your email to like a post')
            }
            else {
                this.imClicked = true 

                setTimeout(() => {
                    this.toggleLikeActivity(this.activity, this.userLike, this.feedId)

                    this.appsFlyerEvent(
                        {
                            eventName: 'Clicked Like',
                            eventValue: {
                                action: this.userLike ? "Unlike" : "Like",
                                actvityId: this?.activity?.foreign_id
                            }
                        }
                    )

                    if (this?.activity?.foreign_id) {
                        this.algoliaConvert(this.activity.foreign_id, 'activities', this.queryId)
                    }
                }, 100)
            }
        }
    }
}
</script>