const smartDevicesMixins = {
    methods: {
        async checkSmartDeviceCode(inCode) {
            this.openSpinner()

            let myStoredCode = await this.getStorage(`code_${inCode}`)

            if (myStoredCode?.value && myStoredCode.value.type && myStoredCode.value.url) {
                if (myStoredCode.type == 'build') {
                    this.routeTo(myStoredCode.value.url)
                    this.closeSpinner()
                }
                else if (myStoredCode.type == 'garage') {
                    this.routeTo(myStoredCode.value.url)
                    this.closeSpinner()
                }
                else if (myStoredCode.type == 'url') {
                    this.openNewTabLink(myStoredCode.value.url)
                    this.closeSpinner()
                }
            }

            if (this.serverConnected) {
                buildzAxios?.get(this.getRoute('codeData', inCode))
                    .then((response) => {
                        if (response.status == 200 && response.data.code) {
                            this.setStorage(`code_${inCode}`, JSON.stringify(response.data.code))

                            if (response.data.code.type == 'build') {
                                this.routeTo(response.data.code.url)
                            }
                            else if (response.data.code.type == 'garage') {
                                this.routeTo(response.data.code.url)
                            }
                            else if (response.data.code.type == 'url') {
                                this.openNewTabLink(response.data.code.url)
                            }
                        }
                        else if (response.status == 200 && response.data.unlinked && response.data.tag_code) {
                            this.routeTo(`/devices/${inCode}/${response.data.tag_code}`)
                        }

                        this.closeSpinner()
                    })
                    .catch((error) => {
                        this.closeSpinner()
                    })
            }
        }
    }
}

export default smartDevicesMixins