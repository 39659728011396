<template>
    <div class="w-full">
        <select-panel
            :fieldData="shippingModels" fieldFormName="defaultShippingModel" fieldLabel="Shipping Model" fieldLabelClasses="text-gray-800" :fieldRequired="true"
            :fieldValue="sellerDataForm.defaultShippingModel" :forceMobile="true" :isReverseLabelAndValue="true"
            @fieldChange="updateSellerDataFormSelectValue"
        >
            <validation-message v-if="validating && !sellerDataForm.defaultShippingModel" 
                message="Shipping Model must be selected"
            />
        </select-panel>
        <select-panel v-if="sellerDataForm.chargeTax"
            :fieldData="sortedTaxRates" fieldDataLabel="label" fieldDataValue="id" fieldFormName="shippingTaxRate" fieldLabel="Shipping Tax Rate" fieldLabelClasses="text-gray-800"
            :fieldRequired="true" :fieldValue="sellerDataForm.shippingTaxRate" :forceMobile="true"
            @fieldChange="updateSellerDataFormSelectValue"
        >
            <validation-message v-if="validating && !sellerDataForm.shippingTaxRate" 
                message="Shipping Tax Rate must be selected"
            />
        </select-panel>
    </div>
</template>

<script>
export default {
    props: [
        'sellerDataForm',
        'validating'
    ],
    computed: {
        sortedTaxRates() {
            return !this.sellerDataForm.taxRates ? [] : this.sellerDataForm.taxRates
                .sort((a, b) => a.label < b.label ? -1 : 1)
        }
    },
    methods: {
        updateSellerDataFormSelectValue(inField, inValue) {
            this.$emit('updateSellerDataFormSelectValue', inField, inValue)
        }
    }
}
</script>