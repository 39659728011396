<template>
    <modal v-if="popupOpen" :open="popupOpen" @close="$emit('closePopup')">
        <modal-background-image
            :bgImage="defaultHero"
        />
        <div class="absolute top-0 left-0 w-full" :class="{ 'px-2 pb-16': windowWidth < desktopMaxWidth, 'desktop-modal-box': windowWidth >= desktopMaxWidth }">
            <div class="relative" :class="{ 'desktop-modal': windowWidth >= desktopMaxWidth }">
                <div class="absolute left-1 top-1 flex items-center" style="z-index: 1;">
                    <div v-if="(saving && !saved) || mediaInProgress" class="ml-2 text-sm">Saving...</div>
                    <div v-if="saved" class="ml-2 text-sm">Saved.</div>
                </div>
                <div class="w-full pt-8 mt-3">
                    <div class="w-full">
                        <label class="text-sm font-bold">
                            Transit Time
                        </label>
                        <div class="w-full">
                            <select v-model="form.transitTime" @change="saveTransitTime" class="form-input edit-field-text-color bg-white py-1 pr-8 w-full">
                                <option v-for="(value, name) in transitTimes" :key="name" :value="name">{{ value }}</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="form.transitTime == 'custom'" class="mt-2 w-full">
                        <label class="text-sm font-bold">
                            Custom Rate Name
                        </label>
                        <div class="w-full">
                            <select v-model="form.customRateName" @change="save" class="form-input edit-field-text-color bg-white py-1 pr-8 w-full">
                                <option v-for="(value, index) in customRates" :key="index" :value="value">{{ value }}</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="form.transitTime == 'custom' && form.customRateName == 'New rate'" class="mt-2 w-full">
                        <label class="text-sm font-bold">
                            New Custom Rate Name
                        </label>
                        <div>
                            <input type="text" v-model="form.newCustomRateName" @input="save" class="edit-field-text-color bg-white py-1 w-full form-input" />
                        </div>
                    </div>
                    <div v-if="form.transitTime == 'custom' && form.customRateName == 'New rate'" class="mt-2 w-full">
                        <label class="text-sm font-bold">
                            Save New Custom Rate Name
                        </label>
                        <div class="w-full">
                            <select v-model="form.saveNewCustomRateName" @change="save" class="form-input edit-field-text-color bg-white py-1 pr-8 w-full">
                                <option :value="true">Yes</option>
                                <option :value="false">No</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="!shippingIsUnitRate(form)" class="mt-2 w-full">
                        <div class="w-full">
                            <label class="text-sm font-bold leading-5">
                                Price
                            </label>
                            <div>
                                <input type="number" v-model="form.price" @input="save" class="edit-field-text-color bg-white py-1 w-full form-input" placeholder="Price" />
                            </div>
                        </div>
                    </div>
                    <div v-if="shippingIsWeightRate(form)" class="mt-2 w-full flex items-start">
                        <div class="w-1/2 mr-1">
                            <label class="text-sm font-bold">
                                Minimum Weight (kg)
                            </label>
                            <div>
                                <input type="number" v-model="form.minWeight" @input="save" class="edit-field-text-color bg-white py-1 w-full form-input" placeholder="0" />
                            </div>
                        </div>
                        <div class="w-1/2 ml-1">
                            <label class="text-sm font-bold">
                                Maximum Weight (kg)
                            </label>
                            <div>
                                <input type="number" v-model="form.maxWeight" @input="save" class="edit-field-text-color bg-white py-1 w-full form-input" placeholder="No limit" />
                            </div>
                        </div>
                    </div>
                    <div v-if="shippingIsPriceRate(form)" class="mt-2 w-full flex items-start">
                        <div class="w-1/2 mr-1">
                            <label class="text-sm font-bold leading-5">
                                Minimum Price
                            </label>
                            <div>
                                <input type="number" v-model="form.minPrice" @input="save" class="edit-field-text-color bg-white py-1 w-full form-input" placeholder="0" />
                            </div>
                        </div>
                        <div class="w-1/2 ml-1">
                            <label class="text-sm font-bold leading-5">
                                Maximum Price
                            </label>
                            <div>
                                <input type="number" v-model="form.maxPrice" @input="save" class="edit-field-text-color bg-white py-1 w-full form-input" placeholder="No limit" />
                            </div>
                        </div>
                    </div>
                    <div v-if="shippingIsUnitRate(form)" class="mt-2 w-full flex items-start">
                        <div class="w-1/2 mr-1">
                            <label class="text-sm font-bold leading-5">
                                First Unit Price
                            </label>
                            <div>
                                <input type="number" v-model="form.firstUnitPrice" @input="save" class="edit-field-text-color bg-white py-1 w-full form-input" placeholder="First Unit Price" />
                            </div>
                        </div>
                        <div class="w-1/2 ml-1">
                            <label class="text-sm font-bold leading-5">
                                Additional Unit Price
                            </label>
                            <div>
                                <input type="number" v-model="form.additionalUnitPrice" @input="save" class="edit-field-text-color bg-white py-1 w-full form-input" placeholder="Additional Unit Price" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal> 
</template>

<script>
import Modal from '@/shared/Modal'
import ModalBackgroundImage from '@/shared/ModalBackgroundImage'

export default {
    props: ['customRates', 'popupOpen', 'shippingRate'],
    components: {
        Modal,
        ModalBackgroundImage
    },
    data() {
        return {
            form: {
                additionalUnitPrice: null,
                basedOn: null,
                customRateName: null,
                firstUnitPrice: null,
                freeShipping: false,
                hasConditions: false,
                id: null,
                maxPrice: null,
                maxWeight: null,
                minPrice: null,
                minWeight: null,
                newCustomRateName: null,
                price: null,
                saveNewCustomRateName: true,
                transitTime: null
            }
        }
    },
    watch: {
        shippingRate: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.form.basedOn = this.shippingRate.basedOn
                    this.form.customRateName = this.shippingRate.customRateName
                    this.form.hasConditions = this.shippingRate.hasConditions
                    this.form.maxPrice = this.shippingRate.maxPrice != 999999999 ? this.shippingRate.maxPrice : null
                    this.form.minPrice = this.shippingRate.minPrice != 0 ? this.shippingRate.minPrice : null
                    this.form.maxWeight = this.shippingRate.maxWeight != 999999999 ? this.shippingRate.maxWeight : null
                    this.form.minWeight = this.shippingRate.minWeight != 0 ? this.shippingRate.minWeight : null
                    this.form.newCustomRateName = this.shippingRate.newCustomRateName
                    this.form.price = this.shippingRate.price
                    this.form.firstUnitPrice = this.shippingRate.firstUnitPrice
                    this.form.additionalUnitPrice = this.shippingRate.additionalUnitPrice
                    this.form.saveNewCustomRateName = this.shippingRate.saveNewCustomRateName
                    this.form.transitTime = this.shippingRate.transitTime
                    this.form.freeShipping = this.shippingRate.freeShipping ?? false
                    this.form.id = this.shippingRate.id
                }
            }
        }
    },
    methods: {
        save() {
            this.$emit('saveEditRate', this.objCopy(this.form))
        },
        saveTransitTime() {
            if (this.form.transitTime != 'custom') {
                this.form.newCustomRateName = null
                this.form.customRateName = null
            }

            this.save()
        }
    }
}
</script>