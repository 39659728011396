<template>
    <div class="white-transparent py-2" style="margin-bottom: 1px;">
        <div class="font-semibold my-1 pl-1">
            {{ label }}
        </div>
        <rate v-for="(rate, index) in rates" 
            :zone="zone" :rate="rate" :readOnly="readOnly" :type="type"
            @clickEditRate="clickEditRate" @clickDeleteRate="clickDeleteRate"
        />
        <div v-if="okayToAdd" class="desktop-button-box justify-end mt-6 px-1">
            <div @click="clickNewRate()" class="alt-button-base">
                Add {{ label.toLowerCase() }} rate
            </div>
        </div>
    </div>
</template>

<script>
import Rate from './Rate'

export default {
    props: ['type', 'label', 'rates', 'zone', 'readOnly'],
    components: {
        Rate
    },
    computed: {
        okayToAdd() {
            return !this.readOnly
        }
    },
    methods: {
        clickDeleteRate(inRate) {
            this.$emit('clickDeleteRate', inRate)
        },
        clickEditRate(inRate) {
            this.$emit('clickEditRate', inRate)
        },
        clickNewRate() {
            this.$emit('clickNewRate', this.type)
        }
    }
}
</script>