<template>
    <div v-if="sale" class="w-full">
        <div v-if="groupBuy" class="w-full">
            <div v-if="showGroupBuyLabels(groupBuy.type) && groupBuy.current_qty < groupBuy.min_quantity && gbStatus == 'current'" class="w-full text-lg text-center bg-red-500 uppercase mb-1">
                {{ groupBuyType(groupBuy.type).labelSingular }} LIVE
            </div>
            <div v-if="showGroupBuyLabels(groupBuy.type) && groupBuy.current_qty >= groupBuy.min_quantity" class="w-full text-lg text-center bg-green-500 uppercase mb-1">
                {{ groupBuyType(groupBuy.type).labelSingular }} FUNDED
            </div>
            <div v-if="showGroupBuyLabels(groupBuy.type) && groupBuy.current_qty < groupBuy.min_quantity && gbStatus == 'ended'" class="w-full text-lg text-center bg-gray-500 uppercase mb-1">
                {{ groupBuyType(groupBuy.type).labelSingular }} NOT FUNDED
            </div>
            <div v-if="!minimal" class="p-2 mb-1" :class="{ 'white-transparent': !whiteLabel || whiteLabelDark, 'black-transparent': whiteLabel && whiteLabelLight }">
                <div v-if="!hideOfferDescription(groupBuy)" v-html="groupBuy.description" :id="`desc-${sale.id}`" class="text-xs overflow-hidden w-full text-center" :style="!showMoreLinkClicked ? { maxHeight: '3.15rem' } : {}">
                </div>
                <div v-if="needsShowMore && !minimal && !hideOfferDescription(groupBuy)" class="w-full text-center mt-1 mb-4">
                    <div v-if="!showMoreLinkClicked" @click="clickShowMore()" class="text-xs cursor-pointer" :class="{ 'text-orange-400': !whiteLabel }" :style="whiteLabel ? { 'color': whiteLabelData.secondaryColor} : {}">
                        See full description
                    </div>
                    <div v-else @click="clickShowMore()" class="text-xs cursor-pointer" :class="{ 'text-orange-400': !whiteLabel }" :style="whiteLabel ? { 'color': whiteLabelData.secondaryColor} : {}">
                        Hide full description
                    </div>
                </div>
                <div v-if="!minimal" class="w-full my-2 ">
                    <div v-if="!showGroupBuyDetails" @click="clickShowGroupBuyDetails()" class="alt-button-base" :style="whiteLabel ? { 'border-color': whiteLabelData.secondaryColor, 'color': whiteLabelData.secondaryColor } : {}">
                        {{ showGroupOfferDetailsText(groupBuy) }}
                    </div>
                    <div v-if="showGroupBuyDetails" @click="clickShowGroupBuyDetails()" class="alt-button-base" :style="whiteLabel ? { 'border-color': whiteLabelData.secondaryColor, 'color': whiteLabelData.secondaryColor } : {}">
                        {{ hideGroupOfferDetailsText(groupBuy) }}
                    </div>
                </div>
                <div v-if="groupBuy && showGroupBuyDetails && !minimal" class="mt-2">
                    <group-buy-graphic v-if="!groupBuy.white_label_data.dollars_graphic"
                        :groupBuy="groupBuy" :eoi="currentEOI" :barId="`sale-bar-${sale.id}`"
                    />
                    <group-buy-graphic-dollars v-else
                        :groupBuy="groupBuy" :barId="`sale-bar-${sale.id}`"
                    />
                    <group-buy-info
                        :groupBuy="groupBuy" :eoi="currentEOI"
                    />
                    <group-buy-text
                        :groupBuy="groupBuy" :currency="null" :showShipping="false" 
                    />
                </div>
            </div>
        </div>
        <div v-if="!singleLine">
            <sale-line-card v-for="line in sale.sale_lines" 
                :currency="sale.currency" :groupBuy="groupBuy" :line="line" :product="lineProduct(line)" :sale="sale" :showGroupBuyDetails="showGroupBuyDetails"
                :sortedPricingLevels="sortedPricingLevels"
            />
        </div>
        <div v-else>
            <sale-line-card 
                :currency="sale.currency" :groupBuy="groupBuy" :line="sale.sale_lines[0]" :product="lineProduct(sale.sale_lines[0])" :sale="sale" :showGroupBuyDetails="showGroupBuyDetails"
                :sortedPricingLevels="sortedPricingLevels"
            >
            </sale-line-card>
            <div v-if="sale.sale_lines.length > 1" class="w-full text-center py-1" :class="{ 'white-transparent': !whiteLabel || whiteLabelDark, 'black-transparent': whiteLabel && whiteLabelLight }">
                <div @click.native.prevent="clickSeeAllLines()" class="text-xs cursor-pointer" :class="{ 'text-orange-400': !whiteLabel }" :style="whiteLabel ? { 'color': whiteLabelData.secondaryColor} : {}">
                    Show all lines
                </div>
            </div>
        </div>
        <sale-message v-if="sale.customer_message && !minimal"
            :fromCustomer="fromCustomer" :saleMessage="sale.customer_message"
        />
        <sale-shipping-address v-if="!minimal"
            :customerEmail="sale.customer.email" :shippingDetails="sale.shipping_details"
        />
    </div>
</template>

<script>
import GroupBuyGraphic from '@/shared/Marketplace/GroupBuyGraphic'
import GroupBuyGraphicDollars from '@/shared/Marketplace/GroupBuyGraphicDollars'
import GroupBuyInfo from '@/shared/Marketplace/GroupBuyInfo'
import GroupBuyText from '@/shared/Marketplace/GroupBuyText'
import SaleLineCard from '@/shared/Marketplace/SaleLineCard'
import SaleMessage from '@/shared/Marketplace/SaleMessage'
import SaleShippingAddress from '@/shared/Marketplace/SaleShippingAddress'

export default {
    props: [
        'fromCustomer',
        'minimal',
        'sale',
        'singleLine'
    ],
    components: {
        GroupBuyGraphic,
        GroupBuyGraphicDollars,
        GroupBuyInfo,
        GroupBuyText,
        SaleLineCard,
        SaleMessage,
        SaleShippingAddress
    },
    data() {
        return {
            needsShowMore: false,
            showGroupBuyDetails: false,
            showMoreLinkClicked: false
        }
    },
    computed: {
        currentEOI() {
            return this.gbStatus == "eoi"
        },
        gbStatus() {
            if (!this.groupBuy) {
                return null
            }

            if (this.groupBuy.start_time < this.currentUnixTime() && this.groupBuy.end_time > this.currentUnixTime()) {
                return "current"
            }

            if (this.groupBuy.start_time < this.currentUnixTime() && this.groupBuy.end_time < this.currentUnixTime()) {
                return "ended"
            }

            if (this.groupBuy.eoi && this.groupBuy.eoi_start_time < this.currentUnixTime() && this.groupBuy.eoi_end_time < this.currentUnixTime()) {
                return "eoi"
            }
        },
        groupBuy() {
            return this?.sale?.group_buy ?? null
        },
        sortedPricingLevels() {
            if (!this?.groupBuy?.quantity_levels) {
                return []
            }

            return this.groupBuy.quantity_levels
                .sort((a, b) => a.discount > b.discount ? 1 : -1)
        }
    },
    mounted() {
        setTimeout(() => {
            this.setNeedsShowMore()
        }, 500)

        setTimeout(() => {
            this.setNeedsShowMore()
        }, 1000)

        setTimeout(() => {
            this.setNeedsShowMore()
        }, 2000)
    },
    methods: {
        clickSeeAllLines() {
            this.$emit('clickSeeAllLines')
        },
        clickShowGroupBuyDetails() {
            this.showGroupBuyDetails = !this.showGroupBuyDetails
        },
        clickShowMore() {
            this.showMoreLinkClicked = !this.showMoreLinkClicked
        },
        lineProduct(inLine) {
            if (this?.sale?.data?.bundle ?? false) {
                return this.sale.data.bundle
            }

            return this.sale.product_data.find((product) => product.id == inLine.product_id)
        },
        setNeedsShowMore() {
            if (!this.needsShowMore) {
                this.needsShowMore = Boolean(this.elGet(`desc-${this.sale.id}`) && this.elGet(`desc-${this.sale.id}`).scrollHeight > this.elGet(`desc-${this.sale.id}`).clientHeight)
            }
        }
    }
}
</script>