<template>
    <div class="w-full px-1">
        <div v-if="sortedDiscountCodes">
            <div v-for="discountCode in sortedDiscountCodes">
                <panel-section 
                    :adminSection="true" :sectionLabel="`${discountCode.code} (${discountCodeUseCount(discountCode)} uses)`"
                >
                    <edit-discount-code
                        :discountCode="discountCode" :discountCodes="discountCodes"
                        @deleteDiscountCode="deleteDiscountCode" @updateDiscountCode="updateDiscountCode"
                    />
                </panel-section>
            </div>
        </div>
    </div>
</template>

<script>
import EditDiscountCode from './EditDiscountCode'

export default {
    props: [
        'discountCodes',
        'refreshCodes'
    ],
    components: {
        EditDiscountCode
    },
    data() {
        return {
            discountCodeForm: {
                codes: null
            }
        }
    },
    computed: {
        sortedDiscountCodes() {
            // if (!this.discountCodeForm.codes) {
            //     return null
            // }

            // return this.discountCodeForm.codes
            //     .sort((a, b) => a.code < b.code ? -1 : 1)

            return this.discountCodeForm.codes
        }
    },
    watch: {
        refreshCodes: {
            handler(val) {
                if (val) {
                    this.discountCodeForm.codes = Object.values(this.objCopy(this.discountCodes))
                }
            }
        }
    },
    mounted() {
        this.discountCodeForm.codes = Object.values(this.objCopy(this.discountCodes))
    },
    methods: {
        deleteDiscountCode(inDiscountCode) {
            this.discountCodeForm.codes = this.discountCodeForm.codes.filter((myCode => myCode.code != inDiscountCode))
            this.$emit('updateDiscountCodes', this.discountCodeForm, true)
        },
        discountCodeUseCount(inCode) {
            return inCode.useCount ?? 0
        },
        updateDiscountCode(inForm, inCurrentCode, inSaveImmediate) {
            let myCode = this.discountCodeForm.codes.find((myCode) => myCode.code == inCurrentCode)
            
            if (myCode) {
                myCode.label = inForm.label
                myCode.percentage = inForm.percentage
                myCode.enabled = inForm.enabled
                myCode.code = inForm.code
                myCode.multiple = inForm.multiple
            }

            this.$emit('updateDiscountCodes', this.discountCodeForm, inSaveImmediate)
        }
    }
}
</script>