export default {
	methods: {
        getScaledValue(value, units, system) {
            if(system == "Metric") return value;

            if(units == 'Power'){
                return Math.round(value * 1.341);
            } 
            if(units == 'Torque'){
                return Math.round(value * 1.356);
            } 
            if(units == 'Weight'){
                return Math.round(value * 2.205);
            } 
            if(units == 'Power/Weight Ratio'){
                return Math.round(value * 0.6082 * 100) / 100;
            } 
            return value;
        },
        getScaledUnits(units, system) {
            if(units == 'Power'){
                if (system == "Metric") return 'kW';
                return 'hp';
            } 
            if(units == 'Torque'){
                if (system == "Metric") return 'Nm';
                return 'ft.lb';
            } 
            if(units == 'Weight'){
                if (system == "Metric") return 'kg';
                return 'lb';
            }
            if(units == 'Power/Weight Ratio'){
                if (system == "Metric") return 'kW/kg';
                return 'hp/lb';
            }
        },
        setScaledValue(value, units, system) {
            if(system == "Metric") return value;

            if(units == 'Power'){
                return Math.round(value / 1.341 * 100) / 100;
            } 
            if(units == 'Torque'){
                return Math.round(value / 1.356 * 100) / 100;
            } 
            if(units == 'Weight'){
                return Math.round(value / 2.205 * 100) / 100;
            } 
            return value;
        }

        

	}
}