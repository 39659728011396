<template>
    <div class="w-full">
        <div class="w-full px-2">
            <zone v-if="sellerDataForm.multipleCountries && sellerDataForm.useDefaultZone && defaultShippingZone"
                :defaultCurrency="sellerDataForm.defaultCurrency" :editingRateId="editingRateId" :editingRateZoneId="editingRateZoneId" :openZones="openZones" 
                :shippingModel="sellerDataForm.defaultShippingModel" :zone="defaultShippingZone"
                @clickCloseZone="clickCloseZone" @clickDeleteRate="clickDeleteRate" @clickEditRate="clickEditRate" @clickNewZoneRate="clickNewZoneRate" @clickOpenZone="clickOpenZone"
            />
            <zone v-for="zone in sortedShippingZones"
                :defaultCurrency="sellerDataForm.defaultCurrency" :editingRateId="editingRateId" :editingRateZoneId="editingRateZoneId" :openZones="openZones" 
                :shippingModel="sellerDataForm.defaultShippingModel" :zone="zone"
                @clickCloseZone="clickCloseZone" @clickDeleteRate="clickDeleteRate" @clickEditRate="clickEditRate" @clickNewZoneRate="clickNewZoneRate" @clickOpenZone="clickOpenZone"
            />
        </div>
        <validation-message v-if="validating && !shippingZonesOkay" 
            message="You must add rates to all Shipping Countries"
        />
    </div>
</template>

<script>
import Zone from './Zone'

export default {
    props: [
        'editingRateId',
        'editingRateZoneId',
        'sellerDataForm',
        'shippingZonesOkay',
        'validating'
    ],
    components: {
        Zone
    },
    data() {
        return {
            openZones: []
        }
    },
    computed: {
        defaultShippingZone() {
            return (this?.sellerDataForm?.shippingZones ?? false) && this.sellerDataForm.shippingZones.length ? this.sellerDataForm.shippingZones.find((myZone) => myZone.id == 1) : null
        },
        sortedShippingZones() {
            return !this.sellerDataForm.shippingZones ? [] : this.sellerDataForm.shippingZones
                .filter((zone) => zone.id != 1)
                .sort((a, b) => a.name < b.name ? -1 : 1)
        }
    },
    methods: {
        clickCloseZone(inZoneId) {
            if (this.openZones.includes(inZoneId)) {
                this.openZones = this.openZones.filter((zoneId) => zoneId != inZoneId)
            }
        },
        clickDeleteRate(inZoneId, inRate) {
            this.$emit('clickDeleteRate', inZoneId, inRate)
        },
        clickEditRate(inZoneId, inRate) {
            this.$emit('clickEditRate', inZoneId, inRate)
        },
        clickNewZoneRate(inZoneId) {
            this.$emit('clickNewZoneRate', inZoneId)
        },
        clickOpenZone(inZoneId) {
            if (!this.openZones.includes(inZoneId)) {
                this.openZones.push(inZoneId)
            }
        }
    }
}
</script>