const fbConversionsMixins = {
    methods: {
        fbConversionsEvent(inEventName, inEventId) {
            let url = window.location.href

            if (url.includes('apptest.buildz') || url.includes('whitetest.buildz') || url.includes('localhost')) {
            }
            else {
                buildzAxios?.post(this.getRoute('fbConversionsEvent'), {
                    eventName: inEventName,
                    eventId: inEventId,
                    fbp: this.getCookie('_fbp'),
                    fbc: this.getCookie('_fbc')
                })
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                    // this.pageMessage('error', 'Error retrieving data')
                })
            }
        },
        fbConversionsTest() {
            buildzAxios?.post(this.getRoute('fbConversionsTest'), {
                fbp: this.getCookie('_fbp'),
                fbc: this.getCookie('_fbc')
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
                // this.pageMessage('error', 'Error retrieving data')
            })
        }
    }
}

export default fbConversionsMixins