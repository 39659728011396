<template>
    <div v-if="formLoaded" class="w-full">
        <input-panel 
            fieldFormName="qty" fieldLabel="Quantity" fieldPlaceholder="Quantity" fieldType="number" :fieldValue="discountForm.qty" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(discountForm.qty)" 
                message="Quantity must be entered"
            />
            <validation-message v-if="validating && duplicateQty && duplicateQty == discountForm.qty" 
                message="Duplicate Quantity"
            />
        </input-panel>
        <input-panel 
            fieldFormName="percentage" fieldLabel="Percentage Discount" fieldPlaceholder="Percentage Discount" fieldType="number" :fieldValue="discountForm.percentage" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(discountForm.percentage)" 
                message="Discount Percentage must be entered"
            />
        </input-panel>
        <select-panel 
            :fieldData="yesNoData" fieldLabel="Enabled" fieldFormName="enabled" :fieldRequired="true" :fieldValue="discountForm.enabled" 
            :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <div class="w-full white-transparent mt-px py-4 px-2" :class="{ 'desktop-button-box justify-center': isDesktopWidth }">
            <div @click="clickCreateBulkDiscount()" class="w-full" :class=" { 'standard-button-base': allOkay, 'disabled-button-base': !allOkay } ">
                Create Bulk Discount
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'productBulkDiscounts'
    ],
    data() {
        return {
            duplicateQty: false,
            formLoaded: false,
            validating: false,
            discountForm: {
                enabled: true,
                percentage: null,
                qty: null
            }
        }
    },
    computed: {
        allOkay() {
            return Boolean(this.discountForm.qty && this.discountForm.percentage)
        }
    },
    mounted() {
        this.formLoaded = true
    },
    methods: {
        clickCreateBulkDiscount() {
            if (this.allOkay) {
                this.duplicateQty = null
                this.validating = false
                
                let checkQty = null
                
                if (this?.productBulkDiscounts?.discounts ?? false) {
                    checkQty = this.productBulkDiscounts.discounts.find((myDiscount) => myDiscount.qty == this.discountForm.qty)
                }

                if (checkQty) {
                    this.duplicateQty = this.discountForm.qty
                    this.validating = true
                    alert("That Quantity already exists")
                }
                else {
                    this.$emit('clickCreateNewBulkDiscount', this.objCopy(this.discountForm))
                    this.pageMessage('success', "Bulk Discount created")
                    this.discountForm.qty = null
                    this.discountForm.percentage = null
                    this.discountForm.enabled = true
                }
            }
            else {
                this.validating = true
            }
        },
        updateFormValue(inField, inValue) {
            this.discountForm[inField] = inValue
        }
    }
}
</script>