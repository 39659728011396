<template>
    <div v-if="formLoaded" class="w-full">
        <draggable v-if="mediaBlocks && mediaBlocks.length" group="media" @change="saveOrder()" item-key="index" tag="div" :list="mediaBlocks" handle=".handle-media" animation="250" easing="cubic-bezier(1, 0, 0, 1)">
            <template #item="{element, index}">
                <div class="flex items-start mt-px w-full white-transparent">
                    <div v-if="!newText && !newImage &&!newYouTube && !editingIndex" class="cursor-move handle-media p-2">
                        <fa-icon icon="up-down-left-right" type="fas" class="h-6 w-6" />
                    </div>
                    <div v-else class="w-10">
                    </div>
                    <div v-if="element.type == 'text' && editingIndex != index" v-html="element.text" class="w-full py-2">
                    </div>
                    <div v-if="element.type == 'text' && editingIndex == index" class="w-full">
                        <rich-text-panel 
                            fieldFormName="editingTextDescription" fieldLabel="Edit Text Block" :fieldValue="editingTextDescription"
                            @fieldInput="updateEditingText"
                        />
                        <div class="w-full mt-px pt-2 pb-4 px-2 white-transparent" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
                            <div @click="clickCancelEditText()" class="alt-button-base mt-2">
                                Cancel
                            </div>
                            <div @click="clickSaveEditText()" class="standard-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                                Save
                            </div>
                        </div>
                    </div>
                    <div v-if="element.type == 'image' && editingIndex != index" class="w-full py-2">
                        <offline-media-card 
                            :element="element.image" :filesCount="1" :hideDelete="true" :index="0"
                            @updateElement="updateElement"
                        />
                    </div>
                    <div v-if="element.type == 'image' && editingIndex == index" class="w-full py-2">
                        <media-panel 
                            fieldLabel="Edit Media Block" :hideSpinner="true" :imagesArray="editingGallery" :imagesOnly="false" :maxFiles="1" :multiple="false"
                            @saveMedia="saveLocalMediaEdit" @updateOfflineMedia="updateOfflineMediaEdit"
                        />
                        <div class="w-full mt-px pt-2 pb-4 px-2 white-transparent" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
                            <div @click="clickCancelEditImage()" class="alt-button-base mt-2">
                                Cancel
                            </div>
                            <div @click="clickSaveEditImage()" class="standard-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                                Save
                            </div>
                        </div>
                    </div>
                    <div v-if="element.type == 'youtube' && editingIndex != index" class="w-full py-2" v-html="youtubeLinks(element.url)">
                    </div>
                    <div v-if="element.type == 'youtube' && editingIndex == index" class="w-full">
                        <div class="text-gray-200 text-center font-semibold w-full pb-2 pt-3 white-transparent" style="font-size: 1rem; line-height: 1.5rem;">
                            Edit YouTube Block
                        </div>
                        <input-panel 
                            fieldFormName="editingYouTubeUrl" fieldLabel="URL" :fieldValue="editingYouTubeUrl"
                            @fieldInput="updateEditingYouTube"
                        />
                        <select-panel
                            :fieldData="yesNoData" fieldFormName="editingYouTubeAutoPlay" fieldLabel="Auto Play" :fieldValue="editingYouTubeAutoPlay" :isLabelAndValue="true"
                            @fieldChange="updateEditingYouTube"
                        />
                        <select-panel
                            :fieldData="yesNoData" fieldFormName="editingYouTubeLoop" fieldLabel="Loop" :fieldValue="editingYouTubeLoop" :isLabelAndValue="true"
                            @fieldChange="updateEditingYouTube"
                        />
                        <select-panel 
                            :fieldData="orientationData" fieldFormName="editingYouTubeOrientation" fieldLabel="Orientation" :fieldValue="editingYouTubeOrientation" :isLabelAndValue="true"
                            @fieldChange="updateEditingYouTube"
                        />
                        <div class="w-full mt-px pt-2 pb-4 px-2 white-transparent" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
                            <div @click="clickCancelEditYouTube()" class="alt-button-base mt-2">
                                Cancel
                            </div>
                            <div @click="clickSaveEditYouTube()" class="standard-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                                Save
                            </div>
                        </div>
                    </div>
                    <div class="w-10">
                        <div v-if="!newText && !newImage &&!newYouTube && !editingIndex" @click="clickDeleteMediaBlock(index, element.type)" class="cursor-pointer p-2">
                            <fa-icon icon="trash-can" type="fas" class="h-6 w-6" />
                        </div>
                        <div v-if="element.type == 'image' && !newText && !newImage && !newYouTube && editingIndex == null" @click="clickEditImageBlock(index)" class="cursor-pointer p-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-6 w-6" />
                        </div>
                        <div v-if="element.type == 'text' && !newText && !newImage &&!newYouTube && editingIndex == null" @click="clickEditTextBlock(index)" class="cursor-pointer p-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-6 w-6" />
                        </div>
                        <div v-if="element.type == 'youtube' && !newText && !newImage &&!newYouTube && editingIndex == null" @click="clickEditYouTubeBlock(index)" class="cursor-pointer p-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-6 w-6" />
                        </div>
                    </div>
                </div>
            </template>
        </draggable>
        <div v-if="newText" class="w-full mt-px">
            <rich-text-panel 
                fieldFormName="newTextDescription" fieldLabel="New Text Block" :fieldValue="newTextDescription"
                @fieldInput="updateNewText"
            />
            <div class="w-full mt-px pt-2 pb-4 px-2 white-transparent" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
                <div @click="clickCancelNewText()" class="alt-button-base mt-2">
                    Cancel
                </div>
                <div @click="clickSaveNewText()" class="standard-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                    Save
                </div>
            </div>
        </div>
        <div v-if="newImage" class="w-full mt-px">
            <media-panel 
                fieldLabel="New Media Block" :hideSpinner="true" :imagesArray="[]" :imagesOnly="false" :maxFiles="1" :multiple="false"
                @saveMedia="saveLocalMedia" @updateOfflineMedia="updateOfflineMedia"
            />
            <div class="w-full mt-px pt-2 pb-4 px-2 white-transparent" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
                <div @click="clickCancelNewImage()" class="alt-button-base mt-2">
                    Cancel
                </div>
                <div @click="clickSaveNewImage()" class="standard-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                    Save
                </div>
            </div>
        </div>
        <div v-if="newYouTube" class="w-full mt-px">
            <div class="text-gray-200 text-center font-semibold w-full pb-2 pt-3 white-transparent" style="font-size: 1rem; line-height: 1.5rem;">
                New YouTube Block
            </div>
            <input-panel 
                fieldFormName="newYouTubeUrl" fieldLabel="URL" :fieldValue="newYouTubeUrl"
                @fieldInput="updateNewYouTube"
            />
            <select-panel
                :fieldData="yesNoData" fieldFormName="newYouTubeAutoPlay" fieldLabel="Auto Play" :fieldValue="newYouTubeAutoPlay" :isLabelAndValue="true"
                @fieldChange="updateNewYouTube"
            />
            <select-panel
                :fieldData="yesNoData" fieldFormName="newYouTubeLoop" fieldLabel="Loop" :fieldValue="newYouTubeLoop" :isLabelAndValue="true"
                @fieldChange="updateNewYouTube"
            />
            <select-panel 
                :fieldData="orientationData" fieldFormName="newYouTubeOrientation" fieldLabel="Orientation" :fieldValue="newYouTubeOrientation" :isLabelAndValue="true"
                @fieldChange="updateNewYouTube"
            />
            <div class="w-full mt-px pt-2 pb-4 px-2 white-transparent" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
                <div @click="clickCancelNewYouTube()" class="alt-button-base mt-2">
                    Cancel
                </div>
                <div @click="clickSaveNewYouTube()" class="standard-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                    Save
                </div>
            </div>
        </div>
        <div v-if="!newText && !newImage &&!newYouTube && !editingIndex" class="w-full white-transparent mt-px pt-2 pb-4 px-2" :class="{ 'desktop-button-box justify-center flex-wrap': isDesktopWidth }">
            <div @click="clickNewImage()" class="alt-button-base mt-2">
                New Media Block
            </div>
            <div @click="clickNewText()" class="alt-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                New Text Block
            </div>
            <div @click="clickNewYouTube()" class="alt-button-base mt-2" :class="{ 'ml-2': isDesktopWidth }">
                New YouTube Block
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

import OfflineMediaCard from '@/shared/Cards/OfflineMediaCard'

export default {
    props: [
        'groupBuy',
        'pGroupBuyForm'
    ],
    components: {
        draggable,
        OfflineMediaCard
    },
    data() {
        return {
            editingGallery: null,
            editingIndex: null,
            editingTextDescription: null,
            editingYouTubeAutoPlay: false,
            editingYouTubeLoop: false,
            editingYouTubeOrientation: null,
            editingYouTubeUrl: null,
            formLoaded: false,
            groupBuyForm: {
                purchase_media_blocks: []
            },
            mediaBlocks: [],
            newImage: false,
            newGallery: null,
            newText: false,
            newTextDescription: null,
            newYouTube: false,
            newYouTubeAutoPlay: false,
            newYouTubeLoop: false,
            newYouTubeOrientation: null,
            newYouTubeUrl: null
        }
    },
    mounted() {
        this.groupBuyForm.purchase_media_blocks = this.pGroupBuyForm.purchase_media_blocks ?? []
        this.mediaBlocks = this.pGroupBuyForm.purchase_media_blocks ?? []
        this.formLoaded = true
    },
    methods: {
        clickCancelEditImage() {
            this.editingIndex = null
            this.editingGallery = null
        },
        clickCancelEditText() {
            this.editingIndex = null
            this.editingTextDescription = null
        },
        clickCancelEditYouTube() {
            this.editingIndex = null
            this.editingYouTubeAutoPlay = false
            this.editingYouTubeLoop = false
            this.editingYouTubeOrientation = null
            this.editingYouTubeUrl = null
        },
        clickCancelNewImage() {
            this.newGallery = null
            this.newImage = false
        },
        clickCancelNewText() {
            this.newTextDescription = null
            this.newText = false
        },
        clickCancelNewYouTube() {
            this.newYouTubeAutoPlay = null
            this.newYouTubeLoop = null
            this.newYouTubeOrientation = null
            this.newYouTubeUrl = null
            this.newYouTube = false
        },
        clickDeleteMediaBlock(inIndex, inType) {
            let theType = inType == 'image' ? 'Image' : 'Text'

            if (confirm(`You wish to delete this ${theType} block?`)) {
                this.groupBuyForm.purchase_media_blocks = this.groupBuyForm.purchase_media_blocks.filter((myBlock, index) => index != inIndex)
                this.$emit('updatePurchaseMediaBlocks', this.groupBuyForm, true)
                this.mediaBlocks = this.groupBuyForm.purchase_media_blocks
            }
        },
        clickEditImageBlock(inIndex) {
            let editBlock = this.groupBuyForm.purchase_media_blocks.find((myBlock, index) => index == inIndex && myBlock.type == 'image')

            if (editBlock) {
                this.editingIndex = inIndex
                this.editingGallery = [editBlock.image]
            }
        },
        clickEditTextBlock(inIndex) {
            let editBlock = this.groupBuyForm.purchase_media_blocks.find((myBlock, index) => index == inIndex && myBlock.type == 'text')

            if (editBlock) {
                this.editingIndex = inIndex
                this.editingTextDescription = editBlock.text
            }
        },
        clickEditYouTubeBlock(inIndex) {
            let editBlock = this.groupBuyForm.purchase_media_blocks.find((myBlock, index) => index == inIndex && myBlock.type == 'youtube')

            if (editBlock) {
                this.editingIndex = inIndex
                this.editingYouTubeAutoPlay = editBlock.autoplay ?? false
                this.editingYouTubeLoop = editBlock.loop ?? false
                this.editingYouTubeOrientation = editBlock.orientation ?? 'landscape'
                this.editingYouTubeUrl = editBlock.url
            }
        },
        clickNewImage() {
            this.newImage = true
        },
        clickNewText() {
            this.newText = true
        },
        clickNewYouTube() {
            this.newYouTube = true
        },
        async clickSaveEditImage() {
            let myTempGallery = await this.buildOfflineTempGallery(this.editingGallery, `groupBuyPMB_temp${this.groupBuy.id}`)
            
            setTimeout(() => {
                this.saveGalleryPrep(myTempGallery, 'groupBuyPMBGalleryUpdate', this.groupBuy.id, 'sellerGroupBuyPMBGallery')
            }, 100)

            let myBlocks = []
            
            this.groupBuyForm.purchase_media_blocks.forEach((myBlock, index) => {
                if (myBlock.type == 'image' && index == this.editingIndex) {
                    myBlock.image = this.editingGallery[0]  
                }
                
                myBlocks.push(myBlock)
            })

            this.groupBuyForm.purchase_media_blocks = myBlocks
            this.$emit('updateMediaBlocks', this.groupBuyForm, true)
            this.editingGallery = null
            this.editingIndex = null
            this.mediaBlocks = this.groupBuyForm.purchase_media_blocks
        },
        clickSaveEditText() {
            let myBlocks = []

            this.groupBuyForm.purchase_media_blocks.forEach((myBlock, index) => {
                if (myBlock.type == 'text' && index == this.editingIndex) {
                    myBlock.text = this.editingTextDescription    
                }
                
                myBlocks.push(myBlock)
            })

            this.groupBuyForm.purchase_media_blocks = myBlocks
            this.$emit('updatePurchaseMediaBlocks', this.groupBuyForm, true)
            this.editingTextDescription = null
            this.editingIndex = null
            this.mediaBlocks = this.groupBuyForm.purchase_media_blocks
        },
        clickSaveEditYouTube() {
            let myBlocks = []

            this.groupBuyForm.purchase_media_blocks.forEach((myBlock, index) => {
                if (myBlock.type == 'youtube' && index == this.editingIndex) {
                    myBlock.autoplay = this.editingYouTubeAutoPlay
                    myBlock.loop = this.editingYouTubeLoop
                    myBlock.orientation = this.editingYouTubeOrientation
                    myBlock.url = this.editingYouTubeUrl
                }
                
                myBlocks.push(myBlock)
            })

            this.groupBuyForm.purchase_media_blocks = myBlocks
            this.$emit('updatePurchaseMediaBlocks', this.groupBuyForm, true)
            this.editingYouTubeAutoPlay = false
            this.editingYouTubeLoop = false
            this.editingYouTubeOrientation = null
            this.editingYouTubeUrl = null
            this.editingIndex = null
            this.mediaBlocks = this.groupBuyForm.purchase_media_blocks
        },
        async clickSaveNewImage() {
            let myImageBlock = {
                type: 'image',
                image: this.newGallery[0]
            }

            this.gallery = this.newGallery
            let myTempGallery = await this.buildOfflineTempGallery(this.newGallery, `groupBuyPMB_temp${this.groupBuy.id}`)
            
            setTimeout(() => {
                this.saveGalleryPrep(myTempGallery, 'groupBuyPMBGalleryUpdate', this.groupBuy.id, 'sellerGroupBuyPMBGallery')
            }, 100)
            
            this.groupBuyForm.purchase_media_blocks.push(myImageBlock)
            this.$emit('updatePurchaseMediaBlocks', this.groupBuyForm, true)
            this.newGallery = null
            this.newImage = false
            this.mediaBlocks = this.groupBuyForm.purchase_media_blocks
        },
        clickSaveNewText() {
            let myTextBlock = {
                type: 'text',
                text: this.newTextDescription
            }
            
            this.groupBuyForm.purchase_media_blocks.push(myTextBlock)
            this.$emit('updatePurchaseMediaBlocks', this.groupBuyForm, true)
            this.newTextDescription = null
            this.newText = false
            this.mediaBlocks = this.groupBuyForm.purchase_media_blocks
        },
        clickSaveNewYouTube() {
            let myYouTubeBlock = {
                type: 'youtube',
                autoplay: this.newYouTubeAutoPlay,
                loop: this.newYouTubeLoop,
                orientation: this.newYouTubeOrientation,
                url: this.newYouTubeUrl
            }
            
            this.groupBuyForm.purchase_media_blocks.push(myYouTubeBlock)
            this.$emit('updatePurchaseMediaBlocks', this.groupBuyForm, true)
            this.newYouTubeAutoPlay = false
            this.newYouTubeLoop = false
            this.newYouTubeOrientation = null
            this.newYouTubeUrl = null
            this.newYouTube = false
            this.mediaBlocks = this.groupBuyForm.purchase_media_blocks
        },
        deleteImage() {

        },
        saveLocalMedia(inGallery) {
            this.newGallery = inGallery
        },
        saveLocalMediaEdit(inGallery) {
            this.editingGallery = inGallery
        },
        saveOrder() {
            this.groupBuyForm.purchase_media_blocks = this.mediaBlocks
            this.$emit('updatePurchaseMediaBlocks', this.groupBuyForm, true)
        },
        updateEditingText(inField, inValue) {
            this.editingTextDescription = inValue
        },
        updateEditingYouTube(inField, inValue) {
            if (inField == "editingYouTubeUrl") {
                this.editingYouTubeUrl = inValue
            }
            else if (inField == "editingYouTubeAutoPlay") {
                this.editingYouTubeAutoPlay = inValue
            }
            else if (inField == "editingYouTubeLoop") {
                this.editingYouTubeLoop = inValue
            }
            else if (inField == "editingYouTubeOrientation") {
                this.editingYouTubeOrientation = inValue
            }
        },
        updateElement(inImage, inIndex) {
            let myBlocks = []

            this.groupBuyForm.purchase_media_blocks.forEach((myBlock) => {
                if (myBlock.type == 'image' && myBlock.image.identifier == inImage.identifier) {
                    myBlock.image = inImage    
                }
                
                myBlocks.push(myBlock)
            })

            this.groupBuyForm.purchase_media_blocks = myBlocks
            this.mediaBlocks = this.groupBuyForm.purchase_media_blocks
        },
        updateNewText(inField, inValue) {
            this.newTextDescription = inValue
        },
        updateNewYouTube(inField, inValue) {
            if (inField == "newYouTubeUrl") {
                this.newYouTubeUrl = inValue
            }
            else if (inField == "newYouTubeAutoPlay") {
                this.newYouTubeAutoPlay = inValue
            }
            else if (inField == "newYouTubeLoop") {
                this.newYouTubeLoop = inValue
            }
            else if (inField == "newYouTubeOrientation") {
                this.newYouTubeOrientation = inValue
            }
        },
        updateOfflineMedia(inGallery) {
            this.newGallery = inGallery
        },
        updateOfflineMediaEdit(inGallery) {
            this.editingGallery = inGallery
        }
    }
}
</script>