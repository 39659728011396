const editableContentMixins = {
    methods: {
        addBR(inNode) {
            inNode.focus()

            if (this.cursorPosition != 0) {
                var myNewPos = this.getNewCaretPosition(inNode, this.cursorPosition)
                this.setCaretPositionNode(myNewPos)
            }
            else {
                this.setCaretPositionEnd(inNode)
            }

            var myTextNode = this.insertBRAtCursor()
            //inNode.innerHTML = inNode.innerHTML.replace("||BR||", "<br class='br'>")

            if (this.cursorPosition != 0) {
                var myNewPos = this.getNewCaretPosition(inNode, this.cursorPosition)
                this.setCaretPositionNode(myNewPos)
            }
            else {
                this.setCaretPositionEnd(inNode)
            }

            inNode.focus()

            console.log(myTextNode)

            var selection = window.getSelection()
            selection.removeAllRanges()
            var myRange = document.createRange()
            myRange.selectNodeContents(myTextNode)
            selection.addRange(myRange)

            if (myTextNode.nextSibling) {
                myRange.deleteContents()
            }

            this.setStateData([
                { cursorPosition: this.getCaretPosition(inNode) }
            ])
        },
        addSpace(inNode) {
            inNode.focus()

            console.log(this.cursorPosition)

            if (this.cursorPosition != 0) {
                var myNewPos = this.getNewCaretPosition(inNode, this.cursorPosition)
                this.setCaretPositionNode(myNewPos)
            }
            else {
                this.setCaretPositionEnd(inNode)
            }

            this.insertTextAtCursor(' ')

            if (this.cursorPosition != 0) {
                var myNewPos = this.getNewCaretPosition(inNode, this.cursorPosition)
                this.setCaretPositionNode(myNewPos)
            }
            else {
                this.setCaretPositionEnd(inNode)
            }

            inNode.focus()

            this.setStateData([
                { cursorPosition: this.getCaretPosition(inNode) }
            ])
        },
        deleteLinebreaks(inNode) {
            var children = Array.from(inNode.childNodes)

            children.forEach(child => {
                if (children.indexOf(child) == children.length - 1) {
                    if (child.tagName && child.tagName === "BR" && child.className != "br") {
                        inNode.removeChild(child)
                    }
                }
            })
        },
        getCaretPosition(inElement) {
            var caretOffset = 0

            console.log(typeof window.getSelection())

            if (typeof window.getSelection() !== "undefined") {
                var range = window.getSelection().getRangeAt(0)
                var selected = range.toString().length
                var preCaretRange = range.cloneRange()

                preCaretRange.selectNodeContents(inElement)
                preCaretRange.setEnd(range.endContainer, range.endOffset)
                caretOffset = preCaretRange.toString().length - selected

                var myNode
                var nodesLength = 0
                var walk = document.createTreeWalker(inElement, NodeFilter.SHOW_TEXT, null, false)

                while (myNode = walk.nextNode())
                    nodesLength = nodesLength + myNode.nodeValue.length

                return nodesLength - caretOffset
            }

            return 0
        },
        getNewCaretPosition(inElement, inPosition) {
            var node
            var nodes = []
            var myNode
            var walk = document.createTreeWalker(inElement, NodeFilter.SHOW_TEXT, null, false);

            while (myNode = walk.nextNode()) nodes.unshift(myNode);

            for (var n = 0; n < nodes.length; n++) {
                if (inPosition > nodes[n].nodeValue.length && nodes[n + 1]) {
                    inPosition -= nodes[n].nodeValue.length
                } else {
                    node = nodes[n]
                    inPosition = nodes[n].nodeValue.length - inPosition
                    break
                }
            }

            return { node: node, position: inPosition }
        },
        insertBRAtCursor() {
            var sel, range
            var br = document.createElement("br")
            var textNode = document.createTextNode(" ")
            br.className = "br"

            if (window.getSelection()) {
                sel = window.getSelection()
                range = sel.getRangeAt(0)

                range.deleteContents()
                range.insertNode(br)
                range.collapse(false)
                range.insertNode(textNode)
                sel.removeAllRanges()

                return textNode
            }
        },
        insertTextAtCursor(inText) {
            var sel, range

            if (window.getSelection()) {
                sel = window.getSelection()

                if (sel.getRangeAt && sel.rangeCount) {
                    range = sel.getRangeAt(0)
                    range.deleteContents()
                    range.insertNode(document.createTextNode(inText))
                }
            } else if (document.selection && document.selection.createRange) {
                document.selection.createRange().text = inText
            }
        },
        pasteClipboard(event) {
            let paste = (event.clipboardData || window.clipboardData).getData('text')
            this.insertTextAtCursor(paste)
            event.preventDefault()
        },
        setCaretPositionEnd(inElement) {
            inElement.focus()

            if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
                var range = document.createRange()
                range.selectNodeContents(inElement)
                range.collapse(false)
                var sel = window.getSelection()
                sel.removeAllRanges()
                sel.addRange(range)
            } else if (typeof document.body.createTextRange != "undefined") {
                var textRange = document.body.createTextRange()
                textRange.moveToElementText(inElement)
                textRange.collapse(false)
                textRange.select()
            }
        },
        setCaretPositionNode(inData) {
            var sel = window.getSelection()
            var range = document.createRange()

            range.setStart(inData.node, inData.position)
            range.collapse(true)
            sel.removeAllRanges()
            sel.addRange(range)
        },
        sleep(inTime) {
            return new Promise(resolve => setTimeout(resolve, inTime))
        },
    }
}

export default editableContentMixins