<template>
    <div class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="chargeTax" fieldLabel="Do You Charge Tax?" fieldLabelClasses="text-gray-800" :fieldRequired="true"
            :fieldValue="sellerDataForm.chargeTax" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="changeChargeTax"
        >
            <validation-message v-if="validating && sellerDataForm.chargeTax == null" 
                message="Select yes or no"
            />
        </select-panel>
        <div v-if="sellerDataForm.chargeTax" class="w-full mt-2">
            <input-panel 
                fieldFormName="taxLabel" fieldLabel="Tax Label" fieldLabelClasses="text-gray-800" fieldPlaceholder="Tax Label" :fieldRequired="true" fieldType="text" 
                :fieldValue="sellerDataForm.taxLabel" :forceMobile="true"
                @fieldInput="updateSellerDataFormValue"
            >
                <validation-message v-if="validating && !sellerDataForm.taxLabel" 
                    message="Tax Label must be entered"
                />
            </input-panel>
            <div class="w-full relative leading-6 mt-3">
                <div class="w-full text-center font-semibold mb-1">
                    Tax Rates
                </div>
                <div v-if="!addingTaxRate && !editingTaxRate" class="absolute top-2 left-1 text-xs text-gray-800">
                    Default
                </div>
                <div v-if="!addingTaxRate && !editingTaxRate" @click="clickNewTaxRate()" class="absolute top-1 right-2 font-normal cursor-pointer text-gray-600">
                    <fa-icon icon="plus" type="fas" class="h-5 w-5" />
                </div>
            </div>
            <div v-if="addingTaxRate" class="mt-px bg-orange-200 flex items-center justify-between">
                <input-panel 
                    fieldFormName="label" fieldPlaceholder="Description" :fieldRequired="true" fieldType="text" :fieldValue="newTaxRateForm.label" :forceMobile="true" :hideLabel="true"
                    @fieldInput="updateNewTaxRateFormValue" 
                >
                </input-panel>
                <div class="flex items-center justify-end">
                    <input-panel 
                        fieldFormName="percentage" fieldPlaceholder="%" :fieldRequired="true" fieldType="number" :fieldValue="newTaxRateForm.percentage" :forceMobile="true" :hideLabel="true"
                        @fieldInput="updateNewTaxRateFormValue" 
                    >
                    </input-panel>
                    <div class="flex items-center justify-center text-gray-600">
                        <div @click="clickSaveNewTaxRate()" class="cursor-pointer">
                            <fa-icon icon="floppy-disk" type="fas" class="h-5 w-5" />
                        </div>
                        <div @click="clickCancelNewTaxRate()" class="cursor-pointer mx-2">
                            <fa-icon icon="ban" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-for="rate in sortedTaxRates" class="mt-px bg-orange-200">
                <div v-if="!editingTaxRate || editingTaxRate != rate.id" class="flex items-center justify-between py-1 pr-1 pl-2">
                    <div class="flex items-center justify-between w-full">
                        <div class="flex items-center justify-start pl-2 w-full">
                            <input @change="changeDefaultTaxRate(rate.id)" :checked="defaultIsChecked(rate.id)" :id="`defaultRate${rate.id}`" type="checkbox" class="w-4 h-4 text-orange-500 border-gray-300 rounded edit-field-text-color" :class="{ 'background-orange-500': defaultIsChecked(rate.id) }">
                            <div @click="clickEditTaxRate(rate)" class="mr-2 ml-4 w-full text-left">
                                {{ rate.label }}
                            </div>
                        </div>
                        <div v-if="rate.percentage" @click="clickEditTaxRate(rate)" class="mr-2 whitespace-nowrap">
                            {{ rate.percentage }}%
                        </div>
                    </div>
                    <div v-if="!editingTaxRate && !addingTaxRate" class="flex items-center justify-end text-gray-600">
                        <div v-if="rate.id != 1" @click="clickEditTaxRate(rate)" class="cursor-pointer mr-2">
                            <fa-icon icon="pen-to-square" type="fas" class="h-5 w-5" />
                        </div>
                        <div v-if="rate.id != 1" @click="clickDeleteTaxRate(rate.id)" class="cursor-pointer mr-1">
                            <fa-icon icon="trash-can" type="fas" class="h-5 w-5" />
                        </div>
                    </div>
                </div>
                <div v-if="editingTaxRate && editingTaxRate == rate.id" class="flex items-center justify-between">
                    <input-panel 
                        fieldFormName="label" fieldPlaceholder="Description" :fieldRequired="true" fieldType="text" :fieldValue="editTaxRateForm.label" :forceMobile="true" :hideLabel="true"
                        @fieldInput="updateEditTaxRateFormValue" 
                    >
                    </input-panel>
                    <div class="flex items-center justify-end">
                        <input-panel 
                            fieldFormName="percentage" fieldPlaceholder="%" :fieldRequired="true" fieldType="number" :fieldValue="editTaxRateForm.percentage" :forceMobile="true" :hideLabel="true"
                            @fieldInput="updateEditTaxRateFormValue" 
                        >
                        </input-panel>
                        <div class="flex items-center justify-center text-gray-600">
                            <div @click="clickSaveEditTaxRate()" class="cursor-pointer">
                                <fa-icon icon="floppy-disk" type="fas" class="h-5 w-5" />
                            </div>
                            <div @click="clickCancelEditTaxRate()" class="cursor-pointer mx-2">
                                <fa-icon icon="ban" type="fas" class="h-5 w-5" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <validation-message v-if="validating && sellerDataForm.chargeTax && sellerDataForm.taxRates && sellerDataForm.taxRates.length < 2" 
            message="You must add at least one Tax Rate"
        />
    </div>
</template>

<script>
export default {
    props: [
        'sellerDataForm',
        'validating'
    ],
    data() {
        return {
            addingTaxRate: false,
            editingTaxRate: false,
            editTaxRateForm: {
                id: null,
                label: null,
                percentage: null
            },
            newTaxRateForm: {
                id: null,
                label: null,
                percentage: null
            }
        }
    },
    computed: {
        sortedTaxRates() {
            return !this.sellerDataForm.taxRates ? [] : this.sellerDataForm.taxRates
                .sort((a, b) => a.label < b.label ? -1 : 1)
        }
    },
    methods: {
        changeChargeTax(inField, inValue) {
            this.$emit('changeChargeTax', inField, inValue)
        },
        changeDefaultTaxRate(inTaxRateId) {
            this.updateSellerDataFormValue('defaultTaxRate', inTaxRateId)
        },
        clickCancelEditTaxRate() {
            this.editTaxRateForm.label = null
            this.editTaxRateForm.percentage = null
            this.editTaxRateForm.id = null
            this.editingTaxRate = false
        },
        clickCancelNewTaxRate() {
            this.newTaxRateForm.label = null
            this.newTaxRateForm.percentage = null
            this.newTaxRateForm.id = null
            this.addingTaxRate = false
        },
        clickDeleteTaxRate(inRateId) {
            if (confirm('You wish to delete this tax rate?')) {
                let myTaxRates = []
                let myDefaultTaxRate = this.sellerDataForm.defaultTaxRate

                this.sellerDataForm.taxRates.forEach((taxRate) => {
                    if (taxRate.id != inRateId) {
                        myTaxRates.push(taxRate)
                    }
                })

                if (inRateId == this.sellerDataForm.defaultTaxRate) {
                    myDefaultTaxRate = null
                }

                this.$emit('updateTaxRates', myTaxRates, myDefaultTaxRate)
            }
        },
        clickEditTaxRate(inRate) {
            if (inRate.id != 1) {
                this.editTaxRateForm.label = inRate.label
                this.editTaxRateForm.percentage = inRate.percentage
                this.editTaxRateForm.id = inRate.id
                this.editingTaxRate = inRate.id
            }
        },
        clickNewTaxRate() {
            this.newTaxRateForm.label = null
            this.newTaxRateForm.percentage = null
            this.newTaxRateForm.id = null
            this.addingTaxRate = true
        },
        clickSaveEditTaxRate() {
            let myTaxRates = this.objCopy(this.sellerDataForm.taxRates).filter((taxRate) => taxRate.id != this.editingTaxRate)
            myTaxRates.push(this.objCopy(this.editTaxRateForm))
            this.$emit('updateTaxRates', myTaxRates, this.sellerDataForm.defaultTaxRate)
            this.editTaxRateForm.label = null
            this.editTaxRateForm.percentage = null
            this.editTaxRateForm.id = null
            this.editingTaxRate = false
        },
        clickSaveNewTaxRate() {
            let myTaxRates = this.objCopy(this.sellerDataForm.taxRates)
            this.sellerDataForm.lastTaxRateId = this.sellerDataForm.lastTaxRateId + 1 ?? 2
            this.newTaxRateForm.id = this.sellerDataForm.lastTaxRateId
            myTaxRates.push(this.objCopy(this.newTaxRateForm))
            this.$emit('updateTaxRates', myTaxRates, this.sellerDataForm.defaultTaxRate)
            this.newTaxRateForm.label = null
            this.newTaxRateForm.percentage = null
            this.newTaxRateForm.id = null
            this.addingTaxRate = false
        },
        defaultIsChecked(inTaxRateId) {
            return this.sellerDataForm.defaultTaxRate == inTaxRateId
        },
        updateEditTaxRateFormValue(inField, inValue) {
            this.editTaxRateForm[inField] = inValue
        },
        updateNewTaxRateFormValue(inField, inValue) {
            this.newTaxRateForm[inField] = inValue
        },
        updateSellerDataFormValue(inField, inValue) {
            this.$emit('updateSellerDataFormValue', inField, inValue)
        }
    }
}
</script>