const tabMixins = {
    methods: {
        checkTabScrollTop() {
            let showIcons = this.scrollTop < 80 || this.tabScrollTop > this.scrollTop
            this.$emit('setTabScrollTop', this.tabLabel, this.scrollTop)

            this.setStateData([
                { showGlobalIcons: showIcons }
            ])
        },
        scrollTab(inTabScrollTop) {
            setTimeout(() => {
                window.scrollTo({
                    top: inTabScrollTop,
                    left: 0,
                    behavior: 'instant'
                })
            }, this.tabScrollTimeout)
        }
    }
}

export default tabMixins