<template>
    <div v-if="formLoaded" class="w-full p-2 white-transparent">
        <div class="w-full">
            <panel-sub-section
                sectionLabel="Select Vendor Product Add Ons" :sectionOpen="showVendorAddOns"
                @cacheSectionOpen="clickShowVendorVariants"
            >
                <div v-for="vendorAddOn in sellerVendor.product_add_ons">
                    <select-panel
                        :fieldData="yesNoData" :fieldFormName="vendorAddOn.identifier" :fieldLabel="vendorAddOn.label"
                        :fieldValue="isVendorAddOnSelected(vendorAddOn.identifier)" :isLabelAndValue="true"
                        @fieldChange="updateAddOnSelected"
                    />
                </div>
            </panel-sub-section>
        </div>
        <template v-for="(vendorAddOn, index) in sellerVendor.product_add_ons" :key="index">
            <div v-if="isVendorAddOnSelected(vendorAddOn.identifier)" class="mt-2">
                <panel-sub-section
                    :sectionIdentifier="vendorAddOn.identifier" :sectionLabel="`${vendorAddOn.label} Values`" :sectionOpen="showAddOnValues.includes(vendorAddOn.identifier)"
                    @cacheSectionOpen="clickShowAddOnValues"
                >
                    <div class="w-full p-2">
                        <draggable
                            animation="250" easing="cubic-bezier(1, 0, 0, 1)" handle=".handle-value" item-key="index" :list="getRealAddOnValues(vendorAddOn.identifier)" tag="div" 
                            @change="saveOrder()"
                        >
                            <template #item="{element, index}">
                                <div class="relative">
                                    <panel-sub-section
                                        :sectionIdentifier="`${vendorAddOn.identifier}|${element.identifier}`" :sectionLabel="element.label" :sectionOpen="isValueDetailOpen(vendorAddOn.identifier, element.identifier)"
                                        @cacheSectionOpen="clickShowValueDetail"
                                    >
                                        <edit-add-on-value
                                            :addOnValue="element" :vendorAddOn="vendorAddOn"
                                            @updateAddOnValue="updateAddOnValue"
                                        />
                                    </panel-sub-section>
                                    <div class="absolute left-0 top-0 cursor-move handle-value p-2">
                                        <fa-icon icon="up-down-left-right" type="fas" class="h-5 w-5" />
                                    </div>
                                </div>
                            </template>
                        </draggable>
                        <div class="w-full orange-transparent" :class="{ 'mt-2': getRealAddOnValues(vendorAddOn.identifier).length }">
                            <panel-sub-section
                                :sectionIdentifier="vendorAddOn.identifier" :sectionLabel="`Create A New ${vendorAddOn.label}`" :sectionOpen="showNewAddOnValue.includes(vendorAddOn.identifier)"
                                @cacheSectionOpen="clickShowNewAddOnValue"
                            >
                                <new-add-on-value
                                    :addOnValues="getRealAddOnValues(vendorAddOn.identifier)" :vendorAddOn="vendorAddOn"
                                    @clickCreateNewAddOnValue="clickCreateNewAddOnValue"
                                />
                            </panel-sub-section>
                        </div>    
                    </div>
                </panel-sub-section>
            </div>
        </template>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

import EditAddOnValue from './EditAddOnValue'
import NewAddOnValue from './NewAddOnValue'

export default {
    props: [
        'product',
        'productAddOns'
    ],
    components: {
        draggable,
        EditAddOnValue,
        NewAddOnValue
    },
    data() {
        return {
            addOnForm: {
                vendorAddOns: null,
                addOnValues: null
            },
            formLoaded: false,
            showNewAddOnValue: [],
            showValueDetail: [],
            showAddOnValues: [],
            showVendorAddOns: false
        }
    },
    mounted() {
        let myVendorAddOns = []

        this.sellerVendor.product_add_ons.forEach((mySellerAddOn) => {
            let mySelected = false

            if (this.productAddOns && this.productAddOns.vendorAddOns) {
                let myAddOn = this.productAddOns.vendorAddOns.find((myAddOn) => myAddOn.identifier == mySellerAddOn.identifier)
                mySelected = myAddOn?.selected ?? false
            }

            let myNewAddOn = {
                identifier: mySellerAddOn.identifier,
                label: mySellerAddOn.label,
                selected: mySelected
            }

            myVendorAddOns.push(myNewAddOn)
        })

        this.addOnForm.vendorAddOns = myVendorAddOns

        if (this.productAddOns && this.productAddOns.addOnValues) {
            this.addOnForm.addOnValues = Object.values(this.objCopy(this.productAddOns.addOnValues))
        }

        this.formLoaded = true
    },
    methods: {
        clickCreateNewAddOnValue(inForm, inAddOnIdentifier) {
            let myAddOnValues = this.addOnForm.addOnValues ?? []
            let myAddOnValue = myAddOnValues.find((myValue) => myValue.identifier == inAddOnIdentifier)
            let myValues = myAddOnValue?.values ?? []

            myValues.push(inForm)

            if (myAddOnValue) {
                myAddOnValue.values = myValues
            }
            else {
                myAddOnValue = {
                    identifier: inAddOnIdentifier,
                    values: myValues
                }
            }

            myAddOnValues = myAddOnValues.filter((myValue) => myValue.identifier != inAddOnIdentifier)
            myAddOnValues.push(myAddOnValue)
            this.addOnForm.addOnValues = myAddOnValues

            if (this.areVendorVariantsOkay) {
                this.buildVariants(inAddOnIdentifier, inForm.identifier)
            }
            else {
                this.$emit('updateAddOns', this.addOnForm, true)
            }
        },
        clickShowNewAddOnValue(inIsOpen, inIdentifier) {
            if (inIsOpen) {
                if (!this.showNewAddOnValue.includes(inIdentifier)) {
                    this.showNewAddOnValue.push(inIdentifier)
                }
            }
            else {
                this.showNewAddOnValue = this.showNewAddOnValue.filter((myValue) => myValue != inIdentifier)
            }
        },
        clickShowValueDetail(inIsOpen, inIdentifier) {
            if (inIsOpen) {
                if (!this.showValueDetail.includes(inIdentifier)) {
                    this.showValueDetail.push(inIdentifier)
                }
            }
            else {
                this.showValueDetail = this.showValueDetail.filter((myValue) => myValue != inIdentifier)
            }
        },
        clickShowAddOnValues(inIsOpen, inIdentifier) {
            if (inIsOpen) {
                if (!this.showAddOnValues.includes(inIdentifier)) {
                    this.showAddOnValues.push(inIdentifier)
                }
            }
            else {
                this.showAddOnValues = this.showAddOnValues.filter((myValue) => myValue != inIdentifier)
            }
        },
        clickShowVendorVariants(inIsOpen) {
            this.showVendorAddOns = inIsOpen
        },
        getRealAddOnValues(inIdentifier) {
            if (this.addOnForm.addOnValues) {
                let myAddOnValues = this.addOnForm.addOnValues.find((myValues) => myValues.identifier == inIdentifier)

                if (myAddOnValues) {
                    return this.addOnForm.addOnValues.find((myValues) => myValues.identifier == inIdentifier).values
                }
            }

            return []
        },
        isValueDetailOpen(inAddOnIdentifier, inValueIdentifier) {
            let myIdentifier = `${inAddOnIdentifier}|${inValueIdentifier}`
            return this.showValueDetail.includes(myIdentifier)
        },
        isVendorAddOnSelected(inIdentifier) {
            let myVendorVariant = this.addOnForm.vendorAddOns.find((myAddOn) => myAddOn.identifier == inIdentifier)
            return myVendorVariant.selected ?? false
        },
        saveOrder() {
            this.$emit('updateAddOns', this.addOnForm, true)
        },
        updateAddOnSelected(inField, inValue) {
            let myVendorVariant = this.addOnForm.vendorAddOns.find((myAddOn) => myAddOn.identifier == inField)
            myVendorVariant.selected = inValue
            this.$emit('updateAddOns', this.addOnForm, true)
        },
        updateAddOnValue(inForm, inAddOnIdentifier, inValueIdentifier, inSaveImmediate) {
            let myAddOnValues = this.addOnForm.addOnValues ?? []
            let myAddOnValue = myAddOnValues.find((myValue) => myValue.identifier == inAddOnIdentifier)
            let myValues = myAddOnValue?.values ?? []

            myValues.find((myValue) => myValue.identifier == inValueIdentifier).label = inForm.label
            myValues.find((myValue) => myValue.identifier == inValueIdentifier).enabled = inForm.enabled
            myValues.find((myValue) => myValue.identifier == inValueIdentifier).priceVariance = inForm.priceVariance
            this.addOnForm.addOnValues.find((myValue) => myValue.identifier == inAddOnIdentifier).values = myValues
            this.$emit('updateAddOns', this.addOnForm, inSaveImmediate)
        }
    }
}
</script>