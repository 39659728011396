const commentsMixins = {
    methods: {
        deleteComment(inComment) {
            if(!this.streamClient?.reactions) return;
            this.streamClient.reactions.delete(inComment.id)
                .then((response) => {
                    let myReactions = this.objCopy(this?.reactions)

                    myReactions = Object.values(myReactions).map((reaction) => {
                        if (reaction.id == inComment.parent) {
                            let myComments = reaction.comments
                            myComments = myComments.filter(comment => comment.id != inComment.id)
                            reaction.comments = myComments
                        }

                        return reaction
                    })

                    this.replaceStateData([
                        { reactions: myReactions }
                    ])
                })
                .catch((error) => {
                    this.pageMessage('error', 'Error deleting reply')
                })
        },
        async newComment(inText, inReaction) {
            this.setStateData([
                { postingComment: true }
            ])

            //if(!this.streamClient?.reactions) return;
            let promise = new Promise((resolve, reject) => {
                this.streamClient.reactions.addChild("comment", inReaction, { text: inText })
                    .then((response) => {
                        let myReactions = this.objCopy(this?.reactions)

                        myReactions = Object.values(myReactions).map((reaction) => {
                            if (reaction.id == inReaction.id) {
                                if (!reaction.comments) {
                                    reaction.comments = {}
                                }

                                let myComments = this.objCopy(reaction.comments)
                                myComments = { ...myComments, response }
                                reaction.comments = myComments

                                if (reaction.own_children) {
                                    if (reaction.own_children.comment) {
                                        reaction.own_children.comment.push(response)
                                    }
                                    else {
                                        reaction.own_children = { ...reaction.own_children, comment: [response] }
                                    }
                                }
                                else {
                                    reaction.own_children = { comment: [response] }
                                }

                                if (reaction.children_counts.comment) {
                                    ++reaction.children_counts.comment
                                }
                                else {
                                    reaction.children_counts = { ...reaction.children_counts, comment: 1 }
                                }
                            }

                            return reaction
                        })

                        this.replaceStateData([
                            { reactions: myReactions }
                        ])

                        this.updateUTCTime()
                        
                        this.setStateData([
                            { postingComment: false }
                        ])

                        resolve(response)
                    })
                    .catch((error) => {
                        this.pageMessage('error', 'Error creating reply')

                        this.setStateData([
                            { postingComment: false }
                        ])

                        reject(false)
                    })
                })
                
                var myReturn = false

                await promise
                    .then((result) => {
                        myReturn = result
                    })
                    .catch((e) => {

                    })
            
                return myReturn
        },
        async updateComment(inText, inComment) {
            this.setStateData([
                { postingComment: true }
            ])

            if(!this.streamClient?.reactions) return;

            let promise = new Promise((resolve, reject) => {
                this.streamClient.reactions.update(inComment.id, { "text": inText })
                    .then((response) => {
                        let myReactions = this.objCopy(this?.reactions)

                        myReactions = Object.values(myReactions).map((reaction) => {
                            if (reaction.id == inComment.parent) {
                                if (!reaction.comments) {
                                    reaction.comments = []
                                }

                                let myComments = this.objCopy(reaction.comments)
                                
                                myComments = myComments.filter(comment => comment.id != inComment.id)
                                myComments.push(response)
                                reaction.comments = myComments    
                            }

                            return reaction
                        })

                        this.setStateData([
                            { postingComment: false }
                        ])

                        this.replaceStateData([
                            { reactions: myReactions }
                        ])

                        resolve(response)
                    })
                    .catch((error) => {
                        this.pageMessage('error', 'Error updating reply')

                        this.setStateData([
                            { postingComment: false }
                        ])
                        
                        reject(false)
                    })
                })

                var myReturn = false

                await promise
                    .then((result) => {
                        myReturn = result
                    })
                    .catch((e) => {

                    })

                return myReturn
        }
    }
}

export default commentsMixins