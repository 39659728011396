<template>
    <div class="relative overflow-hidden page-standard-height-width">
        <div class="fixed top-0 left-0 h-full w-full">
            <div class="mx-auto max-main-width h-full">
                <cached-image imageClasses="object-cover h-full w-full" :imageSrc="transformUrl(defaultHero, ['optimize', 'md', 'q80'])" />
            </div>
        </div>
        <div class="fixed top-0 left-0 h-full w-full black-blur-3">
        </div>
        <div class="fixed w-full h-full">
            <div class="mx-auto max-main-width h-full py-16">
                <div class="flex px-1 mb-1">
                    <div class="w-full h-10 mr-1 flasher-light-bg flasher-light-animate rounded-md">
                    </div>
                    <div class="flasher-button-base h-10">
                        <fa-icon icon="magnifying-glass" type="fas" class="h-7 w-7" />
                    </div>
                </div>
                <text-lines 
                    :count="1" classes="w-1/2 flasher-light-bg flasher-light-animate h-4 mx-auto rounded-full mb-5" 
                />
                <tabs 
                    :count="5" 
                />
                <posts 
                    :count="12" 
                />
            </div>
        </div>
    </div>
</template>

<script>
import Posts from '@/flashers/components/Posts'
import Tabs from '@/flashers/components/Tabs'
import TextLines from '@/flashers/components/TextLines'

export default {
    components: {
        Posts,
        Tabs,
        TextLines
    }
}
</script>