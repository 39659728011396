const wizardMixins = {
    methods: {
        closeNewSellerWizard() {
            this.setStateData([
                { showNewSellerWizard: false }
            ])
        },
        openNewSellerWizard() {
            this.setStateData([
                { showNewSellerWizard: true }
            ])
        }
    }
}


export default wizardMixins