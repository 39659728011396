<template>
    <label class="text-sm font-bold leading-5">
        {{ fieldLabel }} <span v-if="subLabel" class="text-xs">{{ subLabel }}</span>
    </label>
</template>

<script>
export default {
    props: [
        'fieldLabel', 
        'fieldRequired',
        'fieldSubLabel'
    ],
    computed: {
        subLabel() {
            let myText = null

            if (this.fieldRequired && this.fieldSubLabel) {
                myText = `(${this.fieldSubLabel}, required)`
            }
            else if (this.fieldRequired) {
                myText = "(required)"
            }
            else if (this.fieldSubLabel) {
                myText = `(${this.fieldSubLabel})`
            }

            return myText
        }
    }
}
</script>