const cacheMixins = {
    methods: {
        updateCache(inCache, inKey, inValue, inPage) {
            let myLocalPageCache = this.objCopy(inCache)
            myLocalPageCache[inKey] = inValue

            let myPageCache = this.objCopy(this.pageCache)
            myPageCache[inPage] = this.objCopy(myLocalPageCache)

            this.replaceStateData([
                { pageCache: myPageCache }
            ])

            return this.objCopy(myLocalPageCache)
        }
    }
}

export default cacheMixins