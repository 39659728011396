<template>
    <input-panel 
        fieldFormName="email" fieldLabel="Seller Email" fieldLabelClasses="text-gray-800" fieldPlaceholder="Seller Email" :fieldRequired="true" fieldType="text" :fieldValue="sellerForm.email" 
        :forceMobile="true"
        @fieldInput="updateSellerFormValue"
    >
        <validation-message v-if="validating && !sellerForm.email" 
            message="Seller Email must be entered"
        />
        <validation-message v-if="validating && sellerForm.email && !isValidEmailAddress(sellerForm.email)" 
            message="Seller Email is not a valid email address format"
        />
    </input-panel>
</template>

<script>
export default {
    props: [
        'sellerForm',
        'validating'
    ],
    methods: {
        updateSellerFormValue(inField, inValue) {
            this.$emit('updateSellerFormValue', inField, inValue)
        }
    }
}
</script>