<template>
    <div class="w-full">
        <input-panel 
            fieldFormName="name" fieldLabel="Title" fieldPlaceholder="Title" :fieldRequired="true" 
            fieldType="text" :fieldValue="name"
            @fieldInput="updateTitle"
        />
        <div class="w-full white-transparent mt-px py-4 px-2" :class="{ 'desktop-button-box justify-center': isDesktopWidth }">
            <div @click="clickCreateNewTest()" :class=" { 'standard-button-base': name, 'disabled-button-base': !name } ">
                Create
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: null
        }
    },
    methods: {
        clickCreateNewTest() {
            if (this.name) {
                this.$emit('clickCreateNewABTest', 
                    {
                        name: this.name
                    }
                )

                this.name = null
            }
        },
        updateTitle(inField, inValue) {
            this.name = inValue
        }
    }
}
</script>