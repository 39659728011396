<template>
    <div class="w-full flex flex-wrap" style="padding-left: 1px;">
        <div v-for="x in count" :class="{ [parentClasses]: true }" style="padding-right: 1px; padding-bottom: 1px;">
            <div :class="{ [classes]: true }" :style="styles ? styles : {}">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['classes', 'parentClasses', 'count', 'styles']
}
</script>