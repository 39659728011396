<template>
    <div class="flex items-center mx-2 my-2">
        <input @change="fieldChange()" type="checkbox" v-model="fieldModel">
        <div v-if="!hideLabel" class="ml-1">{{ fieldLabel }}</div>
    </div>
</template>

<script>
export default {
    props: [
        'fieldFormName',
        'fieldLabel',
        'hideLabel',
        'fieldValue'
    ],
    data() {
        return {
            fieldModel: null
        }
    },
    mounted() {
        this.fieldModel = this.fieldValue
    },
    methods: {
        fieldChange() {
            this.$emit('fieldChange', this.fieldFormName, this.fieldModel)
        }
    }
}
</script>