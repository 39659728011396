<template>
    <div class="relative w-full overflow-hidden flex items-center justify-center" style="max-height: 60vh;">
        <div v-if="gallery" :id="`gallery-${galleryId}-box${modalString}`" class="flex items-stretch overflow-x-scroll scroll-snap" :class="{ 'media-max-height': !noMaxHeight }">
            <div v-for="(media, index) in gallery" @click="clickGalleryMedia(index)" :id="`gallery-box-${galleryId}-${index}${modalString}`" class="flex-1 relative w-full scroll-snap-start" style="min-width: 100%;">
                <div v-if="isImage(index)" :id="`gallery-inner-box-${galleryId}-${index}`" class="relative h-full w-full">
                    <img @error="retryImage(index)" :src="getImageUrl(index)" :id="`gallery-${galleryId}-${index}${modalString}`" :ref="`gallery-${index}`" class="relative object-cover h-full w-full" :class="{ 'media-max-height': !noMaxHeight }" />
                    <!-- <cached-image :id="`gallery-${galleryId}-${index}${modalString}`" :ref="`gallery-${index}`" imageClasses="relative object-cover h-full w-full" :class="{ 'media-max-height': !noMaxHeight }" :imageSrc="getImageUrl(index)" /> -->
                </div>
                <div v-else-if="isVideo(index)" class="flex-1 relative w-full overflow-hidden scroll-snap-start border" :class="{ 'media-max-height': !noMaxHeight }">
                    <div class="w-full h-full overflow-hidden" :class="{ 'media-max-height': !noMaxHeight }">
                        <video-intersect class="w-full h-full" :class="{ 'media-max-height': !noMaxHeight }" style="width: 100% !important; height: auto !important;"
                            :isIntersecting="isIntersecting" :noAutoPlay="index > 0" :modal="modal" :shouldPauseOnExit="pauseOnExit" :src="getVideoUrl(index)" :poster="getPosterUrl(index)" :attrs="videoAttrs"
                            @clickMute="clickMute" 
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="gallery.length > 1" class="absolute text-gray-800 flex items-center justify-center w-8 h-8 text-xs font-semibold bg-white border rounded-full top-2 right-2" style="opacity: 0.67">
            {{ gallery.length }}
        </div>
        <div v-show="pinching" class="fixed top-0 left-0 h-full w-full black-blur-3" style="z-index: 9998;">
        </div>
        <div v-show="pinching" @click="clickOff" class="fixed top-0 left-0 w-full h-full flex items-center justify-center" style="z-index: 9999;">
            <img @click="clickImage" :id="`gallery-${galleryId}-pinch${modalString}`" class="w-full h-full object-scale-down" />
        </div>
        <new-gallery v-if="gallery && galleryOpen" v-show="galleryOpen" 
            :gallery="gallery" :initialSlide="initialSlide" 
            @close="galleryOpen = false" 
        />
    </div>
</template>

<script>
import NewGallery from '@/shared/NewGallery'
import VideoIntersect from '@/shared/VideoIntersect'

export default {
    props: [
        'containerId', 
        'gallery', 
        'galleryId', 
        'isIntersecting', 
        'modal',
        'noMaxHeight',
        'videosMuted'
    ],
    components: {
        NewGallery,
        VideoIntersect
    },
    data() {
        return {
            allowClickOff: false,
            galleryOpen: false,
            imageRetries: [],
            initialSlide: 0,
            pinching: false,
            pinchStart: {}
        }
    },
    computed: {
        modalString() {
            return this.modal ? '-modal' : ''
        },
        pauseOnExit() {
            return !this.modal && this.userAutoplay
        },
        userAutoplay() {
            return this?.loggedUser?.settings?.autoplay ?? true
        },
        // userMuted() {
        //     return this?.loggedUser?.settings?.mute ?? true
        // },
        videoAttrs() {
            return {
                autoplay: false, 
                loop: true, 
                playsinline: true, 
                controls: !this.userAutoplay, 
                muted: this.videosMuted //this.userMuted
            }
        }
    },
    mounted() {
        this.setGalleryImageEvents()
        this.setPinchImageEvents()
    },
    methods: {
        clickGalleryMedia(inIndex) {
            this.allowClickOff = false
            
            if (this.isMobileDevice) {
                if (this.gallery[inIndex].type == 'image') {
                    let imageElement = this.elGet(`gallery-${this.galleryId}-${inIndex}${this.modalString}`)
                    let pinchingImage = this.elGet(`gallery-${this.galleryId}-pinch${this.modalString}`)

                    if (imageElement && pinchingImage) {
                        pinchingImage.src = imageElement.src
                        this.pinching = true

                        setTimeout(() => {
                            this.allowClickOff = true
                        }, 200)
                    }
                }
            }
            else {
                this.galleryOpen = true
            }

            this.$emit('clickMedia', this.gallery[inIndex].type)
        },
        clickImage() {

        },
        clickMute(inMute) {
            this.$emit('clickMute', inMute)
        },
        clickOff() {
            if (this.allowClickOff) {
                this.pinching = false
            }
        },
        distance(event) {
            return Math.hypot(event.touches[0].pageX - event.touches[1].pageX, event.touches[0].pageY - event.touches[1].pageY)
        },
        getImageUrl(inIndex) {
            return this.transformUrl(this.gallery[inIndex].url, ['optimize', 'lg', 'q80'])
        },
        getPosterUrl(inIndex, inContainerRef) {
            let containerWidth = 0
            let containerHeight = 0

            if (inContainerRef) {
                let myContainer = this.getRectEl(this.$refs[inContainerRef])
                containerWidth = Math.round(myContainer.width)
                containerHeight = Math.round(myContainer.height)
            }
            else {
                let myContainer = this.getRect(this.containerId)
                containerWidth = Math.round(myContainer.width)
                containerHeight = 0
            }

            return this.posterUrl(this.gallery[inIndex].url,['optimize', 'video-md'], containerWidth, containerHeight)
        },
        getVideoUrl(inIndex, inContainerRef) {
            let containerWidth = 0
            let containerHeight = 0

            if (inContainerRef) {
                let myContainer = this.getRectEl(this.$refs[inContainerRef])
                containerWidth = Math.round(myContainer.width)
                containerHeight = Math.round(myContainer.height)
            }
            else {
                let myContainer = this.getRect(this.containerId)
                containerWidth = Math.round(myContainer.width)
                containerHeight = 0
            }
            
            return this.transformUrl(this.gallery[inIndex].url,['video', 'optimize', 'video-md'], containerWidth, containerHeight)
        },
        imgLoaded(inIndex) {
            if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                let imageElement = this.elGet(`gallery-${this.galleryId}-${inIndex}${this.modalString}`)
                let imageBoxElement = this.elGet(`gallery-box-${this.galleryId}-${inIndex}${this.modalString}`)    
                
                if (imageBoxElement) {
                    imageBoxElement.style.width = `${this.windowWidth}px`
                    imageBoxElement.style.minWidth = `${this.windowWidth}px`
                }

                if (imageElement) {
                    imageElement.addEventListener('touchstart', (event) => {
                        if (event.touches.length === 2) {
                            event.preventDefault()
                            
                            let start = {}
                            start.x = (event.touches[0].pageX + event.touches[1].pageX) / 2
                            start.y = (event.touches[0].pageY + event.touches[1].pageY) / 2
                            start.distance = this.distance(event)
                            this.pinchStart = this.objCopy(start)
                        }
                    })

                    imageElement.addEventListener('touchmove', (event) => {
                        if (event.touches.length === 2) {
                            event.preventDefault()
                            
                            let scale

                            if (event.scale) {
                                scale = event.scale
                            } else {
                                const deltaDistance = this.distance(event)
                                scale = deltaDistance / this.pinchStart.distance
                            }

                            let imageElementScale = Math.min(Math.max(1, scale), 4)
                            const deltaX = (((event.touches[0].pageX + event.touches[1].pageX) / 2) - this.pinchStart.x) * 2
                            const deltaY = (((event.touches[0].pageY + event.touches[1].pageY) / 2) - this.pinchStart.y) * 2
                            const transform = `translate3d(${deltaX}px, ${deltaY}px, 0) scale(${imageElementScale})`
                            imageElement.style.transform = transform
                            imageElement.style.WebkitTransform = transform
                            imageElement.style.zIndex = "9999"
                        }
                    })

                    imageElement.addEventListener('touchend', (event) => {
                        imageElement.style.transform = ""
                        imageElement.style.WebkitTransform = ""
                        imageElement.style.zIndex = ""
                    })
                }

                let galleryContainer = this.elGet(`gallery-${this.galleryId}-box`)
                galleryContainer.scrollLeft = 0
            }
        },
        isImage(inIndex) {
            return this.gallery[inIndex].type == 'image'
        },
        isVideo(inIndex) {
            return this.gallery[inIndex].type == 'video'
        },
        retryImage(inIndex) {
            // if (!this.imageRetries.includes(inIndex) && this.$refs[`gallery-${inIndex}`]) {
            //     this.imageRetries.push(inIndex)
            //     this.$refs[`gallery-${inIndex}`].src = null

            //     this.$nextTick(() => {
            //         this.$refs[`gallery-${inIndex}`].src = this.getImageUrl(inIndex)
            //     })
            // }
            // else if (this.$refs[`gallery-${inIndex}`]) {
            //     this.$refs[`gallery-${inIndex}`].classList.add('hidden')
            // }
        },
        setGalleryImageEvents() {
            this.gallery.forEach((item, index) => {
                this.imgLoaded(index)
            })
        },
        setPinchImageEvents() {
            if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                let pinchingImage = this.elGet(`gallery-${this.galleryId}-pinch${this.modalString}`)

                pinchingImage.addEventListener('touchstart', (event) => {
                    if (event.touches.length === 2) {
                        event.preventDefault()
                        
                        let start = {}
                        start.x = (event.touches[0].pageX + event.touches[1].pageX) / 2
                        start.y = (event.touches[0].pageY + event.touches[1].pageY) / 2
                        start.distance = this.distance(event)
                        this.pinchStart = this.objCopy(start)
                    }
                })

                pinchingImage.addEventListener('touchmove', (event) => {
                    if (event.touches.length === 2) {
                        event.preventDefault()
                        let scale

                        if (event.scale) {
                            scale = event.scale
                        } 
                        else {
                            const deltaDistance = this.distance(event)
                            scale = deltaDistance / this.pinchStart.distance
                        }

                        let imageElementScale = Math.min(Math.max(1, scale), 4)
                        const deltaX = (((event.touches[0].pageX + event.touches[1].pageX) / 2) - this.pinchStart.x) * 2
                        const deltaY = (((event.touches[0].pageY + event.touches[1].pageY) / 2) - this.pinchStart.y) * 2
                        const transform = `translate3d(${deltaX}px, ${deltaY}px, 0) scale(${imageElementScale})`
                        pinchingImage.style.transform = transform
                        pinchingImage.style.WebkitTransform = transform
                        pinchingImage.style.zIndex = "9999"
                    }
                })

                pinchingImage.addEventListener('touchend', (event) => {
                    this.pinching = false
                    pinchingImage.style.transform = ""
                    pinchingImage.style.WebkitTransform = ""
                    pinchingImage.style.zIndex = ""
                    pinchingImage.src = null
                })
            }
        }
    }
}
</script>

<style>
.media-max-height {
    max-height: 60vh;
}

.scroll-snap {
    scroll-snap-type: x mandatory;
}

.scroll-snap-start {
    scroll-snap-align: end;
    scroll-snap-stop: always;
}
</style>
