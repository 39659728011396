<template>
    <div class="relative py-3 mt-3 border-t-2 border-b-2">
        <div>
            <div class="flex align-start">
                <div class="flex-none mr-2 rounded-full w-7 h-7 border-2 border-orange-500">
                    <user-avatar :url="loggedUser ? loggedUser.avatar : null" :rounded="true" />
                </div>
                <div class="flex-grow">
                    <mentions
                        :keys="['@', '+']" offset="6" inputId="newPostReactionText"
                        @apply="postBuildImage"
                    >
                        <textarea id="newPostReactionText" v-model="text" class="w-full p-2 text-sm bg-white border border-gray-300 rounded focus:border-orange-500 focus:ring-orange-300 edit-field-text-color" placeholder="Add a comment...">
                        </textarea>
                    </mentions>
                </div>
            </div>
            <div class="flex mt-1 ml-9">
                <div class="flex-grow">
                    <media-panel v-if="mounted && showGallery"
                        fieldLabel="Media" :hideSpinner="true" :imagesArray="gallery" :imagesOnly="false" :maxFiles="1" :multiple="false"
                        @saveMedia="saveLocalGallery" @updateOfflineMedia="updateOfflineMedia"
                    />
                </div>
            </div>
            
            <div class="flex justify-end -mt-2">
                <div @click="clickPost" class="px-3 py-1 text-sm font-medium leading-5 text-white rounded-md" :class="{ 'bg-orange-500 cursor-pointer': !postingReaction && (text || gallery.length > 0), 'bg-gray-300 cursor-not-allowed': postingReaction || (!text && !gallery.length > 0) }">
                    Post
                </div>
            </div>
        </div>
        <div v-if="postingReaction" class="absolute top-0 left-0 flex items-center justify-center w-full h-full text-orange-400">
            <fa-icon icon="spinner" type="fas" class="h-10 w-10 spin" />
        </div>
    </div>
</template>

<script>
import twitter from 'twitter-text'

import Mentions from './Mentions'

export default {
    props: ['queryId', 'replying', 'replyingHandle'],
    components: {
        Mentions
    },
    data() {
        return {
            gallery: [],
            text: null,
            mounted: false,
            showGallery: true
        }
    },
    computed: {
        isUnpostedMedia() {
            return this.gallery && this.gallery.length && this.gallery.filter(media => media.preview).length ? true : false
        }
    },
    watch: {
        replying: {
            immediate: true,
            handler(val) {
                if (val) {
                    if (this.replyingHandle) {
                        this.text = `@${this.replyingHandle} `
                    }

                    let myInput = this.elGet('newPostReactionText')
                    let end = myInput.value.length
                    myInput.setSelectionRange(end, end)
                    myInput.focus()
                    this.$emit('unsetReplying')
                }
            }
        },
        text: {
            handler(val) {
                if (!val || this.elGet('newPostReactionText').scrollHeight < 58) {
                    this.elGet('newPostReactionText').style.height = "58px" 
                }
                else {
                    this.elGet('newPostReactionText').style.height = `${this.elGet('newPostReactionText').scrollHeight}px`
                }
            }
        }
    },
    mounted() {
       this.mounted = true
    },
    methods: {
        async clickPost() {
            if (!this.postingReaction && (this.text || this.gallery.length > 0)) {
                this.setStateData([ 
                    { postingReaction: true }
                ])

                let feeds = {}
                let pattern = /\B\+[a-z0-9_-]+/gi
                
                feeds['builds'] = this.elGet('newPostReactionText').value.match(pattern)?.map(build => {
                    return build.substring(1)
                }) || []

                feeds['mentions'] = twitter?.extractMentions(this.elGet('newPostReactionText').value)
                feeds['hashtags'] = twitter?.extractHashtags(this.elGet('newPostReactionText').value)

                if (this.gallery.length) {
                    this.gallery.forEach((item)=>{
                        if (item.url) {
                            item.url = item.url.replace(/\/v[0-9].*?\//gm,'/')   // strip version
                            item.url = item.url.replace(/\/l_buildz_logo_black_plate_zbi4al,fl_region_relative,w_1.4,g_ocr_text\//gm,'/')   // strip existing ocr
                            
                            if (item.type == 'image' && item.blur){
                                item.url = item.url.replace(/\/buildz_pro_public\/.*/gm, "/l_buildz_logo_black_plate_zbi4al,fl_region_relative,w_1.4,g_ocr_text$&")
                            }
                        }
                    })

                    let myTempGallery = await this.buildOfflineTempGallery(this.gallery, `reaction_${this.getRandomString(20)}`)

                    let myOtherData = {
                        text: this.elGet('newPostReactionText').value, 
                        feeds: feeds
                    }

                    setTimeout(() => {
                        this.saveGalleryPrep(myTempGallery, 'reactionNew', this.getRandomString(20), null, myOtherData)
                        this.$emit('unsetReplying')
                        this.gallery = []
                        this.text = null
                    }, 100)
                }
                else {
                    this.newPostReaction(this.elGet('newPostReactionText').value, null, feeds)
                }

                if (this?.activityToShow?.foreign_id) {
                    this.algoliaConvert(this.activityToShow.foreign_id, 'activities', this.queryId)
                }

                this.$emit('unsetReplying')
                this.gallery = []
                this.text = null
            }
        },
        postBuildImage(inItem, inKey) {
            if (inKey == "+" && inItem.avatar && inItem.avatar.includes('res.cloudinary.com') && this.gallery.length == 0) {
                let myImage = {
                    url: inItem.avatar,
                    type: "image"
                }

                this.gallery = [myImage]
                this.showGallery = false

                setTimeout(() => {
                    this.showGallery = true
                }, 500)
            }
        },
        resetWritePostReaction() {
            this.gallery = []
            this.text = null
            this.showGallery = false

            setTimeout(() => {
                this.showGallery = true
            }, 500)
        },
        saveLocalGallery(inGallery) {
            this.gallery = inGallery
        },
        updateOfflineMedia(inGallery) {
            this.gallery = inGallery
        }
    }
}
</script>