const validationErrorMixins = {
  	methods: {
		postValidationError(inType, inData) {
			let url = window.location.href

            if (url.includes('apptest.buildz') || url.includes('whitetest.buildz') || url.includes('localhost')) {
                console.log(inType, inData)
            }
            else {
				buildzAxios?.post(this.getRoute('validationError'), {
					type: inType,
					data: inData
				})
			}
		}
	}
}

export default validationErrorMixins