const serverMixins = {
    methods: {
        functionUnavailable() {
            this.pageMessage('warning', 'You are currently not connected to the internet, this function is not available')
        },
        setServerConnected(inConnected) {
            if (inConnected != this.serverConnected) {
                this.setStateData([
                    { serverConnected: inConnected }
                ])

                if (inConnected) {
                    this.pageMessage('success', 'Your internet connection has been restored')
                    
                    if (this.isLoggedIn && !this.streamToken) {
                        this.getUserData()
                    }

                    if (!this.utcServer) {
                        this.getGlobalData()
                    }

                    this.readQueue()
                }
                else {
                    alert('You are currently not connected to the internet and have limited functionality')
                }
            }
        }
    }
}

export default serverMixins