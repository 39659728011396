<template>
    <div class="relative w-full white-transparent mt-px py-3 px-2">
        <div v-if="!hideLabel" class="w-full">
            <panel-label 
                :fieldLabel="fieldLabel" :fieldLabelClasses="fieldLabelClasses" :fieldRequired="fieldRequired && !fieldNonEdit" :forceMobile="true"
            />
        </div>
        <div class="w-full">
            <div v-if="!fieldNonEdit" class="w-full">
                <textarea
                    @input="fieldInput()"
                    :placeholder="fieldPlaceholder"
                    v-model="fieldModel"
                    class="w-full p-2 text-sm bg-white border border-gray-300 rounded focus:border-orange-500 focus:ring-orange-300 edit-field-text-color"
                    :class="{ 'bg-white': !hideFieldModel, 'bg-gray-400': hideFieldModel }"
                    :rows="rows"
                />
            </div>
            <div v-else class="w-full mt-2">
                <div class="form-input edit-field-text-color bg-gray-400 py-1 w-full">
                    {{ fieldModel }}
                </div>
            </div>
        </div>
        <div v-if="hideIcon" class="absolute bg-orange-500 text-white w-12 rounded-md flex items-center justify-center cursor-pointer top-0 right-2" style="height: 34px;">
            <div v-if="hideFieldModel" @click="toggleHide()" class="cursor-pointer flex items-center">
                <fa-icon icon="eye-slash" type="fas" class="h-6 w-6" />
            </div>
            <div v-if="!hideFieldModel" @click="toggleHide()" class="cursor-pointer flex items-center">
                <fa-icon icon="eye" type="fas" class="h-6 w-6" />
            </div>
        </div>
        <div v-if="fieldSubLabel" class="text-sm text-center px-2 pb-2">
            {{ fieldSubLabel }}
        </div>
        <slot /> 
    </div>
</template>

<script>
export default {
    props: [
        'fieldFormName', 
        'fieldLabel',
        'fieldLabelClasses',
        'fieldNonEdit',
        'fieldPlaceholder',
        'fieldRequired', 
        'fieldSubLabel',
        'fieldValue',
        'hideFieldFormName',
        'hideFieldValue',
        'hideIcon',
        'hideLabel',
        'rows'
    ],
    data() {
        return {
            fieldModel: null,
            hideFieldModel: null
        }
    },
    watch: {
        hideFieldValue: {
            handler(val) {
                this.hideFieldModel = val
            }
        }
    },
    mounted() {
        this.fieldModel = this.fieldValue
        this.hideFieldModel = this.hideFieldValue
    },
    methods: {
        fieldInput() {
            this.$emit('fieldInput', this.fieldFormName, this.fieldModel)
        },
        toggleHide() {
            this.hideFieldModel = !this.hideFieldModel
            this.$emit('fieldInput', this.hideFieldFormName, this.hideFieldModel)
        }
    }
}
</script>