// import { NativeAudio } from '@capacitor-community/native-audio'

const badgeMixins = {
    methods: {
        closeBadgeModal() {
            this.setStateData([ 
                { badgeAwarded:  null },
                { showBadgeModal:  false }
            ])
        },
        closeBadgePopup(inClearBadge = true) {
            if (inClearBadge) {
                this.setStateData([ 
                    { badgeAwarded:  null }
                ])
            }

            this.setStateData([ 
                { showBadgePopup:  false }
            ])
        },
        openBadgeModal(inBadge, inPlaySound = true) {
            this.setStateData([ 
                { badgeAwarded:  inBadge },
                { badgePlaySound:  inPlaySound },
                { showBadgeModal:  true }
            ])
        },
        openBadgePopup(inBadge) {
            this.setStateData([ 
                { badgeAwarded:  inBadge },
                { badgePlaySound:  true },
                { showBadgePopup:  true }
            ])
        },
        playSound(inId) {
            // if (NativeAudio) {
            //     NativeAudio.play({
            //         assetId: inId
            //     })
            // }
        }
    }
}

export default badgeMixins