<template>
    <div class="px-1 w-full">
        <div v-for="x in count" :class="{ [myClasses]: true }">
        </div>
    </div>
</template>

<script>
export default {
    props: ['count', 'classes'],
    data() {
        return {
            myClasses: this.classes ?? 'flasher-light-bg flasher-light-animate h-4 rounded-full mb-1'
        }
    }
}
</script>