<template>
    <ais-instant-search v-if="algoliaSearchClient" :search-client="algoliaSearchClient" :index-name="index" class="absolute z-10 bg-white border rounded top-10" style="min-width: 200px;">
        <ais-configure
            :hits-per-page.camel="4"
            :distinct="true"
            :filters="filters"
            :attributesToRetrieve="[attribute]"
            :restrictSearchableAttributes="[attribute]"
            
            :query="query"
        />
        <ais-hits v-show="query && query.length > 0" class="relative p-2">
            <template ref="results" v-slot="{ items, sendEvent }">
                <div v-if="items.length > 0">
                    <div v-for="(item, i) in items" :key="i" class="cursor-pointer hover:bg-gray-200">
                        <div @click="$emit('setItem', item[attribute])" class="p-1 text-xs text-left text-gray-500">{{ item[attribute] }}</div>
                    </div>
                </div>
                <div v-else class="p-1 text-xs text-left text-gray-500">
                    No suggested results.
                </div>
                <svg @click="$emit('close')" class="absolute top-0 right-0 w-8 h-8 p-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </template>
        </ais-hits>
    </ais-instant-search>
</template>

<script>
export default {
    props: ['index', 'attribute', 'filters', 'query']
}
</script>