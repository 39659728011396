<template>
    <div v-if="formLoaded" class="w-full">
        <date-picker-panel
            :fieldDisplayValue="groupBuyForm.eoi_start_time" fieldFormName="eoi_start_time" fieldLabel="E.O.I. Start Time" 
            :fieldNonEdit="pGroupBuyForm.eoi || !pGroupBuyForm.disabled" :fieldRequired="true" :fieldValue="dates.eoi_start_time" 
            @fieldChange="updateFormDateValue"
        >
            <validation-message v-if="!dates.eoi_start_time" 
                message="E.O.I. Start Time must be selected"
            />
        </date-picker-panel>
        <date-picker-panel
            :fieldDisplayValue="groupBuyForm.eoi_end_time" fieldFormName="eoi_end_time" fieldLabel="E.O.I. End Time" 
            :fieldNonEdit="pGroupBuyForm.eoi || !pGroupBuyForm.disabled" :fieldRequired="true" :fieldValue="dates.eoi_end_time" 
            @fieldChange="updateFormDateValue"
        >
            <validation-message v-if="!dates.eoi_end_time" 
                message="E.O.I. End Time must be selected"
            />
            <validation-message v-if="dates.eoi_start_time && dates.eoi_end_time && dates.eoi_end_time < dates.eoi_start_time" 
                message="E.O.I. End Time must be after E.O.I. Start Time"
            />
        </date-picker-panel>
        <select-panel
            :fieldData="noYesData" fieldFormName="no_eoi_maximum" fieldLabel="E.O.I. Has Maximum Quantity" :fieldNonEdit="pGroupBuyForm.eoi || !pGroupBuyForm.disabled"
            :fieldNonEditValue="displayValueNoEoiMaximum" :fieldValue="groupBuyForm.no_eoi_maximum" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <input-panel v-if="!groupBuyForm.no_eoi_maximum"
            fieldFormName="eoi_max_quantity" fieldLabel="E.O.I. Maximum Quantity" :fieldNonEdit="pGroupBuyForm.eoi || !pGroupBuyForm.disabled" 
            fieldPlaceholder="E.O.I. Maximum Quantity" :fieldRequired="true" fieldType="number" :fieldValue="groupBuyForm.eoi_max_quantity"
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="groupBuyForm.eoi_max_quantity < 1" 
                message="E.O.I. Maximum Quantity must be greater than zero"
            />
            <validation-message v-if="!pEoiMaximumOkay && pSortedPricingLevels && pSortedPricingLevels.length" 
                :message="`E.O.I. Maximum Quantity must be greater than or equal to your largest breakpoint (${pSortedPricingLevels[pSortedPricingLevels.length - 1].qty})`"
            />
        </input-panel>
    </div>
</template>

<script>
export default {
    props: [
        'pEoiEndTime', 
        'pEoiMaximumOkay',
        'pEoiStartTime', 
        'pFormLoaded', 
        'pGotGroupBuy',
        'pGroupBuy', 
        'pGroupBuyForm',
        'pSortedPricingLevels'
    ],
    data() {
        return {
            dates: {
                eoi_end_time: null,
                eoi_start_time: null
            },
            formLoaded: false,
            groupBuyForm: {
                eoi_end_time: null,
                eoi_max_quantity: 1,
                eoi_start_time: null,
                no_eoi_maximum: false
            }
        }
    },
    computed: {
        displayValueNoEoiMaximum() {
            return this.groupBuyForm.no_eoi_maximum ? "Yes" : "No"
        },
        eoiEndTime() {
            return this.dates.eoi_end_time
        },
        eoiStartTime() {
            return this.dates.eoi_start_time
        }
    },
    mounted() {
        this.dates.eoi_end_time = this.pEoiEndTime
        this.dates.eoi_start_time = this.pEoiStartTime
        this.groupBuyForm.eoi_end_time = this.pGroupBuyForm.eoi_end_time
        this.groupBuyForm.eoi_max_quantity = this.pGroupBuyForm.eoi_max_quantity != null ? this.pGroupBuyForm.eoi_max_quantity : 1
        this.groupBuyForm.eoi_start_time = this.pGroupBuyForm.eoi_start_time
        this.groupBuyForm.no_eoi_maximum = this.pGroupBuyForm.no_eoi_maximum
        this.formLoaded = true
    },
    methods: {
        updateFormDateValue(inField, inValue) {
            let originalValue = Number.isInteger(this.dateTimeToUnixTime(this.dates[inField])) ? this.dateTimeToUnixTime(this.dates[inField]) : this.dates[inField] / 1000
            let newValue = Number.isInteger(this.dateTimeToUnixTime(inValue)) ? this.dateTimeToUnixTime(inValue) : inValue / 1000

            if (originalValue != newValue) {
                this.dates[inField] = inValue
                this.groupBuyForm[inField] = inValue
                this.$emit('updateEoi', this.groupBuyForm, true, this.dates.eoi_end_time, this.dates.eoi_start_time)
            }
        },
        updateFormSelectValue(inField, inValue) {
            this.groupBuyForm[inField] = inValue
            this.$emit('updateEoi', this.groupBuyForm, true, this.dates.eoi_end_time, this.dates.eoi_start_time)
        },
        updateFormValue(inField, inValue) {
            this.groupBuyForm[inField] = inValue
            this.$emit('updateEoi', this.groupBuyForm, false, this.dates.eoi_end_time, this.dates.eoi_start_time)
        }
    }
}
</script>