const waskMixins = {
    methods: {
        initWask() {
//             let waskScript = document.createElement("script")
            
//             waskScript.innerHTML = `
// <!-- Wask Analytics JS Code -->
// (function(w, a, s, k) {
// const h = w.getElementsByTagName('head')[0];
//     k = w.createElement('script');
// k.src = a + '?c=' +s;
// k.async = 1;
// h.appendChild(k);
// window.wask_analytics = window.wask_analytics || {};
// window.wask_analytics.client_code = s;
// })(document, 'https://analytics.wask.co/client/wask.analytics.min.js', '99ecb698-170f-4818-af7a-19439e5c602f');`

//             document.getElementsByTagName('head')[0].appendChild(waskScript)
        }
    }
}

export default waskMixins