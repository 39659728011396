const spinnerMixins = {
    methods: {
        closeSpinner() {
            this.setStateData([
                { showSpinner: false }
            ])
        },
        openSpinner() {
            this.setStateData([
                { showSpinner: true }
            ])
        }
    }
}

export default spinnerMixins