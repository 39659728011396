<template>
    <div class="w-full mt-px" :class="{ 'px-2 py-3': (!isDesktopWidth || forceMobile) && !fieldNude, 'white-transparent': !fieldNude }">
        <div class="w-full" :class="{ 'flex items-stretch': isDesktopWidth && !forceMobile }">
            <div v-if="!hideLabel" :class="{ 'w-1/2 py-3 pl-2 pr-1': isDesktopWidth && !forceMobile, 'w-full': !isDesktopWidth || forceMobile }">
                <panel-label 
                    :fieldLabel="fieldLabel" :fieldLabelClasses="fieldLabelClasses" :fieldRequired="fieldRequired && !fieldNonEdit" :forceMobile="forceMobile" :hide="hideFieldModel"
                />
            </div>
            <div :class="{ 'w-1/2 pr-2 py-3': isDesktopWidth && !forceMobile, 'w-full': !isDesktopWidth || forceMobile, 'flex items-center justify-between': hideIcon }">
                <div v-if="fieldType == 'password'" class="w-full">
                    <input type="password" :id="fieldId" readonly v-model="fieldModel" @input="fieldInput()" @focus="focusPassword()" @blur="fieldBlur()" :placeholder="fieldPlaceholder" :autocomplete="fieldAutoCompleteValue" class="form-input edit-field-text-color bg-white py-1 w-full text-center" />
                </div>
                <div v-else-if="!fieldNonEdit" class="w-full relative">
                    <input :type="fieldType" :id="fieldId" :name="fieldId" v-model="fieldModel" ref="inpt" @input="fieldInput()" @blur="fieldBlur()" @paste="fieldPaste()" :placeholder="fieldPlaceholder" :autocomplete="fieldAutoCompleteValue" class="form-input edit-field-text-color py-1 w-full text-center" :class="{ 'pr-14': hideIcon, 'bg-white': !hideFieldModel, 'bg-gray-400 border-gray-400': hideFieldModel }" />
                    <div v-if="hideIcon" class="absolute top-0 right-0 bg-orange-500 text-white w-12 rounded-r-md flex items-center justify-center cursor-pointer" style="height: 34px;">
                        <div v-if="hideFieldModel" @click="toggleHide()" class="cursor-pointer flex items-center">
                            <fa-icon icon="eye-slash" type="fas" class="h-6 w-6" />
                        </div>
                        <div v-if="!hideFieldModel" @click="toggleHide()" class="cursor-pointer flex items-center">
                            <fa-icon icon="eye" type="fas" class="h-6 w-6" />
                        </div>
                    </div>
                </div>
                <div v-else class="relative form-input edit-field-text-color bg-gray-400 border-gray-400 border py-1 w-full">
                    {{ fieldModel }}
                    <div v-if="hideIcon" class="absolute bg-orange-500 text-white w-12 rounded-r-md flex items-center justify-center cursor-pointer" style="height: 34px; top: -1px; right: -1px;">
                        <div v-if="hideFieldModel" @click="toggleHide()" class="cursor-pointer flex items-center">
                            <fa-icon icon="eye-slash" type="fas" class="h-6 w-6" />
                        </div>
                        <div v-if="!hideFieldModel" @click="toggleHide()" class="cursor-pointer flex items-center">
                            <fa-icon icon="eye" type="fas" class="h-6 w-6" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="fieldSubLabel" class="text-xs text-center px-2">
            {{ fieldSubLabel }}
        </div>
        <slot /> 
    </div>
</template>

<script>
export default {
    props: [
        'fieldAutoComplete', 
        'fieldFormName', 
        'fieldFormSubObjectName', 
        'fieldId',
        'fieldLabel',
        'fieldLabelClasses',
        'fieldNonEdit',
        'fieldNude',
        'fieldPlaceholder', 
        'fieldRequired', 
        'fieldStopAutoComplete',
        'fieldSubLabel',
        'fieldType', 
        'fieldValue',
        'forceMobile',
        'hideFieldFormName',
        'hideFieldValue',
        'hideIcon',
        'hideLabel'
    ],
    data() {
        return {
            fieldModel: null,
            hideFieldModel: null
        }
    },
    computed: {
        fieldAutoCompleteValue() {
            if (this.fieldAutoComplete) {
                return this.fieldAutoComplete
            }

            return this.fieldStopAutoComplete ? this.getRandomString(20) : ''
        }
    },
    watch: {
        fieldValue: {
            handler(val) {
                this.fieldModel = val
            }
        },
        hideFieldValue: {
            handler(val) {
                this.hideFieldModel = val
            }
        }
    },
    mounted() {
        this.fieldModel = this.fieldValue
        this.hideFieldModel = this.hideFieldValue
    },
    methods: {
        fieldBlur() {
            this.$emit('fieldBlur', this.fieldFormName, this.fieldModel, this.fieldFormSubObjectName)
        },
        fieldInput() {
            this.$emit('fieldInput', this.fieldFormName, this.fieldModel, this.fieldFormSubObjectName)
        },
        fieldPaste() {
            setTimeout(() => {
                this.$refs.inpt.blur()
                this.$emit('fieldPaste', this.fieldModel)
            }, 500)
        },
        focusPassword() {
            if (this.fieldId) {
                this.elGet(this.fieldId).removeAttribute('readonly')
            }
        },
        toggleHide() {
            this.hideFieldModel = !this.hideFieldModel
            this.$emit('fieldInput', this.hideFieldFormName, this.hideFieldModel, this.fieldFormSubObjectName)
        }
    }
}
</script>