<template>
    <div>
        <build v-if="showBuild" />
        <communities v-if="showCommunities" />
        <events v-if="showEvents" />
        <garage v-if="showGarage" />
        <settings v-if="showSettings" />
        <tab-search-builders v-if="showTabSearchBuilders" />
        <tab-search-buildz v-if="showTabSearchBuildz" />
        <tab-search-media v-if="showTabSearchMedia" />
        <tab-search-posts v-if="showTabSearchPosts" />
        <tab-search-products v-if="showTabSearchProducts" />
        <tags v-if="showTags" />
    </div>
</template>

<script>
import Build from './pages/build/Build'
import Communities from './pages/communities/Communities'
import Events from './pages/events/Events'
import Garage from './pages/garage/Garage'
import Settings from './pages/settings/Settings'
import TabSearchBuilders from './pages/search/tabs/Builders'
import TabSearchBuildz from './pages/search/tabs/Buildz'
import TabSearchMedia from './pages/search/tabs/Media'
import TabSearchPosts from './pages/search/tabs/Posts'
import TabSearchProducts from './pages/search/tabs/Products'
import Tags from './pages/tags/Tags'

export default {
    components: {
        Build,
        Communities,
        Events,
        Garage,
        Settings,
        TabSearchBuilders,
        TabSearchBuildz,
        TabSearchMedia,
        TabSearchPosts,
        TabSearchProducts,
        Tags
    },
    data() {
      return {
            showBuild: false,
            showCommunities: false,
            showEvents: false,
            showGarage: false,
            showSettings: false,
            showTabSearchBuilders: false,
            showTabSearchBuildz: false,
            showTabSearchMedia: false,
            showTabSearchPosts: false,
            showTabSearchProducts: false,
            showTags: false
        }
    },
    watch: {
        flasherPage: {
            immediate: true,
            handler(val) {
                this.showBuild = false
                this.showCommunities = false
                this.showEvents = false
                this.showGarage = false
                this.showSettings = false
                this.showTabSearchBuilders = false
                this.showTabSearchBuildz = false
                this.showTabSearchMedia = false
                this.showTabSearchPosts = false
                this.showTabSearchProducts = false
                this.showTags = false

                if (val) {
                    if (val == 'build') {
                        this.showBuild = true
                    }
                    else if (val == 'garage') {
                        this.showGarage = true
                    }
                    else if (val == 'settings') {
                        this.showSettings = true
                    }
                    else if (val == 'tags') {
                        this.showTags = true
                    }
                    else if (val == 'communities') {
                        this.showCommunities = true
                    }
                    else if (val == 'events') {
                        this.showEvents = true
                    }
                    else if (val == 'tabSearchBuildz') {
                        this.showTabSearchBuildz = true
                    }
                    else if (val == 'tabSearchProducts') {
                        this.showTabSearchProducts = true
                    }
                    else if (val == 'tabSearchBuilders') {
                        this.showTabSearchBuilders = true
                    }
                    else if (val == 'tabSearchMedia') {
                        this.showTabSearchMedia = true
                    }
                    else if (val == 'tabSearchPosts') {
                        this.showTabSearchPosts = true
                    }
                }
            }
        }
    }
}
</script>