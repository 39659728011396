<template>
    <div v-if="formLoaded" class="w-full">
        <rich-text-panel 
            fieldFormName="body" fieldLabel="Description" :fieldValue="variantForm.body"
            @fieldInput="updateFormValue"
        />
        <select-panel 
            :fieldData="buildTypes" fieldDataLabel="label" fieldDataValue="id" fieldFormName="buildz_group_id" fieldLabel="Build Category" 
            :fieldValue="variantForm.buildz_group_id"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel 
            :fieldData="vendorProductGroups" fieldDataLabel="name" fieldDataValue="id" fieldFormName="product_group_id"
            fieldLabel="Product Group" :fieldValue="variantForm.product_group_id" 
            @fieldChange="updateFormSelectValue"
        />
        <media-panel v-if="imagesArray"
            fieldLabel="Media" :imagesArray="imagesArray" :imagesOnly="false" :maxFiles="10" :multiple="true"
            @saveMedia="saveLocalGallery" @updateOfflineMedia="updateOfflineMedia"
        />
        <input-panel 
            fieldFormName="sku" fieldLabel="SKU" fieldPlaceholder="SKU" fieldType="text" :fieldValue="variantForm.sku"
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldFormName="grams" fieldLabel="Weight in Grams" fieldPlaceholder="Weight in Grams" fieldType="number" :fieldValue="variantForm.grams"
            @fieldInput="updateFormValue"
        />
        <text-area-panel 
            fieldFormName="fitment_data" fieldLabel="Fitment Data" fieldPlaceholder="Fitment Data" :fieldValue="variantForm.fitment_data"
            rows="5"
            @fieldInput="updateFormValue"
        />
    </div>
</template>

<script>
export default {
    props: [
        'pGallery', 
        'pVariantForm'
    ],
    data() {
        return {
            formLoaded: false,
            variantForm: {
                body: null,
                buildz_group_id: null,
                fitment_data: null,
                grams: null,
                product_group_id: null,
                sku: null
            }
        }
    },
    computed: {
        imagesArray() {
            let myGallery = []

            this.pGallery.forEach((myImage) => {
                myGallery.push(myImage)
            })

            return myGallery
        }
    },
    mounted() {
        this.variantForm.body = this.pVariantForm.body
        this.variantForm.buildz_group_id = this.pVariantForm.buildz_group_id
        this.variantForm.fitment_data = this.pVariantForm.fitment_data
        this.variantForm.grams = this.pVariantForm.grams
        this.variantForm.product_group_id = this.pVariantForm.product_group_id
        this.variantForm.sku = this.pVariantForm.sku
        this.formLoaded = true
    },
    methods: {
        saveLocalGallery(inGallery) {
            this.$emit('saveLocalGallery', inGallery)
        },
        updateFormSelectValue(inField, inValue) {
            this.variantForm[inField] = inValue
            this.$emit('updateDetails', this.variantForm, true)
        },
        updateFormValue(inField, inValue) {
            this.variantForm[inField] = inValue
            this.$emit('updateDetails', this.variantForm, false)
        },
        updateOfflineMedia(inGallery) {
            this.$emit('updateOfflineMedia', inGallery)
        }
    }
}
</script>