<template>
    <nav id="bzNavbar" class="fixed top-0 z-20 top-nav iphonePaddingTop" :style="true ? { 'background-color': whiteLabelData.headerColor } : {}">
        <div class="px-2 w-full">
            <div class="relative h-16 w-full">
                <div class="flex items-center justify-between w-full py-1">
                    <cached-image @click="goHome()" imageClasses="block w-auto h-14 cursor-pointer" :imageSrc="whiteLabelData.logo" />
                    <div v-if="isDesktopWidth" @click="goHome()" class="ml-2 cursor-pointer" :style="{ 'color': whiteLabelData.headerTextColor }">
                        {{ whiteLabelData.name }}
                    </div>
                    <div class="flex items-center justify-end">
                        <!-- <div v-if="isLoggedIn" class="flex items-center justify-end text-xs">
                            <router-link to="/account" :style="{ 'color': whiteLabelData.headerLinkColor }">
                                ACCOUNT
                            </router-link>
                            <div class="mx-2">
                                |
                            </div>
                        </div>
                        <router-link v-if="!isLoggedIn" to="/login" class="text-xs" :style="{ 'color': whiteLabelData.headerLinkColor }">
                            LOGIN
                        </router-link>
                        <router-link v-else to="/logout" class="text-xs" :style="{ 'color': whiteLabelData.headerLinkColor }">
                            LOGOUT
                        </router-link> -->
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    methods: {
        goHome() {
            this.routeTo('/')
        }
    }
}
</script>