import axios from "axios"

const moderationMixins = {
    computed: {

    },
    methods: {
        flagItem(type, id){
            buildzAxios?.post(this.getRoute('flagStore'), {
                'id' : id,
                'type' : type
            })
            .then((response) => {
                this.pageMessage('success', 'Thank you for reporting this ' + type + '.')
            })
            .catch((error) => {
                this.pageMessage('error', 'Error reporting ' + type + '.')
            })
            
        }
    }
}

export default moderationMixins