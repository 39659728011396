<template>
    <div class="w-full">
        <location-panel
            fieldId="s_location" fieldLabel="Where Do You Ship From?" fieldLabelClasses="text-gray-800" fieldPlaceholder="Please Type Your Location" :fieldRequired="true" 
            :fieldValue="sellerDataForm.shippingFrom.location" :forceMobile="true" :placeTypes="['(cities)']"
            @fieldChange="getShippingFromData"
        >
            <validation-message v-if="validating && !sellerDataForm.shippingFrom.location" 
                message="Shipping From Location must be selected"
            />
        </location-panel>
    </div>
</template>

<script>
export default {
    props: [
        'sellerDataForm',
        'validating'
    ],
    methods: {
        getShippingFromData(addressData) {
            let myShippingFrom = {
                geoloc: {
                    lat: addressData.geometry.location.lat,
                    lng: addressData.geometry.location.lng
                },
                location: addressData.formatted_address,
            }

            this.$emit('updateSellerDataFormValue', 'shippingFrom', this.objCopy(myShippingFrom))
        }
    }
}
</script>