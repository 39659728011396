<template>
    <div v-if="formLoaded" class="w-full">
        <input-panel 
            fieldFormName="label" fieldLabel="Title" fieldPlaceholder="Title" :fieldRequired="true" fieldType="text" :fieldValue="bundleForm.label" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(bundleForm.label)" 
                message="Label must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="price" fieldLabel="Price" fieldPlaceholder="Price" :fieldRequired="true" fieldType="number" 
            :fieldValue="bundleForm.price" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(bundleForm.price)" 
                message="Price must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="charityPercentage" fieldLabel="Charity Percentage" fieldPlaceholder="Charity Percentage" :fieldRequired="true" fieldType="number" 
            :fieldValue="bundleForm.charityPercentage" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(bundleForm.charityPercentage)" 
                message="Charity Percentage must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="prizePercentage" fieldLabel="Prize Percentage" fieldPlaceholder="Prize Percentage" :fieldRequired="true" fieldType="number" 
            :fieldValue="bundleForm.prizePercentage" 
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(bundleForm.prizePercentage)" 
                message="Prize Percentage must be entered"
            />
        </input-panel>
        <rich-text-panel 
            fieldFormName="description" fieldLabel="Description" :fieldValue="bundleForm.description"
            @fieldInput="updateFormValue"
        />
        <media-panel
            fieldLabel="Image" :hideSpinner="true" :imagesArray="imagesArray" :imagesOnly="true" :maxFiles="1" :multiple="false"
            @saveMedia="saveLocalImage" @updateOfflineMedia="updateOfflineMedia"
        />
        <div class="w-full white-transparent p-2 mt-px text-center">
            <div class="flex items-center justify-center pb-2">
                <div class="mr-2">
                    Campaign Rules
                </div>
                <div @click="clickNewCampaignRule()" class="text-orange-500 cursor-pointer">
                    <fa-icon icon="circle-plus" type="fas" class="h-4 w-4" />
                </div>
            </div>
            <div class="flex items-center justify-start mb-1 w-full">
                <div class="flex items-center justify-start w-full">
                    <div class="w-1/3 text-center font-semibold">
                        Campaign Number
                    </div>
                    <div class="w-1/3 text-center font-semibold">
                        Member Limit
                    </div>
                    <div class="w-1/3 text-center font-semibold">
                        Prize Limit
                    </div>
                </div>
                <div class="w-4">
                </div>
            </div>
            <div v-if="bundleForm.milestones.campaignRules && bundleForm.milestones.campaignRules.length" class="w-full">
                <div v-for="(rule, index) in bundleForm.milestones.campaignRules" class="flex items-center justify-start white-transparent py-2 px-1 mt-px w-full">
                    <div class="flex items-center justify-start w-full">
                        <div class="w-1/3 text-center">
                            <div class="px-1 w-full">
                                <input v-model="bundleForm.milestones.campaignRules[index].number" type="number" class="form-input edit-field-text-color bg-white py-1 w-full text-center" />
                            </div>
                        </div>
                        <div class="w-1/3 text-center">
                            <div class="px-1 w-full">
                                <input v-model="bundleForm.milestones.campaignRules[index].memberLimit" type="text" class="form-input edit-field-text-color bg-white py-1 w-full text-center" />
                            </div>
                        </div>
                        <div class="w-1/3 text-center">
                            <div class="px-1 w-full">
                                <input v-model="bundleForm.milestones.campaignRules[index].prizeLimit" type="text" class="form-input edit-field-text-color bg-white py-1 w-full text-center" />
                            </div>
                        </div>
                    </div>
                    <div @click="clickDeleteCampaignRule(index)" class="cursor-pointer text-orange-500">
                        <fa-icon icon="trash-can" type="fas" class="h-4 w-4" />
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full white-transparent p-2 mt-px text-center">
            <div class="flex items-center justify-center pb-2">
                <div class="mr-2">
                    Prize Rules
                </div>
                <div @click="clickNewPrizeRule()" class="text-orange-500 cursor-pointer">
                    <fa-icon icon="circle-plus" type="fas" class="h-4 w-4" />
                </div>
            </div>
            <div class="flex items-center justify-start mb-1 w-full">
                <div class="flex items-center justify-start w-full">
                    <div class="w-1/4 text-center font-semibold">
                        Min Pool
                    </div>
                    <div class="w-1/4 text-center font-semibold">
                        Max Pool
                    </div>
                    <div class="w-1/4 text-center font-semibold">
                        Type
                    </div>
                    <div class="w-1/4 text-center font-semibold">
                        Prize
                    </div>
                </div>
                <div class="w-4">
                </div>
            </div>
            <div v-if="bundleForm.milestones.prizeRules && bundleForm.milestones.prizeRules.length" class="w-full">
                <div v-for="(rule, index) in bundleForm.milestones.prizeRules" class="flex items-center justify-start white-transparent py-2 px-1 mt-px w-full">
                    <div class="flex items-center justify-start w-full">
                        <div class="w-1/4 text-center">
                            <div class="px-1 w-full">
                                <input v-model="bundleForm.milestones.prizeRules[index].minimum" type="number" class="form-input edit-field-text-color bg-white py-1 w-full text-center" />
                            </div>
                        </div>
                        <div class="w-1/4 text-center">
                            <div class="px-1 w-full">
                                <input v-model="bundleForm.milestones.prizeRules[index].maximum" type="number" class="form-input edit-field-text-color bg-white py-1 w-full text-center" />
                            </div>
                        </div>
                        <div class="w-1/4 text-center">
                            <div class="px-1 w-full">
                                <select v-model="bundleForm.milestones.prizeRules[index].type" class="form-input edit-field-text-color bg-white py-1 pr-8 w-full">
                                    <option value="pool">Pool</option>
                                    <option value="prize">Prize</option>
                                </select>
                            </div>
                        </div>
                        <div class="w-1/4 text-center">
                            <div class="px-1 w-full">
                                <input v-if="bundleForm.milestones.prizeRules[index].type != 'pool'" v-model="bundleForm.milestones.prizeRules[index].prize" type="number" class="form-input edit-field-text-color bg-white py-1 w-full text-center" />
                                <div v-else>
                                    Pool
                                </div>
                            </div>
                        </div>
                    </div>
                    <div @click="clickDeletePrizeRule(index)" class="cursor-pointer text-orange-500">
                        <fa-icon icon="trash-can" type="fas" class="h-4 w-4" />
                    </div>
                </div>
            </div>
        </div>
        <select-panel 
            :fieldData="yesNoData" fieldLabel="Enabled" fieldFormName="enabled" :fieldRequired="true" :fieldValue="bundleForm.enabled" 
            :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <div class="w-full white-transparent mt-px p-2">
            <div @click="clickSaveBundle()" class="w-full" :class=" { 'standard-button-base': allOkay, 'disabled-button-base': !allOkay } ">
                Save
            </div>
            <div @click="clickDelete()" class="mt-2 w-full secondary-button-base">
                Delete
            </div>
            <div v-if="isChanges" @click="clickCancelChanges()" class="mt-2 w-full alt-button-base">
                Cancel Changes
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'bundle',
        'groupBuy'
    ],
    data() {
        return {
            bundleForm: {
                charityPercentage: null,
                description: null,
                enabled: false,
                id: null,
                label: null,
                milestones: null,
                price: null,
                prizePercentage: null,
                tempImage: null
            },
            formLoaded: false,
            image: null,
            newImageGallery: null,
            newCampaignRule: {
                memberLimit: null,
                number: null,
                prizeLimit: null
            },
            newPrizeRule: {
                maximum: null,
                minimum: null,
                prize: null,
                type: null
            },
            validating: false
        }
    },
    computed: {
        allOkay() {
            return Boolean(this.bundleForm.label && this.image && this.bundleForm.price && this.bundleForm.charityPercentage && this.bundleForm.prizePercentage)
        },
        imagesArray() {
            if (!this.image) {
                return []
            }

            if (!this.image.type) {
                this.image.type = "image"
            }

            return [this.image]
        },
        isChanges() {
            let myCheckFields = [
                'charityPercentage',
                'description',
                'enabled',
                'label',
                'milestones',
                'price',
                'prizePercentage'
            ]

            let myChanges = false

            myCheckFields.forEach((checkField) => {
                if (this.bundleForm[checkField] != this.bundle[checkField]) {
                    myChanges = true
                }
            })

            return myChanges && Boolean(this.image != this.bundle.image)
        }
    },
    mounted() {
        this.bundleForm.charityPercentage = this.bundle.charityPercentage
        this.bundleForm.description = this.bundle.description
        this.bundleForm.enabled = this.bundle.enabled
        this.bundleForm.id = this.bundle.id
        this.bundleForm.label = this.bundle.label
        this.bundleForm.milestones = this?.bundle?.milestones ?? {}
        this.bundleForm.price = this.bundle.price
        this.bundleForm.prizePercentage = this.bundle.prizePercentage
        this.bundleForm.tempImage = this.bundle.tempImage
        this.image = this?.bundle?.image ?? this.bundle.tempImage
        this.formLoaded = true
    },
    methods: {
        clickCancelChanges() {
            this.bundleForm.charityPercentage = this.bundle.charityPercentage
            this.bundleForm.description = this.bundle.description
            this.bundleForm.enabled = this.bundle.enabled
            this.bundleForm.label = this.bundle.label
            this.bundleForm.milestones = this.bundle.milestones
            this.bundleForm.price = this.bundle.price
            this.bundleForm.prizePercentage = this.bundle.prizePercentage
            this.image = this.bundle.image
        },
        clickDelete() {
            if (confirm(`You wish to delete ${this.bundle.label}?`)) {
                this.$emit('deleteBundle', this.bundle.id)
            }
        },
        clickDeleteCampaignRule(inIndex) {
            let myCampaignRules = []
            
            this.bundleForm.milestones.campaignRules.forEach((myRule, index) => {
                if (index != inIndex) {
                    myCampaignRules.push(myRule)
                }
            })

            this.bundleForm.milestones.campaignRules = myCampaignRules
        },
        clickDeletePrizeRule(inIndex) {
            let myPrizeRules = []
            
            this.bundleForm.milestones.prizeRules.forEach((myRule, index) => {
                if (index != inIndex) {
                    myPrizeRules.push(myRule)
                }
            })

            this.bundleForm.milestones.prizeRules = myPrizeRules
        },
        clickNewCampaignRule() {
            let myCampaignRules = this?.bundleForm.milestones?.campaignRules ?? []
            let myNewRule = this.objCopy(this.newCampaignRule)
            myCampaignRules.push(myNewRule)
            this.bundleForm.milestones.campaignRules = myCampaignRules
        },
        clickNewPrizeRule() {
            let myPrizeRules = this?.bundleForm.milestones?.prizeRules ?? []
            let myNewRule = this.objCopy(this.newPrizeRule)
            myPrizeRules.push(myNewRule)
            this.bundleForm.milestones.prizeRules = myPrizeRules
        },
        async clickSaveBundle() {
            if (this.allOkay) {
                this.validating = false

                if (this.newImageGallery) {
                    this.image = this.newImageGallery[0]
                    let myTempGallery = await this.buildOfflineTempGallery(this.newImageGallery, `bundle_temp${this.groupBuy.id}_${this.bundleForm.id}`)
                
                    let myOtherData = {
                        bundleId: this.bundleForm.id
                    }
                    
                    setTimeout(() => {
                        this.saveGalleryPrep(myTempGallery, 'groupBuyBundleGalleryUpdate', this.groupBuy.id, 'sellerGroupBuyBundleGallery', myOtherData)
                    }, 100)
                }
                
                this.$emit('updateBundle', this.bundleForm)
                this.pageMessage('success', "Tier saved")
            }
            else {
                this.validating = true
            }
        },
        saveLocalImage(inGallery) {
            this.image = inGallery[0]
            this.newImageGallery = inGallery
        },
        updateFormValue(inField, inValue) {
            this.bundleForm[inField] = inValue
        },
        updateOfflineMedia(inGallery) {
            this.image = inGallery[0]
            this.newImageGallery = inGallery
        }
    }
}
</script>