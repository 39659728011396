<template>
    <div>
        <div class="whitespace-pre-line px-2 mb-1 text-center text-lg font-semibold" :class="{ 'bg-green-500 text-white': sale.order_status == 'delivered', 'bg-blue-600 text-white': sale.order_status == 'shipped', 'bg-blue-300 text-black': sale.order_status == 'pending', 'bg-yellow-500 edit-field-text-color': sale.order_status == 'paid', 'bg-red-500 text-white': sale.order_status == 'failed' || sale.order_status == 'unprocessed', 'bg-gray-200 text-black': sale.order_status == 'cancelled' }">
            {{ getLocalTransactionStatus(sale.order_status) }}
        </div>
        <div class="p-2 text-sm" :class="{ 'white-transparent': !whiteLabel || whiteLabelDark, 'black-transparent': whiteLabel && whiteLabelLight }">
            <slot name="buttons"></slot>
            <div v-if="gbStatus == 'current'" class="w-full my-4">
                <div class="text-base font-semibold mb-4 text-center">
                    Share with others and help reach the goal!
                </div>
                <div class="w-full">
                    <group-buy-share 
                        :customerVendor="sale.vendor" :groupBuy="groupBuy" :orangeButton="true"
                    />
                </div>
            </div>
            <div v-if="showPurchaseLink" @click="clickCopyPurchaseLink()" class="alt-button-base" :class="{'mt-2': gbStatus == 'current' }">
                Copy Customer Purchase Link
            </div>
            <div class="flex justify-between align-center mt-4">
                <div>
                    Items Total
                </div>
                <div>
                    {{ displayPrice(sale.currency, itemsTotal) }}
                </div>
            </div>
            <div v-if="sale.secondary_offer_entries" class="mt-1 w-full font-bold text-xs">
                <div class="flex justify-between align-center">
                    <div>
                        {{ groupBuy.secondary_offer_label }}
                    </div>
                    <div>
                        x{{ secondaryOfferEntries }}
                    </div>
                </div>
            </div>
            <div v-if="!sale.shipping_details.localPickup && !sale.shipping_details.digitalProduct" class="flex justify-between align-center mt-2">
                <div>
                    {{ shippingLabel }}
                </div>
                <div>
                    {{ displayPrice(sale.currency, sale.shipping) }}
                </div>
            </div>
            <div v-if="taxTotal > 0" class="flex justify-between align-center mt-2">
                <div>
                    {{ sale.vendor.data.taxLabel }} <span class="text-xs">(included)</span>
                </div>
                <div>
                    {{ displayPrice(sale.currency, taxTotal) }}
                </div>
            </div>
            <div v-else-if="taxTotal < 0" class="flex justify-between align-center mt-2">
                <div>
                    {{ sale.tax_rate_label }}
                </div>
                <div>
                    {{ displayPrice(sale.currency, taxTotal) }}
                </div>
            </div>
            <div v-for="refund in refunds" class="flex justify-between align-center font-semibold mt-2">
                <div>
                    {{ refund.description }}
                </div>
                <div>
                    {{ displayPrice(sale.currency, refund.amount) }}
                </div>
            </div>
            <div class="flex justify-between align-center font-semibold mt-2">
                <div>
                    Total
                </div>
                <div>
                    {{ displayPrice(sale.currency, sale.total - sale.refund_amount) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GroupBuyShare from '@/shared/Marketplace/GroupBuyShare'

export default {
    props: [
        'sale',
        'showPurchaseLink'
    ],
    components: {
        GroupBuyShare
    },
    computed: {
        gbStatus() {
            if (!this.groupBuy) {
                return null
            }

            if (this.groupBuy.start_time < this.currentUnixTime() && this.groupBuy.end_time > this.currentUnixTime()) {
                return "current"
            }

            if (this.groupBuy.start_time < this.currentUnixTime() && this.groupBuy.end_time < this.currentUnixTime()) {
                return "ended"
            }

            if (this.groupBuy.eoi && this.groupBuy.eoi_start_time < this.currentUnixTime() && this.groupBuy.eoi_end_time < this.currentUnixTime()) {
                return "eoi"
            }
        },
        groupBuy() {
            return this?.sale?.group_buy ?? null
        },
        itemsTotal() {
            return this.sale.total - this.sale.shipping
        },
        refunds() {
            return this?.sale?.data && this.sale.data['refunds'] ? this.sale.data['refunds'] : null
        },
        secondaryOfferEntries() {
            if (!this.sale.secondary_offer_entries) {
                return 0
            }

            return this.sale.secondary_offer_entries == 1 ? "1 Entry" : `${this.sale.secondary_offer_entries} Entries`
        },
        shippingLabel() {
            return this?.sale?.shipping_details?.shippingType ?? 'Shipping'
        },
        taxTotal() {
            let myTax = 0

            if (this.sale.shipping && this.sale.shipping_tax_percentage) {
                myTax = myTax + (this.sale.shipping / (100 + this.sale.shipping_tax_percentage) * this.sale.shipping_tax_percentage)
            }

            this.sale.sale_lines
                .forEach((line) => {
                    if (line.tax_percentage) {
                        myTax = myTax + ((line.product_price * line.product_qty) / (100 + line.tax_percentage) * line.tax_percentage)
                    }
                })

            return myTax
        }
    },
    methods: {
        clickCopyPurchaseLink() {
            let appUrl = this.sale.white_label ? (this.sale.vendor.linked_domain ? `https://${this.sale.vendor.linked_domain}/` : `https://${this.sale.vendor.sub_domain}.buildz.store/`) : this.appUrl
            let myUrl = `${appUrl}purchase/${this.sale.id}/${this.sale.customer.sale_code}`

            navigator.clipboard.writeText(myUrl)
                .then(() => {
                    this.pageMessage('success', 'Link copied to clipboard')
                })
                .catch(() => {
                    this.pageMessage('error', 'Unable to access your clipboard')
                })
        },
        getLocalTransactionStatus(inStatus) {
            let myStatus = this.getTransactionStatus(inStatus)

            if (myStatus == 'Pending' && this.sale.hold_payment_date) {
                myStatus = `Pending until ${this.getLocalUnixTime(this.sale.hold_payment_date)}`
            }

            return myStatus
        }
    }
}
</script>