<template>
    <div class="w-full">
        <select-panel
            :fieldData="yesNoData" fieldFormName="existingPlatform" fieldLabel="Do You Have an Existing Online Shop?" fieldLabelClasses="text-gray-800" 
            :fieldValue="sellerDataForm.existingPlatform" :forceMobile="true" :isLabelAndValue="true"
            @fieldChange="changeExistingPlatform"
        >
            <validation-message v-if="validating && sellerDataForm.existingPlatform == null" 
                message="Select yes or no"
            />
        </select-panel>
        <select-panel v-if="sellerDataForm.existingPlatform"
            :fieldData="shopPlatformData" fieldFormName="platform" fieldLabel="Existing Shop Platform" fieldLabelClasses="text-gray-800" 
            :fieldValue="sellerDataForm.platform" :forceMobile="true" :isReverseLabelAndValue="true"
            @fieldChange="updateSellerDataFormSelectValue"
        >
            <validation-message v-if="validating && !sellerDataForm.platform" 
                message="Existing Shop Platform must be selected"
            />
        </select-panel>
    </div>
</template>

<script>
export default {
    props: [
        'sellerDataForm',
        'validating'
    ],
    data() {
        return {
            shopPlatformData: {
                shopify: 'Shopify',
                squarespace: 'SquareSpace'
            }
        }
    },
    methods: {
        changeExistingPlatform(inField, inValue) {
            this.$emit('changeExistingPlatform', inField, inValue)
        },
        updateSellerDataFormSelectValue(inField, inValue) {
            this.$emit('updateSellerDataFormSelectValue', inField, inValue)
        }
    }
}
</script>