const blockMixins = {
    methods: {
        amIBlocking(inUserId) {
            return this.userIdsImBlocking && this.userIdsImBlocking.includes(parseInt(inUserId))
        },
        startToggleBlocking(ownerId) {
            var myUser = this.objCopy(this.loggedUser)
            var myTogglingBlocks = []

            if (this.userTogglingBlocks) {
                myTogglingBlocks = this.objCopy(this.userTogglingBlocks)
            }

            if (!myTogglingBlocks.includes(ownerId)) {
                myTogglingBlocks.push(ownerId)
            }

            if (myUser) {
                myUser.togglingBlocks = myTogglingBlocks
            }

            this.setStateData([
                { loggedUser: this.objCopy(myUser) }
            ])
        },
        stopToggleBlocking(ownerId) {
            var myUser = this.objCopy(this.loggedUser)

            if (myUser && this.userTogglingBlocks) {
                var myTogglingBlocks = this.objCopy(this.userTogglingBlocks)
                myTogglingBlocks = myTogglingBlocks.filter(block => block != ownerId)
                myUser.togglingBlocks = myTogglingBlocks

                this.setStateData([
                    { loggedUser: this.objCopy(myUser) }
                ])
            }
        },
        toggleBlockOwner(owner) {
            if (!owner) {
               return 
            }
            
            if (!this.loggedUser) {
                this.routeTo('register')
            }
            else {
                this.startToggleBlocking(parseInt(owner.id))

                if (this.amIBlocking(parseInt(owner.id))) {
                    buildzAxios?.delete(this.getRoute('userUnblock', owner.id))
                        .then((response) => {
                            if (response.status == 200) {
                                this.setStateData([
                                    { loggedUser: response.data.user }
                                ])
                            }
                            else {
                                this.pageMessage('error', 'Error unblocking user')
                            }
                        })
                        .catch((error) => {
                            this.pageMessage('error', 'Error unblocking user')
                        })
                        .then(() => {
                            this.stopToggleBlocking(parseInt(owner.id))
                        })
                }
                else {
                    buildzAxios?.post(this.getRoute('userBlock', owner.id))
                        .then((response) => {
                            if (response.status == 200) {
                                if (response.data.message == "success") {
                                    this.setStateData([
                                        { loggedUser: response.data.user }
                                    ])
                                    
                                    this.pageMessage('success', 'User blocked')
                                }
                                else {
                                    this.pageMessage('error', response.data.message)
                                }
                            }
                            else {
                                this.pageMessage('error', 'Error blocking user')
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            this.pageMessage('error', 'Error blocking user')
                        })
                        .then(() => {
                            this.stopToggleBlocking(parseInt(owner.id))
                        })
                }
            }
        },
    }
}

export default blockMixins