<template>
    <div class="relative cursor-pointer mx-2 px-2" v-if="feed" @click="clickOpenNotifies">
        <div>
            Notifications
        </div>
        <div v-if="unseen > 0" class="red-bubble w-4 -top-1 -right-1">
            {{ unseen }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            gettingRecords: false
        }
    },
    computed: {
        feed() {
            return this.feeds && this.feeds.notifications ? this.feeds.notifications : null
        }
    },
    mounted() {
        this.getFeed()
    },
    methods: {
        clickOpenNotifies() {
            this.openNotifications()
        },
        async getFeed() {
            if (!this.streamClient || (this.loggedUser && this.streamClient?.userId != this.loggedUserId)) {

                this.setStateData([
                    { streamClient: null }
                ])

                await this.initClient()
            }

            if (this.streamClient) {
                const myFeedId = await this.initNotificationFeed()

                if (myFeedId) {
                    this.getRecords()
                }
            }
            else {
                // this.pageMessage('error', 'Error inititalising stream client')
            }
        },
        async getRecords() {
            const feedData = await this.getActivities(this.feed, null, 1)

            if (feedData && feedData.results) {
                this.setStateData([
                    { unseen: feedData.unseen }
                ])
            }
        },
        async initNotificationFeed() {
            const myFeed = await this.streamClient?.feed('notification', this.loggedUserId)

            if (myFeed) {
                if (!this.subscribedFeeds.includes(myFeed.id)) {
                    myFeed.subscribe(this.subscribeNewNotificationFeedData).then(this.subscribeSuccess(myFeed.id), this.subscribeFail)
                    let mySubscribedFeeds = this.objCopy(this.subscribedFeeds)
                    mySubscribedFeeds.push(myFeed.id)

                    this.replaceStateDataArray([
                        { subscribedFeeds: mySubscribedFeeds }
                    ])
                }

                if (!this.feeds) {
                    this.setStateData([
                        { feeds: { notifications: myFeed } }
                    ])
                }
                else {
                    this.setStateData([
                        { feeds: { ...this.feeds, notifications: myFeed } }
                    ])
                }

                return myFeed.id
            }
            else {
                this.pageMessage('error', 'Error retrieving feed')
            }
        },
        async subscribeNewNotificationFeedData(data) {
            let myResults = await this.getActivities(this.feed, 0, 1)

            if (myResults?.unseen) {
                this.setStateData([
                    { unseen: myResults.unseen }
                ])
            }
        }
    }
}
</script>