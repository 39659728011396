<template>
    <router-link :to="eventUrl">
        <div v-if="eventImage" class="mb-3">
            <img @load="srcLoaded = true" :src="transformUrl(eventImage, ['optimize', 'md'])" class="object-cover" :class="{ 'w-0 h-0': !showImage, 'w-full h-full': showImage }" loading="lazy"/>
        </div>
        <div class="px-5 pb-3">
            <div v-html="eventData.title">
            </div>
            <div class="text-xs text-orange-400">
                {{ originDomain }}
            </div>
            <div v-if="eventData.description" v-html="nlToBr(eventData.description)" class="mt-1 text-xs line-clamp-6">
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
        eventData: null
    },
    data() {
        return {
            showImage: false,
            srcLoaded: false
        }
    },
    computed: {
        eventImage() {
            return this.eventData.images[0].image ? this.eventData.images[0].image : this.eventData.images[0].secure_url
        },
        eventId() {
            return this.eventData.url.split('/')[4] ? this.eventData.url.split('/')[4] : null
        },
        eventUrl() {
            return this.eventId ? `/event/${this.eventId}` : this.eventData.url
        },
        originDomain() {
            return this.eventData.url.split('/')[2]
        }
    },
    watch: {
        srcLoaded: {
            handler(val) {
                if (val) {
                    this.showImage = true
                }
            }
        }
    }
}
</script>