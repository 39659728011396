<template>
    <modal-container v-if="showEditListingModal && product"
        :altImage="productImage" :openConditions="showEditListingModal"
        @closeModal="closeEditListingModal"
    >
        <template #main>
            <div class="w-full mt-4 px-1">
                <h3 class="section-heading mb-3 text-center">
                    Edit Listing
                </h3>
                <div v-if="formLoaded" class="w-full">
                    <div class="w-full mb-1">
                        <div class="w-full">
                            <input @input="saveDebounce()" placeholder="Title" type="text" v-model="variantForm.title" class="form-input edit-field-text-color bg-white py-1 w-full text-center" />
                        </div>
                        <validation-message v-if="!variantForm.title" 
                            message="Title must be entered"
                        />
                    </div>
                    <panel-section
                        :adminSection="true" sectionLabel="Details"
                    >
                        <edit-details
                            :pGallery="gallery" :pVariantForm="variantForm" 
                            @saveLocalGallery="saveLocalGallery" @updateDetails="updateDetails" @updateOfflineMedia="updateOfflineMedia"
                        />
                    </panel-section>
                    <panel-section
                        :adminSection="true" sectionLabel="Detail Blocks"
                    >
                        <media-blocks
                            :product="product" :pVariantForm="variantForm" 
                            @updateMediaBlocks="updateMediaBlocks"
                        />
                    </panel-section>
                    <panel-section
                        :adminSection="true" sectionLabel="Options"
                    >
                        <edit-options
                            :pVariantForm="variantForm" 
                            @updateOptions="updateOptions"
                        />
                    </panel-section>
                    <panel-section
                        :adminSection="true" sectionLabel="Pricing & Stock"
                    >
                        <edit-pricing
                            :pVariantForm="variantForm" 
                            @updatePricing="updatePricing"
                        />
                    </panel-section>
                    <panel-section 
                        :adminSection="true" sectionLabel="Bulk Discounts"
                    >
                        <bulk-discounts
                            :product="variantForm" :productBulkDiscounts="bulkDiscounts" 
                            @updateBulkDiscounts="updateBulkDiscounts"
                        />
                    </panel-section>
                    <panel-section v-if="variantForm.has_variants && sellerVendor.product_variants"
                        :adminSection="true" sectionLabel="Product Variants"
                    >
                        <product-variants
                            :product="variantForm" :productVariants="productVariants" 
                            @updateVariants="updateVariants"
                        />
                    </panel-section>
                    <panel-section v-if="variantForm.has_add_ons && sellerVendor.product_add_ons"
                        :adminSection="true" sectionLabel="Product Add Ons"
                    >
                        <product-add-ons
                            :product="variantForm" :productAddOns="productAddOns" 
                            @updateAddOns="updateAddOns"
                        />
                    </panel-section>
                    <panel-section 
                        :adminSection="true" sectionLabel="Create A New Discount Code"
                    >
                        <new-discount-code 
                            :discountCodes="discountCodes"
                            @clickCreateNewDiscountCode="clickCreateNewDiscountCode"
                        />
                    </panel-section>
                    <panel-section v-if="discountCodes"
                        :adminSection="true" sectionLabel="Discount Codes"
                    >
                        <discount-codes
                            :discountCodes="discountCodes" :refreshCodes="refreshCodes"
                            @updateDiscountCodes="updateDiscountCodes"
                        />
                    </panel-section>
                    <div v-if="uploadedProduct" class="mt-2 font-semibold text-center text-orange-400">
                        Shopify status - {{ product.shopify_status }}
                    </div>
                    <div v-if="uploadedProduct && !allowImportedEdit" class="w-full mt-4" :class="{ 'desktop-button-box justify-end': isDesktopWidth }">
                        <div @click="allowImportedEdit = true" class="my-2 alt-button-base">
                            Edit Imported Fields
                        </div>
                        <div class="w-full text-center">
                            Any imported data you edit will be overwritten on next data import.
                        </div>
                    </div>
                    <div class="w-full">
                        <div class="w-full white-transparent mt-2 py-4 px-2 mb-20" :class="{ 'desktop-button-box justify-center': isDesktopWidth }">
                            <div @click="clickCopy()" class="alt-button-base">
                                Copy
                            </div>
                            <div @click="closeEditListingModal()" class="standard-button-base" :class="{ 'ml-2': isDesktopWidth, 'mt-2': !isDesktopWidth }">
                                Close
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </modal-container>
</template>

<script>
import {debounce} from 'lodash'

import BulkDiscounts from './edit-listing/components/BulkDiscounts'
import DiscountCodes from '@/shared/DiscountCodes/DiscountCodes'
import EditDetails from './edit-listing/components/EditDetails'
import EditOptions from './edit-listing/components/EditOptions'
import EditPricing from './edit-listing/components/EditPricing'
import MediaBlocks from './edit-listing/components/MediaBlocks'
import NewDiscountCode from '@/shared/DiscountCodes/NewDiscountCode'
import ProductAddOns from './edit-listing/components/ProductAddOns'
import ProductVariants from './edit-listing/components/ProductVariants'

export default {
    components: {
        BulkDiscounts,
        DiscountCodes,
        EditDetails,
        EditOptions,
        EditPricing,
        MediaBlocks,
        NewDiscountCode,
        ProductAddOns,
        ProductVariants
    },
    data() {
        return {
            allowImportedEdit: false,
            formLoaded: false,
            gallery: [],
            productId: null,
            refreshCodes: false,
            tempGallery: [],
            variantForm: {
                body: null,
                buildz_group_id: null,
                bulk_discounts: null,
                compare_at_price: null,
                currency: null,
                digital_product: false,
                disabled: false,
                discount_codes: null,
                fitment_data: null,
                free_shipping: false,
                grams: null,
                group_buy_only: false,
                has_add_ons: false,
                has_variants: false,
                item_condition: null,
                media_blocks: null,
                min_order_qty: 1,
                price: null,
                product_add_ons: null,
                product_group_id: null,
                product_variants: null,
                qty: null,
                sku: null,
                tax_rate_id: null,
                title: null,
                track_quantity: false,
                units: false,
                white_label_only: false
            }
        }
    },
    computed: {
        bulkDiscounts() {
            return this.variantForm.bulk_discounts
        },
        discountCodes() {
            return this.variantForm.discount_codes
        },
        product() {
            return this.productToEdit
        },
        productAddOns() {
            return this.variantForm.product_add_ons
        },
        productImage() {
            return this?.product?.gallery && this.product.gallery.length ? this.product.gallery[0].url : null
        },
        productVariants() {
            return this.variantForm.product_variants
        },
        uploadedProduct() {
            return this?.product?.product_type == 'uploaded' ?? false
        }
    },
    watch: {
        product: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.variantForm.body = this.product.body
                    this.variantForm.buildz_group_id = this.product.buildz_group_id
                    this.variantForm.bulk_discounts = this.product.bulk_discounts
                    this.variantForm.compare_at_price = this.product.compare_at_price
                    this.variantForm.currency = this.sellerVendorData.defaultCurrency
                    this.variantForm.digital_product = this?.product?.digital_product ?? false
                    this.variantForm.disabled = this?.product?.disabled ?? false
                    this.variantForm.discount_codes = this?.product?.discount_codes ?? null
                    this.variantForm.fitment_data = this.product.fitment_data
                    this.variantForm.free_shipping = this?.product?.free_shipping ?? false
                    this.variantForm.grams = this.product.grams ?? 0
                    this.variantForm.group_buy_only = this?.product?.group_buy_only ?? false
                    this.variantForm.has_add_ons = this?.product?.has_add_ons ?? false
                    this.variantForm.has_variants = this?.product?.has_variants ?? false
                    this.variantForm.item_condition = this?.product?.item_condition ?? "New"
                    this.variantForm.media_blocks = this.product.media_blocks
                    this.variantForm.min_order_qty = this.product.min_order_qty ?? 1
                    this.variantForm.price = this.product.price.amount
                    this.variantForm.product_add_ons = this.product.product_add_ons
                    this.variantForm.product_group_id = this.product.product_group_id
                    this.variantForm.product_variants = this.product.product_variants
                    this.variantForm.qty = this.product.qty ?? 0
                    this.variantForm.sku = this.product.sku
                    this.variantForm.tax_rate_id = this.product.tax_rate_id
                    this.variantForm.title = this.product.title
                    this.variantForm.track_quantity = this.product.track_quantity ?? false
                    this.variantForm.units = this.product.units
                    this.variantForm.white_label_only = this?.product?.white_label_only ?? false   
                    this.gallery = this.product.gallery
                    this.formLoaded = true
                }
            }
        }
    },
    mounted() {
        this.productId = this.productToEdit.id
    },
    beforeUnmount() {
        this.save()
    },
    methods: {
        clickCopy() {
            if (confirm("You wish to make a copy of this listing?")) {
                this.openSpinner()
            
                buildzAxios?.post(this.getRoute('sellerProductCopy', this.productId))
                    .then((response) => {
                        let myRecord = response.data.newProduct
                        let myRecords = this.objCopy(this.vendorProducts)
                        myRecords = { ...myRecords, ...{myRecord} }

                        this.setStateData([
                            { vendorProducts: myRecords }
                        ])

                        this.pageMessage('success', 'Listing created')
                        this.closeSpinner()
                        this.closeEditListingModal()
                        this.openEditListingModal(myRecord)
                    })
                    .catch((error) => {
                        console.log(error)
                        this.closeSpinner()
                        this.pageMessage('error', 'Error creating listing')
                    })
            }
        },
        clickCreateNewDiscountCode(inForm) {
            let myNewCode = {
                label: inForm.label,
                percentage: inForm.percentage,
                enabled: inForm.enabled,
                code: inForm.code,
                multiple: inForm.multiple
            }

            if (!this.variantForm.discount_codes) {
                this.variantForm.discount_codes = []
            }

            this.variantForm.discount_codes.push(myNewCode)
            this.save()
            this.refreshCodes = true

            setTimeout(() => {
                this.refreshCodes = false
            }, 500)
        },
        save() {
            if (this.productId) {
                this.variantForm.gallery = this.gallery

                if (this.variantForm.min_order_qty < 1) {
                    this.variantForm.min_order_qty = 1
                }

                this.saveNewProduct(this.productId, this.variantForm)
            }
        },
        saveDebounce: debounce(function() {
            this.save()
        }, 2000),
        async saveLocalGallery(inGallery) {
            this.gallery = inGallery
            await this.postGallery('sellerProductGallery', inGallery, this.product.id)
        },
        updateAddOns(inForm, inSaveImmediate) {
            this.variantForm.product_add_ons = inForm
            
            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateBulkDiscounts(inForm, inSaveImmediate) {
            this.variantForm.bulk_discounts = inForm
            
            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateDetails(inForm, inSaveImmediate) {
            this.variantForm.body = inForm.body
            this.variantForm.buildz_group_id = inForm.buildz_group_id
            this.variantForm.product_group_id = inForm.product_group_id
            this.variantForm.grams = inForm.grams
            this.variantForm.fitment_data = inForm.fitment_data
            this.variantForm.sku = inForm.sku

            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateDiscountCodes(inForm, inSaveImmediate) {
            this.variantForm.discount_codes = inForm.codes
            
            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateFormSelectValue(inField, inValue) {
            this.variantForm[inField] = inValue
            this.save()
        },
        updateFormValue(inField, inValue) {
            this.variantForm[inField] = inValue
            this.saveDebounce()
        },
        async updateOfflineMedia(inGallery) {
            this.gallery = inGallery
            let myTempGallery = await this.buildOfflineTempGallery(inGallery, `listing_temp${this.product.id}`)
            this.tempGallery = this.objCopy(myTempGallery)

            setTimeout(() => {
                this.saveGalleryPrep(this.tempGallery, 'listingGalleryUpdate', this.product.id, 'sellerProductGallery')
            }, 100)
        },
        updateMediaBlocks(inForm, inSaveImmediate) {
            this.variantForm.media_blocks = inForm.media_blocks

            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateOptions(inForm, inSaveImmediate) {
            this.variantForm.digital_product = inForm.digital_product
            this.variantForm.disabled = inForm.disabled
            this.variantForm.free_shipping = inForm.free_shipping
            this.variantForm.group_buy_only = inForm.group_buy_only
            this.variantForm.has_add_ons = inForm.has_add_ons
            this.variantForm.has_variants = inForm.has_variants
            this.variantForm.item_condition = inForm.item_condition
            this.variantForm.white_label_only = inForm.white_label_only

            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updatePricing(inForm, inSaveImmediate) {
            this.variantForm.compare_at_price = inForm.compare_at_price
            this.variantForm.min_order_qty = inForm.min_order_qty
            this.variantForm.price = inForm.price
            this.variantForm.qty = inForm.qty
            this.variantForm.tax_rate_id = inForm.tax_rate_id
            this.variantForm.track_quantity = inForm.track_quantity
            this.variantForm.units = inForm.units
            
            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        },
        updateVariants(inForm, inSaveImmediate) {
            this.variantForm.product_variants = inForm
            
            if (inSaveImmediate) {
                this.save()
            }
            else {
                this.saveDebounce()
            }
        }
    }
}
</script>