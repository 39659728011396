<template>
    <modal v-if="showNewGroupModal" class="text-white" :open="showNewGroupModal" @close="closeNewGroupModal()">
        <modal-background-image
            :bgImage="defaultHero"
        />
        <div class="absolute top-0 left-0 w-full h-full" :class="{ 'px-1': windowWidth < desktopMaxWidth, 'desktop-modal-box': windowWidth >= desktopMaxWidth }">
            <div :class="{ 'desktop-modal': windowWidth >= desktopMaxWidth }">
                <div class="w-full mt-4">
                    <h3 class="section-heading mb-3 text-center">
                        {{ formHeading }}
                    </h3>
                    <div>
                        <label class="text-sm font-bold leading-5">
                            Name
                        </label>
                        <div>
                            <input type="text" v-model="form.name" class="edit-field-text-color bg-white py-1 w-full form-input" placeholder="Community name" />
                        </div>
                    </div>
                    <div class="mt-2">
                        <label class="text-sm font-bold leading-5">
                            Categories
                        </label>
                        <div class="round-tabs-container justify-center">
                            <template v-for="(category, index) in sortedCategories" :key="index">
                                <div v-if="category.parent == 0 || isParentOpen(category)" @click="clickCategory(category)" class="round-tab" :class="{ 'round-tab-off': isNonSelectedParent(category), 'round-tab-on': isSelectedParent(category), 'round-tab-child-off': isNonSelectedChild(category), 'round-tab-child-on': isSelectedChild(category) }">
                                    {{ category.label }}
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="mt-6 pb-8 sm:pb-0 sm:flex sm:flex-row-reverse">
                        <div @click="closeNewGroupModal()" class="alt-button-base sm:mr-2 mb-2">
                            Cancel
                        </div>
                        <div v-show="form.name" @click="createGroup()" class="standard-button-base mb-2 sm:mr-2">
                            {{ saveButtonText }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="postingNewGroup" class="absolute top-0 left-0 flex items-center justify-center w-full h-full text-orange-400">
                <fa-icon icon="spinner" type="fas" class="spin w-14 h-14" />
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/shared/Modal'
import ModalBackgroundImage from '@/shared/ModalBackgroundImage'

export default {
    components: {
        Modal,
        ModalBackgroundImage
    },
    data() {
        return {
            categoriesData: [],
            form: {
                name: this.groupEditForm?.name,
                selectedBuildTypes: [],
                selectedSubCategories: []
            },
            postingNewGroup: false
        }
    },
    computed: {
        formHeading() {
            return this.groupEditForm ? 'Edit Community' : 'Create Community'
        },
        saveButtonText() {
            return this.groupEditForm ? 'Save' : 'Create'
        },
        sortedCategories() {
            return this.categoriesData.sort((a, b) => a.parent > b.parent ? 1 : (a.parent < b.parent ? 0 : (a.label < b.label ? 1 : 0)))
        }
    },
    mounted() {
        this.buildCategoriesData()
        this.resetForm()
    },
    methods: {
        buildCategoriesData() {
            if (this.categoriesData.length == 0) {
                Object.values(this.buildTypes).forEach((buildType) => {
                    let myRecord = {
                        parent: 0,
                        label: buildType.label,
                        id: buildType.id
                    }

                    this.categoriesData.push(myRecord)

                    buildType.sub_categories.forEach((subCat) => {
                        let myRecord = {
                            parent: buildType.id,
                            label: subCat,
                            id: null
                        }

                        this.categoriesData.push(myRecord)
                    })
                })
            }
        },
        clickCategory(inCategory) {
            if (inCategory.parent == 0) {
                if (this.form.selectedBuildTypes.includes(inCategory.id)) {
                    this.form.selectedBuildTypes = []
                    this.form.selectedSubCategories = []
                }
                else {
                    this.form.selectedBuildTypes = []
                    this.form.selectedSubCategories = []
                    this.form.selectedBuildTypes.push(inCategory.id)
                }
            }
            else {
                if (this.form.selectedSubCategories.includes(inCategory.label)) {
                    let mySubCats = this.objCopy(this.form.selectedSubCategories)
                    mySubCats = mySubCats.filter((subCat) => subCat != inCategory.label)
                    this.form.selectedSubCategories = this.objCopy(mySubCats)
                }
                else {
                    this.form.selectedSubCategories.push(inCategory.label)
                }
            }
        },
        createGroup() {
            this.postingNewGroup = true
           
            if (!this.groupEditForm) {
                this.postingNewGroup = true

                buildzAxios?.post(this.getRoute('communityStore'), this.form)
                    .then((response) => {
                        if (response.status == 200 && response.data.group_handle) {
                            this.setStateData([
                                { currentGroupName: null },
                                { currentGroupId: null },
                                { currentGroupHandle: null }
                            ])
                            
                            this.postingNewGroup = false
                            this.closeNewGroupModal()
                            this.routeTo(`/community/${response.data.group_handle}`)
                        }
                        else {
                            this.pageMessage('error', 'Error creating community')
                        }
                    })
                    .catch((error) => {
                        this.pageMessage('error', 'Error creating community')
                    })
            }
            else {
                if (this.editingGroup) {
                    buildzAxios?.put(this.getRoute('communityUpdate', this.editingGroup.id), this.form)
                        .then((response) => {
                            if (response.status == 200 && response.data.group) {
                                let myGroup = this.objCopy(response.data.group)
                                this.publishUpdatedGroup(myGroup)
                                this.setStorage(`group_${this.editingGroup.id}`, JSON.stringify(response.data.group))
                                this.postingNewGroup = false
                                this.closeNewGroupModal()
                            }
                            else {
                                this.pageMessage('error', 'Error updating community')
                            }
                        })
                        .catch((error) => {
                            this.pageMessage('error', 'Error updating community')
                        })
                }
            }

        },
        isNonSelectedChild(inCategory) {
            return inCategory.parent != 0 && !this.form.selectedSubCategories.includes(inCategory.label)
        },
        isNonSelectedParent(inCategory) {
            return inCategory.parent == 0 && !this.form.selectedBuildTypes.includes(inCategory.id)
        },
        isParentOpen(inSubCat) {
            return this.form.selectedBuildTypes.includes(inSubCat.parent)
        },
        isSelectedChild(inCategory) {
            return inCategory.parent != 0 && this.form.selectedSubCategories.includes(inCategory.label)
        },
        isSelectedParent(inCategory) {
            return inCategory.parent == 0 && this.form.selectedBuildTypes.includes(inCategory.id)
        },
        resetForm() {
            if (this.groupEditForm) {
                this.form = this.groupEditForm
            }
            else {
                this.form.name = null
            }

            this.postingNewGroup = false
        }
    }
}
</script>