<template>
    <div v-if="formLoaded" class="w-full">
        <select-panel 
            :fieldData="yesNoData" fieldLabel="Has Variants" fieldFormName="has_variants" :fieldValue="variantForm.has_variants"
            :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel 
            :fieldData="yesNoData" fieldLabel="Has Add Ons" fieldFormName="has_add_ons" :fieldValue="variantForm.has_add_ons"
            :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel 
            :fieldData="itemConditionData" fieldFormName="item_condition" fieldLabel="Item Condition" :fieldRequired="true" 
            :fieldValue="variantForm.item_condition" :isSingleValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel 
            :fieldData="yesNoData" fieldFormName="free_shipping" fieldLabel="Free Shipping" :fieldValue="variantForm.free_shipping"
            :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel 
            :fieldData="yesNoData" fieldLabel="Digital Product" fieldFormName="digital_product" :fieldValue="variantForm.digital_product"
            :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel 
            :fieldData="yesNoData" :fieldLabel="`${pageData.groupBuy.adminLabelSingular} Only`" fieldFormName="group_buy_only" :fieldRequired="true"
            :fieldValue="variantForm.group_buy_only" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel v-if="sellerVendor.white_label"
            :fieldData="yesNoData" fieldFormName="white_label_only" fieldLabel="White Label Only" :fieldRequired="true" 
            :fieldValue="variantForm.white_label_only" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel 
            :fieldData="noYesData" fieldFormName="disabled" fieldLabel="Enabled" :fieldValue="variantForm.disabled"
            :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
    </div>
</template>

<script>
export default {
    props: [
        'pVariantForm'
    ],
    data() {
        return {
            formLoaded: false,
            itemConditionData: [
                'New', 
                'Used'
            ],
            variantForm: {
                digital_product: null,
                disabled: false,
                free_shipping: null,
                group_buy_only: null,
                has_add_ons: false,
                has_variants: false,
                item_condition: null,
                white_label_only: null
            }
        }
    },
    mounted() {
        this.variantForm.digital_product = this.pVariantForm.digital_product
        this.variantForm.disabled = this.pVariantForm.disabled
        this.variantForm.free_shipping = this.pVariantForm.free_shipping
        this.variantForm.group_buy_only = this.pVariantForm.group_buy_only
        this.variantForm.has_add_ons = this.pVariantForm.has_add_ons
        this.variantForm.has_variants = this.pVariantForm.has_variants
        this.variantForm.item_condition = this.pVariantForm.item_condition
        this.variantForm.white_label_only = this.pVariantForm.white_label_only
        this.formLoaded = true
    },
    methods: {
        updateFormSelectValue(inField, inValue) {
            this.variantForm[inField] = inValue
            this.$emit('updateOptions', this.variantForm, true)
        }
    }
}
</script>