const galleryMixins = {
    methods: {
        async saveGalleryPrep(inGallery, inQueueName, inId, inRouteName, inOtherData) {
            if (inRouteName) {
                await this.postGallery(inRouteName, inGallery, inId, inOtherData)
            }
                
            if (inGallery.length) {
                let myPostGalleryData = await this.buildOfflinePostGallery(this.objCopy(inGallery))
                let myGallery = this.objCopy(myPostGalleryData.gallery)

                await this.addToQueue(inQueueName, {
                    id: inId,
                    gallery: myGallery,
                    otherData: inOtherData,
                    wait: true,
                    waitQueueId: myPostGalleryData.queueIds
                })
            }
        },
        async postGallery(inRouteName, inGallery, inId, inOtherData) {
            let promise = new Promise((resolve, reject) => {
                this.saveStart()

                buildzAxios?.post(this.getRoute(inRouteName, inId), 
                    {
                        vendor_id: inId,
                        gallery: inGallery,
                        from_queue: false,
                        otherData: inOtherData
                    }
                )
                .then((response) => {
                    this.saveDone()
                    resolve(response)
                })
                .catch((error) => {
                    console.log(error)
                    this.saveDone()
                    this.pageMessage('error', 'Error updating Gallery')
                    reject(error)
                })
            })

            var myReturn = false

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {
                    myReturn = e
                })
        
            return myReturn
        }
    }
}

export default galleryMixins
