<template>
    <div v-if="mainMenuOpen" class="overlay-menu-base bottom-0">
        <div class="absolute top-4 left-2 w-full iphoneMarginTop flex items-start justify-start">
            <div class="mr-3 text-orange-400 cursor-pointer" @click="closeMainMenu()">
                <fa-icon icon="xmark" type="fas" class="orphan-icon h-8 w-8" />
            </div>
            <div class="">
                <cached-image imageClasses="block w-auto h-8" :imageSrc="bzLogo" />
            </div>
        </div>
        <div v-if="currentAppVersion" class="absolute top-4 right-4 iphoneMarginTop" :class="{ 'mr-4': isIOSApp }">
            <div class="mx-auto max-main-width text-xs">
                {{ currentAppVersion }}
            </div>
        </div>
        <div class="absolute left-0 items-start w-full iphoneMarginBottom" :class="{ 'top-12': !isIOSApp, 'bottom-2': isIOSApp }">
            <div class="mx-auto max-main-width">
                <div class="inline-flex flex-col justify-end pl-1">
                    <template v-for="(menuLine, index) in sortedMenu" :key="index">
                        <div v-if="isMenuLineOkay(menuLine)" class="mt-4" :class="menuLine.class">
                            <div @click="clickMenuOption(menuLine)" class="inline-flex items-center cursor-pointer">
                                <div :class="{ 'overlay-menu-icon-box': clickedMenu != menuLine.name, 'orphan-icon-box': clickedMenu == menuLine.name }">
                                    <fa-icon :icon="menuLine.icon" :type="menuLine.iconType" class="overlay-menu-icon" :class="menuLine.iconClass" />
                                </div>
                                <div class="ml-2 flex flex-col">
                                    <div>
                                        {{ getLabel(menuLine) }}
                                    </div>
                                    <div v-if="menuLine.description" class="font-semibold text-xs">
                                        {{ menuLine.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            clickedMenu: null,
            currentScrollTop: 0,
            vcoConfig: {
                handler: this.closeMainMenu,
                middleware: this.clickOutsideCheck,
                events: ['dblclick', 'click'],
                isActive: true,
                detectIFrame: true,
                capture: false
            }
        }
    },
    computed: {
        sortedMenu() {
            return Object.values(this.menu).sort((a, b) => a.sort < b.sort ? -1 : 1)
        }
    },
    watch: {
        keyEscape: {
            handler(val) {
                if (val && this.popupCheck('mainMenu')) {
                    this.setStateData([
                        { keyEscape: false }
                    ])

                    this.closeMainMenu()
                }
            }
        },
        mainMenuOpen: {
            handler(val) {
                if (val) {
                    this.clickedMenu = null
                }
            }
        }
    },
    mounted() {
        this.popupIn('mainMenu')

        this.setStateData([
            { keyEscape: false }
        ])

        if (this.popups.length == 1) {
            this.currentScrollTop = this.scrollTop
                        
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant'
            })
            
            document.body.classList.remove("overflow-y-scroll")
            document.body.classList.add("overflow-y-hidden")
            document.body.style.marginTop = -(this.currentScrollTop) + "px"
        }
    },
    beforeUnmount() {
        this.popupOut('mainMenu')

        if (this.popups.length == 0) {
            document.body.classList.remove("overflow-y-hidden")
            document.body.classList.add("overflow-y-scroll")
            document.body.style.marginTop = "0px"
            
            window.scrollTo({
                top: this.currentScrollTop,
                left: 0,
                behavior: 'instant'
            })
        }
    },
    methods: {
        clickMenuOption(inMenuLine) {
            this.clickedMenu = inMenuLine.name

            if (inMenuLine.flasher) {
                this.setFlasher(inMenuLine.flasher)
            }

            setTimeout(() => {
                if (inMenuLine.name == 'signout') {
                    localStorage.removeItem("token")
        
                    this.setStateData([
                        { isLoggedIn: false },
                        { loggedUser: null },
                        { streamToken: null }
                    ])

                    this.$router.push({ name: "Logout" })
                }
                else if (inMenuLine.route) {
                    if (inMenuLine.name == "event") {
                        this.routeTo(`/event/${this.currentEventId}`)
                    }
                    else if (inMenuLine.name == "community") {
                        this.routeTo(`/community/${this.currentGroupHandle}`)
                    }
                    else {
                        if (!this.serverConnected && inMenuLine.name == 'chat') {
                            this.pageMessage('warning', 'You are currently not connected to the internet, chat is unavailable')
                        }
                        else {
                            this.$router.push({ name: inMenuLine.route })
                        }
                    }
                }

                this.setSelectedMenu(inMenuLine.name)

                if (inMenuLine.name == 'help') {
                    if (!this.serverConnected) {
                        this.pageMessage('warning', 'You are currently not connected to the internet, help is unavailable')
                    }
                    else {
                        this.routeTo(`/chat/check/1`)
                    }

                    setTimeout(() => {
                        this.closeMainMenu()
                    }, 100)
                }
                else {
                    if (!this.serverConnected && inMenuLine.name != 'chat') {
                        this.pageMessage('warning', 'You are currently not connected to the internet, data might be missing or incomplete')
                    }

                    this.closeMainMenu()
                }
            }, 100)
        },
        clickOutsideCheck(event) {
            if (this.mainMenuOpen) {
                event.preventDefault()
                return true
            }
        },
        getLabel(inMenuLine) {
            if (inMenuLine.name == "event") {
                return this.currentEventName
            }
            else if (inMenuLine.name == "community") {
                return this.currentGroupName
            }
            
            return inMenuLine.label
        },
        isMenuLineOkay(inMenuLine) {
            let isOkay = true

            if (inMenuLine.name == "events" && this.currentEventId) {
                isOkay = false
            }
            else if (inMenuLine.name == "event" && !this.currentEventId) {
                isOkay = false
            }
            else if (inMenuLine.name == "communities" && this.currentGroupId) {
                isOkay = false
            }
            else if (inMenuLine.name == "community" && !this.currentGroupId) {
                isOkay = false
            }
            else if (inMenuLine.name == "seller" && !this.hasStripeAccount) {
                isOkay = false
            }
            else if (inMenuLine.name == "becomeSeller" && this.hasStripeAccount) {
                isOkay = false
            }
            else if (inMenuLine.name == "impersonateUser") {
                isOkay = false
            }
            
            if (inMenuLine.name == "impersonateUser" && (this.userBuildzStaff || this.impersonatingUser)) {
                isOkay = true
            }

            return isOkay
        }
    }
}
</script>