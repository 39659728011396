<template>
    <div v-if="formLoaded" class="w-full">
        <input-panel 
            fieldFormName="sku" fieldLabel="SKU" fieldPlaceholder="SKU" fieldType="text" :fieldValue="theVariantForm.sku"
            @fieldInput="updateFormValue"
        />
        <input-panel 
            fieldFormName="calculatedPrice" fieldLabel="Calculated Price" :fieldNonEdit="true" fieldPlaceholder="Calculated Price"
            fieldSubLabel="This price is the base price plus variant adjustments" fieldType="number" :fieldValue="calculatedPrice" 
        />
        <input-panel 
            fieldFormName="overridePrice" fieldLabel="Override Price" fieldPlaceholder="Override Price" fieldType="number" 
            :fieldValue="theVariantForm.overridePrice" 
            @fieldInput="updateFormValue"
        />
        <input-panel v-if="product.track_quantity"
            fieldFormName="qty" fieldLabel="Quantity in Stock" fieldPlaceholder="Quantity in Stock" fieldType="number" 
            :fieldValue="theVariantForm.qty" 
            @fieldInput="updateFormValue"
        />
        <select-panel 
            :fieldData="yesNoData" fieldLabel="Enabled" fieldFormName="enabled" :fieldRequired="true"
            :fieldValue="theVariantForm.enabled" :isLabelAndValue="true"
            @fieldChange="updateFormValue"
        />
        <div class="w-full white-transparent mt-px p-2">
            <div @click="clickSaveTheVariant()" class="w-full" :class=" { 'standard-button-base': allOkay, 'disabled-button-base': !allOkay } ">
                Save
            </div>
            <div v-if="isChanges" @click="clickCancelChanges()" class="mt-2 w-full alt-button-base">
                Cancel Changes
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'product',
        'theVariant',
        'theVariantPrice',
        'theVariantLabel'
    ],
    data() {
        return {
            calculatedPrice: null,
            theVariantForm: {
                enabled: true,
                identifier: null,
                overridePrice: null,
                qty: null,
                sku: null,
                variants: null
            },
            formLoaded: false,
            validating: false
        }
    },
    computed: {
        allOkay() {
            return true
        },
        isChanges() {
            return Boolean(this.theVariantForm.overridePrice != this.theVariant.overridePrice || this.theVariantForm.qty != this.theVariant.qty|| this.theVariantForm.enabled != this.theVariant.enabled)
        }
    },
    mounted() {
        this.theVariantForm.enabled = this.theVariant.enabled
        this.theVariantForm.identifier = this.theVariant.identifier
        this.theVariantForm.overridePrice = this.theVariant.overridePrice
        this.theVariantForm.qty = this.theVariant.qty
        this.theVariantForm.sku = this?.theVariant?.sku ?? null
        this.theVariantForm.variants = this.theVariant.variants
        this.calculatedPrice = this.fixProductPrice(this.sellerVendorData.defaultCurrency, this.theVariantPrice)
        this.formLoaded = true
    },
    methods: {
        clickCancelChanges() {
            this.theVariantForm.overridePrice = this.theVariant.overridePrice
            this.theVariantForm.qty = this.theVariant.qty
        },
        clickSaveTheVariant() {
            if (this.allOkay) {
                this.validating = false
                this.$emit('updateTheVariant', this.theVariantForm, true)
                this.pageMessage('success', `'${this.theVariantLabel}' saved`)
            }
            else {
                this.validating = true
            }
        },
        updateFormValue(inField, inValue) {
            this.theVariantForm[inField] = inValue
        }
    }
}
</script>