const repostMixins = {
    methods: {
        startRepostingActivity(inActivityId) {
            var myUser = this.objCopy(this.loggedUser)
            var myRepostingAcivities = []

            if (this.userRepostingActivities) {
                myRepostingAcivities = this.objCopy(this.userRepostingActivities)
            }

            if (!myRepostingAcivities.includes(inActivityId)) {
                myRepostingAcivities.push(inActivityId)
            }

            myUser.repostingActivities = myRepostingAcivities
            
            this.setStateData([
                { loggedUser: myUser }
            ])
        },
        stopRepostingActivity(inActivityId) {
            var myUser = this.objCopy(this.loggedUser)

            if (this.userRepostingActivities) {
                var myRepostingAcivities = this.objCopy(this.userRepostingActivities)
                myRepostingAcivities = myRepostingAcivities.filter(repost => repost != inActivityId)
                myUser.repostingActivities = myRepostingAcivities
                
                this.setStateData([
                    { loggedUser: myUser }
                ])
            }
        },
        repost(inActivity) {
            if (!this.userRepostingActivities || !this.userRepostingActivities.includes(inActivity.id)) {
                this.startRepostingActivity(inActivity)
                
                this.streamClient.reactions.add(
                    'repost',
                    inActivity,
                    {},
                    { targetFeeds: [`user:${this.loggedUserId}`] }
                )
                .then((response) => {
                    Object.entries(this.feedActivities).forEach(([feedId, activities]) => {
                        let myActivities = this.objCopy(activities)

                        myActivities = myActivities.map((activity) => {
                            if (activity.id == inActivity.id) {
                                activity.own_reactions = { ...activity.own_reactions, repost: [response] }

                                if (activity?.reaction_counts.repost) {
                                    ++activity.reaction_counts.repost
                                }
                                else {
                                    activity.reaction_counts = { ...activity?.reaction_counts, repost: 1 }
                                }
                            }

                            return activity
                        })

                        this.updateActivities(myActivities, feedId)
                    })
                })
                .catch((error) => {
                    this.pageMessage('error', 'Error re-posting')
                })
                .then(() => {
                    this.stopRepostingActivity(inActivity.id)
                })
            }
        }
    }
}

export default repostMixins