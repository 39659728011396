import axios from 'axios'
import { string } from 'yup'

const nftPortMixins = {
    computed: {
        nftApp() {
            return this.loggedUser && (this?.loggedUser?.settings?.nft_user ?? false) && (this?.loggedUser?.settings?.nft_app ?? false)
        },
        nftUser() {
            return this.loggedUser && (this?.loggedUser?.settings?.nft_user ?? false)
        }
    },
    methods: {
        nftAccountsRetrieveContracts(inAddress, inType, inContinuation = null, inChain = 'polygon') {
            nftAxios?.get(`v0/accounts/contracts/${inAddress}`, {
                params: {
                    chain: inChain,
                    continuation: inContinuation,
                    type: inType
                }
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft retrieve account contracts error')
                console.log(error)
            })
        },
        nftAccountsRetrieveCreated(inAddress, inContinuation = null, inInclude = [], inChain = 'polygon') {
            nftAxios?.get(`v0/accounts/creators/${inAddress}`, {
                params: {
                    chain: inChain,
                    continuation: inContinuation,
                    include: inInclude
                }
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft retrieve account created error')
                console.log(error)
            })
        },
        nftAccountsRetrieveOwned(inAddress, inContinuation = null, inContractAddress = null, inInclude = [], inExclude = [], inChain = 'polygon') {
            nftAxios?.get(`v0/accounts/${inAddress}`, {
                params: {
                    chain: inChain,
                    contract_address: inContractAddress,
                    continuation: inContinuation,
                    include: inInclude,
                    exclude: inExclude
                }
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft retrieve account owned error')
                console.log(error)
            })
        },
        nftBatchBurn(inContractAddress, inTokens, inChain = 'polygon') {
            nftAxios?.delete(`v0/mints/customizable/batch/${inTransactionHash}`, {
                data: {
                    chain: inChain,
                    contract_address: inContractAddress,
                    tokens: inTokens
                }
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft burn batch error')
                console.log(error)
            })

            // {
            //     token_id: string,
            //     quantity: integer
            // }
        },
        nftBatchMint(inContractAddress, inTokens, inChain = 'polygon') {
            nftAxios?.post('v0/mints/customizable/batch',
                {
                    chain: inChain,
                    contract_address: inContractAddress,
                    tokens: inTokens
                }
            )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft batch mint error')
                console.log(error)
            })

            // {
            //     mint_to_address: string,
            //     token_id: string,
            //     metadata_uri: string,
            //     quantity: integer
            // }
        },
        nftBatchRetrieve(inTransactionHash, inChain = 'polygon') {
            nftAxios?.get(`v0/mints/batch/${inTransactionHash}`, {
                params: {
                    chain: inChain
                }
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft retrieve batch error')
                console.log(error)
            })
        },
        nftBatchTransfer(inContractAddress, inTokens, inChain = 'polygon') {
            nftAxios?.post('v0/mints/transfers/batch',
                {
                    chain: inChain,
                    contract_address: inContractAddress,
                    tokens: inTokens
                }
            )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft batch transfer error')
                console.log(error)
            })

            // {
            //     transfer_to_address: string,
            //     token_id: string,
            //     quantity: integer
            // }
        },
        nftContractRetrieve(inTransactionHash, inChain = 'polygon') {
            nftAxios?.get(`v0/contracts/${inTransactionHash}`, {
                params: {
                    chain: inChain
                }
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft retrieve contract error')
                console.log(error)
            })
        },
        nftNftsRetrieveAll(inContinuation = null, inInclude = [], inChain = 'polygon') {
            nftAxios?.get(`v0/nfts`, {
                params: {
                    chain: inChain,
                    continuation: inContinuation,
                    include: inInclude
                }
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft retrieve all nfts error')
                console.log(error)
            })
        },
        nftNftsRetrieveContract(inContractAddress, inPageNumber = 1, inInclude = [], inRefreshMetadata = false, inChain = 'polygon') {
            nftAxios?.get(`v0/nfts/${inContractAddress}`, {
                params: {
                    chain: inChain,
                    include: inInclude,
                    refresh_metadata: inRefreshMetadata,
                    page_number: inPageNumber
                }
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft retrieve contract nfts error')
                console.log(error)
            })
        },
        nftNftsRetrieveNFT(inContractAddress, inTokenId, inRefreshMetadata = false, inChain = 'polygon') {
            nftAxios?.get(`v0/nfts/${inContractAddress}/${inTokenId}`, {
                params: {
                    chain: inChain,
                    refresh_metadata: inRefreshMetadata
                }
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft retrieve nft error')
                console.log(error)
            })
        },
        nftPortInit() {
            window.nftAxios = axios.create({
                baseURL: "https://api.nftport.xyz/",
                headers: {
                    Authorization: 'b3a2e742-9388-4771-bd25-7fd571f1e9c5',
                    'Content-Type': 'multipart/form-data'
                }
            })
        },
        nftTokenBurnMint(inContractAddress, inTokenId, inChain = 'polygon') {
            nftAxios?.delete('v0/mints/customizable', {
                data: {
                    chain: inChain,
                    contract_address: inContractAddress,
                    token_id: inTokenId
                }
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft burn mint error')
                console.log(error)
            })
        },
        nftTokenCustomizableMint(inContractAddress, inMetadataUri, inMintToAddress, inTokenId = null, inChain = 'polygon') {
            nftAxios?.post('v0/mints/customizable',
                {
                    chain: inChain,
                    contract_address: inContractAddress,
                    metadata_uri: inMetadataUri,
                    mint_to_address: inMintToAddress,
                    token_id: inTokenId
                }
            )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft custom mint error')
                console.log(error)
            })
        },
        nftTokenEasyMintFile(inName, inDescription, inMintToAddress, inFileUrl, inChain = 'polygon') {
            this.getFileFromUrl(inFileUrl, (responseFile) => {
                if (responseFile) {
                    let bodyFormData = new FormData()
                    bodyFormData.append('file', responseFile)

                    nftAxios?.post('v0/mints/easy/files?' + new URLSearchParams(
                        {
                            chain: inChain,
                            name: inName,
                            description: inDescription,
                            mint_to_address: inMintToAddress
                        }), bodyFormData
                    )
                    .then((response) => {
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log('nft easy mint file error')
                        console.log(error)
                    })
                }
            })
        },
        nftTokenEasyMintUrl(inName, inDescription, inMintToAddress, inIpfsUrl, inChain = 'polygon') {
            nftAxios?.post('v0/mints/easy/urls',
                {
                    chain: inChain,
                    name: inName,
                    description: inDescription,
                    mint_to_address: inMintToAddress,
                    file_url: inIpfsUrl
                }
            )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft easy mint url error')
                console.log(error)
            })
        },
        nftTokenRetrieve(inTransactionHash, inChain = 'polygon') {
            nftAxios?.get(`v0/mints/${inTransactionHash}`, {
                params: {
                    chain: inChain
                }
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft retrieve mint error')
                console.log(error)
            })
        },
        nftTokenTransferMint(inContractAddress, inTokenId, inTransferToAddress, inChain = 'polygon') {
            nftAxios?.post('v0/mints/transfers',
                {
                    chain: inChain,
                    contract_address: inContractAddress,
                    token_id: inTokenId,
                    transfer_to_address: inTransferToAddress
                }
            )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft transfer mint error')
                console.log(error)
            })
        },
        nftTokenUpdateMint(inContractAddress, inMetadataUri, inTokenId, inFreezeMetadata = false, inChain = 'polygon') {
            nftAxios?.put('v0/mints/customizable',
                {
                    chain: inChain,
                    contract_address: inContractAddress,
                    token_id: inTokenId,
                    metadata_uri: inMetadataUri,
                    freeze_metadata: inFreezeMetadata
                }
            )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft update mint error')
                console.log(error)
            })
        },
        async nftUploadDirectoryToIpfs() {
            let myFolder = 'https://buildz.pro/temp/'

            let myFiles = [
                '1.json',
                '2.json'
            ]

            let promise = new Promise((resolve, reject) => {
                let bodyFormData = new FormData()
                let myCount = 0

                for (const fileUrl of myFiles) {
                    this.getFileFromUrl(myFolder + fileUrl, (responseFile) => {
                        if (responseFile) {
                            myCount++
                            bodyFormData.append('metadata_files', responseFile, fileUrl)

                            if (myCount == myFiles.length) {
                                resolve(bodyFormData)
                            }
                        }
                    })
                }
            })

            var myReturn = null

            await promise
                .then((result) => {
                    myReturn = result

                    nftAxios?.post('v0/metadata/directory', myReturn)
                        .then((response) => {
                            console.log(response)
                        })
                        .catch((error) => {
                            console.log('nft upload directory error')
                            console.log(error)
                        })
                })
                .catch((e) => {

                })
        },
        async nftUploadFilesToIpfs(inFileUrls) {
            let myReturnArray = []

            for (const fileUrl of inFileUrls) {
                const ipfsUrl = await this.nftUploadSingleFileToIpfs(fileUrl)

                if (ipfsUrl) {
                    myReturnArray.push(ipfsUrl)
                }
            }

            console.log(myReturnArray)

            return myReturnArray
        },
        nftUploadMetadataToIpfs(inDataObject) {
            nftAxios?.post('v0/metadata', {
                data: inDataObject
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft upload metadata error')
                console.log(error)
            })
        },
        async nftUploadSingleFileToIpfs(inFileUrl) {
            let promise = new Promise((resolve, reject) => {
                this.getFileFromUrl(inFileUrl, (responseFile) => {
                    if (responseFile) {
                        let bodyFormData = new FormData()
                        bodyFormData.append('file', responseFile)

                        nftAxios?.post('v0/files', bodyFormData)
                            .then((response) => {
                                console.log(response)

                                if (response?.data?.ipfs_url) {
                                    resolve(response.data.ipfs_url)
                                }

                                reject(false)
                            })
                            .catch((error) => {
                                console.log('nft upload file error')
                                console.log(error)
                                reject(false)
                            })
                    }
                })
            })

            var myReturn = null

            await promise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })

            return myReturn
        },
        nftUserDeployedCollectionContracts(inChain = 'polygon') {
            nftAxios?.get('v0/me/contracts/collections', {
                params: {
                    chain: inChain
                }
            })
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log('nft view collection contracts error')
                    console.log(error)
                })
        },
        nftUserDeployedContracts() {
            nftAxios?.get('v0/me/contracts')
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log('nft view contracts error')
                    console.log(error)
                })
        },
        nftUserGetContractABI(inContractAddress, inChain = 'polygon') {
            nftAxios?.get(`v0/me/contracts/abis/${inContractAddress}`, {
                params: {
                    chain: inChain
                }
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft get contract ABI error')
                console.log(error)
            })
        },
        nftUserIpfsUploads(inType = 'all', inStartDate, inEndDate, inPageNumber = 1, inChain = 'polygon') {
            nftAxios?.get('v0/me/storage', {
                params: {
                    chain: inChain,
                    start_date: inStartDate,
                    end_date: inEndDate,
                    page_number: inPageNumber,
                    type: inType
                }
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft get ipft uploads error')
                console.log(error)
            })
        },
        nftUserMintedNfts(inPageNumber = 1, inChain = 'polygon') {
            nftAxios?.get('v0/me/mints', {
                params: {
                    chain: inChain,
                    page_number: inPageNumber
                }
            })
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log('nft get mints error')
                console.log(error)
            })
        },
        nftUserSettings() {
            nftAxios?.get('v0/me/settings')
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log('nft view settings error')
                    console.log(error)
                })
        }
    }
}

export default nftPortMixins