<template>
    <div class="flex flex-wrap items-center justify-between w-full py-2 px-1">
        <div class="cursor-pointer flex items-center justify-between w-full lg:w-2/3 text-sm">
            <div class="flex items-center justify-start pr-1" style="width: 59%;">
                <div class="pr-2 w-1/2">
                    <div class="flex items-center justify-start">
                        <div v-if="sellerVendorWhiteLabelData && sale.white_label" class="rounded-full h-10 w-10 mr-2" style="min-width: 2.5rem;">
                            <img :src="sellerVendorWhiteLabelData.logo" class="rounded-full h-full w-full object-cover" />
                        </div>
                        <div v-else-if="sellerVendorWhiteLabelData && !sale.white_label" class="rounded-full h-10 w-10 mr-2" style="min-width: 2.5rem;">
                            <img :src="buildzLogo" class="rounded-full h-full w-full object-cover" />
                        </div>
                        <div>
                            {{ getLocalUnixTime(sale.create_time) }}
                        </div>
                    </div>
                </div>
                <div class="text-center w-1/2">
                    {{ customer.name }}
                </div>
            </div>
            <div class="flex items-center justify-start" style="width: 41%;">
                <div class="text-center" style="width: 60%;">
                    <div class="whitespace-pre-line px-2 rounded-full" :class="{ 'bg-green-500 text-white': sale.order_status == 'delivered', 'bg-blue-600 text-white': sale.order_status == 'shipped', 'bg-blue-300 text-black': sale.order_status == 'pending', 'bg-yellow-500 edit-field-text-color': sale.order_status == 'paid', 'bg-red-500 text-white': sale.order_status == 'failed' || sale.order_status == 'unprocessed', 'bg-gray-200 text-black': sale.order_status == 'cancelled' }">
                        {{ getLocalTransactionStatus(sale.order_status) }} 
                    </div>
                </div>
                <div class="whitespace-nowrap pl-2" style="width: 40%;">
                    {{ displayPrice(sale.currency, sale.total - sale.refund_amount) }}
                </div>
            </div>
        </div>
        <div class="flex items-center ml-auto justify-end pr-2 pt-2 lg:pt-0">
            <div v-show="sale.group_buy && needsGroupBuyRefund" @click="clickGroupBuyRefund()" class="ml-4">
                <fa-icon icon="money-bill-transfer" type="fas" class="h-6 w-6 cursor-pointer" />
            </div>
            <div v-show="sale.order_status != 'shipped' && sale.order_status != 'delivered' && sale.order_status != 'cancelled'" @click="clickCancel()" class="ml-4">
                <fa-icon icon="ban" type="fas" class="h-6 w-6 cursor-pointer" />
            </div>
            <div v-show="sale.order_status == 'paid'" @click="clickShipped()" class="ml-4">
                <fa-icon icon="truck" type="fas" class="h-6 w-6 cursor-pointer" />
            </div>
            <div v-show="sale.order_status == 'shipped'" @click="clickDelivered()" class="ml-4">
                <fa-icon icon="truck-ramp-box" type="fas" class="h-6 w-6 cursor-pointer" />
            </div>
            <div @click="clickLink()" class="ml-4">
                <fa-icon icon="eye" type="fas" class="h-6 w-6 cursor-pointer" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'index',
        'needsGroupBuyRefund',
        'sale'
    ],
    computed: {
        customer() {
            return this.sale.customer ?? null
        },
        product() {
            return this.sale.product ?? null
        },
        vendor() {
            return this.sale.vendor ?? null
        }
    },
    methods: {
        clickCancel() {
            this.$emit('clickCancel', this.sale.id)
        },
        clickDelivered() {
            this.$emit('clickDelivered', this.sale.id)
        },
        clickGroupBuyRefund() {
            this.$emit('clickGroupBuyRefund', this.sale.id)
        },
        clickLink() {
            this.$emit('showSaleCard', this.sale.id)
        },
        clickShipped() {
            this.$emit('clickShipped', this.sale.id)
        },
        getLocalTransactionStatus(inStatus) {
            let myStatus = this.getTransactionStatus(inStatus)

            if (myStatus == 'Pending' && this.sale.hold_payment_date) {
                myStatus = `${this.getLocalUnixTime(this.sale.hold_payment_date)}`
            }

            return myStatus
        }
    }
}
</script>