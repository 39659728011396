<template>
    <div v-if="formLoaded" class="w-full">
        <div class="w-full white-transparent mt-px py-2">
            <div class="flex flex-wrap justify-center items-center mx-2 text-sm">
                <div class="flex items-center mx-2 my-2">
                    <input type="checkbox" v-model="view.failed">
                    <div class="ml-1">Payment failed</div>
                </div>
                <div class="flex items-center mx-2 my-2">
                    <input type="checkbox" v-model="view.pending">
                    <div class="ml-1">Payment pending</div>
                </div>
                <div class="flex items-center mx-2 my-2">
                    <input type="checkbox" v-model="view.paid">
                    <div class="ml-1">Paid</div>
                </div>
                <div class="flex items-center mx-2 my-2">
                    <input type="checkbox" v-model="view.needsRefund">
                    <div class="ml-1">Needs group refund</div>
                </div>
                <div class="flex items-center mx-2 my-2">
                    <input type="checkbox" v-model="view.cancelled">
                    <div class="ml-1">Cancelled</div>
                </div>
                <div class="flex items-center mx-2 my-2">
                    <input type="checkbox" v-model="view.shipped">
                    <div class="ml-1">Shipped</div>
                </div>
                <div class="flex items-center mx-2 my-2">
                    <input type="checkbox" v-model="view.delivered">
                    <div class="ml-1">Delivered</div>
                </div>
            </div>
        </div>
        <div>
            <template v-for="(sale, index) in sortedSales" :key="index">
                <div v-if="showSale(sale)" class="w-full white-transparent mt-px">
                    <sale-list-line v-if="!saleCardOpen(sale.id)"
                        :index="index" :needsGroupBuyRefund="needsGroupBuyRefund(sale)" :refundAmount="refundAmount(sale)" :sale="sale" 
                        @clickCancel="clickCancel" @clickDelivered="clickDelivered" @clickShipped="clickShipped" @showSaleCard="showSaleCard"
                    />
                    <sale-list-card v-else
                        :needsGroupBuyRefund="needsGroupBuyRefund(sale)" :refundAmount="refundAmount(sale)" :sale="sale" 
                        @clickCancel="clickCancel" @clickDelivered="clickDelivered" @clickGroupBuyRefund="clickGroupBuyRefund" 
                        @clickSendPaymentReminder="clickSendPaymentReminder" @clickShipped="clickShipped" @clickUpdateProcessDate="clickUpdateProcessDate" 
                        @closeSaleCard="closeSaleCard" @showSaleCard="showSaleCard"
                    />
                </div>
            </template>
            <div v-if="gettingMore" class="py-3 mt-px w-full flex justify-center white-transparent">
                <fa-icon icon="spinner" type="fas" class="spin w-10 h-10 text-white" />
            </div>
        </div>
    </div>
</template>

<script>
import {debounce} from 'lodash'

import SaleListCard from '@/shared/Marketplace/SaleListCard'
import SaleListLine from '@/shared/Marketplace/SaleListLine'

export default {
    props: [
        'groupBuy',
        'modalScrollTop'
    ],
    components: {
        SaleListCard,
        SaleListLine
    },
    data() {
        return {
            formLoaded: false,
            gettingMore: false,
            lastPage: false,
            sales: null,
            salesPage: 1,
            showCard: [],
            view: {
                cancelled: true,
                delivered: true,
                failed: true,
                needsRefund: true,
                paid: true,
                pending: true,
                shipped: true
            }
        }
    },
    computed: {
        sortedSales() {
            if (!this.sales) {
                return []
            }

            return this.sales
                .sort((a, b) => a.create_time > b.create_time ? -1 : 1)
        },
        viewCancelled() {
            return this.view.cancelled
        },
        viewDelivered() {
            return this.view.delivered
        },
        viewFailed() {
            return this.view.failed
        },
        viewNeedsRefund() {
            return this.view.needsRefund
        },
        viewPaid() {
            return this.view.paid
        },
        viewPending() {
            return this.view.pending
        },
        viewShipped() {
            return this.view.shipped
        }
    },
    watch: {
        modalScrollTop: {
            handler(val) {
                if (!this.lastPage && !this.gettingMore && document.body.scrollHeight - val < (window.innerHeight * 3)) {
                    this.getSales()
                }
            }
        },
        viewCancelled: {
            handler(val) {
                this.getSalesDebounce()
            }
        },
        viewDelivered: {
            handler(val) {
                this.getSalesDebounce()
            }
        },
        viewPaid: {
            handler(val) {
                this.getSalesDebounce()
            }
        },
        viewPending: {
            handler(val) {
                this.getSalesDebounce()
            }
        },
        viewShipped: {
            handler(val) {
                this.getSalesDebounce()
            }
        }
    },
    mounted() {
        this.getSales()
        this.formLoaded = true
    },
    methods: {
        clickCancel(inSaleId) {
            if (confirm("You want to cancel this order?")) {
                buildzAxios?.post(this.getRoute('sellerUpdateSaleStatus', inSaleId), {
                    status: 'cancelled'
                })
                .then((response) => {
                    this.updateSale(response?.data?.sale ?? null)
                })
                .catch((error) => {
                    console.log('error updating sale')
                    console.log(error)
                })
            }
        },
        clickDelivered(inSaleId) {
            if (confirm("You want to mark this order as delivered?")) {
                buildzAxios?.post(this.getRoute('sellerUpdateSaleStatus', inSaleId), {
                    status: 'delivered'
                })
                .then((response) => {
                    this.updateSale(response?.data?.sale ?? null)
                })
                .catch((error) => {
                    console.log('error updating sale')
                    console.log(error)
                })
            }
        },
        clickGroupBuyRefund(inSale) {
            if (confirm(`You want to refund ${this.refundAmount(inSale)}?`)) {
                let myLinesTotal = 0
                let myDiscount = 0

                inSale.group_buy.quantity_levels
                    .sort((a, b) => a.discount > b.discount ? 1 : -1)
                    .forEach((qLevel) => {
                        if (inSale.group_buy.current_qty >= qLevel.qty) {
                            myDiscount = qLevel.discount
                        }
                    })

                inSale.sale_lines.forEach((saleLine) => {
                    myLinesTotal = myLinesTotal + (saleLine.product_full_price * saleLine.product_qty)
                })

                let refundAmount = (inSale.total - inSale.shipping - inSale.refund_amount) - (myLinesTotal - (myDiscount / 100 * myLinesTotal))

                buildzAxios?.post(this.getRoute('sellerSaleProcessRefund', inSale.id), {
                    refund_amount: refundAmount,
                    refund_description: "Group Offer bonus refund",
                    group_offer: true
                })
                .then((response) => {
                    if (response.data.message == 'success') {
                        this.updateSale(response?.data?.sale ?? null)
                        this.pageMessage('success', 'Refund processed')
                    }
                    else {
                        this.pageMessage('error', 'Error processing refund')
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.pageMessage('error', 'Error processing refund')
                })
            }
        },
        clickSendPaymentReminder(inSaleId, inPayByDate) {
            inPayByDate = Number.isInteger(this.dateTimeToUnixTime(inPayByDate)) ? this.dateTimeToUnixTime(inPayByDate) : inPayByDate / 1000

            buildzAxios?.post(this.getRoute('sellerSendSalePaymentReminder', inSaleId), {
                pay_by_date: inPayByDate
            })
            .then((response) => {
                this.updateSale(response?.data?.sale ?? null)
                this.pageMessage('success', 'Payment reminder sent')
            })
            .catch((error) => {
                console.log(error)
                this.pageMessage('error', 'Error sending payment reminder')
            })
        },
        clickShipped(inSaleId) {
            if (confirm("You want to mark this order as shipped?")) {
                buildzAxios?.post(this.getRoute('sellerUpdateSaleStatus', inSaleId), {
                    status: 'shipped'
                })
                .then((response) => {
                    this.updateSale(response?.data?.sale ?? null)
                })
                .catch((error) => {
                    console.log('error updating sale')
                    console.log(error)
                })
            }
        },
        closeSaleCard(inSaleId) {
            this.showCard = this.showCard.filter((card) => card != inSaleId)
        },
        clickUpdateProcessDate(inSaleId, inProcessDate) {
            inProcessDate = Number.isInteger(this.dateTimeToUnixTime(inProcessDate)) ? this.dateTimeToUnixTime(inProcessDate) : inProcessDate / 1000

            buildzAxios?.post(this.getRoute('sellerUpdateSaleProcessDate', inSaleId), {
                process_date: inProcessDate
            })
            .then((response) => {
                this.updateSale(response?.data?.sale ?? null)
                this.pageMessage('success', 'Process Date updated')
            })
            .catch((error) => {
                console.log(error)
                this.pageMessage('error', 'Error updating process date')
            })
        }, 
        async getSales() {
            this.gettingMore = true
            let result = await this.getSellerSales(this.groupBuy.id, this.salesPage, this.view)

            let mySales = this.sales ?? {}
            mySales = Object.values(mySales)
            
            let myNew = Object.values(result.sales)

            if (myNew && myNew.length) {
                this.sales = this.objCopy(mySales.concat(myNew))
                this.salesPage = result.page
            }
            else {
                this.lastPage = true
            }

            this.gettingMore = false
        },
        getSalesDebounce: debounce(function() {
            this.sales = null
            this.lastPage = false
            this.salesPage = 1
            this.getSales()
        }, 1000),
        needsGroupBuyRefund(inSale) {
            if (inSale.group_buy && inSale.order_status == 'paid' && !inSale.group_buy.no_breakpoints && inSale.group_buy.end_time < this.currentUnixTime()) {
                let myLinesTotal = 0
                let myDiscount = 0

                inSale.group_buy.quantity_levels
                    .sort((a, b) => a.discount > b.discount ? 1 : -1)
                    .forEach((qLevel) => {
                        if (inSale.group_buy.current_qty >= qLevel.qty) {
                            myDiscount = qLevel.discount
                        }
                    })

                inSale.sale_lines.forEach((saleLine) => {
                    myLinesTotal = myLinesTotal + (saleLine.product_full_price * saleLine.product_qty)
                })

                return myDiscount && (myLinesTotal - (myDiscount / 100 * myLinesTotal)) != inSale.total - inSale.shipping - inSale.refund_amount
            }
        },
        refundAmount(inSale) {
            if (inSale.group_buy && inSale.order_status == 'paid' && !inSale.group_buy.no_breakpoints && inSale.group_buy.end_time < this.currentUnixTime()) {
                let myLinesTotal = 0
                let myDiscount = 0

                inSale.group_buy.quantity_levels
                    .sort((a, b) => a.discount > b.discount ? 1 : -1)
                    .forEach((qLevel) => {
                        if (inSale.group_buy.current_qty >= qLevel.qty) {
                            myDiscount = qLevel.discount
                        }
                    })

                inSale.sale_lines.forEach((saleLine) => {
                    myLinesTotal = myLinesTotal + (saleLine.product_full_price * saleLine.product_qty)
                })

                return myDiscount ? this.displayPrice(inSale.currency, (inSale.total - inSale.shipping - inSale.refund_amount) - (myLinesTotal - (myDiscount / 100 * myLinesTotal))) : null
            }

            return null
        },
        saleCardOpen(inSaleId) {
            return this.showCard.includes(inSaleId)
        },
        showSale(inSale) {
            if (this.needsGroupBuyRefund(inSale) && this.view.needsRefund) {
                return true
            }

            if (inSale.order_status == 'failed' && this.view.failed) {
                return true
            }
            if (inSale.order_status == 'pending' && this.view.pending) {
                return true
            }

            if (inSale.order_status == 'paid' && this.view.paid) {
                return true
            }

            if (inSale.order_status == 'cancelled' && this.view.cancelled) {
                return true
            }

            if (inSale.order_status == 'shipped' && this.view.shipped) {
                return true
            }
            
            if (inSale.order_status == 'delivered' && this.view.delivered) {
                return true
            }
        },
        showSaleCard(inSaleId) {
            this.showCard.push(inSaleId)
        },
        updateSale(inSale) {
            if (inSale) {
                let myIndex = this.sales.findIndex((mySale) => mySale.id == inSale.id)
                this.sales[myIndex] = inSale
            }
        }
    }
}
</script>