<template>
    <div v-if="openConditions" class="fixed top-0 left-0 w-full h-screen z-40 black-blur-3">
        <div class="w-full h-full text-center">
            <slot name="main"></slot>
        </div>
        <div @click.stop="$emit('closeWizard')" class="top-2 right-2 cursor-pointer rounded-full p-1 text-white" :class="{ 'absolute': windowWidth < desktopMaxWidth, 'fixed': windowWidth >= desktopMaxWidth }" style="background: rgba(0, 0, 0, 0.40);">
            <fa-icon icon="xmark" type="fas" class="w-6 h-6" />
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'openConditions'
    ]
}
</script>