<template>
    <div v-if="!hideOfferConditions(groupBuy)" class="text-xs p-2 text-center" :class="{ 'mt-4': !noTopMargin, 'white-transparent': !whiteLabel || whiteLabelDark, 'black-transparent': whiteLabel && whiteLabelLight }">
        <div v-if="!hideOfferConditionsOne(groupBuy)" v-html="offerConditionsTextLineOne(groupBuy)"></div>
        <div v-if="!hideOfferConditionsTwo(groupBuy)" v-html="offerConditionsTextLineTwo(groupBuy)" class="mt-2"></div>
        <div v-if="showShipping" class="mt-2">
            Prices displayed are in {{ currency }} and are tax inclusive.
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'currency', 
            'groupBuy', 
            'noTopMargin',
            'showShipping'
        ]
    }
</script>