<template>
    <div v-if="formLoaded" class="w-full">
        <div v-if="groupBuy.overtime" class="w-full white-transparent mt-px px-2 py-3 font-semibold">
            <div v-if="groupBuy.original_end_time < currentUnixTime()">
                Currently in overtime as of {{ getGroupBuyTime(groupBuy.original_end_time) }}
            </div>
            <div v-else>
                Overtime begins at {{ getGroupBuyTime(groupBuy.original_end_time) }}
            </div>
        </div>
        <date-picker-panel
            :fieldDisplayValue="groupBuyForm.end_time" fieldFormName="end_time" fieldLabel="New End Time" :fieldValue="dates.end_time" 
            @fieldChange="updateFormDateValue"
        >
            <validation-message v-if="validating && !dates.end_time" 
                message="New End Time must be selected"
            />
            <validation-message v-if="validating && !endTimeOkay" 
                message="New End Time must be greater than existing End Time"
            />
        </date-picker-panel>
        <input-panel v-if="!groupBuy.no_maximum"
            fieldFormName="max_quantity" fieldLabel="New Maximum Quantity" fieldPlaceholder="New Maximum Quantity" fieldType="number" 
            :fieldValue="groupBuyForm.max_quantity"
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && groupBuyForm.max_quantity < 1" 
                message="New Maximum Quantity must be greater than zero"
            />
            <validation-message v-if="validating && groupBuyForm.max_quantity < groupBuy.max_quantity" 
                message="New Maximum Quantity must be greater than or equal to existing Maximum Quantity"
            />
        </input-panel>
        <div v-if="!groupBuy.disabled" class="w-full white-transparent mt-px py-4 px-2" :class="{ 'desktop-button-box justify-center': isDesktopWidth }">
            <div @click="clickSetOvertime()" :class="{ 'standard-button-base': overtimeFormOkay, 'disabled-button-base': !overtimeFormOkay }">
                Set Overtime
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'groupBuy',
        'pEndTime'
    ],
    data() {
        return {
            dates: {
                end_time: null
            },
            formLoaded: false,
            groupBuyForm: {
                end_time: null,
                max_quantity: 1
            },
            validating: false
        }
    },
    computed: {
        endTime() {
            return this.dates.end_time
        },
        endTimeOkay() {
            let myDate = Number.isInteger(this.dateTimeToUnixTime(this.groupBuyForm.end_time)) ? this.dateTimeToUnixTime(this.groupBuyForm.end_time) : this.groupBuyForm.end_time / 1000
            return myDate > this.groupBuy.end_time
        },
        overtimeFormOkay() {
            return this.endTimeOkay && (this.groupBuy.no_maximum || this.groupBuyForm.max_quantity >= this.groupBuy.max_quantity)
        }
    },
    mounted() {
        this.dates.end_time = this.pEndTime
        this.groupBuyForm.end_time = this.groupBuy.end_time
        this.groupBuyForm.max_quantity = this.groupBuy.max_quantity != null ? this.groupBuy.max_quantity : 1
        this.formLoaded = true
    },
    methods: {
        clickSetOvertime() {
            if (this.overtimeFormOkay) {
                this.validating = false

                if (confirm("You wish to set this Group Offer into overtime?")) {
                    this.groupBuyForm.end_time = Number.isInteger(this.dateTimeToUnixTime(this.endTime)) ? this.dateTimeToUnixTime(this.endTime) : this.endTime / 1000
                    this.saveStart()

                    buildzAxios?.post(this.getRoute('sellerGroupBuyOvertime', this.groupBuy.id), this.groupBuyForm)
                        .then((response) => {
                            if (response.data.groupBuy) {
                                let myRecord = Object.entries(this.vendorGroupBuys).find((entry) => entry[1].id == this.groupBuy.id)
                                let myRecords = this.objCopy(this.vendorGroupBuys)
                                myRecords[myRecord[0]] = this.objCopy(response.data.groupBuy)

                                this.setStateData([
                                    { vendorGroupBuys: myRecords }
                                ])

                                this.$emit('getGroupBuy')

                                this.pageMessage('success', 'Group Offer overtime period set')
                            }
                            else {
                                this.pageMessage('error', 'Unable to put Group Offer into overtime')
                            }

                            this.saveDone()
                        })
                        .catch((error) => {
                            console.log(error)
                            this.saveDone()
                            this.pageMessage('error', 'Error updating Group Offer')
                        })
                }
            }
            else {
                this.validating = true
            }
        },
        updateFormDateValue(inField, inValue) {
            let originalValue = Number.isInteger(this.dateTimeToUnixTime(this.dates[inField])) ? this.dateTimeToUnixTime(this.dates[inField]) : this.dates[inField] / 1000
            let newValue = Number.isInteger(this.dateTimeToUnixTime(inValue)) ? this.dateTimeToUnixTime(inValue) : inValue / 1000

            if (originalValue != newValue) {
                this.dates[inField] = inValue
                this.groupBuyForm[inField] = inValue
            }
        },
        updateFormValue(inField, inValue) {
            if (this.groupBuyForm[inField] != inValue) {
                this.groupBuyForm[inField] = inValue
            }
        }
    }
}
</script>