<template>
    <div v-if="formLoaded" class="w-full">
        <input-panel 
            fieldFormName="code" fieldLabel="Code" fieldPlaceholder="Code" 
            :fieldRequired="true" fieldType="text" :fieldValue="codeForm.code"
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(codeForm.code)" 
                message="Code must be entered"
            />
            <validation-message v-if="validating && duplicateCode && duplicateCode == codeForm.code" 
                message="Duplicate code"
            />
        </input-panel>
        <input-panel 
            fieldFormName="label" fieldLabel="Label" fieldPlaceholder="Label" 
            :fieldRequired="true" fieldType="text" :fieldValue="codeForm.label"
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(codeForm.label)" 
                message="Label must be entered"
            />
        </input-panel>
        <input-panel 
            fieldFormName="percentage" fieldLabel="Discount Percentage" fieldPlaceholder="Discount Percentage" 
            :fieldRequired="true" fieldType="number" :fieldValue="codeForm.percentage"
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="validating && !Boolean(codeForm.percentage)" 
                message="Discount Percentage must be entered"
            />
            <validation-message v-else-if="validating && Boolean(codeForm.percentage) && codeForm.percentage < 0" 
                message="Discount Percentage must be greater than zero"
            />
            <validation-message v-else-if="validating && Boolean(codeForm.percentage) && codeForm.percentage > 100" 
                message="Discount Percentage cant be greater than 100"
            />
        </input-panel>
        <select-panel 
            :fieldData="yesNoData" fieldFormName="multiple" fieldLabel="Can Be Used With Other Offers?"
            :fieldValue="codeForm.multiple" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <select-panel 
            :fieldData="yesNoData" fieldFormName="enabled" fieldLabel="Enabled"
            :fieldValue="codeForm.enabled" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
        <div class="w-full white-transparent mt-px p-2">
            <div @click="clickSaveCode()" class="w-full" :class=" { 'standard-button-base': allOkay, 'disabled-button-base': !allOkay } ">
                Save
            </div>
            <div @click="clickDelete()" class="mt-2 w-full secondary-button-base">
                Delete Discount Code
            </div>
            <div v-if="isChanges" @click="clickCancelChanges()" class="mt-2 w-full alt-button-base">
                Cancel Changes
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'discountCode',
        'discountCodes'
    ],
    data() {
        return {
            codeForm: {
                code: null,
                enabled: false,
                label: null,
                multiple: false,
                percentage: null
            },
            duplicateCode: null,
            formLoaded: false,
            validating: false
        }
    },
    computed: {
        allOkay() {
            return Boolean(this.codeForm.code && this.codeForm.label && this.codeForm.percentage && this.codeForm.percentage > 0 && this.codeForm.percentage <= 100)
        },
        isChanges() {
            return Boolean(this.codeForm.code != this.discountCode.code || this.codeForm.label != this.discountCode.label || this.codeForm.percentage != this.discountCode.percentage || this.codeForm.multiple != this.discountCode.multiple || this.codeForm.enabled != this.discountCode.enabled)
        }
    },
    mounted() {
        this.codeForm.code = this.discountCode.code
        this.codeForm.enabled = this.discountCode.enabled
        this.codeForm.label = this.discountCode.label
        this.codeForm.multiple = this.discountCode.multiple
        this.codeForm.percentage = this.discountCode.percentage
        this.formLoaded = true
    },
    methods: {
        clickCancelChanges() {
            this.codeForm.code = this.discountCode.code
            this.codeForm.enabled = this.discountCode.enabled
            this.codeForm.label = this.discountCode.label
            this.codeForm.multiple = this.discountCode.multiple
            this.codeForm.percentage = this.discountCode.percentage
        },
        clickDelete() {
            if (confirm('You wish to delete this discount code?')) {
                this.$emit('deleteDiscountCode', this.discountCode.code)
            }
        },
        clickSaveCode() {
            if (this.allOkay) {
                this.validating = false
                this.duplicateCode = null
                this.codeForm.code = this.codeForm.code.toUpperCase()
                let checkCode = false

                if (this.codeForm.code != this.discountCode.code) {
                    checkCode = this.discountCodes.find((myCode) => myCode.code == this.codeForm.code)
                }

                if (checkCode) {
                    this.duplicateCode = this.codeForm.code
                    this.validating = true
                    alert("That discount code already exists")
                }
                else {
                    this.$emit('updateDiscountCode', this.codeForm, this.discountCode.code, true)
                    alert("Discount code saved")
                }
            }
            else {
                this.validating = true
            }
        },
        updateFormSelectValue(inField, inValue) {
            this.codeForm[inField] = inValue
        },
        updateFormValue(inField, inValue) {
            this.codeForm[inField] = inValue
        }
    }
}
</script>