<template>
    <modal v-if="showShareIntentModal" :open="showShareIntentModal" @close="closeShareIntentModal" class="text-white">
        <modal-background-image
            :bgImage="defaultHero"
        />
        <div v-if="preview" class="absolute top-0 left-0 w-full" :class="{ 'pb-16 px-1 pt-12': windowWidth < desktopMaxWidth, 'desktop-modal-box': windowWidth >= desktopMaxWidth }">
            <div :class="{ 'desktop-modal': windowWidth >= desktopMaxWidth }">
                <div class="max-width-box">
                    <div v-if="!activityToEdit && !shareIntentProductId" class="w-full round-tabs-container mb-4">
                        <div @click="postType = 'mod'" class="round-tab" :class="{ 'round-tab-off': postType != 'mod', 'round-tab-on': postType == 'mod' }">
                            Create a mod
                        </div>
                        <div @click="postType = 'post'" class="round-tab" :class="{ 'round-tab-off': postType != 'post', 'round-tab-on': postType == 'post' }">
                            Add to post
                        </div>
                    </div>
                    <h3 v-if="shareIntentProductId" class="section-heading mb-3 text-center">
                        Add to Build
                    </h3>
                    <div class="w-full mt-3">
                        <a v-if="!loading_meta" :href="mod.url" target="_blank" class="flex items-start">
                            <div v-if="preview.images && preview.images.length" class="mr-2 pt-1" style="width: 100px; min-width: 100px; max-width: 100px;">
                                <img :src="preview.images ? preview.images[0] : ''" class="w-full h-auto" />
                            </div>
                            <div class="text-sm font-semibold" v-html="preview.title">
                            </div>
                        </a>
                        <div v-else class="w-full h-full flex items-center justify-center text-orange-500 py-4">
                            <fa-icon icon="spinner" type="fas" class="spin h-8 w-8" />
                        </div>
                    </div>
                    <div v-if="postType == 'mod'" class="w-full mt-2">
                        <div>
                            <div class="text-sm font-bold leading-5 text-center">
                                Build
                            </div>
                            <div class="round-tabs-container mt-1 mb-4">
                                <div v-for="build in loggedUser.builds" @click="selectBuild(build.id)" class="round-tab" :class="{ 'round-tab-off': !isBuildSelected(build.id), 'round-tab-on': isBuildSelected(build.id) }">
                                    {{ build.name }}
                                </div>
                            </div>
                        </div>
                        <div class="mt-2">
                            <label class="text-sm font-bold leading-5">
                                Description
                            </label>
                            <div>
                                <textarea
                                    placeholder="Specific to your mod..."
                                    ref="myTextarea"
                                    v-model="mod.description"
                                    class="w-full p-2 text-sm bg-white border border-gray-300 rounded focus:border-orange-500 focus:ring-orange-300 edit-field-text-color"
                                    rows="5"
                                />
                            </div>
                        </div>
                        <div v-if="selectedBuild" class="mt-2">
                            <div class="text-sm font-bold leading-5 text-center">
                                List
                            </div>
                            <div class="round-tabs-container mt-1 mb-4">
                                <div v-for="list in selectedBuild.lists" @click="selectList(list.name)" class="round-tab" :class="{ 'round-tab-off': !isListSelected(list.name), 'round-tab-on': isListSelected(list.name) }">
                                    {{ list.name }}
                                </div>
                            </div>
                        </div>
                        <div v-if="selectedList" class="mt-2">
                            <div class="text-sm font-bold leading-5 text-center">
                                Group
                            </div>
                            <div class="round-tabs-container mt-1 mb-4">
                                <div v-for="cat in selectedList.categories" @click="selectGroup(cat.name)" class="round-tab" :class="{ 'round-tab-off': !isGroupSelected(cat.name), 'round-tab-on': isGroupSelected(cat.name) }">
                                    {{ cat.name }}
                                </div>
                            </div>
                        </div>
                        <div v-if="mod">
                            <div v-if="showDetail" class="mt-2">
                                <label class="text-sm font-bold leading-5">
                                    Type
                                </label>
                                <div>
                                    <input type="text" maxlength="50" v-model="mod.type" @input="typeInput" @keyup.enter="$event.target.blur()" @blur="blurSuggestModType" required class="form-input edit-field-text-color bg-white py-1 w-full" placeholder="Mod type" />
                                    <auto-suggest v-if="suggestModType" @close="suggestModType = false" index="mods_type_distinct" attribute="type" :query="mod.type" @setItem="(query) => { mod.type = query; suggestModType = false; }" />
                                </div>
                            </div>
                            <div v-if="showDetail" class="mt-2">
                                <label class="text-sm font-bold leading-5">
                                    Name
                                </label>
                                <div>
                                    <input type="text" maxlength="50" v-model="mod.name" required class="form-input edit-field-text-color bg-white py-1 w-full" placeholder="Mod name" />
                                </div>
                            </div>
                            <div v-if="showDetail" class="mt-2">
                                <label class="text-sm font-bold leading-5">
                                    Url
                                </label>
                                <div>
                                    <input type="text" maxlength="50" v-model="mod.url" class="form-input edit-field-text-color bg-white py-1 w-full" placeholder="Mod url" />
                                </div>
                            </div>
                            <div v-if="showDetail" class="mt-2">
                                <label class="text-sm font-bold leading-5">
                                    Price
                                </label>
                                <div class="flex items-center">
                                    <select v-if="!mod.currency" v-model="mod.currency" class="form-input edit-field-text-color bg-white py-1 w-full">
                                        <option v-for="(value, key) in currencies" :key="key" :value="key">{{ key }}</option>
                                    </select>
                                    <input type="number" min="0" step="any" v-model="mod.price" class="form-input edit-field-text-color bg-white py-1 mx-1" placeholder="" />
                                    <div v-if="mod.currency" class="whitespace-nowrap">
                                        {{ mod.currency }}
                                    </div>
                                    <select v-if="availabilities && availabilities.length" v-model="mod.availability" class="form-input edit-field-text-color bg-white py-1 w-full">
                                        <option v-for="(value, index) in availabilities" :key="index" :value="value">{{ value }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-6">
                                <div v-if="modAllGood" @click="clickSave" class="standard-button-base mb-2">
                                    <span class="w-full center">Create Mod</span>
                                </div>
                                <div v-if="!showDetail" @click="showDetail = !showDetail" class="alt-button-base">
                                    <span v-if="!showDetail" class="w-full center">Edit</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="w-full">
                        <div class="inline-flex mt-4">
                            <div @click="clickGoPost" class="standard-button-base">
                                <span class="w-full center">Go</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import AutoSuggest from '@/shared/AutoSuggest'
import Modal from '@/shared/Modal'
import ModalBackgroundImage from '@/shared/ModalBackgroundImage'

export default {
    components: {
        AutoSuggest,
        Modal,
        ModalBackgroundImage
    },
    data() {
        return {
            buildId: 0,
            groupName: null,
            linkOgData: null,
            listName: null,
            loading_meta: false,
            mod: null,
            newGroup: null,
            newGroupName: null,
            open: true,
            postAlso: true,
            postType: 'mod',
            preview: null,
            showDetail: false,
            suggestModName: false,
            suggestModType: false,
            urlData: {},
        }
    },
    computed: {
        availabilities() {
            return this.selectedBuild?.availabilities ?? []
        },
        modAllGood() {
            return this.buildId > 0 && this.listName && this.groupName && this.mod.name
        },
        selectedBuild() {
            return this.buildId == 0 ? null : this.loggedUser.builds.filter((build) => build.id == this.buildId)[0]
        },
        selectedList() {
            return !this.selectedBuild || !this.listName ? null : this.selectedBuild.lists.filter((list) => list.name == this.listName)[0]
        }
    },
    created() {
        this.mod = null
        this.preview = null
        this.showDetail = false
        this.postType = 'mod'
        this.getUrlPreview(this.shareIntentURL)
    },
    methods: {
        blurSuggestModType() {
            setTimeout(()=>this.suggestModType = false, 300)
        },
        clickGoPost() {
            this.openWriteActivity()

            this.setStateData([
                { showShareIntentModal: false },
                { shareIntentExternal: false }
            ])
        },
        clickSave() {
            this.openSpinner()

            let minMod = {
                id: Math.round(Math.random() * 100000000),
                mod_id: null,
                type: this.mod.type
            }
            
            buildzAxios?.post(this.getRoute('buildListAddShareMod', this.buildId), {
                list: this.listName,
                category: this.groupName,
                mod: this.mod,
                buildMod: minMod,
                external: this.shareIntentExternal,
                marketplaceId: this.shareIntentProductId
            })
            .then((response) => {
                if (response.status == 200) {
                    this.setStateData([
                        { reloadBuildId: this.buildId },
                        { loggedUser: response.data.user }
                    ])

                    let myGaItem = {
                        data: {
                            id: this.shareIntentProductId,
                            title: this.shareIntentProduct.title,
                            buildz_group_id: this.shareIntentProduct.buildz_group_id,
                            price: this.shareIntentProduct.price
                        },
                        qty: 1
                    }

                    myGaItem = this.gaBuildItemArray([myGaItem])
                    this.gaAddToWishList(this.shareIntentProduct.price.currency, this.fixProductPrice(this.shareIntentProduct.price.currency, this.shareIntentProduct.price.amount), myGaItem)

                    // this.createPost()
                    this.closeShareIntentModal()
                    this.closeSpinner()
                }
                else {
                    this.closeSpinner()
                    this.pageMessage('error', 'Error creating mod')
                }
            })
            .catch((error) => {
                this.closeSpinner()
                console.log(error)
                this.pageMessage('error', 'Error creating mod')
            })
        },
        closeShareIntentModal() {
            this.setStateData([
                { shareIntentURL: null },
                { showShareIntentModal: false },
                { shareIntentExternal: false },
                { shareIntentProductId: null },
                { shareIntentProduct: null }
            ])
        },
        clearForm() {
            
        },
        createPost() {
            if (this.postAlso && this.mod?.url && this.linkOgData) {
                let attachments = {
                    images: [],
                    og: this.linkOgData
                }
                let feeds = {}
                
                feeds['builds'] = []
                feeds['mentions'] = []
                feeds['hashtags'] = []

                let postText = `Added to Build\n\n${this.mod.url}`

                let theMeta = {
                    mod_post: true,
                    mod_url: this.mod.url
                }

                this.newActivity(postText, attachments, feeds, "full", null, ['feed'], [this.buildId], [], 'post', null, theMeta)
                this.closeSpinner()
            }
        },
        getUrlPreview(text){
            this.loading_meta = true;
            
            buildzAxios?.get(this.getRoute('modSearch'), {
                    params: {
                        'type': 'meta',
                        'url': text
                    }
                })
                .then((response)=> {
                    if (!this.preview && response.data.title) {
                        this.preview = response.data
                        this.mod = {}
                        this.mod.price = response?.data?.price ? this.getPrice(response?.data?.price) : null
                        this.mod.currency = response?.data?.currency ? this.getCurrency(response?.data?.currency) : null
                        this.mod.availability = response?.data?.availability ? this.getAvailability(response?.data?.availability) : null
                        this.mod.name = this?.preview?.title ? this.preview.title.slice(0,50) : null
                        this.mod.url = text
                        this.buildId = this?.loggedUser?.builds[0].id ?? 0

                        if (!this.mod.gallery) {
                            this.mod.gallery = []
                        }

                        let media = this?.preview?.images.length ? this.preview.images[0] : null

                        if (media){
                            this.mod.gallery.push({
                                id: null,
                                url: media
                            })
                        }
                    }
                })
                .catch((error)=> {
                    console.log(error)
                    this.status = "Could not reach URL."
                })
                .then(() =>{
                    this.loading_meta = false
                })

            buildzAxios?.post(this.getRoute('linkOg'), {'url': text})
                .then((response)=> {
                    if (response.data.metadata.og) {
                        let res = response.data.metadata
                        let resOG = response.data.metadata.og
                        let resGoogle = response.data.metadata.google
                        let myImages = resOG.images ? resOG.images : resGoogle.images
                        let myImageArray = []

                        if (myImages && myImages.length) {
                            myImageArray = [
                                {
                                    secure_url: myImages[0]
                                }
                            ]
                        }

                        let myOgData = {
                            description: resOG.description ? resOG.description : (res.description ? res.description : resGoogle.description),
                            title: resOG.title ? resOG.title : (res.title ? res.title : resGoogle.title),
                            images: myImageArray,
                            site_name: resOG.site_name,
                            type: 'website',
                            url: resOG.url,
                        }

                        if (myOgData.title || myOgData.description || myImageArray.length) {
                            this.linkOgData = myOgData
                            this.linkDataExists = true
                        }
                        else {
                            this.linkOgData = null
                        }

                        if (myOgData.url) {
                            this.mod.url = myOgData.url
                        }
                    }
                    else {
                        this.linkOgData = null
                    }
                })
                .catch((error)=> {
                    console.log(error)
                    this.linkOgData = null
                })
                .then(() => {
                    
                })
        },
        getPrice(rawPrice){
            let price = rawPrice.replace(',','')
            return parseInt(price)
        },
        getCurrency(rawCurrency){
            if(!rawCurrency) return ''
            return rawCurrency
        },
        getAvailability(rawAvailability){
            if(!rawAvailability) return 'In Stock'
            if(rawAvailability.toLowerCase().includes('out')) return 'Out of Stock'
            if(rawAvailability.toLowerCase().includes('pre')) return 'Pre-Order'
            return 'In Stock'
        },
        isBuildSelected(inBuildId) {
            return inBuildId == this.buildId
        },
        isGroupSelected(inGroupName) {
            return inGroupName == this.groupName
        },
        isListSelected(inListName) {
            return inListName == this.listName
        },
        selectBuild(inBuildId) {
            if (inBuildId != this.buildId) {
                this.listName = null
                this.groupName = null
            }

            this.buildId = inBuildId
        },
        selectGroup(inGroupName) {
            this.groupName = inGroupName
        },
        selectList(inListName) {
            if (inListName != this.listName) {
                this.groupName = null
            }

            this.listName = inListName
        },
        typeInput() {
            if(this.mod.type && this.mod.type.length > 0) this.suggestModType = true
        }
    }
}
</script>