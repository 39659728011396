import { createApp } from 'vue'
import store from './store'
import App from './App'
import router from './router'
import * as Sentry from "@sentry/vue";
import axios from 'axios'
import '@/assets/css/tailwind.css'
import 'v-calendar/style.css'

import InstantSearch from 'vue-instantsearch/vue3/es'
import vClickOutside from 'v-click-outside'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { createGtm } from '@gtm-support/vue-gtm'
import vue3GoogleLogin from 'vue3-google-login'
import VueAppleLogin from 'vue-apple-login'

import activitiesMixins from '@/mixins/activities'
import algoliaMixins from '@/mixins/algolia'
import appInitMixins from '@/mixins/appInit'
import appsFlyerMixins from '@/mixins/appsFlyer'
import badgeMixins from '@/mixins/badge'
import blockMixins from '@/mixins/blocks'
import browserMixins from '@/mixins/browser'
import buildMixins from '@/mixins/build'
import buildzMixins from '@/mixins/buildz'
import cacheMixins from '@/mixins/cache'
import campaignMixins from '@/mixins/campaign'
import cartMixins from '@/mixins/cart'
import chatMixins from '@/mixins/chat'
import collaborateMixins from '@/mixins/collaborate'
import commentsMixins from '@/mixins/comments'
import countriesMixins from '@/mixins/countries'
import currencyHelperMixins from '@/mixins/currency-helpers'
import editableContentMixins from '@/mixins/editableContent'
import eventMixins from '@/mixins/event'
import fbConversionsMixins from '@/mixins/fbConversions'
import feedMixins from '@/mixins/feed'
import flasherMixins from '@/mixins/flasher'
import followMixins from '@/mixins/follows'
import formMixins from '@/mixins/form'
import gaMixins from '@/mixins/ga'
import galleryMixins from '@/mixins/gallery'
import garageMixins from '@/mixins/garage'
import groupBuyMixins from '@/mixins/groupBuy'
import groupMixins from '@/mixins/group'
import imageManiuplationMixins from '@/mixins/image-manipulations'
import likeMixins from '@/mixins/likes'
import marketplaceMixins from '@/mixins/marketplace'
import mediaMixins from '@/mixins/media'
import menuMixins from '@/mixins/menu'
import mobileMixins from '@/mixins/mobile'
import moderationMixins from '@/mixins/moderation'
import nftPortMixins from '@/mixins/nftPort'
import notificationMixins from '@/mixins/notification'
import onboardingMixins from '@/mixins/onboarding'
import paypalMixins from '@/mixins/paypal'
import popupMixins from '@/mixins/popups'
import postReactionsMixins from '@/mixins/postReactions'
import presaleMixins from '@/mixins/presale'
import productMixins from '@/mixins/product'
import queueMixins from '@/mixins/queue'
import repostMixins from '@/mixins/reposts'
import routesMixins from '@/mixins/routes'
import savingMixins from '@/mixins/saving'
import serverMixins from '@/mixins/server'
import shareMixins from '@/mixins/share'
import shippingMixins from '@/mixins/shipping'
import smartDevicesMixins from '@/mixins/smartDevices'
import spinnerMixins from '@/mixins/spinner'
import storageMixins from '@/mixins/storage'
import stripeMixins from '@/mixins/stripe'
import tabMixins from '@/mixins/tab'
import termsMixins from '@/mixins/terms'
import timeMixins from '@/mixins/time'
import tutorialMixins from '@/mixins/tutorial'
import unitConversionMixins from '@/mixins/unit-conversions'
import urlGeneratorMixins from '@/mixins/url-generators'
import userMixins from '@/mixins/user'
import validationErrorMixins from '@/mixins/validationError'
import visitorMixins from '@/mixins/visitor'
import waskMixins from '@/mixins/wask'
import whiteLabelMixins from '@/mixins/whiteLabel'
import wizardMixins from '@/mixins/wizard'

import { mapState, mapMutations, mapGetters } from 'vuex'

import BuildAvatar from '@/shared/BuildAvatar'
import CachedImage from '@/shared/CachedImage'
import CheckboxField from '@/shared/Forms/CheckboxField'
import DatePickerPanel from '@/shared/Forms/DatePickerPanel'
import FormLabel from '@/shared/Forms/FormLabel'
import FormSectionHeading from '@/shared/Forms/FormSectionHeading'
import InputPanel from '@/shared/Forms/InputPanel'
import LocationPanel from '@/shared/Forms/LocationPanel'
import MediaPanel from '@/shared/Forms/MediaPanel'
import ModalContainer from '@/containers/ModalContainer'
import PageContainer from '@/containers/PageContainer'
import PanelLabel from '@/shared/Forms/PanelLabel'
import PanelSection from '@/shared/Forms/PanelSection'
import PanelSubSection from '@/shared/Forms/PanelSubSection'
import PhoneNumberPanel from '@/shared/Forms/PhoneNumberPanel'
import RGBAPanel from '@/shared/Forms/RGBAPanel'
import RichTextPanel from '@/shared/Forms/RichTextPanel'
import SelectPanel from '@/shared/Forms/SelectPanel'
import TextAreaPanel from '@/shared/Forms/TextAreaPanel'
import UserAvatar from '@/shared/UserAvatar'
import ValidationMessage from '@/shared/Forms/ValidationMessage'
import WizardContainer from '@/containers/WizardContainer'

import { FontAwesomeIcon } from '@/mixins/fa-icons'

window.buildzAxios = axios.create()
window.buildzAxios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

window.Pusher = Pusher

const myUrl = new URL(window.location.href)
const myHost = myUrl.host
const myGtmId = store.state.gtmId[myHost]

let mySocketHost = null

if (!myHost.includes('localhost')) {
    mySocketHost = myHost.includes('apptest.buildz') ? 'staging.buildz.pro' : (myHost.includes('dev.buildz') ? 'staging-dev.buildz.pro' : 'buildz.pro')
}
else {
    mySocketHost = store.state.localHostServer
}

window.Echo = new Echo({
    broadcaster: 'reverb',
    key: '98347509bvaoiuehfcnoqow9',
    wsHost: mySocketHost,
    wsPort: 443,
    wssPort: 443,
    forceTLS: true,
    enabledTransports: ['ws', 'wss']
})

export const focus = {
    inserted(el) {
        el.focus()
    }
}

const app = createApp(App)

Sentry.init({
    app,
    dsn: "https://da310f36ae554908a736a09a043e376a@o4506924846022656.ingest.us.sentry.io/4506969313050624",
    ignoreErrors: ["undefined is not an object (evaluating 'n."],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
    
app.use(store)
app.use(router)
app.mixin(activitiesMixins)
app.mixin(algoliaMixins)
app.mixin(appInitMixins)
app.mixin(appsFlyerMixins)
app.mixin(badgeMixins)
app.mixin(blockMixins)
app.mixin(browserMixins)
app.mixin(buildMixins)
app.mixin(buildzMixins)
app.mixin(cacheMixins)
app.mixin(campaignMixins)
app.mixin(cartMixins)
app.mixin(chatMixins)
app.mixin(collaborateMixins)
app.mixin(commentsMixins)
app.mixin(countriesMixins)
app.mixin(currencyHelperMixins)
app.mixin(editableContentMixins)
app.mixin(eventMixins)
app.mixin(fbConversionsMixins)
app.mixin(feedMixins)
app.mixin(flasherMixins)
app.mixin(followMixins)
app.mixin(formMixins)
app.mixin(gaMixins)
app.mixin(galleryMixins)
app.mixin(garageMixins)
app.mixin(groupBuyMixins)
app.mixin(groupMixins)
app.mixin(imageManiuplationMixins)
app.mixin(likeMixins)
app.mixin(marketplaceMixins)
app.mixin(mediaMixins)
app.mixin(menuMixins)
app.mixin(mobileMixins)
app.mixin(moderationMixins)
app.mixin(nftPortMixins)
app.mixin(notificationMixins)
app.mixin(onboardingMixins)
app.mixin(paypalMixins)
app.mixin(popupMixins)
app.mixin(postReactionsMixins)
app.mixin(presaleMixins)
app.mixin(productMixins)
app.mixin(queueMixins)
app.mixin(repostMixins)
app.mixin(routesMixins)
app.mixin(savingMixins)
app.mixin(serverMixins)
app.mixin(shareMixins)
app.mixin(shippingMixins)
app.mixin(smartDevicesMixins)
app.mixin(spinnerMixins)
app.mixin(storageMixins)
app.mixin(stripeMixins)
app.mixin(tabMixins)
app.mixin(termsMixins)
app.mixin(timeMixins)
app.mixin(tutorialMixins)
app.mixin(unitConversionMixins)
app.mixin(urlGeneratorMixins)
app.mixin(userMixins)
app.mixin(validationErrorMixins)
app.mixin(visitorMixins)
app.mixin(waskMixins)
app.mixin(whiteLabelMixins)
app.mixin(wizardMixins)
app.mixin({
    methods: {
        ...mapMutations(store.allMutations)
    },
    computed: {
        ...mapState(store.allState),
        // ...mapGetters(store.allGetters)
    }
})
app.directive('focus', focus)
app.component('build-avatar', BuildAvatar)
app.component('cached-image', CachedImage)
app.component('checkbox-field', CheckboxField)
app.component('date-picker-panel', DatePickerPanel)
app.component('fa-icon', FontAwesomeIcon)
app.component('form-label', FormLabel)
app.component('form-section-heading', FormSectionHeading)
app.component('input-panel', InputPanel)
app.component('location-panel', LocationPanel)
app.component('media-panel', MediaPanel)
app.component('modal-container', ModalContainer)
app.component('page-container', PageContainer)
app.component('panel-label', PanelLabel)
app.component('panel-section', PanelSection)
app.component('panel-sub-section', PanelSubSection)
app.component('phone-number-panel', PhoneNumberPanel)
app.component('rgba-panel', RGBAPanel)
app.component('rich-text-panel', RichTextPanel)
app.component('select-panel', SelectPanel)
app.component('text-area-panel', TextAreaPanel)
app.component('user-avatar', UserAvatar)
app.component('validation-message', ValidationMessage)
app.component('wizard-container', WizardContainer)
app.use(vClickOutside)
app.use(InstantSearch)
app.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyB60uZRvlzT0D3DgYhhhJ45R2tFsHinJjI",
        libraries: "places"
    }
})
app.use(
    createGtm({
        id: myGtmId ? ['GTM-T79WVCS', myGtmId] : 'GTM-T79WVCS', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    //   queryParams: {
    //     gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    //     gtm_preview: 'env-4',
    //     gtm_cookies_win: 'x',
    //   },
        defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
        compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    //   nonce: '2726c7f26c', // Will add `nonce` to the script tag
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: true, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        ignoredViews: [], // Don't trigger events for specified router names (optional)
        trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    }),
)
app.use(
    vue3GoogleLogin, {
        clientId: '150608898068-89p0utq193nvnovn70djnam5s9kkscek.apps.googleusercontent.com'
    }
)
app.use(
    VueAppleLogin, {
        clientId: 'pro.buildz.service',
        scope: 'name email',
        redirectURI: 'https://app.buildz.pro/callback/login',
        state: '12345',
        usePopup: true,
    }
)
app.mount('#app')

    if (window) {
        window.apple_sign_in = {
            clientId: "pro.buildz.service",
            redirectURI: "https://app.buildz.pro/callback/login",
            scopes: "email name",
            state: "12345",
            nonce: "nonce",
        }
    }