<template>
    <div class="h-full flex items-center" :class="{ [fieldLabelClasses]: fieldLabelClasses, 'text-center justify-center': !noFormat, 'form-panel-label': isDesktopWidth && !forceMobile && !hide, 'form-panel-label-mobile': !isDesktopWidth || forceMobile, 'mb-2': (!isDesktopWidth || forceMobile) && !noFormat, 'form-panel-label-hide': isDesktopWidth && !forceMobile && hide }">
        {{ fieldLabel }} 
        <div v-if="fieldRequired" class="absolute -top-1 right-1 text-base">*</div>
    </div>
</template>

<script>
export default {
    props: [
        'fieldLabel', 
        'fieldLabelClasses',
        'fieldRequired',
        'forceMobile',
        'hide',
        'noFormat'
    ]
}
</script>