<template>
    <div v-if="formLoaded" class="w-full">
        <date-picker-panel
            :fieldDisplayValue="groupBuyForm.secondary_offer_start_time" fieldFormName="secondary_offer_start_time" fieldLabel="Secondary Draw Start Time" 
            :fieldRequired="true" :fieldValue="dates.secondary_offer_start_time" 
            @fieldChange="updateFormDateValue"
        >
            <validation-message v-if="!dates.secondary_offer_start_time" 
                message="Secondary Draw Start Time must be selected"
            />
        </date-picker-panel>
        <date-picker-panel
            :fieldDisplayValue="groupBuyForm.secondary_offer_end_time" fieldFormName="secondary_offer_end_time" fieldLabel="Secondary Draw End Time" 
            :fieldRequired="true" :fieldValue="dates.secondary_offer_end_time" 
            @fieldChange="updateFormDateValue"
        >
            <validation-message v-if="!dates.secondary_offer_end_time" 
                message="Secondary Draw End Time must be selected"
            />
            <validation-message v-if="dates.secondary_offer_start_time && dates.secondary_offer_end_time && dates.secondary_offer_end_time < dates.secondary_offer_start_time" 
                message="Secondary Draw End time must be after Secondary Draw Start Time"
            />
        </date-picker-panel>
        <input-panel
            fieldFormName="secondary_offer_label" fieldLabel="Secondary Draw Label" fieldPlaceholder="Secondary Draw Label" :fieldRequired="true" 
            fieldType="text" :fieldValue="groupBuyForm.secondary_offer_label"
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="!groupBuyForm.secondary_offer_amount" 
                message="Secondary Draw Label must be entered"
            />
        </input-panel>
        <input-panel
            fieldFormName="secondary_offer_amount" fieldLabel="Secondary Draw Amount" fieldPlaceholder="Secondary Draw Amount" :fieldRequired="true" 
            fieldType="number" :fieldValue="groupBuyForm.secondary_offer_amount"
            @fieldInput="updateFormValue"
        >
            <validation-message v-if="groupBuyForm.secondary_offer_amount < 1" 
                message="Secondary Draw Amount must be greater than zero"
            />
        </input-panel>
        <select-panel
            :fieldData="yesNoData" fieldFormName="secondary_offer_recurring" fieldLabel="Secondary Draw Recurring" :fieldRequired="true"
            :fieldValue="groupBuyForm.secondary_offer_recurring" :isLabelAndValue="true"
            @fieldChange="updateFormSelectValue"
        />
    </div>
</template>

<script>
export default {
    props: [
        'pGroupBuy', 
        'pGroupBuyForm', 
        'pSecondaryOfferEndTime', 
        'pSecondaryOfferStartTime'
    ],
    data() {
        return {
            dates: {
                secondary_offer_end_time: null,
                secondary_offer_start_time: null
            },
            formLoaded: false,
            groupBuyForm: {
                secondary_offer_amount: null,
                secondary_offer_end_time: null,
                secondary_offer_label: null,
                secondary_offer_recurring: false,
                secondary_offer_start_time: null
            }
        }
    },
    mounted() {
        this.dates.secondary_offer_end_time = this.pSecondaryOfferEndTime
        this.dates.secondary_offer_start_time = this.pSecondaryOfferStartTime
        this.groupBuyForm.secondary_offer_amount = this.pGroupBuyForm.secondary_offer_amount != null ? this.pGroupBuyForm.secondary_offer_amount : 1
        this.groupBuyForm.secondary_offer_end_time = this.pGroupBuyForm.secondary_offer_end_time
        this.groupBuyForm.secondary_offer_label = this.pGroupBuyForm.secondary_offer_label
        this.groupBuyForm.secondary_offer_recurring = this.pGroupBuyForm.secondary_offer_recurring
        this.groupBuyForm.secondary_offer_start_time = this.pGroupBuyForm.secondary_offer_start_time
        this.formLoaded = true
    },
    methods: {
        updateFormDateValue(inField, inValue) {
            let originalValue = Number.isInteger(this.dateTimeToUnixTime(this.dates[inField])) ? this.dateTimeToUnixTime(this.dates[inField]) : this.dates[inField] / 1000
            let newValue = Number.isInteger(this.dateTimeToUnixTime(inValue)) ? this.dateTimeToUnixTime(inValue) : inValue / 1000

            if (originalValue != newValue) {
                this.dates[inField] = inValue
                this.groupBuyForm[inField] = inValue
                this.$emit('updateSecondaryOffer', this.groupBuyForm, true, this.dates.secondary_offer_end_time, this.dates.secondary_offer_start_time)
            }
        },
        updateFormSelectValue(inField, inValue) {
            if (this.groupBuyForm[inField] != inValue) {
                this.groupBuyForm[inField] = inValue
                this.$emit('updateSecondaryOffer', this.groupBuyForm, true, this.dates.secondary_offer_end_time, this.dates.secondary_offer_start_time)
            }
        },
        updateFormValue(inField, inValue) {
            if (this.groupBuyForm[inField] != inValue) {
                this.groupBuyForm[inField] = inValue
                this.$emit('updateSecondaryOffer', this.groupBuyForm, false, this.dates.secondary_offer_end_time, this.dates.secondary_offer_start_time)
            }
        }
    }
}
</script>